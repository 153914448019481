<ng-container *ngIf="transactionData">
    <div class="box-head col-12">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-8 col-lg-8 text-left">

                <a [class.disabled]="transactionData.editTaken===true || transactionData.accountStatus[0].additionalType!=='AC_PENDING' || readonly" href="javascript:void()" (click)="go('P2')" class="text-primary mr-3  font-weight-bold mb-3  d-inline-flex">
                    <span class="fas fa-arrow-left pr-2 pt-1"></span> Responder </a>


                <div class="text-secondary-2 mr-3  font-weight-bold mb-3  d-inline-flex">
                    <span class="fas fa-info-circle pr-1"></span> {{transactionNumber}} </div>

                <div class="text-secondary-2 mr-3  font-weight-bold mb-3 d-inline-flex">
                    <span class="fas fa-info-circle pr-1"></span> Versión {{transactionData?.numberOfVersions?transactionData?.numberOfVersions:'-'}}
                </div>

                <h5 class="font-weight-bold mb-3" [innerHTML]="(transactionData?.plan?.name | i18n ) | i18n "></h5>

                <div>
                    <button (click)="view(transactionNumber)" type="button" class="btn btn-secondary btn-sm active">
                    <i class="fas fa-eye pr-1" ></i>
                    <span >Ver Solicitud</span>
            </button>
                </div>

            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 ">
                <ng-container *ngIf="!readonly">
                    <div class="pull-right text-right">

                        <ng-container>
                            <button (click)="skip()" class="btn btn-outline-primary btn-sm" [class.btn-outline-secondary]="transactionData.editTaken===true" [class.isDisabled]="transactionData.editTaken===true || transactionData.accountStatus[0].additionalType!=='AC_PENDING' || readonly">
                                <i class="fas fa-dice pr-1"></i>  {{ '@i18n-app-text-skip' | i18n }}
                            </button> &nbsp;
                        </ng-container>

                        <!--
                        <ng-container>
                            <button (click)="go('P2')" class="btn btn-outline-primary btn-sm pull-left" [class.btn-outline-secondary]="transactionData.editTaken===true"
                                [class.isDisabled]="transactionData.editTaken===true || transactionData.accountStatus[0].additionalType!=='AC_PENDING' || readonly">
                                {{ '@i18n-app-text-response' | i18n }}
                            </button>&nbsp;
                        </ng-container>
                      -->

                        <div class="btn-group">
                            <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle" data-toggle="dropdown">
                                <i class="fas fa-ellipsis-h"></i>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-right">
                                <li style="padding-left: 12px">
                                    <a class="active-cursor" (click)="edit(transactionNumber)" [class.isDisabled]="transactionData.accountStatus[0].additionalType!=='AC_PENDING' || readonly">{{ '@i18n-app-text-edit' | i18n }}</a>
                                </li>
                                <li style="padding-left: 12px">
                                    <a class="active-cursor" (click)="formalize()" [class.isDisabled]="(!(transactionData.accountStatus[0].additionalType==='AC_RECEIVED' && (transactionData?.accountNumber===undefined || transactionData?.accountNumber==='' || transactionData.accountNumber===null))) || readonly">{{ '@i18n-app-text-formalize' | i18n }}</a>
                                </li>
                                <li style="padding-left: 12px">
                                    <a class="active-cursor" (click)="finishedit()" [class.isDisabled]="(!(transactionData.editTaken===true && transactionData.accountStatus[0].additionalType==='AC_PENDING')) || readonly">{{ '@i18n-app-text-finish-edition' | i18n }}</a>
                                </li>
                                <li style="padding-left: 12px">
                                    <a class="active-cursor" (click)="confirmDelete()" [class.isDisabled]="(transactionData.accountStatus[0].additionalType!=='PI_IN_PROCESS') || readonly">{{ '@i18n-app-text-delete' | i18n }}</a>
                                </li>
                                <li style="padding-left: 12px">
                                    <a class="active-cursor" (click)="view(transactionNumber)">{{ '@i18n-app-text-view' | i18n }}</a>
                                </li>
                                <li style="padding-left: 12px" *ngIf="environment.theme.images!=='FACEBANK'">
                                    <a class="active-cursor" (click)="renew(transactionNumber)" [class.isDisabled]="transactionData.accountStatus[0].additionalType!=='AC_PENDING' || readonly">{{ '@i18n-app-text-renew' | i18n }}</a>
                                </li>

                            </ul>
                        </div>


                    </div>
                </ng-container>
            </div>
        </div>

        <div class="row">
            <br>
        </div>



    </div>
</ng-container>

<ng-container *ngIf="!transactionData">
    <div>
        <img class=" float-left" src="./assets/public/images/loading.gif" alt="">
    </div>
</ng-container>