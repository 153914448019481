import { AuthService } from '@akeela/auth';
import { I18nPipe } from '@akeela/i18n';
import { LocalService } from '@akeela/local-storage';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { HttpService } from '@akeela/properties';
import { AkeelaWorkflowService } from '@akeela/workflow';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { async, Subscription } from 'rxjs';
import { Utf8RegexService } from 'src/app/services/utf8-regex.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cvv',
  templateUrl: './cvv.component.html',
  styleUrls: ['./cvv.component.css']
})
export class CVVComponent implements OnInit, OnDestroy {

  dataInformation;
  errorMsgValidatTel;
  sentValidateCVV = false;
  activeModalValidateCVV = false;
  centerModal;
  code;
  data;
  loadingSentValidateCVV;
  loadingValidateCode;
  sentValidateErrorCVV;
  showValidateTel: Subscription;
  cvv = []
  env: any = environment;

  form: FormGroup;
  emailSubscription: Subscription;
  sendMailSubscription: Subscription;
  submit_disabled = false;
  email = "^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$";
  phone = "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{6,6}$";
  identity = "^[JGVEPjgvep][0-9]+$";
  client;
  showForm;
  loadingCVV;
  animate_init_bottom = true;
  buttomModal;
  username;
  registerCVV;

  constructor(

    private formBuilder: FormBuilder,
    private _httpService: HttpService,
    public _auth: AuthService,
    private localStorage: LocalService,
    private akWorkflowService: AkeelaWorkflowService,
    private i18n: I18nPipe,
    private _wfService: WorkflowService,
    private _utf8: Utf8RegexService,
    private datePipe: DatePipe,


  ) { }

  ngOnInit(): void {
    const temp2 = this.convertUTCDateToLocalDate(new Date()).toISOString().slice(0, 16);
    const dateEnd = this.setToDateString(temp2);

    //Validators.pattern(this.email)
    this.form = this.formBuilder.group({
      fecha: [dateEnd, [Validators.required]],
      status: ['', [Validators.required]],
      code: ['', [Validators.required]],
      description: ['']
    });


    this._wfService.showCVVForClient$.subscribe((result) => {
      this.cvv = [];

      this.setInitialData(result?.user);
    });



  }

  setInitialData = async (response) => {

    if (response) {
      this.loadingCVV = true;
      this.showForm = false;

      this.username = response;
      this.animate_init_bottom = false;

      this.buttomModal = true;
      // this.sendMsgValidateTel(data);

      this.client = await this.getUser(response);
      this.getDataInformations();
      this.registerCVV = null;

      console.clear();
      console.log(response);

    } else {
      this.buttomModal = false;
      // this.form.reset();
    }
  }

  isLegal = (clientDetail = this.client) => {

    return clientDetail?.personMarketType?.audienceType == 'LEGAL';

  }

  getDataInformations = async () => {

    const params = {

      name: this.isLegal(this.client) ? 'briefcase$business_person$get' : 'briefcase$person$get',
      params: {
        request: {
          'id': this.localStorage.getItem('clientIdentifier')
        },
        path: {},
        body: {}
      }
    };


    try {
      const response = await this._httpService.executeRequest(params.name, params.params, true).toPromise()

      if (response) {
        this.dataInformation = response;
        if (this.dataInformation?.dateRegisteredCvv) {


          this.registerCVV = {
            extraDataCvv: this.dataInformation?.extraDataCvv && JSON.parse(this.dataInformation?.extraDataCvv),
            userRegisteredCvv: this.dataInformation?.userRegisteredCvv,
            dateRegisteredCvv: this.dataInformation?.dateRegisteredCvv

          }

        } else {
          this.showForm = true;

        }

        this.loadingCVV = false;

      }


    } catch (error) {
      this.loadingCVV = false;
      throw new Error(error);

    }

  }


  setClose = () => {
    this.buttomModal = false;

  }



  ngOnDestroy = (): void => {
    this.destroySub();

  }

  destroySub = () => {
    this.emailSubscription.unsubscribe();
    this.sendMailSubscription.unsubscribe();
  }



  submit = async () => {
    if (this.form.dirty && this.form.valid) {

      swal.fire({
        title: '',
        html: '¿Estas seguro se asignar este codigo de CVV ?',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then(async (result) => {
        this.submit_disabled = true;

        if (result?.isConfirmed) {
          try {
            

            let response = await this.saveCVV();

            if (response) {

              this.form.reset();
              this.showForm = false;
              this.getDataInformations();
              this.submit_disabled = false;

            }

          } catch (error) {

            //this._error.show(error);
            this.submit_disabled = false;


          }

        }else{
          this.loadingCVV = false;

        }
      });

    }
  }


  saveCVV = async () => {

    let form = this.form.getRawValue();

    try {

      const fecha = this.toDateString2(form?.fecha);

      const description = {
        fecha,
        status: form?.status,
        code: form?.code,
        description: form?.description
      }

      const params = {
        name: 'person$registeredcvv$post',
        params: {
          request: {},
          path: {},
          body: {

            identifier: this.client?.identifier,
            description: JSON.stringify(description) || "",
            modifyUser: this._auth.getUsername()

          }
        }
      };

      return this._httpService.executeRequest(params.name, params.params, true).toPromise();

    } catch (error) {

      console.error(error);
      this.loadingCVV = false;

    }

  }




  getUser = async (user) => {

    const params = {
      name: 'person$enduser$get',
      params: {
        request: {
          'username': user
        },
        path: {},
        body: {}
      }
    };

    return this._httpService.executeRequest(params.name, params.params, true).toPromise();


  }

  custom_sort(a, b) {
    return new Date(b?.create_date).getTime() - new Date(a?.create_date).getTime();
  }


  custom_sort_asc(a, b) {
    return new Date(a?.create_date).getTime() - new Date(b?.create_date).getTime();
  }

  private parseDateString(date: string): Date {
    date = date.replace('T', '-');
    let parts: any = date.split('-');
    let timeParts = parts[3].split(':');

    // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[0], parts[1] - 1, parts[2], timeParts[0], timeParts[1]); // CVV: months are 0-based

  }


  toDateString(fecha): string {
    let temp2 = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16);

    let temp = new Date(fecha).toISOString().slice(0, 16);
    let date: Date = this.parseDateString(temp2);
    return (("0" + (date.getDate())).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear().toString());
  }


  convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;

  }

  setToDateString(fecha): string {
    let temp2 = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16);

    let temp = new Date(fecha).toISOString().slice(0, 16);
    let date: Date = this.parseDateString(temp2);
    return (date.getFullYear().toString() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + (date.getDate())).slice(-2));
  }


  toDateString2(fecha): string {
    let temp2 = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16);

    let temp = new Date(fecha).toISOString().slice(0, 16);
    let date: Date = this.parseDateString(temp2);
    return (("0" + (date.getDate())).slice(-2) + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear().toString());
  }
}