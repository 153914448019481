<div class="container animated fadeIn  mt-4">

    <!-- <div class="row mt-3 mt-2">
    <div class="col-12 mt-2">
      <button (click)="new()" type="button" class="btn btn-primary float-right">Nuevo</button>
      <br><br>
    </div>
  </div> -->



    <div class="mb-0 row box skin-white  animated fadeIn" style="padding-left: 0; padding-right:0">

        <div class="col-12 mt-2">
            <div class="">
                <ng-container *ngIf="formalities">

                    <div class="w-100 fz-body" *ngFor="let row of formalities; let first = first; let i_stf = index" (click)="openFormalities(row?.transactionNumber)">
                        <hr *ngIf="!first" class="m-0 ">


                        <div class="d-flex bd-highlight active-cursor  list-flex flex-wrap pb-3 pt-3 pb-md-4 pt-md-4">

                            <div class=" mb-1 mb-md-0 text-md-left  mt-1 mt-md-0 w-sm-100 mt-sm-1 pl-md-2 pr-md-2 w-50 w-sm-70 order-md-1 order-1">

                                <div class="fz-1">
                                    <span class="font-weight-bold mr-1 mr-md-3">
                    <i *ngIf="cargando == row?.transactionNumber" class="fas  fa-spinner fa-spin"></i>
                                    <i *ngIf="!(cargando == row?.transactionNumber)" class="fas  fa-check-circle-o text-primary fz-1-2"></i>
                  </span>
                                    <span class="font-weight-bold mr-2 text-black"> {{row?.plan?.name}}</span>
                                    <!-- <span class="text-secondary d-none d-md-inline "> {{row?.amount.value? (row?.amount.value | number:'1.2-2':'en'):'-' }} </span> -->

                                </div>

                            </div>

                            <div class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-25  w-sm-100 order-3 order-md-2  ml-4 ml-md-0 ">
                                <div class="font-weight-bold">

                                    {{row?.transactionNumber}}

                                </div>
                            </div>

                            <div class="mb-1 mb-md-0 ml-auto bd-highlight text-right  mt-1 mt-md-0 mt-1 mt-md-0 pl-md-2 pr-md-2 w-20  w-sm-30  order-2 order-md-3">

                                <span class=" ">
                  {{row?.dateOfThing?.modifiedDate | date: 'd MMM, y' }}
                </span>

                            </div>

                            <div class=" mb-1 mb-md-0 text-md-right  mt-md-0 w-5 pl-md-2 pr-md-2  order-4 d-none d-md-block ">
                                <i class="fas fa-angle-right text-primary"></i>
                            </div>


                            <div class=" mb-1 mb-md-0 text-md-left  pt-1 mt-md-0 w-100 mt-sm-1 pl-md-2 pr-md-2  order-5 ">
                                <i class="fa  fa-reply  iconRotate font-weight-bold ml-md-5 ml-2 mr-2"></i>
                                <div class=" ml-4 ml-md-0  font-weight-bold d-md-inline "> {{ row?.accountStatus?row?.accountStatus[0].userName: ''}}</div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="w-100 text-center" *ngIf="formalities.length===0">
                        <hr>
                        <br>
                        <b>SIN SOLICITUES EN CURSO</b>
                        <br>
                        <br>
                        <hr>
                    </div>
                    <br>
                </ng-container>
                <ng-container *ngIf="!formalities">
                    <div class="row">
                        <div class="col-12  col-md-8 ">
                            <img class="float-left" src="./assets/public/images/loading.gif" alt="">
                        </div>
                    </div>
                </ng-container>



            </div>

        </div>
    </div>




</div>