import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UtilsService } from '@akeela/common';


@Pipe({
  name: 'datePipe'
})
export class DatePipePipe implements PipeTransform {
  textI18n: String = '@i18n-text-';

  constructor(
    private date: DatePipe,
    private _util: UtilsService
  ) { }

  transform(value: any, type?: any): any {
    let response = '';
    switch (type) {
      case 'simple':
        response = this._util.digestI18n(this.date.transform(value, '|' + this.textI18n + 'MMM| dd, y - hh:mm aa'), '|');
        break;
      case 'simpleDate':
        response = this._util.digestI18n(this.date.transform(value, '|' + this.textI18n + 'MMM| dd, y'), '|');
        break;
      case 'simpleHour':
        response = this.date.transform(value, 'hh:mm aa');
        break;
      default:
        response = value;
        break;
    }
    return response;
  }



}
