<app-template>
    <ng-template app-content componentName="mainContentModalApp">
        <div class="capa-menu-2" *ngIf="openFilter" (click)="openFilter =!openFilter"> </div>
        <div class="">

            <div class="animate__animated  animate__faster  ak-modal-right p-2" [ngClass]="{'d-none':animate_init,'animate__slideInRight':openFilter,animate__slideOutRight:!openFilter } ">

                <div class="cl" style="height: 30px !important;">

                    <span class="float-right active-cursor" (click)="togle_window();">
                        <i class="text-secondary fas fa-times " style="font-size: 1.2rem;"></i>
                    </span>
                </div>


                <div class="body pb-4 pt-2 " style="overflow:auto;">
                    <div class="d-block  pb-5" style="max-height: 100%; background: #fcfcfc">


                        <div *ngIf="filterBy">
                            <div class=" font-weight-bold mb-4">
                                <i class="fas fa-filter iconsearch mr-1"></i> Filtrar</div>
                            <span *ngIf=" paginator?.number > 0" class="text-primary fz-14 ">Resultados
                                <span class="font-weight-bold"> {{ paginator?.number }} </span>
                            </span>

                            <div class="row p-0 m-0 pb-4">
                                <div class="col-11  p-0 m-0 pb-4">

                                    <div class="row">
                                        <div class="col-6 mt-3">
                                            <span class="label">Desde </span>
                                            <input onkeydown="return false" [(ngModel)]='formsData.interviewDateFecha.dateInit' class="form-control" type="date" placeholder="Desde - 01/06/2021" (change)="setFilterMultiple()" />

                                        </div>
                                        <div class="col-6 mt-3 ">
                                            <span class="label">Hasta</span>

                                            <input onkeydown="return false" [(ngModel)]='formsData.interviewDateFecha.dateEnd' class="form-control" type="date" placeholder="Hasta - 30/6/2021" (change)="setFilterMultiple()" />
                                        </div>



                                        <div class="col-12 mt-4 d-none">
                                            <span class=" label">Agencia </span>

                                            <select (change)="setFilterMultiple()" [(ngModel)]='formsData.agencia.key' class="form-control search withoutorder" name="status" (change)="setFilterStatus(formsData.status2.key); setFilterMultiple()">
                                                <option value='' [selected]="true">--</option>

                                                <ng-container *ngFor="let agency of agencys; let i = index">

                                                    <option [value]='agency'>{{agency}}</option>

                                                </ng-container>
                                            </select>

                                        </div>


                                        <div class="col-12 mt-4 d-none">

                                            <span (change)="setFilterMultiple()" class=" label">Ejecutivo</span>

                                            <select [(ngModel)]='formsData.ejecutivo.key' class="form-control search withoutorder" name="status" (change)="setFilterStatus(formsData.status2.key); setFilterMultiple()">
                                                <option value='' [selected]="true">--</option>

                                                <ng-container *ngFor="let executive of executives; let i = index">

                                                    <option [value]='executive'>{{executive}}</option>

                                                </ng-container>

                                            </select>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div *ngIf="orderBy">
                            <div class=" font-weight-bold mb-4">
                                <i class="fas fa-sort iconsearch mr-1"></i> Ordenar</div>


                            <div class="row">

                                <div class="col-12 mt-3">
                                    <div class="label mb-3">Tiempo de respuesta </div>

                                    <button [ngClass]="{'btn-secondary': timeReponseDesc,'btn-outline-secondary': !timeReponseDesc}" class="btn  " (click)="orderAscDesc(true)">
                                        <i class="fas fa-sort-amount-down-alt mr-2"></i>Descendente</button>

                                    <button [ngClass]="{'btn-secondary': !timeReponseDesc,'btn-outline-secondary': timeReponseDesc}" class="btn  ml-2 " (click)="orderAscDesc(false)">
                                        <i class="fas fa-sort-amount-up-alt mr-2"></i>Ascendente</button>


                                </div>
                            </div>

                        </div>


                    </div>
                </div>

            </div>

        </div>

        <div class="capa-menu-2" *ngIf="buttomModal" (click)="buttomModal =!buttomModal;tap == null"> </div>
        <div [ngClass]="{'d-none':animate_init_bottom,'animate__slideInUp':buttomModal,animate__slideOutDown:!buttomModal } " class="  animate__animated animate__faster ak-modal-botoom ak-modal-botoom2  p-2 ">

            <div class="body pb-4 pt-2 " style="overflow:auto;">
                <div class=" mt-4 " *ngIf="tap == 2">
                    <div class="text-left col-12 col-md-10 offset-md-1 ">
                        <akeela-step *ngIf="true" [inputWorkflowCode]="'ACCOUNT_OPENING'" [inputStageCode]="'CREATE_USER_ACCOUNT_OPENING'" [inputStepCode]="'CREATE_USER_ACCOUNT_OPENING'" [profile]="'SALES_MANAGER'" [audience]="'LEGAL'" [role]="'USER'">
                        </akeela-step>
                    </div>
                </div>
                <div *ngIf="tap == 1">
                    <div class="container text-center">
                        <div class="row">

                            <div class="col-12 col-md-6 offset-md-3">

                                <div class=" text-left">
                                    <div class="p-2 fz-14 text-left size-caja-dopd">

                                        <div class="row">

                                            <div class="col-12 mt-1 form-group">
                                                <span class=" label mb-1"> Razón </span>

                                                <select class="form-control search withoutorder" name="status" [(ngModel)]='formsDataResponse.type'>
                                                    <option value='' [selected]="true">--</option>
                                                    <ng-container *ngFor="let row of responseType; let i = index">

                                                        <option [value]='row?.item'>{{row?.value | i18n }}</option>

                                                    </ng-container>

                                                </select>

                                            </div>
                                            <div class="col-12 mt-1 form-group">
                                                <span class=" label mb-1 ">Descripción </span>

                                                <textarea [(ngModel)]='formsDataResponse.description' name="ejemplo" class="form-control " style="height: 100px !important; "></textarea>

                                            </div>

                                            <div class="col-12 mt-3 form-group">
                                                <button [disabled]="formsDataResponse.type == '' " (click)="submitFormsDataResponse()" class="btn  btn-primary btn-block ">Guardar</button>
                                            </div>
                                        </div>

                                    </div>


                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    </ng-template>
</app-template>

<akeela-meet *ngIf="init_meet" [selected]="init_meet" [username]="userMeet" [transactionedit]="init_meet_edit_transaction" (change)="setMeetData($event)"></akeela-meet>
<app-transaction-detail *ngIf="transactionDetail" [selected]="transactionDetail" [transaction]="transactionSelected" (change)="setTransactionDetail($event)"></app-transaction-detail>
<app-client-detail *ngIf="!init_meet" [selected]="clientDetail" [username]="clientSelected" (change)="setClientDetail($event)"></app-client-detail>

<ng-container>
    <div style="font-size: 14px" class="container pt-4">
        <div class="jumbotron jumbotron-fluid p-3 ">

            <div class="d-flex bd-highlight  flex-wrap">

                <div class="w-95">
                    <div class="elipsse">
                        <h5 [title]="'Reuniones'" class="font-weight-normal">
                            <i class="fas fa-video pr-1"></i> Reuniones
                        </h5>
                    </div>
                </div>
                <div class="w-5 text-right">
                    <i class="fas fa-ellipsis-v "></i>

                </div>
            </div>



        </div>


        <div class="row   mt-2 mb-1 ">
            <div class="col-12 text-primary col-md-7 pt-md-4 ">
                <input (keyup.enter)="updateFilter (fieldSearch) " [(ngModel)]='fieldSearch' class="pt-2 form-control active-cursor padding-filter-searh-input " type="text " placeholder='CI, Telf, Nombre, Apellido, No. Solicitud' />
                <i (click)="updateFilter(fieldSearch) " class="text-primary fas fa-search icon-filter-searh active-cursor"></i>

            </div>
        </div>


        <div class="w-100 text-primary mb-3 mt-3">
            <div class="d-inline-flex w-100 ">

                <button style="height: 35px !important; " class="btn-light btn " (click)=" togle_window(1);fieldSearch='' ">
                    Filtrar
                    <i class="fas fa-filter iconsearch "></i>
                </button>
                <!--

                <button style="height: 35px !important; " class="btn-light ml-2 btn ml-auto" (click)=" togle_window(2);fieldSearch='' ">
                    <i class="fas fa-sort iconsearch "></i> Ordenar
                </button>
                 -->
            </div>
        </div>

        <div *ngIf="historyData?.length > 0" class="pt-4 pb-2">

            <div class="text-secondary pb-3 pt-2 ml-2"> Vistos recientemente </div>

            <ul class="scrollmenu pl-0  ">
                <li class="d-inline">

                    <div class="d-flex">
                        <ng-container *ngFor="let row of historyData ; let i = index">

                            <div class="ml-2 mr-2">

                                <div class="text-center active-cursor" (click)="setClient(row?.username)">

                                    <div *ngIf="!historyDataSelfi[row?.solicitud]" class=" DomainUserAvatar-avatar text-dark AvatarPhoto--client2  AvatarPhoto " role="img">{{getInitials(row?.name)}}
                                    </div>

                                    <div style=" background: url({{historyDataSelfi[row?.solicitud]}}) 50% 50% no-repeat; " class="ak-shadow animate__animated animate__fadeIn content-avata-img-history " *ngIf="historyDataSelfi[row?.solicitud]">
                                    </div>
                                </div>

                                <div class="text-center active-cursor">

                                    <div class="font-weight-bold  active-cursor text-capitalize fz-12 mt-1" (click)="fieldSearch = row?.name; updateFilter(row?.name)"> <span>{{row.name}}</span></div>

                                </div>

                            </div>
                        </ng-container>

                    </div>

                </li>
            </ul>
        </div>

        <div class="d-flex bd-highlight mt-3">

            <div class="">
                <i *ngIf="!reload_loading" (click)="reloadTransaction()" class="fas fa-redo text-secondary ml-2 active-cursor "></i>
                <span *ngIf="updateDate" class="text-secondary  fz-14 ml-2" [title]="updateDate?.time"> Actualizado
                    <span [innerHTML]="updateDate?.value"></span>
                </span>
            </div>

            <div class="text-secondary ml-auto">

                {{ (paginator?.pageable?.to===0)?(paginator?.pageable?.to):(paginator?.pageable?.from) }} - {{ paginator?.pageable?.to }} de
                <b>{{ paginator?.number }} </b>
                <i class="fas fa-chevron-left mr-1 text-secondary active-cursor" [class.active-cursor]="!paginator?.first" [class.disabled]="paginator?.first" (click)="action_paginator('BACK')"></i>
                <i class="fas fa-chevron-right text-secondary " [class.active-cursor]="!paginator?.last" [class.disabled]="paginator?.last" (click)="action_paginator('NEXT')"></i>

            </div>

        </div>
        <hr>

        <div class="text-center mt-4">
            <button [ngClass]="{'btn-primary': formsData.interviewDate.key == 'new','btn-outline-primary': formsData.interviewDate.key != 'new'}" class="btn  mr-2 " (click)="formsData.interviewDate.key = 'new'; setFilterMultiple()">
                Próximas
            </button>
            <button [ngClass]="{'btn-primary': formsData.interviewDate.key == 'oll','btn-outline-primary': formsData.interviewDate.key != 'oll'}" class="btn " (click)="formsData.interviewDate.key = 'oll'; setFilterMultiple()">
               Pasadas
            </button>

        </div>

        <!-- 
        <div class="card  mt-5 mb-3 ak-shadow-box ">
            <div class="card-body p-2  ">

                <app-jitsi *ngIf="init_meet_local " (change)="setUrlmeetLocal($event) " [urlmeet]=" 'meet-hazling-'+meet_local_url " [client]="meet_local_client" [executive]="userNameSesion "></app-jitsi>

            </div>
        </div>
-->
        <ng-container *ngIf="reload_loading">
            <div class=" w-100 mt-2">
                <img src="./assets/public/images/loading.gif" alt="">
            </div>
        </ng-container>

        <ng-container *ngIf="!loading_table">
            <div class="w-100 pt-5  text-center animate__animated animate__fadeIn" *ngIf="rows?.length < 1">
                <i class=" pt-4 pb-2 opacity-card fas fa-people-arrows fa-5x  text-center text-primary"></i>
                <div>No se encontraron reuniones</div>
            </div>

            <div *ngIf="rows?.length > 0" class=" w-100">
                <ng-container *ngFor="let row of rows ; let i = index">
                    <div class="card  mt-4 mb-3 ak-shadow-box " (click)="setHistoryData(row,'view_client','Ver cliente')">
                        <div class="card-body p-3 ">

                            <div class="d-flex bd-highlight  list-flex pt-2">

                                <div>
                                    <i class="fas mr-3 icon-list-meet text-secondary" [ngClass]="{
                                'icon-list-meet-disabled':!interviewsTime[row.idTransaction]['valid'],
                                'fa-video': (interviewType[row?.type])?.label == '@i18n-quote-option-list-interviewType-option3',
                                'fa-phone-alt':(interviewType[row?.type])?.label == '@i18n-quote-option-list-interviewType-option2',
                                'fa-user':(interviewType[row?.type])?.label == '@i18n-quote-option-list-interviewType-option1'}"></i>
                                </div>

                                <div class="mb-3">
                                    <div class="d-flex  flex-wrap text-left ">
                                        <div class=" mb-1 w-100 font-weight-bold"> {{(interviewType[row?.type])?.label | i18n}}</div>

                                        <div class="mb-2 w-100  text-secondary ">

                                            <span class="ml-1 " [ngClass]="{'text-danger': !interviewsTime[row.idTransaction]['valid']}">{{toDateString(row?.interviewDate)}}</span>
                                            <br class="d-block d-md-none">
                                            <span class="mb-2 p-2 fz-12 text-danger " *ngIf="interviewsTime[row.idTransaction]['valid']">
 
                                           <span class="pr-2 ">Faltan</span>
                                            <span class="pr-2" *ngIf="interviewsTime[row.idTransaction]['day'] > 0"><span>{{ interviewsTime[row.idTransaction]['day']}}</span>Dia(s)</span>
                                            <span class="pr-2" *ngIf="interviewsTime[row.idTransaction]['day'] < 1 && interviewsTime[row.idTransaction]['hours'] > 0"><span   >{{ interviewsTime[row.idTransaction]['hours'] }}</span>Hora(s)</span>
                                            <span class="pr-2" *ngIf="interviewsTime[row.idTransaction]['day'] < 1 && interviewsTime[row.idTransaction]['minutes'] > 0"><span >{{interviewsTime[row.idTransaction]['minutes'] }}</span>Min</span>
                                            <span class="pr-2 " *ngIf="interviewsTime[row.idTransaction]['day'] < 1 && interviewsTime[row.idTransaction]['hours'] < 1 &&  interviewsTime[row.idTransaction]['seconds'] > 0"><span   >{{interviewsTime[row.idTransaction]['seconds'] }}</span>Seg</span>
                                            <span class="pl-1 far fa-clock blink-me rounded "></span>
                                            </span>

                                        </div>

                                        <div class="mb-1 w-100 ">
                                            <div class="d-flex bd-highlight  list-flex flex-wrap">

                                                <div class="bd-highlight">
                                                    <div *ngIf="!fileAvatarPreview[row?.username]" class="DomainUserAvatar-avatar text-dark AvatarPhoto--client  AvatarPhoto AvatarPhoto--small" role="img">{{ getInitials(row?.name)}}

                                                    </div>
                                                    <div (click)="getDocumentClient(row?.username,'SELFIE')" style="border: 1px solid #ddd; background: url({{fileAvatarPreview[row?.username]}}) no-repeat center" class="activate-cursor animate__animated animate__fadeIn content-avata-img2 mr-1" *ngIf="fileAvatarPreview[row?.username]">

                                                    </div>

                                                </div>
                                                <div class="bd-highlight pl-1">
                                                    <div class="font-weight-bold active-cursor text-capitalize fz-14 mt-2" (click)=" setClient(row?.username)">

                                                        {{ row?.name}}
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                        <div class="text-left w-100" *ngIf="(interviewType[row?.type])?.label == '@i18n-quote-option-list-interviewType-option3'">

                                            <div>
                                                <span [ngClass]="{'icon-meet-join-disabled':!interviewsTime[row.idTransaction]['valid']}" class="pl-3 pr-3  pt-2 pb-2 badge icon-meet-join text-secondary  badge-pill  mt-2 active-cursor " (click)="redirectMeet('https://meet.jit.si/meet-'+env?.theme?.images+'-'+row?.username+'-'+row?.transactionNumber) ">Unirme</span>
                                                <i class="fas fa-info-circle text-secondary ml-2" (click)="showLink = true"></i>
                                            </div>

                                            <div *ngIf=" showLink " class="mt-1 mb-2  text-primary active-cursor " (click)="redirectMeet('https://meet.jit.si/meet-'+env?.theme?.images+'-'+row?.username+'-'+row?.transactionNumber) ">
                                                {{'https://meet.jit.si/meet-'+env?.theme?.images+'-'+row?.userName+'-'+row?.transactionNumber}}
                                            </div>

                                            <div class="text-left w-100 " *ngIf=" showLink ">

                                                <div class="w-100">
                                                    <div class="text-secondary active-cursor  pr-3" (click)="setCopy( 'https://meet.jit.si/meet-'+env?.theme?.images+ '-'+row?.userName+'-'+row?.transactionNumber) ">
                                                        <button class="btn btn-outline-secondary ">
                                                <i class="fas fa-copy pr-2 "></i>Copiar
                                                </button>
                                                    </div>
                                                    <div class="  d-md-none  text-secondary  text-left" (click)="setShare(interviewType[row?.type].label == '@i18n-quote-option-list-interviewType-option3'?'https://meet.jit.si/meet-'+env?.theme?.images+ '-'+row?.userName+'-'+row?.transactionNumber: false ,{fecha:row?.interviewDate,tipo:interviewType[row?.type].label}) ">

                                                        <button class="btn btn-outline-secondary ">
                                                <i class="fas fa-share-alt pr-2 "></i>Compartir
                                           </button>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div class="fz-16 text-secondary ml-auto  activate-cursor" (click)="setOpenMeet(row?.username,row?.idTransaction)">
                                    <i class="fas fa-edit"></i>
                                </div>

                            </div>

                            <div class="d-flex  flex-wrap text-left ">
                                <div *ngIf="row?.description " class="font-weight-bold ">Descripción </div>
                                <div class=" pb-4 w-100 "> {{row?.description}} </div>
                                <div class="card mb-3 active-cursor" (click)="openTransaction(row?.solicitud)">
                                    <div class="card-body p-2">
                                        <div class="d-flex bd-highlight  list-flex flex-wrap">

                                            <div class="bd-highlight">
                                                <i class="fas fa-layer-group pr-2 mt-1 text-primary fa-2x"></i>
                                            </div>

                                            <div class="bd-highlight">
                                                <div>{{row?.plan}}</div>
                                                <div>Sol #{{row?.solicitud}}</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="w-100">
                                    <hr/>

                                    <ul class="scrollmenu pl-0  mt-2 ">
                                        <li class="d-inline">
                                            <div class="card active-cursor pl-2 pr-2 w-150px">
                                                <div class="card-body p-2">
                                                    <div class="d-flex bd-highlight  list-flex flex-wrap">

                                                        <div class="bd-highlight">
                                                            <i class="fas fa-video pr-2 mt-1 text-success fa-2x"></i>
                                                        </div>

                                                        <div class="bd-highlight mt-2">
                                                            <div>Grabación</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="d-inline">
                                            <div class="card active-cursor ml-3 pl-2 pr-2 w-150px">
                                                <div class="card-body p-2">
                                                    <div class="d-flex bd-highlight  list-flex flex-wrap">

                                                        <div class="bd-highlight">

                                                            <i class="fab fa-dropbox pr-2 mt-1 text-primary fa-2x"></i>
                                                        </div>

                                                        <div class="bd-highlight mt-2 text-secondary">
                                                            <div>Notas de reun..</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="d-inline">
                                            <div class="card active-cursor ml-3 pl-2 pr-2 w-150px">
                                                <div class="card-body p-2">
                                                    <div class="d-flex bd-highlight  list-flex flex-wrap">

                                                        <div class="bd-highlight">

                                                            <i class="fab fa-google-drive pr-2 mt-1 text-primary fa-2x"></i>
                                                        </div>

                                                        <div class="bd-highlight mt-2 text-secondary">
                                                            <div>Notas de reun..</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="d-inline ">
                                            <div class=" w-150px ml-3">

                                                <i class="fas fa-plus  mt-3 text-primary fa-2x"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>

                </ng-container>
            </div>

            <ng-container *ngIf="loading_table ">
                <div>
                    <img src="./assets/public/images/loading.gif " alt=" ">
                </div>
            </ng-container>
        </ng-container>

        <div class="row content-paginator m-t-1" *ngIf="paginator">
            <akeela-paginator [paginator]="paginator" (response)="action_paginator($event)" *ngIf="!loading_table">
            </akeela-paginator>
        </div>

        <ng-container *ngIf="loading_table">
            <div>
                <img src="./assets/public/images/loading.gif" alt="">
            </div>
        </ng-container>


    </div>
</ng-container>