import { Injectable } from '@angular/core';
import { isNullOrUndefined, isNull } from 'util';
import { HttpService } from '@akeela/properties';
import { ErrorService } from '@akeela/common';
import { environment } from '../../environments/environment';
import { AuthService } from '@akeela/auth';
import { LocalService } from '@akeela/local-storage';
@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private _httpService: HttpService,
    private _error: ErrorService,
    private _auth: AuthService,
    private localStorage: LocalService
  ) { }

  postSearch(object, stage, step, size?, page?, sort_by?, sort_type?) {
    let request = {};
    if (!isNullOrUndefined(size) && !isNullOrUndefined(page)) {
      if (!isNullOrUndefined(sort_by) && !isNullOrUndefined(sort_type)) {
        request = {
          size: size,
          page: page,
          sort_by: sort_by,
          sort_type: sort_type
        };
      } else {
        request = {
          size: size,
          page: page
        };
      }
    }
    const params: any = {
      name: 'account$transactionworkspacesearchpaginated$post',
      params: {
        request: request,
        path: {
          stage: stage,
          step: step,
          workspaceCode: this.localStorage.getItem('workspace'),
          user: this._auth.getUsername()
        },
        body: object
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

}
