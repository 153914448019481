import { Component, OnInit, Input, EventEmitter, Output, AfterContentInit, DoCheck, OnDestroy } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { LocalService } from '@akeela/local-storage';
import { AkeelaStepService, AkeelaWorkflowService } from '@akeela/workflow';
import { TransactionService } from '../../../services/transaction.service';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.css']
})

export class SubHeaderComponent implements OnInit, DoCheck, OnDestroy {
  public _transactionData = null;
  public _viewMode = false;
  @Input() title: string;
  @Input() subtitle?: string;
  @Input() link?;
  @Input() currentWorkflowChange?;
  @Input() currentWorkflowDefault?;
  @Input() set inputTransactionData(value) {
    this._transactionData = value;
  }

  @Output() response = new EventEmitter<any>();

  tooltip = '';
  modeEdit = false;
  view_more = false;
  showTemplates;


  constructor(
    public config: NgbDropdownConfig,
    private localStorage: LocalService,
    private akStepService: AkeelaStepService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private transactionService: TransactionService
  ) {
    config.placement = 'bottom-left';
    config.autoClose = true;
  }

  ngOnInit() {
    this.showTemplates = true;
    this._viewMode = this.localStorage.getItem('readonly') ? true : false;
    if (this.link === 'requirements') {
      this.tooltip = 'Ir a Recaudos';
    } else if (this.link === 'clientinfo') {
      this.tooltip = 'Ir a Datos del Cliente';
    } else {
      this.tooltip = '';
    }
    if (isNullOrUndefined(this.link) || this.link === '') {
      this.link = null;
    }
  }


  ngDoCheck() {
    if (this._transactionData) {
      if (this.localStorage.getItem('takenedit') === this._transactionData.transactionNumber) {
        this.modeEdit = true;

      }
    }
  }


  event_response(response) {

    if (response === 'finishview') {
      this.localStorage.removeItem('readonly');
      if (!isNullOrUndefined(window.opener)) {
        this.akStepService.setModeCurrentStep(false);
        window.close();
      } else {
        this.akStepService.setModeCurrentStep(false);
      }
    } else if(response === 'finishedit') {
      this.localStorage.removeItem('readonly');
      this.akStepService.setModeCurrentStep(false);
      this._transactionData.editTaken = false;
      
      
      // this.akeelaWorkflowService.transactionData$.next(this._transactionData);
  


      // Mario: si se comenta el emit, no funciona el finalizar edición, lo vuelvo a descomentar
    }


    this.response.emit(response);
  }

  view_mode_active() {
    this.view_more = !this.view_more;
  }

  ngOnDestroy() {
    this.showTemplates = false;

  }

}
