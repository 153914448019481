import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  params_products;
  params_plans;
  plans;
  product;
  states;
  forms: any = {};
  submit_disabled;
  loading_form: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }



  ngOnInit() {
    document.body.classList.add('teme-gray');
    this.router.navigateByUrl(this.router.createUrlTree
      ([environment.security.uriLogin],
        {
          queryParams: {}
        }
      )
    );
  }

  ngOnDestroy() {
    document.body.classList.remove('teme-gray');
  }

}
