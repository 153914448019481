<div class="sidebar">

    <div class="w-100 head bg-black">
        <div class="float-right">
            <a (click)="toggleMenu()" class="cerrar">
                <i class="fa fa-times close-sidebar" aria-hidden="true"></i>
            </a>
        </div>
        <div class="pt-img">
            <app-navbar-img [imgmovil]="true" [height]="'3'"></app-navbar-img>

        </div>
    </div>
    <div class="col-12 ">

        <div class="w-100 text-success">
            <hr>
            <div class="menu">
                <a (click)="contacts()" *ngIf="queryParams['profile']==='SALES_MANAGER'" class=" text-white ">
                    <small [innerHTML]="'Clientes' | i18n:lang"></small>
                </a>
            </div>
            <div class="menu" *ngIf="queryParams['profile']==='SALES_MANAGER'">
                <a (click)="procedures()" class=" text-white ">
                    <small [innerHTML]="'Mis Solicitudes' | i18n:lang"></small>
                </a>
            </div>
            <hr>
            <div class="menu">
                <a (click)="cerrar_Sesion()" class=" text-white ">
                    <small [innerHTML]="'Cerrar Sesión' | i18n"></small>
                </a>

            </div>
        </div>
        <!-- MENU DINAMICO-->
        <ng-container *ngIf="!sidebarOptions">
            <div class="row">
                <div class="">
                    <i class="fa fa-refresh fa-spin ml-2 text-white" style="font-size: 1.2rem"></i>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="sidebarOptions">
            <ng-container *ngFor="let lst of sidebarOptions?.listItems">
                <ng-container *ngIf="lst?.dataFieldItemList?.listItems?.length < 1">
                    <div class="dd-link " (click)="closeMenu()">
                        <a *ngIf="lst.url || lst.additionalType == ''" [routerLink]="['/public/ACCOUNT_OPENING/stages/SUMMARY_ACCOUNT_OPENING/steps/SUMMARY_ACCOUNT_OPENING']" [queryParams]="{role:'A', audience:'LEGAL', profile:'CLIENT' }" class=" text-white ">
                            <!--  [class]="'icon-menu ' + lst?.image?.iconClass" aria-hidden="true"></i> -->
                            <span [innerHTML]="lst.alternateName | i18n:lang"></span>
                        </a>
                        <a *ngIf="lst.additionalType != ''" [routerLink]="['/public/ACCOUNT_OPENING/stages/SUMMARY_ACCOUNT_OPENING/steps/SUMMARY_ACCOUNT_OPENING']" [queryParams]="{role:'A', audience:'LEGAL', profile:'CLIENT' }" (click)="call_fn(lst.additionalType)" class=" text-white ">
                            <!--  <i [class]="'fa icon-menu '+ lst?.image?.iconClass" aria-hidden="true"></i> -->
                            <span [innerHTML]="
                            (lst.alternateName | i18n:lang) +
                            (
                             lst.additionalType == 'inbox' && _sidebar.my_credits_inbox_number != 0 ? ' ('+_sidebar.my_credits_inbox_number+')':
                             lst.additionalType == 'sent' && _sidebar.my_credits_sent_number != 0 ? ' ('+_sidebar.my_credits_sent_number+')':
                             lst.additionalType == 'draft' && _sidebar.my_credits_draft_number != 0 ? ' ('+_sidebar.my_credits_draft_number+')':'')
                              "></span>
                        </a>
                    </div>
                </ng-container>
                <ng-container *ngIf="lst?.dataFieldItemList?.listItems?.length > 0">
                    <div class="dd-link" (click)="closeMenu()">
                        <a href="javascript:void(0)" [ngClass]="{'font-weight-bold':_router.url == lst.url,'active':dropDownMenu}" (click)="dropDownMenu[lst?.name] = !dropDownMenu[lst?.name] " class="text-white">
                            <i class="fa fa-angle-down float-right text-primary" *ngIf="!dropDownMenu[lst?.name]"></i>
                            <i class="fa fa-angle-up float-right text-primary" *ngIf="dropDownMenu[lst?.name] "></i>
                            <i [ngClass]="{' text-primary':dropDownMenu[lst?.name]}" class="fa fa-line-chart icon-menu" aria-hidden="true"></i>
                            <span [innerHTML]="lst.alternateName | i18n:lang"></span>
                        </a>
                    </div>
                    <ng-container *ngFor="let lst2 of lst?.dataFieldItemList?.listItems" class="">
                        <div *ngIf="dropDownMenu[lst?.name] " class="w-100 text-success">
                            <div class="sub-menu">
                                <a *ngIf="lst2.url && lst2.additionalType == ''" [routerLink]="lst2.url" class=" text-white ">
                                    -
                                    <small [ngClass]="{'font-weight-bold':_router.url == lst2.url}" [innerHTML]="lst2.alternateName | i18n:lang"></small>
                                </a>
                                <a *ngIf="lst2.additionalType != ''" [routerLink]="lst2.url" (click)="call_fn(lst2.additionalType)" class=" text-white ">
                                    -
                                    <small [ngClass]="{'font-weight-bold':_router.url == lst2.url}" [innerHTML]="lst2.alternateName | i18n:lang"></small>
                                </a>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>