<footer (window:resize)="onResize($event)" class="footer animated " style="z-index: 0!important" #footer>
    <div class="container-fluid foter-preguntas  p-b-20">
        <div [ngClass]="[parent == 'dashboard'?'preg-dash':'','float-right']">
        </div>
    </div>
    <div *ngIf="line" class="w-100 p-b-10" style="border-top : 1px solid #979797;"></div>
    <div class="container-fluid  bg-white text-dark p-b-20 p-t-20">
        <div class="container ">
            <div class="p-b-10 b-b-1">
                <span [innerHTML]="environment.i18nConfig.constants.clientNameShort + ('@i18n-quote-footer-text-standard' | i18n:lang) "></span>
            </div>
            <div class="p-t-10">
                <span [innerHTML]="'@i18n-quote-footer-more-information' | i18n:lang"></span>
                <a target="_blank" [href]="environment.i18nConfig.constants.url">{{ environment.i18nConfig.constants.url }}</a>
            </div>
        </div>
    </div>
</footer>