<div class="capa-menu-2" *ngIf="buttomModal" (click)="setClose()"> </div>

<div [ngClass]="{'d-none':animate_init_bottom,'animate__slideInUp':buttomModal,animate__slideOutDown:!buttomModal } " class="  animate__animated animate__faster ak-modal-botoom ak-modal-botoom2  p-2 ">
    <div class="body pb-4 pt-2 " style="overflow:auto;">
        <div class="container">


            <div class="row">
                <div [ngClass]="{'col-md-10 offset-md-1': !showForm,'col-md-8 offset-md-2': showForm}" class="col-12 ">

                    <div (click)="$event.stopPropagation();" class="w-100 bg-white mt-3  ">


                        <div style="min-height: 206px;">


                            <div class="d-flex bd-highlight">
                                <div class="w-80 w-sm-70">

                                    <h5 class="font-weight-bold fz-16"><i class="fas fa-sticky-note text-dark"></i> Notas</h5>
                                    <div class="text-secondary fz-14">{{client?.person?.givenName + ' '+ client?.person?.familyName}}</div>

                                </div>

                                <div class="w-20 w-sm-30 pl-2">
                                    <div class="text-right">

                                        <div *ngIf="showForm ">
                                            <i (click)="showForm=! showForm " class=" active-cursor fas fa-times " style="font-size: 1.2rem; "></i>
                                        </div>
                                        <div class="p-2 ">
                                            <button *ngIf="!showForm " (click)="showForm=! showForm " class=" btn btn-primary btn-sm">
                                                Nueva Nota
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <hr>


                            <ng-container *ngIf="loadingNotes">
                                <div class="text-center mt-3">
                                    <img src="./assets/public/images/loading.gif" alt="">
                                </div>
                            </ng-container>

                            <ng-container *ngIf="notes?.length == 0 && !loadingNotes && !showForm ">
                                <div class=" mt-3"> No se encotraron notas </div>
                            </ng-container>



                            <ng-container *ngIf="!showForm && !loadingNotes">

                                <div class="mt-2 note active-cursor mt-2 " *ngFor="let row of notes">


                                    <!-- <div class="d-flex bd-highlight">
                                        <div class="w-100 w-sm-100 fz-14">

                                             <div class=" active-cursor note-text fz-14 font-weight-bold ">
                                                {{row.title}}
                                            </div>

                                            <div class=" active-cursor note-text fz-14 text-secondary">
                                                {{row.subtitle}}
                                            </div>

                                       


                                        </div>

                                        
                                        <div class="w-20 w-sm-30 pl-2">
                                            <div class=" fz-12 text-right fz-12">

                                                <span class=" text-secondary">{{row.create_date | date: 'dd/MM/yyyy HH:mm'}}</span><br>
                                            

                                            </div>

                                        </div>
                                  
                                    </div>
  -->

                                    <div class=" active-cursor  fz-14 mt-3">
                                        <div class=" small text-secondary ">{{row?.create_date | date: 'dd/MM/yyyy HH:mm'}}</div>

                                        <span class=" font-weight-bold  pr-2">{{row.ejecutive }}</span>
                                        <span>{{row.note}}</span>
                                    </div>

                                </div>
                            </ng-container>



                            <ng-container *ngIf="showForm && !loadingNotes">
                                <div class="w-100">

                                    <form [formGroup]="form" (ngSubmit)="submit()" class="text-left fz-14">

                                        <div class="row">

                                            <!--
                                            <div class="form-group col-12 col-md-12">
                                                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                                        <span [innerHTML]="'Titulo'"></span>
                                        <span class="text-danger mt-1" > * </span>
                                    </label>
                                                <input [placeholder]="'Titulo'" type="text" class="form-control" [ngClass]="{'error': form.controls.title.invalid && form.controls.title.dirty}" formControlName="title">
                                                <div class="form-control-feedback help-block" *ngIf="form.get('title').errors">
                                                    <div *ngIf="form.get('title').errors['required'] && !form.get('title').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="form-group col-12 col-md-12">
                                                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                                        <span [innerHTML]="'Subtitulo'"></span>
                                        <span class="text-danger mt-1" > * </span>
                                    </label>
                                                <input [placeholder]="'Subtitulo'" type="subtitle" class="form-control" [ngClass]="{'error': form.controls.subtitle.invalid && form.controls.subtitle.dirty}" formControlName="subtitle">
                                                <div class="form-control-feedback help-block" *ngIf="form.get('subtitle').errors">
                                                    <div *ngIf="form.get('subtitle').errors['required'] && !form.get('subtitle').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                                                    </div>

                                                </div>
                                            </div>
      -->

                                            <div class="form-group col-12 col-md-6">
                                                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                                            <span [innerHTML]="'Tipo'"></span>
                                            <span class="text-danger mt-1" > * </span>
                                            </label>

                                                <select class="form-control" [ngClass]="{'error': form.controls.noteCliente.invalid && form.controls.noteCliente.dirty}" formControlName="noteCliente">
                                                    <option value='false' >Nota privada</option>
                                                    <option value='true' >Nota publica con SMS al cliente</option>

                                                </select>
                                                <div class="form-control-feedback help-block" *ngIf="form.get('noteCliente').errors">
                                                    <div *ngIf="form.get('noteCliente').errors['required'] && !form.get('noteCliente').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="form-group col-12 col-md-12 ">
                                                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                                        <span [innerHTML]="'Nota'"></span>
                                        <span class="text-danger mt-1" > * </span>
                                    </label>
                                                <textarea style="height: 120px" [placeholder]="'Descripción'" type="subtitle" class="form-control mt-2" [ngClass]="{'error': form.controls.note.invalid && form.controls.note.dirty}" formControlName="note"></textarea>
                                                <div class="form-control-feedback help-block" *ngIf="form.get('note').errors">
                                                    <div *ngIf="form.get('note').errors['required'] && !form.get('note').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                                                    </div>

                                                </div>
                                            </div>



                                        </div>
                                        <div class="row">

                                            <br>
                                            <div class=" col-12 mt-3">


                                                <button type="submit" [disabled]="!form.valid || submit_disabled  || !form.dirty" class="btn btn-primary  ">

                                                    Continuar
                                                    <span *ngIf="submit_disabled">
                                                    <i class="fa  fa-spinner fa-spin"></i>
                                                    </span>
                                                </button>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </ng-container>


                        </div>



                    </div>

                </div>
            </div>
        </div>

    </div>
</div>