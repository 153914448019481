import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resumen-sections-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class ResumenFooterComponent implements OnInit {
menuFooter
  constructor() { }

  ngOnInit() {

    this.menuFooter = 'revisions';
  }

}
