import {Component,OnInit,Renderer2,ViewChild,ElementRef,HostListener, Input} from "@angular/core";
import { environment } from '../../../environments/environment';

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})

export class FooterComponent implements OnInit {
  @Input("parent") parent: string;
  @Input("line") line: string;

  lang: string;
  @ViewChild("footer", {static: false}) footer: ElementRef;

  height: number;
  environment;


  constructor(private renderer: Renderer2) { }
  ngOnInit() {
    this.environment = environment;
  }
  

  ngAfterViewInit() {
    document.body.style.paddingBottom =
      this.footer.nativeElement.offsetHeight + "px";
    this.height = this.footer.nativeElement.offsetHeight;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    document.body.style.paddingBottom =
      this.footer.nativeElement.offsetHeight + "px";
    this.height = this.footer.nativeElement.offsetHeight;
  }
}
