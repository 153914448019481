import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AkeelaSectionService, AkeelaStepService, AkeelaWorkflowService, AkeelaStageService, RouteUtilsService } from '@akeela/workflow';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@akeela/auth';
import { HttpService } from '@akeela/properties';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { RequirementsService } from '@akeela/requirements';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { LocalService } from '@akeela/local-storage';
import * as moment from 'moment-mini-ts'

import { isNullOrUndefined } from 'util';


@Component({
  selector: 'app-private-min-resume',
  templateUrl: './min-resumen.component.html',
  styleUrls: ['./min-resumen.component.css']
})
export class MinResumeComponent implements OnInit {
  transactionNumber;
  transactionData;
  breadcrumdKey: any;
  transactionStatus: any;
  workflowCode;
  stageCode;
  audience;
  holder;
  sectionIncompleats = [];
  sectionRequirementsIncompleats: any = [];
  sectionIncompleatsDropDown = {};
  sectionRequirementsIncompleatsDropDown = {};

  role;

  wfSubscription: Subscription;
  public isCollapsed = false;

  showModalResponses = false;

  env;
  showModalResume = false;

  percent = null;
  completed = 9;
  total = 14;

  view_more = false;

  items;

  legal_representative = [];
  autorizado = [];
  cuentadante = [];
  loadItems;


  incomplete = false;
  constructor(
    private akeelaSectionService: AkeelaSectionService,
    private route: ActivatedRoute,
    private router: Router,
    public _auth: AuthService,
    private _httpService: HttpService,
    private akeelaBreadcrumbService: AkeelaBreadcrumbService,
    private akStepService: AkeelaStepService,
    private workflowService: AkeelaWorkflowService,
    private stageService: AkeelaStageService,
    private _akeelaRequirementsService: RequirementsService,
    private localStorage: LocalService,
    private routerUtilService: RouteUtilsService

  ) {

  }

  ngOnInit() {

    this.env = environment;
    this.transactionNumber = this.localStorage.getItem('transactionNumber');
    this.route.params.subscribe(params => {
      this.role = this.routerUtilService.getRole(params);
      this.audience = this.routerUtilService.getAudience(params);
    });



    this.workflowService.transactionData$.subscribe(transactionData => {

      if (!isNullOrUndefined(transactionData)) {
        this.transactionData = transactionData;
        this.getModulesInformation();
      }

    });
  }

  getModulesInformation() {
    if (!isNullOrUndefined(this.transactionData)) {

      this.loadItems = true;
      const params = {
        name: 'resume$transaction_modules_complete$get',
        params: {
          request: {
            idPlan: this.transactionData && this.transactionData.plan ? this.transactionData.plan.identifier : null,
            role: this.role,
            audience: this.audience
          },
          path: {
            idTransaction: this.transactionData.identifier,
            idPerson: this.transactionData.holder ? this.transactionData.holder.identifier : null
          },
          body: {}
        }
      };
      this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        const items = [];
        this.loadItems = false;

        response = response.sort(function (a, b) {
          return a.identifier - b.identifier;
        });

        this.items = response;

      }, error => {
        this.loadItems = false;

        this.items = null;
      });
    }
  }

  round(value) {
    return Math.round(value);
  }

  diffDates(date) {
    const nowMoment: moment.Moment = moment(Date());
    const resta = moment.duration(nowMoment.diff(moment(date)));
    return resta.months() + 'm ' + resta.days() + 'd ' + resta.hours() + 'h ' + resta.minutes() + 'm ';
  }

  duration(date) {
    const nowMoment: moment.Moment = moment(Date());
    const resta = moment.duration(nowMoment.diff(moment(date)));
    let response = '';
    if (resta.years() > 0) {
      response = 'Más de ' + resta.years() + ' año(s)';
    } else if (resta.months() > 0) {
      response = 'Más de ' + resta.months() + ' mes(es)';
    } else if (resta.weeks() > 0) {
      response = 'Más de ' + resta.weeks() + ' semana(s)';
    } else if (resta.days() > 0) {
      response = 'Más de ' + resta.days() + ' dia(s)';
    } else if (resta.hours() > 0) {
      response = 'Más de ' + resta.hours() + ' hora(s)';
    } else if (resta.minutes() > 0) {
      response = 'Más de ' + resta.minutes() + ' minuto(s)';
    } else {
      response = 'Hace pocos segundos';

    }
    return response;
  }



}

