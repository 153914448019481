import { Component, OnInit } from '@angular/core';
import { ProductsService } from "@akeela/products-plans";
import { HttpService } from '@akeela/properties';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { SubheaderService } from '../../../../services/subheader.service';
import { TransitionService } from "../../../../services/transition.service";
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService} from "@akeela/workflow";
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'public-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})
export class PlansComponent implements OnInit {
  params_plans;
  plans;
  product;
  states;
  forms: any = {};
  submit_disabled;
  loading_form: boolean = true;
  identifierProduct;
  showSubHeaderStep;
  env = environment;
  workflow;
  stage;
  step;
  event='ACCOUNT_SELECTED_AND_USER_NOT_LOGGED';
  url_transition='workflow$transitionssteps$get';

  constructor(
    private formBuilder: FormBuilder,
    private _httpService: HttpService,
    private _productsService: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private sh:SubheaderService,
    private transitionService: TransitionService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private akSectionService: AkeelaSectionService
  ) { }

  ngOnInit() {
    this.workflow=this.akWorkflowService.currentWorkflow$.getValue();
    this.stage=this.akStageService.currentStage$.getValue();
    this.step=this.akStepService.currentStep$.getValue();
    // this.sh.subHeader$.next(true);
    document.body.classList.add('teme-gray');
    this.route.queryParams.subscribe(queryParams => {
      this.identifierProduct = queryParams.identifierProduct;
      let params_plans: any = {
        endpoint: {
          products: {
            name: 'product_plan$plans$get',
            params: {
              path: {},
              request: {
                identifierProduct: queryParams.identifierProduct
              },
              body: {}
            }
          }
        }
      };
      let params_states: any = {
        endpoint: {
          products: {
            name: 'app_properties$states$get',
            params: {
              path: {},
              request: { countryCode: 'VE' },
              body: {}
            }
          }
        }
      };

      this._productsService.currentProduct$.subscribe((identifierProduct) => {
        if (identifierProduct) {
          // this.identifierProduct=identifierProduct;
        }
      });

      this._httpService.executeRequest(params_states.endpoint.products.name, params_states.endpoint.products.params).subscribe((response: any) => {
        this.states = response;
      });

      this._httpService.executeRequest(params_plans.endpoint.products.name, params_plans.endpoint.products.params).subscribe((response: any) => {
        if (response) {
          this.plans = response;
          let group: any = {}
          for (let plan of this.plans) {
            this.forms[plan.identifier] = this.formBuilder.group({
              identifierPlan: ['', Validators.required]
            });
          }
          this.loading_form = false;

          if (response.length > 0) {
            this.product = response[0].financialProduct;
          }
        }
      }, error => {
        this.plans = null;
      });

    });
  }

  submit(identifier) {
    const form = this.forms[identifier];
    if (form.valid) {
      const obj = { product: this.identifierProduct, plan: form.get('identifierPlan').value};
      sessionStorage.setItem('selectProduct', JSON.stringify(obj));
      // this.router.navigateByUrl(this.router.createUrlTree(['dashboard/LOGIN_ACCOUNT_OPENING/stages/LOGIN_ACCOUNT_OPENING/steps/LOGIN_ACCOUNT_OPENING'],
      //   {
      //     queryParams: {}
      //   }
      // )
      // );

      const params = {
        name: this.url_transition,
        params: {
          request: {
            role: 'C',
            audience: 'ALL',
            event_code: this.event
          },
          path: {
            prefixWorkflow: 'workflow_manager',
            workflow: this.workflow,
            stage: this.stage,
            step: this.step
          }
        },
        o: {}
      }
      this.transitionService.next('dashboard', params);
    


    }

  }

  ngOnDestroy() {
    this.sh.subHeader$.next(false);
    document.body.classList.remove('teme-gray');
  }

}
