<akeela-template>
    <ng-template akeela-content componentName="ComponentProducts">
        <select-product></select-product>
    </ng-template>
    <ng-template akeela-content componentName="ComponentPlans">
        <public-plans></public-plans>
    </ng-template>
    <ng-template akeela-content componentName="ComponentLogin">
        <public-login></public-login>
    </ng-template>
</akeela-template>
<div class="container-fluid main-content animated fadeIn mt-4">
    <div class="box mb-2  pt-3 ">
        <div class="col-12">
            <div class="row">
                <div class="w-100">

                    <body>
                        <router-outlet></router-outlet>
                    </body>
                </div>
            </div>
        </div>
    </div>
</div>