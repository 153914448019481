import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { environment } from '../../../environments/environment';
const BASE_URL = './';

@Component({
  selector: 'app-logo-img',
  templateUrl: './logo-img.component.html',
  styleUrls: ['./logo-img.component.css']
})
export class LogoImgComponent implements OnInit {
  urlLogo: string;
  urlLogoMin: string;
  @Input() height;
  @Input('theme') theme?: string;
  @Input('type') type?: number;

  public env = environment;
  constructor() { }

  ngOnInit() {

    let imgTheme: any = {};

    if (this.env.theme.logoConf.theme[this.theme]) {
      imgTheme = this.env.theme.logoConf.theme[this.theme]
    } else {
      imgTheme = this.env.theme.logoConf.theme[this.env.theme.logoConf.themeColorDefault];
    }

    if ((this.type !== 1) && (this.type !== 2)) {
      this.type = this.env.theme.logoConf.typeDefault;
    }

    this.urlLogo = BASE_URL + imgTheme.urlLogo;
    this.urlLogoMin = BASE_URL + imgTheme.urlLogoMin;

  }

}
