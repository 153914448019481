import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.css']
})
export class SortComponent implements OnInit {
  @Input() id;
  @Input() config;
  @Input() direction;
  @Output() response = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  select() {
    this.config.active = this.id;
    this.config.direction = this.direction;
    this.response.emit({id: this.id, direction: this.direction});
  }

}
