import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { LocalService } from '@akeela/local-storage';
import { HttpService } from '@akeela/properties';
import { UploadService, ErrorService } from '@akeela/common';
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-transaction-requirements-preview',
  templateUrl: './transaction-requirements-preview.component.html',
  styleUrls: ['./transaction-requirements-preview.component.css']
})
export class TransactionRequirementsPreviewComponent implements OnInit, OnDestroy {
  _transaction;
  _filter = [];
  transactionData = null;
  requirements = null;
  env = environment;
  activateOverlay = [];
  iterator = 0;
  show_requirements = [];

  @Output() response?= new EventEmitter();
  @Output() listRequirements?= new EventEmitter();

  @Input() set transaction(transaction) {
    this._transaction = transaction;
    //check process first time
    this.process();
  }

  @Input() set filter(filter) {
    this._filter = filter;
    // filter requirements
    this.processRequirements();
  }

  constructor(public localStorage: LocalService, public _httpService: HttpService, public _upload: UploadService) { }

  isNullOrUndefined = (object) => isNullOrUndefined(object);

  ngOnInit() {
  }

  async process() {
    this.transactionData = await this.getTransactionData(this._transaction);
    await this.response.emit(this.transactionData);
    this.requirements = await this.getRequirementsTransaction();
    this.processRequirements();
  }

  getResponsePreview(response) {
    if (!isNullOrUndefined(response)) {
      if (response.response === 'success') {
        this.activateOverlay = [...this.activateOverlay, response.identifier];
      }
    }
  }

  show_file(file) {
    if (this.activateOverlay.includes(file)) {
      file = { identifier: file };
      const archive = {
        identifier: file.identifier,
        name: file.name,
        dateCreated: {
          orderDate: null
        }
      };

      if (file.dateCreated) {
        archive['dateCreated']['orderDate'] = file.dateCreated.modifiedDate;
      } else {
        archive['dateCreated']['orderDate'] = null;
      }

      if (archive.identifier !== null) {
        this._upload.visor_params.name = '';
        this._upload.visor_params.date = '';
        this._upload.visor_params.endpoint = 'archive$downloadFile$get';
        this._upload.visor_params.archive = archive;
        this._upload.visor_params.deleteAfterShow = '';
        if (file['action'] === 'download') {
          this._upload.visor_params.action = 'download';
          this._upload.visor_params.deleteAfterShow = {
            deleteRequirement: file['deleteAfterShow']
          };
          this._upload.visor = false;
          setTimeout(() => {
            this._upload.visor = true;
          }, 6);
        } if (file['action'] === 'downloadzip') {

          if (this.env.theme.images === 'FACEBANK') {
            this._upload.visor_params.endpoint = 'archive$downloadZipFile$get';
          }

          this._upload.visor_params.action = 'download';
          this._upload.visor_params.deleteAfterShow = {
            deleteRequirement: file['deleteAfterShow']
          };
          this._upload.visor = false;
          setTimeout(() => {
            this._upload.visor = true;
          }, 6);
        } else {
          this._upload.visor_params.action = '';
          this._upload.visor = false;
          setTimeout(() => {
            this._upload.visor = true;
          }, 6);
        }
      }
    }
  }

  async getTransactionData(transactionNumber): Promise<any> {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.localStorage.getItem('role'),
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    return await this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }

  async getRequirementsTransaction() {
    const params = {
      name: 'requirements$transaction_requirements$get',
      params: {
        request: {
          audience: this.transactionData.holder.additionalType ? this.transactionData.holder.additionalType : 'LEGAL'
        },
        path: {
          idPlan: this.transactionData.plan.identifier,
          transactionNumber: this.transactionData.transactionNumber,
          idPerson: this.transactionData.holder.identifier
        },
        body: {}
      }
    };
    return await this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }

  processRequirements() {
    if(!isNullOrUndefined(this.requirements)) {
      let list = [];
      Object.keys(this.requirements).map((creq) => {
        this.requirements[creq].map((req) => list = [...list, req]);
      });
      if (!this._filter.includes('') && !this._filter.includes('all')) {
        this.applyFilter(list);
      } else if (this._filter.includes('')) {
        this.show_requirements = [];
      } else if (this._filter.includes('all')) {
        this.show_requirements = list;
      }
      this.listRequirements.emit(list);
    }
    
  };

  applyFilter = (list) => {
    this.show_requirements = list.filter((req) => this._filter.includes(req.requirement.identifier));
  }

  ngOnDestroy(): void {
    
  }

}
