<div *ngIf="transactionData" class="mt-2" style="font-size: 0.84rem">
    <!--
 <h5  class="font-weight-light  title-section mb-3 " >Datos de la Solicitud</h5>
-->
    <div class="mt-2 p-2 alert ak-status alert-secondary  w-100 text-left text-dark p-1 pt-2 pb-2 mb-2" [class.alert-warning]="transactionData?.accountStatus[0]?.additionalType == 'AC_INCOMPLETE' " [class.alert-primary]="transactiotransactionDatanData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS'"
        [class.alert-primary]="transactionData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS' || transactionData?.accountStatus[0]?.additionalType == 'AC_PENDING' ">
        <i class=" text-secondary pr-2 fas fa-info-circle " aria-hidden="true"></i>

        <span class="font-weight-bold ">
       {{transactionData?.accountStatus[0]?.userName}}
   </span>
        <br>
        <span class="text-dark">{{transactionData?.accountStatus[0]?.description}}</span>
    </div>

    <div class="row mb-3" style="padding-top: 12px">
        <div class=" col-6 mb-1">
            <div class=" mt-2 p-1">
                <span class="p-2 rectangle green">{{ diffDates(transactionData?.dateOfThing?.startDate) }}</span>
            </div>
        </div>
        <div class="col-6">
            <div>
                <div>Total</div>
                <div class="text-secondary">{{ duration(transactionData?.dateOfThing?.startDate) }}</div>

            </div>
        </div>
    </div>

    <div class="row  mb-3 mb-1 ">
        <div class="  col-6">
            <div class="mt-2 p-1">
                <span class="p-2 rectangle gray">{{ diffDates(transactionData.accountStatus[0].dateOfThing?.startDate) }}</span>
            </div>
        </div>
        <div class=" col-6">
            <div>
                <div>{{ transactionData.accountStatus[0].userName }}</div>
                <div class="text-secondary">{{duration(transactionData.accountStatus[0].dateOfThing?.startDate)}}</div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-12 font-weight-bold">
            /// Progreso de la solicitud
        </div>
    </div>

    <div class="row">
        <div class="col-12 mt-2" *ngIf="items">
            <!-- {{ items | json}} -->
            <div class="mb-2" *ngFor="let item of items">
                <span class="label" style="line-height : 40px;">{{ item.approvalUser | i18n }} (
                            <span [innerHTML]="item.progress.minValue+'/'+item.progress.maxValue" *ngIf="item.additionalType!=='EXISTENCE'"></span>
                <span [innerHTML]="item.progress.minValue===0?'Pendiente':'Completado'" *ngIf="item.additionalType==='EXISTENCE'"></span>)
                </span>
                <akeela-graphics [percent]="round(item.progress.minValue*100/item.progress.maxValue)" [type]="'line'">
                </akeela-graphics>
            </div>
        </div>

        <div *ngIf="loadItems">
            <img src="./assets/public/images/loading.gif" alt="">
        </div>

    </div>


    <div class="row">
        <div class="col-12 col-md-12" style="padding-top: 24px">
            <div class="row">
                <div class="col-6 font-weight-bold">Producto</div>
                <div class="col-6">{{ transactionData?.plan?.financialProduct.name }}</div>
            </div>

        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-12" style="padding-top: 12px">
            <div class="row">
                <div class="col-6 font-weight-bold">Plan</div>
                <div class="col-6">{{ transactionData?.plan?.name | i18n }}</div>
            </div>

        </div>
    </div>


    <div class="row" *ngIf="transactionData?.accountNumber">
        <div class="col-12 col-md-12" style="padding-top: 12px">
            <div class="row">
                <div class="col-6 font-weight-bold">Número de Cuenta</div>
                <div class="col-6">{{ transactionData?.accountNumber | i18n }}</div>
            </div>

        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-12" style="padding-top: 12px">
            <div class="row">
                <div class="col-6 font-weight-bold">Número de versión</div>
                <div class="col-6">{{ transactionData?.numberOfVersions?transactionData?.numberOfVersions:'-' }}</div>
            </div>

        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-12" style="padding-top: 12px">
            <div class="row">
                <div class="col-6 font-weight-bold">Estado</div>
                <div class="col-6">{{ transactionData.accountStatus[0].userName | i18n }}</div>
            </div>

        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-12" style="padding-top: 12px">
            <div class="row">
                <div class="col-6 font-weight-bold">Fecha de creación</div>
                <div class="col-6">{{ transactionData?.dateOfThing?.startDate | datePipe: 'simple' }}</div>
            </div>

        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-12" style="padding-top: 12px">
            <div class="row">
                <div class="col-6 font-weight-bold">Fecha del estatus</div>
                <div class="col-6">{{ transactionData?.accountStatus[0].dateOfThing?.startDate | datePipe: 'simple' }}</div>
            </div>

        </div>
    </div>


    <div class="row">
        <div class="col-12 col-md-12" style="padding-top: 12px">
            <div class="row">
                <div class="col-6 font-weight-bold">Fecha de última actualización</div>
                <div class="col-6">{{ transactionData?.dateOfThing?.modifiedDate | datePipe: 'simple' }}</div>
            </div>

        </div>
    </div>




    <div class="row" style="padding-top:12px">
        <div class="col-12 col-md-12" style="padding-top: 12px">
            <div class="row">
                <div class="col-6 font-weight-bold">Cliente</div>
                <div class="col-6">{{ transactionData?.holder?.name?transactionData?.holder?.name:'-' }}</div>
            </div>

        </div>

        <div class="col-12 col-md-12" style="padding-top: 12px">
            <div class="row">
                <div class="col-6 font-weight-bold">Tipo de solicitante</div>
                <div class="col-6">{{ transactionData?.holder?.additionalType == 'NATURAL'?('@i18n-quote-option-list-applicantType-option-natural' | i18n): ('@i18n-quote-option-list-applicantType-option-legal' | i18n) }}</div>
            </div>

        </div>


        <div class="col-12 col-md-12" style="padding-top: 12px">
            <div class="row">
                <div class="col-6 font-weight-bold">Aplicante</div>
                <div class="col-6 ">{{ transactionData?.holder?.person?.name?transactionData?.holder?.person?.name:'-' }}
                </div>
            </div>

        </div>
        <div class="col-12 col-md-12" style="padding-top: 12px">
            <div class="row">
                <div class="col-6 font-weight-bold">Creado por</div>
                <div class="col-6">{{ transactionData?.requestUser?.person?.name?transactionData?.requestUser?.person?.name:'-' }}
                </div>
            </div>

        </div>

        <div class="col-12 col-md-12" style="padding-top: 12px">
            <div class="row">
                <div class="col-6 font-weight-bold">Canal</div>
                <div class="col-6">{{ transactionData?.availableChannel?.name?transactionData?.availableChannel?.name:'-' }}
                </div>
            </div>

        </div>
        <div class="col-12 col-md-12" style="padding-top: 12px">
            <div class="row">
                <div class="col-6 font-weight-bold">Agencia</div>
                <div class="col-6">{{ transactionData?.channelAgency?.name?transactionData?.channelAgency?.name:'-' }}
                </div>
            </div>

        </div>

        <div class="col-12 col-md-12" style="padding-top: 12px">
            <div class="row">
                <div class="col-6 font-weight-bold">Alianza</div>
                <div class="col-6">-</div>
            </div>

        </div>
        <div class="col-12 col-md-12" style="padding-top: 12px">
            <div class="row">
                <div class="col-6 font-weight-bold">Ejecutivo</div>
                <div class="col-6" [innerHTML]="transactionData?.salesManagerUser?transactionData?.salesManagerUser?.name:'<i class=\'fas fa-question-circle font-color-green\'></i> <span class=\'font-color-gray\'>Sin asignar</span>'"></div>
            </div>

        </div>
    </div>

</div>

<div class="mt-3" *ngIf="!(transactionData)">
    <img src="./assets/public/images/loading.gif" alt="">
</div>