import { Component, OnInit } from '@angular/core';
import { LocalService } from '@akeela/local-storage';
import { AuthService } from '@akeela/auth';
import { HttpService } from '@akeela/properties';
import { isNullOrUndefined } from 'util';



@Component({
  selector: 'app-section-newtransaction-header',
  templateUrl: './section-newtransaction-header.component.html',
  styleUrls: ['./section-newtransaction-header.component.css']
})
export class SectionNewtransactionHeaderComponent implements OnInit {
  role;
  client = null;
  constructor(
    private localStorage: LocalService,
    private auth: AuthService,
    private _httpService: HttpService
  ) { }

  ngOnInit() {
    // get person information
    this.role = this.localStorage.getItem('role');
    let username;
    if (this.role === 'USER') {
      username = this.localStorage.getItem('clientUsername');
    } else if (this.role === 'CLIENT') {
      username = this.auth.getUsername();
    }

    if (!isNullOrUndefined(username)) {
      const params = {
        name: 'person$enduser$get',
        params: {
          request: {
            'username': username
          },
          path: {},
          body: {}
        }
      };

      this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        this.client = response;
      });
    }
  }

  capitalize(word) {
    return word[0].toUpperCase() + word.slice(1);
  }

}
