import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {
  @Input() config;
  @Input() id;
  @Output() response = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  select(object) {
    this.config.object[object.id].movement = object.action;
    this.config.active = object.id;
    this.response.emit(object);
  }

}
