<div class="mb-4 row" *ngIf="client">

    <div class="col-12 col-md-10 offset-md-1">
            <h5 class=" mb-1 mt-1 font-weight-bold">Nueva Solicitud</h5>

            <div class="font-1 text-bold">
              {{ client.adminOrganization? capitalize(client.adminOrganization.legalName) : capitalize(client.person.givenName)+' '+capitalize(client.person.familyName) }}
          
          
          <span style="font-size: 11px" class="ml-1 badge badge-pill badge-secondary mr-2">
              {{ client.adminOrganization? ('@i18n-quote-option-list-applicantType-option-legal' | i18n): ('@i18n-quote-option-list-applicantType-option-natural' | i18n) }}
          </span>
          
          </div>
    </div>

</div>
