import { Component, OnInit, OnDestroy, Input, Output,EventEmitter } from '@angular/core';
import { AkeelaSectionService, AkeelaStepService, AkeelaWorkflowService, AkeelaStageService, RouteUtilsService } from '@akeela/workflow';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@akeela/auth';
import { HttpService } from '@akeela/properties';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { RequirementsService } from '@akeela/requirements';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LocalService } from '@akeela/local-storage';
import * as moment from 'moment-mini-ts'

import { isNullOrUndefined } from 'util';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'ak-responses',
  templateUrl: './responses.component.html',
  styleUrls: ['./responses.component.css']
})

export class ResponsesComponent implements OnInit, OnDestroy {
  @Input() showCommentsBoolean;
  @Output() close? = new EventEmitter<any>();

  @Input() set transaction(value) {
    if(value){
      this.transactionNumber = value;
      this.transactionExternal = true;

    }else{
      this.transactionNumber = this.localStorage.getItem('transactionNumber');
      this.transactionExternal = false;

    }

  }

  transactionNumber;
  transactionData;
  breadcrumdKey: any;
  transactionStatus: any;
  workflowCode;
  stageCode;
  audience;
  holder;
  sectionIncompleats = [];
  sectionRequirementsIncompleats: any = [];
  sectionIncompleatsDropDown = {};
  sectionRequirementsIncompleatsDropDown = {};
  role;
  loadRecaudos = false;
  loadDatos = false;
  env =  environment;
  transactionExternal = false;
  modifyUser;


  wfSubscription: Subscription;
  public isCollapsed = false;

  showModalResponses = false;

  showModalResume = false;

  percent = null;
  completed = 9;
  total = 14;

  view_more = false;

  items;

  legal_representative = [];
  autorizado = [];
  cuentadante = [];

  incomplete = false;



  constructor(
    private akeelaSectionService: AkeelaSectionService,
    private route: ActivatedRoute,
    private router: Router,
    public _auth: AuthService,
    private _httpService: HttpService,
    private akeelaBreadcrumbService: AkeelaBreadcrumbService,
    private akStepService: AkeelaStepService,
    private workflowService: AkeelaWorkflowService,
    private stageService: AkeelaStageService,
    private _akeelaRequirementsService: RequirementsService,
    private localStorage: LocalService,
    private routerUtilService: RouteUtilsService

  ) { }

  ngOnInit() {
    this.loadRecaudos = true;
    this.loadDatos = true;
    this.workflowCode = environment.workflow;

    this.env = environment;
    this.route.params.subscribe(params => {
      this.role = this.routerUtilService.getRole(params);
      this.audience = this.routerUtilService.getAudience(params);
      this.getAccountproductinformation();
    });


    this.wfSubscription = this.workflowService.currentWorkflow$.subscribe(workflowCode => {
      this.stageService.currentStage$.subscribe(stageCode => {
        this.stageCode = stageCode;
      });
    });
  }

  getRequirementsIncompleted() {
    const config = {
      statusResponseRequirement: {
        verified: 'VERIFIED',
        inconsistent: 'INCONSISTENT',
        incomplete: 'INCOMPLETED',
        rejected: 'REJECTED'

      },
      endpoints: {
        getRequirements: {
          identifier: '',
          name: 'requirements$transaction_requirements$get',
          params: {
            request: {
              audience: this.transactionData.holder.additionalType ? this.transactionData.holder.additionalType : 'LEGAL'
            },
            path: {
              idPlan: this.transactionData.plan.identifier,
              transactionNumber: this.transactionData.transactionNumber,
              idPerson: this.transactionData.holder ? this.transactionData.holder.identifier : null
            },
            body: {}
          }
        },
        saveRequirement: {
          params: {
            request: {},
            path: {},
            body: {
              'transaction': {
                'identifier': this.transactionData.identifier,
                'name': this.transactionData.transactionNumber
              },
              'requirement': {
                'identifier': null
              },
              'requirementArchives': [
                {
                  'creator': {
                    'identifier': null
                  }
                }
              ],
              'person': {
                'identifier': this.transactionData.holder.identifier,
                'identity': this.transactionData.holder.alternateName,
              }

            }
          }
        },
        deleteRequirement: {
          name: 'requirements$requirement$delete',
          params: {
            request: {},
            path: {},
            body: {
              alternateName: 'ACCREDITED',
              transaction: {
                'identifier': this.transactionData.identifier,
                'name': this.transactionData.transactionNumber
              },
              requirement: {
                'identifier': null
              },
              person: {
                'identifier': this.transactionData.holder.identifier,
              },
              requirementArchives: [{
                identifier: null
              }]
            }
          }
        }
      }
    };
    return new Promise((resolve, reject) => {
      this._akeelaRequirementsService.getRequirementsWithMultipleStatus(config, ['INCOMPLETED', 'REJECTED']).then((resp: any) => {
        resolve(resp);
      }, error => {
        reject(error);
      });
    });
  }

  getAccountproductinformation = async () => {
    this.transactionData = null;
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role,
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: this.transactionNumber
        },
        body: {}
      }
    };



    this._httpService.executeRequest(params.name, params.params, true).subscribe( async (response: any) => {
      this.transactionData = response;
      

      this.modifyUser = await this.getClient(this.transactionData.accountStatus[0].modifyUser);

      

      if (this.transactionData.accountStatus[0].additionalType === 'AC_INCOMPLETE') {
        this.getStatusIncompleat();
        this.incomplete = false;

        this.getRequirementsIncompleted().then((resp) => {
          this.loadRecaudos = false;
          this.sectionRequirementsIncompleats = resp;
        });
      } else {

        this.getHistoryStatusTransaction().then((history: any) => {
          if (history.length >= 2) {
            if (history[0].name === 'PI_IN_PROCESS' && history[1].name === 'AC_INCOMPLETE') {
              this.incomplete = true;
              this.getRequirementsIncompleted().then((resp) => {
                if (resp) {
                  this.loadRecaudos = false;

                  this.getStatusIncompleat();
                }
                this.sectionRequirementsIncompleats = resp;
              });
            }
          }
        });
      }
    },error => {
      this.loadRecaudos = false;
    });

  }



  getClient = async (username) => {
    const params = {
      name: 'person$enduser$get',
      params: {
        request: {
          'username': username
        },
        path: {},
        body: {}
      }
    };
    return await this._httpService.executeRequest(params.name, params.params, true).toPromise();

  }

  getHistoryStatusTransaction() {
    return new Promise((resolve, reject) => {
      const params = {
        name: 'accounts$accountstates$get',
        params: {
          request: {},
          path: {
            transactionNumber: this.transactionNumber
          },
          body: {}
        }
      };
      this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        resolve(response);
      }, error => {
        reject(false);
      });
    });
  }

  getStatusIncompleat() {

    const params = {
      name: 'account_opening$stage_sections_incompleted$get',
      params: {
        request: {
          role: this.localStorage.getItem('role'),
        },
        path: {
          workflow: this.workflowCode,
          stage: "ACCOUNT_CONTRACT_ANALYSIS",
          transactionNumber: this.transactionNumber,
          idPerson: this.transactionData.holder.identifier

        }
      }
    };

    this._httpService.executeRequest(params.name, params.params).subscribe((response: any) => {

      this.loadDatos = false;

      this.sectionIncompleats = response;

    }, error => {

      this.loadDatos = false;

      this.sectionIncompleats = [];

    });

  }

  ngOnDestroy() {
    this.akeelaBreadcrumbService.setbreadcrumbOptions([]);
    if (this.wfSubscription) {
      this.wfSubscription.unsubscribe();
    }
  }


  gotoNexUrl(stage, step) {
    const prefixed = "private";
    const queryParams: any = {
      role: this.localStorage.getItem('role'),
      audience: this.localStorage.getItem('audience'),
      profile: this.localStorage.getItem('profile'),
    };

    this.router.navigate([prefixed, this.workflowCode, 'stages', stage, 'steps', step], { queryParams });
  
    this.event_close();
  }



  redirectRequirementsUpload() {
    this.showModalResponses = false;
    const queryParams: any = {};

    this.router.navigateByUrl(this.router.createUrlTree
      ([environment.staticLinks.requirementsUpload],
      {
        queryParams: queryParams
      }
      )
    );
    this.event_close()
  }

  event_close(){

    
    this.close.emit(false);

  }


}
