import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class TabsService {

  config$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() { }

}
