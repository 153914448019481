import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PropertiesService } from '../../services/index';
import { AuthService} from "@akeela/auth";
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input() auth: string;
  @Output() langOut = new EventEmitter();
  lang_local: string;
  wo;
  /*VAR NOTIFY SERVICE*/
  notificacionesSub;
  msgs = [];

  //public _auth:AuthenticationService,public _properties : PropertiesService
  constructor(public _auth:AuthService, 
    public _properties : PropertiesService,
    private route: ActivatedRoute,
    private router: Router) {
   
  }

  ngOnInit() {

    this.route.queryParams.subscribe(queryParams => {
    
    if (!isNullOrUndefined(queryParams['wo'])) {
      this.wo = queryParams['wo'];

    }

    });


  }

  ngOnDestroy() {
    if (this.notificacionesSub) {
      this.notificacionesSub.unsubscribe();
    }
  }

  setLang(event): void {
    this.langOut.emit({ lang: event.lang });
  }


}
