

import { Component, OnInit, Input, Output, OnDestroy, OnChanges, SimpleChanges, DoCheck, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { Observable, BehaviorSubject, Subscription, timer } from 'rxjs';
import { AuthService } from '@akeela/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { TransitionService } from '../../../../services/transition.service';
import swal from 'sweetalert2';
import { I18nService, I18nPipe } from '@akeela/i18n';
import { RefreshService } from '../../services/refresh.service';
import { UploadService, ErrorService, PaginatorService } from '@akeela/common';
import { environment } from '../../../../../environments/environment';
import { SearchService } from '../../../../services/search.service';
import { isNullOrUndefined, log } from 'util';
import merge from 'lodash/merge'
import { LocalService } from '@akeela/local-storage';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';

import { AkeelaSidebarMenuService } from '@akeela/menu';
import { Table, Row, Column, Dropdown, DropdownList } from '@akeela/ak-table';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { DatePipe } from '@angular/common';
import { debug } from 'console';
import { DomSanitizer } from '@angular/platform-browser';
import { PropertiesService } from 'src/app/services';


@Component({
  selector: 'akeela-list-meet',
  templateUrl: './list-meet.component.html',
  styleUrls: ['./list-meet.component.css']
})
export class ListMeetComponent  implements OnInit, OnChanges, OnDestroy, DoCheck {

  static FilterDown = 'DOWN';
  static FilterUp = 'UP';
  params;
  queryParams;
  workflow;
  transaction_taken = null;

  reload = false;

  agencys: any;
  executives: any;

  listMeet: any = null;
  filter: any = {};
  filter_policys: any = {};
  statusTransaction: any = {};
  params_search = null;
  object_search = null;

  paginator: any = null;
  loading_table = true;

  env = environment;
  policys = null;
  policy = null;

  filter_order: any = {};

  query_params_subscriptor: Subscription;
  ColumnMode = ColumnMode;
  subscriptions: Subscription[] = [];
  isAnalist = false;
  list_users;
  username;
  role;
  indicatorsProgres = {};
  current_state = null;
  readonly = false;


  rows = [];
  selected = [];
  temp = [];
  columns = [];
  temp2;
  fieldSearch: any = '';
  anchorSearch = [];
  icon_1 = [];
  icon_2 = [];
  isLoading: boolean;
  profile;
  loadingIndicator = false;
  screenMax = true;
  timeElapsedSetInterval = {};
  timeElapsed = {};
  filters = {};
  formsData: any = {};
  formsDataResponse: any = {};

  boxFilters;
  limitRows = 10;
  totalGet = 9999999;

  stateCodeFilter = '';
  temporalOrder = [];
  countFilterTimeValue = 0;
  openFilter = false;
  animate_init = true;
  animate_init_bottom = true;
  filterEtique: any = [];
  numberSolicitud;

  transactionsBase: any = [];

  preview;
  loading = false;

  reload_loading = false;
  buttomModal;
  updateDate;
  filterBy = true;
  orderBy = false;

  timeReponseDesc = false;
  

  clientDetail;
  transactionDetail;

  clientSelected;
  transactionSelected;

  optVerification;

  indicators_progres = {};
  indicatorsLoading = true;
  indicatorsRowsLoading = true;
  indicatorsInit = true;

  history = {};
  historyData = [];
  historyDataSelect = {};

  indicatorsProgresTemp = {};
  transactionTemp= {};
  historyDataSelfi = {};
  fileAvatarPreview ={};
  interviewType=[];

  interviewTypeIcon = [];
  interviewsTime={};
  clock: any;
  interviewsObj= {};
  copy;

  _second = 1000;
  _minute = this._second * 60;
  _hour = this._minute * 60;
  _day = this._hour * 24;
  end: any;
  now: any;
  day: any;
  hours: any;
  minutes: any;
  seconds: any;
  source = timer(0, 1000);
  newInterview = false;
  userMeet;
  init_meet;
  init_meet_edit_transaction;
  showLink;
  superUser;

  constructor(
    private _httpService: HttpService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private _auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
      private i18n: I18nPipe,
    private refresh: RefreshService,
    private error: ErrorService,
    private localStorage: LocalService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    public config: NgbDropdownConfig,
    private datePipe: DatePipe,
    private _i18n: I18nPipe,
    private _upload: UploadService,
    private sanitizer: DomSanitizer,
    private paginatorService: PaginatorService,
    private _properties: PropertiesService,


  ) {
    this.localStorage.removeItem('objBackTransaction');

    let temp = this.getTimeDate(this.datePipe.transform(Date(), 'dd/MM/yyyy HH:mm'));
    this.updateDate = (temp);

    this.resetForms();
    config.placement = 'bottom-left';
    config.autoClose = true;


  }


  ngOnInit() {

    this.formsDataResponse = {
      type: '',
      description: ''
    }
    
    this.profile = this.localStorage.getItem('profile');
   
    this._akeelaSidebarMenu.setSidebarLeftActive('listMeet');
    this._properties.resetWks();



    this.akWorkflowService.transactionData$.next(null);
    this.role = this.localStorage.getItem('role');
    if (this.localStorage.getItem('profile') === 'ANALIST') {
      this.isAnalist = true;
    } else {
      this.isAnalist = false;
    }
    this.username = this._auth.getUsername();
    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      this.loading_table = true;
      this.queryParams = queryParams;

    }));


    this. getInterviewType();
    this.getUser();
  }

reloadTransaction(){
  this.updateDate =  null;

  this.reload_loading = true;

}



  public ngDoCheck() {

    if (screen.width < 769) {

      this.screenMax = false;
    } else {

      this.screenMax = true;

    }
  }




getListMeet() {

  

  let user = {};

  if(!this.superUser){
   user = { 'userame': this._auth.getUsername()}
  }
  
  const params = {
    name:  'interview$interviewUsersExeutive$get',
    params: {
      request: user,
      path: {
      },
      body: {}
    }
  };
  
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
   
    if(response){
        
      this.listMeet = response;


    this.paginator = {
      totalPages: Math.ceil(this.listMeet?.length / this.env.paginator.size),
      first: true,
      last: false,
      number: this.listMeet?.length,
      pageable: {
        pageNumber: 0,
        from: 1,
        to: this.listMeet?.length<this.env.paginator.size?this.listMeet?.length: this.env.paginator.size
      }
    };


       this.setConfigurationTableNew();
    }

    
   
  });


}


  //juan
  setConfigurationTableNew() {

    let listMeet = this.listMeet;
     // ejemplo.filter((r, i) => i < 10);
 
     let data = [];
     const content: Row[] = [];
     let datas = [];
 
 
     for (const meet of listMeet) {
 
       let row: any ;
       let response : any = [] ;
        row = meet;
 
         if(true){
        let date =row.dateOfThing?.modifiedDate? row.dateOfThing?.modifiedDate :  row.dateOfThing?.startDate; 
 
       const items = [];
       //row.plan.shortDescription
 
       let jsonTmp =
         {
           "typeName": this._i18n.transform((this.interviewType[row?.type])?.label),
           'description':row?.interview?.description,
           'status':row?.interview?.status,
           'type':row?.interview?.type,
           'interviewDateFecha':row?.interview?.interviewDate,
           'interviewDate':row?.interview?.interviewDate,
           'fechaInic':row?.interview?.dateOfThing?.startDate,
           "solicitud": row?.account?.transactionNumber,
           "transactionNumber": row?.account?.transactionNumber,
           "idTransaction": row?.account?.identifier,
           "plan": row?.account?.plan?.name,
           "username": row?.account?.requestUser?.identifier,
           "name": row?.account?.organization? row?.account?.organization?.legalName : (row?.account?.person?.givenName + ' ' + row?.account?.person ?.familyName ),
         }
          
       datas = [...datas, jsonTmp];
 

       this.setTimeInterview(jsonTmp);
 
     }
   }
 
      this.temp = [...datas];

     this.columns = [];
 
     let cont = 0;

     
    
  
     this.paginator.pageable.to = this.paginatorService.getPaginatorTo(this.paginator, this.env);
     if ( datas ?.length > 0 ) {
       datas = this.orderFilterDate(datas, 'interviewDate');

       data = this.paginatorService.getDataPaginator(this.paginator, this.env, datas);
       this.paginator.pageable.from = this.paginatorService.getPaginatorFrom(this.paginator, this.env);
       this.rows = data;
       this.getAvatars(this.rows);


     }

     this.setFilterMultiple();

     this.loading_table = false;
     this.reload_loading = false;
 
    
   }
 

  resetForms() {

    this.formsData = {

      interviewDate: { type: 'interviewDate', key: 'new' },
      ejecutivo: { type: 'string', key: '' },
      agencia: { type: 'string', key: '' },
      interviewDateFecha: { type: 'date', dateInit: '', dateEnd: '', delimit: '/' }
    }

    this.stateCodeFilter = '';
    this.filters = {};
   // this.updateFilters();

  };


  
  setFilterMultiple() {


    if (Object.keys(this.formsData).length > 0) {
      let objFilters = this.temp;

      for (const temp of Object.keys(this.formsData)) {

        let arr = this.formsData;

        this.setFilter(temp, this.formsData[temp]);

      };

      this.updateFilters();

    }


  }


  setFilter(name, obj) {

    this.filters[name] = obj;

  }

  updateFilters() {
  

 let data2 = [];
    // this.fieldSearch = '';

    if (Object.keys(this.filters).length > 0) {
      let objFilters = this.temp;

      for (const temp of Object.keys(this.filters)) {

        let type = this.filters[temp].type;

        if (type == 'date') {

          let { dateInit, dateEnd, delimit } = this.filters[temp];
          objFilters = [...this.filterDate(objFilters, temp, dateInit, dateEnd, delimit)];

        } else if (type == 'string') {

          let { key } = this.filters[temp];

          if (key?.trim() != '') {

            objFilters = [...this.filterSting(objFilters, temp, key)];
           

          }



        } 


        else if (type == 'interviewDate') {

          let { key } = this.filters[temp];

          if (key?.trim() != '') {

            objFilters = [...this.filterinterviewDate(objFilters, temp, key)];
          
          }

        } 
        
      }

      //objFilters =  this.orderFilterStatus(objFilters,'status2',(this.getStatusCode('AC_PENDING')).userName);

      data2 = objFilters;

    } else {

      let objFilters = this.temp;
      data2 = objFilters;

    }

    data2 = this.orderFilterDate(data2, 'interviewDate');


    this.rows  = this.paginatorService.getDataPaginator(this.paginator, this.env, data2);


    this.getAvatars(this.rows);


    this.filtersLinks();
  }


  setTablePaginators(){
    this.loading_table = true;

   let data = this.listMeet;
    

    this.loading_table = false;

    data =  this.orderFilterDate(data, 'interviewDate');
    data = this.paginatorService.getDataPaginator(this.paginator, this.env, data);

    this.paginator.number = this.listMeet?.length;
    this.paginator.totalPages = Math.ceil(this.listMeet?.length / this.env.paginator.size);
    this.paginator.pageable.to = this.paginatorService.getPaginatorTo(this.paginator, this.env);
    this.paginator.pageable.from = this.paginatorService.getPaginatorFrom(this.paginator, this.env);
    this.rows = data;
    this.getAvatars(this.rows);

  }



  filtersLinks() {

    //this.fieldSearch = '';
    let tempFilterEtique = [];

    if (Object.keys(this.filters).length > 0) {
      let objFilters = this.temp;

      for (const temp of Object.keys(this.filters)) {

        let tempObj = {};


        let type = this.filters[temp].type;

        if (type == 'date') {

          let { dateInit, dateEnd, delimit } = this.filters[temp];
          if (dateInit.trim() != '') {

            tempObj = { label: "Desde: " + dateInit, key: "dateInit", type }
            tempFilterEtique = [...tempFilterEtique, tempObj];

          }

          if (dateEnd.trim() != '') {

            tempObj = { label: "Hasta: " + dateEnd, key: "dateEnd", type }
            tempFilterEtique = [...tempFilterEtique, tempObj];



          }

        } else if (type == 'string') {
          let { key } = this.filters[temp];

          if (key.trim() != '') {
            let    label =   this.filters[temp].key;

            if(temp == 'completeIdentification'){
     
              label =   (label == 'true'?'Con ':'Sin ')  + 'verificación de identidad aprobada';
     
            }else if(temp == 'verifiedIdentification'){
     
             label =   (label=='true'?'Con ':'Sin ') + 'verificación de identidad completada';
     
           }else if(temp == 'confirm'){
     
             label =   (label=='true'?'Con ':'Sin ') + 'verificación de email';
     
           }else if(temp == 'solicitudes'){
     
             label =   (label=='true'?'Con ':'Sin ') + 'solicitudes';
     
           }
     
     
            
            tempObj = { label: label , key: temp, type }
            tempFilterEtique = [...tempFilterEtique, tempObj];

          }

        }
      }

      //objFilters =  this.orderFilterStatus(objFilters,'status2',(this.getStatusCode('AC_PENDING')).userName);


      this.filterEtique = [...tempFilterEtique];


    }

  }


  closeReset() {
    this.resetForms();
    this.boxFilters = false;
    //this.fieldSearch = '';
  }


  

  deleteFilter(key, type) {
    let filtered = this.filterEtique.filter(function(value, index, arr){ 
      return value.key != key;

  });


    if (type == 'date') {

      if (Object.keys(this.formsData).length > 0 && this.formsData["fecha"] && this.formsData["fecha"][key]) {
        this.formsData["fecha"][key] = "";
        this.updateFilters();

      }
    } else if (type == 'string') {


      if (Object.keys(this.formsData).length > 0 && this.formsData[key] && this.formsData[key].key) {
        this.formsData[key].key = "";
        this.updateFilters();

      }

    }


    this.filterEtique = [...filtered];


  }


  orderFilterStatus(obj: any = [], name, key) {


    let objs = obj.sort((a, b) => {

      if (a[name] == key) {

        return 1;

      } else if (a[name] != key) {

        return -1;

      }

      return 0;

    });


    const temp = objs.filter(function (d) {

      //  console.log("status: ", d['solicitud'], " ", d['status2']);

    });

    return objs;


  };


  filterDate(obj = [], name, dateInit, dateEnd, delimit) {

    if ((!dateInit || dateInit.trim() == '') && (!dateEnd || dateEnd.trim() == '')) {

      return obj;

    }

    let i = dateInit.split("-");
    let letDateInit: any = new Date(Number(i[0]), Number(i[1]) - 1, Number(i[2]));
    let f = dateEnd.split("-");
    let letDateEnd: any = new Date(Number(f[0]), Number(f[1]) - 1, Number(f[2]));

    const temp = obj.filter(function (d) {

      let fecha = d[name];
      let dateMaestro = fecha?.time?.split(" ");
      let m = dateMaestro[0].split(delimit);

      let letFecha: any;

      if (dateMaestro && dateMaestro.length > 1) {

        let x = dateMaestro[1].split(":");
        letFecha = new Date(Number(m[2]), Number(m[1]) - 1, Number(m[0]));

      } else {

        letFecha = new Date(Number(m[2]), Number(m[1]) - 1, Number(m[0]));

      }



      if ((name && letFecha instanceof Date) &&
        (dateInit && letDateInit instanceof Date) &&
        (dateEnd && letFecha instanceof Date)) {


        return (letFecha.getTime() >= letDateInit.getTime() && letFecha.getTime() <= letDateEnd.getTime());


      } else if ((name && letFecha instanceof Date) &&
        !(dateInit && letDateInit instanceof Date) &&
        (dateEnd && letFecha instanceof Date)) {

        return (letFecha.getTime() <= letDateEnd.getTime());


      } else if ((name && letFecha instanceof Date) &&
        (dateInit && letDateInit instanceof Date) &&
        !(dateEnd && letFecha instanceof Date)) {

        return (letFecha.getTime() >= letDateInit.getTime());

      }

    });

    return temp

  }

  countFilterSting(obj = [], name, key) {

    let objFilters = [...this.filterSting(obj, name, key)];

    return objFilters.length;

  }




  filterSting(obj = [], name, key) {

    const val = key?.toString()?.toLowerCase();

    let temp = obj.filter(function (d) {
      let value = d[name];
      value = value?.toString()?.toLowerCase();

     let result =  typeof value === 'string' && value.indexOf(val) !== -1;

     return result;

    });


    return temp

  }


  filterinterviewDate(obj = [], name, key) {


    let temp = obj.filter((d) => {
      let value = d[name];
      let result;
    
          let temp2 = this.toDateStringTimeFilter(value);
            let end = new Date(temp2);
            let  now = new Date();

            if(key == 'new'){

              result = end > now;

            }else{

              result = end < now;

            }
        

     return result;

    });


    return temp

  }
  
  


  filterRange(obj = [], name, min,max) {
    const temp = obj.filter(function (d) {
      let value = d[name];

    
      return  value >= min && value <= max ;

    });

    return temp

  }

  



  ngOnChanges(changes: SimpleChanges) {

    for (const propName in changes) {

      if (changes.hasOwnProperty(propName)) {
        // switch (propName) {
        //   case 'code': {
        //     if (!isNullOrUndefined(changes[propName].currentValue)) {
        //       this.code = changes[propName].currentValue;
        //       this.getWorkflowDetail();
        //     }
        //   }
        // }
      }
    }
  }





  getPreview(transactionNumber, audience) {

    const params = {
      name: 'account_opening$preview-document$get',
      params: {
        request: {
          role: 'CLIENT',
          audience: audience
        },
        body: {},
        path: {
          transactionNumber: transactionNumber
        }
      }
    };
    this.wait();
    
    this._httpService.executeRequest(params.name, params.params, true).subscribe((resp: any) => {
      if(resp.length>0) {
        this.show_filePreview(resp[0]);
        swal.close();
      } else {
        swal.fire({
          title: '',
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: false,
          html: 'Mensaje:<br><b>Ficha no disponible</b><br>',
        });
      }
      this.preview = resp;
    }, error => {
      this.loading = false;
      this.preview = [];
      swal.close();
    });
  }

  show_filePreview(data) {
    this.show_file(data);
  }

  show_file(file) {


    const archive = {
      identifier: file.identifier,
      name: file.name,
      dateCreated: {
        orderDate: file.dateCreated.modifiedDate
      }
    };


    if (archive.identifier !== null) {
      this._upload.visor_params.name = '';
      this._upload.visor_params.date = '';
      this._upload.visor_params.endpoint = 'archive$downloadFile$get';
      this._upload.visor_params.archive = archive;
      this._upload.visor = true;
    }
  }

  action_paginator(page) {
    this.loading_table = true;
    if (page === 'NEXT') {
      page = this.paginator.pageable.pageNumber + 1;
      if (page >= this.paginator.totalPages) {
        page = this.paginator.pageable.pageNumber;
      }
    } else if (page === 'BACK') {
      page = this.paginator.pageable.pageNumber - 1;
      if (page < 0) {
        page = this.paginator.pageable.pageNumber;
      }
    }
    this.paginator.pageable.pageNumber = page;
    this.paginator.first = (page === 0 ? true : false);
    this.paginator.last = ((page + 1) === this.paginator.totalPages ? true : false);

    this.setTablePaginators();
    this.getAvatars(this.rows);

  }



  getPerson(identifier) {
    const params = {
      name: 'person$person$get',
      params: {
        request: { id: identifier },
        path: {},
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }


  confirm(title, text) {
    return swal.fire({
      title: title,
      html: text,
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    });
  }


  getTransactionData(transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role,
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }







  wait() {
    swal.fire({
      title: '',
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false,
      html: '<b>' + this.i18n.transform('@i18n-text-please-wait') + '...<b><br><i class="fa fa-spin fa-spinner"></i>',
    });
  }



  ngOnDestroy() {
    if (this.query_params_subscriptor) {
      this.query_params_subscriptor.unsubscribe();
    }
    if (this.subscriptions) {
      // prevent memory leak when component destroyed
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }
  
  //HERE
  updateFilter(data) {

    this.boxFilters = false;
   let data2 = [];
    
    this.loadingIndicator = true;
    const val = !isNullOrUndefined(data) ? String(data).toLowerCase() : null;

    if (!isNullOrUndefined(val)) {
      // filter our data
      let arrayBase = this.temp;

      const temp = arrayBase.filter(function (d) {

        let filter = false;
        let tempAnchor = false;

        for (let clave in d) {
          let value = d[clave];

          if (!filter) {

            filter = String(value).toLowerCase().indexOf(val) !== -1 || !val;

          }
        }

        return filter;

      });

      // update the rows
      data2  = temp;
    } else {
      data2 = this.temp;
    }

    data2 = this.orderFilterDate(data2, 'interviewDate');

    this.rows  = this.paginatorService.getDataPaginator(this.paginator, this.env, data2);


    // Whenever the filter changes, always go back to the first page
    //this.table.offset = 0;

    this.loadingIndicator = false;

  }


  setDelete(i) {

    delete this.anchorSearch[i];
    this.anchorSearch = [...this.anchorSearch.filter(el => el != null)];
    this.fieldSearch = null;
    this.updateFilter(null);

  }


  getInitials(givenName: string, familyName: string) {
    return (
      (givenName ? givenName.substr(0, 1).toUpperCase() : '') +
      (familyName ? familyName.substr(0, 1).toUpperCase() : '')
    );
  }

  getInitial(text) {
    let retorno = '';
    if (!isNullOrUndefined(text)) {
      retorno = text.substr(0, 1).toUpperCase();
    }
    return retorno;
  }


  round(value) {

    if(value){
      return Math.round(value);

    }
    return 0;

  }

  setClientDetail(e) {
    this.localStorage.removeItem('objContactSetView');
    if (!isNullOrUndefined(e.open)) {
      this.clientDetail = false;
      if (e.open === 'transaction') {
        this.localStorage.setItem('objBackTransaction', JSON.stringify({username: this.clientSelected}));
        this.transactionSelected = e.value;
        this.transactionDetail = true;
        this.openTransaction(e.value);
      }
    } else {
      this.clientDetail = e;
    }
  }

  setClient(client) {
    this.clientDetail = true;
    this.clientSelected = client;
  }


  openTransaction(transaction, reset?) {
    this.transactionDetail = false;
    if(!isNullOrUndefined(reset)) {
      this.localStorage.removeItem('objBackTransaction');
    }
    this.transactionSelected = transaction;
    this.transactionDetail = true;
  }



getAvatars(datas) {

  let temp = {};
  let endUsers = [];
  
    for (const row of   datas ) {

      temp[row?.username] = row?.username;
    
    }

   for (const prop in temp) {
      endUsers = [...endUsers, {"identifier": prop}]
    }

  const params = {
    name: 'person$selfies_data_identities$post',
    params: {
      request: {},
      path: {},
      body: {
        endUsers :endUsers
        
      }
    }
  };

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

    if (response) {

        for (const row of  response ) {

          if(row?.image){
                this.fileAvatarPreview[row?.alternateName] = 'data:image/jpg' + ';base64,' + row?.image?.description;
          
          }
        }
      }

  }, error => {
    this.fileAvatarPreview=[];
  });



}



setHistoryData(row,action= '', actionDescription= ''){
    ;
      this.get_dataAndSelfi(row);


    let  temp =  {
      "username": row?.username,
      "name":row?.name,
      "solicitud":row?.solicitud,
      "date": this.datePipe.transform(Date(), 'dd/MM/yyyy HH:mm'),
      "action": action,
      "actionDescription":actionDescription
      };


  this.setLocalHistoryDatas(temp);

  this.historyDataSelect[row?.solicitud] = 'selected';

}


get_dataAndSelfi(row) {

  const params = {
    name: 'person$selfie$get',
    params: {
      request: {
        'username': row?.username
      },
      path: {},
      body: {}
    }
  };


  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

    if(response?.description){
      this.historyDataSelfi[row?.solicitud] = 'data:image/jpg' + ';base64,' + response?.description ;

    }

  });

}





sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}



show_file2(file) {

  file = { identifier: file };
  const archive = {
    identifier: file.identifier,
    name: file.name,
    dateCreated: {
      orderDate: null
    }
  };

  if (file.dateCreated) {
    archive['dateCreated']['orderDate'] = file.dateCreated.modifiedDate;
  } else {
    archive['dateCreated']['orderDate'] = null;
  }

  if (archive.identifier !== null) {
    this._upload.visor_params.name = '';
    this._upload.visor_params.date = '';
    this._upload.visor_params.endpoint = 'archive$downloadFile$get';
    this._upload.visor_params.archive = archive;
    this._upload.visor_params.deleteAfterShow = '';
    if (file['action'] === 'download') {
      this._upload.visor_params.action = 'download';
      this._upload.visor_params.deleteAfterShow = {
        deleteRequirement: file['deleteAfterShow']
      };
      this._upload.visor = false;
      setTimeout(() => {
        this._upload.visor = true;
      }, 6);
    } if (file['action'] === 'downloadzip') {

      if (this.env.theme.images === 'FACEBANK') {
        this._upload.visor_params.endpoint = 'archive$downloadZipFile$get';
      }

      this._upload.visor_params.action = 'download';
      this._upload.visor_params.deleteAfterShow = {
        deleteRequirement: file['deleteAfterShow']
      };
      this._upload.visor = false;
      setTimeout(() => {
        this._upload.visor = true;
      }, 6);
    } else {
      this._upload.visor_params.action = '';
      this._upload.visor = false;
      setTimeout(() => {
        this._upload.visor = true;
      }, 6);
    }
  }
}


getUser() {
  const params = {
    name: 'person$enduser$get',
    params: {
      request: {
        'username': this._auth.getUsername()
      },
      path: {},
      body: {}
    }
  };
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
   
    if(response?.viewAll){
      this.superUser = true;

     this.localStorage.setItem('superExecutive',true);
    
    }else{
      this.superUser = false;

      this.localStorage.setItem('superExecutive',false);

    }

    
    if(response?.url){
     this.history = JSON.parse(response.url);
     if(this.history['historyMeet']){

      this.setHistoryDataInit(this.history['historyMeet']);

     }else{
      this.history['historyMeet'] = [];

     }

    }

    this.getListMeet();

  });

}


setHistoryDataInit(datas){

  this.historyDataSelect = {};
  this.historyDataSelfi = {};
  this.historyData = [];


  if(datas){
  let results = [];

  for (const temp of Object.keys(datas)) {

    let row:any = datas[temp];

      results = [...results,row];
      this.get_dataAndSelfi(row);
      this.historyDataSelect[row?.solicitud] = 'selected';

  }

  this.historyData = results;

    
}

}


sliceArray(temp){

  ;
   let sizeArr = temp?.length;
    let min = 30;   
 let result = temp;

    if(sizeArr > min){
    
      result = temp.slice(0,min);

    }


   return result ;
   
 }


setLocalHistoryDatas(row){
  let temp =  [];
  let values = this.historyData;

  if(values){
    temp = [...values];
  }

  temp = [row,...temp];
  temp = [...this.sliceArray(temp)]         ;


  this.history['historyMeet'] = [...temp];
 
  const params: any = {
    name: 'person$view_history$post',
    params: {
      request: {},
      path: {
        userName: this._auth.getUsername(),
      },
      body: {
        description: JSON.stringify(this.history)
      }
    }
  };
  

  this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
  
    this.historyData = [...temp];

  }, error => {


  }));
  
}




getTimeDate(date){

  let meses = ["Ene", "Febr", "Mar", "Abr", "May", "Jun", "Jul", "Agos", "Sept", "Oct", "Nov", "Dic"];


    let dateMaestro = date.split(" ");
    let dato = dateMaestro[0].split("/");
    let dato2 = dateMaestro[1].split(":");

    let dia = dato[0];
    let mes =  dato[1];
    let año = dato[2];
    let hora = dato2[0];
    let minutos = dato2[1];


    let hoy = this.datePipe.transform(Date(), 'dd/MM/yyyy HH:mm');

     dateMaestro = hoy.split(" ");
     dato = dateMaestro[0].split("/");
     dato2 = dateMaestro[1].split(":");

    let dia2 = dato[0];
    let mes2 =  dato[1];
    let año2 = dato[2];
    let hora2 = dato2[0];
    let minutos2 = dato2[1];

    let time;

    if(parseInt(año2) > parseInt(año) ){

      time  = dia+'/'+mes+'/'+año;

    }else if( parseInt(mes2) > parseInt(mes) || parseInt(dia) != parseInt(dia2)){

      time  = `${dia}  ${meses[mes - 1]}  <span class="color-aaa"> ${hora}:${minutos} <span>`;

    }else {

       time  = hora +':'+ minutos;

    } 

    return { value:time,time: date}
  }


  setTransactionDetail(e) {
    this.transactionDetail = false;
    this.localStorage.removeItem('objContactSetView');
    if (!isNullOrUndefined(e.open)) {
      this.clientDetail = false;
      if (e.open === 'contact') {
        this.localStorage.removeItem('objBackTransaction');
        this.localStorage.setItem('objContactSetView', 2);
        this.setClient(e.value);
      }
    } else {
      this.transactionDetail = e;
    }
  }


  showDate(idTransaction){
    let distance = this.interviewsTime[idTransaction]['end'] - this.interviewsTime[idTransaction]['now'];
    this.interviewsTime[idTransaction]['day'] = Math.floor(distance / this._day);
    this.interviewsTime[idTransaction]['hours']  = Math.floor((distance % this._day) / this._hour);
    this.interviewsTime[idTransaction]['minutes']  = Math.floor((distance % this._hour) / this._minute);
    this.interviewsTime[idTransaction]['seconds']  = Math.floor((distance % this._minute) / this._second);
  }

   epoch (value) {

    let date =  this.parseDateString(value);
    return +date;

  }

   parseDateString(date:string): Date {
    date = date.replace('T','-');
    let parts:any = date.split('-');
    let timeParts = parts[3].split(':');
    
   // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[0], parts[1]-1, parts[2], timeParts[0], timeParts[1]); // Note: months are 0-based

 }


  redirectMeet(url:any = false){

    let data  = 'https://meet.jit.si/meet-'+this.env.theme.images+'-'+this.clientDetail?.identifier;
   
    if(url){
      data = url;
    }
   
    // Abrir nuevo tab
    var win = window.open(data, '_blank');
    // Cambiar el foco al nuevo tab (punto opcional)
    win.focus();

}


setNewInterview(idTransaction:any = ''){
  if(idTransaction){
    this.setformTransaction(idTransaction);

  }else{

    this.formsDataResponse = {
      interviewDate: '',
      description: '',
      idTransaction:'',
      modifyUser:this._auth?.getUsername(),
      type:'3'
    }
  
  }

  this.newInterview = true;
}

setCopy(text){
  navigator.clipboard.writeText(text);
  this.copy =  true;
}

setShare(url,row:any =false){

  if (navigator.share) {

    let text = this.env?.theme?.images +" Video LLamada.";

    if(row){
      text = this.env?.theme?.images +". Fue programada una reunion de tipo ( "+ this.i18n.transform(row?.tipo)+") en fecha "+ this.datePipe.transform(row?.fecha, 'd MMM, y h:mm a');
    }

    if(url){
      navigator.share({  text: text , url: url });

    }else{
      navigator.share({text: text});

    }

  }


}


 convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;   
  
}

 toDateString(fecha): string {

 let temp2 = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16);

//  let temp = new Date(fecha).toISOString().slice(0, 16); 
     let date:Date = this.parseDateString(temp2);

  return (("0" + (date.getDate())).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' +date.getFullYear().toString())
   + ' ' + date.toTimeString().slice(0,5);
}


 toDateStringTime(fecha): string {
  let temp = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16); 
  let date:Date = this.parseDateString(temp);

  let result = (  ("0" + (date.getMonth() + 1)).slice(-2)  + '/' + ("0" + (date.getDate())).slice(-2)  + '/' +date.getFullYear().toString())
  + ' ' + date.toTimeString().slice(0,5);
  return result;
}


toDateStringTimeFilter(fecha): string {
  let temp = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16); 
  let date:Date = this.parseDateString(temp);

  let result = (  ("0" + (date.getMonth() + 1)).slice(-2)  + '/' + ("0" + (date.getDate())).slice(-2)  + '/' +date.getFullYear().toString())
  + ' ' + date.toTimeString().slice(0,5);
  return result;
}


setformTransaction(idTransaction){

  let temp = this.interviewsObj[idTransaction];

  if(temp){


  //let fecha =this.datePipe.transform(temp?.interviewDate, 'd-M-yyTh:mm');

  let fecha = this.convertUTCDateToLocalDate(new Date(temp?.interviewDate)).toISOString().slice(0, 16);
    this.formsDataResponse['interviewDate'] =  fecha;
    this.formsDataResponse['type'] =  temp?.type;
    this.formsDataResponse['description'] =  temp?.description;
    this.formsDataResponse['idTransaction'] =  idTransaction;
  }

}


  setTimeInterview(response){
    this.interviewsTime[response?.idTransaction]= {
      now:'',
      end:'',
      day:'',
      hours:'',
      minutes:'',
      seconds:'',
      valid:''
    }
  
    this.clock = this.source.subscribe(t => {
      this.interviewsTime[response?.idTransaction]['now'] = new Date();
    //  this.end = new Date('01/01/' + (this.now.getFullYear() + 1) +' 00:00');

    let temp = this.toDateStringTime(response?.interviewDate);
   // let temp2 = '01/01/' + (this.interviewsTime[response?.idTransaction]['now'].getFullYear() + 1) +' 00:00';

    this.interviewsTime[response?.idTransaction]['end'] = new Date(temp);
    this.interviewsTime[response?.idTransaction]['valid'] = this.interviewsTime[response?.idTransaction]['end'] > this.interviewsTime[response?.idTransaction]['now'];
     // this.interviewsTime[response?.idTransaction]['end'] = this.toDateString(response?.interviewDate);
    if(this.interviewsTime[response?.idTransaction]['valid']){
      this.showDate(response?.idTransaction);
    }

    });
  }



  getInterviewType() {    
    const params = {
      name: 'workflow_manager$attributes_options$get',
      params: {
        request: {},
        path: { 
          code: 'interviewType'
        },
        body: {}
      }
    };
    
  
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      let nameItem = '';

      if(response.length){


        this.interviewType = [];
        for (let value of response) {
          nameItem = value.item.alternateName;
        if(nameItem){

          this.interviewType[nameItem] =   {value:nameItem, label: value.name} ;

        }
                  


        }}
    
      });

  }




  orderFilterDate(obj: any = [], name) {

    let objs = obj.sort((a, b) => {

      let dateMaestro = this.toDateString(b[name])?.split(" ");
      let m = dateMaestro[0].split("/");
      let date: any = [];

      if (dateMaestro[1]) {

        let x = dateMaestro[1].split(":");
        date = new Date(Number(m[2]), Number(m[1]) - 1, Number(m[0]), Number(x[0]), Number(x[1]));

      } else {

        date = new Date(Number(m[2]), Number(m[1]) - 1, Number(m[0]));

      }

      let dateMaestro2 = this.toDateString(a[name])?.split(" ");
      let m2 = dateMaestro2[0].split("/");
      let date2: any = [];

      if (dateMaestro[1]) {

        let x2 = dateMaestro2[1].split(":");
        date2 = new Date(Number(m2[2]), Number(m2[1]) - 1, Number(m2[0]), Number(x2[0]), Number(x2[1]));

      } else {

        date2 = new Date(Number(m2[2]), Number(m2[1]) - 1, Number(m2[0]));

      }

      if(this.timeReponseDesc){

      if (date.getTime() < date2.getTime()) {

        return 1;

      }  

      if (date.getTime() > date2.getTime()) {



        return -1;

      }

      }else{

      if (date.getTime() > date2.getTime()) {

        return 1;

      }
      if (date.getTime() < date2.getTime()) {



        return -1;

      }


      }
      

      return 0;

    });

  
      
    return objs;

  }



setMeetData(e) {
  if(e){
    this.init_meet = false;
    this.userMeet = null;
    setTimeout(() => {
    this.setClient(e);
   }, 500);


  }else{
    this.init_meet = false;
    this.userMeet = null;
  }
    
}


setOpenMeet(username,transaction){

    this.userMeet = username;
    this.init_meet= true;
    this.init_meet_edit_transaction = transaction;

}

togle_window(filter) {

  if(filter == 1){
    this.filterBy = true;
    this.orderBy = false;
  
  }else{

    this.filterBy = false;
    this.orderBy = true;

  }

  this.animate_init = false;
  this.openFilter = !this.openFilter;

}


orderAscDesc(val){

  this.timeReponseDesc = val;
  this.setFilterMultiple();

}

}
