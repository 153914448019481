import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
// import { RequestOptions, ResponseContentType } from "@angular/http";
import { AuthService} from "@akeela/auth";
import { Observable, BehaviorSubject } from "rxjs";
import { PathService } from "@akeela/properties";
import { CommonService, ErrorService } from '@akeela/common';
import { LocalService } from '@akeela/local-storage';

@Injectable()
export class PropertiesService {
  showMenu=false;

  base_url;
  public productIdentifier;
  public productIdentifierBoolean: boolean = false;


  updateClient$: BehaviorSubject<boolean> = new BehaviorSubject(false);



  
  setUpdateClient(updateClient) {
    this.updateClient$.next(updateClient);
  }


  constructor(
    public _common: CommonService,
    public localStorage: LocalService,

    private _http: HttpClient, private _path: PathService, private _auth: AuthService, ) {
    this.base_url = this._path.getServicesIp();
    if (this._auth.isAuthenticated() && screen.width > 767) {
      this.SetShowMenu(true);
    } else {
      this.SetShowMenu(false);
    }
    this.SetShowMenu(false);
  }


  resetWks() {

    this.localStorage.removeItem('queue');
   this.localStorage.removeItem('workspace');
    this._common.setWorkspaceCode(null);
    this._common.workspaceCode$.next(null);
    }
  

  SetShowMenu(param: boolean) {
    this.showMenu = param;
  }


  getCurrency() {
    let path = "app_properties$getCurrency$get";
    return new Observable(resolve => {
      this._path.getPathService(path).subscribe((resp: any) => {
        let url = resp.url;
        return this._http
          .get(url, { responseType: "text" })
          .subscribe(resultado => {
            resolve.next(resultado);
            resolve.complete();
          }, (error) => {
            resolve.error(error);
          });
      });
    });
  }

  getDecimaleScale() {
    let path = "app_properties$getDecimalScale$get";
    return new Observable(resolve => {
      this._path.getPathService(path).subscribe((resp: any) => {
        let url = resp.url;
        return this._http
          .get(url, { responseType: "text" })
          .subscribe(resultado => {
            resolve.next(resultado);
            resolve.complete();
          }, (error) => {
            resolve.error(error);
          });
      });
    });
  }

  getProperty(options: any) {
    return new Observable(resolve => {
      this._path.getPathService(options.endpointKey).subscribe((resp: any) => {
        let url = resp.url;
        const params = {
          request: {
            key: options.propertyKey
          }
        };
        return this._http
          .get(url, {
            headers : null,
            params: {
              key: options.propertyKey
            },
            responseType: "text"
          })
          .subscribe(resultado => {
            resolve.next(resultado);
            resolve.complete();
          }, (error) => {
            resolve.error(error);
          });
      });
    });
  }
  


 toDateString(fecha): string {
  let temp2 = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16);
 
 //  let temp = new Date(fecha).toISOString().slice(0, 16); 
      let date:Date = this.parseDateString(temp2);
   return (("0" + (date.getDate())).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' +date.getFullYear().toString())
    + ' ' + date.toTimeString().slice(0,5);
 }


 convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;   
  
}
 


private parseDateString(date:string): Date {
  date = date.replace('T','-');
  let parts:any = date.split('-');
  let timeParts = parts[3].split(':');
  
 // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1]-1, parts[2], timeParts[0], timeParts[1]); // Note: months are 0-based

}


  toDateStringTime(fecha): string {
    let temp = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16); 
    let date:Date = this.parseDateString(temp);

    return (  ("0" + (date.getMonth() + 1)).slice(-2)  + '/' + ("0" + (date.getDate())).slice(-2)  + '/' +date.getFullYear().toString())
    + ' ' + date.toTimeString().slice(0,5);
  }



  toDateTime(fecha) : Date{
    let temp = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16); 
    return  this.parseDateString(temp);
  }



}
