<!-- <app-workspaces></app-workspaces> -->

<div class="mt-1  animated fadeIn">
    <div class="row">
        <div class="col-12 col-md-6 order-2 order-md-1">
            <h3>Solicitudes</h3>

        </div>
        <!-- <div class="col-md-6 font-weight-bold order-1 order-md-2 ">
        <app-search (response)="updates_transactions($event, true)"></app-search>
        <div class="d-block d-md-none">
          <br>
          <br>
        </div>
      </div> -->

    </div>
    <br>
    <div class="w-100 box skin-white">
        <!--nav-fill-->
        <app-tabs></app-tabs>


        <div class="row">

            <div class="col-6">
                <!-- <ng-container *ngIf="statusTransaction.filterActive">
            <div class=" pull-right">
              <!-- <app-filter-status 
                [current]="statusTransaction.current"
                (response)="applyFilterStatus($event)"
                ></app-filter-status> -->
                <!--</div>
          </ng-container> -->
            </div>

            <div class="col-6">
                <div class="d-block d-md-none text-right">
                    <div class="btn-group dropleft ">
                        <!-- <app-filter-order 
              [filter]="filter"
              (response)="orderJsonByObject($event)"></app-filter-order> -->
                    </div>
                </div>
            </div>
        </div>


        <ng-container *ngIf="transactions">
            <akeela-line-loading *ngIf="loading_table"></akeela-line-loading>
            <div class="">
                <div class="w-100 fz-body d-none d-md-block">
                    <div class="d-flex bd-highlight active-cursor  list-flex flex-wrap font-title">


                        <div style="" class="mt-04 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-25  w-sm-80 order-2 order-md-2  ">

                            Solicitante

                            <!-- <app-filter [id]=0 [config]="filter" (response)="applyFilter($event)" *ngIf="filter"></app-filter> -->

                        </div>

                        <div style="" class=" mt-04 mb-md-0 bd-highlight  pl-md-2 pr-md-2 w-10  w-sm-100  order-4 order-md-3">

                            Solicitud

                            <!-- <app-filter [id]=1 [config]="filter" (response)="applyFilter($event)" *ngIf="filter"></app-filter> -->

                        </div>

                        <div style="" class=" mt-04 mb-md-0 bd-highlight  pl-md-2 pr-md-2 w-25  w-sm-100  order-4 order-md-3">

                            Estatus

                            <!-- <app-filter [id]=1 [config]="filter" (response)="applyFilter($event)" *ngIf="filter"></app-filter> -->

                        </div>


                        <div style="" class=" mt-04 mb-md-0 ml-auto bd-highlight text-left pl-md-2 pr-md-2 w-15  w-sm-40  order-3 order-md-4">
                            Recibido
                            <!-- <app-filter [id]=2 [config]="filter" (response)="applyFilter($event)" *ngIf="filter"></app-filter> -->
                        </div>


                        <div style="" class=" mt-04 mb-md-0 ml-auto bd-highlight  pl-md-2 pr-md-2 w-15  w-sm-50  order-5 order-md-5">
                            Última actualización
                            <!-- <app-filter [id]=3 [config]="filter" (response)="applyFilter($event)" *ngIf="filter"></app-filter> -->

                        </div>

                        <div class="mt-04 mb-md-0 text-md-right w-5 pl-md-2 pr-md-2 order-6 d-none d-md-block ">

                        </div>
                        <div class="mt-04 mb-md-0 text-md-right w-5 pl-md-2 pr-md-2 order-6 d-none d-md-block ">

                        </div>


                    </div>
                </div>
                <hr class="mt-025 mb-0  d-none d-md-block">

                <ng-container *ngIf="transactions.length>0">
                    <ng-container *ngFor="let row of transactions; let first = first; let i_stf = index">
                        <hr *ngIf="!first" class="mt-025 mb-0  d-none d-md-block">
                        <div class="box-pp  w-100 fz-body " [class.column-disabled]="loading_table">

                            <div class="d-flex bd-highlight active-cursor  list-flex flex-wrap">
                                <div (click)="openFormalities(row.transactionNumber)" class="d-none d-md-block mb-md-0 text-md-left  mt-sm-1 pl-md-2 pr-md-2  w-5 w-sm-10 order-md-1 order-1">
                                    <div class="DomainUserAvatar-avatar Avatar--color-{{ getInitial(row?.holder?.name) }} AvatarPhoto AvatarPhoto--small" role="img">
                                        {{ getInitial(row?.holder?.name) }}
                                    </div>
                                </div>

                                <div (click)="openFormalities(row.transactionNumber)" class="text-right mt-04 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-20  w-sm-60 order-2 order-md-2  ">
                                    <div class="row">
                                        <div class="order-2 order-md-1 text-left" [class.col-12]="!row.taken" [class.col-10]="row.taken">{{ row?.holder?.name }}&nbsp;
                                            <i class="fas fa-times-circle text-color-red" *ngIf="row.accountStatus[0].additionalType==='AC_REJECTED'"></i>
                                            <i class="fas fa-check-circle text-color-green" *ngIf="row.accountStatus[0].additionalType==='AC_RECEIVED'"></i>
                                            <i class="fas fa-flag text-color-orange" *ngIf="row.accountStatus[0].additionalType==='AC_INCOMPLETE'"></i>
                                        </div>
                                        <!-- <div class="order-1 order-md-2" [class.col-2]="row.taken" *ngIf="row.taken">
                        <i class="fa fa-lock" [class.icon-red]="transaction_taken!==null && transaction_taken===row.transactionNumber" aria-hidden="false"></i>
                      </div> -->
                                    </div>
                                </div>




                                <div (click)="openFormalities(row.transactionNumber)" style="" class=" mt-04 mb-md-0 bd-highlight  pl-md-2 pr-md-2 w-10  w-sm-100  order-5 order-md-3">
                                    <div>
                                        <span class="d-block d-md-none">
                        <i class="fa fa-reply iconRotate"></i>
                        &nbsp; {{ '#'+row?.transactionNumber }}
                      </span>
                                        <span class="d-none d-md-block">
                        {{ '#'+row?.transactionNumber }}
                      </span>
                                    </div>
                                </div>

                                <div style="" class=" mt-04 mb-md-0 bd-highlight  pl-md-2 pr-md-2 w-25  w-sm-100  order-4 order-md-3">

                                    {{ row?.accountStatus[0].userName }}

                                    <!-- <app-filter [id]=1 [config]="filter" (response)="applyFilter($event)" *ngIf="filter"></app-filter> -->

                                </div>


                                <div (click)="openFormalities(row.transactionNumber)" style="" class="text-right mt-04 mb-md-0 ml-auto bd-highlight text-left pl-md-2 pr-md-2 w-15  w-sm-40  order-3 order-md-4">

                                    <div class="justifyText">
                                        {{ row?.dateOfThing?.orderDate | datePipe: 'simple' }}
                                    </div>
                                </div>


                                <div (click)="openFormalities(row.transactionNumber)" style="" class="mt-04 mb-md-0 ml-auto bd-highlight text-right  pl-md-2 pr-md-2 w-15  w-sm-50  order-5 order-md-5">
                                    <div class="d-block d-md-none" style="font-size:10px; color:#BDBDBD">
                                        Última Actualización:
                                        <br> {{ row?.dateOfThing?.modifiedDate | datePipe: 'simple' }}
                                    </div>

                                    <span class="d-none d-md-block justifyText">
                      {{ row?.dateOfThing?.modifiedDate | datePipe: 'simple' }}
                    </span>

                                </div>
                                <div class="mt-04 mb-md-0 text-md-right w-5 pl-md-2  order-6  text-center">
                                    <!-- && row.holder.modifyUser===usuario_session -->
                                    <div class="d-none d-md-block">
                                        <i class="fa fa-trash pull-right" aria-hidden="true" *ngIf="row.accountStatus[0].additionalType==='PI_IN_PROCESS' && row?.requestUser?.person?.identifier===usuario_session" (click)="confirmDelete(row)"></i>
                                    </div>
                                </div>
                                <div (click)="openFormalities(row.transactionNumber)" class="mt-04 mb-md-0 text-md-right w-5 pl-md-2 pr-md-2 order-6 d-none d-md-block text-right">
                                    <i class="fas fa-angle-right text-primary"></i>
                                </div>


                            </div>
                        </div>

                    </ng-container>

                </ng-container>

                <ng-container *ngIf="transactions.length===0">
                    <div class="text-center font-weigth-bold">
                        <br> NO HAY INFORMACIÓN PARA MOSTRAR
                        <br>
                        <hr class="d-none d-md-block">
                    </div>
                </ng-container>
                <!-- <hr  class="mt-025 mb-0  d-none d-md-block">-->

                <hr class="mt-025 mb-0">
            </div>


            <br>
            <div class="row content-paginator" *ngIf="true">
                <akeela-paginator [paginator]="paginator" (response)="action_paginator($event)" *ngIf="paginator">
                </akeela-paginator>
            </div>
            <br>


        </ng-container>


        <ng-container *ngIf="!transactions">
            <div>
                <img class="w-50 " src="./assets/public/images/loading.gif" alt="">
            </div>
        </ng-container>












        <!--<ng-container *ngIf="!reload">
              <akeela-entity-table [emptymsj]="'<b>NO HAY Solicitudes EN COLA</b>'" [identifier]="'user-table'" [style]="'table-default'" (callFn)="callFn($event)" [params]="params" [readonly]="'false'" *ngIf="params"></akeela-entity-table>
          </ng-container>-->
        <br>

    </div>
</div>