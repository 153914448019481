import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { AuthService } from '@akeela/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { RefreshService } from '../../services/refresh.service';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { TransitionService } from '../../../../services/transition.service';
import { environment } from '../../../../../environments/environment';
import { LocalService } from '@akeela/local-storage';

@Component({
  selector: 'app-private-paperwork',
  templateUrl: './paperwork.component.html',
  styleUrls: ['./paperwork.component.css']
})
export class PaperworkComponent implements OnInit, OnDestroy {
  workflow;
  stage;
  step;
  formalities: any = null;
  queryParams;
  active_redirect = false;
  url_transition = 'workflow$transitionssteps$get';
  trans_subscriber;
  route_subscriber;
  wf_events_subscriber;
  cargando = '';
  role;


  constructor(
    public _httpService: HttpService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private akSectionService: AkeelaSectionService,
    private _auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private akeelaSectionService: AkeelaSectionService,
    private refresh: RefreshService,
    private akeelaBreadcrumbService: AkeelaBreadcrumbService,
    private _transition: TransitionService,
    private localStorage: LocalService

  ) { }

  ngOnInit() {
    this.role = this.localStorage.getItem('role');
    this.refresh.refresh.next(null);
    this.akWorkflowService.worflowEvents$.next(null);
    this.workflow = this.akWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akStageService.currentStage$.getValue();
    this.step = this.akStepService.currentStep$.getValue();
    this.akeelaSectionService.addModalSection({ name: 'PROD_PLAN_SELECTOR_MODAL' });
    this.akWorkflowService.setTransactionNumber(null);

    this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });

    this.getTransactions();

    this.wf_events_subscriber = this.akWorkflowService.worflowEvents$.subscribe((response: any) => {
      if (response) {
        if (response.action === 'account_opening$account_response$post') {
          if (response.response.transactionNumber && !this.active_redirect) {
            this.akeelaSectionService.hideModalSection('PROD_PLAN_SELECTOR_MODAL');
            this.active_redirect = true;
            const aux = Object.assign({ transactionNumber: response.response.transactionNumber }, this.queryParams);

            const parameters = {
              name: this.url_transition,
              params: {
                request: {
                  role: 'A',
                  profile: 'USER',
                  audience: '',
                  event_code: 'PRODUCT_PLAN_SELECTED_EXECUTIVE'
                },
                path: {
                  prefixWorkflow: 'workflow_manager',
                  workflow: this.workflow,
                  stage: this.stage,
                  step: this.step
                }
              },
              o: {}
            };
            this._transition.next('private', parameters, aux);
            this.destroySubscribers();
            // this.router.navigateByUrl(
            //   this.router.createUrlTree(['private/ACCOUNT_OPENING/stages/SUMMARY_ACCOUNT_OPENING/steps/SUMMARY_ACCOUNT_OPENING'],
            //     {
            //       queryParams: aux
            //     }
            //   )
            // );
          }
        }
      }
    });
  }

  getTransactionData(transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role,
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  getTransactions() {
    const params = {
      name: 'account$myformalities$get',
      params: {
        request: {},
        path: {
          user: this._auth.getUsername()
        },
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.formalities = response;
    });
  }

  getPerson(identifier) {
    const params = {
      name: 'person$person$get',
      params: {
        request: { id: identifier },
        path: {},
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  getOrganization(identifier) {
    const params = {
      name: 'person$organization$get',
      params: {
        request: { id: identifier },
        path: {},
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  openFormalities(transactionNumber) {
    this._transition.showWorkflow$.next(false);
    this.cargando = transactionNumber;
    
    this.akWorkflowService.setTransactionNumber(transactionNumber);
    this.getTransactionData(transactionNumber).subscribe((data: any) => {
      this.getPerson(data.holder.identifier).subscribe((response: any) => {
        this.redirect(transactionNumber, 'NATURAL');
      }, (error: any) => {
        this.redirect(transactionNumber, 'LEGAL');
      });
    });

    this.cargando = '';
  }

  redirect(transactionNumber, audience) {
    const queryParams = {
      profile: this.localStorage.getItem('profile'),
      role: this.localStorage.getItem('role'),
      audience: audience,
      transactionNumber: transactionNumber
    };



    this.router.navigateByUrl(
      this.router.createUrlTree([environment.staticLinks.detailClientResumen],
        {
          queryParams: queryParams
        }
      )
    ).then(() => {
      this.destroySubscribers();
    });

  }

  destroySubscribers() {
    if (this.trans_subscriber) {
      this.trans_subscriber.unsubscribe();
    }
    if (this.route_subscriber) {
      this.route_subscriber.unsubscribe();
    }
    if (this.wf_events_subscriber) {
      this.wf_events_subscriber.unsubscribe();
    }
  }

  new() {
    this.akeelaSectionService.showModalSection('PROD_PLAN_SELECTOR_MODAL');
  }

  ngOnDestroy() {
    if (this.trans_subscriber) {
      this.trans_subscriber.unsubscribe();
    }
    if (this.route_subscriber) {
      this.route_subscriber.unsubscribe();
    }

    if (this.wf_events_subscriber) {
      this.wf_events_subscriber.unsubscribe();
    }
  }

}
