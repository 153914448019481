<app-template>
    <ng-template app-content componentName="mainContentApp">
        <div class="full-modal-wf" [ngStyle]="{'z-index': 1000}" *ngIf="objEdit">
            <div class="header">
                <span class="float-lg-right">
            <i class="fas fa-times" (click)="close()"></i>
          </span>
            </div>
            <div class="body">
                <div class=" ">
                    <div class=" m-t-1 p-b-2">
                        <form [formGroup]="formGroup" novalidate>
                            <div class="col-12 col-lg-8 offset-lg-2">
                                <label class="font-weight-bold" style="word-wrap: break-word;">{{ objEdit.url | i18n }}</label>
                                <div class="w-100">
                                    <textarea class="form-control" formControlName="description"></textarea>
                                    <div class="">
                                        <div class="w-100">
                                            <div *ngIf="!formGroup.get('description').valid" class="form-control-feedback help-block">
                                                Campo requerido.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-100 m-t-1">
                                    <button type="button" class="btn col-md-3 offset-md-9 btn-outline-primary" [disabled]="!formGroup.get('description').valid" (click)="saveEdit()">Guardar</button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>

        </div>
    </ng-template>
</app-template>

<div class="row " *ngIf="show">

    <div class="col-12">

        <div class=" mb-2 mt-3">
            <a (click)="comment()" href="javascript:void(0)" class="font-weight-bold text-primary">
                <span class="fas fa-info-circle pr-"></span> Agregar Comentarios específicos
            </a>
        </div>

        <div style="padding-left: 14px">

            <ng-container *ngIf="comments!==null">
                <ng-container *ngIf="comments.length>0">
                    <br>
                    <br>

                    <ng-container *ngFor="let row of comments; let first = first; let i_stf = index">
                        <div class="row pt-1 pull-right">
                            <div class="col-8 fz-body">
                                <span class="font-weight-bold">{{ row.url | i18n }}</span>
                            </div>
                            <div class="col-4 fz-body text-right">

                                <div class="btn-group">
                                    <!-- <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle" data-toggle="dropdown">
                            <i class="fas fa-ellipsis-h"></i>
                        </button> -->
                                    <a class="dropdown-toggle active-cursor" data-toggle="dropdown">
                                        <span class="color-link active-cursor">Selecciona</span>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-right">

                                        <li style="padding-left: 12px">
                                            <a class="active-cursor" (click)="deletes(row?.identifier)">{{ 'Eliminardddd' | i18n }}</a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- <div class="btn-group">
                    <a class="dropdown-toggle active-cursor" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                      <span class="color-link active-cursor">Selecciona</span>
                    </a>
                    <div class="dropdown-menu">
                      <button class="dropdown-item fz-body" type="button" (click)="edit(row)">Editar</button>
                      <button class="dropdown-item fz-body" type="button" (click)="delete(row?.identifier)">Eliminar</button>
                    </div>
                  </div> -->
                            </div>
                        </div>

                        <!--
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" href="#" (click)="edit(row)">Editar</a>
                      <a class="dropdown-item" href="#" (click)="deletes(row?.identifier)">Eliminar</a>
  
                    </div>
              -->
                        <div class="row">
                            <div class="col-8 fz-body">
                                <span>{{ row.description }}</span>
                            </div>
                            <div class="col-4 fz-body text-right">
                                <span style="font-size:0.7rem" *ngIf="row.modifyUser">{{ '@'+row.modifyUser }}</span>
                            </div>

                        </div>
                        <div class="row">

                            <div class="col-12 fz-body text-right">
                                <span style="font-size:0.7rem" *ngIf="row.modifyUser">{{ row.dateOfThing.startDate | datePipe: 'simple' }}</span>
                            </div>

                        </div>
                        <hr class="mt-025 mb-0">
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="comments===null">
                <div class="row">
                    <div class="col-12 text-center">
                        <div>
                            <img class=" float-left" src="./assets/public/images/loading.gif" alt="">
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>









</div>