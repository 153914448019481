<app-template>
    <ng-template app-content componentName="mainContentTransactionModalBotton">
        <div class="full-modal-2 compleat-modal" style="height: 100vh!important;" *ngIf="showModalResponses">
            <div class="header active-cursor" style="text-align:right">
                <div class="float-right" (click)="showModalResponses=! showModalResponses">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i>

                </div>
                <div class="pr-2 pl-2 pb-2 float-left">
                    <div class=" pt-1">
                        <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>
                    </div>
                </div>
            </div>
            <div class="body " style="overflow:auto">
                <ak-responses [transaction]="transactionData?.transactionNumber" (close)="showModalResponses = false"></ak-responses>

            </div>
        </div>
    </ng-template>

    <ng-template app-content componentName="mainContentTransactionModalBotton">
        <ng-container *ngIf="mdActve">
            <div class="capa-menu-2" *ngIf="buttomModal " (click)="changes()"> </div>
            <div [ngClass]="{'d-none':animate_init_bottom,'animate__slideInUp':buttomModal,animate__slideOutDown:!buttomModal } " class="  animate__animated animate__faster ak-modal-botoom ak-modal-botoom2  p-2 ">



                <div class="body pb-4 pt-2 " style="overflow:auto;">
                    <div class="container text-center">
                        <div class=" w-100 text-center ">

                            <div class="w-100 row ">
                                <div class="col-12 col-md-10 offset-md-1 ">

                                    <div class=" row-eq-height">
                                        <div class=" card border-0 pr-4" *ngIf="back">

                                            <div class="text-left">
                                                <i class="fas fa-angle-left text-secondary active-cursor  fa-2x" (click)="setBack()"></i>
                                            </div>
                                        </div>
                                        <div class="fz-12 w-90 text-left" (click)="returnToClient()">
                                            <div class="font-weight-bold text-capitalize">
                                                <div *ngIf="clientDetail?.personMarketType?.identifier==='NATURAL'"> {{ clientDetail.person.givenName + ' ' + clientDetail.person.familyName }} </div>
                                                <div *ngIf="clientDetail?.adminOrganization?.legalName"> {{clientDetail?.adminOrganization?.legalName }} </div>

                                            </div>

                                            <div classtrue="text-secondary">
                                                <div *ngIf="!isLegal()">{{dataInformation?.identity}}</div>
                                                <div *ngIf="isLegal()">{{dataInformation?.taxID}}</div>

                                            </div>

                                            <div class="text-secondary mt-1  ">
                                                <span class="font-weight-bold">Sol #{{ _transaction }}</span> ,

                                                <span [innerHTML]="(transactionData?.accountStatus[0].dateOfThing.startDate | date: 'd MMM, y h:mm a')"></span>

                                            </div>

                                        </div>



                                        <div class="floar-right" (click)="openFormalities(transactionData?.transactionNumber) ">
                                            <button class="  btn btn-primary btn-sm">
                                                <span *ngIf="profile != 'ANALIST' &&  (transactionData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS' || transactionData?.accountStatus[0]?.additionalType == 'AC_INCOMPLETE')">
                                                    Editar     <i  class="fas fa-edit"></i>  
                                                </span>
    
                                                <span *ngIf="profile == 'ANALIST' || (transactionData?.accountStatus[0]?.additionalType != 'PI_IN_PROCESS' && transactionData?.accountStatus[0]?.additionalType != 'AC_INCOMPLETE')">
                                                    Ver     <i  class="fas fa-eye"></i>  
                                                </span>
                                               
                                          </button>
                                        </div>

                                    </div>



                                </div>


                                <div class="col-12 col-md-10 offset-md-1 mt-1 ">
                                    <hr class="">
                                </div>



                            </div>




                        </div>



                        <div [ngClass]="{'alert alert-secondary':dataInfoClientLA || loadingdataInfoClientLA,' alert alert-warning':!dataInfoClientLA && !loadingdataInfoClientLA}" *ngIf="verificationLA && dataInfoClientLA" class="font-weight-normal alert alert-secondary bg-white mt-3 mb-2 p-2 col-12 offset-md-2 col-md-8 text-left fz-14"
                            role="alert">

                            <div *ngIf="loadingdataInfoClientLA" class="text-center w-100">
                                cargando ...
                                <div>

                                    <img style="width:20px ;" src="./assets/public/images/loading.gif " alt=" ">
                                </div>
                            </div>

                            <div *ngIf="!loadingdataInfoClientLA" class="mb-2">
                                <div *ngIf="dataInfoClientLA  && !getdataInfoClientLAError">

                                    Registrado en <b>LA Sistemas</b> como: {{dataInfoClientLA?.givenName}} {{dataInfoClientLA?.familyName}}, C.I. {{dataInfoClientLA?.identity}} Contacto 1: {{dataInfoClientLA?.telephone}}, Contacto 2: {{dataInfoClientLA?.contactPoint?.telephone}},
                                    Email: {{dataInfoClientLA?.contactPoint?.email}}, Código en LA Sistemas: {{dataInfoClientLA?.externalCode}}, Fecha de creación: {{dataInfoClientLA?.dateOfThing?.startDate | date: 'd MMM, y'}}<span *ngIf="false">
                                     ,Fecha de última modificación:{{dataInfoClientLA?.dateOfThing?.modifiedDate | date: 'd MMM, y'}}
                                    </span>



                                </div>
                                <!--
                                <div *ngIf="!dataInfoClientLA && !getdataInfoClientLAError">
                                    No se encontraron resultados en <b>LA Sistemas</b> asociados a este contacto
    
                                </div>
     -->
                                <div *ngIf="getdataInfoClientLAError">
                                    Error al consultar el cliente en <b>LA Sistemas</b>

                                    <button class="float-right btn btn-sm btn-secondary ml-2" (click)="getDataInfoClientLA(dataInformation?.identity)">
                                      Refrescar
                                    </button>
                                </div>
                            </div>
                            <!--
    
                            <pre *ngIf="!loadingdataInfoClientLA">{{ dataInfoClientLA | json }}</pre>
        -->

                        </div>



                        <div [ngClass]="{'alert alert-secondary':dataInfoClientAKB || loadingdataInfoClientAKB,' alert alert-warning':!dataInfoClientAKB && !loadingdataInfoClientAKB}" *ngIf="verificationAKB && dataInfoClientAKB" class="font-weight-normal alert alert-secondary bg-white mt-3 mb-2 p-2 col-12 offset-md-2 col-md-8 text-left fz-14"
                            role="alert">

                            <div *ngIf="loadingdataInfoClientAKB" class="text-center w-100">
                                cargando ...
                                <div>

                                    <img style="width:20px ;" src="./assets/public/images/loading.gif " alt=" ">
                                </div>
                            </div>

                            <div *ngIf="!loadingdataInfoClientAKB" class="mb-2">
                                <div *ngIf="dataInfoClientAKB  && !getdataInfoClientAKBError">
                                    Registrado en <b>Akbrokerage</b> como: {{dataInfoClientAKB?.givenName}} {{dataInfoClientAKB?.familyName}},
                                    <span> C.I.</span> {{dataInfoClientAKB?.identity}}
                                    <span *ngIf="dataInfoClientAKB?.telephone"> Contacto: </span> {{dataInfoClientAKB?.telephone}},
                                    <span *ngIf="dataInfoClientAKB?.contactPoint?.telephone"> Contacto : </span> {{dataInfoClientAKB?.contactPoint?.telephone}}, Email: {{dataInfoClientAKB?.email}}, Código en Akbrokerage: {{dataInfoClientAKB?.externalCode}},
                                    Fecha de creación: {{dataInfoClientAKB?.externalDate | date: 'd MMM, y'}}
                                </div>
                                <!-- 
                                <div *ngIf="!dataInfoClientAKB && !getdataInfoClientAKBError">
                                    No se encontraron resultados en <b>AKB Sistemas</b> asociados a este contacto
    
                                </div>
    -->
                                <div *ngIf="getdataInfoClientAKBError">
                                    Error al consultar el cliente en <b>AKB Sistemas</b>

                                    <button class="float-right btn btn-sm btn-secondary ml-2" (click)="getDataInfoClientAKB(dataInformation?.identity)">
                                      Refrescar
                                    </button>
                                </div>
                            </div>
                            <!--
    
                            <pre *ngIf="!loadingdataInfoClientAKB">{{ dataInfoClientAKB | json }}</pre>
        -->

                        </div>




                        <div class=" mt-3 mb-3 text-center">

                            <div class="  fz-14 font-weight-bold mt-1" [innerHTML]="transactionData?.plan?.name"></div>

                            <div [innerHTML]="transactionData?.plan?.shortDescription | i18n" class="fz-14"></div>
                            <div class="mt-2 mb-2">
                                <span [innerHTML]="transactionData?.accountStatus[0]?.userName | i18n" class="badge badge-pill badge-secondary fz-12"></span>

                            </div>

                        </div>

                        <div class="mt-4">
                            <div class=" w-100 text-center ">
                                <div class="d-flex  justify-content-top flex-wrap">
                                    <div class="w-100">
                                        <ul class="scrollmenu pl-0 mb-0 ">
                                            <li class="d-inline">
                                                <div class="item-rd active-cursor" (click)="setView('transaction')">
                                                    <div [class.border-verification-secondary]="!currentView.includes('transaction')" [class.border-verification-primary]="currentView.includes('transaction')" class=" DomainUserAvatar-avatar text-primary  AvatarPhoto AvatarPhoto--opt  active-cursor " role="img">
                                                        <i class="fas fa-align-left"></i>
                                                    </div>
                                                    <div class=" mt-2 text-primary fz-14">Solicitud</div>
                                                </div>
                                            </li>
                                            <li class="d-inline">

                                                <div (click)="showModalResponses = true" class="item-rd active-cursor">
                                                    <div class=" DomainUserAvatar-avatar text-secondary  AvatarPhoto AvatarPhoto--opt  active-cursor " role="img">

                                                        <i class="fas fa-comments"></i>
                                                        <i *ngIf="transactionData?.accountStatus[0]?.additionalType !='PI_IN_PROCESS' && transactionData?.accountStatus[0]?.additionalType !='AC_PENDING'" class="noti-circle blink_me text-danger fas fa-circle "></i>

                                                    </div>
                                                    <div class=" mt-2 text-secondary fz-14"> Respuesta y comentarios</div>
                                                </div>
                                            </li>
                                            <li class="d-inline">
                                                <div class="item-rd active-cursor">
                                                    <div [class.border-verification-secondary]="!currentView.includes('requirements')" [class.border-verification-primary]="currentView.includes('requirements')" class=" DomainUserAvatar-avatar text-secondary  AvatarPhoto AvatarPhoto--opt  active-cursor "
                                                        role="img">
                                                        <i class="fas fa-people-arrows"></i>
                                                    </div>
                                                    <div class=" mt-2 text-secondary fz-14">Personas</div>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-12 col-md-10 offset-md-1 pt-4 text-center" *ngIf="currentView==='transaction'">
                            <ng-container *ngIf="transactionData && preview">



                                <ng-container>

                                    <div class="row">
                                        <div class="col-12 col-md-10 offset-md-1">
                                            <div class="card text-left ">
                                                <div class="card-body">
                                                    <div class="" (click)="show_filePreview()">
                                                        <akeela-preview-doc [identifier]="preview[0]?.identifier"></akeela-preview-doc>
                                                    </div>
                                                </div>
                                                <div class="card-footer text-muted">
                                                    Ficha </div>
                                            </div>
                                        </div>
                                    </div>

                                </ng-container>
                            </ng-container>
                        </div>

                        <div class="w-100">
                            <div class="row" *ngIf="firstAnalystRevision?.firstAnalystRevisionFlag">
                                <div class="col-12 col-md-8 offset-md-2 pt-4 text-center">

                                    <div class=" p-2 font-weight-normal alert alert-secondary  mt-2 mb-2  col-12  text-left fz-14">
                                        <div class="mb-1">
                                            <span class="fz-12 float-right"> {{firstAnalystRevision?.firstAnalystRevisionDate | date: 'd MMM, yyyy'}} </span>

                                            <span class="font-weight-bold">Analista de Riesgo</span>
                                        </div>
                                        <div class=" ">
                                            <span class="text-secondary">{{firstAnalystRevision?.firstAnalystRevisionUsername}}</span>
                                            <span *ngIf="firstAnalystRevision?.firstAnalystRevisionComments"> {{firstAnalystRevision?.firstAnalystRevisionComments}}. </span>


                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="_auth.getSession()?.business_unit == 'BU_RIES' && !firstAnalystRevision?.firstAnalystRevisionFlag ">
                                <div class="mt-4  text-left  mb-3">
                                    <h5 class="font-weight-bold">Respuesta</h5>
                                </div>

                                <div class="mt-4  text-left  mb-3">

                                    <!--Seleccion ejecutivo  -->
                                    <div class="mb-3 form-group text-left mt-4 ">
                                        <label class="font-weight-bold pb-2" style="word-wrap: break-word;">Estatus de la respuesta</label>

                                        <select class="form-control" [(ngModel)]='checkreviewed.option'>
                                              <option value=''>Seleccionar</option>
                                              <option value='1'>Revisado</option>
                                        </select>
                                    </div>

                                    <div class="mb-2 text-left form-group ">
                                        <label class="font-weight-bold pb-2" style="word-wrap: break-word;">Observaciones</label>

                                        <textarea style="min-height: 150px;" class="form-control" [(ngModel)]='checkreviewed.description'> </textarea>
                                    </div>

                                </div>

                                <div class="mt-4  text-right  mb-3">

                                    <div class="mt-3 text-right">
                                        <button [disabled]="checkreviewed?.option == ''" (click)="setFirstAnalystRevFlag()" class="btn btn-primary mt-2 mb-3 ">
                                                                Responder
                                        </button>
                                    </div>
                                </div>

                            </ng-container>


                            <div class="col-12 col-md-10 offset-md-1 pt-4">

                                <ng-container *ngIf="isResponseEjecutive || isResponseAnalist">
                                    <div class="mt-4  text-left  mb-3">
                                        <h5 class="font-weight-bold">Respuesta
                                            <!--
                                            <button [ngClass]="{'btn-light': !viewOptional,'btn-primary':viewOptional}" (click)="viewOptional = !viewOptional" class="m-2 mb-2 btn   btn-sm">
                                                <span>Ver Opcionales</span>
                                            </button>
                                             -->
                                        </h5>
                                    </div>



                                    <div class="text-secondary font-weight-normal alert alert-secondary mt-2 mb-2 p-2 col-12  text-left fz-14">
                                        Para responder la solicitud, debe dar respuesta a <b>todos los recaudos</b> obligatorios.
                                    </div>

                                    <div class="mt-4  text-left  mb-3">

                                        <!--Seleccion analista  -->
                                        <div class="mb-3 form-group text-left mt-4 " *ngIf="isResponseAnalist">
                                            <label class="font-weight-bold pb-2" style="word-wrap: break-word;">Estatus de la respuesta</label>
                                            <select class="form-control" [(ngModel)]='responseData.option'>
                                                 <option value=''>Seleccionar</option>
                                                 <option [value]="row?.code" *ngFor="let row of optionsResponses; let first = first; let i_stf = index">{{row?.name | i18n}}</option>
        
                                            </select>
                                        </div>

                                        <!--Seleccion ejecutivo  -->
                                        <div class="mb-3 form-group text-left mt-4 " *ngIf="isResponseEjecutive">
                                            <label class="font-weight-bold pb-2" style="word-wrap: break-word;">Estatus de la respuesta</label>

                                            <select class="form-control" [(ngModel)]='responseData.option'>
                                                  <option value=''>Seleccionar</option>
                                                  <option value='1'>Aprobar</option>
                                                  <option value='2'>Devolver</option>
                                            </select>
                                        </div>

                                        <div class="mb-3 mt-3 text-left card p-3 ak-shadow-box" *ngIf="responseData?.option == 'AC_INCOMPLETE' || responseData?.option == 2">

                                            <div class="mb-3">
                                                Secciones completadas y revisadas
                                            </div>

                                            <!-- {{ sectionsIncompleat | json }} -->
                                            <div *ngFor="let section of sectionsIncompleat; let i = index" class="mt-2">

                                                <label class="container-checkbox ">
                                                    <small  class="conditions">
                                                        {{section?.sectionTitle | i18n}}        
                                                    </small>
                                                    <input autocomplete="off" type="checkbox" [checked]="section.check" (change)="section.check = !section.check"/>
                                                    <span class="checkmark"></span>
                                                </label>


                                            </div>

                                            <div class="mt-2">
                                                <label class="container-checkbox ">
                                          
                                                <span class="conditions">
                                                    Datos de Cliente
                                                </span>
                                                <input autocomplete="off" type="checkbox" [checked]="clientDataResp" (change)="clientDataResp = !clientDataResp"/>

                                                <span class="checkmark"></span>
                                            </label>
                                            </div>



                                        </div>



                                        <div class="mb-2 text-left form-group ">
                                            <label class="font-weight-bold pb-2" style="word-wrap: break-word;">Observaciones</label>

                                            <textarea style="min-height: 150px;" class="form-control" [(ngModel)]='responseData.description'> </textarea>
                                        </div>

                                    </div>

                                    <div class="mt-4  text-right  mb-3">

                                        <div class="mt-3 text-right">
                                            <button [disabled]="responseData?.option == ''" (click)="submitResponse()" class="btn btn-primary mt-2 mb-3 ">
                                                                    Responder
                                                                </button>
                                        </div>
                                    </div>



                                    <div class="mt-2 p-3 ">
                                        <label class="font-weight-bold pb-2" style="word-wrap: break-word;">Recaudos anexos a la solicitud</label>
                                        <app-requirements-response [viewOptional]="viewOptional" [isActiveResume]="false"></app-requirements-response>

                                    </div>



                                </ng-container>
                                <div *ngIf="transactionData?.accountStatus[0]?.additionalType == 'AC_RECEIVED'" class="floar-right w-100" (click)="editReque = !editReque">
                                    <button class="  btn btn-outline-primary btn-sm">
                                        <span *ngIf="!editReque">
                                             Editar recaudos   <i  class="fas fa-edit"></i>  
                                        </span>
    
                                        <span *ngIf="editReque">
                                            Ver recaudos    <i  class="fas fa-eye"></i>  
                                        </span>
                                       
                                  </button>
                                </div>

                                <ng-container *ngIf="!isResponseAnalist && !isResponseEjecutive && !editReque ">

                                    <div class="mt-4 card p-3 ak-shadow-box">

                                        <div class="col-12 col-md-6 offset-md-3">
                                            <div class="bd-highlight w-100 w-sm-100 pt-2">
                                                <form>
                                                    <div class="form-group">
                                                        <select class="form-control form-control-sm" (change)="applyFilter($event.target.value)">
                                                        <option value=''>-- seleccione--</option>
                                                        <option value='all' selected>Todos</option>
                                                        <ng-container *ngFor="let requirement of requirements">
                                                            <option [value]='requirement.requirement.identifier'>{{ requirement.requirement.name }}</option>
                                                        </ng-container>
                                                    </select>
                                                    </div>
                                                </form>
                                            </div>
                                            <app-transaction-requirements-preview [transaction]="_transaction" [filter]="filterRequirements" (listRequirements)="setRequirementsList($event)" (response)="setTransactionData($event)" *ngIf="_transaction">
                                            </app-transaction-requirements-preview>
                                        </div>
                                    </div>

                                </ng-container>


                                <ng-container *ngIf="editReque">

                                    <div class="mt-2 p-3 ">
                                        <label class="font-weight-bold pb-2" style="word-wrap: break-word;">Recaudos anexos a la solicitud</label>
                                        <app-requirements-response [viewOptional]="viewOptional" [isActiveResume]="false"></app-requirements-response>

                                    </div>
                                </ng-container>




                                <div class="mt-5">


                                    <div class="pt-2 fz-14 d-flex bd-highlight  list-flex flex-wrap text-left size-caja-dopd">

                                        <div class="w-100">

                                            <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                <div class="w-100   text-capitalize">
                                                    <div class="font-weight-bold  fz-14  ">Ejecutivo / {{transactionData?.channelAgency?.name}}</div>
                                                    <div class="text-secondary  fz-14  ">{{clientDetail?.salesManagerUser?.identifier}}</div>

                                                </div>

                                            </div>
                                        </div>





                                    </div>

                                    <div class=" text-left" *ngIf="currentView==='requirements'">
                                        <!-- <app-transaction-requirements-preview [transaction]="_transaction" [filter]="filterRequirements" (listRequirements)="setRequirementsList($event)" (response)="setTransactionData($event)" *ngIf="_transaction">
                                        </app-transaction-requirements-preview> -->
                                    </div>
                                    <div class=" text-left" *ngIf="currentView==='comments'">

                                    </div>
                                </div>



                            </div>
                        </div>



                    </div>
                </div>
            </div>

        </ng-container>


    </ng-template>
</app-template>