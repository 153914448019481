import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from '@akeela/properties';
import {
  AkeelaWorkflowService,
  AkeelaStageService,
  AkeelaStepService,
  AkeelaSectionService
} from '@akeela/workflow';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs';
import { LocalService } from '@akeela/local-storage';
import { ErrorService } from '@akeela/common';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';




@Component({
  selector: 'app-section-other-legal-representative',
  templateUrl: './section-other-legal-representative.component.html',
  styleUrls: ['./section-other-legal-representative.component.css']
})
export class SectionOtherLegalRepresentativeComponent implements OnInit, OnDestroy {
  workflow;
  stage;
  step;
  transactionNumber;
  transactionData;
  itemList: any = [];
  queryParams;
  readonlyMode = false;
  subscriptions: Subscription[] = [];
  role;
  form: FormGroup;
  lang;
  load;
  nationalitys = null;
  document_types = null;


  private transactionDataSubscription: Subscription;
  private workflowEventsSubscription: Subscription;
  private requestSubscription: Subscription;

  constructor(
    public _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private route: ActivatedRoute,
    private akeelaSectionService: AkeelaSectionService,
    private i18n: I18nPipe,
    private localStorage: LocalService,
    private formBuilder: FormBuilder,
    private errorService: ErrorService

  ) { }

  ngOnInit() {
    this.getNationalitys();
    this.getLegalForms();

    this.form = this.formBuilder.group({
      familyName: ['', [Validators.required]],
      name: ['', [Validators.required]],
      nationality: ['', [Validators.required]],
      type: ['', [Validators.required]],
      identity: ['', [Validators.required]]

    });

    this.akeelaWorkflowService.worflowEvents$.next(null);
    this.role = this.localStorage.getItem('role');

    this.subscriptions.push(this.akeelaStepService.currentStepMode$.subscribe((readonly) => {
      this.readonlyMode = readonly;
    }));


    this.workflow = this.akeelaWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akeelaStageService.currentStage$.getValue();
    this.step = this.akeelaStepService.currentStep$.getValue();
    this.transactionData = this.akeelaWorkflowService.transactionData$.getValue();

    this.subscriptions.push(this.akeelaWorkflowService.transactionNumber.subscribe(transactionNumber => {
      if (transactionNumber) {
        this.transactionNumber = transactionNumber;
      }
    }));


    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;

    }));

  }

  getLegalForms() {
    const params = {
      name: 'workflow_manager$attributes_options$get',
      params: {
        request: {
          audience: 'ALL'
        },
        path: {
          code: 'typeIdentity'
        },
        body: {}
      }
    };

    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.document_types = response;
    });

  }

  getNationalitys() {
    const params = {
      name: 'app_properties$nationalitys$get',
      params: {
        request: {},
        path: {},
        body: {}
      }
    };

    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.nationalitys = response;
    });
  }

  getRelated() {

    let item = this.form.getRawValue();

    const params = {
      name: 'core_integration$consultarRepresentanteLegal$get',
      params: {
        request: {
          idPais: item.nationality,
          idTipoDoc: item.type,
          numeroDocumento: item.identity

        },
        path: {},
        body: {}
      }
    };


    this.load = true;

    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      if (!isNullOrUndefined(response) && response !== 0) {


        let itemList = {
          name: item.name,
          lastName: item.familyName,
          code: response,
        }

        this.itemList = [itemList];



        this.load = false;

        let legalrepresentative: any = {
          "legalRepresentative": {
            "person": {
              "identity": item.identity,
              "additionalType": null,
              "nationality": {
                "identifier": item.nationality
              },
              "externalCode": response,
              "familyName": item.familyName,
              "givenName": item.name,
              "relatedType": {
                "additionalType": "LEGAL_REPRESENTATIVE"
              },
              "email": "",
              "condition": {
                "identifier": 1
              },
              "jobTitle": {
                "name": "Financial Manager"
              }
            },

            "applicantType": "NATURAL"
          }
        };


        this.localStorage.setItem('ak-add-json', JSON.stringify(legalrepresentative));


        this.form.reset();


      } else {
        //        title: this.i18n.transform('Algo salió mal!'),

        swal.fire({
          html: this.i18n.transform('Representante Legal no encontrado')
        });

        this.itemList = [];

      }
      this.load = false;


    }, error => {
      this.load = false;
      this.errorService.show(error);
    }));




  }


  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
    if (this.transactionDataSubscription) {
      this.transactionDataSubscription.unsubscribe();
    }
    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
  }

}
