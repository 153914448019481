import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { AuthService } from '@akeela/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { RefreshService } from '../../services/refresh.service';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { TransitionService } from '../../../../services/transition.service';
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from 'util';
import { I18nPipe } from '@akeela/i18n';
import { LocalService } from '@akeela/local-storage';
import swal from 'sweetalert2';


@Component({
  selector: 'app-paperwork-user',
  templateUrl: './paperwork-user.component.html',
  styleUrls: ['./paperwork-user.component.css']
})
export class PaperworkUserComponent implements OnInit, OnDestroy {

  workflow;
  stage;
  step;
  transactions: any = null;
  queryParams;
  active_redirect = false;
  url_transition = 'workflow$transitionssteps$get';
  trans_subscriber;
  route_subscriber;
  wf_events_subscriber;
  cargando = '';
  loading_table = true;
  paginator: any = null;

  usuario_session = '';

  role;



  constructor(
    public _httpService: HttpService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private akSectionService: AkeelaSectionService,
    private _auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private akeelaSectionService: AkeelaSectionService,
    private refresh: RefreshService,
    private akeelaBreadcrumbService: AkeelaBreadcrumbService,
    private _transition: TransitionService,
    private _i18n: I18nPipe,
    private localStorage: LocalService
  ) { }

  ngOnInit() {
    this.role = this.localStorage.getItem('role');
    this.refresh.refresh.next(null);
    this.akWorkflowService.worflowEvents$.next(null);
    this.workflow = this.akWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akStageService.currentStage$.getValue();
    this.step = this.akStepService.currentStep$.getValue();
    this.akeelaSectionService.addModalSection({ name: 'PROD_PLAN_SELECTOR_MODAL' });
    this.akWorkflowService.setTransactionNumber(null);
    this.localStorage.removeItem('clientaudience');
    this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });

    this.usuario_session = this._auth.getUsername();


    this.getTransactions(this._auth.getUsername(), environment.paginator.page, environment.paginator.size);

    this.wf_events_subscriber = this.akWorkflowService.worflowEvents$.subscribe((response: any) => {
      if (response) {
        if (response.action === 'account_opening$account_response$post') {
          if (response.response.transactionNumber && !this.active_redirect) {
            this.akeelaSectionService.hideModalSection('PROD_PLAN_SELECTOR_MODAL');
            this.active_redirect = true;
            this.akWorkflowService.setTransactionNumber(response.response.transactionNumber);
            this.getAccountproductinformation(response, response.response.transactionNumber);
          }
        }
      }
    });
  }

  getAccountproductinformation(responseIn, transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role,
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };



    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.akWorkflowService.transactionData$.next(response);
      const queryParams = {
        role: this.localStorage.getItem('role'),
        profile: this.localStorage.getItem('profile'),
        audience: this.localStorage.getItem('audience'),
        transactionNumber: responseIn.response.transactionNumber
      };
      const parameters = {
        name: this.url_transition,
        params: {
          request: {
            role: 'A',
            profile: 'USER',
            audience: '',
            event_code: 'PRODUCT_PLAN_SELECTED_EXECUTIVE'
          },
          path: {
            prefixWorkflow: 'workflow_manager',
            workflow: this.workflow,
            stage: this.stage,
            step: this.step
          }
        },
        o: {}
      };
      this._transition.next('private', parameters, queryParams);
      this.destroySubscribers();

      // this.router.navigateByUrl(
      //   this.router.createUrlTree(['private/ACCOUNT_OPENING/stages/PRODUCT_INFORMATION/steps/PRODUCT_INFORMATION'],
      //     {
      //       queryParams: queryParams
      //     }
      //   )
      // ).then(() => {
      //   this.destroySubscribers();
      // });
    });
  }

  getTransactionData(transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role,
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }


  confirmDelete(row) {
    swal.fire({
       title: this._i18n.transform('@i18n-popup-text-delete-question'),
       text: this._i18n.transform('@i18n-popup-text-delete-question-text'),
       showCancelButton: true,
       confirmButtonText: this._i18n.transform('@i18n-popup-text-yes'),
       cancelButtonText: this._i18n.transform('@i18n-popup-text-no'),
       reverseButtons: true
     }).then((result) => {
       if (!isNullOrUndefined(result.value) && result.value) {
         this.trash(row);
       }
     });
   }
 
   trash(row) {
     const request = {};
     const param = {
       name: 'account_opening$account$delete',
       params: {
         request: {
           requestUser: this._auth.getUsername(),
           reason: 'SOMETEXT'
         },
         path: {
           transactionNumber: row.transactionNumber,
         },
         body: {}
       }
     };
 
     this.trans_subscriber = this._httpService.executeRequest(param.name, param.params, true).subscribe((response: any) => {
      this.getTransactions(this._auth.getUsername(), environment.paginator.page, environment.paginator.size);
     });
 
   }

  getTransactions(username, page, size) {
    const params = {
      name: 'account$myformalities_paginated$get',
      params: {
        request: {
          page: page,
          size: size
        },
        path: {
          username: username
        },
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.loading_table = false;
      this.paginator = response;
      this.transactions = response.content;
    }, error => {
      this.loading_table = false;
    });
  }

  action_paginator(page) {
    this.loading_table = true;
    this.getTransactions(this._auth.getUsername(), page, environment.paginator.size);
  }

  getPerson(identifier) {
    const params = {
      name: 'person$person$get',
      params: {
        request: { id: identifier },
        path: {},
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  getOrganization(identifier) {
    const params = {
      name: 'person$organization$get',
      params: {
        request: { id: identifier },
        path: {},
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  openFormalities(transactionNumber) {
    if (!this.loading_table) {
      this._transition.showWorkflow$.next(false);
      this.cargando = transactionNumber;

      this.akWorkflowService.setTransactionNumber(transactionNumber);
      this.getTransactionData(transactionNumber).subscribe((data: any) => {
        this.getPerson(data.holder.identifier).subscribe((response: any) => {
          this.redirect(transactionNumber, 'NATURAL');
        }, (error: any) => {
          this.redirect(transactionNumber, 'LEGAL');
        });
      });

      this.cargando = '';
    }
  }

  redirect(transactionNumber, audience) {

    this.localStorage.setItem('clientaudience',audience);

    const queryParams = {
      profile: this.localStorage.getItem('profile'),
      role: this.localStorage.getItem('role'),
      audience: audience,
      transactionNumber: transactionNumber
    };

    this.router.navigateByUrl(
      this.router.createUrlTree([environment.staticLinks.detailClientResumen],
        {
          queryParams: queryParams
        }
      )
    ).then(() => {
      this.destroySubscribers();
    });

  }

  destroySubscribers() {
    if (this.trans_subscriber) {
      this.trans_subscriber.unsubscribe();
    }
    if (this.route_subscriber) {
      this.route_subscriber.unsubscribe();
    }
    if (this.wf_events_subscriber) {
      this.wf_events_subscriber.unsubscribe();
    }
  }

  new() {
    this.akeelaSectionService.showModalSection('PROD_PLAN_SELECTOR_MODAL');
  }

  getInitial(text) {
    let retorno = '';
    if (!isNullOrUndefined(text)) {
      retorno = text.substr(0, 1).toUpperCase();
    }
    return retorno;
  }

  ngOnDestroy() {
    if (this.trans_subscriber) {
      this.trans_subscriber.unsubscribe();
    }
    if (this.route_subscriber) {
      this.route_subscriber.unsubscribe();
    }

    if (this.wf_events_subscriber) {
      this.wf_events_subscriber.unsubscribe();
    }
  }

}
