<!-- DINAMIZAR SUBHEADER, POR AHORA SOLO DE PREVIEW -->
<div class="container-fluid box">
    <div class="container text-center">
        <h2 class="title font-weight-light">
            <span [innerHTML]="'@i18n-open-account-title' | i18n "></span>
        </h2>
        <h4 class="i18n-plans-subtitle sub-title font-weight-normal text-primary">
            <span class="font-weight-light" [innerHTML]="'@i18n-open-account-subtitle' | i18n"></span>
        </h4>
    </div>
</div>