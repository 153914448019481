import { Component, OnInit, OnDestroy, AfterContentInit, Input, Output,EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpService } from '@akeela/properties';
import {
  AkeelaWorkflowService,
  AkeelaStageService,
  AkeelaStepService,
  AkeelaSectionService
} from '@akeela/workflow';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadService, ErrorService } from '@akeela/common';
import { MiddlewareService } from '../../../../services/middleware.service';
import { Subscription } from 'rxjs';
import { RequirementsService } from '@akeela/requirements';
import { environment } from '../../../../../environments/environment';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { isNullOrUndefined } from 'util';
import { LocalService } from '@akeela/local-storage';



@Component({
  selector: 'app-related-documents-generated',
  templateUrl: './related-documents-generated.component.html',
  styleUrls: ['./related-documents-generated.component.css']
})
export class  RelatedDocumentsGenerated implements OnInit, OnDestroy, AfterContentInit {
  
  loadView = false;
  environment;
  lang;

  queryParams;
  requirementsModuleActive = false;
  preview: any;
  showView = true;
  @Input() documents?: any;
  @Input() name?: any;
  @Input() dowloadAlert?: any;
  url;

//http://localhost:4201
  urlAppPdf = "http://akwritepdf.akeela.co"
  

  @Output() response = new EventEmitter<any>();
  previewText = "<span class='small text-secondary'> (preview)</span>";


  constructor(
    private formBuilder: FormBuilder,
    private _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private _upload: UploadService,
    private _error: ErrorService,
    private _middleware: MiddlewareService,
    private _akeelaRequirementsService: RequirementsService,
    private i18n: I18nPipe,
    private localStorage: LocalService,
    private route: ActivatedRoute,
    private router: Router,

  ) { }




  ngOnInit() {

        
    if (environment.theme.images === 'FACEBANK') {

      this.url = './assets/facebank/public/images';
      this.urlAppPdf = "http://akwritepdf.akeela.co"

  
    } else if (environment.theme.images === 'MIBANCO') {
  
        this.url = './assets/mibanco/public/images';
  
    } else {
  
        this.url = './assets/public/images';
  
    }



  //  this.urlAppPdf = "http://localhost:4201"

  }


  ngAfterContentInit() {

console.log("this.document",this.documents);

  }



  ngOnDestroy() {
  
  }

  getResponsePreview(data){
    
  }

  get_response(resp) {
    this.response.emit(resp);
  }


}
