<div class="container animated fadeIn px-0">
    <div class="mb-0 row skin-white  animated fadeIn" style="padding-left: 0; padding-right:0">
        <!--   <div class="col-12 mt-2">
            <hr class="m-0">

  <h5 class="title font-weight-normal ">Representante Legal y Personas Autorizadas</h5>
      </div>-->
        <div class=" col-12 p-3 ml-2 mr-2 mt-2 mb-2 section-box" *ngIf="itemList && itemList.length === 0 ">

            <form novalidate [formGroup]="form" autocomplete="current-password">
                <div class="row">
                        <div class="col-12 col-md-6 form-group">
                                <label class="font-weight-normal"> {{ '@i18n-text-identity' | i18n:lang }} <span class="text-danger mt-1"> * </span></label>
        
                                <div class="input-group ">
                                    <input placeholder="12345678" type="text" class="form-control" [ngClass]="{'error': form.controls.identity.invalid && form.controls.identity.dirty}"
                                        formControlName="identity">
        
                                </div>
        
                                <div class="form-control-feedback help-block" *ngIf="form.get('identity').errors">
                                        <div *ngIf="form.get('identity').errors['required'] && !form.get('identity').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n:lang">
                                            </div>
                                    
                                    <div *ngIf="form.get('identity').hasError('minlength')  && !form.get('identity').pristine">
                                        Mínimo 8 caracteres
                                    </div>
        
                                    <div *ngIf="form.get('identity').hasError('maxlength')  && !form.get('identity').pristine">
                                        Máximo 12 caracteres
                                    </div>
                                </div>
        
        
                            </div>

                            </div>
                      <div class="row">

                        <div class="col-12 col-md-6 form-group">
                                <label class="font-weight-normal"> {{ 'Nombre' | i18n:lang }} <span class="text-danger mt-1"> * </span></label>
        
                                <div class="input-group ">
                                    <input placeholder="Juan" type="text" class="form-control" [ngClass]="{'error': form.controls.name.invalid && form.controls.name.dirty}"
                                        formControlName="name">

                                </div>
        
                                <div class="form-control-feedback help-block" *ngIf="form.get('name').errors">

                                      <div *ngIf="form.get('name').errors['required'] && !form.get('name').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n:lang">
                                      </div>

                                </div>
                        
        
                            </div>
                            <div class="col-12 col-md-6 form-group">
                                    <label class="font-weight-normal"> {{ 'Apellido' | i18n:lang }} <span class="text-danger mt-1"> * </span></label>
            
                                    <div class="input-group ">
                                        <input placeholder="Prado" type="text" class="form-control" [ngClass]="{'error': form.controls.familyName.invalid && form.controls.familyName.dirty}"
                                            formControlName="familyName">
            
                                    </div>
        
                                    <div class="form-control-feedback help-block" *ngIf="form.get('familyName').errors">
            
                                      <div *ngIf="form.get('familyName').errors['required'] && !form.get('familyName').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n:lang">
                                      </div>

                                    </div>
                                
            
                                </div>


                    <div class="col-12 col-md-6 form-group">
                        <label class="font-weight-normal"> {{ '@i18n-text-nationality' | i18n:lang }} <span class="text-danger mt-1"> * </span></label>

                        <div class="input-group ">
                            <select class="form-control" [ngClass]="{'error': form.controls.nationality.invalid && form.controls.nationality.dirty}"
                                formControlName="nationality">
                                <option value=''>Seleccione</option>
                                <ng-container *ngFor="let nat of nationalitys">
                                    <option [value]='nat.alternateName'>{{ nat.nationality }}</option>
                                </ng-container>
                            </select>
                        </div>

                        <div class="form-control-feedback help-block" *ngIf="form.get('nationality').errors">

                          
                                <div *ngIf="form.get('nationality').errors['required'] && !form.get('nationality').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n:lang">
                                    </div>

                        </div>


                    </div>

                    <div class="col-12 col-md-6 form-group">
                        <label class="font-weight-normal"> {{ '@i18n-person-type' | i18n:lang }} <span class="text-danger mt-1"> * </span></label>

                        <div class="input-group ">
                            <select class="form-control" [ngClass]="{'error': form.controls.type.invalid && form.controls.type.dirty}" formControlName="type">
                                <option value=''>Seleccione</option>
                                <ng-container *ngFor="let obj of document_types">
                                    <option [value]='obj.item.alternateName'>{{ obj.name }}</option>
                                </ng-container>
                            </select>
                        </div>

                        <div class="form-control-feedback help-block" *ngIf="form.get('type').errors">

                                <div *ngIf="form.get('type').errors['required'] && !form.get('type').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n:lang">
                                 </div>
                        </div>


                    </div>
                    </div>
                    <div class="row">


                    <div class="col-12  col-md-3 offset-md-9 col-md-2 form-group">
                        <div class="input-group w-100">

                            <button [disabled]="!form.valid" (click)="getRelated()" class="btn btn-secondary cursor-pounter mt-md-4 btn-block" type="button">Agregar</button>
                       
                        </div>
                    </div>


                </div>
            </form>

            </div>
            <div class="col-12 mt-2 pb-4 ">
                <hr>
            <ng-container *ngIf="itemList && itemList.length > 0 && !load">
                <div class="head-list d-none d-lg-flex bd-highlight list-flex flex-wrap pb-3 pt-3 pb-md-2 pt-md-2">
                    <div class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-30  order-1  ml-4 ml-md-0 ">
                        <div class="font-weight-bold text-capitalize">
                            Nombre
                        </div>
                    </div>

                    <div class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-30  order-2  ml-4 ml-md-0 ">
                        <div class="font-weight-bold text-capitalize">
                            Apellido
                        </div>
                    </div>

                    <div class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-20  order-3  ml-4 ml-md-0 ">
                        <div class="font-weight-bold text-capitalize">
                            Cód.Ente
                        </div>
                    </div>
                </div>
                <div class="w-100 fz-body" *ngFor="let row of itemList; let first = first; let i_stf = index">
                    <hr *ngIf="!first" class="m-0 ">
                    <div (click)="openItem(row)" class="d-flex bd-highlight list-flex flex-wrap pb-3 pt-3 pb-md-2 pt-md-2">

                        <div class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-30   order-1 ml-4 ml-md-0 ">
                            <div class=" text-capitalize">
                                {{row?.name}}
                            </div>
                        </div>

                        <div class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-30   order-2  ml-4 ml-md-0 ">
                            <div class=" text-capitalize">
                                {{row?.lastName}}
                            </div>
                        </div>

                        <div class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2   w-20  order-3  ml-4 ml-md-0 ">
                            <div class=" text-capitalize">
                                {{row?.code}}
                            </div>
                        </div>

                

                        <div class=" mb-1 mb-md-0 text-md-right  mt-md-0 pl-md-2 pr-md-2  order-6   w-20   ">
                            <a  *ngIf="!readonlyMode" href="javascript:void(0)" (click)="itemList = []" class="font-weight-bold">
                                <i class="fa fa-trash"></i>
                            </a>
                        </div>

                    </div>
                </div>
            </ng-container>

            <div class="w-100 text-center py-2" *ngIf="(itemList && itemList.length===0)">

                <div class="text-secondary mt-2">

                    <span [innerHTML]="'Agregue su(s)' | i18n:lang "></span>
                    <span [innerHTML]="'Representante(s) Legale(s)' | i18n:lang "></span>

                </div>
            </div>

            <ng-container *ngIf="load">
                <div class="row">
                    <div>
                        <img class="float-left" src="./assets/public/images/loading.gif" alt="">
                    </div>
                </div>
            </ng-container>
            <hr><br>
        </div>
    </div>
</div>