<button type="button" class="btn btn-outline-primary dropdown-toggle d-none d-md-block" data-toggle="dropdown" aria-haspopup="true"
  aria-expanded="false">
  <i class="fas fa-sort-amount-down voltear" style="font-size:11px"></i>
  <i class="fas fa-sort-amount-down" style="font-size:11px"></i>&nbsp;
  <span style="font-size:11px">Ordenar</span>
</button>

<button type="button" class="btn btn-outline-primary  d-block d-md-none" [swal]="showWindow">
  <i class="fas fa-sort-amount-down voltear" style="font-size:11px"></i>
  <i class="fas fa-sort-amount-down" style="font-size:11px"></i>&nbsp;
  <span style="font-size:11px">Ordenar</span>
</button>




<div class="dropdown-menu" style="font-size:14px">
  <a class="dropdown-item" (click)="applyFilter({id: 0, action:'UP'})">
    <i class="fas fa-check fa-color-white" [class.fa-color-black]="filter.active===0 && filter.object[0].movement==='UP'"></i>&nbsp;Solicitante (ASC)</a>
  <a class="dropdown-item" (click)="applyFilter({id: 0, action:'DOWN'})">
    <i class="fas fa-check fa-color-white" [class.fa-color-black]="filter.active===0 && filter.object[0].movement==='DOWN'"></i>&nbsp;Solicitante (DESC)</a>
  <a class="dropdown-item" (click)="applyFilter({id: 1, action:'UP'})">
    <i class="fas fa-check fa-color-white" [class.fa-color-black]="filter.active===1 && filter.object[1].movement==='UP'"></i>&nbsp;Solicitud (ASC)</a>
  <a class="dropdown-item" (click)="applyFilter({id: 1, action:'DOWN'})">
    <i class="fas fa-check fa-color-white" [class.fa-color-black]="filter.active===1 && filter.object[1].movement==='DOWN'"></i>&nbsp;Solicitud (DESC)</a>
  <a class="dropdown-item" (click)="applyFilter({id: 2, action:'UP'})">
    <i class="fas fa-check fa-color-white" [class.fa-color-black]="filter.active===2 && filter.object[2].movement==='UP'"></i>&nbsp;Fecha de recibido (ASC)</a>
  <a class="dropdown-item" (click)="applyFilter({id: 2, action:'DOWN'})">
    <i class="fas fa-check fa-color-white" [class.fa-color-black]="filter.active===2 && filter.object[2].movement==='DOWN'"></i>&nbsp;Fecha de recibido (DESC)</a>
  <a class="dropdown-item" (click)="applyFilter({id: 3, action:'UP'})">
    <i class="fas fa-check fa-color-white" [class.fa-color-black]="filter.active===3 && filter.object[3].movement==='UP'"></i>&nbsp;Fecha de última actualización (ASC)</a>
  <a class="dropdown-item" (click)="applyFilter({id: 3, action:'DOWN'})">
    <i class="fas fa-check fa-color-white" [class.fa-color-black]="filter.active===3 && filter.object[3].movement==='DOWN'"></i>&nbsp;Fecha de última actualización (DESC)</a>
</div>



<swal [width]="'850px'" [width]="'100%'" [showCloseButton]="true" [showConfirmButton]="false" [showCancelButton]="false"
  #showWindow>
  <ng-container *swalPortal="swalTargets.content">
    <br>
    <br>


    <div class="filter-text">

      <div class="row ">
        <div class="col-12 font-weight-bold">
          Ascendente&nbsp;<i class="fas fa-sort-amount-down voltear"></i>
        </div>
      </div>
      <hr>
      <div class="row active-cursor" (click)="applyFilter({id: 0, action:'UP'})">
        <div class="col-10">
          {{ '&nbsp;&nbsp;&nbsp;Solicitante' }}
        </div>
        <div class="col-2 text-right">
          <i class="fas fa-check" *ngIf="filter.active===0 && filter.object[0].movement==='UP'"></i>
        </div>
      </div>
      <hr>
      <div class="row active-cursor" (click)="applyFilter({id: 1, action:'UP'})">
        <div class="col-10">
          {{ '&nbsp;&nbsp;&nbsp;Solicitudes' }}
        </div>
        <div class="col-2 text-right">
          <i class="fas fa-check" *ngIf="filter.active===1 && filter.object[1].movement==='UP'"></i>
        </div>
      </div>
      <hr>
      <div class="row active-cursor" (click)="applyFilter({id: 2, action:'UP'})">
        <div class="col-10">
          {{ '&nbsp;&nbsp;&nbsp;Fecha de recibido' }}
        </div>
        <div class="col-2 text-right">
          <i class="fas fa-check" *ngIf="filter.active===2 && filter.object[2].movement==='UP'"></i>
        </div>
      </div>
      <hr>
      <div class="row active-cursor" (click)="applyFilter({id: 3, action:'UP'})">
        <div class="col-10">
          {{ '&nbsp;&nbsp;&nbsp;Fecha de última actualización' }}
        </div>
        <div class="col-2 text-right">
          <i class="fas fa-check" *ngIf="filter.active===3 && filter.object[3].movement==='UP'"></i>
        </div>
      </div>
      <hr>
      <div class="row ">
        <div class="col-12 font-weight-bold">
          Descendente&nbsp;<i class="fas fa-sort-amount-down"></i>
        </div>
      </div>
      <hr>
      <div class="row active-cursor" (click)="applyFilter({id: 0, action:'DOWN'})">
        <div class="col-10" >
          {{ '&nbsp;&nbsp;&nbsp;Solicitante' }}
        </div>
        <div class="col-2 text-right">
          <i class="fas fa-check" *ngIf="filter.active===0 && filter.object[0].movement==='DOWN'"></i>
        </div>
      </div>
      <hr>

      <div class="row active-cursor" (click)="applyFilter({id: 1, action:'DOWN'})">
        <div class="col-10">
          {{ '&nbsp;&nbsp;&nbsp;Solicitudes' }}
        </div>
        <div class="col-2 text-right">
          <i class="fas fa-check" *ngIf="filter.active===1 && filter.object[1].movement==='DOWN'"></i>
        </div>
      </div>
      <hr>

      <div class="row active-cursor" (click)="applyFilter({id: 2, action:'DOWN'})">
        <div class="col-10">
          {{ '&nbsp;&nbsp;&nbsp;Fecha de recibido' }}
        </div>
        <div class="col-2 text-right">
          <i class="fas fa-check" *ngIf="filter.active===2 && filter.object[2].movement==='DOWN'"></i>
        </div>
      </div>
      <hr>

      <div class="row active-cursor" (click)="applyFilter({id: 3, action:'DOWN'})">
        <div class="col-10">
          {{ '&nbsp;&nbsp;&nbsp;Fecha de última actualización' }}
        </div>
        <div class="col-2 text-right">
          <i class="fas fa-check" *ngIf="filter.active===3 && filter.object[3].movement==='DOWN'"></i>
        </div>
      </div>


      <br>
    </div>
  </ng-container>
</swal>