import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { AuthService } from '@akeela/auth';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-detail-responses',
  templateUrl: './detail-responses.component.html',
  styleUrls: ['./detail-responses.component.css']
})
export class DetailResponsesComponent implements OnInit {
  responses;
  transactionNumber;

  constructor(
    private _httpService: HttpService,
    private _auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      this.transactionNumber = queryParams['transactionNumber'];
    });
    const params = {
      name: 'account_opening$responses$get',
      params: {
        request: {},
        path: {
          transactionNumber: this.transactionNumber
        },
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params).subscribe((response: any) => {
      this.responses = response;
    });
  }

}
