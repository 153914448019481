
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { AuthService } from '@akeela/auth';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import swal from 'sweetalert2';
import merge from 'lodash/merge'
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { TransitionService } from '../../../../services/transition.service';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { CommonService, ErrorService } from '@akeela/common';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { LocalService } from '@akeela/local-storage';
import { I18nPipe } from '@akeela/i18n';

import { Table, Row, Column } from '@akeela/ak-table';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { DatePipe } from '@angular/common';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { PropertiesService } from '../../../../services';



@Component({
  selector: 'app-sales-representative',
  templateUrl: './list-sales-representative.component.html',
  styleUrls: ['./list-sales-representative.component.css']
})
export class ListSalesRepresentativeComponent implements OnInit, OnDestroy {
  workspaces: any = null;
  queryParams;
  workflow;
  stage;
  step;
  subscriptions: Subscription[] = [];
  loading_table = true;
  loading_table2 = false;
  env = environment;

  configuration_table: Table;
  limitRows = 25;
  rows = [];
  selected = [];
  temp = [];
  fieldSearch;
  salesRepresentatives = [];

  title = 'My first AGM project';
  lat =  6.42375;
  lng = -66.58973;
  zoom = 5;
  mapTypeId ='hybrid'
  showModal;


  constructor(
    private _httpService: HttpService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private _auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private transitionService: TransitionService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    public _common: CommonService,
    private _breadcrumbService: AkeelaBreadcrumbService,
    private localStorage: LocalService,
    private _error: ErrorService,
    private _i18n: I18nPipe,
    private datePipe: DatePipe,
    public config: NgbDropdownConfig,
    private _properties: PropertiesService,


    

  ) {

    this.akStageService.setshowHeaderStage(false);
    this.akStepService.setshowHeaderStep(false);
    this.akStepService.setshowSubHeaderStep(false);

    config.placement = 'bottom-left';
    config.autoClose = true;
  }

  ngOnInit() {

    this._akeelaSidebarMenu.setSidebarLeftActive('salesRepresentative');
    this.localStorage.removeItem('readonly');

    this.stage = this.akStageService.currentStage$.getValue();
    this.step = this.akStepService.currentStep$.getValue();
    this.workflow = this.akWorkflowService.currentWorkflow$.getValue();
    this.akWorkflowService.transactionData$.next(null);
    this._properties.resetWks();

    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;

    //  this.setConfigurationTableNew();
    this.getsalesRepresentatives();


    }));

  }

  getsalesRepresentatives() {
    const params: any = {
      name: 'person$executives$get',
      params: {
        request: {},
        path: {},
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {


      this.salesRepresentatives = response;
      this.setConfigurationTableNew();

    }, error => {
      this.salesRepresentatives = [];
    })
  }


//juan
setConfigurationTableNew(){
  let data = [];
      const content: Row[] = [];
      let i = 0;
      for (const row of  this.salesRepresentatives ) {

    
        let jsonTmp = 
        {
          "identifier": row.identifier,
          "name":  row.person?.givenName + " " + row.person?.familyName,
          "telephone": row.person?.telephone,
          "email": row.person?.email,
          "direction": row.localBusiness?.address?.description,
          "agencia": row.localBusiness?.name,
          "fecha": (row?.dateOfThing?.endDate) ? (this.datePipe?.transform(row?.dateOfThing?.endDate, 'dd/MM/yyyy HH:mm')) : '-',
         
        }

        data = [...data,jsonTmp];
      }
      
      this.loading_table = false;

        
            // cache our list
            this.temp = [...data];
            // push our inital complete list
            this.rows = data;    
    }




  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }




updateFilter(data) {

this.loading_table2= true;
const val = !isNullOrUndefined(data) ?String(data).toLowerCase():null;

// filter our data
let arrayBase = this.temp;

const temp = arrayBase.filter(function (d) {

 let  filter = false;
 let tempAnchor = false;

 for (let clave in d){
  let value = d[clave];
    
    if(!filter){

      filter = String(value).toLowerCase().indexOf(val) !== -1 || !val ;
  
    }
}

return filter;

});

// update the rows
this.rows = temp;
// Whenever the filter changes, always go back to the first page
 this.loading_table2= false;

}

close(){

}



getInitials(givenName: string, familyName: string) {
  return (
    (givenName ? givenName.substr(0, 1).toUpperCase() : '') +
    (familyName ? familyName.substr(0, 1).toUpperCase() : '')
  );
}
getInitial(text) {
  let retorno = '';
  if (!isNullOrUndefined(text)) {
    retorno = text.substr(0, 1).toUpperCase();
  }
  return retorno;
}
}
