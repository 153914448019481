<app-template>

    <ng-template app-content componentName="mainContentModalBottonMeet">
        <div class="capa-menu-2" *ngIf="buttomModal" (click)="changes()"></div>

        <div [ngClass]="{'d-none':animate_init_bottom,'animate__slideInUp':buttomModal,animate__slideOutDown:!buttomModal } " class="  animate__animated animate__faster ak-modal-botoom  ak-modal-botoom2 p-2 ">
            <div class="body pb-4 pt-2 " style="overflow:auto;">
                <div class="container text-center  ">

                    <div class="text-left row mb-5">
                        <div class="col-12 ">

                            <div class="row">

                                <div class="col-8 ">
                                    <div class="d-flex w-100">
                                        <h5 class="font-weight-bold  "> {{'Recaudos' | i18n}}</h5>
                                        <!--
                                        <button *ngIf="getIndicatorsProgresData('DOC_SUPPORT')?.optional > 0" [ngClass]="{'btn-light': !viewOptional,'btn-primary':viewOptional}" (click)="viewOptional = !viewOptional" class="ml-2 mb-2 btn   btn-sm">
                                        <span>Ver Opcionales ({{  getIndicatorsProgresData('DOC_SUPPORT')?.optional }})</span>
                                        </button>
                                        -->
                                    </div>
                                </div>
                                <div class="col-4 text-right" >
                                    
                                        <a href="javascript:void()" (click)="cerrar()"><i class="fas fa-times fa-2x"></i></a>
                                    
                                </div>
                            </div>

                            <div class="   p-2">
                                <hr class="m-1 p-1">
                                
                                <app-private-requirements  [dataInformation]="dataInformation" [viewOptional]="viewOptional" [isActiveResume]="false" *ngIf="dataInformation!==null"></app-private-requirements>

                                
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </ng-template>



    <ng-template app-content componentName="mainContentModalCenter">


        <div *ngIf="sendTransactioModal" class=" capa-menu-2 w-100 h-100" (click)="sendTransactioModal = (!showLoadingSend?false:true)">
            <div class="p-2 d-flex justify-content-center flex-column align-items-center w-100 h-100" style="border-radius: 8px;">
                <div class="container bg-white p-2" (click)="$event.stopPropagation();" style="height: 70px;">

                    <span class="float-right mt-2">
                        <button (click)="send()" class="btn btn-primary btn-sm ">Aceptar y Enviar
                              <span *ngIf="!showLoadingSend" class="fas fa-paper-plane ml-1"></span>
                    <i *ngIf="showLoadingSend" class="fa  fa-spinner fa-spin"></i>

                    </button>
                    </span>
                    <h5 class="font-weight-bold text-secondary pt-2">Aceptar Terminos y condiciones</h5>


                    <hr>
                </div>
                <div style="max-height:calc(90%  - 150px) ;overflow: auto;" class="container bg-white p-2" (click)="$event.stopPropagation();">
                    <div class="  p-2">
                        <div class=" mb-2 fz-14 text-secondary">
                            <div class="text-dark">
                                <span [innerHTML]="'@i18n-termAndConditions' | i18n"></span>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="container bg-white p-2 text-center" (click)="$event.stopPropagation();" style="height: 80px;">
                    <hr class="m-0 mb-3">
                    <button (click)="send()" class="btn btn-primary">Aceptar y Enviar
                        <span *ngIf="!showLoadingSend" class="fas fa-paper-plane ml-1"></span>
                        <i *ngIf="showLoadingSend" class="fa  fa-spinner fa-spin"></i>
                        </button>

                </div>
            </div>

        </div>

    </ng-template>



    <ng-template app-content componentName="mainContentModalApp">
        <div class="full-modal-2 compleat-modal" style="height: 100vh!important;" *ngIf="showModalResponses">
            <div class="header active-cursor" style="text-align:right">
                <div class="float-right" (click)="showModalResponses=!showModalResponses">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i>

                </div>
                <div class="pr-2 pl-2 pb-2 float-left">
                    <div class=" pt-1">
                        <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>
                    </div>
                </div>
            </div>
            <div class="body " style="overflow:auto">
                <ak-responses [transaction]="transactionData?.transactionNumber" (close)="showModalResponses = false" [showCommentsBoolean]="showCommentsBoolean"></ak-responses>

            </div>
        </div>
    </ng-template>
</app-template>




<ng-container *ngIf="!transactionData">
    <div class="text-center">
        <img src="./assets/public/images/loading.gif" alt="">
    </div>
</ng-container>
<div class="container mb-4" *ngIf="transactionData">
    <div class="row">
        <div class="col-12 ">

            <div class="w-100 text-center">
                <div class="w-100 mt-4"><i class="fas fa-layer-group fa-3x text-primary"></i></div>
                <div class="mt-2 mb-2"><span class="text-secondary ">Solicitud #{{transactionData?.transactionNumber}}</span></div>

                <!--       <div class="mt-1  text-secondary mb-3 ">
                    <span class="badge badge-secondary pt-1 pb-1">{{ transactionData?.accountStatus[0]?.clientName}}</span>
                </div>
 -->


                <div class="mt-2 font-weight-bold text-dark fz-14">{{transactionData?.plan?.name}}</div>
                <div class="mt-1 text-dark fz-14">{{transactionData?.plan?.shortDescription}}</div>


                <div class=" mb-2 fz-14">
                    <span class="text-dark">   <span  [innerHTML]="transaction?.plan?.shortDescription| i18n"></span> </span>
                </div>
                <div *ngIf="transactionData?.dateOfThing?.startDate"><span class="text-secondary fz-12">Creado {{transactionData?.dateOfThing?.startDate | date: 'd MMM, y h:mm a'}}</span></div>
                <div *ngIf="transactionData?.dateOfThing?.modifiedDate "><span class="text-secondary  fz-12">Últ. actualización {{transactionData?.dateOfThing?.modifiedDate | date: 'd MMM, y h:mm a'}}</span></div>


                <div *ngIf="transactionData?.holder?.name"><span class="pt-2 text-dark  fz-18">{{transactionData?.holder?.name}}</span></div>


                <div class="mt-4">
                    <button *ngIf="incomplete" (click)="showModalResponses = true" class="  btn btn-outline-secondary  btn-sm">
                        <i class=" mr-1 far fa-comments text-primary" ></i>
                        Respuesta y comentarios
                        <i class=" pl-2 fas fa-circle text-danger blink_me" style="font-size: 7px" ></i>
                  </button>

                </div>

            </div>
            <div class="w-100 ">
                <!-- -->

                <div class="container">
                    <div class="row  mb-2" *ngIf="
                (transactionData && transactionData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS' || transactionData && transactionData?.accountStatus[0]?.additionalType == 'AC_INCOMPLETE')
                 && getIndicatorsProgresData('DOC_SUPPORT')?.percentReq == 100  && 
                 getIndicatorsProgresData(transactionData.plan.url)?.percent  == 100 &&  
                 getIndicatorsProgresData(clientActive)?.percent == 100 && 
                 (isFinalClientData? (dataInformation?.completeBriefcase) : true)  ">
                        <div class="text-left fz-14 col-12  mt-4">
                            <ng-container>

                                <div class="pt-2 fz-14 mt-4 animate__animated a&& (isFinalClientData? !(dataInformation?.completeBriefcase) : false)nimate__fadeInDown alert alert-secondary w-100 text-secondary p-2 bg-white" [innerHTML]="'@i18n-transaction-send-success' | i18n">

                                    
                                </div>

                                <!--<div class="pt-2 fz-14 mt-4 animate__animated a&& (isFinalClientData? !(dataInformation?.completeBriefcase) : false)nimate__fadeInDown alert alert-secondary w-100 text-secondary p-2 bg-white">

                                    <b>Felicitaciones</b> , tu solicitud esta lista.<br><br> Por favor procede a enviarla para que nuestros analistas la revisen.<br> <br> Te responderemos en un tiempo no mayor a 59 min una vez recibida.<br><br> Si toda
                                    la información está correcta tu producto o servicio bancario será creado en línea al momento de la aprobación. Recibirás las instrucciones y próximos pasos por correo.
                                </div>-->

                            </ng-container>

                            <div class="alert alert-secondary p-3 mt-4 mb-4 d-none" [ngClass]="{'d-blok': !(transactionData && dataInformation?.completeBriefcase && getIndicatorsProgresData('DOC_SUPPORT')?.percentReq == 100  && getIndicatorsProgresData(transactionData.plan.url)?.percent  == 100 &&  getIndicatorsProgresData(clientActive)?.percent == 100 )}">
                                <div class="d-flex fz-14">
                                    <div class="text-left w-100">
                                        <div ak-shadow>
                                            <div class="font-weight-bold">Complete su solicitud para continuar con la creación de su cuenta. </div>
                                        </div>
                                        <div class="ml-auto text-right " (click)="openFormalities()"><a href="javascript:void(0) " class="">Completar</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row  ">

                    <ng-container *ngIf="!getIndicatorsProgresData">
                        <div class="text-center">
                            <img src="./assets/public/images/loading.gif" alt="">
                        </div>
                    </ng-container>

                    <div *ngIf="getIndicatorsProgresData" class="text-left fz-14 col-12 mt-4">
                        <h5 *ngIf="!disabledForm && transactionData && transactionData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS' || transactionData && transactionData?.accountStatus[0]?.additionalType == 'AC_INCOMPLETE'" class="mb-4 text-center text-secondary font-weight-bold ">{{'@i18n-title-dashboard-transaction' | i18n}}</h5>
                        <div class="d-flex justify-content-center flex-wrap mt-2 mb-4 text-secondary">

                            <!-- 
                          {{dataInformation?.completeBriefcase}}
-->
                            <div class="option-car-selected-ak" (click)="optActive = 1;redirectContinueClient(); optActive = 1;">

                                <div [ngClass]="{'selected': optActive==1}" class="card  text-center">
                                    <div class="card-header text-right">

                                        <div class="fz-14 mt-1 text-secondary text-left">
                                            <div class="float-right">
                                                
                                                <i *ngIf="!(getIndicatorsProgresData( clientActive)?.percent==100) || !dataInformation?.completeBriefcase" class="far fa-circle  option-car-check  text-success"></i>
                                                <i *ngIf="getIndicatorsProgresData( clientActive)?.percent==100 && dataInformation?.completeBriefcase " class="fas fa-check-circle option-car-check text-success "></i>
                                                
                                            </div>

                                            <h5 *ngIf="!incomplete">1</h5>

                                        </div>

                                    </div>
                                    <div class="card-body pt-0 pb-0 pl-2 pr-2">

                                        <div class="bg-2 circle-bg ml-auto mr-auto text-center">

                                            <i class="fas fa-portrait"></i>
                                        </div>

                                        <div class="mt-3 mb-2 font-weight-bold text-dark">Datos de Cliente</div>
                                        <div class="fz-13">
                                            Completa tu información de identificación
                                        </div>

                                    </div>
                                    <div class="card-footer border-0 text-left ">
                                        <div class="fz-12 mt-1 text-secondary">
                                            Completados {{getIndicatorsProgresData(clientActive)?.minValue}}/{{getIndicatorsProgresData(clientActive)?.maxValue}}
                                        </div>
                                        <div>
                                            <akeela-graphics [percent]="getIndicatorsProgresData(clientActive)?.percent" [type]=" 'line' ">
                                            </akeela-graphics>
                                        </div>



                                        <div *ngIf="!(getIndicatorsProgresData( clientActive)?.percent==100 && (isFinalClientData? (dataInformation?.completeBriefcase) : true))" class="fz-12 text-center  text-primary mt-2">

                                            <span class=" "> Completar <span class="fas fa-arrow-right ml-1"></span></span>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="option-car-selected-ak" (click)="optActive = 2;openFormalities()">

                                <div [ngClass]="{'selected': optActive==2}" class="card  text-center">
                                    <div class="card-header text-right">
                                        <div class="fz-14 mt-1 text-secondary text-left">
                                            <div class="float-right">
                                                <i *ngIf="!(getIndicatorsProgresData(transactionData.plan.url)?.percent  == 100)" class="far fa-circle  option-car-check  text-success"></i>
                                                <i *ngIf="getIndicatorsProgresData(transactionData.plan.url)?.percent  == 100" class="fas fa-check-circle option-car-check text-success "></i>
                                                
                                            </div>
                                            <h5 *ngIf="!incomplete">2</h5>

                                        </div>
                                    </div>
                                    <div class="card-body pt-0 pb-0 pl-2 pr-2">

                                        <div [innerHTML]="'@i18n-body-dashboard-transaction' | i18n"></div>

                                    </div>
                                    <div class="card-footer border-0 text-left ">

                                        <div style="margin-top: 20px;">
                                            <akeela-graphics [percent]="getIndicatorsProgresData(transactionData.plan.url)?.percent" [type]="'line'">
                                            </akeela-graphics>
                                        </div>


                                        <div *ngIf="!(getIndicatorsProgresData(transactionData.plan.url)?.percent  == 100)" class="fz-12 text-center  text-primary mt-2">

                                            <span class=" "> Completar <span class="fas fa-arrow-right ml-1"></span></span>

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div (click)="optActive = 3;buttomModal = true;animate_init_bottom = false" class="option-car-selected-ak">

                                <div [ngClass]="{'selected': optActive == 3}" class="card  text-center">
                                    <div class="card-header text-right">

                                        <div class="fz-14 mt-1 text-secondary text-left">
                                            <div class="float-right">
                                                <i *ngIf="!( getIndicatorsProgresData('DOC_SUPPORT')?.percentReq  == 100)" class="far fa-circle  option-car-check  text-success"></i>
                                                <i *ngIf="getIndicatorsProgresData('DOC_SUPPORT')?.percentReq  == 100" class="fas fa-check-circle option-car-check text-success "></i>

                                            </div>

                                            <h5 *ngIf="!incomplete">3</h5>
                                        </div>


                                    </div>
                                    <div class="card-body pt-0 pb-0 pl-2 pr-2">

                                        <div class="bg-2 circle-bg ml-auto mr-auto text-center">

                                            <i class="fas fa-folder"></i>
                                        </div>

                                        <div class="mt-3 mb-2 font-weight-bold text-dark">Recaudos</div>
                                        <div class="fz-13">
                                            Adjunta tus recaudos
                                        </div>

                                    </div>
                                    <div class="card-footer border-0  text-left ">

                                        <div class="fz-12 mt-1 text-secondary">
                                            Recaudos {{getIndicatorsProgresData('DOC_SUPPORT')?.minValueReq}}/{{getIndicatorsProgresData('DOC_SUPPORT')?.maxValueReq}}
                                        </div>
                                        <div>
                                            <akeela-graphics [percent]="getIndicatorsProgresData('DOC_SUPPORT')?.percentReq" [type]=" 'line' ">
                                            </akeela-graphics>
                                        </div>

                                        <div *ngIf="!( getIndicatorsProgresData('DOC_SUPPORT')?.percentReq  == 100)" class="fz-12 text-center  text-primary mt-2">

                                            <span class=" "> Completar <span class="fas fa-arrow-right ml-1"></span> </span>

                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div *ngIf="!disabledForm && transactionData && transactionData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS' || transactionData && transactionData?.accountStatus[0]?.additionalType == 'AC_INCOMPLETE'" (click)="optActive = 4;" class="option-car-selected-ak">

                                <div [ngClass]="{'selected': optActive==4}" class="card  text-center">
                                    <div class="card-header text-right">

                                        <div class="fz-14 mt-1 text-secondary text-left">
                                            <div class="float-right">
                                                <i class="far fa-circle  option-car-check  text-success"></i>

                                            </div>

                                            <h5 *ngIf="!incomplete">4</h5>

                                        </div>

                                    </div>
                                    <div class="card-body pt-0 pb-0 pl-2 pr-2">

                                        <div class="circle-bg bg-2 ml-auto mr-auto text-center">

                                            <i class="fas fa-paper-plane"></i>
                                        </div>

                                        <div class="fz-13" class="mt-3 mb-2 font-weight-bold text-dark">Enviar Solicitud</div>
                                        <div *ngIf="(transactionData && transactionData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS' || transactionData && transactionData?.accountStatus[0]?.additionalType == 'AC_INCOMPLETE')
                                        && getIndicatorsProgresData('DOC_SUPPORT')?.percentReq == 100  && 
                                        getIndicatorsProgresData(transactionData.plan.url)?.percent  == 100 &&  
                                        getIndicatorsProgresData(clientActive)?.percent == 100">
                                            Enviar expediente para revisión y aprobación
                                        </div>

                                        <div class="fz-13" *ngIf="!((transactionData && transactionData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS' || transactionData && transactionData?.accountStatus[0]?.additionalType == 'AC_INCOMPLETE')
                                        && getIndicatorsProgresData('DOC_SUPPORT')?.percentReq == 100  && 
                                        getIndicatorsProgresData(transactionData.plan.url)?.percent  == 100 &&  
                                        getIndicatorsProgresData(clientActive)?.percent == 100)">

                                            Debe completar todas las secciones

                                        </div>


                                    </div>
                                    <div class="card-footer  text-left ">

                                        <div class="text-center mt-2">
                                            <button [disabled]="!(
                                                (transactionData && transactionData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS' || transactionData && transactionData?.accountStatus[0]?.additionalType == 'AC_INCOMPLETE')
                                                 &&
                                                 getIndicatorsProgresData(transactionData.plan.url)?.percent  == 100 &&  
                                                 getIndicatorsProgresData(clientActive)?.percent == 100)" (click)="sendTransactioModal =true" [ngClass]="
                                                 {'btn-primary ': 
                                                 (transactionData && transactionData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS' || transactionData && transactionData?.accountStatus[0]?.additionalType == 'AC_INCOMPLETE')
                                                  &&
                                                  getIndicatorsProgresData(transactionData.plan.url)?.percent  == 100 &&  
                                                  getIndicatorsProgresData(clientActive)?.percent == 100,
                                                  'btn-secondary ':!(
                                                 (transactionData && transactionData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS' || transactionData && transactionData?.accountStatus[0]?.additionalType == 'AC_INCOMPLETE')
                                                  &&
                                                  getIndicatorsProgresData(transactionData.plan.url)?.percent  == 100 &&  
                                                  getIndicatorsProgresData(clientActive)?.percent == 100) ||  disabledForm
                                                
                                                
                                                }" class="btn">Enviar para análisis
                                                <span class="fas fa-paper-plane ml-1"></span>
                                            </button>
                                        </div>

                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>

                </div>

                <div>

                    <!-- 
                    <div class="text-left row mb-5">
                        <div class="col-12 col-md-8 offset-md-2 active-cursor mt-4" (click)="redirectContinueClient() " *ngIf="transactionData && getIndicatorsProgresData(clientActive) && getIndicatorsProgresData( clientActive)?.percent < 100 || (isFinalClientData? !(dataInformation?.completeBriefcase) : false)">
                            <div class="w-100 section-box ak-shadow  p-3  text-left ">
                                <div class="d-flex w-100 ">
                                    <div>

                                        <div>
                                            <span class="font-weight-bold "> {{'Datos de Cliente' | i18n}}</span>

                                        </div>
                                        <div class="fz-14 mt-2">


                                            <span class="text-secondary">
                                            Gestiona tu información para hacer que {{'clientNameShort' | i18n }}  trabaje mejor para tí
                                        </span>
                                        </div>

                                    </div>

                                    <div class=" ml-auto pl-2">
                                        <i class=" fas fa-user rq-icon mr-1 text-secondary "></i>
                                        <i *ngIf="getIndicatorsProgresData( clientActive)?.percent==100 && (isFinalClientData? (dataInformation?.completeBriefcase) : true)" class="text-success fas fa-check-circle check-compleat "></i>
                                        <i *ngIf="!(getIndicatorsProgresData( clientActive)?.percent==100 && (isFinalClientData? (dataInformation?.completeBriefcase) : true))" class="text-warning fas fa-info-circle check-compleat "></i>

                                    </div>
                                </div>

                                <div class="row mt-2 mb-2" *ngIf="getIndicatorsProgresData( clientActive)?.percent < 100 ">
                                    <div class="col-12 ">
                                        <div class="mt-3 " *ngIf="getIndicatorsProgresData( clientActive)?.percent < 100 ">
                                            <akeela-graphics [percent]="getIndicatorsProgresData(clientActive)?.percent" [type]=" 'line' ">
                                            </akeela-graphics>
                                        </div>

                                        <div class="fz-14 mt-1 text-secondary">
                                            Completados {{getIndicatorsProgresData(clientActive)?.minValue}}/{{getIndicatorsProgresData(clientActive)?.maxValue}}
                                        </div>


                                    </div>
                                </div>


                                <div class="mt-4 fz-14">
                                    <div class="text-compleat-ak">
                                        <a *ngIf="getIndicatorsProgresData(clientActive)?.percent < 100" class=" mt-2" href="javascript:void(0) ">Completar</a>
                                        <a *ngIf="getIndicatorsProgresData(clientActive)?.percent == 100 && (isFinalClientData? !(dataInformation?.completeBriefcase) : false)" class=" mt-2" href="javascript:void(0) ">Finalizar</a>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                 
                    <div class="text-left row mb-5">



                        <div class="col-12 col-md-8 offset-md-2 ">
                            <div class="active-cursor w-100 section-box ak-shadow hover p-3  text-left" (click)="openFormalities()">
                                <div class="d-flex w-100" *ngIf="transactionData ">
                                    <div>
                                        <div class="">
                                            <span class="font-weight-bold ">Datos de la Solicitud</span>

                                        </div>

                                        <div class="mt-2">
                                            <span class=""> {{transactionData?.plan?.name}}</span>

                                        </div>
                                        <div>
                                            <span class="fz-14 text-secondary "> <span  [innerHTML]="getListWf(transactionData?.plan?.url)?.name | i18n"></span></span>
                                        </div>
                                   
                </div>

                <div class=" ml-auto pl-2">
                    <i class=" fas fa-credit-card icon mr-1 text-secondary"></i>
                    <i *ngIf="getCompleatTransaction()" class="text-success fas fa-check-circle check-compleat"></i>
                    <i *ngIf="!(getCompleatTransaction())" class="text-warning fas fa-info-circle check-compleat "></i>

                </div>

            </div>

            <div class="w-100 mt-3">


                <div class="d-flex w-100 mb-2 fz-14 ">

                    <div class="ml-auto text-right " *ngIf="getIndicatorsProgresData(transactionData.plan.url)?.percent > 0">

                        {{getIndicatorsProgresData(transactionData.plan.url)?.percent}}%

                    </div>

                </div>
                <div>
                    <akeela-graphics [percent]="getIndicatorsProgresData(transactionData.plan.url)?.percent" [type]="'line'">
                    </akeela-graphics>
                </div>

            </div>

            <div class="w-100 d-flex fz-14  mt-3 ">

                <div class="fz-12" *ngIf="transactionData?.dateOfThing?.modifiedDate">
                    <span class="text-secondary">Última actualización </span>
                    <br> {{transactionData?.dateOfThing?.modifiedDate | date: 'd MMM, y h:mm a'}}
                </div>

                <div class="ml-auto pt-3 " *ngIf="getSedCompleatTransaction()" href="javascript:void(0) ">

                    <a class="text-primary "> Completar</a>

                </div>

            </div>

        </div>
    </div>


</div>
-->
                    <div class="container mt-2">





                        <div class="text-left row" *ngIf="optActive==4">
                            <div class="col-12 ">

                                <div *ngIf="countDocuments(documents)" class=" ">

                                    <div class="d-flex w-100 ">
                                        <span class="font-weight-bold text-secondary "> {{'Formatos de la Solicitud' | i18n}}</span>

                                    </div>
                                    <div class=" fz-14 mt-4 animate__animated alert alert-warning w-100 text-secondary p-2 ">
                                        <div [innerHTML]="'@i18n-formatoSol' | i18n"></div>

                                    </div>

                                    <div class="mt-2">
                                        <app-related-documents-generated *ngIf="transactionData.accountStatus[0].additionalType !=='AC_REJECTED' || transactionData.accountStatus[0].additionalType !=='AC_RECEIVED' " [name]="transactionData?.person ? capitalize(transactionData?.person?.givenName)
                                    + ' ' + capitalize(transactionData?.person?.familyName) : capitalize(transactionData?.organization?.legalName) " (response)="show_filePreview($event) " [documents]="documents "></app-related-documents-generated>
                                    </div>

                                    <div [innerHTML]="'@i18n-declaracionJurada-contrato' | i18n"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>

    <!--  
<ng-container>


    <pre>
        {{ indicatorsProgres | json }}
    </pre>

    <div>Hola {{dataInformation?.completeBriefcase}}</div>

</ng-container>


@i18n-quote-interIdentityVerifEnd-section.title
-->