<div class="mt-1  animated fadeIn">

    <div class="jumbotron jumbotron-fluid p-3 ">

        <div class="d-flex bd-highlight">

            <div>
                <h5 class="font-weight-normal">
                    <i class="fas fa-building pr-1"></i> Agencias
                </h5>
            </div>

            <div class="ml-auto">
                <i class="fas fa-ellipsis-v "></i>
            </div>
        </div>

    </div>

    <div class="w-100  mb-3">
        <!-- this creates a google map on the page with the given lat/lng from -->
        <!-- the component as the initial center of the map:
                -->
        <agm-map [fitBounds]="true" [latitude]="lat" [longitude]="lng" [zoom]='zoom' [mapTypeId]="mapTypeId">
            <agm-marker [agmFitBounds]="true" [latitude]="10.4911563" [longitude]="-67.4844948" [iconUrl]="
            {
              url:'./assets/mibanco/public/images/image-min.png',
              scaledSize: {
                  width:30,
                  height:30
              }
          }">
                <agm-info-window>
                    <div style="padding: 5px">
                        <b>Agencia Centro Lido</b><br>
                        <p>Caracas</p>
                    </div>

                </agm-info-window>
            </agm-marker>
            <agm-marker [agmFitBounds]="true" [latitude]="10.3457046" [longitude]="-66.8647473" [iconUrl]="
            {
                url:'./assets/mibanco/public/images/image-min.png',
                scaledSize: {
                    width:30,
                    height:30
                }
          }">
                <agm-info-window>
                    <div style="padding: 5px">
                        <b>Agencia Turmero</b><br>
                        <p>Maracay</p>
                    </div>

                </agm-info-window>
            </agm-marker>

            <agm-marker [agmFitBounds]="true" [latitude]="10.3453413" [longitude]="-67.7883512" [iconUrl]="
            {
                url:'./assets/mibanco/public/images/image-min.png',
                scaledSize: {
                    width:30,
                    height:30
                }
          }">
                <agm-info-window>
                    <div style="padding: 5px">
                        <b>Agencia Las Delicias</b><br>
                        <p>Maracay, Aragua</p>
                    </div>

                </agm-info-window>
            </agm-marker>



        </agm-map>
    </div>


    <div class="w-100 ">



        <div class="row mb-3 ">
            <div class="col-12 text-primary col-md-6">
                <div class="d-inline-flex w-100">
                    <input class="form-control active-cursor padding-filter-searh-input" type="text" placeholder='Agencia, Dirección, Tlf., Coordinador' (keyup)="updateFilter ($event.target.value)" />
                    <i class="fas fa-search icon-filter-searh"></i>

                </div>

                <div class="w-100 animate__animated animate__fadeInDown">
                    <ul class="scrollmenu pl-0 pt-2 ">
                        <li class="d-inline">
                            <div class="item-rd text-secondary  border-primary fz-14 active">Persona Natural
                                <i class="fa fa-times ml-2 active-cursor" aria-hidden="true"></i>

                            </div>

                        </li>
                    </ul>
                </div>

            </div>
        </div>



        <div class="mt-3 w-100">

            <div class=" ">
                <i class="fas fa-redo text-secondary"></i>
                <span class="text-secondary float-right ">
                                10 - 25 de 25 <i class="fas fa-chevron-left mr-1 text-secondary active-cursor"></i>
                              </span>
            </div>

        </div>
        <hr>



        <div *ngIf="!loading_table">


            <ng-container *ngFor="let row of rows ; let i = index">
                <div class="card mt-4 ak-shadow-box" *ngIf="i < 100">
                    <div class="card-body p-3 fz-14 ">
                        <div class="d-flex bd-highlight  flex-wrap ">
                            <div class="w-10 d-block d-md-none  ">
                                <i class="fas fa-align-left pr-2" style="font-size: 18px;"></i>

                            </div>
                            <div class="w-70 ">

                                <div ngbDropdown display="dynamic">

                                    <div style="cursor: pointer" ngbDropdownToggle id="navbarDropdown{{i}}" class=" navbar-brand-profile ak-vertical-center ">

                                        <div class="elipsse">

                                            <!-- <i class="fas fa-align-left pr-2 d-none d-md-inline" ></i>-->
                                            <span class="font-weight-bold active-cursor text-capitalize" style="font-size: 18px;">{{row.name}}</span>

                                        </div>
                                    </div>

                                    <div style="width: 250px;" ngbDropdownMenu aria-labelledby="navbarDropdown{{i}}" class="dropdown-menu text-center">
                                        <div class="p-2 fz-14 d-flex bd-highlight  list-flex flex-wrap text-left size-caja-dopd">
                                            <!--  <div class="w-15">
                                                                                        <i class=" pr-2 fas fa-align-left  "></i> 
                                                                                     </div>-->
                                            <div class="w-100 fz-12">
                                                <div>
                                                    <div class="font-weight-bold text-capitalize">{{row?.name}}</div>
                                                    <div class=" text-secondary">{{row?.alternateName}}</div>

                                                </div>


                                                <div class="pt-3">
                                                    <div class="font-weight-bold">Tlf. </div>
                                                    <div class=" text-secondary">{{row?.telephone}}</div>
                                                </div>

                                                <div class=" pt-2">
                                                    <div class="font-weight-bold">Correo</div>
                                                    <div class=" text-secondary">{{row?.email}}</div>
                                                </div>

                                                <div class=" pt-2">
                                                    <div class="font-weight-bold">channel</div>
                                                    <div class=" text-secondary">{{row?.channel}}</div>
                                                </div>

                                                <div class=" pt-2">
                                                    <div class="font-weight-bold">Dirección</div>
                                                    <div class=" text-secondary">{{row?.address}}</div>
                                                </div>




                                            </div>


                                        </div>


                                    </div>
                                </div>



                                <!--- <div  class=" text-capitalize mt-1"><span class="font-weight-bold">{{row?.status}}</span> 
                                                        {{row?.status2}}
                                                </div> -->


                            </div>

                            <div class="bd-highlight ml-auto  text-right fz-18">

                                <i *ngIf="i > 2" class="fas fa-map-marker-alt  mr-3 active-cursor text-primary"></i>
                                <i *ngIf="i < 2" class="fas fa-street-view  mr-3 active-cursor text-primary "></i>
                                <i class="fas fa-ellipsis-v "></i>

                            </div>
                            <div class=" text-secondary w-100">Caracas, La Boyera, Tlf. 0212-9631841</div>


                        </div>


                        <div class="d-flex bd-highlight  flex-wrap ">
                            <div class="w-100">
                                <hr>
                            </div>


                            <div class=" w-100 text-center mb-4">
                                <div class="DomainUserAvatar-avatar Avatar--color-J AvatarPhoto AvatarPhoto--coord" role="img">JS</div>
                                <div class="text-secondary mt-2 font-weight-bold fz-16">Juan Soto </div>
                            </div>



                            <div class=" w-100 text-center mb-4">
                                <div class="text-dark mb-2 font-weight-bold" *ngIf="this.executivesAgency[row?.alternateName]">Ejecutivos</div>

                                <div class="w-100 animate__animated animate__fadeInDown">
                                    <ul class="scrollmenu pl-0 pt-2 ">
                                        <li class="d-inline">

                                            <div class="d-flex d-md-none">

                                                <ng-container *ngFor="let executive of executives ; let i = index">
                                                    <div class="ml-2 mr-2" *ngIf="executive?.agenciaId == row?.alternateName">
                                                        <div class="DomainUserAvatar-avatar Avatar--color-R AvatarPhoto AvatarPhoto--eje" role="img">{{getInitials(executive.name)}}</div>
                                                        <div class="text-secondary mt-2 text-muted">{{executive?.name}}</div>
                                                    </div>
                                                </ng-container>

                                            </div>

                                            <div class="d-none d-md-flex  justify-content-center">

                                                <ng-container *ngFor="let executive of executives ; let i = index">
                                                    <div class="ml-2 mr-2" *ngIf="executive?.agenciaId == row?.alternateName">
                                                        <div class="DomainUserAvatar-avatar Avatar--color-R AvatarPhoto AvatarPhoto--eje" role="img">{{getInitials(executive.name)}}</div>
                                                        <div class="text-secondary mt-2 text-muted">{{executive?.name}}</div>
                                                    </div>
                                                </ng-container>

                                            </div>

                                        </li>
                                    </ul>
                                </div>

                            </div>




                            <div class=" w-100 ">

                                <div class=" mb-1 mt-2">
                                    <div class="font-weight-bold mb-2 active-cursor">
                                        <i class="fas  fa-user mr-2"></i> Registros <i class="ml-2 fas fa-chevron-right text-primary"></i>
                                    </div>

                                    <ul class=" pl-0 mb-0  ">
                                        <li class="d-inline">
                                            <div class="item-rd  fz-14  active-cursor">Sin verificar email <span class="font-weight-bold">122 (10%)</span> </div>
                                            <div class="item-rd fz-14     active-cursor">Sin solicitud <span class="font-weight-bold">10 (3%)</span> </div>

                                        </li>
                                    </ul>
                                    <div class=" ml-2 fz-14 text-muted mt-1">
                                        <span class="font-weight-normal ">Ult. registro 13/01/2022 15:14</span>
                                    </div>

                                </div>

                            </div>



                            <div class=" w-100 mt-4">

                                <div class=" mb-1 mt-2">
                                    <div class="font-weight-bold mb-2 active-cursor" (click)="openNew(row.identifier)">
                                        <i class="fas fa-chart-line mr-2"></i> Conversión <i class="ml-2 fas fa-chevron-right text-primary"></i>
                                    </div>

                                    <ul class=" pl-0 mb-0  ">
                                        <li class="d-inline" (click)="openNew(row.identifier)">
                                            <div class="item-rd  fz-14  active-cursor">En Proceso <span class="font-weight-bold">122</span> </div>
                                            <div class="item-rd fz-14     active-cursor">Devueltos <span class="font-weight-bold">10</span> </div>
                                            <div class="item-rd item-rd-2  fz-14   active-cursor">Recibidas <span class="font-weight-bold">50</span> </div>

                                        </li>
                                    </ul>
                                </div>

                            </div>




                            <div class="w-100 pl-0 mt-4">
                                <div class="active-cursor">
                                    <i class="fas fa-chart-pie mr-2 "></i>
                                    <span class="font-weight-bold">Estadísticas </span>
                                    <i class="ml-2 fas fa-chevron-right text-primary"></i>

                                </div>

                                <ul class="scrollmenu pl-0 mt-3">
                                    <li class="d-inline">
                                        <div class="card mb-2 boxstatus active-cursor ">
                                            <div class="card-body p-3 ">
                                                <div class="d-flex bd-highlight  flex-wrap ">
                                                    <div class="w-100 font-weight-bold mb-2">
                                                        Aprobadas
                                                    </div>
                                                    <div>
                                                        <h3 class="font-weight-bold number-car">40</h3>
                                                    </div>

                                                    <div class="w-100 mb-1">
                                                        Tasa de corvensión <span class="font-weight-bold">33%</span>

                                                    </div>
                                                    <div class="w-100 ">
                                                        Total solicitudes <span class="font-weight-bold">121</span>

                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li class="d-inline ml-3">
                                        <div class="card mb-2 boxstatus active-cursor ">
                                            <div class="card-body p-3 ">
                                                <div class="d-flex bd-highlight  flex-wrap ">
                                                    <div class="w-100 font-weight-bold mb-2">
                                                        Velocidad

                                                    </div>

                                                    <div class="w-100 mb-1">
                                                        Nuevas
                                                        <div class="float-right font-weight-bold">10/día</div>
                                                    </div>

                                                    <div class="w-100  mb-1">
                                                        Recibidas
                                                        <div class="float-right font-weight-bold">5/día</div>
                                                    </div>

                                                    <div class="w-100 ">
                                                        Aprobadas
                                                        <div class="float-right font-weight-bold">3/día</div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li class="d-inline ml-3">
                                        <div class="card mb-2 boxstatus active-cursor ">
                                            <div class="card-body p-3 ">
                                                <div class="d-flex bd-highlight  flex-wrap ">
                                                    <div class="w-100 font-weight-bold mb-2">
                                                        Desiertas

                                                    </div>
                                                    <div>
                                                        <h2 class="font-weight-bold number-car">330</h2>
                                                    </div>

                                                    <div class="w-100 mb-2 ">
                                                        Tasa de desierto <span class="font-weight-bold">77%</span>

                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li class="d-inline ml-3">
                                        <div class="card mb-2 boxstatus active-cursor ">
                                            <div class="card-body p-3 ">
                                                <div class="d-flex bd-highlight  flex-wrap ">
                                                    <div class="w-100 font-weight-bold mb-2">
                                                        Esta semana
                                                    </div>

                                                    <div class="w-100 mb-1">
                                                        Solicitudes
                                                        <div class="float-right font-weight-bold">50 <i class="ml-1 fas fa-arrow-up text-success"></i></div>
                                                    </div>
                                                    <div class="w-100  mb-1">
                                                        Aprobadas
                                                        <div class="float-right font-weight-bold">30 <i class="ml-1 fas fa-arrow-down text-danger"></i></div>
                                                    </div>
                                                    <div class="w-100  ">
                                                        Desiertas
                                                        <div class="float-right font-weight-bold">50 <i class="ml-1 fas fa-arrow-up text-success"></i></div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li class="d-inline ml-3">
                                        <div class="card mb-2 boxstatus active-cursor " style="width: 260px;">
                                            <div class="card-body p-3 ">
                                                <div class="d-flex bd-highlight  flex-wrap ">
                                                    <div class="w-100 font-weight-bold mb-2">
                                                        Ultima interacción
                                                    </div>

                                                    <div class="w-100  ">
                                                        <div>Solicitudes</div>
                                                        <span class="number-car">Juan soto</span>
                                                        <div class="float-right font-weight-bold fz-12">Hoy 10:31 AM</div>

                                                    </div>
                                                    <div class="w-100  mt-2">
                                                        <div>Aprobaciones</div>

                                                        <span class="number-car">Mario Díaz</span>
                                                        <div class="float-right font-weight-bold fz-12">Ayer 11:45 AM</div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </li>



                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>



        <ng-container *ngIf="loading_table">
            <div class="row">
                <div class="col-12">
                    <img src="./assets/public/images/loading.gif" alt="">
                </div>
            </div>
        </ng-container>
    </div>
</div>