<ng-container>
    <div>
        <img src="./assets/public/images/loading.gif " alt=" ">
    </div>
</ng-container>

<!-- 
    //http://localhost:4200/private/ACCOUNT_OPENING/stages/LIST_CLIENTS/steps/VIEW_CLIENT?audience=LEGAL&viewUsername=rhernandez&profile=ejecutivo&role=USER

<div class="animated fadeIn">
    <div class="row skin-white">
        <ng-container *ngIf="!client">
            <div class="row">
                <div class="col-lg-12">
                    <img class="float-left" src="./assets/public/images/loading.gif" alt="">
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="client">
            <div class="col-12 fz-body">
                <ng-container *ngIf="client.adminOrganization || client.person">

                    <div class="row ">
                        <div class="col-12 col-sm-12 col-md-8 col-lg-8">
                            <div>
                                <div class="h2 text-capitalize" *ngIf="client?.personMarketType?.identifier==='NATURAL'">
                                    {{ client.person.givenName + ' ' + client.person.familyName }} &nbsp;
                                </div>

                                <div *ngIf="client?.adminOrganization?.legalName" class="h2 text-capitalize">

                                    {{ client?.adminOrganization?.legalName }}
                                </div>
                                <ng-container *ngIf="client.adminOrganization">
                                    <span style="font-size: 0.875rem" class="text-color-gray " [innerHTML]=" 'Siglas: '" *ngIf="client.adminOrganization.acronym"></span>
                                    <span style="font-size: 0.875rem" class=" " [innerHTML]=" client.adminOrganization.acronym" *ngIf="client.adminOrganization"></span>
                                    <ng-container *ngIf="client.adminOrganization.acronym">・</ng-container>
                                </ng-container>
                                <br *ngIf="nationality">
                                <span *ngIf="nationality" style="font-size: 0.875rem" class="text-color-gray " [innerHTML]=" 'Nacionalidad: '"></span>
                                <span *ngIf="nationality" style="font-size: 0.875rem" class=" " [innerHTML]="nationality"></span>
                                <br>
                                <span style="font-size: 0.875rem" class="text-color-gray " [innerHTML]=" 'Teléfono: '" *ngIf="client.person?.telephone"></span>
                                <span style="font-size: 0.875rem" class=" " [innerHTML]="client?.person?.telephone"></span>

                                <br>
                                <span style="font-size: 0.875rem" class="text-color-gray " [innerHTML]=" 'Estatus: '"></span>
                                <span style="font-size: 0.875rem" class=" " [innerHTML]="client.status==='ACTIVE'? 'Activo':'Ináctivo'"></span>
                                <br>
                                <span style="font-size: 0.875rem" class="text-color-gray " [innerHTML]=" 'Tipo: '"></span>
                                <span style="font-size: 0.875rem" class=" " [innerHTML]="client.personMarketType.identifier==='NATURAL'? ('@i18n-quote-option-list-applicantType-option-natural' | i18n): ('@i18n-quote-option-list-applicantType-option-legal' | i18n)"></span>
                                <ng-container *ngIf="client?.adminOrganization?.legalForm?.name">・</ng-container>
                                <span style="font-size: 0.875rem" class="text-color-gray " [innerHTML]=" ''" *ngIf="client.adminOrganization"></span>
                                <span style="font-size: 0.875rem" class=" " [innerHTML]=" client?.adminOrganization?.legalForm?.name" *ngIf="client.adminOrganization"></span>
                                <br>
                                <span style="font-size: 0.875rem" class="text-color-gray " [innerHTML]=" 'Creado, '+ ( client.createDate | datePipe: 'simple') "></span>


                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 text-right">
                            <div *ngIf="!isAnalist && !readonly  && ((paginator)  && ((paginator?.content?.length < 1) && ruleUniqueTransaction===true) || ruleUniqueTransaction===false)">
                                <button (click)="new()" class="btn btn-outline-primary">
                                    Nueva Solicitud
                                </button>

                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <hr class="font-color-gray">
                        </div>
                    </div>

                    <div class="row ">
                        <div class="col-12 col-sm-12 col-md-8 col-lg-8">
                            <div>

                                <div class="h4 ">

                                    {{ 'Atención al Cliente' }} &nbsp;

                                </div>



                                <span style="font-size: 0.875rem" class="text-color-gray " [innerHTML]=" 'Siglas: '"></span>
                                <span style="font-size: 0.875rem" class=" " [innerHTML]=" client?.salesManagerUser?.localBusiness?client?.salesManagerUser?.localBusiness?.name:'-'"></span>

                                <br>
                                <span style="font-size: 0.875rem" class="text-color-gray " [innerHTML]=" 'Ejecutivo: '"></span>
                                <span style="font-size: 0.875rem" class=" " [innerHTML]="client?.salesManagerUser?.person ?
                                client?.salesManagerUser?.person?.givenName +' '+
                                client?.salesManagerUser?.person?.familyName:'-'"></span>


                            </div>
                        </div>

                    </div>






                    <div class="row">
                        <div class="col-12">
                            <hr class="font-color-gray">
                        </div>
                    </div>






                </ng-container>



                <div class="row">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                        <div>
                            <div class="h4">Usuarios Administradores&nbsp;&nbsp;</div>
                        </div>
                    </div>
                </div>

                <app-table-admin-users [client]="client" *ngIf="client"></app-table-admin-users>


                <div class="row">
                    <div class="col-12">
                        <hr class="font-color-gray">
                    </div>
                </div>


                <div class="row">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                        <div>
                            <div class="h4">Solicitudes relacionadas&nbsp;&nbsp;</div>
                            <br>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">

                        <ng-container *ngIf="transactions && configuration_table ">
                            <akeela-table [configuration]="configuration_table" [order]="filter_order" [loading]="loading_table" [paginator]="paginator" (response)="table_response($event)">
                            </akeela-table>
                            <br>
                        </ng-container>

                    </div>
                </div>

                <div class="row">
                    <br>
                </div>

            </div>
        </ng-container>
    </div>
</div>
-->