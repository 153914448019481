import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, ErrorHandler } from '@angular/core';

// MODULES...
import { I18nModule } from '@akeela/i18n';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';


// COMPONENTS
import { ResumenComponent } from './components/resumen/resumen.component';
import { ResumeComponent } from './components/resume/resumen.component';
import { MinResumeComponent } from './components/min-resumen/min-resumen.component';

import { WorkflowComponent } from './components/workflow/workflow.component';
import { EmbedComponentsComponent } from './components/workflow/embed-components/embed-components.component';

import { RelatedDocumentsGenerated } from './components/related-documents-generated/related-documents-generated.component';
import { PaperworkComponent } from './components/paperwork/paperwork.component';
import { GeneralComponent } from './components/resumen/general/general.component';
import { DetailFormalityComponent } from './components/detail-formality/detail-formality.component';
import { ListFormalitiesRelationshipComponent } from './components/list-formalities-relationship/list-formalities-relationship.component';
import { DetailOperationDataComponent } from './components/detail-operation-data/detail-operation-data.component';
import { ListFormalitiesRelatedComponent } from './components/list-formalities-related/list-formalities-related.component';
import { DetailResponsesComponent } from './components/detail-responses/detail-responses.component';
import { ListFormalitiesDocumentationComponent } from './components/list-formalities-documentation/list-formalities-documentation.component';
import { HeaderComponent } from './components/resumen/header/header.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ListAccountAnalysisComponent } from './components/list-account-analysis/list-account-analysis.component';
import { ListClientsComponent } from './components/list-clients/list-clients.component';
import { DetailClientComponent } from './components/detail-client/detail-client.component';
import { RefreshService } from './services/refresh.service';
import { ProgressService } from './services/progress.service';

import { SectionLegalRepresentativeComponent } from './components/section-legal-representative/section-legal-representative.component';
import { SectionOtherLegalRepresentativeComponent } from './components/section-other-legal-representative/section-other-legal-representative.component';
import { SectionBoardOfDirectorComponent } from './components/section-board-of-directors/section-board-of-directors.component';
import { SectionBankAccountInstitutionComponent } from './components/section-bank-account-institution/section-bank-account-institution.component';
import { SectionBankAccountOtherInstitutionComponent } from './components/section-bank-account-other-institution/section-bank-account-other-institution.component';
import { SectionShareholdersComponent } from './components/section-shareholders/section-shareholders.component';
import { SectionShareholdersRelatedsComponent } from './components/section-shareholders-relateds/section-shareholders-relateds.component';

import { SectionSuppliersComponent } from './components/section-suppliers/section-suppliers.component';
import { SectionCustomersComponent } from './components/section-customers/section-customers.component';
import { SectionRelatedCompanyComponent } from './components/section-related-company/section-related-company.component';
import { SectionPepComponent } from './components/section-pep/section-pep.component';
import { SwornComponent } from './components/sworn/sworn.component';
import { PayrollComponent } from './components/payroll/payroll.component';
import { ClientExecutiveComponent } from './components/client-executive/client-executive';
import { ConstitutionDataComponent } from './components/section-constitution-data/section-constitution-data.component';
import { SectionPepBoardOfDirectorsComponent } from './components/section-pep-boardofdirectors/section-pep-boardofdirectors.component';
import { SectionPepShareHoldersComponent } from './components/section-pep-shareholders/section-pep-shareholders.component';
import { RequirementsComponent } from './components/requirements/requirements.component';
import { ComponentsComponent } from './components/components.component';
import { RequirementsResponseComponent } from './components/requirements-response/requirements-response.component';
import { IncompletedCommentsComponent } from './components/incompleted-comments/incompleted-comments.component';
import { PreviuslyRevisionsComponent } from './components/previusly-revisions/previusly-revisions.component';
import { PaperworkUserComponent } from './components/paperwork-user/paperwork-user.component';
import { DetailResumenComponent } from './components/detail-resumen/detail-resumen.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { SectionCommercialReferencesComponent } from './components/section-commercial-references/section-commercial-references.component';
import { SectionPepPNAssociationComponent } from './components/section-pep-pn-association/section-pep-pn-association.component';
import { SectionFiscalDirectionsComponent } from './components/section-fiscal-directions/section-fiscal-directions.component';
import { NgbModule, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ResumenFooterComponent } from './components/resumen/sections/footer/footer.component';
import { ResumenHeaderComponent} from './components/resumen/sections/header/header.component';
/**AIzaSyD4vQN9WVbH1RVFnoWDe1DNDxkHoV2u4_k */
/* PN */

import { SectionLegalRepresentativePNComponent } from './components/section-legal-representative-pn/section-legal-representative-pn.component';
import { SectionCustomersPNComponent } from './components/section-customers-pn/section-customers-pn.component';
import { SectionPersonalReferencesPNComponent } from './components/section-personal-references-pn/section-personal-references-pn.component';
import { SectionSuppliersPNComponent } from './components/section-suppliers-pn/section-suppliers-pn.component';
import { SectionPepPNComponent } from './components/section-pep-pn/section-pep-pn.component';
import { environment } from 'src/environments/environment';
import { WorkspacesComponent } from './components/workspaces/workspaces.component';
import { ListAgenciesComponent } from './components/list-agencies/list-agencies.component';
import { ListSalesRepresentativeComponent } from './components/list-sales-representative/list-sales-representative.component';




import { GuideComponent } from './components/resume/guide/guide.component';
import { SectionNewtransactionHeaderComponent } from './components/section-newtransaction-header/section-newtransaction-header.component';
import { TableAdminUsersComponent } from './components/detail-client/table-admin-users/table-admin-users.component';
import { AgmCoreModule } from '@agm/core';
import { ClientDetailComponent } from './components/client-detail/client-detail.component';
import { PreviewImgComponent } from './components/preview-img/preview-img.component';
import { JitsiComponent } from './components/jitsi/jitsi.component';

import { TransactionDetailComponent } from './components/transaction-detail/transaction-detail.component';
import { TransactionRequirementsPreviewComponent } from './components/transaction-requirements-preview/transaction-requirements-preview.component';
import { AkeelaMeetComponent } from './components/akeela-meet/akeela-meet.component';
import { AkeelaDashboardComponent } from './components/akeela-dashboard/akeela-dashboard.component';
import { AkeelaUserVerificationComponent } from './components/akeela-user-verification/akeela-user-verification.component';
import { ListMeetComponent } from './components/list-meet/list-meet.component';
import { FaceApiAnalisisComponent } from './components/face-api-analisis/face-api-analisis.component';
import { ClientDashboardComponent } from './components/client-dashboard/client-dashboard.component';
import { TransactionDashboardComponent } from './components/transaction-dashboard/transaction-dashboard.component';
import { NewTransactionComponent } from './components/new-transaction/new-transaction.component';
import { ListGuestComponent } from './components/list-guest/list-guest.component';
import { SendClientSectionComponent } from './components/send-client-section/send-client-section.component';
import { UpdateEmailPhoneComponent } from './components/update-email-phone/update-email-phone.component';
import { ListOrdersComponent } from './components/list-orders/list-orders.component';
import { OrdersActionsComponent } from './components/orders-actions/orders-actions.component';
import { NotesComponent } from './components/notes/notes.component';
import { streamComponent } from './components/stream/stream.component';
import { ListExportsComponent } from './components/list-exports/list-exports.component';
import { CVVComponent } from './components/cvv/cvv.component';

import { InvestorProfileComponent } from './components/investor-profile/investor-profile.component';
import { InvestorProfileBriefcaseComponent } from './components/investor-profile-briefcase/investor-profile-briefcase.component';
import { ListClientsPaginatedComponent } from './components/list-clients-paginated/list-clients-paginated.component';



@NgModule({
    imports: [
        CommonModule,
        I18nModule.config(environment.i18nConfig),
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        BsDropdownModule.forRoot(),
        NgbModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD4vQN9WVbH1RVFnoWDe1DNDxkHoV2u4_k'
          }),
        

    ],
    providers: [
        NgbDropdown,
        RefreshService,
        ProgressService
    ],
    declarations: [
        ClientDashboardComponent,
        BreadcrumbComponent,
        ResumenComponent,
        WorkflowComponent,
        EmbedComponentsComponent,
        PaperworkComponent,
        GeneralComponent,
        DetailFormalityComponent,
        ListFormalitiesRelationshipComponent,
        DetailOperationDataComponent,
        ListFormalitiesRelatedComponent,
        DetailResponsesComponent,
        ListFormalitiesDocumentationComponent,
        ResumenHeaderComponent,
        ListAccountAnalysisComponent,
        ListClientsComponent,
        DetailClientComponent,
        ResumeComponent,
        MinResumeComponent,
        SectionLegalRepresentativeComponent,
        SectionOtherLegalRepresentativeComponent,
        SectionBoardOfDirectorComponent,
        SectionBankAccountInstitutionComponent,
        SectionBankAccountOtherInstitutionComponent,
        SectionShareholdersComponent,
        SectionShareholdersRelatedsComponent,
        SectionSuppliersComponent,
        SectionCustomersComponent,
        SectionRelatedCompanyComponent,
        SwornComponent,
        SectionPepComponent,
        PayrollComponent,
        ClientExecutiveComponent,
        ConstitutionDataComponent,
        SectionPepBoardOfDirectorsComponent,
        SectionPepShareHoldersComponent,
        RequirementsComponent,
        ComponentsComponent,
        RequirementsResponseComponent,
        IncompletedCommentsComponent,
        PreviuslyRevisionsComponent,
        PaperworkUserComponent,
        DetailResumenComponent,
        ChangePasswordComponent,
        SectionCommercialReferencesComponent,
        SectionLegalRepresentativePNComponent,
        SectionPepPNComponent,
        SectionCustomersPNComponent,
        SectionPersonalReferencesPNComponent,
        SectionSuppliersPNComponent,
        WorkspacesComponent,
        SectionPepPNAssociationComponent,
        SectionFiscalDirectionsComponent,
        RelatedDocumentsGenerated,
        GuideComponent,
        SectionNewtransactionHeaderComponent,
        TableAdminUsersComponent,
        ResumenFooterComponent,
        HeaderComponent,
        ListAgenciesComponent,
        ListSalesRepresentativeComponent,
        ClientDetailComponent,
        PreviewImgComponent,
        JitsiComponent,
        TransactionDetailComponent,
        TransactionRequirementsPreviewComponent,
        AkeelaMeetComponent,
        AkeelaDashboardComponent,
        AkeelaUserVerificationComponent,
        ListMeetComponent,
        FaceApiAnalisisComponent,
        TransactionDashboardComponent,
        NewTransactionComponent,
        ListGuestComponent,
        ListOrdersComponent,
        SendClientSectionComponent,
        UpdateEmailPhoneComponent,
        OrdersActionsComponent,
        NotesComponent,
        CVVComponent,
        streamComponent,
        ListExportsComponent,
        InvestorProfileComponent,
        InvestorProfileBriefcaseComponent,
        ListClientsPaginatedComponent


    ],
    exports: [ResumenFooterComponent, ResumenHeaderComponent, ResumenComponent, WorkflowComponent, EmbedComponentsComponent, ChangePasswordComponent,SendClientSectionComponent
    ],
})
export class PrivateModule {
    constructor() {
        I18nModule.config(environment.i18nConfig);
    }
}