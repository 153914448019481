<div>
    <div class=" mt-2" style="line-height: 1.4rem">

        <div *ngIf="!(link!==null) ">
            <div class="h4 font-weight-normal title " [innerHTML]="title | i18n"></div>
        </div>

        <div *ngIf="(link!==null) ">
            <div class="h4 font-weight-normal title" [innerHTML]="_transactionData?.plan?.name | i18n"></div>
        </div>

        <div *ngIf="subtitle!=='' && !(link!==null) ">
            <span class="font-weight-light sub-title text-secondary-2 " [innerHTML]="subtitle | i18n"></span>
        </div>

    </div>
    <div *ngIf="_transactionData" style="font-size: 14px">
        <!-- <div [innerHTML]="'<b>Solicitud de '+(_transactionData?.plan?.name)+' '+(_transactionData?.transactionNumber)" class=""></div>
        <span [innerHTML]="'<b>Estatus: </b>'+ _transactionData?.accountStatus[0]?.clientName" class=""></span>
        &nbsp;
        <a (click)='view_mode_active()' href="javascript:void()">{{ (view_more?'Ver menos': 'Ver más') }}</a>
        <div [innerHTML]="'<span class=\'color-gray\'>'+(_transactionData?.dateOfThing?.modifiedDate?(_transactionData?.dateOfThing?.modifiedDate | date: 'd MMM, yyyy'):(_transactionData?.dateOfThing?.startDate | date: 'd MMM, y h:mm a'))+'.</span> '+_transactionData?.accountStatus[0]?.clientDescription+''"
            class="" *ngIf="view_more"></div> -->

        <div [innerHTML]="'Solicitud N° '+'<b>'+(_transactionData?.transactionNumber)" class=""></div>




        <div *ngIf="showTemplates" class="mt-2 mb-3 ak-font-roboto animate__animated animate__fadeInDown mt-2 alert ak-status alert-secondary  w-100 text-left text-dark p-1 pt-2 pb-2 " [class.alert-warning]="_transactionData?.accountStatus[0]?.additionalType == 'AC_INCOMPLETE' "
            [class.alert-primary]="transactio_transactionDatanData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS'" [class.alert-primary]="_transactionData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS' || _transactionData?.accountStatus[0]?.additionalType == 'AC_PENDING' ">
            <i class=" text-secondary pl-2 pr-2 fas fa-info-circle " aria-hidden="true" style="    line-height: 26px;"></i>

            <span class="font-weight-bold ">
                    {{_transactionData?.accountStatus[0]?.clientName}}
                </span>

            <span class='text-secondary float-right d-block d-md-none'> 
                        {{(_transactionData?.dateOfThing?.modifiedDate?(_transactionData?.dateOfThing?.modifiedDate | date: 'd MMM, yyyy'):(_transactionData?.dateOfThing?.startDate | date: 'd MMM, y h:mm a'))}}
                       </span>

            <br class="d-block d-md-none">
            <span class="text-dark pr-3 pl-2 pl-md-0">{{_transactionData?.accountStatus[0]?.clientDescription}}</span>

            <button *ngIf="incomplete" (click)="showModalResponses = true" class="ml-2 ml-md-0  mt-2 mt-md-0 btn btn-outline-secondary  btn-sm">
                        <i class=" mr-1 far fa-comments text-primary" ></i>
                        Respuesta y comentarios
                        <i class=" pl-2 fas fa-circle text-danger blink_me" style="font-size: 7px" ></i>
                  </button>


            <span class='text-secondary float-right d-none d-md-block'> 
                        {{(_transactionData?.dateOfThing?.modifiedDate?(_transactionData?.dateOfThing?.modifiedDate | date: 'd MMM, yyyy'):(_transactionData?.dateOfThing?.startDate | date: 'd MMM, y h:mm a'))}}
                       </span>
        </div>
    </div>

    <div class="col-12" style="text-align: right" *ngIf="modeEdit">
        <div>
            <button class="btn btn-primary" (click)="event_response('finishedit')">Finalizar Edición</button>
        </div>
    </div>
    <div class="col-12" style="text-align: right" *ngIf="_viewMode && !modeEdit">
        <div>
            <button class="btn btn-primary" (click)="event_response('finishview')">Finalizar Vista</button>
        </div>
    </div>

    <!-- {{ _viewMode }} -->

    <app-menu-module *ngIf="_transactionData" [transactionData]="_transactionData" [selected]="currentWorkflowDefault" (response)="event_response($event)"></app-menu-module>


</div>