import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpService } from '@akeela/properties';
import { AuthService, ErrorService } from '@akeela/auth';
import { DatePipe } from '@angular/common';
import { isNullOrUndefined } from 'util';
import swal from 'sweetalert2';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LocalService } from '@akeela/local-storage';
import { environment } from '../../../../../environments/environment';
import {
  AkeelaWorkflowService,
  AkeelaStageService,
  AkeelaStepService,
  AkeelaSectionService
} from '@akeela/workflow';



@Component({
  selector: 'app-investor-profile',
  templateUrl: './investor-profile.component.html',
  styleUrls: ['./investor-profile.component.css']
})
export class InvestorProfileComponent implements OnInit {
  readonly = false;
  formLoaded = false;
  sectionName = 'INVESTOR_PROFILE';
  env;
  loading_form = false;
  reasonsList$;
  sourceOfFundsList$;
  profileInvestorList$;
  investorExperienceList$;
  investorObjetiveList$;
  currency;
  transitionsWfs = [];

  request_reasons_list = [];

  investorInformation = null;

  form = this.formBuilder.group({});

  @Input() briefcase?: boolean;

  compleat = false;


  constructor(
    private _error: ErrorService,
    private formBuilder: FormBuilder, 
    private _httpService: HttpService,
    private _auth: AuthService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    public localStorage: LocalService,
    public akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStepService: AkeelaStepService,
  ) { }

  async ngOnInit() {

    //this.form.valueChanges.subscribe(data => this.form.markAsTouched());

    
      this.form.valueChanges.subscribe(data => {
        if(this.formLoaded){
          this.form.markAsTouched();
        }
      });
    

    this.akeelaWorkflowService.wfTransitions$.subscribe((transitionsWfs) => {
      console.log(transitionsWfs);
      this.transitionsWfs = transitionsWfs;
    });

    if(!this.briefcase) {
      this.akeelaStepService.currentStepMode$.subscribe((readonly) => {
        this.readonly = readonly;
      });
    }

    this.env = environment;
    this.currency = {
      prefix: '',
      thousands: '.',
      decimal: ','
    };
    this.setAttributes();
    this.reasonsList$ = this.listWorkflow('requestReason');
    this.sourceOfFundsList$ = this.listWorkflow('sourceOfFunds');
    this.investorExperienceList$ = this.listWorkflow('investorExperience');
    this.investorObjetiveList$ = this.listWorkflow('investorObjetive');
    this.profileInvestorList$ = this.listWorkflow('investorProfile');
    try {
      this.investorInformation = await this.getInvestorInformation();
      if(!isNullOrUndefined(this.investorInformation)) {
        this.compleat = true;
        this.setInvestorInformation();
      }
    }
    catch (error) {
      console.log(error);
      this.setInvestorInformation();
    }
    //this.form.readonly();

  }

  setInvestorInformation = () => {
    const environment = this.env.theme.images;

    if(this.form.contains('profile')) {
      this.form.patchValue({
        profile: this.investorInformation?.investorProfile?this.investorInformation?.investorProfile:899
      });
    }

    if(this.form.contains('experience')) {
      this.form.patchValue({
        experience: this.investorInformation?.experience?this.investorInformation?.experience:''
      });
    }

    if(this.form.contains('objective')) {
      this.form.patchValue({
        objective: this.investorInformation?.objective?this.investorInformation?.objective:''
      });
    }

    if(this.form.contains('objetiveSelect')) {
      this.form.patchValue({
        objetiveSelect: this.investorInformation?.objectiveSelect?this.investorInformation?.objectiveSelect:''
      });
    }
    

    if(this.form.contains('investments')) {
      this.form.patchValue({
        investments: this.investorInformation?.investments?this.investorInformation?.investments:''
      });
    }

    if(this.form.contains('savings')) {
      this.form.patchValue({
        savings: this.investorInformation?.savings?this.investorInformation?.savings:''
      });
    }

    if(this.form.contains('passives')) {
      this.form.patchValue({
        passives: this.investorInformation?.passives?this.investorInformation?.passives:''
      });
    }

    if(this.form.contains('totalAssets')) {
      this.form.patchValue({
        totalAssets: this.investorInformation?.totalAssets?this.investorInformation?.totalAssets:''
      });
    }

    this.formLoaded = true;

  }

  setAttributes = () => {
    const environment = this.env.theme.images;
    //let environment = "MC";
    this.form.addControl("profile", new FormControl('', Validators.required));
    this.form.addControl("experience", new FormControl('', [Validators.required]));
    if(environment=="KOI" || environment=="MC" || environment=="PERCAPITA" || environment=="RENDIVALORES") { // solo aplican en MC
      this.form.addControl("objetiveSelect", new FormControl('', [Validators.required]));
    }

    // if(environment=="MC") { // solo aplican en MC
    //   this.form.addControl("objective", new FormControl('', [Validators.required]));
    // }

    if(environment!="MC" && environment!="PERCAPITA") { // solo aplican en MC
      this.form.addControl("investments", new FormControl('', [Validators.required]));
      this.form.addControl("savings", new FormControl('', [Validators.required]));
      this.form.addControl("passives", new FormControl('', [Validators.required]));
      this.form.addControl("totalAssets", new FormControl('', [Validators.required]));
    }


    // this.form.addControl("sourceOfFunds", new FormControl('', [Validators.required]));
    // this.form.addControl("destinationOfFunds", new FormControl('', [Validators.required]));
    // this.form.addControl("month_average", new FormControl('', [Validators.required]));

    // if(environment!="AKEELA") { // todos los ambientes menos AKEELA
    //   this.form.addControl("monthly_operations", new FormControl('', []));
    // }
    
  }

  setProfile = (profile) => {
    this.form.patchValue({
      profile: profile
    });
    this.form.markAsTouched();
  }


  listWorkflow = async (code) => {
    const params = {
      name: 'workflow_manager$attributes_options$get',
      params: {
        request: {},
        path: {
          code: code
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }

  save = () => {
    if(this.form.valid) {
      this.loading_form = true;
      const params = {
        name: 'investor$investorProfile$post',
        params: {
          request: {},
          path: {},
          body: this.getJson()
        }
      };
      this._httpService.executeRequest(params.name, params.params, true).toPromise().then((response)=> {
        this.form.markAsUntouched();

        if(!isNullOrUndefined(this.briefcase)) {
          if(this.briefcase===true){
            swal.fire({
              title: '',
              html: "Perfil de Inversor actualizado.",
            });
            this.router.navigate(['/dashboard'], {
              queryParams: {
                  role: this.localStorage.getItem('role'),
                  profile: this.localStorage.getItem('profile'),
                  audience: this.localStorage.getItem('clientaudience') ? this.localStorage.getItem('clientaudience') : 'LEGAL'
              }
            });
          }
        } else {
          this.setRedirectSectionSection();
        }
        
        this.loading_form = false;
      }, error => {
        this.loading_form = false;
        this._error.show(error);
      });
    }
  }

  getJson = () => {
    const environment = this.env.theme.images;
    let form = this.form.getRawValue();
    let to_send = [];
    to_send['idPerson'] = this.localStorage.getItem('clientIdentifier');
    if(this.form.contains('profile')) to_send['investorProfile'] = form.profile;
    if(this.form.contains('experience')) to_send['experience'] = form.experience;
    
    if(environment=="KOI" || environment=='MC' || environment=="PERCAPITA" || environment=="RENDIVALORES"){
      if(this.form.contains('objetiveSelect')) to_send['objectiveSelect'] = form.objetiveSelect;
    }

    // if(environment=='RENDIVALORES'){
    //   if(this.form.contains('objective')) to_send['objective'] = form.objective;
    // }

    if(this.form.contains('credits')) to_send['savings'] = form.credits;
    if(this.form.contains('debits')) to_send['passives'] = form.debits;

    if(this.form.contains('investments')) to_send['investments'] = form.investments;
    if(this.form.contains('savings')) to_send['savings'] = form.savings;
    if(this.form.contains('passives')) to_send['passives'] = form.passives;
    if(this.form.contains('totalAssets')) to_send['totalAssets'] = form.totalAssets;

    if(this.form.contains('month_average')) to_send['totalAssets'] = form.month_average;
    return {...to_send};
  }

  getInvestorInformation = async () => {
    const params = {
      name: 'investor$investorProfile$get',
      params: {
        request: {
          idPerson: this.localStorage.getItem('clientIdentifier')
        },
        path: {},
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }

  back = () => {
    this.redirectSection( this.transitionsWfs[ this.sectionName]?.back?.wf, this.transitionsWfs[ this.sectionName]?.back?.stage, this.transitionsWfs[ this.sectionName]?.back?.step, this.transitionsWfs[ this.sectionName]?.back?.section);
  }


  continue = async () => {
    const params = {
      name: 'briefcase$validate_complete_section$post',
      params: {
        request: {
          role: this.localStorage.getItem('role'),
          audience: this.localStorage.getItem('clientaudience'),
          section: this.sectionName,
          idPerson: this.localStorage.getItem('clientIdentifier')
        },
        path: {},
        body: {}
      }
    };
    let response;
    try {
      response = await this._httpService.executeRequest(params.name, params.params, true).toPromise();
      this.setRedirectSectionSection();
    }
    catch(error) {

    }
    
  }

  setRedirectSectionSection(){
        if(this.transitionsWfs){
          if(this.transitionsWfs[ this.sectionName]?.new){
              this.redirectSection( this.transitionsWfs[ this.sectionName]?.new?.wf, this.transitionsWfs[ this.sectionName]?.new?.stage, this.transitionsWfs[ this.sectionName]?.new?.step, this.transitionsWfs[ this.sectionName]?.new?.section);
          }else if(this.transitionsWfs[ this.sectionName]?.end){
            this.redirectToUrl(this.transitionsWfs[ this.sectionName]?.end);
          }
        }
  }

  redirectSection(urlWf, stage, step, section ) {
    const prefixed = 'private';
    let url = [urlWf + '/stages/' + stage + '/steps/' + step];
    const queryParams = {
      role: this.localStorage.getItem('role'),
      profile: this.localStorage.getItem('profile'),
      audience: this.localStorage.getItem('clientaudience'),
      section: section,

    };
    this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });
  }

  redirectToUrl(url) {
      const queryParams = {
        role: this.localStorage.getItem('role'),
        profile: this.localStorage.getItem('profile'),
        audience: this.localStorage.getItem('clientaudience'),
      };
      this.router.navigate([url], { queryParams: queryParams });
  }


}
