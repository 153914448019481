import { Component, OnInit, OnDestroy, Input, } from '@angular/core';
import { UploadService, ErrorService } from '@akeela/common';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { AuthService } from '@akeela/auth';
import { HttpService } from '@akeela/properties';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowService } from '../../../../services/workflow.service';
import { TransitionService } from '../../../../services/transition.service';
import { I18nPipe } from '@akeela/i18n';
import swal from 'sweetalert2';
import { RouteUtilsService, AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from 'util';
import { PropertiesService } from '../../../../services/properties/properties.service';
import { LocalService } from '@akeela/local-storage';
import { resolve, reject } from 'q';
import { RequirementsService } from '@akeela/requirements';
import { log } from 'console';


@Component({
  selector: 'app-requirements-response',
  templateUrl: './requirements-response.component.html',
  styleUrls: ['./requirements-response.component.css']
})
export class RequirementsResponseComponent implements OnInit, OnDestroy {
  @Input() identifier?;
  @Input() inputTransactionNumber?;
  @Input() isActiveResume?= true;
  config_requirements: any = null;
  balance_requirements;
  @Input() viewOptional?;

  transactionData;
  idTransaction;
  transactionNumber;
  audience;
  idPerson;
  idPersonMarketType;
  user;
  submit_disabled;
  activeRequirements = false;

  workflow;
  stage;
  step;

  showWindow = true;

  queryParams;

  mb_max_filesize: any = 1;
  subscriptions: Subscription[] = [];

  role;

  list_attributes;
  filter_selected = '';
  filter_category_selected = '';
  categories;

  extensions;

  env: any;
  folder;

  constructor(
    private _upload: UploadService,
    public _auth: AuthService,
    private _httpService: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private _wfService: WorkflowService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private akeelaSectionService: AkeelaSectionService,
    private _error: ErrorService,
    private _transition: TransitionService,
    private i18n: I18nPipe,
    private _properties: PropertiesService,
    private localStorage: LocalService,
    private routerUtilService: RouteUtilsService,
    private _requirementsService: RequirementsService



  ) { }

  async ngOnInit() {
    this.env = environment;
    this.folder = '';
    if (environment.theme.images === 'FACEBANK') {
      this.folder = 'facebank/';
    } else if (environment.theme.images === 'MIBANCO') {
      this.folder = 'mibanco/';
    }

    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    }));

    this.role = await this.routerUtilService.getParamAsync(this.route, 'role');
    this.workflow = this.akWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akStageService.currentStage$.getValue();
    this.step = this.akStepService.currentStep$.getValue();

    if (!isNullOrUndefined(this.inputTransactionNumber)) {
      this.transactionNumber = this.inputTransactionNumber;
    } else {
      this.transactionNumber = this.localStorage.getItem('transactionNumber');
    }

    this._properties.getProperty({
      endpointKey: 'app_properties$getProperty$get',
      propertyKey: 'akarchive.valid.extention'
    }).subscribe(resp => {
      this.extensions = resp;
    });
    this.startConfigurationRequirements();
  }


  startConfigurationRequirements() {
    this.getAccountproductinformation().then((response: any) => {
      if (response) {

        this.getRequirementsCategory(response.plan.identifier, response.holder.additionalType, true).subscribe((categories) => {
          this.categories = categories;
        });
        if (response.accountStatus[0].additionalType === 'PI_IN_PROCESS') {
          this.getHistoryStatusTransaction().then((history: any) => {
            this._properties.getProperty({
              endpointKey: 'app_properties$getProperty$get',
              propertyKey: 'app.max_upload_size'
            }).subscribe(resp => {
              if (resp === 'UNLIMITED') {
                this.mb_max_filesize = null;
              } else {
                this.mb_max_filesize = (!isNullOrUndefined(resp) ? resp : (!isNullOrUndefined(environment.requirements.file.mb_max_filesize) ? environment.requirements.file.mb_max_filesize : 1));
              }
              // this.mb_max_filesize = (!isNullOrUndefined(resp) ? resp : (!isNullOrUndefined(environment.requirements.file.mb_max_filesize) ? environment.requirements.file.mb_max_filesize : 1));
              this.setConfigurationRequirements();
              if (history.length >= 2) {
                if (history[1].name === 'AC_INCOMPLETE') {
                  this.setConditions('8');
                } else {
                  this.setConditions();
                }
              } else {
                this.setConditions();
              }
              this.activeRequirements = true;
            }, error => {
              this.mb_max_filesize = (!isNullOrUndefined(environment.requirements.file.mb_max_filesize) ? environment.requirements.file.mb_max_filesize : 1);
              this.setConfigurationRequirements();
              if (history.length >= 2) {
                if (history[1].name === 'AC_INCOMPLETE') {
                  this.setConditions('8');
                } else {
                  this.setConditions();
                }
              } else {
                this.setConditions();
              }
              this.activeRequirements = true;
            });
          });
        } else {
          this._properties.getProperty({
            endpointKey: 'app_properties$getProperty$get',
            propertyKey: 'app.max_upload_size'
          }).subscribe(resp => {
            if (resp === 'UNLIMITED') {
              this.mb_max_filesize = null;
            } else {
              this.mb_max_filesize = (!isNullOrUndefined(resp) ? resp : (!isNullOrUndefined(environment.requirements.file.mb_max_filesize) ? environment.requirements.file.mb_max_filesize : 1));
            }
            // this.mb_max_filesize = (!isNullOrUndefined(resp) ? resp : (!isNullOrUndefined(environment.requirements.file.mb_max_filesize) ? environment.requirements.file.mb_max_filesize : 1));

            this.setConfigurationRequirements();
            this.setConditions();
            this.activeRequirements = true;
          }, error => {
            this.mb_max_filesize = (!isNullOrUndefined(environment.requirements.file.mb_max_filesize) ? environment.requirements.file.mb_max_filesize : 1);
            this.setConfigurationRequirements();
            this.setConditions();
            this.activeRequirements = true;
          });

        }

      }
    });
  }

  getHistoryStatusTransaction() {
    return new Promise((resolve, reject) => {
      const params = {
        name: 'accounts$accountstates$get',
        params: {
          request: {},
          path: {
            transactionNumber: this.transactionNumber
          },
          body: {}
        }
      };
      this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        resolve(response);
      }, error => {
        reject(false);
      });
    });
  }

  getListIncompletedResponse() {
    return new Promise((resolve, reject) => {
      if (environment.requirements.fieldsResponseShow.reasonIncompleted) {
        if (this.config_requirements.responseOptionalFieldsIndividualResponse.reasonIncompleted) {
          const params: any = {
            name: 'workflow_manager$listattributes$get',
            params: {
              request: {},
              path: {
                attribute: 'response.reasonIncomplete',
                section: 'RESPONSE_MODAL_FIELDS_REQ'
              },
              body: {}
            }
          };
          this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
            this.config_requirements.responseOptionalFieldsIndividualResponse.reasonIncompleted = response[0].dataFieldItemList.listItems;
            resolve(true);
          }, error => {
            resolve(true);
          }));
        } else {
          resolve(true);
        }
      } else {
        resolve(true);
      }
    });
  }

  setConditions(status?) {

    if (!isNullOrUndefined(this.env.requirements.isFilterRequirementsByCategory)) { // se condiciona este caso, debido a un requerimiento de FACEBANK
      this.config_requirements.isFilterRequirementsByCategory = this.env.requirements.isFilterRequirementsByCategory;
    }
      console.clear();

    this.getListIncompletedResponse().then((resp: any) => {
       this.config_requirements.isResponseActive = false;

      let status_filter = null;
      if (!isNullOrUndefined(status) && status !== '') {
        status_filter = status;
      } else {
        status_filter = this.transactionData.accountStatus[0].identifier;
      }

      

      // alert(status_filter);

      if (status_filter === '8' || status_filter === 8) { // Si el solicitud está en estatus INCOMPLETO/PI_IN_PROCESS
        this.config_requirements.isDigitalUploadActive = false;

        if (!isNullOrUndefined(this.env.requirements.isFilterRequirementsByResponse)) { // se condiciona este caso, debido a un requerimiento de FACEBANK
          this.config_requirements.isFilterRequirementsByResponse = this.env.requirements.isFilterRequirementsByResponse;
        } else {
          // SOLO COMENTADO EN EL USER... this.config_requirements.isFilterRequirementsByResponse = true;
        }
        this.config_requirements.isShowStatusResponse = true;
        this.config_requirements.isActiveVerifyChangeTransaction = true;

        //TODO:Juan -  Ajustar nuevo caso
        if (this.localStorage.getItem('profile') === 'ANALIST') {
          this.config_requirements.isReadonly = true;
        } else {
          this.config_requirements.isReadonly = false;
        }


        // condición de inhabilitar el solicitud si lo posee tomado otro usuario
        if (!this.localStorage.getItem('transactionModeEdit') && (this.localStorage.getItem('transactionModeEdit') === this.localStorage.getItem('transactionNumber') || isNullOrUndefined(this.localStorage.getItem('transactionModeEdit')))) {
          this.config_requirements.isReadonly = true;
        }
        this.config_requirements.enableEspecialUpload = {
          active: true,
          status: ['INCOMPLETED', 'REJECTED']
        };
      } else if (status_filter === '9' || status_filter === 9) { // Se el solicitud fue rechazado
        if (!isNullOrUndefined(this.env.requirements.isFilterRequirementsByResponse)) { // se condiciona este caso, debido a un requerimiento de FACEBANK
          this.config_requirements.isFilterRequirementsByResponse = this.env.requirements.isFilterRequirementsByResponse;
        } else {
          // SOLO COMENTADO EN EL USER... this.config_requirements.isFilterRequirementsByResponse = true;
        }
        this.config_requirements.isReadonly = true;
        this.config_requirements.enableEspecialUpload = {
          active: false,
          status: ['INCOMPLETED', 'REJECTED']
        };
      } else if (status_filter === '7' || status_filter === 7) { // Se el solicitud fue aprobado
        if (!isNullOrUndefined(this.env.requirements.isFilterRequirementsByResponse)) { // se condiciona este caso, debido a un requerimiento de FACEBANK
          this.config_requirements.isFilterRequirementsByResponse = this.env.requirements.isFilterRequirementsByResponse;
        } else {
          // SOLO COMENTADO EN EL USER... this.config_requirements.isFilterRequirementsByResponse = true;
        }

        //TODO:Juan -  ahora se puede adjuntar despues de aprobada la solicitud
        this.config_requirements.isReadonly = false;
        this.config_requirements.isResponseActive = true;

        
        this.config_requirements.isShowStatusResponse = true;
        this.config_requirements.isActiveVerifyChangeTransaction = true;
        this.config_requirements.isDigitalUploadActive = true;

        
        this.config_requirements.enableEspecialUpload = {
          active: true,
          status: ['INCOMPLETED', 'REJECTED']
        };
      } else if (status_filter === '6' || status_filter === 6) { // Se el solicitud está en proceso de análisis
        this.config_requirements.isReadonly = true;
        if (this.localStorage.getItem('profile') === 'ANALIST') {
          // this.config_requirements.requiredFieldValidation = 'secondRequired'; // se agrega la validación para los recaudos obligatorios por la institución (secondRequired), aplica para analista. Comento este código, ahora para el user siempre este va a ser el requerido
          this.config_requirements.isResponseActive = true;
          this.config_requirements.isActiveProgressBarUpload = true;
        } else {
          this.config_requirements.isResponseActive = false;
        }
        this.config_requirements.isShowStatusResponse = true;
        this.config_requirements.isHistoryResponsesShow = true;
        this.config_requirements.isGlobalResponseActive = false;
        this.config_requirements.enableEspecialUpload = {
          active: false,
          status: ['INCOMPLETED', 'REJECTED']
        };
      }else if (status_filter === '10' || status_filter === 10) { 
        this.config_requirements.isReadonly = false;

        if (this.localStorage.getItem('profile') === 'SALES_MANAGER') {
          // this.config_requirements.requiredFieldValidation = 'secondRequired'; // se agrega la validación para los recaudos obligatorios por la institución (secondRequired), aplica para analista. Comento este código, ahora para el user siempre este va a ser el requerido
          this.config_requirements.isResponseActive = true;
          this.config_requirements.isActiveProgressBarUpload = true;
        } else {

          this.config_requirements.isResponseActive = false;

        }
        
        this.config_requirements.isShowStatusResponse = true;
        this.config_requirements.isHistoryResponsesShow = true;
        this.config_requirements.isGlobalResponseActive = false;
        this.config_requirements.enableEspecialUpload = {
          active: false,
          status: ['INCOMPLETED', 'REJECTED']
        };
      } else if (status_filter === '3' || status_filter === '4' || status_filter === '5' || status_filter === 3 || status_filter === 4 || status_filter === 5) { // Si el solicitud está en proceso de carga por el cliente
        this.config_requirements.isFilterRequirementsByResponse = false;
        this.config_requirements.isReadonly = false;
        this.config_requirements.isActiveProgressBarUpload = true;
        // condición de inhabilitar el solicitud si lo posee tomado otro usuario
        // if (!this.localStorage.getItem('transactionModeEdit') && !isNullOrUndefined(this.localStorage.getItem('transactionModeEdit')) && (this.localStorage.getItem('transactionModeEdit') === this.localStorage.getItem('transactionNumber'))) {
        //   this.config_requirements.isReadonly = true;
        //   this.config_requirements.isActiveProgressBarUpload = false;
        // } 

      }

      if (!isNullOrUndefined(this.inputTransactionNumber)) {
        this.config_requirements.isReadonly = true;
        this.config_requirements.isReadonlyPhysical = true;
        this.config_requirements.isResponseActive = false;
        this.config_requirements.isGlobalResponseActive = false;
        this.config_requirements.isFilterRequirementsByResponse = false;
        this.config_requirements.isActiveProgressBarUpload = false;
        this.config_requirements.showHeaderContent = false;
      }

      
      // condición especial, caso solicitud en modo edición por el analista...
      if (this.transactionData.editTaken === true && this.localStorage.getItem('takenedit') === this.transactionData.transactionNumber) {
        this.config_requirements.isReadonly = false;
        this.config_requirements.isResponseActive = false;
        this.config_requirements.isGlobalResponseActive = false;
        this.config_requirements.isActiveProgressBarUpload = true;
      }
      this.config_requirements.showHeaderContent = true;
      this.config_requirements.isActiveProgressBarUpload = true;

      if (this.localStorage.getItem('workspaceReadonly') === 'true' || this.localStorage.getItem('readonly') === 'true') {
        this.config_requirements.isDigitalUploadActive = false;
        this.config_requirements.isPhysicalCheckActive = false;
        this.config_requirements.isReadonly = true;
        this.config_requirements.isReadonlyPhysical = true;
        this.config_requirements.isActiveProgressBarUpload = false;
        this.config_requirements.isResponseActive = false;
        this.config_requirements.enableEspecialUpload = {
          active: false,
          status: null
        };
      }
    });

    
   // this.config_requirements.isResponseActive = true;


  }

  setConfigurationRequirements() {
    let preview = true;
    if(!isNullOrUndefined(environment?.requirements['preview'])) {
      preview = environment?.requirements['preview'];
    }
    this.config_requirements = {
      preview,
      downloadAllUploadFilesLink: true,
      downloadAllUploadFilesByRequirementLink: true,
      showHeaderContent: true,
      showFooterContent: false,
      showDetailHeaderContent: true,
      showDetailFooterContent: false,
      showDetailHeaderContentLabel: true,
      isReadonly: false,
      isPhysicalCheckActive: false,
      isReadonlyPhysical: true,
      isDigitalUploadActive: true,
      isCameraActive: false,
      isResponseActive: false,
      isGlobalResponseActive: false,
      isHistoryResponsesShow: true,
      isFilterRequirementsByResponse: false,
      isFilterRequirementsByCategory: false,
      isShowStatusResponse: false,
      isInputDateExpiredActive: true,
      isActiveProgressBarUpload: false,
      viewName: 'UPLOAD_FILES_EXAMPLE',
      isEnabledIconColors: true,
      isDetailEnable: false,
      isModeUserActive: true,
      isEnableConsiderationsByCategory: true,
      isEnableConsiderationsByRequirements: true,
      isEnableFilterInconsistent: true,
      requiredFieldValidation: 'secondRequired',
      enableEspecialUpload: {
        active: true,
        status: ['INCOMPLETED', 'REJECTED']
      },
      filterRequirementsByResponse: {
        state: this.filter_selected
      },
      filterRequirementsByCategory: {
        name: this.filter_category_selected
      },
      transactionData: {
        transactionNumber: this.transactionData.transactionNumber,
        identifier: this.transactionData.identifier
      },
      statusResponseRequirement: {
        verified: 'VERIFIED',
        inconsistent: 'INCONSISTENT',
        incomplete: 'INCOMPLETED',
        rejected: 'REJECTED'
      },
      file: {
        file_type_accepts: this._requirementsService.getPermitedExtensions(this.extensions),
        file_type_accepts_description: this._requirementsService.getPermitedExtensionsDescription(this.extensions),
        mb_max_filesize: this.mb_max_filesize
      },
      lists: {
        responsesRequirements: [
          {
            identifier: 'VERIFIED',
            name: 'VERIFIED',
            requireObservation: false,
            isFinalResponse: true
          },
          {
            identifier: 'INCOMPLETED',
            name: 'INCOMPLETED',
            requireObservation: true,
            isFinalResponse: true
          },
          {
            identifier: 'REJECTED',
            name: 'REJECTED',
            requireObservation: true,
            isFinalResponse: true
          },
          {
            identifier: 'INCONSISTENT',
            name: 'INCONSISTENT',
            requireObservation: true,
            isFinalResponse: false
          }]
      },
      endpoints: {
        downloadRequirementFilesZip: {
          name: 'requirements$zipfile_requirement$get',
          params: {
            request: {
              systemUser: this._auth.getUsername(),
              idTransactionRequirement: null
            },
            path: {
              idTransaction: this.transactionData.identifier,
              idPerson: this.transactionData.holder.identifier
            },
            body: {}
          }
        },
        downloadAllFilesZip: {
          name: 'requirements$zipfile_transaction$get',
          params: {
            request: {
              systemUser: this._auth.getUsername()
            },
            path: {
              idTransaction: this.transactionData.identifier,
              idPerson: this.transactionData.holder.identifier
            },
            body: {}
          }
        },
        getRequirements: {
          identifier: '',
          name: 'requirements$transaction_requirements$get',
          params: {
            request: {
              audience: this.transactionData.holder.additionalType ? this.transactionData.holder.additionalType : 'LEGAL'
            },
            path: {
              idPlan: this.transactionData.plan.identifier,
              transactionNumber: this.transactionData.transactionNumber,
              idPerson: this.transactionData.holder.identifier
            },
            body: {}
          }
        },
        getRequirement: {
          identifier: '',
          name: 'requirements$transaction_requirement$get',
          params: {
            request: {},
            path: {
              idTransaction: this.transactionData.identifier,
              idRequirement: null,
              idPerson: this.transactionData.holder.identifier
            },
            body: {}
          }
        },
        getStatusResponseRequirements: {
          name: 'credit$credit_status_responses$get',
          params: {
            request: {},
            body: {},
            path: {}
          }
        },
        getCategoriesByPlan: {
          name: 'requirements$category$get',
          params: {
            request: {
              audience: this.transactionData.holder.additionalType ? this.transactionData.holder.additionalType : 'LEGAL'
            },
            body: {},
            path: {
              idPlan: this.transactionData.plan.identifier,
            },
          }
        },
        getPlansByProduct: {
          name: 'product_plan$plans$get',
          params: {
            path: {},
            request: {
              identifierProduct: this.transactionData.plan.financialProduct.identifier
            },
            body: {}
          }
        },
        getHistoricRequirement: {
          name: 'requirements$historic$get',
          params: {
            path: {
              idTransaction: this.transactionData.identifier,
              idRequirement: null,
              idPerson: this.transactionData.holder.identifier
            },
            request: {
              getLast: true
            },
            body: {}
          }
        },
        getHistoryResponseRequirement: {
          name: 'requirements$transaction_requirements_status$get',
          params: {
            request: {},
            body: {},
            path: {
              idRequirement: null,
              idTransaction: this.transactionData.identifier,
              idPerson: this.transactionData.holder.identifier
            }
          }
        },
        saveResponseRequirement: {
          name: 'requirements$req_response$post',
          params: {
            request: {},
            body: {
              identifier: null,
              response: null,
              responseDescription: null,
              modifyUser: this._auth.getUsername()
            },
            path: {}
          }
        },
        saveRequirement: {
          params: {
            request: {},
            path: {},
            body: {
              'transaction': {
                'identifier': this.transactionData.identifier,
                'name': this.transactionData.transactionNumber
              },
              'requirement': {
                'identifier': null
              },
              'requirementArchives': [
                {
                  'creator': {
                    'identifier': this._auth.getUsername()
                  }
                }
              ],
              'person': {
                'identifier': this.transactionData.holder.identifier,
                'identity': this.transactionData.holder.alternateName,
              }

            }
          }
        },
        deleteRequirement: {
          name: 'requirements$requirement$delete',
          params: {
            request: {},
            path: {},
            body: {
              alternateName: 'ACCREDITED',
              transaction: {
                'identifier': this.transactionData.identifier,
                'name': this.transactionData.transactionNumber
              },
              requirement: {
                'identifier': null
              },
              person: {
                'identifier': this.transactionData.holder.identifier,
              },
              requirementArchives: [{
                identifier: null
              }]
            }
          }
        },
        deleteArchive: {
          name: 'archive$archive$delete',
          params: {
            request: {},
            path: {},
            body: {
              identifier: null
            }
          }
        },
        saveRequirementPhysical: {
          name: 'requirements$consigned_requirements$put',
          format: {
            identifier: null,
            transaction: {
              identifier: this.transactionData.identifier
            },
            requirement: {
              identifier: null
            },
            person: {
              identifier: this.transactionData.holder.identifier
            },
            consigned: false,
            consignedUsername: this._auth.getUsername()
          },
          params: {
            request: {},
            path: {},
            body: {
              transactionRequirements: null
            }
          }
        },
        updateDate: {
          name: 'requirements$emission_expiration_dates$put',
          dateFormat: 'dd-mm-yyyy',
          params: {
            request: {},
            path: {},
            body: {
              'transaction': {
                'identifier': this.transactionData.identifier
              },
              'person': {
                'identifier': this.transactionData.holder.identifier
              },
              'requirement': {
                'identifier': null
              },
              'requirementArchives': null
            }
          }

        }
      },

      responseOptionalFieldsIndividualResponse: null
    };

    if (!isNullOrUndefined(environment.requirements.responseOptionalFieldsIndividualResponse)) {
      this.config_requirements.responseOptionalFieldsIndividualResponse = environment.requirements.responseOptionalFieldsIndividualResponse;
    }

  }

  response_transaction_change(newidentifier) {
    this.activeRequirements = false;
    this._wfService.showContainer$.next(true);
    this.startConfigurationRequirements();
  }


  getAccountproductinformation() {
    return new Promise((resolve, reject) => {
      this.transactionData = null;
      const params = {
        name: 'account$accountproductinformation$get',
        params: {
          request: {
            role: this.role,
            profile: this.localStorage.getItem('profile')
          },
          path: {
            transactionNumber: this.transactionNumber
          },
          body: {}
        }
      };
      this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        this.transactionData = response;
        resolve(response);
      }, error => {
        reject(false);
      });
    });
  }


  show_file(file) {
    const archive = {
      identifier: file.identifier,
      name: file.name,
      dateCreated: {
        orderDate: null
      }
    };

    if (file.dateCreated) {
      archive['dateCreated']['orderDate'] = file.dateCreated.modifiedDate;
    } else {
      archive['dateCreated']['orderDate'] = null;
    }

    if (archive.identifier !== null) {
      this._upload.visor_params.name = '';
      this._upload.visor_params.date = '';
      this._upload.visor_params.endpoint = 'archive$downloadFile$get';
      this._upload.visor_params.archive = archive;
      this._upload.visor_params.deleteAfterShow = '';
      if (file['action'] === 'download') {
        this._upload.visor_params.action = 'download';
        this._upload.visor_params.deleteAfterShow = {
          deleteRequirement: file['deleteAfterShow']
        };
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      } if (file['action'] === 'downloadzip') {

        if (this.env.theme.images === 'FACEBANK') {
          this._upload.visor_params.endpoint = 'archive$downloadZipFile$get';
        }

        this._upload.visor_params.action = 'download';
        this._upload.visor_params.deleteAfterShow = {
          deleteRequirement: file['deleteAfterShow']
        };
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      } else {
        this._upload.visor_params.action = '';
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      }
    }
  }


  // show_file(file) {
  //   const archive = {
  //     identifier: file.identifier,
  //     name: file.name,
  //     dateCreated: {
  //       orderDate: null
  //     }
  //   };

  //   if (file.dateCreated) {
  //     archive['dateCreated']['orderDate'] = file.dateCreated.modifiedDate;
  //   } else {
  //     archive['dateCreated']['orderDate'] = null;
  //   }

  //   if (archive.identifier !== null) {
  //     this._upload.visor_params.name = '';
  //     this._upload.visor_params.date = '';
  //     this._upload.visor_params.endpoint = 'archive$downloadFile$get';
  //     this._upload.visor_params.archive = archive;
  //     this._upload.visor_params.deleteAfterShow = '';
  //     if (file['action'] === 'download') {
  //       this._upload.visor_params.action = 'download';
  //       this._upload.visor_params.deleteAfterShow = {
  //         deleteRequirement: file['deleteAfterShow']
  //       };
  //       this._upload.visor = false;
  //       setTimeout(() => {
  //         this._upload.visor = true;
  //       }, 6);
  //     } else {
  //       this._upload.visor_params.action = '';
  //       this._upload.visor = false;
  //       setTimeout(() => {
  //         this._upload.visor = true;
  //       }, 6);
  //     }
  //   }
  // }

  response_requirements(response) {
    let obj = null;
    obj = {
      section: 'RESPONSE_MODAL',
      response: {
        name: '',
        responseDescription: '',
        status: '',
        modifyUser: this._auth.getUsername()
      }
    };
    this.akeelaSectionService.externalUpdateSectionForm$.next(obj);
    if (response.total_required_responses_user_requirements > response.total_required_responses_user_requirements_completed) {
      // obj = {
      //   section: 'RESPONSE_MODAL',
      //   response: {
      //     name: '',
      //     responseDescription: '',
      //     status: '',
      //     modifyUser: this._auth.getUsername()
      //   }
      // };
      // this.akeelaSectionService.externalUpdateSectionForm$.next(obj);
    } else if (response.total_required_responses_user_requirements_completed_in_status_rejected > 0) {
      // obj = {
      //   section: 'RESPONSE_MODAL',
      //   response: {
      //     name: 'AC_REJECTED',
      //     responseDescription: '',
      //     status: 'AC_REJECTED',
      //     modifyUser: this._auth.getUsername()
      //   }
      // };
      // this.akeelaSectionService.externalUpdateSectionForm$.next(obj);
    } else if (response.total_required_responses_user_requirements_completed_in_status_incompleted > 0) {
      // obj = {
      //   section: 'RESPONSE_MODAL',
      //   response: {
      //     name: 'AC_INCOMPLETE',
      //     responseDescription: '',
      //     status: 'AC_INCOMPLETE',
      //     modifyUser: this._auth.getUsername()
      //   }
      // };
      // this.akeelaSectionService.externalUpdateSectionForm$.next(obj);
      // this.akWorkflowService.worflowEvents$.next({
      //   target: {
      //     target: 'sectionsIncompleted'
      //   },
      //   value: 'AC_INCOMPLETE'
      // });
    } else if (response.total_requirements_completed !== 0 && response.total_requirements_completed === response.total_required_responses_user_requirements_completed_in_status_verified) {
      // obj = {
      //   section: 'RESPONSE_MODAL',
      //   response: {
      //     name: 'AC_RECEIVED',
      //     responseDescription: '',
      //     status: 'AC_RECEIVED',
      //     modifyUser: this._auth.getUsername()
      //   }
      // };
      // this.akeelaSectionService.externalUpdateSectionForm$.next(obj);
    } else {
      // obj = {
      //   section: 'RESPONSE_MODAL',
      //   response: {
      //     name: '',
      //     responseDescription: '',
      //     status: '',
      //     modifyUser: ''
      //   }
      // };
      // this.akeelaSectionService.externalUpdateSectionForm$.next(obj);
    }




    // if (response.total_required_responses_user_requirements <= response.total_required_responses_user_requirements_completed) {
    //   // this.akWorkflowService.hiddenSection$.next({
    //   //   code: 'RESPONSE_MODAL_FIELDS',
    //   //   hidden: true
    //   // });

    //   const obj2 = {
    //     section: 'RESPONSE_MODAL',
    //     response: {
    //       responseDescription: 'VERIFIED'
    //     }
    //   };
    //   this.akeelaSectionService.externalUpdateSectionForm$.next(obj2);
    // }

    this.balance_requirements = response;

    this.akWorkflowService.worflowEvents$.next({
      response: 'UPDTATE REQUIREMENT',
      action: null,
      forms: null
    });
  }

  finish_upload(response) {
    this.akWorkflowService.worflowEvents$.next({
      response: 'UPDATE_REQUIREMENT_FINISH',
      action: null,
      forms: null
    });
  }

  round(value) {
    return Math.round(value);
  }

  getNameHolder() {
    return this.transactionData.holder.name;
  }

  getInitialsHolder() {
    return this.transactionData.holder.name.charAt(0).toUpperCase();
  }

  goToPaperwork() {
    this.router.navigateByUrl(
      this.router.createUrlTree(['private/' + environment.workflow + '/stages/LIST_ACCOUNT_OPENING/steps/LIST_ACCOUNT_OPENING'],
        {
          queryParams: {}
        }
      )
    );
  }

  openWindowRequirement(open) {
    this._wfService.showContainer$.next(!open);
  }

  continuar() {
    this.router.navigateByUrl(
      this.router.createUrlTree(['private/' + environment.workflow + '/stages/ACCOUNT_CONTRACT/steps/ACCOUNT_CONTRACT'],
        {
          queryParams: this.queryParams
        }
      )
    );
  }

  send() {
    const params = {
      name: 'transaction$analysisdocsupports$put',
      params: {
        request: {
          role: this.localStorage.getItem('role'),
          audience: this.transactionData.holder.additionalType ? this.transactionData.holder.additionalType : 'LEGAL'
        },
        path: {
          transactionNumber: this.transactionNumber,
          workflow: this.workflow,
          stage: this.stage,
          step: this.step,
          event: 'SEND_TRANSACTION_ANALYSIS_DOC_SUPPORT'
        },
        body: {}
      }
    };
    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this._transition.nextStep('private', response.nextStep);
    }, (error: any) => {
      this._error.show(error);
    }));

  }

  response_transaction(transaction) {
    this._transition.nextStep('private', transaction.nextStep);
  }

  detailClient() {
    swal.fire({
      title: '',
      html: '<b>#' + this.transactionData.transactionNumber + '</b><br>' + this.getNameHolder()
    }).then((result) => {
      // if (!isNullOrUndefined(result.value) && result.value) {
      //   this.deleteItem(row);
      // }
    });
  }


  ngOnDestroy() {
    this.showWindow = true;

    if (this.subscriptions) {
      // prevent memory leak when component destroyed
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }




  showDetail() {
    this.config_requirements.isDetailEnable = !this.config_requirements.isDetailEnable;
  }

  filter_category(event) {
    if (this.filter_category_selected !== event.target.value) {
      this.activeRequirements = false;
      this.filter_category_selected = event.target.value;
      setTimeout(() => {
        if (event.target.value !== '') {
          this.config_requirements.filterRequirementsByCategory = {
            name: event.target.value
          };
        } else {
          this.config_requirements.filterRequirementsByCategory = {
            name: ''
          };
        }
        this.activeRequirements = true;
      }, 30);
    }
  }

  filter(event) {

    if (this.filter_selected !== event.target.value) {
      this.activeRequirements = false;
      this.filter_selected = event.target.value;
      setTimeout(() => {
        if (event.target.value !== '') {
          this.config_requirements.filterRequirementsByResponse = {
            state: event.target.value
          };
        } else {
          this.config_requirements.filterRequirementsByResponse = {
            state: ''
          };
        }
        this.activeRequirements = true;
      }, 30);
    }
  }


  getRequirementsCategory(idPlan, audience, withRequirements?) {
    return new Observable(resolve => {
      let request;
      if (withRequirements) {
        request = {
          'audience': audience,
          'withRequirements': withRequirements
        };
      } else {
        request = {
          'audience': audience
        };

      }

      const params: any = {
        endpoint: {
          name: 'requirements$category$get',
          params: {
            path: {
              'idPlan': idPlan
            },
            request: request,
            body: {}
          }
        }
      };
      this._httpService.executeRequest(params.endpoint.name, params.endpoint.params, true).subscribe((response: any) => {
        resolve.next(response);
        resolve.complete();
      }, (error: any) => {
        resolve.next(null);
        resolve.complete();
      });
    });
  }

}
