import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { isNullOrUndefined } from 'util';
import { LocalService } from '@akeela/local-storage';
import { Table, Row, Column, Dropdown, DropdownList } from '@akeela/ak-table';
import { DatePipe } from '@angular/common';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-list-formalities-relationship',
  templateUrl: './list-formalities-relationship.component.html',
  styleUrls: ['./list-formalities-relationship.component.css']
})
export class ListFormalitiesRelationshipComponent implements OnInit {
  @Input() transactionNumber;
  @Output() reload = new EventEmitter();

  transactionData;


  activeTemplate = false;
  transactions;
  objModal;

  role;

  configuration_table: Table = null;


  constructor(
    private _httpService: HttpService,
    private _wfService: AkeelaWorkflowService,
    private localStorage: LocalService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.role = this.localStorage.getItem('role');
    setTimeout(() => {
      if (!isNullOrUndefined(this.transactionNumber)) {
        this.activeTemplate = true;
        this.getTransactionData(this.transactionNumber).subscribe((response: any) => {
          this.transactionData = response;
          this.getTransactions();
        }, (error: any) => {
        });
      } else {
        this.getTransactionData(this._wfService.transactionNumber$.getValue()).subscribe((response: any) => {
          this.transactionData = response;
          this.getTransactions();
        }, (error: any) => {
        });
      }
    }, 0);
  }


  getTransactionData(transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role,
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }


  getTransactionById(identifier) {
    const params = {
      name: 'account$account$get',
      params: {
        request: {},
        path: {
          identifierAccount: identifier
        },
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.transactionData = response;
    }, (error: any) => {
    });
  }

  getTransactions() {
    this.transactions = null;
    const params: any = {
      name: 'account$relatedtransactions$get',
      params: {
        request: {},
        path: {
          identifierTransaction: this.transactionData.identifier
        },
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response) => {
      this.transactions = response;
      this.setConfigurationTable();
    }, error => {
      this.transactions = {};
      this.setConfigurationTable();
    });
  }

  setConfigurationTable() {
    const base: Column[] = environment.akeelaTablesConfig.listRelatedTransactions.columns;
    this.configuration_table = {
      cssCustom: '',
      width: '100%',
      header: {
        cssCustom: 'font-weight-bold class-color-title',
        rows: [{
          backgroundColor: '#F8F7F7',
          columns: base
        }],
      },
      body: {
        cssCustom: '',
        rows: this.setContentTable(base)
      },
      footer: {
        cssCustom: '',
        rows: []
      }
    };
  }

  setContentTable(configuration): Row[] {
    const content: Row[] = [];
    let i = 0;
    for (const row of this.transactions) {


      /* Valores configurables */
      /*icono*/
      let icon = '';
      const initials = this.getInitials(row.holder.name, '');
      const initial = this.getInitial(row.holder.name);
      const name = row.holder.name;
      icon = '<div class="DomainUserAvatar-avatar Avatar--color-' + initial + ' AvatarPhoto AvatarPhoto--small" role="img">' + initials + '</div>';


      content.push(
        {
          identifier: row.identifier,
          cssCustom: '',
          enabled: true,
          columns: [
            {
              alignContent: configuration[0].alignContent,
              fixed: configuration[0].fixed,
              maxWidth: configuration[0].maxWidth,
              minWidth: configuration[0].minWidth,
              html: icon,
              cssCustom: configuration[0].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[0].enableLeftBorder) ? configuration[0].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[0].enableRightBorder) ? configuration[0].enableRightBorder : false
            },
            {
              alignContent: configuration[1].alignContent,
              fixed: configuration[1].fixed,
              maxWidth: configuration[1].maxWidth,
              minWidth: configuration[1].minWidth,
              html: (row.transactionNumber),
              cssCustom: configuration[1].cssCustom + ' ak-text-color-4' ,
              enableLeftBorder: !isNullOrUndefined(configuration[1].enableLeftBorder) ? configuration[1].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[1].enableRightBorder) ? configuration[1].enableRightBorder : false
            },
            {
              alignContent: configuration[2].alignContent,
              fixed: configuration[2].fixed,
              maxWidth: configuration[2].maxWidth,
              minWidth: configuration[2].minWidth,
              html: row.accountStatus[0].userName,
              cssCustom: configuration[2].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[2].enableLeftBorder) ? configuration[2].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[2].enableRightBorder) ? configuration[2].enableRightBorder : false
            },
            {
              alignContent: configuration[3].alignContent,
              fixed: configuration[3].fixed,
              maxWidth: configuration[3].maxWidth,
              minWidth: configuration[3].minWidth,
              html: this.datePipe.transform(row.dateOfThing.startDate),
              cssCustom: configuration[3].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[3].enableLeftBorder) ? configuration[3].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[3].enableRightBorder) ? configuration[3].enableRightBorder : false
            },
            {
              alignContent: configuration[4].alignContent,
              fixed: configuration[4].fixed,
              maxWidth: configuration[4].maxWidth,
              minWidth: configuration[4].minWidth,
              html: this.datePipe.transform(row.dateOfThing.modifiedDate),
              cssCustom: configuration[4].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[4].enableLeftBorder) ? configuration[4].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[4].enableRightBorder) ? configuration[4].enableRightBorder : false
            },
            {
              alignContent: configuration[5].alignContent,
              fixed: configuration[5].fixed,
              maxWidth: configuration[5].maxWidth,
              minWidth: configuration[5].minWidth,
              html: '<i class="fas fa-angle-right text-primary" title="Ir..."></i>',
              cssCustom: configuration[5].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[5].enableLeftBorder) ? configuration[5].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[5].enableRightBorder) ? configuration[5].enableRightBorder : false
            }
          ]
        }
      );
      i++;
    }
    return content;
  }
  // responseTime,
  table_response(response) {
    if (response.action === 'open') {
      let transaction = null;
      transaction = this.transactions.find((wspc: any) => {
        return wspc.identifier === response.object.identifier;
      });

      this.detail(transaction);

    }
    // if (response.action === 'order') {
    //   this.filter_order = response.object;
    //   this.applyFilter(response.object);
    // }

    // if (response.action === 'action') {
    //   if (response.object.action === 'assign_user') {
    //     this.assign(response.object.obj.client, response.object.obj.procedure);
    //   }
    // }

    // if (response.action === 'paginator') {
    //   this.action_paginator(response.object);
    // }
  }

  getInitials(givenName: string, familyName: string) {
    return (
      (givenName ? givenName.substr(0, 1).toUpperCase() : '') +
      (familyName ? familyName.substr(0, 1).toUpperCase() : '')
    );
  }

  getInitial(text) {
    let retorno = '';
    if (!isNullOrUndefined(text)) {
      retorno = text.substr(0, 1).toUpperCase();
    }
    return retorno;
  }


  detail(obj) {
    if (!this.activeTemplate) {
      this.objModal = obj;
    } else {
      this.reload.emit(obj);
    }
  }

  close() {
    this.objModal = null;
  }

}
