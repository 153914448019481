import { Component, OnInit, Input } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { environment } from '../../../../../../environments/environment';
import { DatePipe } from '@angular/common';
import { Table, Row, Column, Dropdown, DropdownList } from '@akeela/ak-table';


@Component({
  selector: 'app-table-admin-users',
  templateUrl: './table-admin-users.component.html',
  styleUrls: ['./table-admin-users.component.css']
})
export class TableAdminUsersComponent implements OnInit {
  configuration_table: Table = null;
  @Input() client;
  paginator: any = null;
  loading_table = false;


  constructor(
    private datePipe: DatePipe

  ) { }

  ngOnInit() {
    this.setConfigurationTable();
  }

  setConfigurationTable() {
    const base: Column[] = this.setHeadTable();
    this.configuration_table = {
      cssCustom: '',
      width: '100%',
      header: {
        cssCustom: 'font-weight-bold class-color-title',
        rows: [{
          backgroundColor: '#F8F7F7',
          columns: base
        }],
      },
      body: {
        cssCustom: '',
        rows: this.setContentTable(base)
      },
      footer: {
        cssCustom: '',
        rows: []
      }
    };
  }

  setContentTable(configuration): Row[] {
    const content: Row[] = [];
    let i = 0;




    content.push(
      {
        identifier: null,
        cssCustom: '',
        enabled: true,
        columns: [
          {
            alignContent: configuration[0].alignContent,
            fixed: configuration[0].fixed,
            maxWidth: configuration[0].maxWidth,
            minWidth: configuration[0].minWidth,
            html: this.client.person ? this.client.person.givenName: '',
            cssCustom: configuration[0].cssCustom + ' text-capitalize',
            enableLeftBorder: !isNullOrUndefined(configuration[0].enableLeftBorder) ? configuration[0].enableLeftBorder : false,
            enableRightBorder: !isNullOrUndefined(configuration[0].enableRightBorder) ? configuration[0].enableRightBorder : false
          },
          {
            alignContent: configuration[1].alignContent,
            fixed: configuration[1].fixed,
            maxWidth: configuration[1].maxWidth,
            minWidth: configuration[1].minWidth,
            html: this.client.person ? this.client.person.familyName: '',
            cssCustom: configuration[1].cssCustom+ ' text-capitalize',
            enableLeftBorder: !isNullOrUndefined(configuration[1].enableLeftBorder) ? configuration[1].enableLeftBorder : false,
            enableRightBorder: !isNullOrUndefined(configuration[1].enableRightBorder) ? configuration[1].enableRightBorder : false
          },

          {
            alignContent: configuration[2].alignContent,
            fixed: configuration[2].fixed,
            maxWidth: configuration[2].maxWidth,
            minWidth: configuration[2].minWidth,
            html: this.client.identifier,
            cssCustom: configuration[2].cssCustom ,
            enableLeftBorder: !isNullOrUndefined(configuration[2].enableLeftBorder) ? configuration[2].enableLeftBorder : false,
            enableRightBorder: !isNullOrUndefined(configuration[2].enableRightBorder) ? configuration[2].enableRightBorder : false
          },
          {
            alignContent: configuration[3].alignContent,
            fixed: configuration[3].fixed,
            maxWidth: configuration[3].maxWidth,
            minWidth: configuration[3].minWidth,
            html: 'Propietario',
            cssCustom: configuration[3].cssCustom ,
            enableLeftBorder: !isNullOrUndefined(configuration[3].enableLeftBorder) ? configuration[3].enableLeftBorder : false,
            enableRightBorder: !isNullOrUndefined(configuration[3].enableRightBorder) ? configuration[3].enableRightBorder : false
          },
          {
            alignContent: configuration[4].alignContent,
            fixed: configuration[4].fixed,
            maxWidth: configuration[4].maxWidth,
            minWidth: configuration[4].minWidth,
            html: this.client.person.email,
            cssCustom: configuration[4].cssCustom + ' ak-text-color-4',
            enableLeftBorder: !isNullOrUndefined(configuration[4].enableLeftBorder) ? configuration[4].enableLeftBorder : false,
            enableRightBorder: !isNullOrUndefined(configuration[4].enableRightBorder) ? configuration[4].enableRightBorder : false
          },
          {
            alignContent: configuration[5].alignContent,
            fixed: configuration[5].fixed,
            maxWidth: configuration[5].maxWidth,
            minWidth: configuration[5].minWidth,
            html: this.datePipe.transform(this.client.createDate),
            cssCustom: configuration[5].cssCustom,
            enableLeftBorder: !isNullOrUndefined(configuration[5].enableLeftBorder) ? configuration[5].enableLeftBorder : false,
            enableRightBorder: !isNullOrUndefined(configuration[5].enableRightBorder) ? configuration[5].enableRightBorder : false
          }
        ]
      }
    );

    return content;
  }


  setHeadTable() {

    const base: Column[] = [
      {
        alignContent: 'left',
        fixed: 'right',
        maxWidth: '200',
        minWidth: '100',
        html: 'Nombre',
        cssCustom: '',
        enableRightBorder: true
      },
      {
        alignContent: 'left',
        fixed: false,
        maxWidth: '200',
        minWidth: '100',
        html: 'Apellido',
        cssCustom: '',
      }
      ,
      {
        alignContent: 'left',
        fixed: false,
        maxWidth: '200',
        minWidth: '100',
        html: 'Usuario',
        cssCustom: ''
      },
      {
        alignContent: 'left',
        fixed: false,
        maxWidth: '200',
        minWidth: '100',
        html: 'Rol',
        cssCustom: ''
      },
      {
        alignContent: 'left',
        fixed: false,
        maxWidth: '200',
        minWidth: '100',
        html: 'Email',
        cssCustom: ''
      },
      {
        alignContent: 'left',
        fixed: false,
        maxWidth: '200',
        minWidth: '100',
        html: 'Creado',
        cssCustom: ''
      }
    ];
    return base;
  }

  table_response() {

  }

}
