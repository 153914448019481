import { Component, OnInit, Input, Output, OnDestroy, OnChanges, SimpleChanges, DoCheck, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { Observable, BehaviorSubject, Subscription, async } from 'rxjs';
import { AuthService } from '@akeela/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { TransitionService } from '../../../../services/transition.service';
import swal from 'sweetalert2';
import { I18nService, I18nPipe } from '@akeela/i18n';
import { RefreshService } from '../../services/refresh.service';
import { ProgressService } from '../../services/progress.service';

import { UploadService, ErrorService, PaginatorService } from '@akeela/common';
import { environment } from '../../../../../environments/environment';
import { SearchService } from '../../../../services/search.service';
import { isNullOrUndefined, log } from 'util';
import merge from 'lodash/merge'
import { LocalService } from '@akeela/local-storage';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';

import { AkeelaSidebarMenuService } from '@akeela/menu';
import { Table, Row, Column, Dropdown, DropdownList } from '@akeela/ak-table';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { DatePipe } from '@angular/common';
import { debug } from 'console';
import { DomSanitizer } from '@angular/platform-browser';
import { asin } from '@tensorflow/tfjs-core';
import { CastExpr } from '@angular/compiler';
import { WorkflowService } from 'src/app/services/workflow.service';


@Component({
  selector: 'app-list-account-analysis',
  templateUrl: './list-account-analysis.component.html',
  styleUrls: ['./list-account-analysis.component.css']
})

export class ListAccountAnalysisComponent implements OnInit, OnChanges, OnDestroy, DoCheck {

  static FilterDown = 'DOWN';
  static FilterUp = 'UP';
  params;
  env: any = environment;

  workspaceName;
  queryParams;
  workflow;
  stage;
  step;
  transaction_taken = null;
  loading_order;
  reload = false;

  agencys: any;
  executives: any;

  transactions: any = null;
  filter: any = {};
  filter_policys: any = {};
  statusTransaction: any = {};
  params_search = null;
  object_search = null;

  paginator: any = null;
  loading_table = true;

  policys = null;
  policy = null;

  filter_order: any = {};

  query_params_subscriptor: Subscription;
  workflowEventsSubscription: Subscription;

  ColumnMode = ColumnMode;
  subscriptions: Subscription[] = [];
  queueName;

  isAnalist = false;

  queue;
  list_users;

  username;
  role;
  queueNameWsp;
  workspace;
  indicatorsProgres = {};
  configuration_table: Table = null;
  states = null;
  current_state = null;
  readonly = false;


  rows = [];
  selected = [];
  temp = [];
  columns = [];
  SelectionType = SelectionType;
  temp2;
  fieldSearch: any = '';
  anchorSearch = [];
  icon_1 = [];
  icon_2 = [];
  isLoading: boolean;
  profile;
  loadingIndicator = false;
  screenMax = true;
  timeElapsedSetInterval = {};
  timeElapsed = {};
  filters = {};
  formsData: any = {};
  formsDataResponse: any = {};
  historyTransaction;
  boxFilters;
  limitRows = 10;
  totalGet = 9999999;

  stateCodeFilter = '';

  danger = environment.appEndPontDefaultConfig.ac_pending.dangerMts;
  warning = environment.appEndPontDefaultConfig.ac_pending.warningMts;


  temporalOrder = [];
  countFilterTimeValue = 0;
  openFilter = false;
  animate_init = true;
  animate_init_bottom = true;
  filterEtique: any = [];
  showModalResponses = false;
  numberSolicitud;
  @ViewChild("chartsContent", { static: false }) chartsContent: ElementRef;
  workspaceDetail: any = {};
  estadistica = false;

  transactionsBase: any = [];

  preview;
  loading = false;

  reload_loading = false;
  buttomModal;

  updateDate;
  filterBy = true;
  orderBy = false;

  timeReponseDesc = false;

  responseType = [];

  clientDetail;
  transactionDetail;

  clientSelected;
  transactionSelected;

  optVerification;

  indicators_progres = {};
  indicatorsLoading = true;
  indicatorsRowsLoading = true;
  indicatorsInit = true;

  history = {};
  historyData = [];
  historyDataSelect = {};

  indicatorsProgresTemp = {};
  transactionTemp = {};
  historyDataSelfi = {};

  fileAvatarPreview = {};
  tap;
  productsPlans = {};
  indicatorsProgresRsp = {};
  usersTransactions: any = {};
  baseProgresCompleat;

  orderByData = 1;
  notes = {};
  transactionTakenYou;
  takenListUsers = {};
  objFilters = [];

  workspaceParams = null;

  constructor(
    private _httpService: HttpService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private _auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private akeelaSectionService: AkeelaSectionService,
    private transitionService: TransitionService,
    private i18n: I18nPipe,
    private refresh: RefreshService,
    private _progressService: ProgressService,
    private error: ErrorService,
    private _search: SearchService,
    private localStorage: LocalService,
    private _breadcrumbService: AkeelaBreadcrumbService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    public config: NgbDropdownConfig,
    private datePipe: DatePipe,
    private _i18n: I18nPipe,
    private _upload: UploadService,
    private sanitizer: DomSanitizer,
    private paginatorService: PaginatorService,
    private _wfService: WorkflowService,

  ) {

    this.localStorage.removeItem('objBackTransaction');
    // console.log('AQUI:');
    // console.log(this.localStorage.getItem('workspaceAll'));
    if(!isNullOrUndefined(this.localStorage.getItem('workspaceAll'))) {
      this.workspaceParams = JSON.parse(this.localStorage.getItem('workspaceAll'));
    }
    let temp = this.getTimeDate(this.datePipe.transform(Date(), 'dd/MM/yyyy HH:mm'));
    this.updateDate = (temp);

    this.resetForms();
    config.placement = 'bottom-left';
    config.autoClose = true;


    if (!this.localStorage.getItem('queueDescriptionDefault')) {

      const queryParams = {
        profile: this.localStorage.getItem('profile'),
        role: this.localStorage.getItem('role'),
        audience: this.localStorage.getItem('audience'),
      };


      this.router.navigateByUrl(
        this.router.createUrlTree([environment.staticLinks.listClients],
          {
            queryParams: queryParams
          }
        )
      );
    }

    this.akStageService.setshowHeaderStage(false);
    this.akStepService.setshowHeaderStep(false);
    this.akStepService.setshowSubHeaderStep(false);

    this.statusTransaction = {
      filterActive: true,
      current: null,
    };
    this.filter = {
      active: this.policy
    };
    this.filter = {
      active: 2,
      object: [
        {
          identifier: 0,
          movement: 'DOWN'
        },
        {
          identifier: 1,
          movement: 'DOWN'
        },
        {
          identifier: 2,
          movement: 'DOWN'
        },
        {
          identifier: 3,
          movement: 'DOWN'
        }
      ]
    };

  }


  public ngDoCheck() {

    if (screen.width < 769) {

      this.screenMax = false;
    } else {

      this.screenMax = true;

    }
  }


  getStatusCode(data) {

    let states = this.states;
    if (states) {
      let tmp = states.filter(valor => valor.code === data);
      return tmp[0]

    }
    return null;


  }

  setFilterStatus(data) {

    this.formsData.status2.key = data;


    let states = this.states;
    states.map((valor) => {

      if (valor.userName === data) {

        this.stateCodeFilter = valor.code;
      }

    });

    if (this.stateCodeFilter != 'AC_PENDING') {

      this.formsData.time.key = '';

    }

  }

  getRowClass(row) {
    return {
      'ak-table-opacity': row.icon == 'other',
      'ak-table-your': row.icon == 'your'
    };
  }


  submitFormsDataResponse() {

    const params = {
      name: 'account_opening$formsDataResponse$post',
      params: {
        request: {
          requestUser: this._auth?.getUsername(),
          valueAttributeListOption: this.formsDataResponse?.type,
          comments: this.formsDataResponse?.description,
          nameAttributeList: 'reasonElimination',
        },
        path: {
          transactionNumber: this.formsDataResponse?.number
        },
        body: {}
      }
    };

    swal.fire({
      title: '',
      html: this.i18n.transform('¿Estas seguro que deseas archivar esta solicitud?'),
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {

      if (result?.isConfirmed) {

        let solicitud = this.formsDataResponse?.number;

        this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

          swal.fire({
            reverseButtons: true,
            confirmButtonText: 'Ok',
            title: 'Éxito',
            html: 'Solicitud Archivada',
          });

          this.rows = this.rows.filter(function (value, index, arr) {
            return value.solicitud != solicitud;

          });

          this.buttomModal = false;

        }, error => {


          this.rows = this.rows.filter(function (value, index, arr) {
            return value.solicitud != solicitud;

          });

          this.buttomModal = false;

          this.error.show(error, this.i18n.transform('Alerta!'), this.i18n.transform('No se pudo dar respuesta a esta Solicitud  <br><b>' + this.formsDataResponse?.number + '</b>'));
        });


      } else {


      }



    });
  }

  resetForms() {
    this.stateCodeFilter = '';

    this.formsData = {
      externalCode: { type: 'empty', key: null },
      completeIdentification: { type: 'string', key: '' },
      verifiedIdentification: { type: 'string', key: '' },
      audience: { type: 'string', key: '' },
      plan: { type: 'string', key: '' },
      tipo: { type: 'string', key: '' },
      ejecutivo: { type: 'string', key: '' },
      accounttype: { type: 'string', key: '' },
      agencia: { type: 'string', key: '' },
      status2: { type: 'string', key: '' },
      fecha: { type: 'date', dateInit: '', dateEnd: '', delimit: '/' },
      time: { type: 'time', key: '', relation: 'fecha' },
      rangeDatosCliente:
      {
        name: 'AO_range',
        type: 'range',
        min: 0,
        max: 100
      },


      rangeRecaudos:
      {
        name: 'RQ_range',
        type: 'range',
        min: 0,
        max: 100
      }



    };
    this.filters = {};
    this.updateFilters();

  };



  setFilterMultiple() {


    if (Object.keys(this.formsData).length > 0) {
      
      
      for (const temp of Object.keys(this.formsData)) {

        this.setFilter(temp, this.formsData[temp]);

      };

      this.updateFilters();

    }

  }


  setFilter(name, obj) {

    this.filters[name] = obj;

  }

  updateFilters() {
    this.loading_table = true;

    let data2 = [];
    // this.fieldSearch = '';

    if (Object.keys(this.filters).length > 0) {
      let objFilters: any = this.temp;

      for (const temp of Object.keys(this.filters)) {

        let type = this.filters[temp].type;

        if (type == 'date') {

          let { dateInit, dateEnd, delimit } = this.filters[temp];
          objFilters = [...this.filterDate(objFilters, temp, dateInit, dateEnd, delimit)];

        } else if (type == 'string') {

          let { key } = this.filters[temp];

          if (key?.trim() != '') {

            objFilters = [...this.filterSting(objFilters, temp, key)];


          }

        } else if (type == 'time') {

          let { key, relation } = this.filters[temp];

          if (key.trim() != '' && this.stateCodeFilter == 'AC_PENDING') {

            let temporal = this.filterTime(objFilters, relation, key);

            objFilters = [...temporal];

          }

        }
        else if (type == 'range' && !this.indicatorsLoading) {

          let { min, max, name } = this.filters[temp];

          if ((min > -1 || max > -1) && !(min == 0 && max == 100)) {

            objFilters = [...this.filterRange(objFilters, name, min, max)];


          }

        } else if (type == 'empty') {

          let { key } = this.filters[temp];

          if(!(key === null)){
            
            objFilters = [...this.filterEmpty(objFilters, temp, key)];

          }

        }


      }

      objFilters = this.setOrderBy(objFilters);

      this.objFilters = objFilters;


      // objFilters = this.orderFilterDate(objFilters, 'fecha', 'status2');



      //objFilters =  this.orderFilterStatus(objFilters,'status2',(this.getStatusCode('AC_PENDING')).userName);

      data2 = objFilters;

    } else {

      let objFilters = this.temp;
      data2 = objFilters;

    }



    this.update_transactionsFilter(data2);
    this.rows = this.paginatorService.getDataPaginator(this.paginator, this.env, data2);
    //this.loading_table = false;

    this.getIndicatorsProgres(this.rows, false);
    this.getAvatars(this.rows);
    this.getUsersTransactions(this.rows);
    this.setNotesForUserNames(this.rows);


    this.filtersLinks();

    this.loading_table;

  }




  setTablePaginators() {
    // let data = this.transactions;
    let data = this.objFilters;

    data = this.paginatorService.getDataPaginator(this.paginator, this.env, data);

    this.paginator.number = this.transactions?.length;
    this.paginator.totalPages = Math.ceil(this.transactions?.length / this.env.paginator.size);
    this.paginator.pageable.to = this.paginatorService.getPaginatorTo(this.paginator, this.env);
    this.paginator.pageable.from = this.paginatorService.getPaginatorFrom(this.paginator, this.env);
    this.rows = data;
    this.getAvatars(this.rows);
    this.getUsersTransactions(this.rows);
    this.setNotesForUserNames(this.rows);


  }



  filtersLinks() {

    //this.fieldSearch = '';
    let tempFilterEtique = [];

    if (Object.keys(this.filters).length > 0) {
      let objFilters = this.temp;

      for (const temp of Object.keys(this.filters)) {

        let tempObj = {};


        let type = this.filters[temp].type;

        if (type == 'date') {

          let { dateInit, dateEnd, delimit } = this.filters[temp];
          if (dateInit.trim() != '') {

            tempObj = { label: "Desde: " + dateInit, key: "dateInit", type }
            tempFilterEtique = [...tempFilterEtique, tempObj];

          }

          if (dateEnd.trim() != '') {

            tempObj = { label: "Hasta: " + dateEnd, key: "dateEnd", type }
            tempFilterEtique = [...tempFilterEtique, tempObj];



          }

        } else if (type == 'string') {
          let { key } = this.filters[temp];

          if (key.trim() != '') {
            let label = this.filters[temp].key;

            if (temp == 'completeIdentification') {

              label = (label == 'true' ? 'Con ' : 'Sin ') + 'verificación de identidad aprobada';

            } else if (temp == 'verifiedIdentification') {

              label = (label == 'true' ? 'Con ' : 'Sin ') + 'verificación de identidad completada';

            } else if (temp == 'confirm') {

              label = (label == 'true' ? 'Con ' : 'Sin ') + 'verificación de email';

            } else if (temp == 'solicitudes') {

              label = (label == 'true' ? 'Con ' : 'Sin ') + 'solicitudes';

            }



            tempObj = { label: label, key: temp, type }
            tempFilterEtique = [...tempFilterEtique, tempObj];

          }

        } else if (type == 'time') {

          let { key, relation } = this.filters[temp];

          if (key.trim() != '' && this.stateCodeFilter == 'AC_PENDING') {

          }

        }else if (type == 'empty') {

          
          let { key } = this.filters[temp];

            tempObj = { label: temp + ": "+(key?"Si":"No"), key: temp, type }
            tempFilterEtique = [...tempFilterEtique, tempObj];


        } /* else if (type == 'range') {


          let { min,max,name,key } = this.filters[temp];

          if ((min  > -1 || max > -1) && !(min == 0 && max == 100)) {

            let tmpName = name == 'AO_range'?'Datos del cliente':'Recaudos';

              tempObj = { label: tmpName +" " + min + "% al "+ max+"%", key:temp, type }
            tempFilterEtique = [...tempFilterEtique, tempObj];
        

            ;

          }

        }*/
      }

      objFilters = this.orderFilterDate(objFilters, 'fecha', 'status2');
      //objFilters =  this.orderFilterStatus(objFilters,'status2',(this.getStatusCode('AC_PENDING')).userName);


      this.filterEtique = [...tempFilterEtique];


    }

  }


  closeReset() {
    this.resetForms();
    this.boxFilters = false;
    //this.fieldSearch = '';
  }


  filterTime(obj = [], name, key) {

    let response = false;
    let objs = this;

    let temp = obj.filter(function (d) {
      response = false;
      let value = d[name];
      let values = objs.getTimeElapsed(value, objs.warning, objs.danger)

      let { timeDangerHHValue, timeWarningHHValue } = values;


      if (key == 'warning' && timeWarningHHValue == true) {

        response = true;

      } else if (key == 'danger' && timeDangerHHValue == true) {

        response = true;

      } else if (key == '') {

        response = true;

      }

      return response;

    });

    return temp
  }



  deleteFilter(key, type) {
    let filtered = this.filterEtique.filter(function (value, index, arr) {
      return value.key != key;

    });


    if (type == 'date') {

      if (Object.keys(this.formsData).length > 0 && this.formsData["fecha"] && this.formsData["fecha"][key]) {
        this.formsData["fecha"][key] = "";
        this.updateFilters();

      }
    } else if (type == 'string') {


      if (Object.keys(this.formsData).length > 0 && this.formsData[key] && this.formsData[key].key) {
        this.formsData[key].key = "";
        this.updateFilters();

      }

    } else if (type == 'range') {

      if (Object.keys(this.formsData).length > 0 && this.formsData[key]) {
        this.formsData[key].min = 0;
        this.formsData[key].max = 100;

        this.updateFilters();

      }

    }else if (type == 'empty') {

      
     

      if (Object.keys(this.formsData).length > 0 && this.formsData[key] && this.formsData[key].key) {
        this.formsData[key].key = null;
        this.updateFilters();

      }


    }


    this.filterEtique = [...filtered];


  }


  orderFilterStatus(obj: any = [], name, key) {


    let objs = obj.sort((a, b) => {

      if (a[name] == key) {

        return 1;

      } else if (a[name] != key) {

        return -1;

      }

      return 0;

    });


    const temp = objs.filter(function (d) {

      //  console.log("status: ", d['solicitud'], " ", d['status2']);

    });

    return objs;


  };




  orderFilterDate(obj: any = [], name, name2) {

    let objs = obj.sort((a, b) => {

      let dateMaestro = b[name]?.time?.split(" ");
      let m = dateMaestro[0].split("/");
      let date: any = [];

      if (dateMaestro[1]) {

        let x = dateMaestro[1].split(":");
        date = new Date(Number(m[2]), Number(m[1]) - 1, Number(m[0]), Number(x[0]), Number(x[1]));

      } else {

        date = new Date(Number(m[2]), Number(m[1]) - 1, Number(m[0]));

      }

      let dateMaestro2 = a[name]?.time?.split(" ");
      let m2 = dateMaestro2[0].split("/");
      let date2: any = [];

      if (dateMaestro[1]) {

        let x2 = dateMaestro2[1].split(":");
        date2 = new Date(Number(m2[2]), Number(m2[1]) - 1, Number(m2[0]), Number(x2[0]), Number(x2[1]));

      } else {

        date2 = new Date(Number(m2[2]), Number(m2[1]) - 1, Number(m2[0]));

      }

      if (this.timeReponseDesc) {


        if (date.getTime() < date2.getTime()) {

          return 1;

        }

        if (date.getTime() > date2.getTime()) {



          return -1;

        }

      } else {

        if (date.getTime() > date2.getTime()) {

          return 1;

        }
        if (date.getTime() < date2.getTime()) {



          return -1;

        }


      }


      return 0;

    });

    /*
      const temp = objs.filter(function (d) {
    
        let dateMaestro =   d[name].split(" ");
        let x =  dateMaestro[1].split(":");
        let m =  dateMaestro[0].split("/");
        let date:any = new   Date( Number(m[2]),Number(m[1])-1,Number(m[0]),Number(x[0]),Number(x[1]));
    
         console.log("date: ",d['solicitud']," ",date);
        
      });
    */

    return objs;

  }

  filterDate(obj = [], name, dateInit, dateEnd, delimit) {

    if ((!dateInit || dateInit.trim() == '') && (!dateEnd || dateEnd.trim() == '')) {

      return obj;

    }

    let i = dateInit.split("-");
    let letDateInit: any = new Date(Number(i[0]), Number(i[1]) - 1, Number(i[2]));
    let f = dateEnd.split("-");
    let letDateEnd: any = new Date(Number(f[0]), Number(f[1]) - 1, Number(f[2]));

    const temp = obj.filter(function (d) {

      let fecha = d[name];
      let dateMaestro = fecha?.time?.split(" ");
      let m = dateMaestro[0].split(delimit);

      let letFecha: any;

      if (dateMaestro && dateMaestro.length > 1) {

        let x = dateMaestro[1].split(":");
        letFecha = new Date(Number(m[2]), Number(m[1]) - 1, Number(m[0]));

      } else {

        letFecha = new Date(Number(m[2]), Number(m[1]) - 1, Number(m[0]));

      }
      /*
            console.log("");
            console.log("letFecha:  ",letFecha.getTime(),' ',letFecha,' ',fecha);
            console.log("letDateInit:  ",letDateInit.getTime(),' ',letDateInit,' ',dateInit);
            console.log("letDateEnd:  ",letDateEnd.getTime(),' ',letDateEnd,' ',dateEnd);
            console.log("");
      */



      if ((name && letFecha instanceof Date) &&
        (dateInit && letDateInit instanceof Date) &&
        (dateEnd && letFecha instanceof Date)) {


        return (letFecha.getTime() >= letDateInit.getTime() && letFecha.getTime() <= letDateEnd.getTime());


      } else if ((name && letFecha instanceof Date) &&
        !(dateInit && letDateInit instanceof Date) &&
        (dateEnd && letFecha instanceof Date)) {

        return (letFecha.getTime() <= letDateEnd.getTime());


      } else if ((name && letFecha instanceof Date) &&
        (dateInit && letDateInit instanceof Date) &&
        !(dateEnd && letFecha instanceof Date)) {

        return (letFecha.getTime() >= letDateInit.getTime());

      }

    });

    return temp

  }

  countFilterSting(obj = [], name, key) {

    let objFilters = [...this.filterSting(obj, name, key)];

    return objFilters.length;

  }




  countFilterTime(obj = [], name, key, relation, type) {


    let objFilters = [];
    objFilters = [...this.filterSting(obj, name, key)];


    objFilters = this.filterTime(objFilters, relation, type);


    return objFilters.length;

  }

  replaceSpaces = (str) => {
    return str.replace(/  +/g, ' ');
  }

  filterSting(obj = [], name, key) {

    let val = key?.toString()?.toLowerCase();
    if(!isNullOrUndefined(val)) {
      val = val.replace(/  +/g, ' ');
    }
    
    let temp = obj.filter(function (d) {
      let value = d[name];

      value = value?.toString()?.toLowerCase().trim();
      if(!isNullOrUndefined(value)) {
        value = value.replace(/  +/g, ' ');
      }

      console.log(value);
      let result = typeof value === 'string' && value.indexOf(val.trim()) !== -1;

      return result;

    });



    return temp

  }

  


  filterEmpty(obj = [], name, key) {
    let temp = obj.filter(function (d) {
      let value = d[name];
      let result;

      if (key) {

        result = value && true ;

      }else{

        result =  !value  && true ;

      }



      return result;

    });


    return temp

  }


  filterRange(obj = [], name, min, max) {
    const temp = obj.filter(function (d) {
      let value = d[name];


      return value >= min && value <= max;

    });

    return temp

  }





  ngOnChanges(changes: SimpleChanges) {

    for (const propName in changes) {

      if (changes.hasOwnProperty(propName)) {
        // switch (propName) {
        //   case 'code': {
        //     if (!isNullOrUndefined(changes[propName].currentValue)) {
        //       this.code = changes[propName].currentValue;
        //       this.getWorkflowDetail();
        //     }
        //   }
        // }
      }
    }
  }

  ngOnInit() {


    let notes = this._wfService.tempNote$?.getValue();


    this.formsDataResponse = {
      type: '',
      description: ''
    }

    this.formsDataResponse = {
      type: '',
      description: ''
    }

    this.profile = this.localStorage.getItem('profile');

    this.timeReponseDesc = false;

    this.getPlans();

    this.localStorage.removeItem('readonly');
    this._akeelaSidebarMenu.updateQueues$.next(true);
    if (this.localStorage.getItem('workspaceReadonly') === 'true' || this.localStorage.getItem('readonly') === 'true') {
      this.readonly = true;
    }
    this._akeelaSidebarMenu.setSidebarLeftActive(environment.appEndPontDefaultConfig.sidebarLeftActive);
    this.processBreacrum();

    this._akeelaSidebarMenu.setSidebarLeftActive(environment.appEndPontDefaultConfig.sidebarLeftActive);

    this.akWorkflowService.transactionData$.next(null);
    this.role = this.localStorage.getItem('role');
    if (this.localStorage.getItem('profile') === 'ANALIST') {
      this.isAnalist = true;
    } else {
      this.isAnalist = false;
    }
    this.username = this._auth.getUsername();
    this.workspaceName = this.localStorage.getItem('workspace');

    this.localStorage.removeItem('takenedit');
    this.refresh.refresh.next(null);
    this.stage = this.akStageService.currentStage$.getValue();
    this.step = this.akStepService.currentStep$.getValue();
    this.workflow = this.akWorkflowService.currentWorkflow$.getValue();
    this.akeelaSectionService.addModalSection({ name: 'PROD_PLAN_SELECTOR_MODAL' });

    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {

      this.orderByData = 1;

      this.getStates();
      this.loading_table = true;
      this.queryParams = queryParams;
      this.baseProgresCompleat = null;
      if (!isNullOrUndefined(queryParams.codeQueue)) {
        this.queueName = queryParams.codeQueue;
        this.localStorage.setItem('queue', this.queueName);

        this.processBreacrum();

        this.getDetailQUEUE(this.queueName);

      } else if (!isNullOrUndefined(this.localStorage.getItem('queue'))) {
        this.queueName = this.localStorage.getItem('queue');
        this.getDetailQUEUE(this.queueName);
      }
      // this.getPolicys();
      // this.paginator = null;

      this.getWorkflowDetail();

    }));

    this.filter_order = {
      identifier: null,
      movement: null
    };

    this.getExecutives();
    this.getAgencys();
    this.getReasonElimination();


    this.workflowEventsSubscription = this.akWorkflowService.worflowEvents$.subscribe((response: any) => {

      if (response?.action === 'reloadDataResponse') {

        this.getWorkflowDetail();

      }
    });


    this._wfService.transactionTakenYou$.subscribe(response => {

      this.transactionTakenYou = response;

    });


  }

  reloadTransaction() {
    this.updateDate = null;

    this.reload_loading = true;
    this.getTransactionsPaginator(this.statusTransaction.current, environment.paginator.page, this.totalGet);

  }


  setConfigurationTable() {
    const base: Column[] = environment.akeelaTablesConfig.listTransactionsUser.columns;
    this.configuration_table = {
      cssCustom: '',
      width: '100%',
      header: {
        cssCustom: 'font-weight-bold class-color-title',
        rows: [{
          backgroundColor: '#F8F7F7',
          columns: base
        }],
      },
      body: {
        cssCustom: '',
        rows: this.setContentTable(base)
      },
      footer: {
        cssCustom: '',
        rows: []
      }
    };
  }



  //juan
  setConfigurationTableNew(noUpdate?) {

    let temp2 = this.getTimeDate(this.datePipe.transform(Date(), 'dd/MM/yyyy HH:mm'));
    this.updateDate = (temp2); let transactions = this.transactionsBase;
    // ejemplo.filter((r, i) => i < 10);

    let data: any = [];
    const content: Row[] = [];
    let datas = [];


    for (const transaction of transactions) {


      let row: any;
      let response: any = [];
      row = transaction;

      // row = transaction.bankAccountTransaction;
      // response = transaction.modules;

      //if(row.transactionNumber == "842021-1755"){

      if (true) {
        let date = row.dateOfThing?.modifiedDate ? row.dateOfThing?.modifiedDate : row.dateOfThing?.startDate;

        const items = [];
        response = response?.sort(function (a, b) {
          return a.identifier - b.identifier;
        });
        for (const resp of response) {
          items.push({
            identifier: resp.alternateName,
            name: resp.approvalUser,
            icon: resp.modifyUser,
            active: true,
            type: resp.additionalType,
            progress: {
              percent: null,
              total: resp.progress.maxValue ? resp.progress.maxValue : 0,
              completed: resp.progress.minValue ? resp.progress.minValue : 0
            },
            link: resp.url ? JSON.parse(resp.url) : null
          });
        }
        let modules = items;
        let module_AO;
        let module_RQ;


        let compleat = true;
        for (let item of modules) {


          item.progress.completed != item.progress.total

          if (item) {

            if (item.progress.completed != item.progress.total) {
              compleat = false;
            }
          }


        }

        for (let item of modules) {

          if (item.identifier == 'ACCOUNT_OPENING') {

            module_AO = item;
          }

          if (item.identifier == 'REQUIREMENT') {

            module_RQ = item;

          }
        }


        let icon1 = '';
        if (row.taken && this.isAnalist && !this.readonly) {


          if (!isNullOrUndefined(this.transaction_taken) && this.transaction_taken) {
            if (this.transaction_taken !== null && (this.transaction_taken).replace('|EDIT_TAKEN', '') === row.transactionNumber) {
              icon1 = 'your';
              this.takenListUsers[row.transactionNumber] = 'your';

            } else {
              this.takenListUsers[row.transactionNumber] = 'other';

              icon1 = 'other';
            }
          } else {
            this.takenListUsers[row.transactionNumber] = 'other';

            icon1 = 'other';
          }
        }



        let responseTime = '';
        if (row.responseTimeStatus === 'RED') {
          responseTime = '<div class=\'icon-red\'>' + (row.responseTime ? this.formatTime(row.responseTime) : '-') + '</div>';
        } else {
          responseTime = '<div>' + (row.responseTime ? this.formatTime(row.responseTime) : '-') + '</div>';
        }

        if (row.accountStatus) {

          this.akgetTimeElapsedSetInterval(row.identifier, this.datePipe.transform(date, 'dd/MM/yyyy HH:mm'));
        }


        let jsonTmp =
        {

          "plan": { name: this.i18n.transform(row?.plan?.name), shortDescription: this.i18n.transform(row?.plan?.shortDescription) },
          "workflow": row?.plan?.url,
          "planIdentifier": row?.plan?.identifier,
          "identifier": row.identifier,
          "icon": icon1,
          "solicitud": row.transactionNumber,
          "audience": row?.additionalType,
          "solicitante": row.holder ? String(row.holder.name).toLowerCase() : 'Todos',
          "agencia": row.channelAgency ? row.channelAgency.name : 'Todos',
          "ejecutivo": ((row.salesManagerUser) ? (row.salesManagerUser.name) : ('Todos')),
          "status": ((row.accountStatus) ? (row.accountStatus[0]) : 'Todos'),
          "status2": ((row.accountStatus) ? (row.accountStatus[0].userName) : ''),
          "create": date,
          "fecha": (row.accountStatus) ? (this.getTimeDate(this.datePipe.transform(date, 'dd/MM/yyyy HH:mm'))) : '-',
          "fecha2": this.timeElapsed[row.identifier],
          "time": responseTime,
          "razon": this._i18n.transform(row.description),
          "active": row.status == 'ACTIVE' ? true : false,
          "takenUser": row.taken ? row.takenUser : "",
          "username": row.holder ? row.holder.modifyUser : "",
          "verifiedIdentification": row?.holder.person?.verifiedIdentification ? true : false,
          "completeIdentification": row?.holder.person?.completeIdentification ? true : false,
          "externalCode": row?.holder?.person?.externalCode? row?.holder?.person?.externalCode:null,
          "accounttype": row?.plan?.url?row?.plan?.url:null


        }


        datas = [...datas, jsonTmp];


      }


    }

    // this.temp = [...datas];
    let temp = datas;


    this.columns = [

      { prop: 'identifier' },
      { prop: 'icon', name: 'Icon' },
      { prop: 'solicitud', name: 'Solicitud' },
      { prop: 'solicitante', name: 'Solicitante' },
      { prop: 'agencia', name: 'Agencia' },
      { prop: 'ejecutivo', name: 'Ejecutivo' },
      { prop: 'status', name: 'Estatus de la Solicitud' },
      { prop: 'time', name: 'Tiempo de Resp.' }
    ];

    let cont = 0;
    this.transactionTemp = [];
    for (const row of datas) {
      if (!isNullOrUndefined(row)) {
        cont++;
        let jsonTmp =
        {
          "identifier": row.identifier,
          "icon": row.icon,
          "plan": row.plan,
          "workflow": row?.workflow,

          "planIdentifier": row.planIdentifier,
          "solicitud": row.solicitud,
          "transactionNumber": row.solicitud,
          "audience": row.audience,
          "solicitante": row.solicitante,
          "agencia": row.agencia,
          "ejecutivo": row.ejecutivo,
          "status": row.status,
          "status2": row.status2,
          "create": row?.create,
          "fecha": row?.fecha,
          "fecha2": row.fecha2,
          "time": row.time,
          "takenUser": row.takenUser,
          "username": row.username,
          "razon": row.razon,
          "active": row.active,
          "verifiedIdentification": row?.verifiedIdentification,
          "completeIdentification": row?.completeIdentification,
          "externalCode": row?.externalCode


        }

        this.transactionTemp[row?.solicitud] = true;
        data = [...data, jsonTmp];
      }
    }


    this.paginator.pageable.to = this.paginatorService.getPaginatorTo(this.paginator, this.env);

    // cache our list
    if (isNullOrUndefined(noUpdate)) {
      this.temp = [...temp];
      this.objFilters = [...temp];
      this.transactions = this.temp;
    }

    // push our inital complete list
    if (isNullOrUndefined(noUpdate) && data?.length > 0) {
      // data = this.orderFilterDate(data, 'fecha', 'status2');


      data = this.setOrderBy(data);


      data = this.paginatorService.getDataPaginator(this.paginator, this.env, data);
      this.paginator.pageable.from = this.paginatorService.getPaginatorFrom(this.paginator, this.env);
      this.rows = data;

    }



    this.getIndicatorsProgres(this.rows, false);

    this.getAvatars(this.rows);
    this.getUsersTransactions(this.rows);
    this.setNotesForUserNames(this.rows);

    this.getIndicatorsProgres(this.temp, true);


    if (this.indicatorsInit) {

      this.getUser();

      // this.getIndicatorsProgres(this.temp ,true);
      this.indicatorsInit = false;




    } else {

      // this.getIndicatorsProgresTemp();


    }



    //this.setFilterMultiple();

    // this.countFilterTimeValue =  this.countFilterTime(this.temp,'status2',(this.getStatusCode('AC_PENDING')).userName,'fecha','danger' );

    this.loading_table = false;
    this.reload_loading = false;


  }

  generateProgressService = async (transactions, role, audience) => {



    const data = {
      bankAccountTransactions: transactions
    };


    const params = {

      name: 'resume$workflow_progress$post',
      params: {
        request: {
          role: role,
          audience: audience,

        },
        path: {},
        body: data

      }
    };

    return this._httpService.executeRequest(params.name, params.params, true).toPromise();


  }



  getIndicatorsProgresTransactions = async (datas, role, audience, base = false) => {
    let transactions = [];
    let data: any;

    for (const row of datas) {

      transactions = [...transactions, { transactionNumber: row?.solicitud }]

    }

    try {

      let response: any = await this.generateProgressService(transactions, role, audience);

      if (response) {

        for (const row of response) {

          let transactionNumber = row?.bankAccountTransaction?.transactionNumber;

          let progress: any;
          let preData = row.modules;

          let progress2 = preData;



          if (progress2?.length > 0) {

            let indicatorsProgresCompleat = { minValue: 0, maxValue: 0, percent: 0 };

            for (const row2 of progress2) {

              progress = row2?.progress;

              let maxValue = progress?.maxValue;
              let minValue = progress?.minValue;

              let indicatorsProgres = {};



              indicatorsProgres['name'] = row2?.name;

              indicatorsProgres['maxValue'] = maxValue;
              indicatorsProgres['minValue'] = minValue;
              indicatorsProgres['percent'] = minValue > 0 ? this.round(minValue * 100 / maxValue) : 0;

              if (!this.indicatorsProgresRsp[transactionNumber]) {
                this.indicatorsProgresRsp[transactionNumber] = {};

              }


              if (row2?.progressAdditional) {
                let req = row2?.progressAdditional;

                let maxValueReq = req?.maxValue;
                let minValueReq = req?.minValue;

                indicatorsProgres['maxValueReq'] = maxValueReq;
                indicatorsProgres['minValueReq'] = minValueReq;
                indicatorsProgres['percentReq'] = minValueReq > 0 ? this.round(minValueReq * 100 / maxValueReq) : 0;


                if (maxValueReq < maxValue) {

                  indicatorsProgres['optional'] = maxValue - maxValueReq;

                } else {

                  indicatorsProgres['optional'] = maxValueReq - maxValue;


                }




              }


              if (row2?.progressAdditional) {

                let req = row2?.progressAdditional;
                let maxValueReq = req?.maxValue;
                let minValueReq = req?.minValue;


                indicatorsProgresCompleat['minValue'] = indicatorsProgresCompleat['minValue'] + minValueReq;
                indicatorsProgresCompleat['maxValue'] = indicatorsProgresCompleat['maxValue'] + maxValueReq;


              } else {


                indicatorsProgresCompleat['minValue'] = indicatorsProgresCompleat['minValue'] + minValue;
                indicatorsProgresCompleat['maxValue'] = indicatorsProgresCompleat['maxValue'] + maxValue;


              }




              if (!row2?.alternateName) {

                this.indicatorsProgresRsp[transactionNumber]['DOC_SUPPORT'] = indicatorsProgres;


              } else {


                this.indicatorsProgresRsp[transactionNumber][row2?.alternateName] = indicatorsProgres;


              }


            }


            indicatorsProgresCompleat['percent'] = indicatorsProgresCompleat?.minValue > 0 ? this.round(indicatorsProgresCompleat?.minValue * 100 / indicatorsProgresCompleat?.maxValue) : 0;
            this.indicatorsProgresRsp[transactionNumber]['compleat'] = indicatorsProgresCompleat;

          }

        }
        ;

        if (base) {
          this.baseProgresCompleat = true

        }

      }

    } catch (error) {

    }


  }





  setConfigurationTableByFilter(transactions, noUpdate?) {

    // ejemplo.filter((r, i) => i < 10);

    let data = [];
    const content: Row[] = [];
    let datas = [];

    for (const transaction of transactions) {

      let row = transaction.bankAccountTransaction;
      let response = transaction?.modules;

      const items = [];
      response = response?.sort(function (a, b) {
        return a.identifier - b.identifier;
      });
      for (const resp of response) {
        items.push({
          identifier: resp.alternateName,
          name: resp.approvalUser,
          icon: resp.modifyUser,
          active: true,
          type: resp.additionalType,
          progress: {
            percent: null,
            total: resp.progress.maxValue ? resp.progress.maxValue : 0,
            completed: resp.progress.minValue ? resp.progress.minValue : 0
          },
          link: resp.url ? JSON.parse(resp.url) : null
        });
      }
      let modules = items;
      let module_AO;
      let module_RQ;


      let compleat = true;
      for (let item of modules) {


        item.progress.completed != item.progress.total

        if (item) {

          if (item.progress.completed != item.progress.total) {
            compleat = false;
          }
        }


      }

      for (let item of modules) {

        if (item.identifier == 'ACCOUNT_OPENING') {

          module_AO = item;
        }

        if (item.identifier == 'REQUIREMENT') {

          module_RQ = item;

        }

      }


      let icon1 = '';
      if (row.taken && this.isAnalist && !this.readonly) {
        if (!isNullOrUndefined(this.transaction_taken) && this.transaction_taken) {
          if (this.transaction_taken !== null && (this.transaction_taken).replace('|EDIT_TAKEN', '') === row.transactionNumber) {
            icon1 = 'your';
          } else {
            icon1 = 'other';
          }
        } else {
          icon1 = 'other';
        }
      }


      let responseTime = '';
      if (row.responseTimeStatus === 'RED') {
        responseTime = '<div class=\'icon-red\'>' + (row.responseTime ? this.formatTime(row.responseTime) : '-') + '</div>';
      } else {
        responseTime = '<div>' + (row.responseTime ? this.formatTime(row.responseTime) : '-') + '</div>';
      }

      if (row.accountStatus) {
        this.akgetTimeElapsedSetInterval(row.identifier, this.datePipe.transform(row.accountStatus[0].dateOfThing.startDate, 'dd/MM/yyyy HH:mm'));
      }

      let jsonTmp =
      {
        "plan": this._i18n.transform(row?.plan?.shortDescription),
        "workflow": row?.plan?.url,
        "identifier": row.identifier,
        "icon": icon1,
        "solicitud": row.transactionNumber,
        "audience": !isNullOrUndefined(row.holder.person) ? 'NATURAL' : 'LEGAL',
        "solicitante": row.holder ? String(row.holder.name).toLowerCase() : 'Todos',
        "agencia": row.channelAgency ? row.channelAgency.name : 'Todos',
        "ejecutivo": ((row.salesManagerUser) ? (row.salesManagerUser.name) : ('Todos')),
        "status": ((row.accountStatus) ? (row.accountStatus[0]) : 'Todos'),
        "status2": ((row.accountStatus) ? (row.accountStatus[0].userName) : ''),
        "fecha": (row.accountStatus) ? (this.datePipe.transform(row.accountStatus[0].dateOfThing.startDate, 'dd/MM/yyyy HH:mm')) : '-',
        "fecha2": this.timeElapsed[row.identifier],
        "time": responseTime,
        "takenUser": row.taken ? row.takenUser : "",
        "username": row.holder ? row.holder.modifyUser : "",
        "row": row,
        "module_AO": module_AO,
        "module_RQ": module_RQ,



      }


      datas = [...datas, jsonTmp];


    }

    // this.temp = [...datas];
    console.log('Datas');
    console.log(this.temp);
    let temp = datas;

    datas = this.paginatorService.getDataPaginator(this.paginator, this.env, data);
    this.paginator.pageable.from = this.paginatorService.getPaginatorFrom(this.paginator, this.env);


    this.columns = [

      { prop: 'identifier' },
      { prop: 'icon', name: 'Icon' },
      { prop: 'solicitud', name: 'Solicitud' },
      { prop: 'solicitante', name: 'Solicitante' },
      { prop: 'agencia', name: 'Agencia' },
      { prop: 'ejecutivo', name: 'Ejecutivo' },
      { prop: 'status', name: 'Estatus de la Solicitud' },
      { prop: 'time', name: 'Tiempo de Resp.' }
    ];

    let cont = 0;
    for (const row of datas) {
      if (!isNullOrUndefined(row)) {
        cont++;
        let jsonTmp =
        {
          "identifier": row.identifier,
          "icon": row.icon,
          "plan": row.plan,
          "workflow": row?.workflow,
          "solicitud": row.solicitud,
          "solicitante": row.solicitante,
          "agencia": row.agencia,
          "ejecutivo": row.ejecutivo,
          "status": row.status,
          "status2": row.status2,
          "fecha": row.fecha,
          "fecha2": row.fecha2,
          "time": row.time,
          "takenUser": row.takenUser,
          "username": row.username,
          "row": row,
          "module_AO": row.module_AO,
          "module_RQ": row.module_RQ,


        }


        data = [...data, jsonTmp];
      }
    }


    this.paginator.pageable.to = this.paginatorService.getPaginatorTo(this.paginator, this.env);



    // cache our list
    if (isNullOrUndefined(noUpdate)) {
      console.log('SE ALMACENA TEMPORAL');
      console.log(this.temp);
    } else {
      console.log('LO QUE TENGO:');
      console.log(this.temp);
    }

    // push our inital complete list
    if (isNullOrUndefined(noUpdate)) {
      this.rows = data;
    }



    //this.setFilterMultiple();

    // this.countFilterTimeValue =  this.countFilterTime(this.temp,'status2',(this.getStatusCode('AC_PENDING')).userName,'fecha','danger' );

    this.loading_table = false;
    this.reload_loading = false;

  }



  getPreview(transactionNumber, audience) {

    const params = {
      name: 'account_opening$preview-document$get',
      params: {
        request: {
          role: 'CLIENT',
          audience: audience
        },
        body: {},
        path: {
          transactionNumber: transactionNumber
        }
      }
    };
    this.wait();

    this._httpService.executeRequest(params.name, params.params, true).subscribe((resp: any) => {
      swal.close();
      if (resp.length > 0) {
        this.show_filePreview(resp[0]);
      } else {
        swal.fire({
          title: '',
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: false,
          html: 'Mensaje:<br><b>Ficha no disponible</b><br>',
        });
      }
      this.preview = resp;
    }, error => {
      this.loading = false;
      this.preview = [];
      swal.close();
    });
  }

  show_filePreview(data) {
    this.show_file(data);
  }

  show_file(file) {


    const archive = {
      identifier: file.identifier,
      name: file.name,
      dateCreated: {
        orderDate: file.dateCreated.modifiedDate
      }
    };


    if (archive.identifier !== null) {
      this._upload.visor_params.name = '';
      this._upload.visor_params.date = '';
      this._upload.visor_params.endpoint = 'archive$downloadFile$get';
      this._upload.visor_params.archive = archive;
      this._upload.visor = true;
    }
  }

  setContentTable(configuration): Row[] {

    const content: Row[] = [];
    let i = 0;
    for (const row of this.transactions) {

      /* Valores configurables */
      /*Condición para mostrar el icono que indica si el solicitud está tomado */


      let icon1 = '';
      if (row.taken && this.isAnalist && !this.readonly) {
        if (!isNullOrUndefined(this.transaction_taken) && this.transaction_taken) {
          if (this.transaction_taken !== null && (this.transaction_taken).replace('|EDIT_TAKEN', '') === row.transactionNumber) {
            icon1 = '<i class="fa fa-lock icon-red"></i>';
          } else {
            icon1 = '<i class="fa fa-lock"></i>';
          }
        } else {
          icon1 = '<i class="fa fa-lock"></i>';

        }
      }

      /*Condición para mostrar el icono que indica el estatus del solicitud */
      let icon2 = '';
      if (row.accountStatus) {
        if (row.accountStatus[0].additionalType === 'AC_REJECTED') {
          icon2 = '<i class="fas fa-times-circle text-color-red"></i>';
        } else if (row.accountStatus[0].additionalType === 'AC_INCOMPLETE') {
          icon2 = '<i class="fas fa-times-circle text-color-orange"></i>';
        } else if (row.accountStatus[0].additionalType === 'AC_RECEIVED') {
          icon2 = '<i class="fas fa-check-circle text-color-green"></i>';
        }
      }

      /*Condicion para habilitar/inhabilitar la columna*/
      let enabled = true;
      if (!isNullOrUndefined(this.transaction_taken) && this.transaction_taken) {
        if ((this.transaction_taken !== null && this.transaction_taken.replace('|EDIT_TAKEN', '') !== row.transactionNumber && row.taken && this.isAnalist)) {
          enabled = false;
        }
      }

      /*Condicion para mostrar el tiempo de respuesta */
      let responseTime = '';
      if (row.responseTimeStatus === 'RED') {
        responseTime = '<div class=\'icon-red\'>' + (row.responseTime ? this.formatTime(row.responseTime) : '-') + '</div>';
      } else {
        responseTime = '<div>' + (row.responseTime ? this.formatTime(row.responseTime) : '-') + '</div>';
      }

      let dropdown_analist: Dropdown;
      if (this.isAnalist && !this.readonly) {
        // if (isNullOrUndefined(row.analystUser)) {
        let status = row.accountStatus ? row.accountStatus[0].additionalType : null;

        if (status === 'AC_PENDING' && !row.taken) {
          dropdown_analist = {
            identifier: 'users_analist',
            title: (!isNullOrUndefined(row.analystUser) ? 'Asignada a: ' + row.analystUser.modifyUser : ' Sin Asignar'),
            icon: '<i class="fas fa-user-plus" style="z-index:1!important"></i>',
            list: []
          };
          this.getListAssignUsers(row).then((resp) => {
            const list_aux: any = resp;
            const list: DropdownList[] = [];
            for (const lst of list_aux) {
              list.push({
                obj: {
                  option: 'assign_user',
                  client: lst,
                  procedure: row
                },
                value: lst.person.givenName + ' ' + lst.person.familyName
              });
            }
            dropdown_analist.list = list;
          });

        }
        // }
      }


      content.push(
        {
          identifier: row.identifier,
          cssCustom: '',
          enabled: enabled,
          columns: [
            {
              alignContent: configuration[0].alignContent,
              fixed: configuration[0].fixed,
              maxWidth: configuration[0].maxWidth,
              minWidth: configuration[0].minWidth,
              html: this.paginator.number === 0 ? (i + 1) : ((this.paginator.number * this.paginator.size) + (i + 1)),
              cssCustom: configuration[0].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[0].enableLeftBorder) ? configuration[0].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[0].enableRightBorder) ? configuration[0].enableRightBorder : false
            },
            {
              alignContent: configuration[1].alignContent,
              fixed: configuration[1].fixed,
              maxWidth: configuration[1].maxWidth,
              minWidth: configuration[1].minWidth,
              html: icon1,
              cssCustom: configuration[1].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[1].enableLeftBorder) ? configuration[1].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[1].enableRightBorder) ? configuration[1].enableRightBorder : false
            },
            {
              alignContent: configuration[2].alignContent,
              fixed: configuration[2].fixed,
              maxWidth: configuration[2].maxWidth,
              minWidth: configuration[2].minWidth,
              html: (row.transactionNumber),
              cssCustom: configuration[2].cssCustom + ' ak-text-color-4',
              enableLeftBorder: !isNullOrUndefined(configuration[2].enableLeftBorder) ? configuration[2].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[2].enableRightBorder) ? configuration[2].enableRightBorder : false
            },
            {
              alignContent: configuration[3].alignContent,
              fixed: configuration[3].fixed,
              maxWidth: configuration[3].maxWidth,
              minWidth: configuration[3].minWidth,
              html: row.holder ? row.holder.name : '-',
              cssCustom: configuration[3].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[3].enableLeftBorder) ? configuration[3].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[3].enableRightBorder) ? configuration[3].enableRightBorder : false
            },
            {
              alignContent: configuration[4].alignContent,
              fixed: configuration[4].fixed,
              maxWidth: configuration[4].maxWidth,
              minWidth: configuration[4].minWidth,
              html: row.channelAgency ? row.channelAgency.alternateName : '-',
              cssCustom: configuration[4].cssCustom + ' color-gray',
              enableLeftBorder: !isNullOrUndefined(configuration[4].enableLeftBorder) ? configuration[4].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[4].enableRightBorder) ? configuration[4].enableRightBorder : false
            },
            {
              alignContent: configuration[5].alignContent,
              fixed: configuration[5].fixed,
              maxWidth: configuration[5].maxWidth,
              minWidth: configuration[5].minWidth,
              html: ((row.salesManagerUser) ? (row.salesManagerUser.name) : ('-')),
              cssCustom: configuration[5].cssCustom + ' color-gray',
              enableLeftBorder: !isNullOrUndefined(configuration[5].enableLeftBorder) ? configuration[5].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[5].enableRightBorder) ? configuration[5].enableRightBorder : false
            },
            {
              alignContent: configuration[6].alignContent,
              fixed: configuration[6].fixed,
              maxWidth: configuration[6].maxWidth,
              minWidth: configuration[6].minWidth,
              html: ((row.accountStatus) ? (row.accountStatus[0].userName) : '-'),
              cssCustom: configuration[6].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[6].enableLeftBorder) ? configuration[6].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[6].enableRightBorder) ? configuration[6].enableRightBorder : false
            },
            {
              alignContent: configuration[7].alignContent,
              fixed: configuration[7].fixed,
              maxWidth: configuration[7].maxWidth,
              minWidth: configuration[7].minWidth,
              html: responseTime,
              cssCustom: configuration[7].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[7].enableLeftBorder) ? configuration[7].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[7].enableRightBorder) ? configuration[7].enableRightBorder : false
            },
            {
              alignContent: configuration[8].alignContent,
              fixed: configuration[8].fixed,
              maxWidth: configuration[8].maxWidth,
              minWidth: configuration[8].minWidth,
              dropdown: dropdown_analist,
              cssCustom: configuration[8].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[8].enableLeftBorder) ? configuration[8].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[8].enableRightBorder) ? configuration[8].enableRightBorder : false
            },
            {
              alignContent: configuration[9].alignContent,
              fixed: configuration[9].fixed,
              maxWidth: configuration[9].maxWidth,
              minWidth: configuration[9].minWidth,
              html: '<i class="fas fa-angle-right text-primary" title="Ir..."></i>',
              cssCustom: configuration[9].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[9].enableLeftBorder) ? configuration[9].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[9].enableRightBorder) ? configuration[9].enableRightBorder : false
            }
          ]
        }
      );
      i++;
    }
    return content;
  }
  // responseTime,



  table_response(response) {
    if (response.action === 'open') {
      let transaction = null;
      transaction = this.transactions.find((wspc: any) => {
        return wspc.identifier === response.object.identifier;
      });
      if (this.isAnalist) {
        this.show(transaction.transactionNumber);
      } else {
        this.openFormalities(transaction.transactionNumber);
      }
    }
    if (response.action === 'order') {
      this.filter_order = response.object;
      this.applyFilter(response.object);
    }

    if (response.action === 'action') {
      if (response.object.action === 'assign_user') {
        this.assign(response.object.obj.client, response.object.obj.procedure);
      }
    }

    if (response.action === 'paginator') {
      this.action_paginator(response.object);
    }
  }


  getWorkflowDetail() {

    this.workspace = null;
    const request = {
      light: true
    };

    const params: any = {
      name: 'workflow$workspace$get',
      params: {
        request: request,
        path: {
          workspaceCode: this.workspaceName,
          prefixWorkflow: 'workspace_manager'
        },
        body: {}
      }
    };

    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.workspace = response;
      let workspace = this.workspace;
      this.workspaceDetail =
      {
        "identifier": workspace.identifier,
        "wpname": workspace.name,
        "clave": workspace.alternateName,
        'product': !isNullOrUndefined(workspace.product) ? workspace.product.name : '',
        "plan": !isNullOrUndefined(workspace.plan) ? (workspace.plan.name) : '',
        "vista": workspace.viewType === 'EXECUTIVE' ? 'Ejecutivo' : 'Analista',
        "agencia": !isNullOrUndefined(workspace.agency) ? workspace.agency.name : '',
        "responsable": !isNullOrUndefined(workspace.leadership) ? (workspace.leadership.name) : '',
        "statusCola": workspace.status === 'ACTIVE' ? this._i18n.transform('@i18n-text-active') : (this._i18n.transform('@i18n-text-inactive')),
        'statusSolicitud': workspace.transactionState ? workspace.transactionState.name : '',
        'tipo': workspace.personType ? workspace.personType.audienceType == 'NATURAL' ? 'Persona Natural' : 'Persona Jurídica' : '',
        'defaultQueue': workspace.defaultQueue
      }

      this.getTransactionsPaginator(this.statusTransaction.current, environment.paginator.page, this.totalGet);

    }, error => {
      this.workspace = null;
    }));
  }


  setBreacrumb(breadcrumbKey) {
    this._breadcrumbService.setbreadcrumbKey(breadcrumbKey);
  }

  processBreacrum() {
    const breadcrumb = null;
    this.akStepService.breadcrumbStep$.subscribe(breadcrumbKey => {


      if (breadcrumbKey) {
        this.setBreacrumb(breadcrumbKey);
      } else {
        this._breadcrumbService.setbreadcrumbOptions(null);
      }
    });

  }

  applyFilter(event: any) {
    // paginación y filtro 
    this.loading_table = true;

    this.filter.identifier = event.identifier;
    this.filter.movement = event.movement;
    if (!isNullOrUndefined(this.params_search)) {
      this.getTransactionsPaginatorSearch(this.paginator.pageable.pageNumber, this.filter.identifier, this.filter.movement);
    } else {
      this.getTransactionsPaginator(this.statusTransaction.current, environment.paginator.page, this.totalGet, null, event.identifier, event.movement);
    }
  }
  //juan 
  getListAssignUsers(row) {
    return new Promise((resolve, reject) => {
      this.list_users = 'loading';
      const params: any = {
        name: 'person$endusers_profiles$get',
        params: {
          request: {
            idAgency: row.channelAgency.identifier
          },
          path: {
            profile: 'ANALIST'
          },
          body: {}
        }
      };
      this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        this.list_users = response;
        resolve(this.list_users);
      }, error => {
        this.list_users = [];
        resolve(this.list_users);
      }));
    });


  }

  getStates() {
    const params: any = {
      name: 'workflow$allstates$get',
      params: {
        request: {},
        path: {
          workflow: this.workflow
        },
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response) => {
      this.states = response;
    }, error => {
      this.states = {};
    });
  }

  assign(client, procedure) {
    // account_opening/account/2982020-262/analyst/analista?userActivate=analista
    const params: any = {
      name: 'account_opening$assing_procedure_analist$post',
      params: {
        request: {
          userActivate: this._auth.getUsername()
        },
        path: {
          user: client.identifier,
          transactionNumber: procedure.transactionNumber
        },
        body: {}
      }
    };
    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this._akeelaSidebarMenu.updateQueues$.next(true);
      this.getTransactionsPaginator(this.statusTransaction.current, environment.paginator.page, this.totalGet);
    }, error => {
      this.error.show(error);
    }));
  }

  getDetailQUEUE(codeQueue) {

    if (this.queueName == 'Q_ALL_OP_M') {

      this.localStorage.setItem('queueDescription', this.localStorage.getItem('queueDescriptionDefault'));
      this.processBreacrum();

    } else {

      this.queue = null;

      const params: any = {
        name: 'workflow$queue$get',

        params: {
          request: {
            codeQueue: codeQueue
          },
          path: {
            workflow: this.workflow,
            stage: this.stage,
            step: this.step,
            codeQUEUE: this.queueName,
            workspace: this.workspaceName,
            user: this._auth.getUsername()
          },
          body: {}
        }
      };

      this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {


        this.queue = response;

        this.localStorage.setItem('queueDescription', response.name);
        this.processBreacrum();

      }, error => {

        this.queue = null;

      }));
    }

  }

  getPolicys() {
    const params: any = {
      name: 'person$policies$get',
      params: {
        request: {},
        path: {},
        body: {}
      }
    };
    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.policys = response;
    }, error => {
      this.policys = {};
    }));
  }


  getQueueFilterValue(filter) {

    if (filter.additionalType != 'NOT_FOUND') {

      if (!isNullOrUndefined(filter.valueReference)) {

        if (filter.valueReference == 'username') {

          return this._auth.getUsername();

        }

      }

      else if (!isNullOrUndefined(filter.description)) {
        if (filter.description == "" && filter.additionalType == "EQUALITY") {

          return '@i18n-no';

        }

        if (filter.description == "" && filter.additionalType == "NEGATION") {

          return '@i18n-si';

        }

        return filter.description || '@i18n-all';

      }
    }

    return '';


  }



  getQueueFilterAdditionaType(type) {

    if (type == 'EQUALITY') {

      return '='
    }


    if (type == 'NEGATION') {

      return '<>'
    }

    return '';



  }

  action_paginator(page) {
    this.loading_table = true;
    if (page === 'NEXT') {
      page = this.paginator.pageable.pageNumber + 1;
      if (page >= this.paginator.totalPages) {
        page = this.paginator.pageable.pageNumber;
      }
    } else if (page === 'BACK') {
      page = this.paginator.pageable.pageNumber - 1;
      if (page < 0) {
        page = this.paginator.pageable.pageNumber;
      }
    }
    this.paginator.pageable.pageNumber = page;
    this.paginator.first = (page === 0 ? true : false);
    this.paginator.last = ((page + 1) === this.paginator.totalPages ? true : false);
    this.setTablePaginators();

    this.getIndicatorsProgres(this.rows, false);
    this.getAvatars(this.rows);
    this.getUsersTransactions(this.rows);
    this.setNotesForUserNames(this.rows);
    this.loading_table = false;


  }

  getTransactionsPaginatorSearch(page, sort_by?, sort_type?) {
    this.transactions = null;
    this.loading_table = true;
    this._search.postSearch(this.object_search, this.stage, this.step, this.totalGet, sort_by, sort_type).subscribe((response: any) => {
      this.transactions = response.content;
      // this.paginator = response;


      this.loading_table = false;
      this.reload_loading = false;

    }, error => {
      this.transactions = null;
      // this.paginator = null;
      this.loading_table = false;
      this.reload_loading = false;

    });
  }

  getTransactionsPaginator(transactionStatus, page, size, isPolicy?, sort_by?, sort_type?) {

    this.indicatorsInit = true;

    this.loading_table = true;
    this.rows = [];


    let request = {};
    if (this.policy !== null) {
      if (!isNullOrUndefined(sort_by) && !isNullOrUndefined(sort_type)) {
        request = {
          page: page,
          size: size,
          hours_policy: this.policy,
          sort_by: sort_by,
          sort_type: sort_type
        };
      } else {
        request = {
          page: page,
          size: size,
          hours_policy: this.policy
        };
      }
    } else {
      if (!isNullOrUndefined(sort_by) && !isNullOrUndefined(sort_type)) {
        if (!isNullOrUndefined(transactionStatus) && transactionStatus !== '') {
          request = {
            page: page,
            size: size,
            sort_by: sort_by,
            sort_type: sort_type,
            //transacctionStatus: transactionStatus
          };
        } else {
          request = {
            page: page,
            size: size,
            sort_by: sort_by,
            sort_type: sort_type
          };
        }
      } else {
        if (!isNullOrUndefined(transactionStatus) && transactionStatus !== '') {
          request = {
            //  page: page,
            //  size: size,
            //  transacctionStatus: transactionStatus
          };
        } else {
          request = {
            //   page: page,
            //   size: size
          };
        }
      }

    }
    if (!isPolicy) {
      this.policy = null;
    }

    let params: any;


    if (this.localStorage.getItem('codeQueue')) {

      this.queueName = this.localStorage.getItem('codeQueue');
      this.localStorage.removeItem('codeQueue');

      this.getDetailQUEUE(this.queueName);


    }

    if (isNullOrUndefined(this.queueName)) {
      this.queueName = this.workspaceDetail?.defaultQueue;

      this.getDetailQUEUE(this.queueName);

    }

    this._akeelaSidebarMenu.setSidebarSubLeftActive(this.queueName);



    if (isNullOrUndefined(this.queueName) || this.queueName == 'Q_ALL_OP_M') {
      params = {
        name: 'account_opening$myformalities_workspace_optimized$get',
        //name: 'account_opening$myformalities_workspace_optimized_progres$get',
        //  name: 'account_opening$myformalities_workspace$get',
        params: {
          request: request,
          path: {
            workflow: this.workflow,
            stage: this.stage,
            step: this.step,
            codeQUEUE: this.queueName,
            workspace: this.localStorage.getItem('workspace'),
            user: this._auth.getUsername()
          },
          body: {}
        }
      };
    } else {

      params = {
        name: 'account_opening$myformalities_workspace_optimized$get',
        // name: 'account_opening$myformalities_workspace_optimized_progres$get',

        //
        // name: 'account_opening$transactions_queue$get',
        params: {
          request: {
            codeQueue: this.queueName,
            // transacctionStatus: transactionStatus
          },
          path: {
            workflow: this.workflow,
            stage: this.stage,
            step: this.step,
            codeQUEUE: this.queueName,
            workspace: this.workspaceName,
            user: this._auth.getUsername()
          },
          body: {}
        }
      };
    }

    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.isTaken(this._auth.getUsername()).subscribe((taken) => {
        this.transaction_taken = taken;
        this.update_transactions(response);
      }, error => {
        this.update_transactions(response);
      });
      this.loading_table = false;
      this.reload_loading = false;

    }, error => {
      this.error.show(error);
      this.transactions = [];
      this.paginator = {
        totalPages: 0,
        first: true,
        last: false,
        number: 0,
        pageable: {
          pageNumber: 0,
          from: null,
          to: null
        }
      };
      // this.setConfigurationTable();
      this.setConfigurationTableNew();

      this.loading_table = false;
      this.reload_loading = false;

    }));
  }

  getTransactions(transactionStatus) {
    this.transactions = null;
    const params: any = {
      name: 'account$transactions$get',
      params: {
        request: {
        },
        path: {
          stage: this.stage,
          step: this.step,
        },
        body: {}
      }
    };
    if (transactionStatus === '') {
      params.params.request = {};
    }
    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.isTaken(this._auth.getUsername()).subscribe((taken) => {


        this.transaction_taken = taken;
        this.update_transactions(response);
      }, error => {
        this.update_transactions(response);
      });

    }, error => {
      this.transactions = {};
    }));
  }

  update_transactions(transactions, loading?) {

    // transactions =  transactions.slice(0,20);
    this.paginator = {
      totalPages: Math.ceil(transactions?.length / this.env.paginator.size),
      first: true,
      last: false,
      number: transactions?.length,
      pageable: {
        pageNumber: 0,
        from: null,
        to: null
      }
    };




    if (loading) {

      // this.paginator = transactions.response;
      // this.params_search = transactions.params;
      // this.transactions = transactions.response.content;
      this.transactions = transactions;
      this.transactionsBase = transactions;

      this.object_search = transactions.object;
      //this.setConfigurationTable();
      this.setConfigurationTableNew();


      this.loading_table = false;
      this.reload_loading = false;

    } else {
      if (transactions) {


        // this.transactions = transactions.response.content;
        this.transactions = transactions;
        this.transactionsBase = transactions;

        // this.paginator = transactions;
        // this.setConfigurationTable();
        this.setConfigurationTableNew();

        this.loading_table = false;
        this.reload_loading = false;


      } else {
        // this.paginator = null;
        this.transactions = [];
        this.transactionsBase = [];
        // this.setConfigurationTable();
        this.setConfigurationTableNew();

        this.loading_table = false;
        this.reload_loading = false;

      }
    }
  }


  update_transactionsFilter(transactions, loading?) {
    this.paginator = {
      totalPages: Math.ceil(transactions?.length / this.env.paginator.size),
      first: true,
      last: false,
      number: transactions?.length,
      pageable: {
        pageNumber: 0,
        from: 1,
        to: transactions?.length < this.env.paginator.size ? transactions?.length : this.env.paginator.size
      }
    };

    if (loading) {
      // this.paginator = transactions.response;
      // this.params_search = transactions.params;
      // this.transactions = transactions.response.content;
      this.transactions = transactions;

      this.object_search = transactions.object;
      //this.setConfigurationTable();
      // this.setConfigurationTableByFilter(transactions, true);


      this.loading_table = false;
      this.reload_loading = false;

    } else {
      if (transactions) {
        // this.transactions = transactions.response.content;
        this.transactions = transactions;

        // this.paginator = transactions;
        // this.setConfigurationTable();
        // this.setConfigurationTableByFilter(transactions, true);

        this.loading_table = false;
        this.reload_loading = false;


      } else {
        // this.paginator = null;
        this.transactions = [];
        // this.setConfigurationTable();
        // this.setConfigurationTableByFilter(transactions, true);
        this.paginator.pageable.to = 0;
        this.paginator.pageable.from = 0;

        this.loading_table = false;
        this.reload_loading = false;

      }
    }

  }

  applyFilterStatus(status) {
    // console.log(status.target.value);
    this.loading_table = true;
    this.policy = null;
    // this.paginator = null;
    this.params_search = null;
    this.object_search = null;
    if (this.statusTransaction.current !== status || this.statusTransaction.current === null) {
      if (isNullOrUndefined(status) || status === '') {
        this.statusTransaction.current = '';
        status = null;
      } else {
        this.statusTransaction.current = status;
      }
      this.getTransactionsPaginator(status, 0, this.totalGet);
    }
  }

  onPolicy(event) {
    this.policy = event;
    this.filter_policys.active = event;
    this.getTransactionsPaginator(this.statusTransaction.current, environment.paginator.page, this.totalGet, true);
  }





  callFn(fn) {
    if (fn) {
      if (typeof this[fn['name']] === 'function') {
        let data = '';
        fn['data'].forEach(function (value, key) {
          if (key < (fn['data'].length - 1)) {
            data += value + ',';
          } else {
            data += value;
          }
        });
        this[fn['name']](data, fn['identifier']);
      } else {
        console.log('function no definida:', fn.f);
      }
    }
  }

  new() {
    this.akeelaSectionService.showModalSection('PROD_PLAN_SELECTOR_MODAL');
  }

  show = async (transactionNumber) => {
    this.wait();
    const queryParams = {};


    try {

      let transactionData: any = await this.getTransactionData(transactionNumber);

      if (!isNullOrUndefined(transactionData)) {

        let audience = "NATURAL";

        try {
          let response: any = await this.getPerson(transactionData.holder.identifier);
        } catch (error) {
          audience = "LEGAL";
        }


        this.localStorage.setItem('clientaudience', audience);
        this.localStorage.setItem('audience', audience);
        if ((transactionData.accountStatus[0] ? transactionData.accountStatus[0].additionalType : '') === 'AC_PENDING' && !this.readonly) {

          try {
            let isTakenResponse: any = await this.isTaken(this._auth.getUsername()).toPromise();

            if (isTakenResponse.indexOf('EDIT_TAKEN') > -1) {
              if (isTakenResponse.replace('|EDIT_TAKEN', '') !== transactionNumber) {

                const error = {
                  code: 'AKC-004',
                  message: null,
                  messageLabel: null,
                  name: null,
                  timestamp: null,
                  titleLabel: null,
                  error: {
                    titleLabel: null
                  }
                };
                this.error.show(error, 'Mensaje!', this.i18n.transform('@i18n-error-message-account-editaken-part1 ') + ' ' + isTakenResponse.replace('|EDIT_TAKEN', '') + ' ' + this.i18n.transform(' @i18n-error-message-account-editaken-part2'));

              } else {

                this.takenListUsers[transactionNumber] = 'your';

                this.localStorage.removeItem('transactionModeEditShow');
                this.akWorkflowService.setTransactionNumber(transactionNumber);
                // this.transitionService.next('private', params_trans, queryParams);
              }

              swal.close();

            } else if (isTakenResponse === transactionNumber) {
              this.takenListUsers[transactionNumber] = 'your';

              this.localStorage.removeItem('transactionModeEditShow');
              swal.close();

              //  this.akWorkflowService.setTransactionNumber(transactionNumber);

            } else {
              if (isTakenResponse !== null && isTakenResponse !== '' && isTakenResponse !== undefined) {
                const title = '';
                let text;
                text = 'Usted posee asignada la solicitud #<b>' + isTakenResponse + '</b><br>¿Desea liberarla y continuar con la seleccionada?';
                this.confirm(title, text).then(async (result_confirm) => {
                  if (result_confirm.value) {


                    try {
                      this.wait();

                      await this.setUntaken(this._auth.getUsername(), isTakenResponse).toPromise();
                      this.takenListUsers[isTakenResponse] = '';

                      this._wfService.setTransactionTakenYou(null);

                      await this.setTaken(this._auth.getUsername(), transactionNumber).toPromise();
                      this.takenListUsers[transactionNumber] = 'your';

                      this._wfService.setTransactionTakenYou({ taken: true, transaction: transactionNumber });
                      this.localStorage.removeItem('transactionModeEdit');
                      this.localStorage.removeItem('transactionModeEditShow');


                      //      this.akWorkflowService.setTransactionNumber(transactionNumber);
                      swal.close();

                    } catch (error) {
                      swal.close();
                      this.error.show(error);
                    }
                  }
                });
              } else {
                this.takenListUsers[transactionNumber] = 'your';

                this._wfService.setTransactionTakenYou({ taken: true, transaction: transactionNumber });
                this.subscriptions.push(this.setTaken(this._auth.getUsername(), transactionNumber).subscribe((response: any) => {
                  const param = Object.assign({ transactionNumber: transactionNumber }, queryParams);
                  this.localStorage.removeItem('transactionModeEdit');
                  this.localStorage.removeItem('transactionModeEditShow');
                }, error => {
                  this.error.show(error);
                }));
              }
            }

          } catch (error) {

          //  this.error.show(error);
          }

        } else {
          this.localStorage.removeItem('transactionModeEdit');
          this.localStorage.removeItem('transactionModeEditShow');
          const param = Object.assign({ transactionNumber: transactionNumber }, queryParams);

        }


      }


    } catch (error) {
      this.error.show(error);

    }




  }


  setUntakenUser = async (isTakenResponse) => {

    try {

      await this.setUntaken(this._auth.getUsername(), isTakenResponse).toPromise();
      this.takenListUsers[isTakenResponse] = '';

      this._wfService.setTransactionTakenYou(null);

    } catch (error) {

    }


  }



  openFormalities = async (transactionNumber) => {

    this.deleteEditTransaction();
    this.wait();
    this.localStorage.removeItem('transactionModeEditShowFlag');

    // this.transitionService.showWorkflow$.next(false);
    this.akWorkflowService.setTransactionNumber(null);
    this.akWorkflowService.setTransactionNumber(transactionNumber);


    try {

      let transactionData: any = await this.getTransactionData(transactionNumber);

      this.akWorkflowService.transactionData$.next(transactionData);
      swal.close();

      try {

        let response: any = await this.getPerson(transactionData.holder.identifier);

        let taken = false;
        const temp = transactionData?.accountStatus[0].additionalType;
        if (temp !== 'AC_PENDING' && temp !== 'AC_RECEIVED' && temp !== 'AC_REJECTED') {
          if (transactionData?.takenByRol.trim() !== this.role.trim() && transactionData?.takenByRol !== '') {
            taken = true;
          }
        }

        this.redirect(transactionNumber, 'NATURAL', false);
        swal.close();


      } catch (error) {


        this.redirect(transactionNumber, 'LEGAL', false);
        swal.close();

      }
    } catch (error) {
      swal.close();

    }


  }

  redirect(transactionNumber, audience, close?) {
    this.localStorage.setItem('clientaudience', audience);
    this.localStorage.setItem('audience', audience);
    this.localStorage.setItem('sectionRedirect', true);

    const queryParams = {
      role: this.localStorage.getItem('role'),
      profile: this.localStorage.getItem('profile'),
      audience: this.localStorage.getItem('audience'),
      transactionNumber: transactionNumber,
    };


    this.router.navigate(['console/transaction-dashboard'], { queryParams: queryParams });
    swal.close();


  }

  getPerson(identifier) {
    const params = {
      name: 'person$person$get',
      params: {
        request: { id: identifier },
        path: {},
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }

  deleteEditTransaction() {
    if (!isNullOrUndefined(this.localStorage.getItem('transactionModeEdit'))) {
      this.breakfreeTransactionEdit(); // Mario: este caso era de liberación individual, pero debido a que ahora se va a liberar por usuario, se comenta para ver el comportamiento
      this.breakfreeTransactionsByUser();
    } else {
      this.breakfreeTransactionsByUser();
    }
  }

  breakfreeTransactionsByUser() {
    const configuration = {
      name: 'account$finish_editing_role_by_user$put',
      params: {
        path: {},
        request: {
          role: 'USER',
          systemUser: this._auth.getUsername()
        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.akWorkflowService.transactionData$.next(null);

    }, error => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.akWorkflowService.transactionData$.next(null);

    });
  }


  breakfreeTransactionEdit() {
    const configuration = {
      name: 'account_opening$finish_editing_role$put',
      params: {
        path: {
          transactionNumber: this.localStorage.getItem('transactionModeEdit')
        },
        request: {
          role: 'USER',
          systemUser: this._auth.getUsername()
        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.akWorkflowService.transactionData$.next(null);

    }, error => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.akWorkflowService.transactionData$.next(null);

    });
  }



  confirm(title, text) {
    return swal.fire({
      title: title,
      html: text,
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    });
  }

  isTaken(username) {
    if (this.isAnalist) {
      const params = {
        name: 'account_opening$taken$get',
        params: {
          responseType: 'text',
          request: {},
          path: {
            user: username
          },
          body: {}
        }
      };
      const subscriptor = this._httpService.executeRequest(params.name, params.params, true);
      return subscriptor;
    } else {
      return new Observable(resolve => {
        resolve.next(false);
      });
    }
  }

  setTaken(username, transactionNumber) {
    const params = {
      name: 'account_opening$taken$put',
      params: {
        request: {},
        path: {
          transactionNumber: transactionNumber,
          user: username
        },
        body: {}
      }
    };
    const subscriptor = this._httpService.executeRequest(params.name, params.params);
    return subscriptor;
  }

  setUntaken(username, transactionNumber) {
    const params = {
      name: 'account_opening$untaken$put',
      params: {
        request: {},
        path: {
          transactionNumber: transactionNumber,
          user: username
        },
        body: {}
      }
    };
    const subscriptor = this._httpService.executeRequest(params.name, params.params);
    return subscriptor;
  }





  getValueParam(elements, variable) {
    for (const element of elements) {
      if (element.startsWith(variable + '=')) {
        return element.replace(variable + '=', '');
      }
    }
    return '';
  }

  resume() {
    this.router.navigateByUrl(this.router.createUrlTree
      ([environment.staticLinks.viewAccountOpeningAnalisys],
        {
          queryParams: {
            role: 'USER',
            profile: 'ANALIST',
            transactionNumber: '2102019-12',
            audience: 'LEGAL'
          }
        }
      )
    );
  }


  wait() {
    swal.fire({
      title: '',
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false,
      html: '<b>' + this.i18n.transform('@i18n-text-please-wait') + '...<b><br><i class="fa fa-spin fa-spinner"></i>',
    });
  }


  // close() {
  //   swal.fire({
  //     title: '',
  //     showCancelButton: false,
  //     showCloseButton: false,
  //     showConfirmButton: false,
  //     html: '',
  //     timer: 3
  //   });
  // }

  formatTime(time) {
    const split = time.split(' ');
    let response = null;
    // for (const element of split) {
    //   if (element.indexOf('h') !== -1) {
    //     response = element;
    //   }
    // }
    if (isNullOrUndefined(response)) {
      if (split.length > 0) {
        response = split[0];
      } else {
        response = time;
      }
    }
    return response;
  }

  ngOnDestroy() {
    if (this.query_params_subscriptor) {
      this.query_params_subscriptor.unsubscribe();
    }
    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }
    if (this.subscriptions) {
      // prevent memory leak when component destroyed
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  validateTransactioWithFilterStatus(queue, workspace) {
    if (queue) {
      for (const filter of queue.filters) {
        if (filter.alternateName === 'F_TRANSACTION_STATE') {
          return true;
        }
      }
    } else if (workspace) {
      //console.log(workspace);
      if (workspace.transactionState) {
        return true;
      }
    }
    return false;
  }



  //************************************************************************************** */


  onActivate(event) {
    if (event.type == 'click' && event.row.identifier) {
      if (event && event.value !== '' && event.row.onActivate != 'other') {
        if (this.isAnalist) {
          this.show(event.row.solicitud);
        } else {
          this.openFormalities(event.row.solicitud);
        }

      }

      //console.log("event",event);

    }
  }


  //HERE
  updateFilter(data) {
    this.boxFilters = false;
    let data2 = [];

    this.loadingIndicator = true;
    const val = !isNullOrUndefined(data) ? String(data).toLowerCase() : null;

    if (!isNullOrUndefined(val)) {
      // filter our data
      let arrayBase = this.temp;

      const temp = arrayBase.filter(function (d) {

        let filter = false;
        let tempAnchor = false;

        for (let clave in d) {
          let value = d[clave];

          if (!filter) {

            filter = String(value).toLowerCase().indexOf(val) !== -1 || !val;

          }
        }

        return filter;

      });

      // update the rows
      data2 = temp;
    } else {
      data2 = this.temp;
    }

    this.update_transactionsFilter(data2);
    this.rows = this.paginatorService.getDataPaginator(this.paginator, this.env, data2);


    // Whenever the filter changes, always go back to the first page
    //this.table.offset = 0;

    this.loadingIndicator = false;

  }


  setDelete(i) {

    delete this.anchorSearch[i];
    this.anchorSearch = [...this.anchorSearch.filter(el => el != null)];
    this.fieldSearch = null;
    this.updateFilter(null);

  }

  anchor() {

    if (this.fieldSearch && this.fieldSearch != '') {
      let tmp = this.fieldSearch;
      this.anchorSearch = [...this.anchorSearch, tmp];
    }

    //this.fieldSearch = '';
    this.updateFilter(null);
  }


  getInitials(givenName: string, familyName: string) {
    return (
      (givenName ? givenName.substr(0, 1).toUpperCase() : '') +
      (familyName ? familyName.substr(0, 1).toUpperCase() : '')
    );
  }
  getInitial(text) {
    let retorno = '';
    if (!isNullOrUndefined(text)) {
      retorno = text.substr(0, 1).toUpperCase();
    }
    return retorno;
  }




  getTimeDate(date) {

    let meses = ["Ene", "Febr", "Mar", "Abr", "May", "Jun", "Jul", "Agos", "Sept", "Oct", "Nov", "Dic"];


    let dateMaestro = date.split(" ");
    let dato = dateMaestro[0].split("/");
    let dato2 = dateMaestro[1].split(":");

    let dia = dato[0];
    let mes = dato[1];
    let año = dato[2];
    let hora = dato2[0];
    let minutos = dato2[1];


    let hoy = this.datePipe.transform(Date(), 'dd/MM/yyyy HH:mm');

    dateMaestro = hoy.split(" ");
    dato = dateMaestro[0].split("/");
    dato2 = dateMaestro[1].split(":");

    let dia2 = dato[0];
    let mes2 = dato[1];
    let año2 = dato[2];
    let hora2 = dato2[0];
    let minutos2 = dato2[1];

    let time;

    if (parseInt(año2) > parseInt(año)) {

      time = dia + '/' + mes + '/' + año;

    } else if (parseInt(mes2) > parseInt(mes) || parseInt(dia) != parseInt(dia2)) {

      time = `${dia}  ${meses[mes - 1]}  <span class="color-aaa"> ${hora}:${minutos} <span>`;

    } else {

      time = hora + ':' + minutos;

    }

    return { value: time, time: date }
  }


  getTimeElapsed(date, timeWarningHH = 6, timeDangerHH = 8) {



    let dateMaestro = date.split(" ");
    let x = dateMaestro[1].split(":");
    let m = dateMaestro[0].split("/");
    let nacimiento: any = new Date(Number(m[2]), Number(m[1]) - 1, Number(m[0]), Number(x[0]), Number(x[1]));
    let hoy: any = new Date();

    let tiempoPasado = hoy - nacimiento;
    let segs = 1000;
    let mins = segs * 60;
    let hours = mins * 60;
    let days = hours * 24;
    let months = days * 30.416666666666668;
    let years = months * 12;

    //calculo 
    let anos = Math.floor(tiempoPasado / years);
    tiempoPasado = tiempoPasado - (anos * years);
    let meses = Math.floor(tiempoPasado / months)

    tiempoPasado = tiempoPasado - (meses * months);
    let dias = Math.floor(tiempoPasado / days)

    tiempoPasado = tiempoPasado - (dias * days);
    let horas = Math.floor(tiempoPasado / hours)

    tiempoPasado = tiempoPasado - (horas * hours);
    let minutos = Math.floor(tiempoPasado / mins)

    tiempoPasado = tiempoPasado - (minutos * mins);
    let segundos = Math.floor(tiempoPasado / segs)

    // console.log(`${anos} años, ${meses} meses,  ${dias} dias, ${horas} horas, y ${minutos}`)

    let anos2 = anos > 0 ? `${anos} año` + this.maxOne(anos, 's') : '';
    let meses2 = meses > 0 ? `${meses} mes` + this.maxOne(meses, 'es') : '';
    let dias2 = dias > 0 ? `${dias} dia` + this.maxOne(dias, 's') : '';
    let horas2 = horas > 0 ? `${horas} hora` + this.maxOne(horas, 's') : '';
    let minutos2 = minutos > 0 ? `${minutos} minuto` + this.maxOne(minutos, 's') : '';
    let segundos2 = segundos > 0 ? `${segundos} segundo` + this.maxOne(segundos, 's') : '';

    let anos3 = anos > 0 ? `${anos}a` : '';
    let meses3 = meses > 0 ? `${meses}m` : '';
    let dias3 = dias > 0 && anos == 0 ? `${dias}d` : '';
    let horas3 = horas > 0 && anos == 0 && meses == 0 ? `${horas}h` : '';
    let minutos3 = minutos > 0 && anos == 0 && meses == 0 && dias == 0 ? `${minutos}min` : '';
    let segundos3 = segundos > 0 && anos == 0 && meses == 0 && dias == 0 && minutos == 0 ? `< 1min` : '';

    let minutes = this.getMinutesBetweenDates(nacimiento, hoy);
    // console.log("minutos 2",  this.getMinutesBetweenDates(nacimiento,hoy));


    let timeDangerHHValue = false;

    if (minutes >= timeDangerHH) {
      timeDangerHHValue = true;
    }

    let timeWarningHHValue = false;
    if (minutes >= timeWarningHH && minutes < timeDangerHH) {

      timeWarningHHValue = true;

    }

    let value = '';
    if (anos3 !== '' || meses3 !== '' || dias3 !== '' || horas3 !== '' || minutos3 !== '' || segundos3 !== '') {
      value = (`${anos3}  ${meses3} ${dias3}  ${horas3}  ${minutos3} ${segundos3}`);
    }
    let detail = (`${anos2} ${meses2} ${dias2} ${horas2} ${minutos2}`);

    if (value.trim() === '' || value.trim() === ' ') {
      value = 'Hace segundos';
    }

    return { value, detail, timeWarningHHValue, timeDangerHHValue };

  }

  maxOne(num, value) {

    return num > 1 ? value + ' ' : '';
  }


  akgetTimeElapsedSetInterval(id, date, status = false) {

    this.timeElapsed[id] = this.getTimeElapsed(date, this.warning, this.danger);

    if (this.timeElapsedSetInterval[id]) {
      clearInterval(this.timeElapsedSetInterval[id]);
    }

    this.timeElapsedSetInterval[id] = setInterval(() => {

      this.timeElapsed[id] = this.getTimeElapsed(date, this.warning, this.danger);

    }, 60000);

  }





  getMinutesBetweenDates(startDate, endDate) {

    let diff = Math.abs(endDate - startDate);
    return Math.floor((diff / 1000) / 60);
  }

  dateComparator(dateInit, dateEnd, rowA, rowB) {

    let dateMaestro = dateInit.split(" ");
    let x = dateMaestro[1].split(":");
    let m = dateMaestro[0].split("/");
    let letDateInit: any = new Date(Number(m[2]), Number(m[1]) - 1, Number(m[0]), Number(x[0]), Number(x[1]));

    let dateMaestro2 = dateEnd.split(" ");
    x = dateMaestro2[1].split(":");
    m = dateMaestro2[0].split("/");
    let letDateEnd: any = new Date(Number(m[2]), Number(m[1]) - 1, Number(m[0]), Number(x[0]), Number(x[1]));

    if (letDateInit instanceof Date && letDateEnd instanceof Date) {
      if (letDateInit.getTime() < letDateEnd.getTime()) return -1;
      if (letDateInit.getTime() > letDateEnd.getTime()) return 1;
    }

  }

  round(value) {

    if (value) {
      return Math.round(value);

    }
    return 0;

  }

  togle_window(filter) {

    if (filter == 1) {
      this.filterBy = true;
      this.orderBy = false;

    } else {

      this.filterBy = false;
      this.orderBy = true;

    }

    this.animate_init = false;
    this.openFilter = !this.openFilter;

  }


  togle_botton_window(row = false) {

    this.formsDataResponse.number = row;

    this.animate_init_bottom = false;
    this.buttomModal = !this.buttomModal;

  }

  showDetail(username, identifier) {



    let aux;
    aux = Object.assign(
      {
        audience: 'LEGAL',
        viewUsername: username,
        viewIdentifierContact: identifier
      }
      , this.queryParams);



    this.router.navigateByUrl(
      this.router.createUrlTree([environment.staticLinks.viewClient],
        {
          queryParams: aux
        }
      )
    );

  }


  getAgencys() {
    const params: any = {
      name: 'app_properties$agencies$get',
      params: {
        request: {},
        path: {},
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {


      this.agencys = [];

      for (const row of response) {
        this.agencys = [...this.agencys, row.name];
      }
    }, error => {
      this.agencys = [];
    })
  }

  getExecutives() {
    const params: any = {
      name: 'person$executives$get',
      params: {
        request: {},
        path: {},
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.executives = [];

      for (const row of response) {
        this.executives = [...this.executives, row.person.givenName + " " + row.person.familyName];
      }
    }, error => {
      this.executives = [];
    });
  }



  getReasonElimination() {
    const params = {
      name: 'workflow_manager$attributes_options$get',
      params: {
        request: {},
        path: {
          code: 'reasonElimination'
        },
        body: {}
      }
    };

    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      if (response.length) {
        this.responseType = [];
        for (const value of response) {
          let data = { item: value.item.alternateName, value: value.name }
          this.responseType = [...this.responseType, data]

        }
      }
    });

  }


  setClientDetail(e) {

    this.localStorage.removeItem('objContactSetView');
    if (!isNullOrUndefined(e?.open)) {
      this.clientDetail = false;
      if (e.open === 'transaction') {
        this.localStorage.setItem('objBackTransaction', JSON.stringify({ username: this.clientSelected }));
        this.transactionSelected = e.value;
        this.transactionDetail = true;

        // 

        this.open(e.value);


      }
    } else {
      this.clientDetail = e;
    }

  }




  openTransaction(transaction, reset?) {
    this.transactionDetail = false;
    if (!isNullOrUndefined(reset)) {
      this.localStorage.removeItem('objBackTransaction');
    }
    this.transactionSelected = transaction;
    this.transactionDetail = true;
  }

  setClient(client) {
    this.clientDetail = true;
    this.clientSelected = client;
  }

  setTransactionDetail(e) {

    this.transactionDetail = false;
    this.localStorage.removeItem('objContactSetView');
    if (!isNullOrUndefined(e.open)) {
      this.clientDetail = false;
      if (e.open === 'contact') {
        this.localStorage.removeItem('objBackTransaction');
        this.localStorage.setItem('objContactSetView', 2);
        this.setClient(e.value);
      }
    } else {
      this.transactionDetail = e;
    }

  }


  open(solicitud) {

    if (this.isAnalist) {
      this.show(solicitud);

    }


    this.openTransaction(solicitud);
    swal.close();

  }


  getIndicatorsProgres = async (datas, base = false) => {

    if (!this.baseProgresCompleat) {

      this.getIndicatorsProgresTransactions(datas, this.localStorage.getItem('role'), this.localStorage.getItem('audience'), base);

    }

  }



  generateAvatarsService = async (endUsers) => {


    const params = {
      name: 'person$selfies_data_identities$post',
      params: {
        request: {},
        path: {},
        body: {
          endUsers: endUsers

        }
      }
    };

    return this._httpService.executeRequest(params.name, params.params, true).toPromise();

  }

  getAvatars = async (datas) => {

    let temp = {};
    let endUsers = [];

    for (const row of datas) {

      temp[row?.username] = row?.username;

    }

    for (const prop in temp) {
      endUsers = [...endUsers, { "identifier": prop }]
    }

    try {
      let response: any = await this.generateAvatarsService(endUsers);

      if (response) {

        for (const row of response) {

          if (row?.image) {
            //  this.fileAvatarPreview[row.identifier] = 'data:image/jpg' + ';base64,' + row?.image?.description;
            this.fileAvatarPreview[row?.alternateName] = 'data:image/jpg' + ';base64,' + row?.image?.description;

          }
        }
      }

    } catch (error) {

    }




  }


  setHistoryData(row, action = '', actionDescription = '') {

    this.get_dataAndSelfi(row);


    let temp = {
      "username": row?.username,
      "name": row?.solicitante,
      "solicitud": row?.solicitud,
      "date": this.datePipe.transform(Date(), 'dd/MM/yyyy HH:mm'),
      "action": action,
      "actionDescription": actionDescription
    };


    this.setLocalHistoryDatas(temp);

    this.historyDataSelect[row?.solicitud] = 'selected';

  }


  get_dataAndSelfi = async (row) => {

    const params = {
      name: 'person$selfie$get',
      params: {
        request: {
          'username': row?.username
        },
        path: {},
        body: {}
      }
    };

    try {
      let response: any = await this._httpService.executeRequest(params.name, params.params, true).toPromise();

      if (response?.description) {
        this.historyDataSelfi[row?.solicitud] = 'data:image/jpg' + ';base64,' + response?.description;

      }

    } catch (error) {

    }



  }





  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }



  show_file2(file) {

    file = { identifier: file };
    const archive = {
      identifier: file.identifier,
      name: file.name,
      dateCreated: {
        orderDate: null
      }
    };

    if (file.dateCreated) {
      archive['dateCreated']['orderDate'] = file.dateCreated.modifiedDate;
    } else {
      archive['dateCreated']['orderDate'] = null;
    }

    if (archive.identifier !== null) {
      this._upload.visor_params.name = '';
      this._upload.visor_params.date = '';
      this._upload.visor_params.endpoint = 'archive$downloadFile$get';
      this._upload.visor_params.archive = archive;
      this._upload.visor_params.deleteAfterShow = '';
      if (file['action'] === 'download') {
        this._upload.visor_params.action = 'download';
        this._upload.visor_params.deleteAfterShow = {
          deleteRequirement: file['deleteAfterShow']
        };
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      } if (file['action'] === 'downloadzip') {

        if (this.env.theme.images === 'FACEBANK') {
          this._upload.visor_params.endpoint = 'archive$downloadZipFile$get';
        }

        this._upload.visor_params.action = 'download';
        this._upload.visor_params.deleteAfterShow = {
          deleteRequirement: file['deleteAfterShow']
        };
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      } else {
        this._upload.visor_params.action = '';
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      }
    }
  }


  getUser() {
    const params = {
      name: 'person$enduser$get',
      params: {
        request: {
          'username': this._auth.getUsername()
        },
        path: {},
        body: {}
      }
    };


    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      if (response?.url) {
        this.history = JSON.parse(response.url);

        if (this.history['historyTransaction']) {

          this.setHistoryDataInit(this.history['historyTransaction']);

        } else {
          this.history['historyTransaction'] = [];

        }

      }


    });

  }


  setHistoryDataInit(datas) {

    this.historyDataSelect = {};
    this.historyDataSelfi = {};
    this.historyData = [];


    if (datas) {
      let results = [];

      for (const temp of Object.keys(datas)) {

        let row: any = datas[temp];

        if (this.transactionTemp[row?.solicitud]) {
          results = [...results, row];
          this.get_dataAndSelfi(row);
          this.historyDataSelect[row?.solicitud] = 'selected';
        }

      }

      this.historyData = results;


    }

  }


  sliceArray(temp) {

    let sizeArr = temp?.length;
    let min = 30;
    let result = temp;

    if (sizeArr > min) {

      result = temp.slice(0, min);

    }


    return result;

  }


  setLocalHistoryDatas = async (row) => {
    let temp = [];
    let values = this.historyData;

    if (values) {
      temp = [...values];
    }

    if (temp?.length > 0 && temp[0]?.solicitud == row?.solicitud && temp[0]?.username == row?.username) {

      return;
    } else {


      temp = [row, ...temp];
      temp = [...this.sliceArray(temp)];


      this.history['historyTransaction'] = [...temp];

      const params: any = {
        name: 'person$view_history$post',
        params: {
          request: {},
          path: {
            userName: this._auth.getUsername(),
          },
          body: {
            description: JSON.stringify(this.history)
          }
        }
      };


      try {


        this._httpService.executeRequest(params.name, params.params, true).toPromise();

        this.historyData = [...temp];



      } catch (error) {

      }




    };

  }


  getPlans() {

    const params_plans: any = {
      endpoint: {
        plan: {
          name: 'product_plan$plans$get',
          params: {
            path: {},
            request: {},
            body: {}
          }
        }
      }
    };

    this._httpService.executeRequest(params_plans.endpoint.plan.name, params_plans.endpoint.plan.params, true).subscribe((response: any) => {
      if (response) {
        response.filter(p => {

          this.productsPlans[p.identifier] = p;
        });

        this.productsPlans;

      }
    }, error => {


    });
  }

  ifIndicatorProgresItem(transactionNumber, item) {

    return (this.indicatorsProgresRsp[transactionNumber] && this.indicatorsProgresRsp[transactionNumber][item]) ? true : false;
  }



  getUsersTransactions = async (datas) => {

    let temp = {};
    let endUsers = [];
    let usersTransactions = {};
    for (const row of datas) {

      temp[row?.username] = row?.username;

    }

    for (const prop in temp) {
      endUsers = [...endUsers, { "identifier": prop }]
    }

    const params = {
      name: 'person$endusers_transactions$post',
      params: {
        request: {},
        path: {},
        body: {
          endUsers: endUsers

        }
      }
    };


    try {
      let response: any = await this._httpService.executeRequest(params.name, params.params, true).toPromise();
      if (response) {

        for (const row of response) {

          let resultObj = {};



          resultObj['completeBriefcase'] = row?.adminOrganization ? row?.adminOrganization?.completeBriefcase : row?.person?.completeBriefcase;
          resultObj['verifiedBriefcase'] = row?.adminOrganization ? row?.adminOrganization?.verifiedBriefcase : row?.person?.verifiedBriefcase;
          resultObj['transaction'] = null;


          if (row?.bankAccountTransactions?.length > 0) {

            let results: any = row?.bankAccountTransactions;
            let status;

            for (let result of results) {
              let statustrans = result?.accountStatus[0]?.stateInWorkFlow?.code;

              if (statustrans == 'AC_RECEIVED') {

                status = statustrans;
                resultObj['transaction'] = result;


              } else if (statustrans == 'AC_PENDING' && (!status || (status != 'AC_RECEIVED'))) {

                status = statustrans;
                resultObj['transaction'] = result;


              } else if ((!status || (status != 'AC_RECEIVED' && status != 'AC_PENDING'))) {

                status = statustrans;
                resultObj['transaction'] = result;

              }


            }


          }


          usersTransactions[row?.identifier] = resultObj;

        }
      }

      this.usersTransactions = usersTransactions;

    } catch (error) {
      console.log(error);
    }

  }

  getUsersTransactionsOrUsername(data) {
    let resp = this.usersTransactions[data];
    return resp;


  }




  getTransactionData = async (transactionNumber) => {

    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role,
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }



  setOrder = (val) => {

    this.loading_order = true;
    this.orderByData = val;


    this.setFilterMultiple();
    this.openFilter = !this.openFilter;

    this.loading_order = false;

  }

  custom_sort_date_desc(a, b) {
    return new Date(b?.create).getTime() - new Date(a?.create).getTime();
  }


  custom_sort_date_asc(a, b) {

    return new Date(a?.create).getTime() - new Date(b?.create).getTime();

  }

  custom_sort_progres_desc(a, b) {

    return b?.compleatpercent - a?.compleatpercent;

  }

  custom_sort_progres_asc(a, b) {

    return a?.compleatpercent - b?.compleatpercent;


  }

  setOrderBy = (datas) => {
    let response;


    switch (this.orderByData) {
      case 1:
        response = datas.sort(this.custom_sort_date_desc);
        break;

      case 2:
        response = datas.sort(this.custom_sort_date_asc);

        break;
      case 3:

        datas.map((resp) => {

          resp['compleatpercent'] = this.indicatorsProgresRsp[resp?.solicitud]?.compleat?.percent || 0;

          return resp;
        });

        response = datas.sort(this.custom_sort_progres_desc);

        break;
      case 4:

        datas.map((resp) => {

          resp['compleatpercent'] = this.indicatorsProgresRsp[resp?.solicitud]?.compleat?.percent || 0;

          return resp;
        });

        response = datas.sort(this.custom_sort_progres_asc);

        break;
    }

    return response;


  }


  getNoteForUsername = (username) => {

    return (this.notes[username]) && (this.notes[username][0] || null);

  }


gergeneral
  setViewsNote(userName) {
    
    this._wfService.setShowNoteForClient(userName);

  }


  setNotesForUserNames = async (datas) => {

    if (datas?.length > 0) {

      let list = [];

      for (const row of datas) {

        list = [...list, row?.username];

      }


      try {


        let listNotes: any = await this.getListNotesUsernameServe(list);

        listNotes.sort(this.custom_sort);

        let temp = {};
        listNotes?.map((r, i) => {
          let a = temp[r?.client] || [];
          temp[r?.client] = [...a, r];

        });


        this.notes = temp;

      } catch (error) {
        this.notes = {};


      }

    }

  }


  getListNotesUsernameServe = async (list) => {


    let data = [...list]

    try {
      //environment?.theme?.site?.name
      const params = {
        pathResponse: {
          url: ' https://utilities.akeela.co/note/list',
          httpMethod: 'POST'
        },
        params: {
          request: {
            'environment_code': this.env?.code || this.env?.theme?.images
          },
          body: data

        }
      }

      return this._httpService.executeRequestExternalUrl(params).toPromise();

    } catch (error) {

      console.error(error);

    }

  }


  custom_sort(a, b) {
    return new Date(b?.create_date).getTime() - new Date(a?.create_date).getTime();
  }

  substring250 = (cadena) => {
    let obj = {};

    if (cadena?.length > 100) {

      obj = {

        more: true,
        text: cadena?.substring(0, 100)
      }
    } else {
      obj = {

        more: false,
        text: cadena
      }
    }
    return obj;
  }


  substringMore = (num, cadena) => {
    let obj = {};

    if (cadena?.length > num) {

      obj = {

        more: true,
        text: cadena?.substring(0, num)
      }
    } else {
      obj = {

        more: false,
        text: cadena
      }
    }
    return obj;
  }

  setLA(e){
    const {externalCode,username} = e;

    this.rows?.map((resp) => {

      if(resp?.username == username){
        resp['externalCode'] = externalCode;
      }

      return resp;

    });

   
    this.temp?.map((resp) => {

      if(resp?.username == username){
        resp['externalCode'] = externalCode;
      }

      return resp;

    });


  }

  validateFilterTypeAccount = () => {
    if(this.env?.theme?.images === 'RENDIVALORES') {
      if(this.localStorage.getItem('workspace')==='SSCA01R01CPE' || this.localStorage.getItem('workspace')==='SSCA001CP' || this.localStorage.getItem('workspace')==='SSCALLCE' ||  this.localStorage.getItem('workspace')==='SSCA01R01CE') {
        return true;
      }
      return false;
    }
    return true;
  }
}
