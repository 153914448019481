import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import swal from 'sweetalert2';
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-filter-policys',
  templateUrl: './filter-policys.component.html',
  styleUrls: ['./filter-policys.component.css']
})
export class FilterPolicysComponent implements OnInit {
  @Input() policys;
  @Input() filter;
  @Output() response = new EventEmitter();
  constructor(public readonly swalTargets: SwalPortalTargets) { }

  ngOnInit() {
  }

  applyFilter(event) {
    swal.close();
    this.filter.active = event;
    this.response.emit(event);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    swal.close();
  }

}
