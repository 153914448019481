<div class="capa-menu-2" *ngIf="buttomModal" (click)="setClose()"> </div>

<div [ngClass]="{'d-none':animate_init_bottom,'animate__slideInUp':buttomModal,animate__slideOutDown:!buttomModal } " class="  animate__animated animate__faster ak-modal-botoom ak-modal-botoom2  p-2 ">
    <div class="body pb-4 pt-2 " style="overflow:auto;">
        <div class="container">


            <div class="row">
                <div [ngClass]="{'col-md-6 offset-md-3': !showForm,'col-md-8 offset-md-2': showForm}" class="col-12 ">

                    <div (click)="$event.stopPropagation();" class="w-100 bg-white mt-3  ">


                        <div style="min-height: 206px;">


                            <div class="d-flex bd-highlight">
                                <div class="w-80 w-sm-70">

                                    <h5 class="font-weight-bold fz-16"><i class="fas fa-sticky-note text-dark"></i> Caja Venezolana de Valores</h5>
                                    <div class="text-secondary fz-14">{{client?.person?.givenName + ' '+ client?.person?.familyName}}</div>

                                </div>


                            </div>

                            <hr>


                            <ng-container *ngIf="loadingCVV">
                                <div class="text-center mt-3">
                                    <img src="./assets/public/images/loading.gif" alt="">
                                </div>
                            </ng-container>


                            <ng-container *ngIf="!showForm && !loadingCVV">


                                <div class="row fz-14">
                                    <div class="col-12 col-md-6  text-capitalize">
                                        <div class="font-weight-bold  fz-14  ">Codigo</div>
                                        <div class="text-secondary  fz-14  ">{{registerCVV?.extraDataCvv?.code}}</div>

                                    </div>
                                    <div class="col-12 col-md-6  text-capitalize text-right">
                                        <div class="font-weight-bold  fz-14  ">fecha</div>
                                        <div class="text-secondary  fz-14  ">{{registerCVV?.extraDataCvv?.fecha}}</div>

                                    </div>

                                </div>


                                <div class="row fz-14 mt-3">
                                    <div class="col-12 col-md-6  text-capitalize">
                                        <div class="font-weight-bold  fz-14  ">Estatus</div>
                                        <div class="text-secondary  fz-14  ">{{registerCVV?.extraDataCvv?.status}}</div>

                                    </div>
                                    <div class="col-12 col-md-6  text-capitalize text-right">
                                        <div class="font-weight-bold  fz-14  ">Ejecutivo</div>
                                        <div class="text-secondary  fz-14  ">{{registerCVV?.userRegisteredCvv}}</div>

                                    </div>

                                </div>

                                <div class="row fz-14 mt-3">
                                    <div class="col-12   text-capitalize">
                                        <div class="font-weight-bold  fz-14  ">Descripcion</div>
                                        <div class="text-secondary  fz-14  ">{{registerCVV?.extraDataCvv?.description}}</div>

                                    </div>

                                </div>


                            </ng-container>



                            <ng-container *ngIf="showForm && !loadingCVV">
                                <div class="w-100">

                                    <form [formGroup]="form" (ngSubmit)="submit()" class="text-left fz-14">

                                        <div class="row">
                                            <div class="form-group col-12 col-md-6">
                                                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                                        <span [innerHTML]="'Fecha'"></span>
                                        
                                        <span class="text-danger mt-1" > * </span>
                                    </label>
                                                <input type="date" class="form-control" [ngClass]="{'error': form.controls.fecha.invalid && form.controls.fecha.dirty}" formControlName="fecha">
                                                <div class="form-control-feedback help-block" *ngIf="form.get('fecha').errors">
                                                    <div *ngIf="form.get('fecha').errors['required'] && !form.get('fecha').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">



                                            <div class="form-group col-12 col-md-6">
                                                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                                        <span [innerHTML]="'Estatus'"></span>
                                        <span class="text-danger mt-1" > * </span>
                                    </label>
                                                <input type="text" placeholder="estatus" class="form-control" [ngClass]="{'error': form.controls.status.invalid && form.controls.status.dirty}" formControlName="status">
                                                <div class="form-control-feedback help-block" *ngIf="form.get('status').errors">
                                                    <div *ngIf="form.get('status').errors['required'] && !form.get('status').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="form-group col-12 col-md-6">
                                                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                                        <span [innerHTML]="'Codigo'"></span>
                                        <span class="text-danger mt-1" > * </span>
                                    </label>
                                                <input type="text" class="form-control" [ngClass]="{'error': form.controls.code.invalid && form.controls.code.dirty}" formControlName="code">
                                                <div class="form-control-feedback help-block" *ngIf="form.get('code').errors">
                                                    <div *ngIf="form.get('code').errors['required'] && !form.get('code').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                                                    </div>

                                                </div>
                                            </div>



                                            <div class="form-group col-12 col-md-12 ">
                                                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                                        <span [innerHTML]="'Nota'"></span>
                                        <span class="text-danger mt-1" > * </span>
                                    </label>
                                                <textarea style="height: 120px" [placeholder]="'Descripción'" type="subtitle" class="form-control mt-2" [ngClass]="{'error': form.controls.description.invalid && form.controls.description.dirty}" formControlName="description"></textarea>
                                                <div class="form-control-feedback help-block" *ngIf="form.get('description').errors">
                                                    <div *ngIf="form.get('description').errors['required'] && !form.get('description').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                                                    </div>

                                                </div>
                                            </div>



                                        </div>
                                        <div class="row">

                                            <br>
                                            <div class=" col-12 mt-3">


                                                <button type="submit" [disabled]="!form.valid || submit_disabled  || !form.dirty" class="btn btn-primary  ">

                                                    Continuar
                                                    <span *ngIf="submit_disabled">
                                                    <i class="fa  fa-spinner fa-spin"></i>
                                                    </span>
                                                </button>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </ng-container>


                        </div>



                    </div>

                </div>
            </div>
        </div>

    </div>
</div>