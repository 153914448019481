import { Component, OnInit } from '@angular/core';
import { EmailService } from '../../../../services/email.service';
import { I18nPipe } from '@akeela/i18n';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from 'util';
import { HttpService } from '@akeela/properties';
import { ErrorService } from '@akeela/common';


@Component({
  selector: 'app-public-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.css']
})
export class RecoveryPasswordComponent implements OnInit {
  config;
  submit_disabled = false;
  environment;

  constructor(
    private _emailService: EmailService,
    private i18n: I18nPipe,
    private route: ActivatedRoute,
    private router: Router,
    private _httpService: HttpService,
    private _error: ErrorService
  ) { }

  ngOnInit() {
    this.environment = environment;
    this.config = {
      url: 'person$reset_password_user$put',
      prefixUrl: 'create-new-password',
      portal: 'Facebank',
      email_site: 'onlinemock@facebank.com',
      params: {
        body: {},
        request: {},
        path: {}
      }
    };
  }

  getResponse(response) {
    if (response.success) {
      this.sendMailConfirmation(response.response.identifier);
      // this.sendMailRecovery(response.response);
    } else {
      this._error.show(response.response.error);
    }
  }

  sendMailConfirmation(username) {
    const params = {
      name: 'person$recovery-password$post',
      params: {
        request: {
          username: username
        },
        path: {},
        body: {
        }
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      swal.fire({
        title: this.i18n.transform('@i18n-text-mail-success'),
        html: this.i18n.transform('@i18n-text-mail-success-recovery-password'),
        showCancelButton: false,
        confirmButtonText: this.i18n.transform('@i18n-popup-text-ok'),
        reverseButtons: true
      }).then((result) => {

        this.router.navigateByUrl(this.router.createUrlTree
          ([this.environment.staticLinks.login],
          {
            queryParams: {}
          }
          )
        );

      });

    }, error => {
      this._error.show(error, this.i18n.transform('Alerta!'), this.i18n.transform('<b>No se pudo enviar el correo de recuperación. Vuelve a intentarlo más tarde..</b>'));
      this.submit_disabled = false;

    });
  }

  sendMailRecovery(data) {
    const email = data.person.email;
    const token = data.name;
    const name = '';
    const username = data.identifier;
    const origin = window.location.hostname;
    const url = environment.urlFront + '/' + this.config.prefixUrl + '/' + username + '/' + token;
    const portal = environment.theme.site.name;
    const properties: object = {};
    const site_email = environment.theme.site.supportMail;
    properties['template'] = 'templates/es/recoverypassword.html';

    let site_header = environment.theme.site.name;
    if (!isNullOrUndefined(environment.theme.image)) {
      if (!isNullOrUndefined(environment.theme.image.active)) {
        if (environment.theme.image.active) {
          site_header = '<img src="' + environment.theme.image.urlImageHeader + '" ></img>';
        }
      }
    }

    properties['attr'] = [
      { 'key': '%%URL%%', 'value': url },
      { 'key': '%%URLDESCRIPTION%%', 'value': this.i18n.transform('@i18n-mail-verify-account') },
      { 'key': '%%NOMBRE%%', 'value': name },
      { 'key': '%%USERNAME%%', 'value': username },
      { 'key': '%%SITENAME%%', 'value': portal },
      { 'key': '%%SITEEMAIL%%', 'value': environment.theme.site.supportMail },
      {
        'key': '%%FONT_COLOR%%',
        'value': environment.theme.fontColor
      },
      {
        'key': '%%BACKGROUND_COLOR%%',
        'value': environment.theme.backgroundColor
      },
      {
        'key': '%%PROCEDURE%%',
        'value': environment.procedureMail
      },
      {
        'key': '%%BACKGROUND_BORDER%%',
        'value': environment.theme.backgroundColorBorderMail
      },
      {
        'key': '%%ALIAS_APP%%',
        'value': site_header
      },
      {
        'key': '%%TEXTFOOTER%%',
        'value': !isNullOrUndefined(environment.theme.textFooterMail) ? environment.theme.textFooterMail : ''
      }
    ];
    const email_message = {
      identifier: 1,
      dateOfThing: {},
      status: null,
      sender: null,
      text: null,
      type: 'text/html',
      subjectOf: portal,
      toRecipient: email
    };
    const template = this._emailService.getTemplate(properties);
    template.subscribe(template_mail => {
      const dataMail = this._emailService.configureTemplate(template_mail, properties['attr']);
      const template_data = { properties, template_mail };
      template_mail = dataMail;
      const response = this._emailService.sendMail(
        email_message,
        template_data.properties,
        template_data.template_mail
      );
      response.subscribe(mail_result => {
        swal.fire({
          title: this.i18n.transform('@i18n-text-mail-success'),
          html: '<b>' + this.i18n.transform('@i18n-text-mail-success-recovery-password') + '</b>',
        });
        this.router.navigateByUrl(this.router.createUrlTree
          (['security/login'],
          {
            queryParams: {}
          }
          )
        );
      }, error => {
        swal.fire({
          title: 'Warning',
          html: '<b>No se pudo enviar el correo de recuperación. Vuelve a intentarlo más tarde..</b>',
        });
        this.submit_disabled = false;
      });
    }, error => {
      swal.fire({
        title: 'Warning',
        html: '<b>No se pudo enviar el correo de recuperación. Vuelve a intentarlo más tarde..</b>',
      });
      this.submit_disabled = false;
    });
  }

}
