import { AuthService } from '@akeela/auth';
import { PaginatorService } from '@akeela/common';
import { LocalService } from '@akeela/local-storage';
import { HttpService } from '@akeela/properties';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-list-guest',
  templateUrl: './list-guest.component.html',
  styleUrls: ['./list-guest.component.css']
})
export class ListGuestComponent implements OnInit {

listGuest :any = [];
rows = [];
temp = [];
columns = [];
akinitial = {};
paginator: any = null;
reload_loading;
loadingIndicator;
fieldSearch: any = '';
newGuest;
env = environment;

  constructor(
    private _httpService: HttpService,
    private _auth: AuthService,
    private router: Router,
    private localStorage: LocalService,
    private paginatorService: PaginatorService


  ) { }

  ngOnInit(): void {
    this.paginator = {
      totalPages: 0,
      first: true,
      last: false,
      number: 0,
      pageable: {
        pageNumber: 0,
        from: null,
        to: null
      }
    };

     this.setListGuest();

  }

  redirectClient(){

    const queryParams = {
      role: 'USER',
      profile: this.localStorage.getItem('profile'),
      audience: 'NATURAL',
    };
  
    this.router.navigate([environment.staticLinks.listClients], {queryParams});

  }

   setListGuest =  async () =>{
    this.reload_loading = true;
    this.newGuest = false;

    try {
      let listGuest:any = await this.getListGuest();
      
      this.listGuest = [...listGuest];
      this.setConfigurationTableNew();

    } catch (error) {
      this.listGuest = [];
      this.reload_loading = false;

    }
    }

  getListGuest =  async () =>{
    const params: any = {
      name: 'person$get_guests$get',
      params: {
        request: {},
        path: {},
        body: {}
      }
    };

    return await this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }


  setConfigurationTableNew() {
    this.columns = [];
    let datas = [];
    let data = [];
    const content = [];
    let i = 0;

    for (const row of this.listGuest) {
      /*icono*/
      let icon = '';
      let initials = this.getInitials(row?.name,row?.lastName);
      let initial = this.getInitial(row?.businessName);

      this.akinitial[row.identifier] = { initial, initials }

      /*Menu de opciones*/

      let jsonTmp =
        {
          "identifier": row?.identifier,
          "name": `${row?.name} ${row?.lastName}`,
          "phone": row?.phone,
          "personType": row?.personType,
          "email": row?.email,
          "isClient": row?.isClient || false,
          "executiveUsername": row?.executiveUsername,
          "businessName": row?.businessName ,
          "application": row?.application,
          "idPerson": row?.idPerson,
          "extraData": row?.extraData,
          "fecha":row?.dateOfThing?.startDate

        }

      datas = [...datas, jsonTmp];

    }

    
 this.reload_loading = false;

  datas.sort(this.custom_sort);

    this.temp = [...datas];
    this.rows = [...datas];

    let guest = this.temp;



   data = this.paginatorService.getDataPaginator(this.paginator, this.env, guest);
    this.paginator.number = guest?.length;
    this.paginator.totalPages = Math.ceil(guest?.length / this.env.paginator.size);
    this.paginator.pageable.to = this.paginatorService.getPaginatorTo(this.paginator, this.env);
    this.paginator.pageable.from = this.paginatorService.getPaginatorFrom(this.paginator, this.env);
    this.rows = data;

  }



  //HERE
  updateFilter(data) {

    let data2 = [];
    this.loadingIndicator = true;
    const val = data ? String(data).toLowerCase() : null;

    if (val) {
      // filter our data

      let arrayBase = this.temp;

      const temp = arrayBase.filter(function (d) {

        let filter = false;
        let tempAnchor = false;

        for (let clave in d) {


          let value = d[clave];


          if (!filter ) {

            filter = String(value).toLowerCase().indexOf(val) !== -1 || !val;

          }
                        
          
        }

        return filter;

      });

      // update the rows
      data2 = temp;
    } else {
      data2 = this.temp;
    }

    this.rows = data2;

    this.rows = this.paginatorService.getDataPaginator(this.paginator, this.env, data2);

    this.update_transactionsFilter(data2);

    this.loadingIndicator = false;

  }


  custom_sort (a, b) {
    return new Date(b?.fecha).getTime() - new Date(a?.fecha).getTime();
}



  getInitials(givenName: string, familyName: string) {
    return (
      (givenName ? givenName.substr(0, 1).toUpperCase() : '') +
      (familyName ? familyName.substr(0, 1).toUpperCase() : '')
    );
  }
  getInitial(text) {
    let retorno = '';
    if (text) {
      retorno = text.substr(0, 1).toUpperCase();
    }
    return retorno;
  }

  getFirstLetter(data: string) {
    return (data ? data.substr(0, 1).toUpperCase() : '');
  }


  

  gotoWhatsapp(event,num) : void {
    window.open(" https://wa.me/"+num?.replace('+', ''), "_blank");
    event.stopPropagation();

  }
  

  action_paginator(page) {
    if (page === 'NEXT') {
      page = this.paginator.pageable.pageNumber + 1;
      if (page >= this.paginator.totalPages) {
        page = this.paginator.pageable.pageNumber;
      }
    } else if (page === 'BACK') {
      page = this.paginator.pageable.pageNumber - 1;
      if (page < 0) {
        page = this.paginator.pageable.pageNumber;
      }
    }
    this.paginator.pageable.pageNumber = page;
    this.paginator.first = (page === 0 ? true : false);
    this.paginator.last = ((page + 1) === this.paginator.totalPages ? true : false);    
    this.setTablePaginators();
  }



  setTablePaginators() {

    let data = this.paginatorService.getDataPaginator(this.paginator, this.env, this.temp);

    this.paginator.number = this.temp?.length;
    this.paginator.totalPages = Math.ceil(this.temp?.length / this.env.paginator.size);
    this.paginator.pageable.to = this.paginatorService.getPaginatorTo(this.paginator, this.env);
    this.paginator.pageable.from = this.paginatorService.getPaginatorFrom(this.paginator, this.env);
    this.rows = data;


  }



  update_transactionsFilter(params, loading?) {
    this.reload_loading = true;
    this.paginator = {
      totalPages: Math.ceil(params.length / this.env.paginator.size),
      first: true,
      last: false,
      number: params.length,
      pageable: {
        pageNumber: 0,
        from: 1,
        to: params.length < this.env.paginator.size ? params.length : this.env.paginator.size
      }
    };

      this.reload_loading = false;

    

  }

}
