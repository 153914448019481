<app-template *ngIf="!loading">
    <!-- RESUMEN -->
    <ng-template app-content componentName="template-resumen-info">
        <div class="col-12 pull-left">
            <app-detail-formality [transactionData]="transactionData"></app-detail-formality>
        </div>
    </ng-template>
    <ng-template app-content componentName="template-resumen-documentation" *ngIf="env?.appEndPontDefaultConfig?.showPayroll">
        <div class="col-lg-12">
            <app-list-formalities-documentation ></app-list-formalities-documentation>
        </div>
    </ng-template>
    <div class="col-lg-12">
        <br>
    </div>
</app-template>

<ng-container *ngIf="!loading">
        <app-general></app-general>
</ng-container>
