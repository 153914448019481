<div class="mt-1  animated fadeIn">

    <div class="jumbotron jumbotron-fluid p-3 ">

        <div class="d-flex bd-highlight">

            <div>
                <h5 class="font-weight-normal">
                    <i class="fas fa-building pr-1"></i> Ejecutivos
                </h5>
            </div>

            <div class="ml-auto">
                <i class="fas fa-ellipsis-v "></i>
            </div>
        </div>

    </div>

    <div class="w-100  mb-3">
        <!-- this creates a google map on the page with the given lat/lng from -->
        <!-- the component as the initial center of the map:
                -->
        <agm-map [fitBounds]="true" [latitude]="lat" [longitude]="lng" [zoom]='zoom' [mapTypeId]="mapTypeId">
            <agm-marker [agmFitBounds]="true" [latitude]="10.4911563" [longitude]="-67.4844948" [iconUrl]="
            {
              url:'./assets/mibanco/public/images/avatar.png',
              scaledSize: {
                  width:30,
                  height:30
              }
          }">
                <agm-info-window>
                    <div style="padding: 5px">
                        <b>Juan Soto</b><br>
                        <p>Caracas</p>
                    </div>

                </agm-info-window>
            </agm-marker>
            <agm-marker [agmFitBounds]="true" [latitude]="10.3457046" [longitude]="-66.8647473" [iconUrl]="
            {
                url:'./assets/mibanco/public/images/avatar2.png',
                scaledSize: {
                    width:30,
                    height:30
                }
          }">
                <agm-info-window>
                    <div style="padding: 5px">
                        <b>Barbara Dasilva</b><br>
                        <p>Maracay</p>
                    </div>

                </agm-info-window>
            </agm-marker>

        </agm-map>
    </div>


    <div class="w-100 ">
        <div class="row mb-3 ">
            <div class="col-12 text-primary col-md-6">
                <div class="d-inline-flex w-100">
                    <input class="form-control active-cursor padding-filter-searh-input" type="text" placeholder='Agencia, Dirección, Tlf., Coordinador' />
                    <i class="fas fa-search icon-filter-searh"></i>

                </div>
            </div>
            <div class="w-100 pl-0  ml-2 animate__animated animate__fadeIn">
                <ul class="scrollmenu pl-0 pt-2 ">
                    <li class="d-inline">
                        <div class="item-rd text-secondary  border-primary fz-14 active">Este mes
                            <i class="fa fa-times ml-2 active-cursor" aria-hidden="true"></i>

                        </div>

                    </li>
                    <li class="d-inline">
                        <div class="item-rd text-secondary  border-primary fz-14 active">Centro Lido
                            <i class="fa fa-times ml-2 active-cursor" aria-hidden="true"></i>

                        </div>

                    </li>

                </ul>
            </div>
        </div>

        <div class="mt-3 w-100">

            <div class=" ">
                <i class="fas fa-redo text-secondary"></i>
                <span class="text-secondary float-right ">
                                10 - 25 de 25 <i class="fas fa-chevron-left mr-1 text-secondary active-cursor"></i>
                              </span>
            </div>
        </div>
        <hr>


        <div *ngIf="!loading_table">


            <div class="jumbotron jumbotron-fluid p-2 rounded mb-2  d-none d-md-block">
                <div class="d-flex bd-highlight  flex-wrap font-weight-bold fz-12 ">

                    <div class="w-40 ">
                        Ejecutivo
                    </div>

                    <div class="w-60  d-none d-md-block ">
                        <div class="d-flex bd-highlight flex-wrap">

                            <div class="w-20  text-right   ">
                                Aprobadas
                            </div>
                            <div class="w-20   text-right ">
                                Recibidas </div>
                            <div class="w-20 text-right ">
                                Devueltas
                            </div>
                            <div class="w-20  text-right ">
                                Nuevas
                            </div>
                            <div class="w-20  text-right ">
                                Desiertas
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngFor="let row of rows ; let i = index">
                <div class="card  table-card  ak-shadow-box rounded" [ngClass]="{'mt-3':i > 0}">
                    <div class="card-body p-3 fz-14 ">
                        <div class="d-flex bd-highlight  flex-wrap ">

                            <div class="w-40 w-sm-100 ">
                                <div class="d-flex bd-highlight">
                                    <div>
                                        <div class="DomainUserAvatar-avatar Avatar--color-{{getInitial(row.name)}} AvatarPhoto AvatarPhoto--eje" role="img">{{getInitials(row.name)}}</div>
                                    </div>

                                    <div class="ml-2 ">
                                        <div class=" d-flex bd-highlight">
                                            <div class="font-weight-bold fz-16 ">
                                                {{row.name}}
                                            </div>

                                            <div class="  mt-1 text-muted fz-12 d-none d-md-block ml-1">
                                                ({{row.agencia}})
                                            </div>
                                        </div>

                                        <!--<div class="fz-12 mt-1 ">
                                            <span class="font-weight-light text-secondary ">{{row.agencia}} </span>,
                                            <span class="font-weight-light  text-secondary ">Mienbro desde {{row.fecha}} (352 días)</span>
                                        </div>-->
                                        <div class="fz-12">
                                            <span class="font-weight-normal ">Ult. aprobación {{row.fecha}}</span>
                                        </div>
                                    </div>

                                    <div class=" text-muted  ml-auto ml-md-1 fz-12 d-block d-md-none">
                                        {{row.agencia}}
                                    </div>
                                </div>
                            </div>


                            <div class="w-60  d-none d-md-block align-self-center">
                                <div class="d-flex bd-highlight flex-wrap align-items-center">

                                    <div class="w-20  text-right font-weight-bold  ">
                                        30
                                    </div>
                                    <div class="w-20 text-primary text-right ">
                                        36
                                    </div>
                                    <div class="w-20 text-right ">
                                        5
                                    </div>
                                    <div class="w-20  text-right ">
                                        10
                                    </div>
                                    <div class="w-20  text-right ">
                                        122
                                    </div>
                                </div>
                            </div>


                            <div class="w-100 ">
                                <!--
                                <div class="fz-12 mt-1 ml-40">
                                    <span class="font-weight-light text-secondary ">{{row.agencia}} </span>,
                                    <span class="font-weight-light  text-secondary ">Mienbro desde {{row.fecha}} (352 días)</span>
                                </div>
-->
                                <div class="fz-12   ml-40">
                                </div>
                            </div>


                            <div class="w-100  d-block d-md-none pt-1 ">
                                <hr>

                                <div class="d-flex bd-highlight  flex-wrap  mb-1 fz-12">
                                    <div class="w-50">
                                        Aprobadas
                                    </div>
                                    <div class="w-50 font-weight-bold text-right">
                                        30
                                    </div>
                                </div>
                                <div class="d-flex bd-highlight  flex-wrap  mb-1 fz-12">
                                    <div class="w-50">
                                        Recibidas
                                    </div>
                                    <div class="w-50 font-weight-bold text-primary  text-right">
                                        36
                                    </div>
                                </div>
                                <div class="d-flex bd-highlight  flex-wrap  mb-1 fz-12">
                                    <div class="w-50">
                                        Devueltas
                                    </div>
                                    <div class="w-50 text-right">
                                        5
                                    </div>
                                </div>
                                <div class="d-flex bd-highlight  flex-wrap  mb-1 fz-12">
                                    <div class="w-50">
                                        Nuevas
                                    </div>
                                    <div class="w-50  text-right">
                                        10
                                    </div>
                                </div>
                                <div class="d-flex bd-highlight  flex-wrap  mb-1 fz-12">
                                    <div class="w-50">
                                        Desiertas
                                    </div>
                                    <div class="w-50  text-right">
                                        122
                                    </div>
                                </div>



                            </div>

                        </div>
                    </div>
                </div>
            </ng-container>
            <br> <br>
        </div>



        <ng-container *ngIf="loading_table">
            <div class="row">
                <div class="col-12">
                    <img src="./assets/public/images/loading.gif" alt="">
                </div>
            </div>
        </ng-container>
    </div>
</div>