<div class="mt-1 container animated fadeIn">
    <div class="w-100 ">
        <div class="box m-t-3 fz-body">
            <ng-container *ngIf="transactionData!==null">
                <div class="row">
                    <div class="text-left">
                        <span class="text-color-gray text-size-2" [innerHTML]="('@i18n-app-column-formality' | i18n )+' <b>#'+transactionNumber+'</b>' | i18n "></span>
                        <span class="text-color-gray d-none d-md-block" [innerHTML]=" (transactionData.accountStatus[0].userDescription | i18n)+', '+(transactionData.accountStatus[0].dateOfThing.startDate | date:'medium') "></span>
                        <!-- style="min-height:30px!important;padding-left:10px;padding-top:10px;font-size:12px;position:relative"-->
                    </div>
                    <br>
                </div>
                <!-- INTEGRACIÓN -->

                <div class="row">

                    <div class="w-100">
                        <br>
                        <br>
                        <ul class="nav nav-tabs fz-body bg-white" [class.nav-fill]="config.tabs.length > 2" [class.nav-pills]="config.tabs.length > 2" *ngIf="config">
                            <ng-container *ngFor="let tab of config.tabs">
                                <li class="nav-item" [class.active-cursor]="tab.enabled" *ngIf="tab.enabled">
                                    <a class="nav-link" [class.disabled]="!tab.enabled" [class.active]="tab.identifier === active" (click)="active=tab.identifier">{{ tab.description | i18n}}</a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>

                    <div class="w-100">
                        <ng-container *ngIf="active==='P1'">
                            <div class="m-t-2">
                                <app-detail-formality [transactionData]="transactionData"></app-detail-formality>
                            </div>

                            <div class="m-t-2" *ngIf="env?.appEndPontDefaultConfig?.showPayroll">
                                <app-list-formalities-documentation></app-list-formalities-documentation>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="active==='P2'">
                            <div class="col-12">
                                <!-- <app-previusly-revisions [transactionNumber]="transactionData.transactionNumber"></app-previusly-revisions> -->
                            </div>
                        </ng-container>

                        <ng-container *ngIf="active==='P4'">
                            <div class="col-12">
                                <app-requirements-response [identifier]="identifier" [inputTransactionNumber]="transactionNumber" [isActiveResume]="false"></app-requirements-response>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="active==='P3'">
                            <div class="col-12 m-t-2">
                                <app-list-formalities-relationship (reload)="refresh($event)" [transactionNumber]="transactionData?.transactionNumber" *ngIf="transactionData"></app-list-formalities-relationship>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="active==='P5'">
                            <div class="col-12 m-t-2">
                                <app-list-formalities-related (reload)="refresh($event)" [transactionNumber]="transactionData?.transactionNumber" *ngIf="transactionData"></app-list-formalities-related>
                            </div>
                        </ng-container>

                    </div>


                </div>



            </ng-container>

            <ng-container *ngIf="transactionData===null">
                <div class="col-12 col-md-8 p-t-1 p-b-1">
                    <img src="./assets/public/images/loading.gif" alt="">
                </div>
            </ng-container>
        </div>
    </div>
</div>