import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  DoCheck,
} from "@angular/core";
import { HttpService } from "@akeela/properties";
import { AuthService, ErrorService } from "@akeela/auth";
import { UploadService, PaginatorService } from "@akeela/common";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import swal from "sweetalert2";
import { I18nPipe } from "@akeela/i18n";
import { EmailService } from "../../../../services/email.service";
import { environment } from "../../../../../environments/environment";
import { isNullOrUndefined } from "util";
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";
import {
  AkeelaWorkflowService,
  AkeelaStageService,
  AkeelaStepService,
  AkeelaSectionService,
} from "@akeela/workflow";
import { LocalService } from "@akeela/local-storage";
import { async, Subscription } from "rxjs";
import { Table, Row, Column, Dropdown, DropdownList } from "@akeela/ak-table";
import { DatePipe } from "@angular/common";
import { AkeelaSidebarMenuService } from "@akeela/menu";
import {
  DatatableComponent,
  ColumnMode,
  SelectionType,
} from "@swimlane/ngx-datatable";
import { PropertiesService } from "../../../../services";
import { WorkflowService } from "src/app/services/workflow.service";

@Component({
  selector: "app-list-clients-paginated",
  templateUrl: "./list-clients-paginated.component.html",
  styleUrls: ["./list-clients-paginated.component.css"],
})
export class ListClientsPaginatedComponent implements OnInit {
  clients;
  clients_backup;
  queryParams;
  form: FormGroup;
  isAnalist = false;
  executives;
  executivesOptions;
  showWindowNewContact = false;
  objModal;
  paginator: any = null;
  loading_table = false;
  clientEjecutive;
  filter;

  env = environment;

  subscriptions: Subscription[] = [];
  configuration_table: Table = null;
  filter_order: any = {};
  queueNameWsp;
  readonly = false;

  currentPage = 0;

  executive_name = "";
  rows = [];
  clientLast;
  rows_executive = [];
  temp_executive = [];
  akinitial_executive = [];

  selected = [];
  temp = [];
  columns = [];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  temp2;
  fieldSearch: any = "";
  fieldSearchEjecutive: any = "";

  anchorSearch = [];
  akDropdown = [];
  akinitial = [];

  isLoading: boolean;
  profile;
  loadingIndicator = false;
  listOptionAnalist = [];
  listOptionEjecutive = [];
  screenMax = true;
  filters = {};

  formsData = {};
  boxFilters;
  updateClients = false;
  filterEtique: any = [];

  //************************************ */

  reload_loading = false;
  updateDate;
  filterBy = true;
  orderBy = false;

  animate_init = true;
  animate_init_bottom = true;
  openFilter = false;

  clientDetail;
  clientSelected;
  stateCodeFilter = "";
  agencys = [];
  buttomModal = false;
  rowSelected;
  superUser;
  fileAvatarPreview = {};
  usersTransactions: any = {};
  client_indicators = {
    loading: true,
    notConfirm: 0,
    notTransaction: 0,
    notVerif: 0,
  };

  history = {};
  historyData = {};
  historyDataSelect = {};

  indicatorsProgresTemp = {};
  transactionTemp = {};
  historyDataSelfi = {};

  transactionSelected;
  transactionDetail;
  showFavorites;
  cvv;
  actives = true;
  constructor(
    private _httpService: HttpService,
    private _auth: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private i18n: I18nPipe,
    private _emailService: EmailService,
    public config: NgbDropdownConfig,
    private _error: ErrorService,
    private akeelaSectionService: AkeelaSectionService,
    private localStorage: LocalService,
    private datePipe: DatePipe,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    private _properties: PropertiesService,
    private _upload: UploadService,
    private _wfService: WorkflowService,
    private _wfStepService: AkeelaStepService,

    private paginatorService: PaginatorService
  ) {
    this.profile = this.localStorage.getItem("profile");
    if (!this.localStorage.getItem("queueDescriptionDefault")) {
      this.localStorage.setItem("queueDescription", "Clientes");
    }

    this.resetForm();
    config.placement = "bottom-right";
    config.autoClose = true;
  }

  closeReset() {
    this.resetForms();
    this.boxFilters = false;
    this.fieldSearch = "";
    this.filters = {};
    this.updateFilters();
  }

  public ngDoCheck() {
    if (screen.width < 769) {
      this.screenMax = false;
    } else {
      this.screenMax = true;
    }
  }

  setFilterMultiple() {
    if (Object.keys(this.formsData).length > 0) {
      let objFilters = this.temp;
      for (const temp of Object.keys(this.formsData)) {
        this.setFilter(temp, this.formsData[temp]);
      }
      console.log(this.filters);
      this.updateFilters();
    }
  }

  ngOnInit() {
    if (
      environment.theme.images === "MC" ||
      environment.theme.images === "RENDIVALORES" ||
      environment.theme.images === "KOI"
    ) {
      this.cvv = true;
    }

    this.historyDataSelect = {};
    this.historyDataSelfi = {};

    this.historyData["historyFavorites"] = {};
    this.historyData["historyNotes"] = {};

    this.paginator = {
      totalPages: 0,
      first: true,
      last: false,
      number: 0,
      pageable: {
        pageNumber: 0,
        from: null,
        to: null,
      },
    };

    this._properties.resetWks();
    // this.updateClients = await this._properties.updateClient$.toPromise();

    this._properties.updateClient$.subscribe((response: any) => {
      this.updateClients = response;
    });
    this.resetForms();
    this.listOptionAnalist = [
      {
        obj: {
          option: "reset_pass",
        },
        value: "Reiniciar Contraseña",
      },
      {
        obj: {
          option: "unlock_pass",
        },
        value: "Desbloquear Manualmente",
      },
      {
        obj: {
          option: "unactive_user",
        },
        value: "Desactivar Usuario",
      },
      {
        obj: {
          option: "email_verification_user",
        },
        value: "Reenviar Confirmación",
      },
      {
        obj: {
          option: "facial_recognition_user",
        },
        value: "Reconocimiento Facial (Activar/Desactivar)",
      },
    ];

    this.listOptionEjecutive = [
      {
        obj: {
          option: "reset_pass",
        },
        value: "Reiniciar Contraseña",
      },
      {
        obj: {
          option: "unlock_pass",
        },
        value: "Desbloquear Manualmente",
      },
      {
        obj: {
          option: "unactive_user",
        },
        value: "Desactivar Usuario",
      },
      {
        obj: {
          option: "email_verification_user",
        },
        value: "Reenviar Confirmación",
      },
      {
        obj: {
          option: "facial_recognition_user",
        },
        value: "Reconocimiento Facial (Activar/Desactivar)",
      },
    ];

    if (
      this.localStorage.getItem("workspaceReadonly") === "true" ||
      this.localStorage.getItem("readonly") === "true"
    ) {
      this.readonly = true;
    }

    this.form = this.formBuilder.group({
      filter: [""],
    });
    this.filter = {
      active: null,
      direction: null,
    };
    this.filter_order = {
      identifier: null,
      movement: null,
    };

    this.subscriptions.push(
      this.route.queryParams.subscribe((queryParams) => {
        this.queryParams = queryParams;
        this.getUserClient(this._auth.getUsername());
      })
    );

    this.getExecutives();
    this.getAgencys();
    this.getUser();
  }

  table_response(response) {
    if (response.action === "open") {
      let client = null;
      client = this.clients.find((wspc: any) => {
        return wspc.identifier === response.object.identifier;
      });
      this.showDetail(client);
    }

    if (response.action === "order") {
      this.filter_order = response.object;
      this.applyFilter(response.object);
    }

    if (response.action === "action") {
      if (response.object.action === "reset_pass") {
        this.reset_password(response.object.obj.client);
      }
      if (response.object.action === "unlock_pass") {
        this.unlokUser(response.object.obj.client);
      }
      if (response.object.action === "assign_executive") {
        this.showModalEjecutive(response.object.obj.client);
      }
      if (response.object.action === "unactive_user") {
        this.unactiveUser(response.object.obj);
      }
      if (response.object.action === "facial_recognition_user") {
        this.changeStatusFacialRecognition(
          this.clientDetail?.person?.facialRecognitionActive
        );
      }
    }

    if (response.action === "paginator") {
      this.action_paginator(response.object);
    }
  }

  getClient = async (username) => {
    const params = {
      name: "person$enduser$get",
      params: {
        request: {
          username: username,
        },
        path: {},
        body: {},
      },
    };
    return await this._httpService
      .executeRequest(params.name, params.params, true)
      .toPromise();
  };

  changeStatusFacialRecognition = async (client) => {
    const dclient: any = await this.getClient(client.identifier);
    if (isNullOrUndefined(dclient.adminOrganization)) {
      console.log(dclient.person?.facialRecognitionActive);
      let action = "";
      let valueSave = false;
      if (
        isNullOrUndefined(dclient.person?.facialRecognitionActive) ||
        dclient.person?.facialRecognitionActive === true
      ) {
        action = "Desactivar";
        valueSave = false;
      } else {
        action = "Activar";
        valueSave = true;
      }
      // action = isNullOrUndefined(dclient.person ?.facialRecognitionActive) || dclient.person ?.facialRecognitionActive !== true ? 'Activar' : 'Desactivar';
      swal
        .fire({
          title: "",
          html:
            "¿Desea <b>'" +
            action +
            "'</b> el reconocimiento facial del cliente?",
          showCancelButton: true,
          confirmButtonText: "Si",
        })
        .then(async (result) => {
          if (result.value) {
            this.wait();
            const params = {
              name: "person$active_facial_recognition$post",
              params: {
                request: {
                  idPerson: dclient.person.identifier,
                  active: valueSave,
                },
                path: {},
                body: {},
              },
            };
            const response = await this._httpService
              .executeRequest(params.name, params.params, true)
              .toPromise();
            this.updateClients = true;
            this.getClientsPaginator(this.paginator.page, 10);
            // this.getClientsPaginator(0, 5000000);
            swal.close();
          }
        });
    } else {
      swal.fire({
        title: "",
        html: "Reconocimiento facial solo disponible para Persona Natural",
      });
    }
  };

  unactiveUser(client) {
    swal
      .fire({
        title: "",
        html:
          "¿Desea desáctivar al usuario : <b>" + client.identifier + "</b>?",
        showCancelButton: true,
        confirmButtonText: "Si, desactivar",
      })
      .then((result) => {
        if (result.value) {
          this.wait();

          let params;

          params = {
            name: "person$inactivateclient$put",
            params: {
              request: {
                username: client.identifier,
                requestUser: this._auth.getUsername(),
              },
              path: {},
              body: {},
            },
          };

          this._httpService
            .executeRequest(params.name, params.params, true)
            .subscribe(
              (response: any) => {
                swal.close();
                this.getClientsPaginator(this.paginator.page, 10);
                // this.getClientsPaginator(0, 5000000);
              },
              (error) => {
                this._error.show(error);
              }
            );
        }
      });
  }

  emailVerificationUser(client) {
    swal
      .fire({
        title: "",
        html: "<b>¿Desea reenviar correo de confirmación </b>?",
        showCancelButton: true,
        confirmButtonText: "Si, reenviar",
      })
      .then((result) => {
        if (result.value) {
          this.wait();

          let params;

          params = {
            name: "person$emailVerification$post",
            params: {
              request: {
                username: client.identifier,
              },
              path: {},
              body: {},
            },
          };

          this._httpService
            .executeRequest(params.name, params.params, true)
            .subscribe(
              (response: any) => {
                swal.close();
                swal.fire({
                  title: this.i18n.transform("Éxito"),
                  html: this.i18n.transform("Correo de confirmación reenviado"),
                });
              },
              (error) => {
                swal.close();

                if (error.error) {
                  this._error.show(error);
                }
              }
            );
        }
      });
  }

  close() {
    this.objModal = null;
  }

  showModalEjecutive(client) {
    this.objModal = true;
    this.buttomModal = false;
    this.clientEjecutive = client;
    console.log("Ingreso al show modal");
  }

  resetForm() {
    // this.akeelaSectionService.externalResetSectionForm$.next('CREATE_USER_NEW_CLIENT');
    const obj = {
      section: "CREATE_USER_NEW_CLIENT",
      response: {
        applicantType: "LEGAL",
        endUser: {
          salesManagerUser: {
            localBusiness: {
              name: "",
            },
          },
          person: {
            givenName: "",
            familyName: "",
            email: "",
          },
          identifier: "",
        },
        organization: {
          address: {
            addressCountry: {
              identifier: "",
            },
          },
          legalName: "",
          tradeName: "",
          legalForm: {
            name: "",
          },
          taxID: "",
        },
        systemUser: "",
      },
    };
    this.akeelaSectionService.externalUpdateSectionForm$.next(obj);
  }

  applyFilter(event: any) {
    this.loading_table = true;
    this.filter.identifier = event.identifier;
    this.filter.movement = event.movement;
    // this.getClientsPaginator(0, 5000000);
    this.getClientsPaginator(this.paginator.page, 10);
  }

  newContact() {
    this._wfStepService.setModeCurrentStep(false);
    this.localStorage.setItem("clientaudience", "LEGAL");

    const queryParams = {
      role: this.localStorage.getItem("role"),
    };

    this.router.navigateByUrl(
      this.router.createUrlTree(
        [
          "private/ACCOUNT_OPENING/stages/CREATE_USER_ACCOUNT_OPENING/steps/CREATE_USER_ACCOUNT_OPENING",
        ],
        {
          queryParams: queryParams ? queryParams : {},
        }
      )
    );
    // this.router.navigateByUrl(
    //   this.router.createUrlTree(['private/ACCOUNT_OPENING/stages/CREATE_USER_ACCOUNT_OPENING/steps/CREATE_USER_ACCOUNT_OPENING'],
    //     {
    //       queryParams: queryParams
    //     }
    //   )
    // );
  }

  reset_password(client: any) {
    swal
      .fire({
        title: "",
        html:
          "¿Desea reiniciar la contraseña del usuario : <b>" +
          client.identifier +
          "</b>?",
        showCancelButton: true,
        confirmButtonText: "Si, restablecer",
      })
      .then((result) => {
        if (result.value) {
          this.wait();

          let params;

          params = {
            name: "person$reset_password_client$put",
            params: {
              request: {},
              path: {},
              body: {
                identifier: client.identifier,
              },
            },
          };

          this._httpService
            .executeRequest(params.name, params.params, true)
            .subscribe(
              (response: any) => {
                //  console.log("response: ", response);
                this.sendMailConfirmation(response.identifier);
              },
              (error) => {
                swal.close();

                console.log("error al response reset user");
              }
            );
        }
      });
  }

  unlokUser(client: any) {
    swal
      .fire({
        title: "",
        html:
          "¿Desea desbloquear la Cuenta de usuario : <b>" +
          client.identifier +
          "</b>?",
        showCancelButton: true,
        confirmButtonText: "Si, desbloquear",
      })
      .then((result) => {
        if (result.value) {
          this.wait();

          let params;

          params = {
            name: "person$unlock$put",
            params: {
              request: {},
              path: {
                username: client.identifier,
                systemuser: this._auth.getUsername(),
              },
              body: {},
            },
          };

          this._httpService
            .executeRequest(params.name, params.params, true)
            .subscribe(
              (response: any) => {
                swal.close();
                //  console.log("response: ", response);
                swal.fire({
                  reverseButtons: true,
                  confirmButtonText: "Ok",
                  html: "Desbloqueo manual éxitoso",
                });
                // Mario: Comentado a solicitud de Vincent, por la migraión del mail al backend
                // this.sendMailUnlock(response);
              },
              (error) => {
                swal.close();
                this._error.show(error);
              }
            );
        }
      });
  }

  wait() {
    swal.fire({
      title: "",
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false,
      html:
        "<b>" +
        this.i18n.transform("@i18n-text-please-wait") +
        '...<b><br><i class="fa fa-spin fa-spinner"></i>',
    });
  }

  sendMailConfirmation(username) {
    const params = {
      name: "person$recovery-password$post",
      params: {
        request: {
          username: username,
        },
        path: {},
        body: {},
      },
    };
    this._httpService
      .executeRequest(params.name, params.params, true)
      .subscribe((response: any) => {
        swal.close();
      });
  }

  sendMailRecovery(data) {
    const email = data.person.email;
    // const email =  'juan@hazling.com';
    const token = data.name;
    const name = "";
    const username = data.identifier;
    const origin = window.location.hostname;
    const url =
      environment.urlClient + "/create-new-password/" + username + "/" + token;
    const portal = environment.theme.site.name;
    const properties: object = {};
    const site_email = environment.theme.site.supportMail;
    properties["template"] = "templates/es/recoverypassword.html";

    let site_header = environment.theme.site.name;
    if (!isNullOrUndefined(environment.theme.image)) {
      if (!isNullOrUndefined(environment.theme.image.active)) {
        if (environment.theme.image.active) {
          site_header =
            '<img src="' + environment.theme.image.urlImageHeader + '" ></img>';
        }
      }
    }

    properties["attr"] = [
      { key: "%%URL%%", value: url },
      {
        key: "%%URLDESCRIPTION%%",
        value: this.i18n.transform("@i18n-mail-verify-account"),
      },
      { key: "%%NOMBRE%%", value: name },
      { key: "%%USERNAME%%", value: username },
      { key: "%%SITENAME%%", value: portal },
      { key: "%%SITEEMAIL%%", value: environment.theme.site.supportMail },
      {
        key: "%%FONT_COLOR%%",
        value: environment.theme.fontColor,
      },
      {
        key: "%%BACKGROUND_COLOR%%",
        value: environment.theme.backgroundColor,
      },
      {
        key: "%%PROCEDURE%%",
        value: environment.procedureMail,
      },
      {
        key: "%%BACKGROUND_BORDER%%",
        value: environment.theme.backgroundColorBorderMail,
      },
      {
        key: "%%ALIAS_APP%%",
        value: site_header,
      },
      {
        key: "%%TEXTFOOTER%%",
        value: !isNullOrUndefined(environment.theme.textFooterMail)
          ? environment.theme.textFooterMail
          : "",
      },
    ];
    const email_message = {
      identifier: 1,
      dateOfThing: {},
      status: null,
      sender: null,
      text: null,
      type: "text/html",
      subjectOf: portal,
      toRecipient: email,
    };
    const template = this._emailService.getTemplate(properties);
    template.subscribe(
      (template_mail) => {
        const dataMail = this._emailService.configureTemplate(
          template_mail,
          properties["attr"]
        );
        const template_data = { properties, template_mail };
        template_mail = dataMail;
        const response = this._emailService.sendMail(
          email_message,
          template_data.properties,
          template_data.template_mail
        );
        response.subscribe(
          (mail_result) => {
            swal.fire({
              title: this.i18n.transform("@i18n-text-mail-success"),
              html:
                "<b>" +
                this.i18n.transform(
                  "@i18n-text-mail-success-recovery-password-client"
                ) +
                "</b>",
            });
          },
          (error) => {
            swal.fire({
              title: "Warning",
              html: "<b>No se pudo enviar el correo de recuperación. Vuelve a intentarlo más tarde..</b>",
            });
          }
        );
      },
      (error) => {
        swal.fire({
          title: "Warning",
          html: "<b>No se pudo enviar el correo de recuperación. Vuelve a intentarlo más tarde..</b>",
        });
      }
    );
  }

  sendMailUnlock(data) {
    const email = data.person.email;
    // const email =  'juan@hazling.com';
    const token = data.name;
    const name = "";
    const username = data.identifier;
    const origin = window.location.hostname;
    const url =
      environment.urlClient + "/create-new-password/" + username + "/" + token;
    const portal = environment.theme.site.name;
    const properties: object = {};
    const site_email = environment.theme.site.supportMail;
    properties["template"] = "templates/es/unlockandrecoverypassword.html";

    let site_header = environment.theme.site.name;
    if (!isNullOrUndefined(environment.theme.image)) {
      if (!isNullOrUndefined(environment.theme.image.active)) {
        if (environment.theme.image.active) {
          site_header =
            '<img src="' + environment.theme.image.urlImageHeader + '" ></img>';
        }
      }
    }

    properties["attr"] = [
      { key: "%%URL%%", value: url },
      {
        key: "%%URLDESCRIPTION%%",
        value: this.i18n.transform("@i18n-mail-verify-account"),
      },
      { key: "%%NOMBRE%%", value: name },
      { key: "%%USERNAME%%", value: username },
      { key: "%%SITENAME%%", value: portal },
      { key: "%%SITEEMAIL%%", value: environment.theme.site.supportMail },
      {
        key: "%%FONT_COLOR%%",
        value: environment.theme.fontColor,
      },
      {
        key: "%%BACKGROUND_COLOR%%",
        value: environment.theme.backgroundColor,
      },
      {
        key: "%%PROCEDURE%%",
        value: environment.procedureMail,
      },
      {
        key: "%%BACKGROUND_BORDER%%",
        value: environment.theme.backgroundColorBorderMail,
      },
      {
        key: "%%ALIAS_APP%%",
        value: site_header,
      },
      {
        key: "%%TEXTFOOTER%%",
        value: !isNullOrUndefined(environment.theme.textFooterMail)
          ? environment.theme.textFooterMail
          : "",
      },
    ];
    const email_message = {
      identifier: 1,
      dateOfThing: {},
      status: null,
      sender: null,
      text: null,
      type: "text/html",
      subjectOf: portal,
      toRecipient: email,
    };
    const template = this._emailService.getTemplate(properties);
    template.subscribe(
      (template_mail) => {
        const dataMail = this._emailService.configureTemplate(
          template_mail,
          properties["attr"]
        );
        const template_data = { properties, template_mail };
        template_mail = dataMail;
        const response = this._emailService.sendMail(
          email_message,
          template_data.properties,
          template_data.template_mail
        );
        response.subscribe(
          (mail_result) => {
            swal.fire({
              title: this.i18n.transform("@i18n-text-mail-success"),
              html:
                "<b>" +
                this.i18n.transform(
                  "@i18n-message-user-manual-unlocking-success"
                ) +
                "</b>",
            });
          },
          (error) => {
            swal.fire({
              title: "Warning",
              html: "<b>No se pudo enviar el correo de desbloqueo y  recuperación. Vuelve a intentarlo más tarde..</b>",
            });
          }
        );
      },
      (error) => {
        swal.fire({
          title: "Warning",
          html: "<b>No se pudo enviar el correo de desbloqueo y recuperación. Vuelve a intentarlo más tarde..</b>",
        });
      }
    );
  }

  action_paginator(page) {
    this.loading_table = true;
    if (page === "NEXT") {
      page = this.paginator.pageable.pageNumber + 1;
      page = this.paginator.pageable.pageNumber + 1;
      if (page >= this.paginator.totalPages) {
        page = this.paginator.pageable.pageNumber;
      }
    } else if (page === "BACK") {
      page = this.paginator.pageable.pageNumber - 1;
      if (page < 0) {
        page = this.paginator.pageable.pageNumber;
      }
    }
    this.paginator.pageable.pageNumber = page;
    this.paginator.first = page === 0 ? true : false;
    this.paginator.last = page + 1 === this.paginator.totalPages ? true : false;
    this.getClientsPaginator(page, 10);
    this.setTablePaginators();
  }

  getClientsPaginator = async (page?, size?, sort_by?, sort_type?) => {
    this.loading_table = true;
    this.rows = [];
    let params;
    this.actives;
    let body = {
      identifier: this._auth.getUsername(),
    };

    if (this.filters["ejecutivo"]) {
      if (this.filters["ejecutivo"]["key"] !== "") {
        body["salesManagerUser"] = {};
        body["salesManagerUser"]["identifier"] =
          this.filters["ejecutivo"]["key"];
      }
    }

    if (this.filters["tipo"]) {
      if (this.filters["tipo"]["key"] !== "") {
        body["personMarketType"] = {};
        body["personMarketType"]["name"] = this.filters["tipo"]["key"];
      }
    }
    body["person"] = {};

    if (this.fieldSearch.trim() !== "") {
      body["person"]["email"] = this.fieldSearch;
    }

    if (this.fieldSearch.trim() !== "") {
      body["person"]["identity"] = this.fieldSearch;
    }

    // if(this.filters['verifiedIdentification']) {
    //   if(this.filters['verifiedIdentification']['key']!=="") {
    //     body['personMarketType'] = {};
    //     body['personMarketType']['identifier'] = this.filters['verifiedIdentification']['key'];
    //   }
    // }

    console.log(this.filters);
    if (
      this.localStorage.getItem("profile") === "ANALIST" ||
      this.superUser == true
    ) {
      const request = {
        page: this.paginator.pageable.pageNumber,
        size: 10,
      };

      params = {
        name: this.actives
          ? "person$clients_paginate$post"
          : "person$inactive_endusers$get",
        params: {
          request: request,
          path: {},
          body,
        },
      };
    } else {
      const request = {
        page: this.paginator.pageable.pageNumber,
        size: this.paginator.totalPages,
      };

      console.log(this.filters);

      params = {
        name: this.actives
          ? "person$clients_paginate$post"
          : "person$inactive_endusers$get",
        params: {
          request: request,
          path: {},
          body,
        },
      };
    }

    // añado al request si es del tipo con transacciones o sin transacciones...
    if (this.queryParams["customersWithTransactions"]) {
      params.params.request["hasTransactions"] = true;
    } else if (this.queryParams["customersWithoutTransactions"]) {
      params.params.request["hasTransactions"] = false;
    } else {
      this._properties.updateClient$.next(true);
    }

    // this.paginator = null;
    this.loading_table = true;

    try {
      let response: any = await this._httpService
        .executeRequest(params.name, params.params, true)
        .toPromise();
      this._properties.setUpdateClient(false);
      this.updateClients = false;
      if (this.localStorage.getItem("profile") === "SALES_MANAGER") {
        this.executive_name =
          this._auth.getSession().firstName +
          " " +
          this._auth.getSession().lastName;
      }

      // filtro status...
      /*const clients_filter = response.filter((client) => {
       return client.status === 'ACTIVE';
     });*/

      const clients_filter = response.content;

      this.clients = clients_filter;
      this.clients_backup = clients_filter;

      console.log(response);
      //  this.paginator.totalPages = response.totalPages;
      //  this.paginator.number = response.number;

      this.setPaginator(response);

      this.setConfigurationTableNew();
      if (!this.isAnalist) {
        params = {
          name: "person$endusers_profile$get",
          params: {
            request: {
              profile: "SALES_MANAGER",
            },
            path: {},
            body: {},
          },
        };
        this.subscriptions.push(
          this._httpService
            .executeRequest(params.name, params.params, true)
            .subscribe((executives: any) => {
              this.executives = executives;
              this.setConfigurationTableEjecutive();
            })
        );
      }
    } catch (error) {
      this._error.show(error);
      this.paginator = null;
      this.clients = [];
      this.executives = [];
      this.loading_table = false;
    }
  };

  getInitials(givenName: string, familyName: string) {
    return (
      (givenName ? givenName.substr(0, 1).toUpperCase() : "") +
      (familyName ? familyName.substr(0, 1).toUpperCase() : "")
    );
  }

  getInitial(text) {
    let retorno = "";
    if (!isNullOrUndefined(text)) {
      retorno = text.substr(0, 1).toUpperCase();
    }
    return retorno;
  }

  getFirstLetter(data: string) {
    return data ? data.substr(0, 1).toUpperCase() : "";
  }

  search_clients(evt) {
    this.clients = this.clients_backup;
    let search_text = "";
    if (evt.key === "Backspace") {
      search_text = this.form
        .get("filter")
        .value.substring(0, this.form.get("filter").value.length - 1);
    } else {
      search_text = this.form.get("filter").value + evt.key;
    }
    const aux = this.clients;

    this.clients = this.clients.filter((client: any) => {
      let first_search = "";
      let second_search = "";

      if (client.adminOrganization) {
        if (client.adminOrganization.legalName) {
          first_search = client.adminOrganization.legalName;
        } else {
          first_search = client.person.givenName;
          second_search = client.person.familyName;
        }
      } else {
        first_search = client.person.givenName;
        second_search = client.person.familyName;
      }

      return (
        first_search.toUpperCase().indexOf(search_text.toUpperCase()) !== -1 ||
        second_search.toUpperCase().indexOf(search_text.toUpperCase()) !== -1
      );
    });
  }

  showDetail(client) {
    if (!this.loading_table && client) {
      let idPerson = "";
      if (client.adminOrganization) {
        idPerson = client.adminOrganization.identifier;
      } else {
        idPerson = client.person.identifier;
      }

      let aux;
      if (client.adminOrganization) {
        aux = Object.assign(
          {
            audience: "LEGAL",
            viewUsername: client.identifier,
            viewIdentifierContact: client.adminOrganization.identifier,
          },
          this.queryParams
        );
      } else {
        aux = Object.assign(
          {
            audience: "NATURAL",
            viewUsername: client.identifier,
            viewIdentifierContact: client.person.identifier,
          },
          this.queryParams
        );
      }

      this.router.navigateByUrl(
        this.router.createUrlTree([environment.staticLinks.viewClient], {
          queryParams: aux,
        })
      );
    }
  }

  showFormalities(username: string, client) {
    let idPerson = "";
    if (client.adminOrganization !== null) {
      idPerson = client.adminOrganization.identifier;
    } else {
      idPerson = client.person.identifier;
    }
  }

  assignExecutive(client, systemuser) {
    this.objModal = null;

    const params = {
      name: "person$associate_client_systemuser$post",
      params: {
        headers: {
          "Content-Type": "application/json",
        },
        request: {
          username: client,
          systemUser: systemuser,
        },
        path: {},
        body: {},
      },
    };
    this.subscriptions.push(
      this._httpService
        .executeRequest(params.name, params.params, true)
        .subscribe((response: any) => {
          this.clients = false;
          this.getClientsPaginator(this.paginator.page, 10);
          swal.fire({
            title: "Éxito",
            html: "<b>El cliente fue asignado al ejecutivo exitosamente</b>",
          });
        })
    );
  }

  onCambioFn() {
    this.temp2 = !this.temp2;
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      // prevent memory leak when component destroyed
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
  }

  onActivate(event) {
    if (event.type == "click" && event.row.identifier) {
      if (event && event.value !== "") {
        let client = null;
        client = this.clients.find((wspc: any) => {
          return wspc.identifier === event.row.identifier;
        });

        this.showDetail(client);
      }
    }
  }

  onActivateEjecutive(event) {
    if (event.type == "click") {
      //

      if (event) {
        this.assignExecutive(
          this.clientEjecutive.identifier,
          event.row.identifier
        );
      }
    }
  }

  setFilter(name, obj) {
    this.filters[name] = obj;
  }

  updateFilters() {
    let data = [];

    this.fieldSearch = "";

    if (Object.keys(this.filters).length > 0) {
      let objFilters = this.temp;

      for (const temp of Object.keys(this.filters)) {
        let type = this.filters[temp].type;

        if (type == "date") {
          let { dateInit, dateEnd, delimit } = this.filters[temp];
          objFilters = [
            ...this.filterDate(objFilters, temp, dateInit, dateEnd, delimit),
          ];
        } else if (type == "string") {
          let { key } = this.filters[temp];

          if (key.trim() != "") {
            objFilters = [...this.filterSting(objFilters, temp, key)];
          }
        }
      }

      data = [...objFilters];
    } else {
      let objFilters = this.temp;
      data = [...objFilters];
    }

    if (this.showFavorites) {
      data = [
        ...data?.filter((result) => {
          return this.historyData["historyFavorites"][result?.username];
        }),
      ];
    }

    console.log(this.filters);
    // this.update_transactionsFilter(data);

    // this.rows = this.paginatorService.getDataPaginator(this.paginator, this.env, data);
    this.filtersLinks();
    this.getClientsPaginator(0, 10);
  }

  filtersLinks() {
    //
    //this.fieldSearch = '';
    let tempFilterEtique = [];

    if (Object.keys(this.filters).length > 0) {
      let objFilters = this.temp;

      for (const temp of Object.keys(this.filters)) {
        let tempObj = {};

        let type = this.filters[temp].type;

        if (type == "date") {
          let { dateInit, dateEnd, delimit } = this.filters[temp];
          if (dateInit.trim() != "") {
            tempObj = { label: "Desde: " + dateInit, key: "dateInit", type };
            tempFilterEtique = [...tempFilterEtique, tempObj];
          }

          if (dateEnd.trim() != "") {
            tempObj = { label: "Hasta: " + dateEnd, key: "dateEnd", type };
            tempFilterEtique = [...tempFilterEtique, tempObj];
          }
        } else if (type == "string") {
          let { key } = this.filters[temp];

          if (key.trim() != "") {
            let label = this.filters[temp].key;

            if (temp == "completeIdentification") {
              label =
                (label == "true" ? "Con " : "Sin ") +
                "verificación de identidad aprobada";
            } else if (temp == "verifiedIdentification") {
              label =
                (label == "true" ? "Con " : "Sin ") +
                "verificación de identidad completada";
            } else if (temp == "confirm") {
              label =
                (label == "true" ? "Con " : "Sin ") + "verificación de email";
            } else if (temp == "solicitudes") {
              label = (label == "true" ? "Con " : "Sin ") + "solicitudes";
            }

            tempObj = { label: label, key: temp, type };
            tempFilterEtique = [...tempFilterEtique, tempObj];
          }
        }
      }

      this.filterEtique = [...tempFilterEtique];
    }
  }

  deleteFilter(key, type) {
    let filtered = this.filterEtique.filter(function (value, index, arr) {
      return value.key != key;
    });

    if (type == "date") {
      if (
        Object.keys(this.formsData).length > 0 &&
        this.formsData["fecha"] &&
        this.formsData["fecha"][key]
      ) {
        this.formsData["fecha"][key] = "";
        this.updateFilters();
      }
    } else if (type == "string") {
      if (
        Object.keys(this.formsData).length > 0 &&
        this.formsData[key] &&
        this.formsData[key].key
      ) {
        this.formsData[key].key = "";
        this.updateFilters();
      }
    }

    this.filterEtique = [...filtered];
  }

  filterDate(obj = [], name, dateInit, dateEnd, delimit) {
    if (
      (!dateInit || dateInit.trim() == "") &&
      (!dateEnd || dateEnd.trim() == "")
    ) {
      return obj;
    }

    let i = dateInit.split("-");
    let letDateInit = new Date(Number(i[0]), Number(i[1]) - 1, Number(i[2]));
    let f = dateEnd.split("-");
    let letDateEnd = new Date(Number(f[0]), Number(f[1]) - 1, Number(f[2]));

    const temp = obj.filter(function (d) {
      let fecha = d[name];
      let m = fecha.split(delimit);
      let letFecha = new Date(Number(m[2]), Number(m[1]) - 1, Number(m[0]));

      if (
        name &&
        letFecha instanceof Date &&
        dateInit &&
        letDateInit instanceof Date &&
        dateEnd &&
        letFecha instanceof Date
      ) {
        return (
          letFecha.getTime() >= letDateInit.getTime() &&
          letFecha.getTime() <= letDateEnd.getTime()
        );
      } else if (
        name &&
        letFecha instanceof Date &&
        !(dateInit && letDateInit instanceof Date) &&
        dateEnd &&
        letFecha instanceof Date
      ) {
        return letFecha.getTime() <= letDateEnd.getTime();
      } else if (
        name &&
        letFecha instanceof Date &&
        dateInit &&
        letDateInit instanceof Date &&
        !(dateEnd && letFecha instanceof Date)
      ) {
        return letFecha.getTime() >= letDateInit.getTime();
      }
    });

    return temp;
  }

  filterSting(obj = [], name, key) {
    const val = !isNullOrUndefined(key) ? key.toString().toLowerCase() : null;

    const temp = obj.filter(function (d) {
      let value = d[name];
      return value.toString().toLowerCase().indexOf(val) !== -1;
    });

    return temp;
  }

  updateFilterAll(data) {
    const val = !isNullOrUndefined(data) ? data.toString().toLowerCase() : null;

    let anchorSearch = this.anchorSearch;

    // filter our data

    let arrayBase = this.temp;

    if (anchorSearch.length > 0 && this.rows.length > 0) {
      arrayBase = this.temp;
    }

    const temp = arrayBase.filter(function (d) {
      let filter = false;

      let tempAnchor = false;

      for (let clave in d) {
        let value = d[clave];

        if (!filter) {
          if (anchorSearch.length > 0) {
            anchorSearch.forEach(function (data) {
              if (!tempAnchor) {
                if (
                  value &&
                  value.c &&
                  value.toString().toLowerCase() &&
                  value.toString().toLowerCase().indexOf(data) !== -1
                ) {
                  tempAnchor = true;
                }
              }
            });
          }

          if (!isNullOrUndefined(data)) {
            if (anchorSearch.length > 0) {
              filter =
                tempAnchor ||
                (!isNullOrUndefined(value) &&
                  value.toString() &&
                  value.toString().toLowerCase() &&
                  value.toString().toLowerCase().indexOf(val) !== -1);
            } else {
              filter =
                (!isNullOrUndefined(value) &&
                  value.toString() &&
                  value.toString().toLowerCase() &&
                  value.toString().toLowerCase().indexOf(val) !== -1) ||
                !val;
            }
          } else {
            if (anchorSearch.length > 0) {
              filter = tempAnchor;
            } else {
              filter = true;
            }
          }
        }
      }

      return filter;
    });

    // update the rows
    this.rows = temp;
    this.getAvatars(this.rows);
    this.getUsersTransactions(this.rows);

    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;

    this.loadingIndicator = false;
  }

  setConfigurationTableNew() {
    this.columns = [
      { prop: "identifier" },
      { prop: "nombre", name: "Nombre" },
      { prop: "tipo", name: "Tipo" },
      { prop: "correo", name: "Corrreo" },
      { name: "Ejecutivo" },
      { prop: "fecha", name: "Fecha Creación" },
      { name: "Solicitudes" },
      { prop: "telephone", name: "Teléfono" },
    ];
    let datas = [];
    let data = [];
    const content: Row[] = [];
    this.akDropdown = [];
    let i = 0;

    for (const row of this.clients) {
      let icon = "";
      let initials = this.getInitials(
        !isNullOrUndefined(row.adminOrganization)
          ? row.adminOrganization.legalName
          : row.person.givenName,
        !isNullOrUndefined(row.adminOrganization) ? "" : row.person.familyName
      );
      let initial = this.getInitial(
        row.adminOrganization
          ? row.adminOrganization.legalName
          : row.person.givenName
      );
      this.akinitial[row.identifier] = { initial, initials };
      const name = !isNullOrUndefined(row.adminOrganization)
        ? row.adminOrganization.legalName
        : row.person.givenName + " " + row.person.familyName;
      const confirm = !isNullOrUndefined(row.mailConfirmationDate)
        ? '<i class="fas fa-check ml-2 text-primary small" ></i>'
        : "";

      /*Menu de opciones*/
      let jsonTmp = {
        identifier: row.identifier,
        doc: row?.person?.identity,
        typeDoc: row?.person?.additionalType,
        verifiedIdentification: row?.person?.verifiedIdentification
          ? true
          : false,
        completeIdentification: row?.person?.completeIdentification
          ? true
          : false,
        nombre: name,
        tipo: row.personMarketType.audienceType,
        correo: row.person.email,
        ejecutivo: row.salesManagerUser
          ? row.salesManagerUser?.identifier
          : "-",
        telephone: row?.person.telephone,
        otherPhone: row?.person?.otherPhone,

        fecha: this.datePipe.transform(row.createDate, "dd/MM/yyyy"),
        solicitudes: row.countTransactions > 0 ? true : false,
        confirm: !isNullOrUndefined(row.mailConfirmationDate),
        username: row.identifier,
        status: row.status,
      };

      datas = [...datas, jsonTmp];
    }

    for (const row of datas) {
      /*Menu de opciones*/
      let jsonTmp = {
        identifier: row.identifier,
        nombre: row.nombre,
        doc: row.doc,
        typeDoc: row.typeDoc,
        verifiedIdentification: row?.verifiedIdentification,
        completeIdentification: row?.completeIdentification,
        tipo: row.tipo,
        correo: row.correo,
        ejecutivo: row.ejecutivo,
        fecha: row.fecha,
        solicitudes: row.solicitudes,
        confirm: row.confirm,
        username: row.username,
        telephone: row.telephone,
        otherPhone: row?.otherPhone,
        status: row.status,
      };

      if (!row.confirm) {
        this.client_indicators.notConfirm =
          1 + this.client_indicators.notConfirm;
      }

      if (!row.solicitudes) {
        this.client_indicators.notTransaction =
          1 + this.client_indicators.notTransaction;
      }

      if (!row.completeIdentification) {
        this.client_indicators.notVerif = 1 + this.client_indicators.notVerif;
      }

      data = [...data, jsonTmp];
    }

    this.client_indicators.loading = false;

    // cache our list
    this.temp = [...data];

    this.clients = this.temp;
    // push our inital complete list

    //data = this.paginatorService.getDataPaginator(this.paginator, this.env, data);

    // this.paginator.number = this.clients ?.length;
    // this.paginator.totalPages = Math.ceil(this.clients ?.length / this.env.paginator.size);
    // this.paginator.pageable.to = this.paginatorService.getPaginatorTo(this.paginator, this.env);
    // this.paginator.pageable.from = this.paginatorService.getPaginatorFrom(this.paginator, this.env);

    this.rows = data;
    this.getAvatars(this.rows);
    this.getUsersTransactions(this.rows);
  }

  setPaginator(response) {
    this.paginator.number = response.totalElements;
    this.paginator.totalPages = response.totalPages;
    this.paginator.pageable.to =
      response.number * response.numberOfElements + response.numberOfElements;
    this.paginator.pageable.from =
      response.number * response.numberOfElements + 1;
  }

  setTablePaginators() {
    this.loading_table = true;
    let data = this.clients;

    this.client_indicators.loading = false;

    //data = this.paginatorService.getDataPaginator(this.paginator, this.env, data);

    this.rows = data;
    this.getAvatars(this.rows);
    this.getUsersTransactions(this.rows);
  }

  setDelete(i) {
    delete this.anchorSearch[i];
    this.anchorSearch = [...this.anchorSearch.filter((el) => el != null)];
    this.fieldSearch = null;
    this.updateFilterAll(null);
  }

  anchor() {
    if (this.fieldSearch && this.fieldSearch != "") {
      let tmp = this.fieldSearch;
      this.anchorSearch = [...this.anchorSearch, tmp];
    }

    this.fieldSearch = "";
    this.updateFilterAll(null);
  }

  response_action(json) {
    let client = null;
    console.log(json);

    client = this.clients.find((wspc: any) => {
      return wspc.identifier === json.identifier;
    });
    console.log(client);

    if (json.action === "reset_pass") {
      this.reset_password(client);
    }
    if (json.action === "unlock_pass") {
      this.unlokUser(client);
    }
    if (json.action === "assign_executive") {
      console.log("ingreso acá");
      this._properties.setUpdateClient(true);

      this.showModalEjecutive(client);
    }
    if (json.action === "unactive_user") {
      this._properties.setUpdateClient(true);
      this.unactiveUser(client);
    }

    if (json.action === "email_verification_user") {
      this._properties.setUpdateClient(true);

      this.emailVerificationUser(client);
    }

    if (json.action === "email_verification_user") {
      this._properties.setUpdateClient(true);

      this.emailVerificationUser(client);
    }

    if (json.action === "facial_recognition_user") {
      this._properties.setUpdateClient(true);

      this.changeStatusFacialRecognition(client);
    }

    //this.buttomModal = false;
  }

  dateComparator(dateInit, dateEnd, rowA, rowB) {
    let i = dateInit.split("/");
    let letDateInit: any = new Date(
      Number(i[2]),
      Number(i[1]) - 1,
      Number(i[0])
    );
    let f = dateEnd.split("/");
    let letDateEnd: any = new Date(
      Number(f[2]),
      Number(f[1]) - 1,
      Number(f[0])
    );

    if (letDateInit instanceof Date && letDateEnd instanceof Date) {
      if (letDateInit.getTime() < letDateEnd.getTime()) return -1;
      if (letDateInit.getTime() > letDateEnd.getTime()) return 1;
    }
  }

  setConfigurationTableEjecutive() {
    let datas = [];
    let data = [];
    let i = 0;

    for (const row of this.executives) {
      let icon = "";
      let initials = this.getInitials(
        row.person.givenName,
        row.person.familyName
      );
      let initial = this.getInitial(row.person.givenName);

      this.akinitial_executive[row.identifier] = { initial, initials };

      let jsonTmp = {
        identifier: row.identifier,
        nombre: row.person.givenName + " " + row.person.familyName,
        agencia: row.localBusiness.name,
      };

      data = [...data, jsonTmp];
    }

    // cache our list
    this.temp_executive = [...data];
    // push our inital complete list
    this.rows_executive = data;
  }

  updateFilterEjecutive(data) {
    const val = !isNullOrUndefined(data) ? String(data).toLowerCase() : null;

    // filter our data
    let arrayBase = this.temp_executive;

    const temp = arrayBase.filter(function (d) {
      let filter = false;
      let tempAnchor = false;

      for (let clave in d) {
        let value = d[clave];

        if (!filter) {
          filter = String(value).toLowerCase().indexOf(val) !== -1 || !val;
        }
      }

      return filter;
    });
    //

    // update the rows
    this.rows_executive = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  /********************************************* */

  togle_window(filter) {
    if (filter == 1) {
      this.filterBy = true;
      this.orderBy = false;
    } else {
      this.filterBy = false;
      this.orderBy = true;
    }

    this.animate_init = false;
    this.openFilter = !this.openFilter;
  }

  //HERE
  updateFilter(data) {
    this.paginator = {
      totalPages: 0,
      first: true,
      last: false,
      number: 0,
      pageable: {
        pageNumber: 0,
        from: null,
        to: null,
      },
    };
    let data2 = [];
    this.boxFilters = false;

    this.loadingIndicator = true;
    const val = !isNullOrUndefined(data) ? String(data).toLowerCase() : null;
    this.fieldSearch = val;
    if (!isNullOrUndefined(val) && val !== "") {
      // filter our data

      // let arrayBase = this.temp;
      // const temp = arrayBase.filter(function (d) {
      //   let filter = false;
      //   let tempAnchor = false;
      //   for (let clave in d) {
      //     if(
      //       clave == 'doc' ||
      //       clave == 'ejecutivo' ||
      //       clave == 'correo' ||
      //       clave == 'fecha' ||
      //       clave == 'otherPhone' ||
      //       clave == 'telephone' ||
      //       clave == 'nombre'){
      //     let value = d[clave];
      //     if (!filter ) {
      //       filter = String(value).toLowerCase().indexOf(val) !== -1 || !val;
      //     }
      //     }

      //   }

      //   return filter;

      // });

      // // update the rows
      // data2 = temp;
      // this.update_transactionsFilter(data2);

      // this.rows = this.paginatorService.getDataPaginator(this.paginator, this.env, data2);

      this.getClientsPaginator(0, 10);
      this.loadingIndicator = false;
    } else {
      this.getClientsPaginator(0, 10);
      this.loadingIndicator = false;
      //data2 = this.temp;
    }
  }

  update_transactionsFilter(clients, loading?) {
    this.paginator = {
      totalPages: Math.ceil(clients.length / this.env.paginator.size),
      first: true,
      last: false,
      number: clients.length,
      pageable: {
        pageNumber: 0,
        from: 1,
        to:
          clients.length < this.env.paginator.size
            ? clients.length
            : this.env.paginator.size,
      },
    };

    if (loading) {
      this.clients = clients;

      //   this.object_search = transactions.object;

      this.loading_table = false;
      this.reload_loading = false;
    } else {
      if (clients) {
        this.clients = clients;
        this.loading_table = false;
        this.reload_loading = false;
      } else {
        this.clients = [];
        this.paginator.pageable.to = 0;
        this.paginator.pageable.from = 0;

        this.loading_table = false;
        this.reload_loading = false;
      }
    }
  }

  setClient(client) {
    this.clientSelected = null;
    this.clientDetail = false;
    setTimeout(() => {
      this.clientDetail = true;
      this.clientSelected = client;
    }, 10);
  }

  resetForms() {
    this.stateCodeFilter = "";

    this.formsData = {
      ejecutivo: { type: "string", key: "" },
      agencia: { type: "string", key: "" },
      confirm: { type: "string", key: "" },
      status2: { type: "string", key: "" },
      completeIdentification: { type: "string", key: "" },
      verifiedIdentification: { type: "string", key: "" },
      solicitudes: { type: "string", key: "" },
      tipo: { type: "string", key: "" },
      fecha: { type: "date", dateInit: "", dateEnd: "", delimit: "/" },
      time: { type: "time", key: "", relation: "fecha" },
    };

    this.filters = {};
    //this.updateFilters();
  }

  getconfirmEmail() {
    this.resetForms();
    this.formsData["confirm"]["key"] = "false";

    this.setFilterMultiple();
  }

  getCompleteIdentification() {
    this.resetForms();
    this.formsData["completeIdentification"]["key"] = "false";

    this.setFilterMultiple();
  }

  getVerifiedIdentification() {
    this.resetForms();
    let formsData: any = this.formsData;
    this.formsData["verifiedIdentification"]["key"] = "false";
    this.setFilterMultiple();
  }

  getSinSolicitudes() {
    this.resetForms();
    this.formsData["solicitudes"]["key"] = "false";
    this.setFilterMultiple();
  }

  getAgencys() {
    const params: any = {
      name: "app_properties$agencies$get",
      params: {
        request: {},
        path: {},
        body: {},
      },
    };
    this._httpService
      .executeRequest(params.name, params.params, true)
      .subscribe(
        (response: any) => {
          this.agencys = [];

          for (const row of response) {
            this.agencys = [...this.agencys, row.name];
          }
        },
        (error) => {
          this.agencys = [];
        }
      );
  }

  getExecutives() {
    const params: any = {
      name: "person$executives$get",
      params: {
        request: {},
        path: {},
        body: {},
      },
    };

    this._httpService
      .executeRequest(params.name, params.params, true)
      .subscribe(
        (response: any) => {
          this.executivesOptions = [];
          for (const row of response) {
            let ejecutive = {
              label: row?.person?.givenName + " " + row?.person?.familyName,
              value: row?.identifier,
            };

            this.executivesOptions = [...this.executivesOptions, ejecutive];
          }
        },
        (error) => {
          this.executivesOptions = [];
        }
      );
  }

  getUserClient(client) {
    const params = {
      name: "person$enduser$get",
      params: {
        request: {
          username: client,
        },
        path: {},
        body: {},
      },
    };

    this._httpService
      .executeRequest(params.name, params.params, true)
      .subscribe((response: any) => {
        this.clientLast = response;
        this.superUser = response?.viewAll;
        if (response?.viewAll == true) {
          let temp = {
            obj: {
              option: "assign_executive",
            },
            value: "Asignar Ejecutivo",
          };
          this.listOptionEjecutive = [...this.listOptionEjecutive, temp];
        }

        this.getClientsPaginator(this.paginator.page, 10);
        // this.getClientsPaginator(0, 5000000);
      });
  }

  getAvatars(datas) {
    let temp = {};
    let endUsers = [];

    for (const row of datas) {
      temp[row?.username] = row?.username;
    }

    for (const prop in temp) {
      endUsers = [...endUsers, { identifier: prop }];
    }

    const params = {
      name: "person$selfies_data_identities$post",
      params: {
        request: {},
        path: {},
        body: {
          endUsers: endUsers,
        },
      },
    };

    this._httpService
      .executeRequest(params.name, params.params, true)
      .subscribe((response: any) => {
        if (response) {
          for (const row of response) {
            if (row?.image) {
              //  this.fileAvatarPreview[row.identifier] = 'data:image/jpg' + ';base64,' + row?.image?.description;
              this.fileAvatarPreview[row?.alternateName] =
                "data:image/jpg" + ";base64," + row?.image?.description;
            }
          }
        }
      });
  }

  getDocumentClient(username, type) {
    const params = {
      name: "person$enduser$get",
      params: {
        request: {
          username: username,
        },
        path: {},
        body: {},
      },
    };

    this._httpService
      .executeRequest(params.name, params.params, true)
      .subscribe((response: any) => {
        let clientDetail = response;

        if (!isNullOrUndefined(clientDetail)) {
          if (clientDetail.personDocuments) {
            const doc = clientDetail.personDocuments.filter((doc) => {
              return doc.alternateName === type;
            });
            if (!isNullOrUndefined(doc) && doc.length > 0) {
              this.show_file2(doc[0].identifier);
            } else {
              return null;
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      });
  }

  show_file2(file) {
    file = { identifier: file };
    const archive = {
      identifier: file.identifier,
      name: file.name,
      dateCreated: {
        orderDate: null,
      },
    };

    if (file.dateCreated) {
      archive["dateCreated"]["orderDate"] = file.dateCreated.modifiedDate;
    } else {
      archive["dateCreated"]["orderDate"] = null;
    }

    if (archive.identifier !== null) {
      this._upload.visor_params.name = "";
      this._upload.visor_params.date = "";
      this._upload.visor_params.endpoint = "archive$downloadFile$get";
      this._upload.visor_params.archive = archive;
      this._upload.visor_params.deleteAfterShow = "";
      if (file["action"] === "download") {
        this._upload.visor_params.action = "download";
        this._upload.visor_params.deleteAfterShow = {
          deleteRequirement: file["deleteAfterShow"],
        };
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      }
      if (file["action"] === "downloadzip") {
        if (this.env.theme.images === "FACEBANK") {
          this._upload.visor_params.endpoint = "archive$downloadZipFile$get";
        }

        this._upload.visor_params.action = "download";
        this._upload.visor_params.deleteAfterShow = {
          deleteRequirement: file["deleteAfterShow"],
        };
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      } else {
        this._upload.visor_params.action = "";
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      }
    }
  }

  getUser() {
    const params = {
      name: "person$enduser$get",
      params: {
        request: {
          username: this._auth.getUsername(),
        },
        path: {},
        body: {},
      },
    };

    this._httpService
      .executeRequest(params.name, params.params, true)
      .subscribe((response: any) => {
        if (response?.url) {
          this.history = JSON.parse(response.url);

          if (this.history) {
            this.setHistoryDataInit(this.history);
          } else {
            this.history = {};
          }
        }
      });
  }

  setHistoryDataInit(datas) {
    if (datas) {
      let results = datas;
      this.historyData["historyFavorites"] = results["historyFavorites"] || {};
      this.historyData["historyNotes"] = results["historyNotes"] || {};

      /*
      for (const temp of Object.keys(datas)) {

        let row: any = datas[temp];

        results = {row,...results };
        this.get_dataAndSelfi2(row);

      }*/
    } else {
      this.historyData["historyFavorites"] = {};
      this.historyData["historyNotes"] = {};
    }
  }

  setDataHistoryFavorites(row) {
    // this.get_dataAndSelfi2(row);
    let temp = {
      username: row?.username,
      name: row?.nombre,
      date: this.datePipe.transform(Date(), "dd/MM/yyyy HH:mm"),
      akinitial: this.akinitial[row.identifier]?.initials,
    };
    this.setHistoryFavorites(temp);
  }

  sliceArray(temp) {
    let sizeArr = temp?.length;
    let min = 10;
    let result = temp;

    if (sizeArr > min) {
      result = temp.slice(0, min);
    }

    return result;
  }

  setHistoryFavorites = async (row) => {
    let temp: any = {};
    let values = this.historyData["historyFavorites"];

    if (values) {
      temp = values;
    }
    if (temp[row?.username]) {
      delete temp[row?.username];
    } else {
      temp[row?.username] = row;
    }

    this.history["historyFavorites"] = temp;

    const params: any = {
      name: "person$view_history$post",
      params: {
        request: {},
        path: {
          userName: this._auth.getUsername(),
        },
        body: {
          description: JSON.stringify(this.history),
        },
      },
    };

    try {
      let resp = await this._httpService
        .executeRequest(params.name, params.params, true)
        .toPromise();

      this.historyData["historyFavorites"] = resp
        ? temp
        : this.historyData["historyFavorites"];
    } catch (error) {
      this._error.error_text(error);
    }
  };

  get_dataAndSelfi2(row) {
    const params = {
      name: "person$selfie$get",
      params: {
        request: {
          username: row?.username,
        },
        path: {},
        body: {},
      },
    };

    this._httpService
      .executeRequest(params.name, params.params, true)
      .subscribe((response: any) => {
        if (response?.description) {
          this.historyDataSelfi[row?.username] =
            "data:image/jpg" + ";base64," + response?.description;
        }
      });
  }

  setClientDetail(e) {
    this.clientDetail = e;
    console.log(e);
    this.localStorage.removeItem("objContactSetView");
    if (!isNullOrUndefined(e?.open)) {
      this.clientDetail = false;
      if (e.open === "transaction") {
        this.localStorage.setItem(
          "objBackTransaction",
          JSON.stringify({ username: this.clientSelected })
        );
        this.transactionSelected = e.value;
        this.transactionDetail = true;
        this.openTransaction(e.value);
      }
    } else {
      this.clientDetail = e;
    }
  }

  openTransaction(transaction, reset?) {
    this.transactionDetail = false;
    if (!isNullOrUndefined(reset)) {
      this.localStorage.removeItem("objBackTransaction");
    }
    this.transactionSelected = transaction;
    this.transactionDetail = true;
  }

  setTransactionDetail(e) {
    this.transactionDetail = false;
    this.localStorage.removeItem("objContactSetView");
    if (!isNullOrUndefined(e.open)) {
      this.clientDetail = false;
      if (e.open === "contact") {
        this.localStorage.removeItem("objBackTransaction");
        this.localStorage.setItem("objContactSetView", 2);
        this.setClient(e.value);
      }
    } else {
      this.transactionDetail = e;
    }
  }

  gotoWhatsapp(event, num): void {
    window.open(" https://wa.me/" + num?.replace("+", ""), "_blank");
    event.stopPropagation();
  }

  redirectGuest() {
    const queryParams = {
      role: "USER",
      profile: this.localStorage.getItem("profile"),
      audience: "NATURAL",
    };

    this.router.navigate(["/console/list-guest"], { queryParams });
  }
  isAkeela() {
    return environment?.theme?.images === "AKEELA";
  }

  getUsersTransactions = async (datas) => {
    let temp = {};
    let endUsers = [];
    let usersTransactions = {};
    for (const row of datas) {
      temp[row?.username] = row?.username;
    }

    for (const prop in temp) {
      endUsers = [...endUsers, { identifier: prop }];
    }

    const params = {
      name: "person$endusers_transactions$post",
      params: {
        request: {},
        path: {},
        body: {
          endUsers: endUsers,
        },
      },
    };

    try {
      let response: any = await this._httpService
        .executeRequest(params.name, params.params, true)
        .toPromise();
      this.loading_table = false;
      if (response) {
        for (const row of response) {
          let resultObj = {};

          resultObj["completeBriefcase"] = row?.adminOrganization
            ? row?.adminOrganization?.completeBriefcase
            : row?.person?.completeBriefcase;
          resultObj["verifiedBriefcase"] = row?.adminOrganization
            ? row?.adminOrganization?.verifiedBriefcase
            : row?.person?.verifiedBriefcase;
          resultObj["transaction"] = null;

          if (row?.bankAccountTransactions?.length > 0) {
            let results: any = row?.bankAccountTransactions;
            let status;

            for (let result of results) {
              let statustrans = result?.accountStatus[0]?.stateInWorkFlow?.code;

              if (statustrans == "AC_RECEIVED") {
                status = statustrans;
                resultObj["transaction"] = result;
              } else if (
                statustrans == "AC_PENDING" &&
                (!status || status != "AC_RECEIVED")
              ) {
                status = statustrans;
                resultObj["transaction"] = result;
              } else if (
                !status ||
                (status != "AC_RECEIVED" && status != "AC_PENDING")
              ) {
                status = statustrans;
                resultObj["transaction"] = result;
              }
            }
          }

          usersTransactions[row?.identifier] = resultObj;
        }
      }

      this.usersTransactions = usersTransactions;
    } catch (error) {
      console.log(error);
    }
  };

  getUsersTransactionsOrUsername(data) {
    let resp = this.usersTransactions[data];
    return resp;
  }

  setShowFavorites() {
    this.showFavorites = !this.showFavorites;
    this.setFilterMultiple();
  }

  setViewsNote(userName) {
    console.log("recibido...");
    console.log(userName);
    this._wfService.setShowNoteForClient(userName);
  }

  setViewsExport() {
    this._wfService.setShowExportsForClient(true);
  }

  togle_botton_window(row: any) {
    console.log("Vista...");
    console.log(row);
    console.log(this.buttomModal);
    this.rowSelected = row;
    this.animate_init_bottom = false;
    this.buttomModal = !this.buttomModal;
  }
}
