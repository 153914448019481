<app-client-detail [selected]="clientDetail" [username]="clientSelected" (change)="setClientDetail($event)">
</app-client-detail>

<div class="mt-1 container-fluid">


    <div class="mt-4 jumbotron jumbotron-fluid p-3 mb-2 ">

        <div class="d-flex bd-highlight">

            <div>
                <h5 class="font-weight-normal">
                    <i class="fas fa-stream pr-1"></i> Stream
                </h5>
            </div>

            <div class="ml-auto">
                <i class="fas fa-ellipsis-v "></i>
            </div>
        </div>

    </div>


    <ng-container *ngIf="reload_loading">
        <div class=" w-100 mt-2">
            <img src="./assets/public/images/loading.gif" alt="">
        </div>
    </ng-container>


    <ng-container *ngIf="!reload_loading && rows?.length ==0">
        <div class="mt-3">No se encontraron comentarios</div>
    </ng-container>



    <div class="pb-3">


        <ng-container *ngFor="let row of rows ; let i = index">

            <div class="line ml-auto mr-auto text-center w-100 ">
                <div class="badge badge-pill badge-light badge-line">{{row?.create_date}}</div>
            </div>
            <br>


            <div *ngFor="let row2 of row?.object; let i = index" class="mt-3 note active-cursor ">

                <div class=" active-cursor  fz-14 mt-3 " (click)="setClient(row2?.client)">

                    <div class="mb-1">

                        <span class=" font-weight-bold  pr-2   ">{{row2.full_name }}</span>
                        <span class="small text-secondary "> {{row2?.create_date | date: 'dd/MM/yyyy HH:mm'}}</span>

                    </div>

                    <span class=" text-secondary  pr-2 ">{{row2.ejecutive }}</span>
                    <span>{{row2.note}}</span>
                </div>

            </div>

        </ng-container>
    </div>



    <!-- paginator -->
    <div class="row content-paginator m-t-1" *ngIf="paginator">
        <akeela-paginator [paginator]="paginator" (response)="action_paginator($event)" *ngIf="!reload_loading">
        </akeela-paginator>
    </div>



</div>