import { AuthService } from '@akeela/auth';
import { PaginatorService } from '@akeela/common';
import { LocalService } from '@akeela/local-storage';
import { HttpService } from '@akeela/properties';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { EmailService } from 'src/app/services/email.service';
import { Utf8RegexService } from 'src/app/services/utf8-regex.service';
import { environment } from '../../../../../environments/environment';
import swal from 'sweetalert2';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-list-orders',
  templateUrl: './list-orders.component.html',
  styleUrls: ['./list-orders.component.css']
})
export class ListOrdersComponent implements OnInit {

  listOrders: any = [];
  rows = [];
  temp = [];
  columns = [];
  akinitial = {};
  paginator;
  reload_loading;
  loadingIndicator;
  fieldSearch: any = '';
  newOrders;
  env: any = environment;
  emailSubscription: Subscription;
  sendMailSubscription: Subscription;
  loadingsendEmail = {};
  dataExport = [];


  constructor(
    private _httpService: HttpService,
    private _auth: AuthService,
    private router: Router,
    private localStorage: LocalService,
    private paginatorService: PaginatorService,
    private _email: EmailService,
    private _utf8: Utf8RegexService,
    private datePipe: DatePipe,

  ) { }

  ngOnInit(): void {


    this.paginator = {
      totalPages: 0,
      first: true,
      last: false,
      number: 0,
      pageable: {
        pageNumber: 0,
        from: null,
        to: null
      }
    };

    this.setListOrders();

  }

  redirectClient() {

    const queryParams = {
      role: 'USER',
      profile: this.localStorage.getItem('profile'),
      audience: 'NATURAL',
    };

    this.router.navigate([environment.staticLinks.listClients], { queryParams });

  }

  setListOrders = async () => {
    this.reload_loading = true;
    this.newOrders = false;

    try {
      let listOrders: any = await this.getListOrders();

      this.listOrders = [...listOrders];
      this.setConfigurationTableNew();

    } catch (error) {
      this.listOrders = [];
      this.reload_loading = false;

    }
  }

  getListOrders = async () => {

    try {
      //environment?.theme?.site?.name
      const params = {
        pathResponse: {
          url: 'https://orders.akeela.co/client/list',
          httpMethod: 'GET'
        },
        params: {
          responseType: 'json',

          request: {
            'environment_code': this.env?.code || this.env?.theme?.images
          }

        }


      }

      return await this._httpService.executeRequestExternalUrl(params).toPromise();

    } catch (error) {
      console.error(error);

    }

  }


  setConfigurationTableNew() {
    this.columns = [];
    let datas = [];
    let datas2 = [];
    let data = [];
    const content = [];
    let i = 0;

    for (const row of this.listOrders) {
      /*icono*/
      let icon = '';
      let initials = this.getInitials(row?.first_name, row?.family_name);
      let initial = this.getInitial(row?.legal_name);

      this.akinitial[row._id] = { initial, initials }

      /*Menu de opciones*/

      let jsonTmp =
      {
        "identifier": row?._id,
        "name": `${row?.first_name} ${row?.family_name}`,
        "phone": row?.phone,
        "personType": row?.person_type,
        "email": row?.email,
        "businessName": row?.legal_name &&  row?.legal_name != 'null'?row?.legal_name:'' ,
        "identity": row?.identity_number,
        "shares": row?.shares,
        "company": row?.company,
        "fecha": row?.create_date
      }
      

      datas = [...datas, jsonTmp];

      let jsonTmp2 =
      {
        "name": `${row?.first_name} ${row?.family_name}`,
        "businessName": row?.legal_name &&  row?.legal_name != 'null'?row?.legal_name:'' ,
        "identity": row?.identity_number,
        "personType": row?.person_type,
        "phone": row?.phone,
        "email": row?.email,
        "company": row?.company,
        "shares": row?.shares,
        "fecha":this.datePipe.transform(row?.create_date, 'dd/MM/yyyy HH:mm') 
      }
      

      datas2 = [...datas2, jsonTmp2];


    }


    this.reload_loading = false;

    datas2.sort(this.custom_sort);
    this.dataExport = [...datas2];

    datas.sort(this.custom_sort);
    this.temp = [...datas];
    this.rows = [...datas];



    data = this.paginatorService.getDataPaginator(this.paginator, this.env, this.temp);
    this.paginator.number = this.temp?.length;
    this.paginator.totalPages = Math.ceil(this.temp?.length / this.env.paginator.size);
    this.paginator.pageable.to = this.paginatorService.getPaginatorTo(this.paginator, this.env);
    this.paginator.pageable.from = this.paginatorService.getPaginatorFrom(this.paginator, this.env);
    this.rows = data;


  }

  custom_sort(a, b) {
    return new Date(b?.fecha).getTime() - new Date(a?.fecha).getTime();
  }

  //HERE
  updateFilter(data) {

    let data2 = [];
    this.loadingIndicator = true;
    const val = data ? String(data).toLowerCase() : null;

    if (val) {
      // filter our data

      let arrayBase = this.temp;

      const temp = arrayBase.filter(function (d) {

        let filter = false;
        let tempAnchor = false;

        for (let clave in d) {


          let value = d[clave];


          if (!filter) {

            filter = String(value).toLowerCase().indexOf(val) !== -1 || !val;

          }


        }

        return filter;

      });

      // update the rows
      data2 = temp;
    } else {
      data2 = this.temp;
    }

    this.rows = data2;

    this.update_transactionsFilter(data2);

    this.rows = this.paginatorService.getDataPaginator(this.paginator, this.env, data2);




    this.loadingIndicator = false;

  }



  getInitials(givenName: string, familyName: string) {
    return (
      (givenName ? givenName.substr(0, 1).toUpperCase() : '') +
      (familyName ? familyName.substr(0, 1).toUpperCase() : '')
    );
  }
  getInitial(text) {
    let retorno = '';
    if (text) {
      retorno = text.substr(0, 1).toUpperCase();
    }
    return retorno;
  }

  getFirstLetter(data: string) {
    return (data ? data.substr(0, 1).toUpperCase() : '');
  }




  gotoWhatsapp(event, num): void {
    window.open(" https://wa.me/" + num?.replace('+', ''), "_blank");
    event.stopPropagation();

  }


  resetList = (event) => {

    this.setListOrders();
  }



  action_paginator(page) {
    if (page === 'NEXT') {
      page = this.paginator.pageable.pageNumber + 1;
      if (page >= this.paginator.totalPages) {
        page = this.paginator.pageable.pageNumber;
      }
    } else if (page === 'BACK') {
      page = this.paginator.pageable.pageNumber - 1;
      if (page < 0) {
        page = this.paginator.pageable.pageNumber;
      }
    }
    this.paginator.pageable.pageNumber = page;
    this.paginator.first = (page === 0 ? true : false);
    this.paginator.last = ((page + 1) === this.paginator.totalPages ? true : false);
    this.setTablePaginators();
  }



  setTablePaginators() {

    let data = this.paginatorService.getDataPaginator(this.paginator, this.env, this.temp);

    this.paginator.number = this.temp?.length;
    this.paginator.totalPages = Math.ceil(this.temp?.length / this.env.paginator.size);
    this.paginator.pageable.to = this.paginatorService.getPaginatorTo(this.paginator, this.env);
    this.paginator.pageable.from = this.paginatorService.getPaginatorFrom(this.paginator, this.env);
    this.rows = data;


  }



  update_transactionsFilter(params, loading?) {
    this.reload_loading = true;
    this.paginator = {
      totalPages: Math.ceil(params.length / this.env.paginator.size),
      first: true,
      last: false,
      number: params.length,
      pageable: {
        pageNumber: 0,
        from: 1,
        to: params.length < this.env.paginator.size ? params.length : this.env.paginator.size
      }
    };

    this.reload_loading = false;



  }


  newEmailClient = async (data) => {

    

    this.loadingsendEmail[data?.identifier] = true;

    let site_header = environment.theme.site.name;
    let img = '';
    if (environment.theme.image) {
      if (environment.theme.image.active) {
        if (environment.theme.image.active) {
          img = `<img style="height: 40px;" src="${environment?.theme?.image?.urlImageHeader}"  ></img>`;
        }
      }
    }
    const portal = environment?.urlClient + '/' + environment?.staticLinks?.register;

    let text = `
     
        <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
        <html xmlns="http://www.w3.org/1999/xhtml">
        
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </head>
  
        <body style="margin:0;font-size: 14px;padding:0;color: #555;">
  
           <br>
           ${img}
           <br> 
           <br>
           <h3>Hola, ${data?.name} ${data?.lastName} ${data?.personType == 'LEGAL' ? '( <span style="font-size: 16px;" > ' + data?.businessName + ' </span> )' : ''}</h3>
           
            Te informamos que tu solicitud de orden fue generada exitosamente <br><br>
  
            <b>Número de Identificación </b> ${data?.identity} <br>
            <b>Email  </b> ${data?.email} <br>
            <b>Teléfono </b>  ${data?.phone} <br>
            <b>Cantidad de acciones </b>  ${data?.shares} <br><br>
            ----
            <br><br>
            Nos complace que hayas elegido formar parte de   ${site_header}
            Estás dando el primer paso hacia la transformación digital con la experiencia que te ofrece   ${site_header}<br>
            Para continuar con el proceso, debes crear tu cuenta, registrandote en nuestro portal en linea.
  
            
            Ingresa   <a href="${portal}" >Aquí</a>
  
      
        </body>
        
        </html>
  
     
     `;


    this.sendMail(text, data, 'Orden generada exitosamente', data['email']).subscribe((event: any) => {
      this.loadingsendEmail[data?.identifier]  = false;

      swal.fire({
        reverseButtons: true,
        confirmButtonText: 'Ok',
        html: 'Notificación enviada.',
      });
      this.destroySub();

    }, error => {
      this.loadingsendEmail[data?.identifier]  = false;

      swal.fire({
        reverseButtons: true,
        confirmButtonText: 'Ok',
        html: 'Error al enviar notificación.',
      });

      this.destroySub();

    });

  }



  sendMail = (text, data, subject, email) => {
    this._utf8.utf8Encode(text);
    let emailTo = email;

    return new Observable(resolve => {
      const portal = environment.theme.site.name;
      const properties: object = {};


      const email_message = {
        identifier: 1,
        dateOfThing: {},
        status: null,
        sender: null,
        text: null,
        type: 'text/html',
        subjectOf: subject,
        toRecipient: emailTo
      };
      const template = this._email.getTemplate(properties);

      const response = this._email.sendMail(
        email_message,
        properties,
        text
      );


      this.sendMailSubscription = response.subscribe(mail_result => {
        resolve.next(mail_result);
        resolve.complete();
      }, error => {

        resolve.next(false);
        resolve.complete();

      });


    });

  }

  destroySub = () => {
    this.emailSubscription.unsubscribe();
    this.sendMailSubscription.unsubscribe();
  }

  exportSCV(){


    var date = new Date();
   let fecha =((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
   


    let options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      showTitle: true,
      title:  'Orders '+fecha,
      useBom: true,
      noDownload: false,
      headers: ["Nombre y Apellido", "Nombre de la Razón Social ","Número de documento", "Tipo de solicitante","Teléfono","Email","Empresa","Cantidad de acciones","Fecha"]
    };

    new ngxCsv(this.dataExport, 'Orders-'+fecha,options);

  }
}
