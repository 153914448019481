import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { AuthService } from '@akeela/auth';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import swal from 'sweetalert2';
import merge from 'lodash/merge'
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { TransitionService } from '../../../../services/transition.service';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { CommonService, ErrorService } from '@akeela/common';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { LocalService } from '@akeela/local-storage';
import { I18nPipe } from '@akeela/i18n';

import { Table, Row, Column } from '@akeela/ak-table';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { DatePipe } from '@angular/common';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { PropertiesService } from '../../../../services';
import { debug } from 'console';


@Component({
  selector: 'app-workspaces',
  templateUrl: './workspaces.component.html',
  styleUrls: ['./workspaces.component.css']
})
export class WorkspacesComponent implements OnInit, OnDestroy, AfterViewInit {
  workspaces: any = null;
  queryParams;
  workflow;
  stage;
  step;
  subscriptions: Subscription[] = [];
  loading_table = false;
  loading_table2 = false;
  env = environment;

  configuration_table: Table;
  limitRows = 25;
  rows = [];
  selectedRow = [];
  selected = [];
  temp = [];
  columns = [];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  fieldSearch;
  showModal;
  wsMetric;
  @ViewChild("chartsContent", { static: false }) chartsContent: ElementRef;

  buttomModal;
  clientDetail;
  wksDropdowns = [];
  enableAllWksDropdowns = true;
  animate_init_bottom = true;

  paginator: any = null;
  profile;

  client_indicators ;

general_indicators ;


clientLast ; 
clientSelected;
  /************ */

  multi: any[] = [
    {
      "name": "Germany",
      "series": [
        {
          "name": "2010",
          "value": 7300000
        },
        {
          "name": "2011",
          "value": 8940000
        },
        {
          "name": "2022",
          "value": 5656565
        }
      ]
    }
  ];



  view: any[];

  // options
  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  yAxisLabel: string = 'Country';
  showYAxisLabel: boolean = true;
  xAxisLabel: string = 'Population';
  responseType:any = {};
  colorScheme = {
    domain: ['var(--ak-color-1)', ' var(--ak-color-4)', '#C7B42C']
  };



  onSelect(event) {
    console.log(event);
  }


  onResize(event) {
    // this.view = [this.chartsContent.nativeElement.offsetWidth - 50, 100];
  }

  /*********** */




  constructor(
    private _httpService: HttpService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private _auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private transitionService: TransitionService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    public _common: CommonService,
    private _breadcrumbService: AkeelaBreadcrumbService,
    private localStorage: LocalService,
    private _error: ErrorService,
    private _i18n: I18nPipe,
    private datePipe: DatePipe,
    public config: NgbDropdownConfig,
    private _properties: PropertiesService,
  ) {

    this.paginator = {
      totalPages: 2,
      first: true,
      last: false,
      number: 1,
      pageable: {
        pageNumber: 0,
        from: null,
        to: null
      }
    };

    this.loading_table = false;

    this.akStageService.setshowHeaderStage(false);
    this.akStepService.setshowHeaderStep(false);
    this.akStepService.setshowSubHeaderStep(false);

    config.placement = 'bottom-left';
    config.autoClose = true;
  }

  togle_botton_window() {

    this.animate_init_bottom = false;
    this.buttomModal = !this.buttomModal;
    

  }



  setWksDropdowns(){



    if (!this.enableAllWksDropdowns) {


      for (const workspace of this.workspaces) {

        this.wksDropdowns[workspace ?.identifier] = true;

      }
      this.enableAllWksDropdowns = true;

    } else {

      this.wksDropdowns = [];
      this.enableAllWksDropdowns = false;

    }

  }

  ngAfterViewInit() {


   // this.view = [this.chartsContent.nativeElement.offsetWidth - 50, 100];
  }

  ngOnInit() {

    this.profile =  this.localStorage.getItem('profile'),

    this._akeelaSidebarMenu.setSidebarLeftActive('WORKSPACES');

    //this._breadcrumbService.setbreadcrumbOptions(null);
    //this._akeelaSidebarMenu.setshowSidebarLeft(true);

    if (environment.theme.images === 'FACEBANK') {
      this.test_integration();
    }

    
    this.stage = this.akStageService.currentStage$.getValue();
    this.step = this.akStepService.currentStep$.getValue();
    this.workflow = this.akWorkflowService.currentWorkflow$.getValue();
    this.akWorkflowService.transactionData$.next(null);
    this._properties.resetWks();

    this._akeelaSidebarMenu.setSidebarTopActive({ sidebarTopActive: environment.appEndPontDefaultConfig.sidebarTopActive });
    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
      this.getWorkflowsPaginator();
    }));


//this.getClientIndicators();
//this.getGeneralIndicators();
  }

  test_integration() {
    const params: any = {
      name: 'akcoreintegration$consultarUser$get',
      params: {
        request: {
          username: 'moctavio'
        },
        path: {},
        body: {}
      }
    };
    this.loading_table = true;
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

    });

  }


  //juan
  setConfigurationTableNew() {
    console.log('acá');
    this.paginator = {
      totalPages: Math.ceil(this.workspaces.length / this.env.paginator.size),
      first: true,
      last: false,
      number: this.workspaces.length,
      pageable: {
        pageNumber: 0,
        from: null,
        to: null
      }
    };


    let data = [];
    const content: Row[] = [];
    let i = 0;

    for (const workspace of this.workspaces) {


      let jsonTmp =
        {
          "identifier": workspace.identifier,
          "wpname": workspace.name,
          "clave": workspace.alternateName,
          'product': !isNullOrUndefined(workspace.product) ? workspace.product.name : 'Todos los Productos',
          "plan": !isNullOrUndefined(workspace.plan) ? (workspace.plan.name) : 'Todos los Planes',
          "vista": workspace.viewType === 'EXECUTIVE' ? 'Ejecutivo' : 'Analista',
          "agencia": !isNullOrUndefined(workspace.agency) ? workspace.agency.name : 'Todas las Agencias',
          "responsable": !isNullOrUndefined(workspace.leadership) ? (workspace.leadership.name) : '-',
          "statusCola": workspace.status === 'ACTIVE' ? this._i18n.transform('@i18n-text-active') : (this._i18n.transform('@i18n-text-inactive')),
          'statusSolicitud': workspace.transactionState ? workspace.transactionState.name : 'Todos',
          'tipo': workspace.personType ? workspace.personType.audienceType == 'NATURAL' ? 'Persona Natural' : 'Persona Jurídica' : 'Todas',

        }

      data = [...data, jsonTmp];
    }

      
      this.loading_table = false;

          this.getIndicators(data);

            // cache our list
            this.temp = [...data];
            // push our inital complete list
            this.rows = data;    

            this.setWksDropdowns();



    // cache our list
    this.temp = [...data];
    // push our inital complete list
    this.rows = data;
  }


  setConfigurationTable() {
    const base: Column[] = environment.akeelaTablesConfig.workspaces.columns;
    this.configuration_table = {
      cssCustom: '',
      width: '100%',
      header: {
        cssCustom: 'font-weight-bold class-color-title',
        rows: [{
          backgroundColor: '#F8F7F7',
          columns: base
        }],
      },
      body: {
        cssCustom: '',
        rows: this.setContentTable(base)
      },
      footer: {
        cssCustom: '',
        rows: []
      }
    };
  }

  setContentTable(configuration): Row[] {
    const content: Row[] = [];
    let i = 0;
    for (const workspace of this.workspaces) {
      i++;
      content.push(
        {
          identifier: workspace.identifier,
          cssCustom: '',
          enabled: true,
          columns: [
            {
              alignContent: configuration[0].alignContent,
              fixed: configuration[0].fixed,
              maxWidth: configuration[0].maxWidth,
              minWidth: configuration[0].minWidth,
              html: (workspace.name),
              cssCustom: configuration[0].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[0].enableLeftBorder) ? configuration[0].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[0].enableRightBorder) ? configuration[0].enableRightBorder : false
            },
            {
              alignContent: configuration[1].alignContent,
              fixed: configuration[1].fixed,
              maxWidth: configuration[1].maxWidth,
              minWidth: configuration[1].minWidth,
              html: (workspace.alternateName),
              cssCustom: configuration[1].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[1].enableLeftBorder) ? configuration[1].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[1].enableRightBorder) ? configuration[1].enableRightBorder : false
            },
            {
              alignContent: configuration[2].alignContent,
              fixed: configuration[2].fixed,
              maxWidth: configuration[2].maxWidth,
              minWidth: configuration[2].minWidth,
              html: !isNullOrUndefined(workspace.product) ? workspace.product.name : '',
              cssCustom: configuration[2].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[2].enableLeftBorder) ? configuration[2].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[2].enableRightBorder) ? configuration[2].enableRightBorder : false
            },
            {
              alignContent: configuration[3].alignContent,
              fixed: configuration[3].fixed,
              maxWidth: configuration[3].maxWidth,
              minWidth: configuration[3].minWidth,
              html: !isNullOrUndefined(workspace.plan) ? (workspace.plan.name) : '-',
              cssCustom: configuration[3].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[3].enableLeftBorder) ? configuration[3].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[3].enableRightBorder) ? configuration[3].enableRightBorder : false
            },
            {
              alignContent: configuration[4].alignContent,
              fixed: configuration[4].fixed,
              maxWidth: configuration[4].maxWidth,
              minWidth: configuration[4].minWidth,
              html: workspace.viewType === 'EXECUTIVE' ? 'Ejecutivo' : 'Analista',
              cssCustom: configuration[4].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[4].enableLeftBorder) ? configuration[4].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[4].enableRightBorder) ? configuration[4].enableRightBorder : false
            },
            {
              alignContent: configuration[5].alignContent,
              fixed: configuration[5].fixed,
              maxWidth: configuration[5].maxWidth,
              minWidth: configuration[5].minWidth,
              html: !isNullOrUndefined(workspace.agency) ? workspace.agency.alternateName : '',
              cssCustom: configuration[5].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[5].enableLeftBorder) ? configuration[5].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[5].enableRightBorder) ? configuration[5].enableRightBorder : false
            },
            {
              alignContent: configuration[6].alignContent,
              fixed: configuration[6].fixed,
              maxWidth: configuration[6].maxWidth,
              minWidth: configuration[6].minWidth,
              html: !isNullOrUndefined(workspace.leadership) ? (workspace.leadership.name) : '-',
              cssCustom: configuration[6].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[6].enableLeftBorder) ? configuration[6].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[6].enableRightBorder) ? configuration[6].enableRightBorder : false
            },
            {
              alignContent: configuration[7].alignContent,
              fixed: configuration[7].fixed,
              maxWidth: configuration[7].maxWidth,
              minWidth: configuration[7].minWidth,
              html: '-',
              cssCustom: configuration[7].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[7].enableLeftBorder) ? configuration[7].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[7].enableRightBorder) ? configuration[7].enableRightBorder : false
            },
            {
              alignContent: configuration[8].alignContent,
              fixed: configuration[8].fixed,
              maxWidth: configuration[8].maxWidth,
              minWidth: configuration[8].minWidth,
              html: workspace.status === 'ACTIVE' ? this._i18n.transform('@i18n-text-active') : (this._i18n.transform('@i18n-text-inactive')),
              cssCustom: configuration[8].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[8].enableLeftBorder) ? configuration[8].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[8].enableRightBorder) ? configuration[8].enableRightBorder : false
            },
            {
              alignContent: configuration[9].alignContent,
              fixed: configuration[9].fixed,
              maxWidth: configuration[9].maxWidth,
              minWidth: configuration[9].minWidth,
              html: '<i class="fas fa-angle-right text-primary" title="Ir..."></i>',
              cssCustom: configuration[9].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[9].enableLeftBorder) ? configuration[9].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[9].enableRightBorder) ? configuration[9].enableRightBorder : false
            }
          ]
        }
      );
    }
    return content;
  }

  table_response(response) {
    if (response.action === 'open') {
      let workspace = null;
      workspace = this.workspaces.find((wspc: any) => {
        return wspc.identifier === response.object.identifier;
      });


      this.open(workspace);
    }
  }



  getWorkflowsPaginator(page?, size?, isPolicy?, sort_by?, sort_type?) {
    this.workspaces = null;
    const request = {
      username: this._auth.getUsername(),
      profileCode: this.localStorage.getItem('profile')
    };

    const params: any = {

      //name: 'workflow$workspaces$get',
      name: 'workflow$workspaces_optimized$get',
      params: {
        request: request,
        path: {
          workflow: this.workflow,
          stage: this.stage,
          step: this.step,
          prefixWorkflow: 'workspace_manager'
        },
        body: {}
      }
    };
    this.loading_table = true;
    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.workspaces = response;
      this.loading_table = false;
      //  this.setConfigurationTable();
      this.setConfigurationTableNew();
    }, error => {
      this._error.show(error);
      this.workspaces = [];
      this.loading_table = false;
      this.paginator = {
        totalPages: 0,
        first: true,
        last: false,
        number: 0,
        pageable: {
          pageNumber: 0,
          from: null,
          to: null
        }
      };
      // this.setConfigurationTable();
      this.setConfigurationTableNew();

    }));

  }

  open(workspace,codeQueue = workspace?.defaultQueue) {
    const queryParams = {
      codeQueue: codeQueue,
      role : this.localStorage.getItem('role'),
      profile : 'USER',
      audience : this._auth.getUserAudience()
    };
    const params_trans = {
      name: 'workflow$transitionssteps$get',
      params: {
        request: {
          profile : 'USER',
          role: this.localStorage.getItem('role'),
          audience: this.localStorage.getItem('audience'),
          event_code: 'CLICK_ON_WKS'
        },
        path: {
          codeWorkflow: workspace.alternateName,
          prefixWorkflow: 'workflow_manager',
          workflow: this.workflow,
          stage: this.stage,
          step: this.step
        }
      },
      o: {}
    };

    this.localStorage.setItem('workspaceAll', JSON.stringify(workspace));
    this.localStorage.setItem('workspace', workspace.alternateName);
    this.localStorage.setItem('queueDescriptionDefault', workspace.name);
    this.localStorage.setItem('queueDescription', workspace.name);

    if (workspace.onlyRead) {
      this.localStorage.setItem('workspaceReadonly', 'true');

    }

    this._common.setWorkspaceCode(workspace.alternateName);
    this._common.workspaceCode$.next(workspace.alternateName);

    if (workspace.viewType === 'EXECUTIVE') {
      this._akeelaSidebarMenu.setSidebarLeftActive("QUEUES");


    }


     this.localStorage.setItem('codeQueue',codeQueue);


    this.transitionService.next('private', params_trans, queryParams);
  }


  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }



  onActivate(event) {
    if (event.type == 'click' && event.row.identifier) {
      if (event && event.value !== '') {
        let workspace = null;
        workspace = this.workspaces.find((wspc: any) => {
          return wspc.identifier === event.row.identifier;
        });

        this.open(workspace);

      }
      //console.log("event",event);


    }
  }

  openNew(identifier,codeQueue) {

    let workspace = null;
    workspace = this.workspaces.find((wspc: any) => {
      return wspc.identifier === identifier;
    });

    this.open(workspace,codeQueue);

    //console.log("event",event);


  }

  updateFilter(data) {

    this.loading_table2 = true;
    const val = !isNullOrUndefined(data) ? String(data).toLowerCase() : null;

    // filter our data
    let arrayBase = this.temp;

    const temp = arrayBase.filter(function (d) {

      let filter = false;
      let tempAnchor = false;

      for (let clave in d) {
        let value = d[clave];

        if (!filter) {

          filter = String(value).toLowerCase().indexOf(val) !== -1 || !val;

        }
      }

      return filter;

    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    this.loading_table2 = false;

  }


getIndicators(datas) {



  let workspaces = [];


    for (const row of   datas ) {
            
      workspaces = [...workspaces, {alternateName:row.clave}]

    }
  
  const params = {


    name: 'workspace_manager$indicators$post',
    params: {
      request: {},
      path: {
        username: this._auth.getUsername(),
      },
      body: {
        workspaces :workspaces
        
      }
    }
  };


  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

    
    if (response) {

      this.responseType = this.getResponseType(response);

    }
  });

}



getResponseType(response:any){

       let responseType:any = {};

     for (const value of response) {

      let datas = {};


      for (const row of value?.indicators) {

        datas[row?.name] = row;

      }
         let data = value?.alternateName;
 
       responseType[data] = datas;
       
       
    }

    return responseType;

}


totalCalc(data){

 // ;
  let sum = 0;

  for (const row in data) {
    if(data[row]?.value){
    sum = sum +  data[row]?.value;

    }

  }
  
   return sum; 

}


getGeneralIndicators() {
  const params: any = {
    name: 'workspace_manager$indicators_general$get',
    params: {
      request: {},
      path: {},
      body: {}
    }
  };
  this.general_indicators = null;
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    this.general_indicators = {};  
    for (const row of response) {      
    
      this.general_indicators[row?.name]= row;
    
  }

  }, error => {
  })
}



getClientIndicators() {

  const params: any = {
    name: 'workspace_manager$indicators_client$get',
    params: {
      request: {},
      path: {},
      body: {}
    }
  };

  this.client_indicators = null;


  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    this.client_indicators = {};

    for (const row of response) {      
    
      this.client_indicators[row?.name]= row;
    
  }
      this.getUser(this.client_indicators['LAST_REC']?.modifyUser);

  }, error => {

  })
}

setClientDetail(e){
  this.clientDetail = e;
}

setClient(client){
  this.clientDetail = true; 
  this.clientSelected = client;
}

isNullOrUndefined = (value) => {
  return isNullOrUndefined(value);
}



getUser(client) {
  const params = {
    name: 'person$enduser$get',
    params: {
      request: {
        'username': client
      },
      path: {},
      body: {}
    }
  };

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    this.clientLast = response;

  });

}



}
