import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { MiddlewareService } from '../../../../services/middleware.service';
import { isNullOrUndefined } from 'util';
import { LocalService } from '@akeela/local-storage';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-detail-resumen',
  templateUrl: './detail-resumen.component.html',
  styleUrls: ['./detail-resumen.component.css']
})
export class DetailResumenComponent implements OnInit {
  @Input() transactionNumber;
  @Input() identifier;

  transactionData = null;
  isActiveRequirements = false;
  config;
  active = 'P1';
  env = environment;

  role;

  constructor(
    private _httpService: HttpService,
    private _middleware: MiddlewareService,
    private localStorage: LocalService
  ) { }


  ngOnInit() {
    this.role = this.localStorage.getItem('role');

    this._middleware.isModuleRequirementsActive().then((response) => {
      if (response) {
        this.isActiveRequirements = true;
      }
      this.getTransactionData(this.transactionNumber).subscribe((data: any) => {
        this.transactionData = data;
      });

      this.config = {
        isActiveMyNumber: true,
        isActiveMenu: true,
        tabs: [
          {
            identifier: 'P1',
            description: '@i18n-text-tab-resume',
            enabled: true
          },
          {
            identifier: 'P2',
            description: '@i18n-text-tab-revisions',
            enabled: true,

          },
          {
            identifier: 'P3',
            description: '@i18n-text-tab-versions',
            enabled: true,

          },
          {
            identifier: 'P4',
            description: '@i18n-text-tab-checklist',
            enabled: this.isActiveRequirements,

          },
          {
            identifier: 'P5',
            description: '@i18n-text-tab-procedures',
            enabled: true,

          }
        ]
      };
    });
  }

  getTransactionData(transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role,
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  refresh(obj) {
    if (!isNullOrUndefined(obj)) {
      this.transactionData = null;
      this.transactionNumber = obj.transactionNumber;
      this.getTransactionData(this.transactionNumber).subscribe(response=>{
        this.transactionData = response;
      });
    }
  }



}
