import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '@akeela/properties';
import { AlertService } from '@akeela/alert';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-create-user-password',
  templateUrl: './create-user-password.component.html',
  styleUrls: ['./create-user-password.component.css']
})
export class CreateUserPasswordComponent implements OnInit {
  username;
  token;
  config;
  environment;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _httpService: HttpService,
    private _activatedRoute: ActivatedRoute,
    private akAlert: AlertService
  ) { }

  ngOnInit() {
    this.environment = environment;

    this.route.params.subscribe(queryParams => {
      this.username = queryParams['username'];
      this.token = queryParams['token'];
      this.config = {
        validation: {
          url: 'person$exists_password$get',
          params: {
            path: {},
            request: {
              username: this.username
            },
            body: {}
          }
        },
        save: {
          url: 'person$create_password$put',
          params: {
            path: {},
            request: {},
            body: {}
          }
        }
      };
    });
  }

  getResponse(response) {
    if (response.success) {



      const params = {
        name: 'person$confirm_user_email$put',
        params: {
          path: {},
          request: {},
          body: {
            name: this.token
          }
        }
      };
      this._httpService.executeRequest(params.name, params.params).subscribe((response: any) => { });

      this.router.navigateByUrl(this.router.createUrlTree
        ([this.environment.staticLinks.login],
        {
          queryParams: {}
        }
        )
      ).then((response) => {
        if (response) {
          this.akAlert.show({
            title: '',
            html: 'Favor ingrese su usuario y contraseña para continuar.'
          });
        }
      });

    } else {
      switch (response.type) {
        case 'VALIDATION_LINK_FAIL':
          this.router.navigateByUrl(this.router.createUrlTree
            ([this.environment.staticLinks.login],
              {
              queryParams: {}
            }
            )
          );
          break;

      }
    }
  }

}
