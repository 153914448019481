<ng-container *ngIf="current_object!==null">
  <button type="button" class="btn btn-secondary  d-block d-md-none" [swal]="showWindow">
    <span style="font-size:11px">{{ current_object?.length>0?current_object[0].name: 'Todos' }}</span>&nbsp;
  </button>
  <button type="button" class="btn btn-secondary dropdown-toggle d-none d-md-block" data-toggle="dropdown" aria-haspopup="true"
    aria-expanded="false">
    <span style="font-size:11px">{{ current_object?.length>0?current_object[0].name: 'Todos' }}</span>&nbsp;
  </button>
</ng-container>
<div class="dropdown-menu" style="font-size:14px">
  <a class="dropdown-item" (click)="apply('')">&nbsp;Todos</a>
  <ng-container *ngFor="let state of states">
    <a class="dropdown-item" (click)="apply(state.code)">&nbsp;{{ state.name }}</a>
  </ng-container>
</div>


<swal [width]="'850px'" [width]="'100%'" [showCloseButton]="true" [showConfirmButton]="false" [showCancelButton]="false"
  #showWindow>

  <ng-container *swalPortal="swalTargets.content">
    <br><br>
    <div class="filter-text">
    <div class="row active-cursor" (click)="apply('')">
      <div class="col-10" >
        {{ 'Todos' }}
      </div>
      <div class="col-2 text-right">
          <i class="fas fa-check" *ngIf="current===''"></i>
      </div>
    </div>
    <hr>
    <ng-container *ngFor="let state of states; let i = index" [attr.data-index]="i">
      <div class="row active-cursor" (click)="apply(state.code)">
        <div class="col-10" >
          {{ state.name }}
        </div>
        <div class="col-2 text-right">
            <i class="fas fa-check" *ngIf="current===state.code"></i>
        </div>
      </div>
      <hr *ngIf="i!==(states.length-1)">
    </ng-container>
    <br>
    </div>

  </ng-container>
</swal>