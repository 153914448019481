import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { isNullOrUndefined, isNull } from 'util';
import merge from 'lodash/merge'
import swal from 'sweetalert2';
import { HttpService } from '@akeela/properties';
import { ErrorService } from '@akeela/common';
import { environment } from '../../../environments/environment';
import { SearchService } from '../../services/search.service';
import { AuthService } from '@akeela/auth';
import { LocalService } from '@akeela/local-storage';
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';



@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @Input() size?;
  @Input() page?;
  @Output() response = new EventEmitter();
  activeSearch = false;
  activeTrash = false;
  disabled = false;
  loading = false;
  step;
  stage;
  workflow;
  states;
  channels = null;
  agencys = null;
  executives = null;

  m_date_between_active = false;
  c_date_between_active = false;
  s_date_between_active = false;



  public formGroup: FormGroup;
  public formGroupAdvanced: FormGroup;

  constructor(
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private akWorkflowService: AkeelaWorkflowService,
    private formBuilder: FormBuilder,
    private formBuilderAdvanced: FormBuilder,
    private _httpService: HttpService,
    private _error: ErrorService,
    private _search: SearchService,
    private _auth: AuthService,
    private localStorage: LocalService,
    public readonly swalTargets: SwalPortalTargets
  ) { }

  ngOnInit() {
    this.stage = this.akStageService.currentStage$.getValue();
    this.step = this.akStepService.currentStep$.getValue();
    this.workflow = this.akWorkflowService.currentWorkflow$.getValue();
    this.buildFormSimple();
    this.buildFormAdvanced();
    this.getChannels();
    this.getStates();
    this.getExecutives();
    this.getAgencys();
  }

  private buildFormSimple() {
    this.formGroup = this.formBuilder.group({
      search: ['']
    });
  }

  private buildFormAdvanced() {
    this.formGroupAdvanced = this.formBuilderAdvanced.group({
      procedure: [''],
      client: [''],

      c_type: [''],
      date_c_to: [''],
      date_c_from: [''],

      s_type: [''],
      date_s_to: [''],
      date_s_from: [''],

      m_type: [''],
      date_m_to: [''],
      date_m_from: [''],

      status: [''],
      channel: [''],
      agency: [''],
      executive: ['']
    });
  }

  advanced() {
    this.formGroupAdvanced.reset();
    this.c_date_between_active = false;
    this.m_date_between_active = false;
    const text = this.formGroup.get('search').value;
    if (!isNullOrUndefined(text) && text !== '') {
      this.formGroupAdvanced.controls['procedure'].setValue(text);
      this.formGroupAdvanced.controls['procedure'].patchValue(text);
      this.formGroupAdvanced.controls['client'].setValue(text);
      this.formGroupAdvanced.controls['client'].patchValue(text);
    }
  }

  check() {
    if (!this.disabled) {
      const text = this.formGroup.get('search').value;
      if (!isNullOrUndefined(text) && text !== '') {
        this.activeSearch = true;
        this.activeTrash = true;
      } else {
        this.activeSearch = false;
        this.activeTrash = false;
      }
    }
  }

  verify(type) {
    if (this.formGroupAdvanced.get(type).value === 'BETWEEN') {
      if (type === 'c_type') {
        this.c_date_between_active = true;
      } else {
        this.m_date_between_active = true;
      }
    } else {
      if (type === 'c_type') {
        this.c_date_between_active = false;
      } else {
        this.m_date_between_active = false;
      }
    }
  }

  search() {
    this.disabled = true;
    this.loading = true;
    let object = {};
    if (!isNullOrUndefined(this.formGroup.get('search').value) && this.formGroup.get('search').value !== '') {
      object = merge({ 'transactionNumber': this.formGroup.get('search').value }, object);
    }

    if (this.formGroup.status === 'VALID') {
      if (Object.keys(object).length > 0) {
        this.postSearch(object);
      } else {
        this.disabled = false;
      }
    } else {
      this.disabled = false;
    }
  }

  trash() {
    if (!this.disabled) {
      this.formGroup.reset();
      this.check();
    }
  }

  send() {
    this.loading = true;
    let object = {};
    if (!isNullOrUndefined(this.formGroupAdvanced.get('procedure').value) && this.formGroupAdvanced.get('procedure').value !== '') {
      object = merge({ 'transactionNumber': this.formGroupAdvanced.get('procedure').value }, object);
    }
    if (!isNullOrUndefined(this.formGroupAdvanced.get('status').value) && this.formGroupAdvanced.get('status').value !== '') {
      object = merge({ 'transacctionStatus': this.formGroupAdvanced.get('status').value }, object);
    }
    if (!isNullOrUndefined(this.formGroupAdvanced.get('channel').value) && this.formGroupAdvanced.get('channel').value !== '') {
      object = merge({ 'channelCode': this.formGroupAdvanced.get('channel').value }, object);
    }

    if (!isNullOrUndefined(this.formGroupAdvanced.get('client').value) && this.formGroupAdvanced.get('client').value !== '') {
      object = merge({ 'holderName': this.formGroupAdvanced.get('client').value }, object);
    }
    if (!isNullOrUndefined(this.formGroupAdvanced.get('agency').value) && this.formGroupAdvanced.get('agency').value !== '') {
      object = merge({ 'channelId': this.formGroupAdvanced.get('agency').value }, object);
    }
    if (!isNullOrUndefined(this.formGroupAdvanced.get('executive').value) && this.formGroupAdvanced.get('executive').value !== '') {
      object = merge({ 'systemUser': this.formGroupAdvanced.get('executive').value }, object);
    }


    if (!isNullOrUndefined(this.formGroupAdvanced.get('c_type').value) && this.formGroupAdvanced.get('c_type').value !== '') {
      if (!isNullOrUndefined(this.formGroupAdvanced.get('date_c_from').value) && this.formGroupAdvanced.get('date_c_from').value !== '') {
        if (this.formGroupAdvanced.get('c_type').value !== 'BETWEEN') {
          object = merge({
            'createdDate': {
              'startDate': this.dateStringToTimestamp(this.formGroupAdvanced.get('date_c_from').value),
            },
            'createdDateCondition': this.formGroupAdvanced.get('c_type').value
          }, object);
        } else {
          if (!isNullOrUndefined(this.formGroupAdvanced.get('date_c_to').value) && this.formGroupAdvanced.get('date_c_to').value !== '') {
            object = merge({
              'createdDate': {
                'startDate': this.dateStringToTimestamp(this.formGroupAdvanced.get('date_c_from').value),
                'endDate': this.dateStringToTimestamp(this.formGroupAdvanced.get('date_c_to').value)
              },
              'createdDateCondition': this.formGroupAdvanced.get('c_type').value
            }, object);
          } else {
            this.formGroupAdvanced.controls['date_c_to'].setErrors({ 'required': true });
          }
        }
      } else {
        this.formGroupAdvanced.controls['date_c_from'].setErrors({ 'required': true });
      }
    }

    if (!isNullOrUndefined(this.formGroupAdvanced.get('s_type').value) && this.formGroupAdvanced.get('s_type').value !== '') {
      if (!isNullOrUndefined(this.formGroupAdvanced.get('date_s_from').value) && this.formGroupAdvanced.get('date_s_from').value !== '') {
        if (this.formGroupAdvanced.get('s_type').value !== 'BETWEEN') {
          object = merge({
            'updateDate': {
              'startDate': this.dateStringToTimestamp(this.formGroupAdvanced.get('date_s_from').value),
            },
            'upDateCondition': this.formGroupAdvanced.get('s_type').value
          }, object);
        } else {
          if (!isNullOrUndefined(this.formGroupAdvanced.get('date_s_to').value) && this.formGroupAdvanced.get('date_s_to').value !== '') {
            object = merge({
              'updateDate': {
                'startDate': this.dateStringToTimestamp(this.formGroupAdvanced.get('date_s_from').value),
                'endDate': this.dateStringToTimestamp(this.formGroupAdvanced.get('date_s_to').value)
              },
              'upDateCondition': this.formGroupAdvanced.get('s_type').value
            }, object);
          } else {
            this.formGroupAdvanced.controls['date_s_to'].setErrors({ 'required': true });
          }
        }
      } else {
        this.formGroupAdvanced.controls['date_s_from'].setErrors({ 'required': true });
      }
    }

    if (!isNullOrUndefined(this.formGroupAdvanced.get('m_type').value) && this.formGroupAdvanced.get('m_type').value !== '') {
      if (!isNullOrUndefined(this.formGroupAdvanced.get('date_m_from').value) && this.formGroupAdvanced.get('date_m_from').value !== '') {
        if (this.formGroupAdvanced.get('m_type').value !== 'BETWEEN') {
          object = merge({
            'receivedDate': {
              'startDate': this.dateStringToTimestamp(this.formGroupAdvanced.get('date_m_from').value),
            },
            'receivedDateCondition': this.formGroupAdvanced.get('m_type').value
          }, object);
        } else {
          if (!isNullOrUndefined(this.formGroupAdvanced.get('date_m_to').value) && this.formGroupAdvanced.get('date_m_to').value !== '') {
            object = merge({
              'receivedDate': {
                'startDate': this.dateStringToTimestamp(this.formGroupAdvanced.get('date_m_from').value),
                'endDate': this.dateStringToTimestamp(this.formGroupAdvanced.get('date_m_to').value)
              },
              'receivedDateCondition': this.formGroupAdvanced.get('m_type').value
            }, object);
          } else {
            this.formGroupAdvanced.controls['date_m_to'].setErrors({ 'required': true });
          }
        }
      } else {
        this.formGroupAdvanced.controls['date_m_from'].setErrors({ 'required': true });
      }
    }

    if (this.formGroupAdvanced.status === 'VALID') {
      if (Object.keys(object).length > 0) {
        this._search.postSearch(object, this.stage, this.step, environment.paginator.size, environment.paginator.page).subscribe((response) => {
          this.response.emit({
            object: object,
            response: response,
            params: this.generateObjectResponse(object)
          });
          this.disabled = false;
          this.formGroup.reset();
          this.loading = false;
          this.check();
          swal.close();
        }, error => {
          this.loading = false;
          this.disabled = false;
          this.formGroup.reset();
          this.check();
          this._error.show(error);
        });
      } else {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  }

  postSearch(object, size?, page?) {
    let request = {};
    if (!isNullOrUndefined(size) && !isNullOrUndefined(page)) {
      request = {
        size: size,
        page: page
      };
    } else {
      request = {
        size: environment.paginator.size,
        page: 0
      };
    }
    const params: any = {
      name: 'account$transactionworkspacesearchpaginated$post',
      params: {
        request: request,
        path: {
          stage: this.stage,
          step: this.step,
          workspaceCode: this.localStorage.getItem('workspace'),
          user: this._auth.getUsername()
        },
        body: object
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response) => {
      this.response.emit({
        object: object,
        response: response,
        params: this.generateObjectResponse(object)
      });
      this.disabled = false;
      this.formGroup.reset();
      this.loading = false;
      this.check();
      swal.close();
    }, error => {
      this.loading = false;
      this.disabled = false;
      this.formGroup.reset();
      this.check();
      this._error.show(error);
    });
  }

  generateObjectResponse(object) {
    const response = [];
    for (const obj of Object.keys(object)) {
      response.push(this.getRealValue(obj, object[obj]));
    }
    return response;
  }

  getRealValue(key, value) {
    console.log(value);
    let ret = value;
    switch (key) {
      case 'channelCode':
        ret = this.channels.find((channel) => {
          return channel.alternateName === value;
        });
        break;
      case 'systemUser':
        ret = this.executives.find((executive) => {
          return executive.identifier === value;
        });
        break;

      case 'channelId':
        ret = this.agencys.find((agency) => {
          return agency.alternateName === value;
        });
        break;
      case 'transacctionStatus':
        ret = this.states.find((state) => {
          return state.code === value;
        });
        break;
      default:
        break;
    }

    console.log({
      name: key,
      value: ret
    });
    return {
      name: key,
      value: ret
    };
  }

  getChannels() {
    const params: any = {
      name: 'app_properties$channels$get',
      params: {
        request: {},
        path: {},
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params).subscribe((response) => {
      this.channels = response;
    }, error => {
      this.channels = {};
    });
  }

  getAgencys() {
    const params: any = {
      name: 'app_properties$agencies$get',
      params: {
        request: {},
        path: {},
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response) => {
      this.agencys = response;
    }, error => {
      this.agencys = {};
    });
  }

  getExecutives() {
    const params: any = {
      name: 'person$executives$get',
      params: {
        request: {},
        path: {},
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response) => {
      this.executives = response;
    }, error => {
      this.executives = {};
    });
  }

  getStates() {
    const params: any = {
      name: 'workflow$allstates$get',
      params: {
        request: {
        },
        path: {
          workflow: this.workflow
        },
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response) => {
      this.states = response;
    }, error => {
      this.states = {};
    });
  }



  dateStringToTimestamp(fecha) {
    if (!isNullOrUndefined(fecha)) {
      const myDate = fecha.split('-');
      if (myDate.length === 3) {
        const newDate = myDate[1] + ',' + myDate[2] + ',' + myDate[0];
        fecha = new Date(newDate).getTime();
      }
    } else {
      fecha = null;
    }
    return fecha;
  }

  onEnter() {
    this.search();
  }

}
