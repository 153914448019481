import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-face-api-analisis',
  templateUrl: './face-api-analisis.component.html',
  styleUrls: ['./face-api-analisis.component.css']
})
export class FaceApiAnalisisComponent implements OnInit {
  public items;
  @Input() set analisis(input) {
    this.items = input;

  }

  constructor() { }

  ngOnInit(): void {
  }



}
