import { Component, OnInit, Input } from '@angular/core';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { HttpService } from '@akeela/properties';
import { isNullOrUndefined } from 'util';
import { RequirementsService } from '@akeela/requirements';
import { LocalService } from '@akeela/local-storage';


@Component({
  selector: 'app-previusly-revisions',
  templateUrl: './previusly-revisions.component.html',
  styleUrls: ['./previusly-revisions.component.css']
})
export class PreviuslyRevisionsComponent implements OnInit {
  @Input() transactionNumber?;
  transaction = null;
  transactions = null;
  open = false;
  requirements = [];
  finish = false;
  active = null;
  transactionData;
  role;

  icon;
  initials;
  initial;
  name;


  constructor(
    private akeelaWorkflowService: AkeelaWorkflowService,
    public _httpService: HttpService,
    private _requerementsService: RequirementsService,
    private localStorage: LocalService

  ) { }

  ngOnInit() {

    this.role = this.localStorage.getItem('role');
    if (isNullOrUndefined(this.transactionNumber)) {
      this.akeelaWorkflowService.transactionData$.subscribe((response: any) => {
        if (response !== null) {

          
       
          this.initials = this.getInitials(response.holder.name, '');
          this.initial = this.getInitial(response.holder.name);  
          this.name = (response.person) ? this.capitalize(response.person.givenName) + " " + response.organization ? this.capitalize(response.person.familyName) : this.capitalize(response.organization.legalName):response.holder.name;
  

          this.transaction = response;
          this.getHistoryStatus(response.transactionNumber);
        }
      });
    } else {
      this.getTransactionData(this.transactionNumber);
    }
  }

  getInitials(givenName: string, familyName: string) {

    return (
      givenName.substr(0, 1).toUpperCase() +
      familyName.substr(0, 1).toUpperCase()
    );

  }


  getInitial(text) {

    let retorno = '';
    if (!isNullOrUndefined(text)) {
      retorno = text.substr(0, 1).toUpperCase();
    }
    return retorno;

  }


  getHistoryStatus(transactionNumber: any) {
    const params = {
      name: 'account$transactionstates$get',
      params: {
        request: {
          order: 'ASC'
        },
        path: {
          'transactionNumber': transactionNumber
        },
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((resp: any) => {
      
      
      for (const transaction of resp) {

        if (transaction.name === 'AC_INCOMPLETE') {
          this.getRequirementsTransaction(transaction.additionalType, transaction.status).then(respon => {
            // console.log('*******************************************+');
            this.requirements[transaction.additionalType] = respon;
            // console.log(transaction.additionalType);
            // console.log(this.requirements);
          });
        }
      }
      this.finish = true;
      this.transactions = resp;
      //console.log("entroooooooooooo",this.transactions);

    });
  }



  capitalize(word) {
    return word[0].toUpperCase() + word.slice(1);
  }

  getRequirementsTransaction(idTransaction, status, filterBy?) {
    return new Promise((resolve, reject) => {
      const params = {
        name: 'requirements$transaction_version_incompleted_rejected$get',
        params: {
          request: {},
          path: {
            idTransaction: idTransaction,
          },
          body: {}
        }
      };
      this._httpService.executeRequest(params.name, params.params, true).subscribe((resp: any) => {
        const config = {
          isFilterRequirementsByResponse: true,
          filterRequirementsByResponse: {
            state: 'INCOMPLETED'
          }
        };
        resolve(this._requerementsService.format_response(resp, config));
      }, error => {
        reject(null);
      });
    });
  }

  getTransactionData(transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role,
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.transaction = response;
      this.getHistoryStatus(this.transactionNumber);
    }, (error: any) => {
    });
  }

  updateShow(identifier) {
    if (this.active === null || this.active === '') {
      this.active = identifier;
    } else {
      if (this.active === identifier) {
        this.active = '';
      } else {
        this.active = identifier;
      }
    }
  }

}
