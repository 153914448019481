import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, DoCheck } from '@angular/core';
import { AkeelaSectionService, AkeelaStepService, AkeelaWorkflowService, AkeelaStageService } from '@akeela/workflow';
import { ActivatedRoute, Router } from '@angular/router';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { AuthService, ErrorService } from '@akeela/auth';
import { PathService, HttpService } from '@akeela/properties';
import { environment } from 'src/environments/environment';
import { isNullOrUndefined, isNull } from 'util';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { Subscription } from 'rxjs';
import { TransitionService } from '../../../../services/transition.service';
import swal from 'sweetalert2';
import { I18nService, I18nPipe } from '@akeela/i18n';
import { EmailService } from '../../../../services/email.service';
import { constants } from 'os';
import { MiddlewareService } from '../../../../services/middleware.service';
import { WorkflowService } from '../../../../services/workflow.service';
import { max } from 'rxjs/operators';
import { LocalService } from '@akeela/local-storage';
import { TemplateService } from '../../../../services/template.service';
import { PropertiesService } from '../../../../services';
import { TransactionService } from '../../../../services/transaction.service';
import { NotesService } from 'src/app/services/notes.service';


@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.css']
})
export class WorkflowComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {
  containerFluid;
  dataWorkflowCode_subscriber: Subscription;
  wfSubscription: Subscription;

  breadcrumb;
  borderBox: any = false;
  showSubHeaderStep = false;
  showWorkflow = false;
  showSubHeaderStepSubscription: Subscription;
  transitionSubscription: Subscription;
  breadcrumbSubscription: Subscription;
  workflowSubscription: Subscription;
  stageSubscription: Subscription;
  queryParamsSubscription: Subscription;
  stepServiceSubscriptor: Subscription;
  breadcrumbServiceSubscriptor: Subscription;
  isAuth = false;
  subcription_events: Subscription;
  @ViewChild('rq', {static: false}) rq: ElementRef;
  link = null;

  transactionData;
  transactionNumber;
  loading = true;
  sectionWfActual;
  animate_init_bottom = true;
  plan;

  title = '';
  subtitle = '';

  showWindow: Boolean = true;
  queryParams;
  env: any = environment;
  sizeMenuLeft;
  showSidebarLeft: boolean;
  showChangePass;
  currentWorkflowDefault;
  currentWorkflowChange = [];
  clientinfo = {};
  requirements = {};

  name_aux_input = '';
  lastname_aux_input = '';
  count_it_username_search = 0;

  subscriptions: Subscription[] = [];

  role;

  templates: any = {};
  wo;
  buttomModal;
  workflowCodeActive;
  optionsDataWF;
  sectionName;
  transitionsWfs;
  workflowStatusMenu;
  histotyBak;
  dropDownTransitionsWfs = {};
  workflowStatusSubscription: Subscription;
  workflowStatus = {};
  sectionInactive;
  elemento;
  pos;
  elemento2;
  clientCreated;
  continueSection;
  constructor(
    private akeelaSectionService: AkeelaSectionService,
    private route: ActivatedRoute,
    private router: Router,
    private stepService: AkeelaStepService,
    private _auth: AuthService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    private _pathService: PathService,
    private workflowService: AkeelaWorkflowService,
    private akStepService: AkeelaStepService,
    private akStageService: AkeelaStageService,
    private _transition: TransitionService,
    private _breadcrumbService: AkeelaBreadcrumbService,
    private _email: EmailService,
    private _i18n: I18nService,
    private i18nPipe: I18nPipe,
    private _httpService: HttpService,
    private _middleware: MiddlewareService,
    private _wfService: WorkflowService,
    private _error: ErrorService,
    private localStorage: LocalService,
    private templateService: TemplateService,
    private _properties: PropertiesService,
    private transactionService: TransactionService,
    public  _notes: NotesService

  ) {


  }




 
  public ngDoCheck() {

    if (this.showSidebarLeft && this._auth.isAuthenticated()) {

      this.sizeMenuLeft = true;

    } else {

      this.sizeMenuLeft = false;
    }


    if (this.showSidebarLeft && this._auth.isAuthenticated()) {

      this.sizeMenuLeft = true;

    } else {

      this.sizeMenuLeft = false;
    }
  }



  ngOnInit() {


    this.role = this.localStorage.getItem('role');
    this.subscriptions.push(this.workflowService.transactionData$.subscribe((transactionData: any) => {
      if (!isNullOrUndefined(transactionData)) {
        this.transactionData = transactionData;
        // this.modeForm(this.transactionData);
      }
    }));

    this.subscriptions.push(this.workflowService.currentWorkflow$.subscribe(workflowCode => {
      this.getDataWorkflowCode(workflowCode);
    }));


    

    this.subscriptions.push( this.workflowService.worflowEvents$.subscribe((event: any) => {
      if (!isNullOrUndefined(event)) {
        if (event && event.action === 'account_opening$raccount_response$post') {

          let msg = event.response? event.response.name:'';
          setTimeout(() => {
            swal.fire({
              title: '',
              html: "La solicitud fue respondida <b>" + this.i18nPipe.transform(msg)+ "</b> exitosamente",
            });
        }, 100);


           //name: "AC_INCOMPLETE"

         //  this.i18nPipe.transform('@i18n-mail-verify-account')

        } else if (event && event.action === 'openModal') {
          if (event && event.value === 'SHAREHOLDERS_MODAL') {
            this.workflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_LEGAL_FIELDS', 'hidden': true });
            this.workflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_NATURAL_FIELDS', 'hidden': false });
            this.workflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_PEP', 'hidden': false });
          }
        } else if (event && event.action === 'send_formValidationEvent_transaction_redirect') {
          
        //  this.redirectDashboard();
         
        }else if (event && ( event?.action === 'briefcase$ownbusiness$post'  ||  event.action === 'briefcase$labor_dependency$post' ))  {
          this.continueSection = true;
          this.getOptionsMenu(this.workflowCodeActive);

        }
      }
    }));



    this.subscriptions.push(  this.workflowService.currentWorkflowActive$.subscribe(resp => {

          debugger
        if(resp && this.workflowCodeActive  != resp ){

            this.workflowCodeActive = resp;
            this.getDataWorkflows();


          }

    }));

    

    this.subscriptions.push(this._auth.showChangePassword$.subscribe((data: boolean) => {

      this.showChangePass = data;
    }));

    this.subscriptions.push(this._akeelaSidebarMenu.showSidebarLeft.subscribe((data: boolean) => {
      this.showSidebarLeft = data;
    }));


    this.showWorkflow = false;
    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      
      if (!isNullOrUndefined(queryParams['wo'])) {
        this.wo = queryParams['wo'];
  
      }

      this.sectionName = null;
      

      if(queryParams?.section){
        this.sectionName = queryParams?.section;

        if(this.transitionsWfs){

        let step =    this.transitionsWfs[this.sectionName]?.active?.step;

        if(step){
          this.dropDownTransitionsWfs[step] = true;
        }
        }

      }else{
        this.sectionWfActual = false;

      }

      this.queryParams = queryParams;
    }));

    this.transactionNumber = this.workflowService.transactionNumber$.getValue();
    this.getTransactionData(this.transactionNumber);


    this.subscriptions.push(this._auth.isAuth$.subscribe((isAuth: boolean) => {
      this.isAuth = isAuth;
    }));

    this.showSubHeaderStepSubscription = this.akStepService.showSubHeaderStep$.subscribe(data => {
      this.showSubHeaderStep = data;
      this._transition.showWorkflow$.next(true);
    });


    this.transitionSubscription = this._transition.showWorkflow$.subscribe((showWorkflow) => {
      this.showWorkflow = showWorkflow;
    });

    this.workflowSubscription = this._wfService.showContainer$.subscribe((response) => {
      this.showWindow = response;
    });


    this.stageSubscription = this.akStageService.showHeaderStage$.subscribe(data => {

      const stage = this.akStageService.currentStage$.getValue();

      if ((stage === 'DOC_SUPPORT' || stage === 'SUMMARY_ACCOUNT_OPENING' || stage === 'PRODUCT_INFORMATION' || stage === 'CLIENT_IDENTIFICATION' || stage === 'ACCOUNT_CONTRACT')) {

        this.link = true;

      } else {

        this.link = false;

      }


      this.clientinfo = {};
      this.clientinfo['name'] = "clientinfo";
      this.clientinfo['description'] = "@i18n-workflow-name-accountopening";

      if (this.workflowService.currentWorkflow$.getValue() === 'ACCOUNT_OPENING') {

        this.currentWorkflowDefault = 'ACCOUNT_OPENING';

      }

      if (this.workflowService.currentWorkflow$.getValue() === 'INTERVIEW') {

        this.currentWorkflowDefault = 'INTERVIEW';
        this.clientinfo['name'] = 'interview';
        this.clientinfo['description'] = "@i18n-workflow-name-interview";

      }

      this.setCurrentWorkflowChange();

      this._middleware.isModuleRequirementsActive().then((response) => {

        if (response === true) {

          if (this.workflowService.currentWorkflow$.getValue() == 'DOC_SUPPORT') {

            this.currentWorkflowDefault = 'REQUIREMENT';

          }

          this.requirements['name'] = "requirements";
          this.requirements['description'] = "@i18n-workflow-name-docsupport";
        }

        this.setCurrentWorkflowChange();
      });

      if (data === true) {

        this.borderBox = true;

      } else {

        this.borderBox = false;

      }

      this.subscriptions.push(this.akStepService.showHeaderStep$.subscribe(data2 => {


        if (data2 || data) {

          this.borderBox = true;

        } else {

          this.borderBox = false;

        }

      }));

    });

    this.processBreacrum();
    if (this.localStorage.getItem('showSidebarLeft') === null) {
      if (screen.width > 768) {
        this._akeelaSidebarMenu.setshowSidebarLeft(true);
      }
    } else {

      if (this.localStorage.getItem('showSidebarLeft') === 'true') {
        this._akeelaSidebarMenu.setshowSidebarLeft(true);
      } else {
        this._akeelaSidebarMenu.setshowSidebarLeft(false);
      }
    }


    this.subscriptions.push(this.workflowService.worflowEvents$.subscribe((event: any) => {
      if (event) {
        console.log('Respuesta cliente: ');
        console.log(event);
        if (event.action === 'setNewClient') {
          this._properties.updateClient$.next(true);
          const properties: object = {};
          const url = environment.urlClient + '/create-new-password/' + event.response.identifier + '/' + event.response.name;
          const portal = environment.theme.site.name;
          properties['template'] = 'templates/' + this._i18n.lang + '/' + 'userconfirmation.html';

          let site_header = environment.theme.site.name;
          if (!isNullOrUndefined(environment.theme.image)) {
            if (!isNullOrUndefined(environment.theme.image.active)) {
              if (environment.theme.image.active) {
                site_header = '<img src="' + environment.theme.image.urlImageHeader + '" ></img>';
              }
            }
          }

          properties['attr'] = [
            {
              'key': '%%USERNAME%%',
              'value': event.response.identifier
            },

            {
              'key': '%%URLDESCRIPTION%%',
              'value': this.i18nPipe.transform('@i18n-mail-verify-account')
            },
            {
              'key': '%%NOMBRE%%',
              'value': event.response.person.givenName
            },
            {
              'key': '%%PORTAL%%',
              'value': portal
            },
            {
              'key': '%%URL%%',
              'value': url
            },
            {
              'key': '%%PORTAL%%',
              'value': environment.theme.site.name
            },
            {
              'key': '%%FONT_COLOR%%',
              'value': environment.theme.fontColor
            },
            {
              'key': '%%BACKGROUND_COLOR%%',
              'value': environment.theme.backgroundColor
            },
            {
              'key': '%%PROCEDURE%%',
              'value': environment.procedureMail
            },
            {
              'key': '%%BACKGROUND_BORDER%%',
              'value': environment.theme.backgroundColorBorderMail
            },
            {
              'key': '%%ALIAS_APP%%',
              'value': site_header
            },
            {
              'key': '%%TEXTFOOTER%%',
              'value': !isNullOrUndefined(environment.theme.textFooterMail) ? environment.theme.textFooterMail : ''
            }
          ];



          const params = {
            name: 'person$associate_client_systemuser$post',
            params: {
              request: {
                'username': event.response.identifier,
                'systemUser': this._auth.getUsername()

              },
              path: {},
              body: {}
            }
          };




          this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
            // MARIO: Comentado solo en Facebank, ya que acá no se enviará correo, solicitado por Joa.
            // Mario: Posterior a comentarlo, se me solicito descomentarlo, temporalmente
            // this.sendMail(event.response.person.email, properties, 'NUEVO USUARIOS');

            // if (!isNullOrUndefined(this.env.sendMailNewClient)) {
            //   if (this.env.sendMailNewClient === true) {
            //     console.log('Nuevo Cliente ' + this.env.sendMailNewClient);
            //     this.sendMail(event.response.person.email, properties, 'NUEVO USUARIOS');
            //   }
            // }


           this.clientCreated = event.response;
           this.setNoteNewclient(this.clientCreated);


            swal.fire({
              title: this.i18nPipe.transform('@i18n-text-user-registred'),
              html: this.i18nPipe.transform('@i18n-text-user-registred-detail-pw'),
            });

            this.queryParams = {
              customersWithoutTransactions: true
            };
            // this.queryParams = {};
            // this.route.queryParams.subscribe(queryParams => {
            //   if (!isNullOrUndefined(queryParams)) {
            //     queryParams
            //     this.router.navigate([environment.staticLinks.listClients], queryParams);
            //   }
            // });
            this.router.navigate([environment.staticLinks.listClients], this.queryParams);
            


          }));




        } else if (event.action === 'account_opening$raccount_response$post') {
          this.router.navigateByUrl(
            this.router.createUrlTree([environment.staticLinks.accountContractAnalisys],
              {
                queryParams: {}
              }
            )
          );
        } else if (event.action === 'fn_load_executive') {
          const obj = {
            section: 'CREATE_USER_NEW_CLIENT',
            response: {
              "systemUser": this._auth.getUsername(),
              "systemUserView": (this._auth.getSession() ? this._auth.getSession().firstName : '') + ' ' + (this._auth.getSession() ? this._auth.getSession().lastName : '')
            }
          };
          this.akeelaSectionService.externalUpdateSectionForm$.next(obj);
        } else if (event.action === 'fn_load_agency') {
          //alert('aqui')
          const params = {
            name: 'person$executives$get',
            params: {
              request: {},
              path: {},
              body: {}
            }
          };

          this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((resp: any) => {
            const executive_aux = resp.filter((executive) => {
              return executive.identifier === this._auth.getUsername();
            });

            if (resp.length > 0) {
              const obj = {
                section: 'CREATE_USER_NEW_CLIENT',
                response: {
                  'endUser': {
                    'salesManagerUser': {
                      'localBusiness': {
                        'name': executive_aux[0]?.localBusiness?.identifier
                      }
                    }
                  },
                  'nameAgencyView': executive_aux[0]?.localBusiness?.name
                }
              };
              this.akeelaSectionService.externalUpdateSectionForm$.next(obj);
            }
          }));
        } else if (event.action === 'fn_load_pre_username') {
          const obj = {
            section: 'CREATE_USER_NEW_CLIENT',
            response: {
              'endUser': {
                'identifier': 'mariodiaz'
              }
            }
          };
          this.akeelaSectionService.externalUpdateSectionForm$.next(obj);
        } else if (event.action === 'fn_load_name_aux') {
          if (this.name_aux_input !== event.text) {
            this.count_it_username_search = 0;
            this.name_aux_input = event.text;
            this.loadUsername();
          }
        } else if (event.action === 'fn_load_lastname_aux') {
          if (this.lastname_aux_input !== event.text) {
            this.count_it_username_search = 0;
            this.lastname_aux_input = event.text;
            this.loadUsername();
          }
        } else if (event.action === 'account_opening$saveProductNumber$post') {
          console.log(event + this.transactionData?.transactionNumber);
          if (event.response) {
            this.getTransactionDataSub(this.transactionData?.transactionNumber).subscribe((transactionData: any) => {
              this.workflowService.transactionData$.next(transactionData);
            });
          }
        } else if (event.action === 'interview$interview$post') {
          console.log('refresh de la entrevistas');
        } else if (event.response === 'showErrorNeedLegalRepresentative') {
          swal.fire({
            title: this.i18nPipe.transform(''),
            html: this.i18nPipe.transform('@i18n-text-required-legal-representative'),
          });
        }
      }
    }));

    

    this.subscriptions.push( this.workflowService.resetEstatus$.subscribe((response) => {
      if (!isNullOrUndefined(response) && this.workflowCodeActive) {
        

     //  this.workflowStatus = response;
     if(this.workflowCodeActive && this.workflowCodeActive != 'ACCOUNT_OPENING' && this.workflowCodeActive != 'INTER_IDENTITY_VERIF' ){
      this.resetStatus();

     //  this.getIndicatorsProgres();
     }
        
      }
    }));
    
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.templates = this.templateService.getTemplates();
      console.log(this.templates);
    }, 1);
  }


  modeForm(response) {
    if (this.localStorage.getItem('workspaceReadonly') === 'true' || this.localStorage.getItem('readonly') === 'true') {
      this.akStepService.setModeCurrentStep(true);
    } else {
      if (this.akStageService.currentStage$.getValue() !== 'CREATE_USER_ACCOUNT_OPENING') {
        this.transactionData = response;
        const temp = response.accountStatus[0].additionalType;
        if (this.localStorage.getItem('profile') === 'SALES_MANAGER') {
          // SEPARAMOS EN DOS CASOS:
          // CASO EJECUTIVO:

          if (!isNullOrUndefined(response)) {

            if (temp === 'AC_PENDING' || temp === 'AC_RECEIVED' || temp === 'AC_REJECTED') {

              this.akStepService.setModeCurrentStep(true);
            } else {
              if (!isNullOrUndefined(response.takenByRol) && this.role !== '' && response.takenByRol !== 'USER' && !isNullOrUndefined(this.role)) {
                if (response.takenByRol.trim() !== this.role.trim() && response.takenByRol !== '') {
                  this.akStepService.setModeCurrentStep(true);
                  if (!this.localStorage.getItem('transactionModeEditShow')) {
                    const error = {
                      error: {
                        status: 500,
                        code: 'AKC-014',
                        titleLabel: '',
                        name: ''
                      }
                    };
                    this._error.show(error, 'Mensaje!', this.i18nPipe.transform(response.takenByRol));
                    this.localStorage.setItem('transactionModeEditShow', true);
                  }
                } else {
                  this.akStepService.setModeCurrentStep(false);
                  if (!isNullOrUndefined(this.localStorage.getItem('transactionNumber'))) {
                    this.localStorage.setItem('transactionModeEdit', response.transactionNumber);
                  }
                }
              } else {
                this.akStepService.setModeCurrentStep(false);
              }
            }
          } else {
            this.akStepService.setModeCurrentStep(false);
          }
        } else if (this.localStorage.getItem('profile') === 'ANALIST') {
          // SEPARAMOS EN DOS CASOS:
          // CASO ANALISTA:
          if (!isNullOrUndefined(response)) {
            if (temp === 'AC_PENDING') {
              if (temp === 'AC_PENDING' && this.localStorage.getItem('takenedit') === response.transactionNumber) {
                this.akStepService.setModeCurrentStep(false);
              } else {
                this.akStepService.setModeCurrentStep(false);
              }
            } else {
              this.akStepService.setModeCurrentStep(true);
            }
          } else {
            this.akStepService.setModeCurrentStep(false);
          }

        } else {
          this.akStepService.setModeCurrentStep(true);
        }
      } else {
        this.akStepService.setModeCurrentStep(false);

      }

    }

  }
 

  loadUsername() {
    const limit_min = 6;
    const limit_max = 12;
    const max_intentos = 10;
    let username_opt = '';
    if (this.name_aux_input !== '' && this.lastname_aux_input !== '' && !isNullOrUndefined(this.name_aux_input) && !isNullOrUndefined(this.lastname_aux_input)) {
      username_opt = this.name_aux_input.charAt(0) + this.lastname_aux_input;
    } else {
      username_opt = '';
    }
    if (username_opt !== '') {
      this.next(username_opt, limit_min, limit_max, max_intentos);
    }
  }

  next(username_opt, limit_min, limit_max, max_intentos) {
    if (max_intentos > this.count_it_username_search) {
      const username_aux = this.generateUsername(username_opt, limit_min, limit_max);
      this.validateUsername(username_aux).then((resp) => {
        this.count_it_username_search++;
        if (resp === false) {
          this.setUsernameForm(username_aux);
        } else {
          this.next(username_opt, limit_min, limit_max, max_intentos);
        }
      });
    } else {
      this.setUsernameForm('');
    }
  }

  generateUsername(username_opt, limit_min, limit_max) {
    if (username_opt.length < limit_min && username_opt !== '') {
      username_opt += this.generateNumberRandom(limit_min - username_opt.length);
    } else if (username_opt.length >= limit_max && username_opt !== '') {
      username_opt = username_opt.substr(0, limit_max - 5) + this.generateNumberRandom(4);
    } else if ((limit_max - username_opt.length - 2) > 0 && username_opt !== '') {
      username_opt = username_opt + this.generateNumberRandom((limit_max - username_opt.length) - 2);
    } else {
      if (username_opt !== '') {
        username_opt = username_opt + this.generateNumberRandom((limit_max - username_opt.length));
      }
    }
    return this.removeAccents(username_opt.replace(/ /g, '').toLowerCase());
  }

  removeAccents = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  generateNumberRandom(digits, min?, max?) {
    min = !isNullOrUndefined(min) ? min : 1;
    max = !isNullOrUndefined(max) ? max : 4;
    if (digits === '*') {
      return Math.floor(Math.random() * Math.pow(10, Math.random() * (max - min) + min));
    } else {
      return Math.floor(Math.random() * Math.pow(10, digits));
    }
  }

  setUsernameForm(username) {
    const obj = {
      section: 'CREATE_USER_NEW_CLIENT',
      response: {
        'endUser': {
          'identifier': username
        }
      }
    };
    this.akeelaSectionService.externalUpdateSectionForm$.next(obj);
  }

  validateUsername(username) {
    return new Promise((resolve, reject) => {
      const params = {
        name: 'person$exists_username$get',
        params: {
          request: {
            username: username
          },
          path: {},
          body: {}
        }
      };
      this.subscriptions.push(this._httpService.executeRequest(params.name, params.params).subscribe((response: any) => {
        resolve(response);
      }, (error: any) => {
        resolve(false);
      }));
    });

  }


  setCurrentWorkflowChange() {
    this.currentWorkflowChange = [];
    if (!(Object.keys(this.clientinfo).length === 0)) {
      this.currentWorkflowChange.push(this.clientinfo);
    }
    if (!(Object.keys(this.requirements).length === 0)) {
      this.currentWorkflowChange.push(this.requirements);
    }



  }



  getDataWorkflowCode(workflow) {
    if (!isNullOrUndefined(workflow)) {
      this.subtitle = '';
      const param = {
        name: 'workflow$workflow$get',
        params: {
          request: { content: 'none' },
          path: { workflow: workflow },
          body: {}
        }
      };
      this.dataWorkflowCode_subscriber = this._httpService.executeRequest(param.name, param.params, true).subscribe((response: any) => {
        this.subtitle = response.name;
      });
    }
  }



  getTransactionData(transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role,
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.loading = false;
      this.transactionData = response;
    }, (error: any) => {
      this.loading = false;
    }));
  }

  getTransactionDataSub(transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role,
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }









  setBreacrumb(breadcrumbKey) {
    this._breadcrumbService.setbreadcrumbKey(breadcrumbKey);
  }

  processBreacrum() {
    let breadcrumb = null;
    this.stepServiceSubscriptor = this.stepService.breadcrumbStep$.subscribe(breadcrumbKey => {


      if (breadcrumbKey) {
        this.setBreacrumb(breadcrumbKey);
      } else {
        this._breadcrumbService.setbreadcrumbOptions(null);
      }
    });

  }

  deleteSubscription() {

    if (this.wfSubscription) {
      this.wfSubscription.unsubscribe();
    }

    if (this.dataWorkflowCode_subscriber) {
      this.dataWorkflowCode_subscriber.unsubscribe();
    }

    if (this.showSubHeaderStepSubscription) {
      this.showSubHeaderStepSubscription.unsubscribe();
    }

    if (this.breadcrumbSubscription) {
      this.breadcrumbSubscription.unsubscribe();
    }

    if (this.transitionSubscription) {
      this.transitionSubscription.unsubscribe();
    }

    if (this.workflowSubscription) {
      this.workflowSubscription.unsubscribe();
    }

    if (this.stageSubscription) {
      this.stageSubscription.unsubscribe();
    }

    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }

    if (this.stepServiceSubscriptor) {
      this.stepServiceSubscriptor.unsubscribe();
    }

    if (this.breadcrumbServiceSubscriptor) {
      this.breadcrumbServiceSubscriptor.unsubscribe();
    }

  }



  sendMail(emailTo, properties, subjectOf) {


    const email_message = {
      identifier: 1,
      dateOfThing: {},
      status: null,
      sender: null,
      text: null,
      type: 'text/html',
      subjectOf: subjectOf,
      toRecipient: emailTo
    };
    const template = this._email.getTemplate(properties);
    template.subscribe(template_mail => {
      const response = this._email.sendMail(
        email_message,
        properties,
        template_mail
      );
      this.subscriptions.push(response.subscribe(mail_result => {
        console.log(mail_result);
      }, error => {
        console.log(error);

      }));
    }, error => {
      console.log(error);
    });

  }



  ngOnDestroy() {

    if(this.workflowStatusSubscription){
      this.workflowStatusSubscription.unsubscribe();
    }

    this.deleteSubscription();
    this.akeelaSectionService.background$.next(false);
    if (this.subscriptions) {
      // prevent memory leak when component destroyed
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  // (response) {
  //   if (response === 'finishedit') {
  //     this.finishEditMode(this.transactionData.transactionNumber);
  //   } else if (response === 'requirements') {
  //     // redirecciona a requirements wf
  //     this.router.navigateByUrl(this.router.createUrlTree
  //       ([environment.staticLinks.requirementsUpload],
  //       {
  //         queryParams: this.queryParams
  //       }
  //       )
  //     );
  //   } else {
  //     this.router.navigateByUrl(this.router.createUrlTree
  //       ([environment.staticLinks.detailClientResumen],
  //       {
  //         queryParams: {}
  //       }
  //       )
  //     );
  //   }
  // }

  finishEditMode(transactionNumber) {
    const params = {
      name: 'account_opening$finishediting$put',
      params: {
        request: {},
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.localStorage.removeItem('takenedit');
      this.showSubHeaderStep = false;

      // refresco la transacción
      this.transactionService.getTransactionData(transactionNumber, 'USER', this.localStorage.getItem('profile')).subscribe((transactionData)=>{
        this.workflowService.transactionData$.next(transactionData);
      });

      this.router.navigate([environment.staticLinks.resumenUser], { queryParams: {} });
    }, (error: any) => {
      this._error.show(error);
    }));
  }


  setShowChangePassword(param) {
    this._auth.setShowChangePassword(param);
  }

  get_response_breadcrub(response) {

    this.akStepService.currentStep$.subscribe((responses) => {
     // this.modeForm(this.transactionData);

      if (!isNullOrUndefined(responses)) {
        // Mario: Otra condición, si el modulo es el de entrevista, para cualquier perfil, en cualquier momento se puede guardar ese step
        // por lo cual acá condicionaré que para ese workflow, stage y step, sea en modo de edición
        if (responses === 'CLIENT_INTERVIEW') {
          this.akStepService.setModeCurrentStep(false);
        }
      }
    });


    if (response === 'finishedit') {
      console.log('SI FINALIZARA EL MODO DE EDICIÓN');

      this.finishEditMode(this.transactionData.transactionNumber);
    } else if (response === 'finishview') {
      this.finishViewMode();
    } else if (!isNullOrUndefined(response) && response !== '') {
      this.router.navigateByUrl(this.router.createUrlTree
        (['private/' + response],
        {
          queryParams: this.queryParams
        })
      );
    }
    // if (response === 'REQUIREMENT') {
    //   // redirecciona a requirements wf

    //   this.router.navigateByUrl(this.router.createUrlTree
    //     ([environment.staticLinks.requirementsUpload],
    //     {
    //       queryParams: this.queryParams
    //     }
    //     )
    //   );

    // } else {

    //   this.router.navigateByUrl(this.router.createUrlTree
    //     ([environment.staticLinks.detailClientResumen],
    //     {
    //       queryParams: {}
    //     }
    //     )
    //   );

    // }
  }



  finishViewMode() {
    this.localStorage.removeItem('readonly');
    this.router.navigate([environment.staticLinks.viewAccountOpeningAnalisys], { queryParams: {} });
  }

  eventBreadcrumb(response) {
    if (response.action === 'navigate') {
      if (!isNullOrUndefined(this.localStorage.getItem('transactionModeEdit'))) {
        this.breakfreeTransactionEdit(); // Mario: este caso era de liberación individual, pero debido a que ahora se va a liberar por usuario, se comenta para ver el comportamiento
        this.breakfreeTransactionsByUser();
      } else {
        this.breakfreeTransactionsByUser();
      }
    }
  }

  breakfreeTransactionsByUser() {
    const configuration = {
      name: 'account$finish_editing_role_by_user$put',
      params: {
        path: {},
        request: {
          role: 'USER',
          systemUser: this._auth.getUsername()
        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.workflowService.transactionData$.next(null);

    }, error => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.workflowService.transactionData$.next(null);

    });
  }


  breakfreeTransactionEdit() {
    const configuration = {
      name: 'account_opening$finish_editing_role$put',
      params: {
        path: {
          transactionNumber: this.localStorage.getItem('transactionModeEdit')
        },
        request: {
          role: 'USER',
          systemUser: this._auth.getUsername()
        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.workflowService.transactionData$.next(null);

    }, error => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.workflowService.transactionData$.next(null);

    });
  }



getDataWorkflows() {
  const params = {
    name: 'workflow$workflow$get',
    params: {
      request: {
        content:'none'
      },
      path: {
        "workflow":this.workflowCodeActive,
      },
      body: {}
    }
  };

  let sectionBack = null;

 this._httpService.executeRequest(params.name, params.params, true).subscribe((result: any) => {
   let endSection    = null;
      if (result) {
        this.optionsDataWF = result;

        this.initWorkflowActive();

      }


  });
}


 


initWorkflowActive(){

  if(this.workflowCodeActive &&  this.optionsDataWF?.endpointStatus){

    if( !this.optionsDataWF?.productCode){

      this.workflowService.transactionData$.next(null);
      this.workflowService.setTransactionNumber(null);
      this.containerFluid = false;

    }else{
      this.containerFluid = true;


    }

    this.workflowService.setWfTransitionsActive(true);
    this.getOptionsMenu(this.workflowCodeActive);


  }else{
    this.workflowService.setWfTransitionsActive(false);
    this.workflowStatusMenu = null;
    this.transitionsWfs = null;
    this.containerFluid = false;
  }
  
  
}


redirectDashboard(){


 
  if(this.workflowCodeActive &&  this.optionsDataWF?.endpointStatus){

  
   let tst =  this.transactionData?.transactionNumber || this.localStorage.getItem('redirectTransation');

    if(this.optionsDataWF?.productCode || tst){



      const queryParams = {
        role: 'CLIENT',
        profile: this.localStorage.getItem('profile'),
        audience: 'NATURAL',
        'transactionNumber' : tst
        
      };
    
    
      this.router.navigate(['console/transaction-dashboard'], { queryParams: queryParams });
    

    }else{

      

      const queryParams = {
        role: 'CLIENT',
        profile: this.localStorage.getItem('clientUsername'),
        audience: 'NATURAL',
        id : this.localStorage.getItem('clientUsername')
      };
    
    
      this.router.navigate(['console/client-data'], { queryParams: queryParams });
    
     
    }


  }
  



}


getOptionsMenu(wf) {
  const params = {
    name: 'workflows$stagesMenu$get',
    params: {
      request: {
        role: this.localStorage.getItem('role'),
        audience: this.localStorage.getItem('clientaudience'),
        profile: this.localStorage.getItem('profile'),
        idPerson:  this.localStorage.getItem('clientIdentifier')


      },
      path: {
         // workflowCode: this.transactionData?.plan?.
           workflowCode: wf


      },
      body: {}
    }
  };

  let sectionBack = null;

 this.subscriptions.push( this._httpService.executeRequest(params.name, params.params, true).subscribe((result: any) => {
   let endSection    = null;
      if (result) {
        
        let transitionsWfs = [];

         for (const dataStage of result) {
          if (dataStage) {
              if (dataStage.howToSteps) {
                  for (const dataStep of dataStage.howToSteps) {

                      if (dataStep.sections) {

                        for (const dataSection of dataStep.sections) {

                          endSection = dataSection.alternateName;


                          if(this.sectionName == dataSection.alternateName){
                            this.dropDownTransitionsWfs[dataStep.alternateName] = true;

                          }


                        transitionsWfs[dataSection.alternateName]={};
                        transitionsWfs[dataSection.alternateName]['active'] = {section:dataSection.alternateName,step:dataStep.alternateName,stage:dataStage.alternateName,wf: wf} ;
                        

                        if(sectionBack){
                          
                          transitionsWfs[sectionBack?.section]['new'] = {section:dataSection.alternateName,step:dataStep.alternateName,stage:dataStage.alternateName,wf: wf } ;
                          transitionsWfs[dataSection.alternateName]['back'] = sectionBack;
                          sectionBack =  {section:dataSection.alternateName,step:dataStep.alternateName,stage:dataStage.alternateName,wf: wf};

                        }else{

                          sectionBack =  {section:dataSection.alternateName,step:dataStep.alternateName,stage:dataStage.alternateName,wf: wf};
                        
                        }
                      }

                      }

                  }
              }
          }

      }

      if( this.localStorage.getItem('redirectTransation') || this.optionsDataWF?.productCode){

        this.histotyBak = 'console/transaction-dashboard';
        transitionsWfs[endSection]['end'] = '/'+this.histotyBak;

      }else{

        this.histotyBak = 'console/client-data';

        transitionsWfs[endSection]['end'] = '/'+this.histotyBak;

      }

      this.workflowService.setWfTransitions(transitionsWfs);

      this.transitionsWfs =  transitionsWfs;
      this.workflowStatusMenu =  result;

    
        if(this.continueSection){
          
          this.setRedirectSectionSection(true);
        }


        this.resetStatus();


      }else{

        this.transitionsWfs = null;
         this.workflowStatusMenu = null;

      }


  }));
 
}



setRedirectSectionSection(mark?){

  if(mark){

    this.continueSection = false;

  }

  

  if(this.transitionsWfs){

    const res = this.transitionsWfs[ this.sectionName];
  if(res?.new){
    
    const newResp =  res?.new;




    if(mark){

      this.continueSection = false;
  
       console.clear();  

      console.log("************ test ******************");
      console.log("");
      console.log("Section Actual: ",this.sectionName);
      console.log("new sections : ", newResp?.section);
      console.log("");
      console.log("OBJ completo");
      console.log(newResp);
      console.log("");
      console.log("************ fin test ******************");
      console.log("");
  
    }


      this.redirectSection( newResp?.wf,newResp?.stage, newResp?.step, newResp?.section);
 




  }else if(this.transitionsWfs[ this.sectionName]?.end){

     this.redirectToUrl(res?.end);

  }
  

}

}




redirectToUrl(url) {
  const queryParams = {
      role: this.localStorage.getItem('role'),
      profile: this.localStorage.getItem('profile'),
      audience: this.localStorage.getItem('audience'),
  };

  
this.router.navigate([url], { queryParams: queryParams });
}
 

resetStatus() {   
   
  let params;

  if( this.workflowCodeActive && !this.optionsDataWF?.productCode && this.optionsDataWF?.endpointStatus ){
      params = {
  
      name: this.optionsDataWF?.endpointStatus,
      params: {
        request: {
          role: this.localStorage.getItem('role'),
          audience: this.localStorage.getItem('clientaudience'),
          id_person:  this.localStorage.getItem('clientIdentifier'),
          wfp_code: this.workflowCodeActive

        },
        path: {
       
        },
        body: {}
      }
    };

  }else if( this.workflowCodeActive && this.optionsDataWF?.productCode && this.optionsDataWF?.endpointStatus ){


     params = {
  
      name: this.optionsDataWF?.endpointStatus,
      params: {
        request: {
          role: this.localStorage.getItem('role'),
          audience: this.localStorage.getItem('audience')

        },
        path: {
            transactionNumber: this.transactionData?.transactionNumber,
            idPerson:this.transactionData?.holder?.identifier,
            workflowCode: this.workflowCodeActive

        },
        body: {}
      }
    };


  }
  
    if(this.workflowStatusSubscription){
      this.workflowStatusSubscription.unsubscribe();
    }

    if(params){
    this.workflowStatusSubscription =  this._httpService.executeRequest(params.name, params.params, true).subscribe((result: any) => {
      let sectionBack = null;
      let sectionNext = null;

        if (result) {

         //   this._akeelaSidebarMenu.setshowSidebarLeft(true);
            let WorkflowStatus = [];
            let stepsStates;
            let sectionsStates;

            for (const dataStage of result) {
                if (dataStage) {

                    stepsStates = [];
                    if (dataStage.howToSteps) {
                        for (const dataStep of dataStage.howToSteps) {
                            sectionsStates = [];

                            if (dataStep.sections) {

                                for (const dataSection of dataStep.sections) {                                 
                                    sectionsStates[dataSection.alternateName] = {
                                        code: dataSection.alternateName,
                                        state: dataSection.status,
                                    };
                                }
                            }

                            stepsStates[dataStep.alternateName] = {
                                code: dataStep.alternateName,
                                state: dataStep.status,
                                sections: sectionsStates
                            };

                        }
                    }
                }


                WorkflowStatus[dataStage.alternateName] = {
                    code: dataStage.alternateName,
                    state: dataStage.status,
                    steps: stepsStates
                };

             

            }

                this.workflowStatus= WorkflowStatus;

                //&&  this.sectionName 


                if(this.workflowCodeActive && this.workflowCodeActive != 'ACCOUNT_OPENING' && this.workflowCodeActive != 'INTER_IDENTITY_VERIF'  ){

                  this.getworkflowCodeContinue();

                  let sectionRedirect = this.localStorage.getItem('sectionRedirect');

                  if(sectionRedirect){
                    this.localStorage.removeItem('sectionRedirect');
                    this.redirectSection(this.sectionInactive?.urlWf,this.sectionInactive?.stage,this.sectionInactive?.step,this.sectionInactive?.section);

                  }
                  
                }
          
              
        }
    });
   
  }

  }


  getworkflowCodeContinue(){


    let firstSection = null;
    let firstSectionData = null;



 if (this.workflowStatusMenu) {
       
   for (const dataStage of this.workflowStatusMenu) {
     if (dataStage) {
         if (dataStage.howToSteps) {
             for (const dataStep of dataStage.howToSteps) {

                 if (dataStep.sections) {

                   for (const dataSection of dataStep.sections) {

                     if(dataSection?.additionalType != 'modal'){

                       if(!firstSection){
                         firstSectionData =  {urlWf:this.workflowCodeActive, stage:dataStage?.alternateName,step:dataStep?.alternateName,section: dataSection?.alternateName };

                       }

                       let stage = this.workflowStatus[dataStage?.alternateName];
                       let step =  stage?.steps[dataStep?.alternateName];
                       let section = step?.sections[dataSection?.alternateName];


                       if((section?.state != 'COMPLETED') && !this.sectionInactive){

                         this.sectionInactive =  {urlWf:this.workflowCodeActive, stage:dataStage?.alternateName,step:dataStep?.alternateName,section: dataSection?.alternateName };


                       }

                     }
                   

                  }

                 }

             }
         }
     }

 }
 if(!this.sectionInactive){
   this.sectionInactive = firstSectionData;
 }

}

return this.sectionInactive;
}


redirectSection(urlWf, stage,step,section ) {

  this.dropDownTransitionsWfs[step] = true;
  const prefixed = 'private';
 let url = [urlWf + '/stages/' + stage + '/steps/' + step];

  const queryParams = {
    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
    section: section,

  };

  
  this.buttomModal = false;
  this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });

}



openModalMenu() {
  this.animate_init_bottom = false;
   this.buttomModal = true;
 }

  setNoteNewclient = async (params) =>{
const client = this.clientCreated;
    const env: any = environment;
   
  const note = client?.personMarketType?.audienceType == 'NATURAL'?
  this.i18nPipe.transform(`Cliente creado de Tipo ${client?.personMarketType?.name}, Email ${client?.person?.email}, Teléfono ${client?.person?.otherPhone || client?.person?.telephone}`):
  this.i18nPipe.transform(`Cliente creado de Tipo ${client?.personMarketType?.name}, Email ${client?.person?.email}, Teléfono ${client?.person?.otherPhone || client?.person?.telephone}`);

  const nameClient = client?.personMarketType?.audienceType == 'NATURAL'?
  `${client?.person?.givenName} ${client?.person?.familyName}`:
  `${client?.adminOrganization?.legalName}`

     const data =  {
      "environment_code": env?.code || env?.theme?.images,
       "type": "CLIENT",
       "show_note": "TRUE",
       "note_extra_data_json": "",
       "client": client?.identifier,
       "full_name":  nameClient,
       "ejecutive":this._auth.getUsername(),
       "ejecutive_update": "",
       "title": '',
       "subtitle": 'Nuevo cliente creado',
       "status_view": "NEW_CLIENT",
       "extra_data": "",
       "note": note,
     };
      await this._notes.saveNote(data);
   
   };
}
