<ng-container *ngIf="config"> 
  <i class="fas fa-sort-amount-down voltear" 
    [class.filter-active]="config.object[id].movement==='UP' && id===config.active"
    (click)="select({id:id, action:'UP'})">
  </i>
  <i class="fas fa-sort-amount-down" 
    [class.filter-active]="config.object[id].movement==='DOWN' && id===config.active"
    (click)="select({id:id, action:'DOWN'})">
  </i>
</ng-container>



