import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileService } from '@akeela/common';
import { isNullOrUndefined } from 'util';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-preview-img',
  templateUrl: './preview-img.component.html',
  styleUrls: ['./preview-img.component.css']
})
export class PreviewImgComponent implements OnInit {
  @Input() identifier;
  @Input() name?;
  @Output() response = new EventEmitter();

  blob;
  loading;
  safe_file = null;
  url;
  enablePreview;
  isImage = false;
  progress_download = false;

  constructor(private file: FileService, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.response.emit({
      response: 'success',
      identifier: this.identifier
    });
  }

  
 

  

}
