<akeela-template>
    <ng-template akeela-content componentName="ComponentProducts">
        <select-product></select-product>
    </ng-template>
    <ng-template akeela-content componentName="ComponentPlans">
        <public-plans></public-plans>
    </ng-template>
    <!-- <ng-template akeela-content componentName="ComponentLogin">
    <public-login></public-login>
  </ng-template> -->
    <ng-template akeela-content componentName="ComponentConfirmationUserAccountOpening">
        <app-create-user></app-create-user>
    </ng-template>
</akeela-template>

<div [class.background-image]="currentStep==='ACCOUNT_SELECTION'">
    <div class="pt-4">
        <div class="container  animated fadeIn ">
            <div class="box bg-white mb-2" [class.bg-transparent]="currentStep==='ACCOUNT_SELECTION' || currentStep==='LOGIN_ACCOUNT_OPENING'">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 w-100">

                            <body>
                                <router-outlet></router-outlet>
                            </body>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>