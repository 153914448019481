import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from '@akeela/properties';
import {
  AkeelaWorkflowService,
  AkeelaStageService,
  AkeelaStepService,
  AkeelaSectionService
} from '@akeela/workflow';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs';
import { LocalService } from '@akeela/local-storage';
import { Table, Row, Column } from '@akeela/ak-table';
import { debug } from 'console';
import { environment } from '../../../../../environments/environment';




@Component({
  selector: 'app-section-related-company',
  templateUrl: './section-related-company.component.html',
  styleUrls: ['./section-related-company.component.css']
})
export class SectionRelatedCompanyComponent implements OnInit, OnDestroy {
  workflow;
  stage;
  step;
  transactionNumber;
  transactionData;
  itemList: any;
  queryParams;
  active_redirect = false;
  checkDoNotHaveRelatedCompany;
  showLoading = true;
  readonlyMode = false;
  loading_table = false;
  configuration_table: Table;
  subscriptor_transaction: Subscription;
  workflow_event_suscriptor: Subscription;
  role;
  paramsSubscription: Subscription;
  env = null;

  hideTop = null;



  constructor(
    public _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private route: ActivatedRoute,
    private akeelaSectionService: AkeelaSectionService,
    private i18n: I18nPipe,
    private localStorage: LocalService,
  ) { }

  ngOnInit() {
    this.env = environment;

    this.akeelaWorkflowService.worflowEvents$.next(null);
    this.workflow = this.akeelaWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akeelaStageService.currentStage$.getValue();
    this.step = this.akeelaStepService.currentStep$.getValue();
    this.transactionData = this.akeelaWorkflowService.transactionData$.getValue();
    this.role = this.localStorage.getItem('role');
    if(this.env) {
      if(this.env?.theme?.images==="MC") {
        this.hideTop = true;
      }
    }
    this.akeelaStepService.currentStepMode$.subscribe((readonly) => {
      this.readonlyMode = readonly;
    });

    this.getItemList();



    this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });


    if (this.workflow_event_suscriptor) {
      this.workflow_event_suscriptor.unsubscribe();
    }
    this.workflow_event_suscriptor = this.akeelaWorkflowService.worflowEvents$.subscribe((response: any) => {

      if (!isNullOrUndefined(response) && (response.action === 'account_contacts$customer-contacts$post')) {
        
        this.getItemList();

      } else if (!isNullOrUndefined(response) && (response.action === 'account_contacts$delete-contact$delete')) {
        this.getItemList();

      } else if (!isNullOrUndefined(response) && response.action === 'receive_formValidationEvent') {
        this.akeelaWorkflowService.worflowEvents$.next(null);
      }
    });
  }



  destroyTransactionDataSubscription() {
    if (this.subscriptor_transaction) {
      this.subscriptor_transaction.unsubscribe();
    }
  }

  getTransactionData() {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role
        },
        path: {
          transactionNumber: this.transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  ngOnDestroy() {
    this.destroyTransactionDataSubscription();
    if (this.workflow_event_suscriptor) {
      this.workflow_event_suscriptor.unsubscribe();
    }
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  setShowLoading(value: boolean) {
    this.showLoading = value;
  }

  setCheckDoNotHaveRelatedCompany(value: boolean) {
    this.checkDoNotHaveRelatedCompany = value;
    this.setShowLoading(false);
  }

  getItemList() {

    const params = {
      name: 'account_contacts$contacts$get',
      params: {
        request: {
          idPerson: this.localStorage.getItem('clientIdentifier'),
          contactType:'RELATED_COMPANY'
        },
        path: {
        },
        body: {}
      }
    };
    this.setShowLoading(true);
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.itemList = response;
      this.setConfigurationTable();
        this.akeelaWorkflowService.setResetEstatus(true);
      
      this.setShowLoading(false);
    }, error => {
      this.setShowLoading(false);
    });
  }

  openItem(row) {
    this.akeelaSectionService.addModalSection({ name: 'RELATED_COMPANIES_MODAL' });
    this.akeelaSectionService.showModalSection('RELATED_COMPANIES_MODAL');
    this.akeelaSectionService.setModalFormValue('RELATED_COMPANIES_MODAL', row);
  }

  confirmDelete(row: any) {
    swal.fire({
      title: this.i18n.transform('@i18n-popup-text-delete-question'),
      text: this.i18n.transform('@i18n-popup-text-delete-question-text'),
      showCancelButton: true,
      confirmButtonText: this.i18n.transform('@i18n-popup-text-yes'),
      cancelButtonText: this.i18n.transform('@i18n-popup-text-no'),
      reverseButtons: true
    }).then((result) => {
      
      if (!isNullOrUndefined(result.value) && result.value) {
        this.deleteItem(row);
      }
    });
  }

  deleteItem(row: any) {
    const contactId = !isNullOrUndefined(row.organizationContact) && !isNullOrUndefined(row.organizationContact.identifier) ? row.organizationContact.identifier : row.personContact.identifier;
    const configuration = {
      name: 'account_contacts$delete-contact$delete',
      params: {
        path: {
        },
        request: {
          contactId: contactId,
          idPerson: this.localStorage.getItem('clientIdentifier'),
          contactType:'RELATED_COMPANY',
          currentStepCode:'RELATED_PERSONS',
          role: this.localStorage.getItem('role'),
          audience: this.localStorage.getItem('audience')
        }
      }
    };

    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((response) => {
      swal.fire({
        title: this.i18n.transform('Éxito'),
        html: this.i18n.transform('@i18n-text-information-deleted')
      });
      this.updateList('account_contacts$delete-contact$delete');
      this.akeelaWorkflowService.setResetEstatus(true);

    }, error => {
      //dfutrille esto debe borrarse.
      this.updateList('account_contacts$delete-contact$delete');
    });
  }

  updateList(action: string) {
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {},
      action: action
    });
  }





  /**tabla */
  
  setConfigurationTable() {
    const base: Column[] = this.setHeadTable();
    this.configuration_table = {
      cssCustom: '',
      width: '100%',
      header: {
        cssCustom: 'class-color-title-table-section',
        rows: [{
          backgroundColor: '#fff',
          columns: base
        }],
      },
      body: {
        cssCustom: '',
        rows: this.setContentTable(base)
      },
      footer: {
        cssCustom: '',
        rows: []
      }
    };
    
    
  }

  setHeadTable(){
    
    const base: Column[] = [
      {
        alignContent: 'left',
        fixed: 'right',
        maxWidth: '200',
        minWidth: '400',
        html: 'Razón Social',
        cssCustom: '',
        enableRightBorder: false

      },
      {
        alignContent: 'right',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      },
      {
        alignContent: 'right',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      }
  ]
    return base;
  }
  
  setContentTable(configuration): Row[] {
   
      let content: Row[] = [];
      let i = 0;
      for (const row of  this.itemList) {
        i++;


      let temp = '-';
      if(row.applicantType == 'NATURAL'){

        temp = '@i18n-quote-option-list-applicantType-option-natural';

      }else if(row.applicantType == 'LEGAL'){
        temp = '@i18n-quote-option-list-applicantType-option-legal';
      }
        content = [...content,
        {
          identifier: row,
          cssCustom: '',
          enabled: true,
          columns: [
              this.setRowContentTable({

                configuration,
                number:0,
                html: row.organizationContact.legalName,
                cssCustom:' text-capitalize'
            
              }), 
              this.setRowContentTable({

                configuration,
                number: 1,
                html: 'Ver',
                cssCustom: 'ak-tabled-row-active text-capitalize'
    
              }),
  
              this.setRowContentTable({
                configuration,
                number:2,
                html: '<i class="fa fa-trash" title="Eliminar" aria-hidden="true"></i>',
                click: 'delete_item',
              })
          ]
        }
      ];
    }
    return content;
  }


  setRowContentTable(row){
    
    let configuration = row.configuration;
    let number = row.number;
    let html = row.html;
    let click = row.click?row.click:null ;
    let dropdown = row.dropdown?row.dropdown:null ;
    let cssCustom = row.cssCustom?row.cssCustom:configuration[number].cssCustom;


let result = {
      
      alignContent: configuration[number].alignContent,
      fixed: configuration[number].fixed,
      maxWidth: configuration[number].maxWidth,
      minWidth: configuration[number].minWidth,
      html: html,
      click:click,
      dropdown:dropdown,
      cssCustom: cssCustom,
      enableLeftBorder: !isNullOrUndefined(configuration[number].enableLeftBorder) ? configuration[number].enableLeftBorder : false,
      enableRightBorder: !isNullOrUndefined(configuration[number].enableRightBorder) ? configuration[number].enableRightBorder : false
    }
  
return result;

  }
  table_response(response) {

    if (response.action === 'open') {
      this.openItem(response.object.identifier);

    }else if (response.action === 'action') {
       if (response.object.action === 'delete_item' && !this.readonlyMode) {

        this.confirmDelete(response.object.obj.identifier);
      }
    }
  }


  isNull(param){    

    return isNullOrUndefined(param);

  }

}



