import { Component, OnInit, ViewChild } from '@angular/core';
import { AkeelaStepService } from '@akeela/workflow';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { DatatableComponent, ColumnMode, SelectionType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.css']
})
export class WorkflowComponent implements OnInit {

  showSubHeaderStep;
  showSubHeaderStepSubscription;

  constructor(    private akStepService: AkeelaStepService,    private _breadcrumbService: AkeelaBreadcrumbService

  ) {
    
   }


  
  ngOnInit() {
    this._breadcrumbService.setbreadcrumbOptions([]);



    document.body.classList.add('teme-gray');

    this.showSubHeaderStepSubscription = this.akStepService.showSubHeaderStep$.subscribe(data => {

      this.showSubHeaderStep = data;

    });
  }


  deleteSubscription() {
    if (this.showSubHeaderStepSubscription) {
      this.showSubHeaderStepSubscription.unsubscribe();
    }

  
  }

  ngOnDestroy() {
    this.deleteSubscription();
  }
}
