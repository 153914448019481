import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';
import { AkeelaRoutes, AkeelaStageService, AkeelaStepService, AkeelaWorkflowService, RouteUtilsService, Step } from '@akeela/workflow';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { HttpService } from '@akeela/properties';
import { CreditService } from '@akeela/common';


@Component({
    selector: 'app-step',
    templateUrl: './step.component.html',
    styleUrls: ['./step.component.css']
})
export class StepComponent implements OnInit {
    @Input() transactionNumber: string;
    @Input() workflowCode: string;
    @Input() stageCode: string;
    @Input() inputPoint: string;
    @Input() profile: string;
    @Input() role: string;
    @Input() audience: string;
    @Input() figure?: string;

    private stepsSubscription: Subscription;
    private currentStepSubscription: Subscription;

    steps: Step[] = [];
    currentStepCode: string;
    currentStep: Step;
    transactionData:any;
    stepStatus :any = {};

    constructor(
        private stepService: AkeelaStepService,
        private route: ActivatedRoute,
        private router: Router,
        private stageService: AkeelaStageService,
        private workflowService: AkeelaWorkflowService,
        private routeService: RouteUtilsService,
        private _httpService: HttpService,
        private creditService: CreditService,


    ) {
    }


    ngOnInit(): void {
        this.route.params
            .pipe(
                mergeMap(params => this.route.queryParams
                    .pipe(
                        map(queryParams => {
                            return { ...params, ...queryParams };
                        })
                    )
                )
            )
            .subscribe(params => {

                // this.creditService.transactionNumber.subscribe(transactionNumber => {
                //     this.transactionNumber = transactionNumber;
              
                //   });
      
                this.role = this.routeService.getRole(params);
                this.audience = this.routeService.getAudience(params);
                this.profile = this.routeService.getProfile(params);
                this.inputPoint = this.routeService.getInputPoint(params);
                this.currentStepCode = this.routeService.getStepCode(params);
                this.workflowService.currentWorkflow$.subscribe(workflowCode => {
                    this.workflowCode = workflowCode;

                    this.stageService.currentStage$.subscribe(stageCode => {
                        this.currentStepCode = stageCode;
                        this.stageCode = stageCode;

                        if (isNullOrUndefined(this.stageCode)) {
                            this.getStage();
                        } else {
                            this.createStepsSubscription();
                        }

                    });

                });
            });

    }



    getStatus() {


        this.workflowService.transactionData$.subscribe((transactionData :any) => {

            this.creditService.figure$.subscribe(figure => {
            if(transactionData.accredited){
                if(this.steps.length > 0){
                    let params;
                    for (let i of this.steps) {

                        if(i.canBeCompleted){

                        let event ="DEFAULT_FLOW_EVENT";
                         params = {
                            configuration: {
                              name: 'loan_credit_request$credits$step_state$get',
                              params: {
                                request: {
                                    role : 'CLIENT',
                                    audience : this.audience 
                                  },
                                path: {
                                  identifierCredit: transactionData.identifier,
                                  workflow:this.workflowCode,
                                  stage: this.stageCode,
                                  step:i.alternateName,
                                  event:event,
                                  idPerson: transactionData.accredited.identifier,
                                  figure: figure

                                }                              }
                            }
                         };                                                 
                          
                          this._httpService.executeRequest(params.configuration.name, params.configuration.params).subscribe((step: any) => {
                
                             if(step){
                                this.stepStatus[i.alternateName] = step.status


                             }

                          }, (error) => {


                          });
                        }
            
                    }
                    
                }

                
            }

        });
            
                            });

       
       
      }



    getStage() {
        if (isNullOrUndefined(this.inputPoint)) {
            // TODO: cambiar el hardcode por la linea comentada
            // this.currentStageCode = this.stages[0].alternateName;
            this.stageCode = 'PAYMENT_CAPACITY';
            this.gotoStage();
        } else {
            this.stageService.getFirstStageByInputCode(this.workflowCode, this.inputPoint, this.profile, this.role, this.audience)
                .subscribe(_stage => {
                    this.currentStepCode = _stage.alternateName;

                    // this.gotoStage();
                });
        }
    }

    createStepsSubscription() {
        this.deleteStepsSubscription();
        if (isNullOrUndefined(this.workflowCode)) {
            return;
        }


        this.stepsSubscription = this.stepService
            .getStepTitles(this.workflowCode, this.stageCode, this.profile, this.role, this.audience)
            .subscribe(steps => {
                this.steps = steps;

                this.getStatus();

                this.createCurrentStepSubscription();
            });
    }

    createCurrentStepSubscription() {
        this.deleteCurrentStepSubscription();
        this.currentStepSubscription = this.stepService.currentStep$.subscribe(currentStepCode => {
            setTimeout(() => {
                if (!isNullOrUndefined(currentStepCode) || currentStepCode !== 'do nothing') {
                    this.currentStepCode = currentStepCode;
                }
            }, 100);
        });
    }

    deleteStepsSubscription() {
        if (this.stepsSubscription) {
            this.stepsSubscription.unsubscribe();
        }
    }

    deleteCurrentStepSubscription() {
        if (this.currentStepSubscription) {
            this.currentStepSubscription.unsubscribe();
        }
    }

    ngOnDestroy(): void {
        this.deleteStepsSubscription();
    }

    gotoStage() {
        const uri = AkeelaRoutes.stage;

        const queryParams = {
            role: this.role,
            audience: this.audience,
            profile: this.profile,
            input_point: this.inputPoint
        };
        this.router.navigate([this.workflowCode, uri, this.stageCode], { queryParams, relativeTo: this.route });
    }

    gotoStep(stepCode: string) {
        const prefixed = this.route.routeConfig.path.indexOf('/steps/') > 0 ? '../../' : '';
        const uri = prefixed + AkeelaRoutes.step;
        const queryParams: any = {
            role: this.role,
            audience: this.audience,
            profile: this.profile
        };

        if (!isNullOrUndefined(this.transactionNumber)) {
            queryParams.transactionNumber = this.transactionNumber;
        }

        const commands = [this.workflowCode];
        commands.push('stages');
        commands.push(this.stageCode);
        commands.push(uri);


        if (!isNullOrUndefined(stepCode)) {
            commands.push(stepCode);
        }

        this.router.navigate(commands, { queryParams, relativeTo: this.route });
    }

}
