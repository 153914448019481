import { AuthService } from '@akeela/auth';
import { I18nPipe } from '@akeela/i18n';
import { LocalService } from '@akeela/local-storage';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { HttpService } from '@akeela/properties';
import { AkeelaWorkflowService } from '@akeela/workflow';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Utf8RegexService } from 'src/app/services/utf8-regex.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit, OnDestroy {


  errorMsgValidatTel;
  sentValidateNotes = false;
  activeModalValidateNotes = false;
  centerModal;
  code;
  data;
  loadingSentValidateNotes;
  loadingValidateCode;
  sentValidateErrorNotes;
  showValidateTel: Subscription;
  notes = []
  env: any = environment;

  form: FormGroup;
  emailSubscription: Subscription;
  sendMailSubscription: Subscription;
  submit_disabled = false;
  email = "^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$";
  phone = "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{6,6}$";
  identity = "^[JGVEPjgvep][0-9]+$";
  client;
  showForm;
  loadingNotes;
  animate_init_bottom = true;
  buttomModal;
  sendNoteSubscription: Subscription;

  constructor(

    private formBuilder: FormBuilder,
    private _httpService: HttpService,
    public _auth: AuthService,
    private localStorage: LocalService,
    private akWorkflowService: AkeelaWorkflowService,
    private i18n: I18nPipe,
    private _wfService: WorkflowService,
    private _utf8: Utf8RegexService,
    private datePipe: DatePipe,


  ) { }

  ngOnInit(): void {

    //Validators.pattern(this.email)
    this.form = this.formBuilder.group({
      note: ['', [Validators.required]],
      noteCliente: [false, [Validators.required]],
      title: [''],
      subtitle: ['']
    });


   this.sendNoteSubscription = this._wfService.showNoteForClient$.subscribe((result) => {
      this.notes = [];

      if(result){
        if(result?.form){

          this.showForm = true;
          
        }
  
        this.setInitialData(result?.user);
      }
    
     
    });



  }

  setInitialData = (response) =>{

    
    if (response) {
      this.animate_init_bottom = false;

      this.buttomModal = true;
      // this.sendMsgValidateTel(data);

      this.setNotesForUserNames(response);

      console.clear();
      console.log(response);

    }else{
      this.buttomModal = false;
     // this.form.reset();
    }
  }

  setNotesForUserNames = async (user) => {


    try {
      this.loadingNotes = true;

      this.client = await this.getUser(user); 

      let listNotes: any = await this.getListNotesUsernameServe(user);
      listNotes.sort(this.custom_sort);
     /* let temp = {};
      listNotes?.map((r, i) => {
        let a = temp[r?.client] || [];
        temp[r?.client] = [...a, r];

      });*/
     

      this.notes = listNotes;
      this.loadingNotes = false;

    } catch (error) {
      this.notes = [];
      this.loadingNotes = false;


    }
  }

  setClose = ()=>{
    this._wfService.setShowNoteForClient(null);

    this.buttomModal = false;

  }




  getListNotesUsernameServe = async (user) => {


    let data = [

      { "client": this.client?.identifier }

    ]

    try {
      //environment?.theme?.site?.name
      const params = {
        pathResponse: {
          url: 'https://utilities.akeela.co/note/search',
          httpMethod: 'POST'
        },
        params: {
          request: {
            'environment_code': this.env?.code || this.env?.theme?.images
          },
          body: data

        }
      }

      return this._httpService.executeRequestExternalUrl(params).toPromise();

    } catch (error) {

      console.error(error);
      this.loadingNotes = false;

    }

  }


  ngOnDestroy = (): void => {
    this.destroySub();

  }

  destroySub = () => {
    this.sendNoteSubscription.unsubscribe();
    this.emailSubscription.unsubscribe();
    this.sendMailSubscription.unsubscribe();
  }



  submit = async () => {

    let form = this.form.getRawValue();

    this.submit_disabled = true;
    if (this.form.dirty && this.form.valid) {

      swal.fire({
        title: '',
        html: '¿Estas seguro de crear esta nota?',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then(async (result) => {

        if (result?.isConfirmed) {


          try {


            this.submit_disabled = false;



            let response = await this.saveNote();

            if (response) {

              this.setInitialData(this.client?.identifier);

              
              this.showForm = false;
              
              this.submit_disabled = false;

              if(form.noteCliente){

                try {
                  await this.sendNoteClient();


                this.form = this.formBuilder.group({
                  note: ['', [Validators.required]],
                  noteCliente: [false, [Validators.required]],
                  title: [''],
                  subtitle: ['']
                });



                } catch (error) {
                  console.log(error , " error al enviar SMS");

                this.form = this.formBuilder.group({
                  note: ['', [Validators.required]],
                  noteCliente: [false, [Validators.required]],
                  title: [''],
                  subtitle: ['']
                });



                  
                }

              }


            }

          } catch (error) {

            //this._error.show(error);
            this.submit_disabled = false;


          }

        }
      });

    }
  }


  saveNote = async () => {

     this.client;
    let form = this.form.getRawValue();
    const data =  {
     "environment_code": this.env?.code || this.env?.theme?.images,
      "type": "",
      "show_note": "TRUE",
      "note_extra_data_json": "",
      "client": this.client?.identifier,
      "full_name": this.client?.person?.givenName + ' '+ this.client?.person?.familyName,
      "ejecutive": this._auth.getUsername(),
      "ejecutive_update": "",
      "title": form?.title,
      "subtitle": form?.subtitle,
      "status_view": "",
      "extra_data": "",
      "note": form?.note
    };


    const params = {
      pathResponse: {
        url: 'https://utilities.akeela.co/note',
        httpMethod: 'POST'
      },
      params: {
        request: {},
        body: data

      }
    }

    return this._httpService.executeRequestExternalUrl(params).toPromise();
  }


  sendNoteClient = async () => {
    this.client;
   let form = this.form.getRawValue();
   const data =  {

    "phone":this.client?.person?.otherPhone,
    "client": this.env?.code || this.env?.theme?.images,
    "msg":form?.note
    
    }

    const params = {
      pathResponse:{
          url:'https://otp.akeela.co/sendsms/general',
          httpMethod:'POST'
      },
      params: {
          responseType:'json',
          request:{}  ,  
          body: data

         
      }
    }

   return this._httpService.executeRequestExternalUrl(params).toPromise();

   
 }


  getUser = async (user)  =>{

    const params = {
      name: 'person$enduser$get',
      params: {
        request: {
          'username': user
        },
        path: {},
        body: {}
      }
    };

    return this._httpService.executeRequest(params.name, params.params, true).toPromise();


}

custom_sort (a, b) {
  return new Date(b?.create_date).getTime() - new Date(a?.create_date).getTime();
}


custom_sort_asc (a, b) {
  return new Date(a?.create_date).getTime() - new Date(b?.create_date).getTime();
}


getListNotesServe = async () => {


  let data = [   {"ejecutive": "ejecutive"},
  {"status": "ACTIVE"}]

  try {
    //environment?.theme?.site?.name
    const params = {
      pathResponse: {
        url: 'https://utilities.akeela.co/note/search',
        httpMethod: 'POST'
      },
      params: {
        request: {
          'environment_code': this.env?.code || this.env?.theme?.images
        },
        body: data

      }
    }

    return this._httpService.executeRequestExternalUrl(params).toPromise();

  } catch (error) {

    console.error(error);
    this.loadingNotes = false;

  }

}

getListNotesAll = async () =>{

  let listNotes: any = await this.getListNotesServe();
  listNotes.sort(this.custom_sort_asc);

  
  let obj = {};

  listNotes?.map((r, i) => {


    let date =  this.toDateString(r?.create_date);
  
    let a = obj[date] || [];
    obj[date] = [...a,r];

  });

  let objectArray = [];

  for (const property in obj) {
    objectArray = [...objectArray,{create_date:property,object:obj[property]}]

  }

}

private parseDateString(date:string): Date {
  date = date.replace('T','-');
  let parts:any = date.split('-');
  let timeParts = parts[3].split(':');
  
 // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1]-1, parts[2], timeParts[0], timeParts[1]); // Note: months are 0-based

}


toDateString(fecha): string {
  let temp2 = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16);
 
   let temp = new Date(fecha).toISOString().slice(0, 16); 
      let date:Date = this.parseDateString(temp2);
   return (("0" + (date.getDate())).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' +date.getFullYear().toString());
 }
 
 
 convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;   
  
}

}