import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { isNullOrUndefined } from 'util';
import {
  AkeelaWorkflowService,
  AkeelaStageService,
  AkeelaStepService,
  AkeelaSectionService
} from '@akeela/workflow';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalService } from '@akeela/local-storage';
import { Table, Row, Column } from '@akeela/ak-table';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '@akeela/auth';


@Component({
  selector: 'app-section-personal-references-pn',
  templateUrl: './section-personal-references-pn.component.html',
  styleUrls: ['./section-personal-references-pn.component.css']
})
export class SectionPersonalReferencesPNComponent implements OnInit, OnDestroy {
  workflow;
  stage;
  step;
  transactionNumber;
  transactionData;
  itemList: any;
  queryParams;
  readonlyMode = false;
  checkDoNotHavePersonalReference = false;
  showLoading = true;
  subscriptor_transaction: Subscription;
  role;
  identitys = [];

  loading_table = false;
  configuration_table: Table;

  private transactionDataSubscription: Subscription;
  private workflowEventsSubscription: Subscription;
  private requestSubscription: Subscription;
  paramsSubscription: Subscription;
  clientIdentifier;

  constructor(
    public _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private route: ActivatedRoute,
    private akeelaSectionService: AkeelaSectionService,
    private i18n: I18nPipe,
    private localStorage: LocalService,
    public _auth:AuthService,


  ) { }

  ngOnInit() {
    this.clientIdentifier = this.localStorage.getItem('clientIdentifier');
    this.itemList = [];
    this.akeelaWorkflowService.worflowEvents$.next(null);
    this.akeelaStepService.currentStepMode$.subscribe((readonly) => {
      this.readonlyMode = readonly;
    });

    this.role = this.localStorage.getItem('role');

    this.workflow = this.akeelaWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akeelaStageService.currentStage$.getValue();
    this.step = this.akeelaStepService.currentStep$.getValue();
    this.transactionData = this.akeelaWorkflowService.transactionData$.getValue();

    this.akeelaWorkflowService.transactionNumber.subscribe(transactionNumber => {
      if (transactionNumber) {
        this.transactionNumber = transactionNumber;
      }
    });


    this.destroyTransactionDataSubscription();
    this.subscriptor_transaction = this.getTransactionData().subscribe((response: any) => {
      this.transactionData = response;
    });


    this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });


    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }

    this.createEventsSubscription();
    this.getIdentity();

     this.setCheckDoNotHavePersonalReferences(false);
    this.getItemList();

  }


  getIdentity() {
    const params = {
      name: 'workflow_manager$attributes_options$get',
      params: {
        request: {
          audience: this.queryParams.audience

        },
        path: {
          code: 'typeIdentity'
        },
        body: {}
      }
    };

    this._httpService.executeRequest(params.name, params.params, false).subscribe((response: any) => {
      let nameItem = '';
      if (response.length) {
        this.identitys = [];
        for (const value of response) {
          nameItem = value.item.alternateName;
          this.identitys[nameItem] = value.name;
        }
      }
    });

  }

  createEventsSubscription() {
    this.workflowEventsSubscription = this.akeelaWorkflowService.worflowEvents$.subscribe((response: any) => {
      if (!isNullOrUndefined(response) && (response.action === 'account_contacts$save-customer-contact$post')) {
        this.getItemList();
      } else if (!isNullOrUndefined(response) && (response.action === 'account_contacts$contacts$delete')) {
        this.getItemList();
      } else if (!isNullOrUndefined(response) && response.action === 'send_formValidationEvent') {
        // Mario 28/08/2020: actualizado, quite esta validación desde el js_events y ahora no es necesaria esta función que causa el llamado multiple
        // esto fue lo que quite del js_events por si fuera necesario a futuro: "formValidationEvent":"send_formValidationEvent"
        // this.sendEventToContinue(response.response);
      } else if (!isNullOrUndefined(response) && response.action === 'receive_formValidationEvent') {
        this.akeelaWorkflowService.worflowEvents$.next(null);
      }
    });
  }

  destroyTransactionDataSubscription() {
    if (this.subscriptor_transaction) {
      this.subscriptor_transaction.unsubscribe();
    }
  }

  getTransactionData() {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role
        },
        path: {
          transactionNumber: this.transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  setShowLoading(value: boolean) {
    this.showLoading = value;
  }
  setCheckDoNotHavePersonalReferences(value: boolean) {
    this.setShowLoading(false);
    this.checkDoNotHavePersonalReference = value;
  }

  haveAtLeastOnePep() {
    const modalFather = this.akeelaSectionService.getModalFather();
    if (!isNullOrUndefined(modalFather) && !isNullOrUndefined(modalFather.person)) {
      // Esto es cuando la entidad Padre ya esta guardada en BD y se verifican sus PEP asociados en el JSON
      if (isNullOrUndefined(modalFather.person.relatedPeps) || (!isNullOrUndefined(modalFather.person.relatedPeps) && modalFather.person.relatedPeps.length === 0)) {
        return false;
      }
    } else if (isNullOrUndefined(modalFather)) {
      // Esto es cuando la entidad Padre no esta guardada en BD y se verifica si tiene PEP en memoria.
      if (this.akeelaSectionService.countModalChildList('new_pep_board_of_directors') === 0) {
        return false;
      }
    }
    return true;
  }

  sendEventToContinue(response) {
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {
        fn: response.fn,
        event: event,
        sectionIdentifier: response.sectionIdentifier,
        field: response.field
      },
      action: 'receive_formValidationEvent'
    });
  }

  ngOnDestroy() {
    if (this.transactionDataSubscription) {
      this.transactionDataSubscription.unsubscribe();
    }
    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  getItemList() {

    const params = {
      name: 'account_contacts$contacts$get',
      params: {
        request: {
          idPerson: this.clientIdentifier,
          contactType:'PERSONAL_REFERENCE'
        },
        path: {
        },
        body: {}
      }
    };
    this.setShowLoading(true);
    this.requestSubscription = this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.setShowLoading(false);
      this.itemList = response;
      this.setConfigurationTable();

      if (this.itemList.length === 0) {
        this.akeelaWorkflowService.setResetEstatus(true);
      }
    }, error => {
      this.setShowLoading(false);
    });
  }


  openItem(row) {
    // este caso se agrega, porque al seleccionar el tipo pasaporte, el valor llega en otro atributo, llamado passport 
    
    if(row){
      row.personContact.identity = row.personContact.additionalType === '1' ? row.personContact.identity : row.personContact.passport;

    }else{
      row = {};
    }

    this.akeelaSectionService.addModalSection({ name: 'PERSONAL_REFERENCES_MODAL' });
    this.akeelaSectionService.showModalSection('PERSONAL_REFERENCES_MODAL', true);
    this.akeelaSectionService.setModalFormValue('PERSONAL_REFERENCES_MODAL', row);
  }

  confirmDelete(row: any) {
    swal.fire({
      title: this.i18n.transform('@i18n-popup-text-delete-question'),
      text: this.i18n.transform('@i18n-popup-text-delete-question-text'),
      showCancelButton: true,
      confirmButtonText: this.i18n.transform('@i18n-popup-text-yes'),
      cancelButtonText: this.i18n.transform('@i18n-popup-text-no'),
      reverseButtons: true
    }).then((result) => {
      if (!isNullOrUndefined(result.value) && result.value) {
        this.deleteItem(row);
      }
    });
  }


  deleteItem(row: any) {
    const contactId = row?.personContact?.identifier;
    const configuration = {
      name: 'account_contacts$contacts$delete',
      params: {
        path: {
        },
        request: {
          contactId: contactId,
          idPerson: this.clientIdentifier,
          contactType:'PERSONAL_REFERENCE',
          currentStepCode:'PERSONAL_REFERENCE',
          role: this.localStorage.getItem('role'),
          audience: this.localStorage.getItem('audience')
        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params, true).subscribe((response) => {
      swal.fire({
        title: this.i18n.transform('Éxito'),
        html: this.i18n.transform('@i18n-text-information-deleted')
      });
      this.updateList('account_contacts$contacts$delete');
      this.akeelaWorkflowService.setResetEstatus(true);

    }, error => {

    })
  }

  updateList(action: string) {

    if (this.itemList.length === 0) {
      this.akeelaWorkflowService.setResetEstatus(true);
    }

    this.akeelaWorkflowService.worflowEvents$.next({
      response: {},
      action: action
    });
  }




  setConfigurationTable() {
    const base: Column[] = this.setHeadTable();
    this.configuration_table = {
      cssCustom: '',
      width: '100%',
      header: {
        cssCustom: 'class-color-title-table-section',
        rows: [{
          backgroundColor: '#fff',
          columns: base
        }],
      },
      body: {
        cssCustom: '',
        rows: this.setContentTable(base)
      },
      footer: {
        cssCustom: '',
        rows: []
      }
    };

  }

  setHeadTable() {

    const base: Column[] = [
      {
        alignContent: 'left',
        fixed: 'right',
        maxWidth: '200',
        minWidth: '100',
        html: 'Nombre y Apellido',
        cssCustom: '',
        enableRightBorder: true

      },
      {
        alignContent: 'left',
        fixed: false,
        maxWidth: '200',
        minWidth: '100',
        html: 'Documento de Identidad',
        cssCustom: '',

      }
      ,
      {
        alignContent: 'left',
        fixed: false,
        maxWidth: '200',
        minWidth: '100',
        html: 'Número de Documento',
        cssCustom: ''
      },
      {
        alignContent: 'center',
        fixed: false,
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      },
      {
        alignContent: 'center',
        fixed: false,
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      }
    ]
    return base;
  }

  setContentTable(configuration): Row[] {
    let content: Row[] = [];
    let i = 0;
    for (const row of this.itemList) {
      i++;
      content = [...content,
      {
        identifier: row,
        cssCustom: '',
        enabled: true,
        columns: [
          this.setRowContentTable({

            configuration,
            number: 0,
            html: row.personContact.givenName + " " + row.personContact.familyName,
            cssCustom: 'ak-tabled-row-active text-capitalize'

          }),

          this.setRowContentTable({
            configuration,
            number: 1,
            html: this.i18n.transform(this.identitys[row.personContact.additionalType]),
          }),

          this.setRowContentTable({
            configuration,
            number: 2,
            html: row.personContact.additionalType === '1' ? row.personContact.identity : row.personContact.passport,
          }),

          this.setRowContentTable({

            configuration,
            number: 3,
            html: 'Ver',
            cssCustom: 'ak-tabled-row-active text-capitalize'

          }),

          this.setRowContentTable({
            configuration,
            number: 4,
            html: '<i class="fa fa-trash" title="Eliminar" aria-hidden="true"></i>',
            click: 'delete_item',
          })
        ]
      }
      ];
    }
    return content;
  }


  setRowContentTable(row) {

    let configuration = row.configuration;
    let number = row.number;
    let html = row.html;
    let click = row.click ? row.click : null;
    let dropdown = row.dropdown ? row.dropdown : null;
    let cssCustom = row.cssCustom ? row.cssCustom : configuration[number].cssCustom;



    let result = {

      alignContent: configuration[number].alignContent,
      fixed: configuration[number].fixed,
      maxWidth: configuration[number].maxWidth,
      minWidth: configuration[number].minWidth,
      html: html,
      click: click,
      dropdown: dropdown,
      cssCustom: cssCustom,
      enableLeftBorder: !isNullOrUndefined(configuration[number].enableLeftBorder) ? configuration[number].enableLeftBorder : false,
      enableRightBorder: !isNullOrUndefined(configuration[number].enableRightBorder) ? configuration[number].enableRightBorder : false
    }

    return result;

  }
  table_response(response) {

    if (response.action === 'open') {
      this.openItem(response.object.identifier);

    } else if (response.action === 'action') {
      if (response.object.action === 'delete_item' && !this.readonlyMode) {

        this.confirmDelete(response.object.obj.identifier);
      }
    }
  }


}

