import { Component, OnInit } from '@angular/core';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { isNullOrUndefined } from 'util';
import { HttpService } from '@akeela/properties';
import { AuthService } from '@akeela/auth';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ErrorService } from '@akeela/common';
import { RequirementsService } from '@akeela/requirements';
import { LocalService } from '@akeela/local-storage';

@Component({
  selector: 'app-incompleted-comments',
  templateUrl: './incompleted-comments.component.html',
  styleUrls: ['./incompleted-comments.component.css']
})
export class IncompletedCommentsComponent implements OnInit {
  show = false;
  comments = null;
  sections;
  transactionData;
  objEdit;
  formGroup;

  constructor(
    private _httpService: HttpService,
    private akeelaSectionService: AkeelaSectionService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStepService: AkeelaStepService,
    private _auth: AuthService,
    private _i18n: I18nPipe,
    private formBuilder: FormBuilder,
    private _error: ErrorService,
    private _requirements: RequirementsService,
    private localStorage: LocalService
  ) { }

  ngOnInit() {
    this.akeelaSectionService.addModalSection({ name: 'SPECIFIC_COMMENTS_MODAL' });
    this.initSectionResponse();
    this.getComments();
    this.form();
    if (this.localStorage.getItem('workspaceReadonly') === 'true' || this.localStorage.getItem('readonly') === 'true') {
      this.akeelaStepService.setModeCurrentStep(true);
    }

    this.akeelaWorkflowService.worflowEvents$.subscribe((response: any) => {
          
      if (response) {
        if (!isNullOrUndefined(response.target)) {
          if (!isNullOrUndefined(response.target.target)) {            
            if (response.target.target === 'sectionsIncompleted') {
             // console.log("responseresponseresponse",response);
              
              if (!isNullOrUndefined(response.valueControl)) {
                if (response.valueControl == 'AC_INCOMPLETE') {
                  this.show = true;
                } else {
                  this.show = false;
                }
              } else {
                this.show = false;
              }

            }
          }
        } else {
          if (response.action === 'setResponsesIncompletedTransactions') {
            this.resetForm();
            this.getComments();
          }
        }
      }
    });
  }

  form() {
    this.formGroup = this.formBuilder.group({
      description: ['', Validators.required]
    });
  }

  getComments() {
    this.comments = null;
    this.akeelaWorkflowService.transactionData$.subscribe((transactionData: any) => {
      if (!isNullOrUndefined(transactionData)) {
        this.transactionData = transactionData;
        if (this.transactionData.accountStatus[0].additionalType === 'AC_RECEIVED') {
          this.akeelaStepService.setModeCurrentStep(true);
        }
        this.getSectionsIncompleted(transactionData).then(() => {
          const params = {
            name: 'account$responsestransactionsincompleted$get',
            params: {
              request: {},
              path: {
                transactionNumber: this.transactionData.transactionNumber,
                identifierPerson: this.transactionData.holder.identifier
              },
              body: {}
            }
          };
          this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
            if (!isNullOrUndefined(response.sectionsIncomplete)) {
              this.comments = response.sectionsIncomplete;
              // this.setObjectValue();
            }
          }, error => {
            if (!isNullOrUndefined(error.error.sectionsIncomplete)) {
              this.comments = error.error.sectionsIncomplete;
              // this.setObjectValue();
            }
          });
        });
      }
    });
  }

  setObjectValue() {
    let obj;
    for (const comment of this.comments) {
      obj = this.sections.filter(resp => {
        return comment.name === resp.identifier;
      });
    }
  }

  getSectionsIncompleted(transactionData) {
    return new Promise((resolve, reject) => {
      const params = {
        name: 'account_opening$sections_completed$get',
        params: {
          request: {},
          path: {
            transactionNumber: transactionData?.transactionNumber,
            mtWorkflow: transactionData?.plan?.url
          },
          body: {}
        }
      };
      this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        this.sections = response;
        resolve(true);
      }, error => {
        resolve(true);
      });
    });
  }

  comment() {
    this.resetForm();
    this.initSectionSpecificComments();
    this.akeelaSectionService.showModalSection('SPECIFIC_COMMENTS_MODAL');
  }

  initSectionSpecificComments() {
    const obj = {
      section: 'SPECIFIC_COMMENTS_MODAL',
      response: {
        status: 'NOREQUIREMENTS',
        modifyUser: this._auth.getUsername()
      }
    };
    this.akeelaSectionService.externalUpdateSectionForm$.next(obj);
  }

  initSectionResponse() {
    const obj = {
      section: 'RESPONSE_MODAL',
      response: {
        status: 'NOREQUIREMENTS',
        modifyUser: this._auth.getUsername()
      }
    };
    this.akeelaSectionService.externalUpdateSectionForm$.next(obj);
  }

  resetForm() {
    this.akeelaSectionService.externalResetSectionForm$.next('SPECIFIC_COMMENTS_MODAL');

  }

  deletes(identifier) {
    
    swal.fire({
      title: this._i18n.transform('@i18n-popup-text-delete-question'),
      text: this._i18n.transform('@i18n-popup-text-delete-question-text'),
      showCancelButton: true,
      confirmButtonText: this._i18n.transform('@i18n-popup-text-yes'),
      cancelButtonText: this._i18n.transform('@i18n-popup-text-no'),
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        let params;
        params = {
          name: 'account$responsestransactionsincompleted$delete',
          params: {
            request: {},
            path: {
              "transactionNumber": this.transactionData.transactionNumber,
              "identifierPerson": this.transactionData.holder.identifier
            },
            body: {
              "sectionsIncomplete": [{
                identifier: identifier
              }],
              "modifyUser": this._auth.getUsername()
            }
          }
        };

        this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
          this.getComments();
        });
      }
    });
  }

  edit(obj) {
    this.formGroup.controls['description'].setValue(obj.description);
    this.objEdit = obj;
  }

  close() {
    this.objEdit = null;
  }

  saveEdit() {
    if (this.formGroup.valid) {
      const params = {
        name: 'account$responsestransactionsincompleted$put',
        params: {
          request: {},
          path: {
            transactionNumber: this.transactionData.transactionNumber,
            identifierPerson: this.transactionData.holder.identifier
          },
          body: {
            sectionsIncomplete: [{
              identifier: this.objEdit.identifier,
              description: this.formGroup.controls['description'].value
            }],
            modifyUser: this._auth.getUsername()
          }
        }
      };
      this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        this.objEdit = null;
        this.getComments();
      }, error => {
        this._error.show(error);
      });
    }
  }


}
