import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { TabsService } from '../../services/tabs.service';
import { AkeelaSectionService, AkeelaStepService, AkeelaWorkflowService, AkeelaStageService } from '@akeela/workflow';
import { isNullOrUndefined } from 'util';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit {

  config;
  currenttab;
  stage;
  step;

  constructor(
    private akStepService: AkeelaStepService,
    private akStageService: AkeelaStageService,
    private _tabs: TabsService,
    private route: ActivatedRoute,
    private router: Router  ) { }

  ngOnInit() {
    this.stage = this.akStageService.currentStage$.getValue();
    this.step = this.akStepService.currentStep$.getValue();
    this._tabs.config$.subscribe((config) => {
      if (!isNullOrUndefined(config)) {
        this.config = config;
      }
    });
  }

  go(identifier, prefix?) {
    const next = this.config.tabs.filter(resp => {
      return resp.identifier === identifier;
    });
    if (!isNullOrUndefined(next)) {
        const _prefix = prefix ? prefix : 'private';
        this.router.navigateByUrl(this.router.createUrlTree([_prefix + '/' + next[0].workflow + '/stages/' + next[0].stage + '/steps/' + next[0].step],
          {
            queryParams: {}
          }
        ));
    }

  }

}
