<!-- <button type="button" class="btn btn-outline-primary dropdown-toggle d-none d-md-block" data-toggle="dropdown" aria-haspopup="true"
  aria-expanded="false">
  <span style="font-size:11px">Filtrar Politicas</span>
</button> -->

<button type="button" class="btn btn-outline-primary  d-block d-md-none" [swal]="showWindow">
  <span style="font-size:11px">Filtrar Politicas</span>
</button>





<div ngbDropdown display="dynamic" placement="bottom-right">
  <button type="button" class="btn btn-outline-primary dropdown-toggle d-none d-md-block" ngbDropdownToggle id="navbarDropdown3">
    <span style="font-size:11px">Filtrar Politicas</span>
  </button>

  <div ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu text-left">

    <ng-container *ngFor="let policy of policys">
      <a class="dropdown-item" (click)="applyFilter(policy.identifier)">
        <i class="fas fa-check fa-color-white" [class.fa-color-black]="filter.active===policy.identifier"></i>&nbsp;{{ policy.name }}
      </a>
    </ng-container>

  </div>
</div>



<!-- <div class="dropdown-menu" style="font-size:14px">
  <ng-container *ngFor="let policy of policys">
    <a class="dropdown-item" (click)="applyFilter(policy.identifier)">
      <i class="fas fa-check fa-color-white" [class.fa-color-black]="filter.active===policy.identifier"></i>&nbsp;{{ policy.name }}
    </a>
  </ng-container>
</div> -->



<swal [width]="'850px'" [width]="'100%'" [showCloseButton]="true" [showConfirmButton]="false" [showCancelButton]="false"
  #showWindow>
  <ng-container *swalPortal="swalTargets.content">
    <br>
    <div class="filter-text">
      <hr>
      <ng-container *ngFor="let policy of policys">
        <div class="row active-cursor" (click)="applyFilter(policy.identifier)">
          <div class="col-10">
            &nbsp;&nbsp;&nbsp; {{ policy.name  }}
          </div>
          <div class="col-2 text-right">
            <i class="fas fa-check" *ngIf="filter.active===policy.identifier"></i>
          </div>
        </div>
        <hr>
      </ng-container>
      
    </div>

  </ng-container>
</swal>