<app-template>
    <ng-template app-content componentName="mainContentModalCenter">

        <div *ngIf="centerModal" class="ak-full-modal-3 capa-menu-2 " (click)="changes()" style="height: 100vh!important;z-index: 1000;">
            <div class="container">
                <div (click)="$event.stopPropagation();" class="w-100 bg-white mt-3 body p-3" style="overflow:auto">


                    <div style="min-height: 206px;">
                        <div class="float-right" (click)="changes()">
                            <i class="fa fa-times " aria-hidden="true"></i>
                        </div>
                        <h6 class=" p-2">Selecciona el tipo de solicitud</h6>
                        <hr>

<!-- {{ products | json }} -->
                        <div class="mt-2 animate__animated animate__fadeIn animate__faster" *ngIf="products">

                            <h5 class="mt-4">{{'@i18n-modal-transacton-product-title' | i18n}}</h5>
                            <div class="d-flex flex-wrap">
                                <div [class]="{'active':product?.identifier == productSelected}" *ngFor="let product of products ; let first = first; let i = index" (click)=" getPlans(product?.identifier);productSelected = product?.identifier" class="btn-select section-box ak-shadow hover p-3  ml-1 mr-1 ml-md-2 mr-md-2  mt-1 mb-2 text-left">


                                    <div [innerHtml]="product?.description | i18n"> </div>

                                </div>
                            </div>

                        </div>
                        <div class="mt-4 animate__animated animate__fadeIn animate__faster" *ngIf="plans">
                            <h6>{{'@i18n-modal-transacton-plan-title' | i18n}}</h6>

                            <div class="d-flex flex-wrap">
                                <div [class]="{'active':plan?.identifier == planSelected}" *ngFor="let plan of plans; let first = first; let i = index" (click)="setRq(plans,plan?.identifier);planSelected = plan?.identifier" class=" btn-select section-box ak-shadow hover p-3 ml-1 mr-1 ml-md-2 mr-md-2  mt-1 mb-2 text-center">

                                    <span class="fz-14" [innerHtml]="plan?.name | i18n"> </span>

                                </div>
                            </div>

                        </div>

                    </div>

                    <div>

                        <ng-container *ngIf="requirements">
                            <div class="mt-3 ml-0 ml-md-2 animate__animated animate__fadeIn animate__faster fz-14" *ngIf="requirements.length>0">
                                <div class="font-weight-bold text-dark">Recaudos:</div>

                                <div class="mt-2" *ngFor="let row of requirements; let first = first; let i = index">
                                    <ng-container *ngIf="row?.showCheckList">
                                        <i class="fas fa-circle text-success mr-2 fz-12"></i>
                                        <span class=" text-secondary" [innerHtml]="row?.name | i18n"></span>
                                    </ng-container>

                                </div>

                            </div>
                        </ng-container>

                        <div *ngIf="planSelected && getExistsTransactionForPlan(planSelected)" class="mt-4 mb-5">
                            <hr>
                        </div>


                        <div *ngIf="planSelected && getExistsTransactionForPlan(planSelected)" class="  animate__animated animate__fadeIn animate__faster alert alert-warning text-dark p-3 mt-5 mb-4 animate__animated animate__fadeIn fz-14">
                            Ya el cliente tiene una solicitud para este tipo de Cuenta Bancaria ({{transaction?.plan?.name}})
                        </div>


                        <div class="row mt-3 ">

                            <div *ngIf="getExistsTransactionForPlan(planSelected)" class="col-12 col-md-6 mt-2 ">
                                <div class="w-100 section-box ak-shadow hover p-3 text-left " (click)="redirectTransactions2(transaction) ">
                                    <div class="d-flex w-100 ">

                                        <div class="mt-1 ml-1 ">
                                            <div class="">
                                                <span class="font-weight-bold "> {{transaction?.plan?.name}}</span>

                                            </div>
                                            <div>
                                                <span class="fz-14  "> <span  [innerHTML]="transaction?.plan?.shortDescription | i18n"></span></span>
                                            </div>
                                            <div class="mt-2">
                                                <span class="fz-14 text-primary"> Sol# {{transaction?.transactionNumber}}</span>

                                            </div>
                                        </div>


                                        <div class=" ml-auto ">
                                            <i class=" fas fa-credit-card icon mr-1 text-secondary"></i>
                                        </div>


                                    </div>



                                    <div class="w-100 mt-3 " *ngIf="indicatorsProgresTransactionData">


                                        <div class="d-flex w-100 mb-2 fz-14 ">


                                            <div class="ml-auto text-right " *ngIf="indicatorsProgresTransactionData?.percent > 0">

                                                {{indicatorsProgresTransactionData?.percent}}%
                                            </div>

                                        </div>
                                        <div>
                                            <akeela-graphics [percent]="indicatorsProgresTransactionData?.percent" [type]=" 'line' ">
                                            </akeela-graphics>
                                        </div>

                                    </div>

                                    <div class="w-100 d-flex fz-14 mt-3 ">




                                        <div class="fz-12">
                                            <span class="text-secondary">Última actualización </span>
                                            <br> {{(transaction?.dateOfThing?.modifiedDate?transaction?.dateOfThing?.modifiedDate:transaction?.dateOfThing?.startDate) | date: 'MMM d, y h:mm a'}}
                                        </div>
                                    </div>
                                    <div class="mt-1 mb-1">
                                        <span [innerHTML]="transaction?.accountStatus[0]?.userName | i18n" class="badge badge-pill badge-secondary fz-12"></span>

                                    </div>


                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="mt-4 text-md-right">
                        <hr>

                        <button [disabled]="loading && (!productSelected || !planSelected ) " class="btn btn-primary" (click)="!loading && setProductPlan(productSelected, planSelected);"> 
                           Continuar
                           <span class="fas fa-arrow-right ml-1" *ngIf="!loading"></span>
                            <span *ngIf="loading">...</span>
                        </button>

                    </div>


                </div>
            </div>

        </div>
    </ng-template>
</app-template>