<akeela-requirements-template>

    <ng-template akeelaRequirementsContent componentName="imgLogo">
        <div>
            <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>
        </div>
    </ng-template>
    <ng-template akeelaRequirementsContent componentName="requirementsDetailHeaderContent">
    </ng-template>
    <ng-template akeelaRequirementsContent componentName="requirementsDetailFooterContent">
    </ng-template>
    <ng-template akeelaRequirementsContent componentName="requirementsHeaderContent">
        <ng-container *ngIf="isActiveResume">
            <!-- Acá irá el HEADER -->
            <div class="box-2" [innerHTML]="getInitialsHolder()"></div>
            <div class="box-3" [innerHTML]="getNameHolder()"></div>
            <div class="box-3 font-weight-bold">
                <b>
                    <small [innerHTML]="'#'+transactionData.transactionNumber"></small>
                </b>
            </div>
            <br>

            <div class="w-100">

                <ng-container *ngIf="transactionData && config_requirements">
                    <!-- Datos de la Operación-->
                    <h5 class="  ak-text-color-4" [innerHTML]="'Datos de la Solicitud' | i18n:lang">
                    </h5>

                    <div class="ak-line mb-3"></div>

                    <div class="row  mt-4 mb-4">
                        <div class="col-12 col-sm-6 col-md-4 mb-sm-2">
                            <div>
                                <div [innerHTML]="'Producto' | i18n:lang" class="label"></div>
                                <div [innerHTML]="transactionData?.plan?.financialProduct?.name" class="text "></div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 mb-sm-2">
                            <div>
                                <div [innerHTML]="'@i18n-quote-productInformation-form-label-planName' | i18n:lang" class="label"></div>
                                <div [innerHTML]="transactionData?.plan?.name" class="text"></div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4">
                            <div>
                                <div [innerHTML]="'Canal' | i18n:lang" class="label"></div>
                                <div [innerHTML]="transactionData?.availableChannel?.name" class="text"></div>

                            </div>
                        </div>

                    </div>

                </ng-container>
            </div>
        </ng-container>
        <!--
        <div class="w-100" *ngIf="balance_requirements && config_requirements.isActiveProgressBarUpload">

            <div class="row">
                <div class="col-12 col-lg-10" style="font-size: 14px">
                    <akeela-requirements-progress [balanceRequirements]="balance_requirements" [configuration]="config_requirements"></akeela-requirements-progress>
                </div>
            </div>


        </div>
 -->

    </ng-template>
</akeela-requirements-template>


<app-template>
    <ng-template appContent componentName="topAlert">
        <!--

        <div class="container" *ngIf="transactionData?.accountStatus[0]?.additionalType==='AC_INCOMPLETE' && balance_requirements?.total_required_responses_user_requirements_completed_in_status_incompleted>0">

            <div class="alert alert-secondary" role="alert">
                Por favor revise los comentarios de cada recaudo y complete la información solicitada y vuelva para enviar a análisis.
            </div>
        </div> -->
    </ng-template>
</app-template>
<ng-container *ngIf="activeRequirements">

    <div class="mt-4" *ngIf="isAnalist">
        <akeela-requirements-user [viewOptional]="true" [folder]="folder" [config]="config_requirements" (showFile)="show_file($event)" (openWindowRequirement)="openWindowRequirement($event)" (response)="response_requirements($event)" (finishupload)="finish_upload($event)"
            (responseTransactionChange)="response_transaction_change($event)" (responsetransaction)="response_transaction($event)" *ngIf="config_requirements"></akeela-requirements-user>

    </div>


    <div class="mt-4" *ngIf="!isAnalist">
        <akeela-requirements [viewOptional]="true" [folder]="folder" [config]="config_requirements" (showFile)="show_file($event)" (openWindowRequirement)="openWindowRequirement($event)" (response)="response_requirements($event)" (finishupload)="finish_upload($event)"
            (responseTransactionChange)="response_transaction_change($event)" (responsetransaction)="response_transaction($event)" *ngIf="config_requirements" (open)="openDocument($event)"></akeela-requirements>

    </div>


</ng-container>

<ng-container *ngIf="!config_requirements">
    <div class="container animate__animated animate__fadeIn ">
        <div>
            <img src="./assets/public/images/loading.gif" alt="">
        </div>
    </div>
</ng-container>