import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.css']
})
export class BoxComponent implements OnInit {
  _selected = false;
  _obj = null;
  _last = false;

  percent = null;
  total = 80;
  completed = 33;

  @Output() response = new EventEmitter<any>();


  @Input() set selected(value) {
    this._selected = value;
  }

  @Input() set last(value) {
    this._last = value;
  }

  @Input() set obj(value) {
    this._obj = value;
  }

  constructor() { }

  ngOnInit() {
  }

  round(value) {

    if(value){
      return Math.round(value);

    }
    return 0;

  }
  select(identifier) {
    this.response.emit(identifier);
  }


}
