import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '@akeela/auth';
import { environment } from '../../environments/environment';
import { LocalService } from '@akeela/local-storage';


@Injectable()
export class ValidateSession implements CanActivate {
  constructor(
    private router: Router,
    private _auth: AuthService,
    private _router: Router,
    private localStorage: LocalService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this._auth.isAuthenticated()) {
      return true;
    } else {
      if (this.localStorage.getItem('profile') === 'ANALIST') {
        this.router.navigate([environment.staticLinks.workspace], { queryParams: {} });
      } else {
        this.router.navigate([environment.staticLinks.workspace], { queryParams: {} });
      }
      return false;
    }
  }
}
