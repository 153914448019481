<div class="container  px-0">
    <div class="mb-0 row box  box-section skin-white  animate__animated animate__fadeIn" style="padding-left: 0; padding-right:0">
        <div class="col-12 mt-2">


            <ng-container>
                <div class=" mb-4">
                    <button (click)="openItem({})" class="btn btn-outline-dark">
                    <span class="fas fa-plus mr-1" ></span>

                    Agregar
                    <span [innerHTML]="'@i18n-quote-bankAccountsWithInstitution-section.title' | i18n "></span>
                </button>
                </div>
            </ng-container>



            <div *ngIf="(!(itemList && itemList?.length==0) && !showLoading && configuration_table)">

                <akeela-table [configuration]="configuration_table" [loading]="loading_table" (response)="table_response($event)">

                </akeela-table>
            </div>


            <ng-container *ngIf="showLoading">
                <div>
                    <img src="./assets/public/images/loading.gif" alt="">
                </div>
            </ng-container>
        </div>
    </div>




</div>