import { Injectable } from '@angular/core';
import { now } from 'moment-mini-ts';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  showContainer$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  listWF$: BehaviorSubject<any> = new BehaviorSubject(null);

  showNoteForClient$: BehaviorSubject<any> = new BehaviorSubject(null);
  showExportsForClient$: BehaviorSubject<any> = new BehaviorSubject(null);

  tempNote$: BehaviorSubject<any> = new BehaviorSubject([]);

  transactionTakenYou$: BehaviorSubject<any> = new BehaviorSubject(null);

  showCVVForClient$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() {
}

  setListWF(listWF){
    this.listWF$.next(listWF);

  }


  setTransactionTakenYou(transactionTakenYou){
    this.transactionTakenYou$.next(transactionTakenYou);

  }

  setShowNoteForClient(showNoteForClient){
    this.showNoteForClient$.next(showNoteForClient);

  }
  setShowExportsForClient(showExportsForClient){
    this.showExportsForClient$.next(showExportsForClient);

  }


  setShowCVVForClient(showCVVForClient){
    this.showCVVForClient$.next(showCVVForClient);

  }
}

