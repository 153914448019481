<ng-container *ngIf="transactionData">
  <div class="row">
    <div class="col-12">
      <br>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-6 col-lg-3">
      <div>
        <div [innerHTML]="'Solicitud' | i18n:lang" class="label"></div>
        <div [innerHTML]="transactionData?.plan?.financialProduct?.name" class="text "></div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-6 col-lg-9">
      <div>
        <div [innerHTML]="'Producto' | i18n:lang" class="label"></div>
        <div [innerHTML]="transactionData?.plan?.name" class="text"></div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!transactionData">
  <div class="row">
    <div class="col-lg-12">
      <img class="float-left" src="./assets/public/images/loading.gif" alt="">
    </div>
  </div>
</ng-container>