import { Component, OnInit } from '@angular/core';
import { AkeelaStepService, AkeelaWorkflowService, AkeelaStageService } from '@akeela/workflow';
import { MiddlewareService } from '../../services/middleware.service';
import { RequirementsService } from '@akeela/requirements';
import { HttpService } from '@akeela/properties';
import { isNullOrUndefined, isNull } from 'util';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
  workflow;
  stage;
  step;
  message = null;
  transactionData;

  constructor(
    private akeelaStepService: AkeelaStepService,
    private akeelaStageService: AkeelaStageService,
    private akWorkflowService: AkeelaWorkflowService,
    private _middleware: MiddlewareService,
    private _akeelaRequirementsService: RequirementsService,
    private httpService: HttpService
  ) { }

  ngOnInit() {
    this.workflow = this.akWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akeelaStageService.currentStage$.getValue();
    this.step = this.akeelaStepService.currentStep$.getValue();
    this.akWorkflowService.transactionData$.subscribe((transactionData: any) => {
      if (!isNullOrUndefined(transactionData)) {
        // if (transactionData.editTaken) {
        //   this.validateRules();
        // }
        this.validateRules();
      }
    });
  }

  validateRules() {
    
    if (this.workflow === 'ACCOUNT_OPENING' && this.stage === 'ACCOUNT_CONTRACT_ANALYSIS' && this.step === 'REVISIONS_TAB') {
      this.message = '';
      this._middleware.isModuleRequirementsActive().then((response) => {
        if (response) {
          this.requirements().then((validation) => {
            if (!validation) {
              // this.message = {
              //   type: 'alert-primary',
              //   text: 'Este Solicitud requiere que se aprueben los recaudos mínimos'
              // };
            }
          });
        }

        // rule 2: no se  puede responder un Solicitud que está en modo de editar por el análista.
        this.akWorkflowService.transactionData$.subscribe((transactionData: any) => {
          if (!isNullOrUndefined(transactionData)) {
            if (transactionData.editTaken) {
              this.message = {
                type: 'alert-primary',
                text: 'Esta solicitud no se puede responder mientras está habilitada su edición'
              };
            }
          }
        });



      }, error => {
        // rule 2: no se  puede responder un Solicitud que está en modo de editar por el análista.
        this.akWorkflowService.transactionData$.subscribe((transactionData: any) => {
          if (!isNullOrUndefined(transactionData)) {
            if (transactionData.editTaken) {
              this.message = {
                type: 'alert-primary',
                text: 'Esta solicitud no se puede responder mientras está habilitada su edición'
              };
            }
          }
        });
      });


    }
  }

  requirements() {
    return new Promise((resolve, reject) => {
      this.akWorkflowService.transactionData$.subscribe((transactionData: any) => {
        if (!isNullOrUndefined(transactionData)) {
          const config = {
            statusResponseRequirement: {
              verified: 'VERIFIED',
              inconsistent: 'INCONSISTENT',
              incomplete: 'INCOMPLETED',
              rejected: 'REJECTED'
            },
            endpoints: {
              getRequirements: {
                identifier: '',
                name: 'requirements$transaction_requirements$get',
                params: {
                  request: {
                    audience: transactionData.holder.additionalType ? transactionData.holder.additionalType : 'LEGAL'
                  },
                  path: {
                    idPlan: transactionData.plan.identifier,
                    transactionNumber: transactionData.transactionNumber,
                    idPerson: transactionData.holder ? transactionData.holder.identifier : null
                  },
                  body: {}
                }
              },
              saveRequirement: {
                params: {
                  request: {},
                  path: {},
                  body: {
                    'transaction': {
                      'identifier': transactionData.identifier,
                      'name': transactionData.transactionNumber
                    },
                    'requirement': {
                      'identifier': null
                    },
                    'requirementArchives': [
                      {
                        'creator': {
                          'identifier': null
                        }
                      }
                    ],
                    'person': {
                      'identifier': transactionData.holder.identifier,
                      'identity': transactionData.holder.alternateName,
                    }

                  }
                }
              },
              deleteRequirement: {
                name: 'requirements$requirement$delete',
                params: {
                  request: {},
                  path: {},
                  body: {
                    alternateName: 'ACCREDITED',
                    transaction: {
                      'identifier': transactionData.identifier,
                      'name': transactionData.transactionNumber
                    },
                    requirement: {
                      'identifier': null
                    },
                    person: {
                      'identifier': transactionData.holder.identifier,
                    },
                    requirementArchives: [{
                      identifier: null
                    }]
                  }
                }
              }
            }
          };

          // verifico si ya cargo todos sus recaudos...
          this._akeelaRequirementsService.verifyStatusUploadFiles(config).then((resp: any) => {
            if (resp.total_required_responses_user_requirements_completed >= resp.total_required_responses_user_requirements) {
              resolve(true);
            } else {
              resolve(false);
            }
          }, error => {
            resolve(false);
          });
        }
      });
    });

  }

  verifyAllFilesUpload(transactionData: any) {
    const params = {
      name: 'requirements$upload_all_docs$get',
      params: {
        request: {
          audience: transactionData.requestUser.alternateName
        },
        path: {
          idPlan: transactionData.plan.identifier,
          idTransaction: transactionData.identifier,
          idPerson: transactionData.holder ? transactionData.holder.identifier : null
        },
        body: {}
      }
    };
    return this.httpService.executeRequest(params.name, params.params, true);
  }

}
