<div class="container  px-0">

    <div class="mb-0 row box  box-section skin-white  animate__animated animate__fadeIn" style="padding-left: 0; padding-right:0">
        <div class="col-12 mt-2">

            <div class="  w-100 ak-table-0-comment">

                <div class="text-dark  mb-4 text-left">
                    <div class="text-form-description mb-2">
                        <div *ngIf="!showLoading" class="text-secondary alert alert-warning p-3 mt-4 mb-4 mb-2 animate__animated animate__fadeIn">
                            <div class=" font-weight-bold ">

                                Identifique la composición accionaria de la empresa. Agregue a todos los accionistas, personas naturales o empresas, principales con participación mayor al 10%. En caso que algún accionista sea de tipo empresa se le podrá solicitar identificar la composición
                                accionarias de la misma.


                            </div>
                        </div>
                    </div>

                </div>
            </div>


            
            <ng-container *ngIf="this.sumSharePercentage<100 && !showLoading">
                <button (click)="openItem({})" class="ak-btn-form  btn btn-outline-dark">                                   
                    <span class="fas fa-plus mr-1" ></span>

                    Agregar <span [innerHTML]="'@i18n-quote-shareholders-shareholdersTable-section.title' | i18n "></span></button>
            </ng-container>
            <ng-container *ngIf="itemList && !showLoading ">

                <div *ngIf="!((itemList && itemList.length===0))" class="mt-4">
                    <akeela-table [configuration]="configuration_table" [loading]="loading_table" (response)="table_response($event)">
                    </akeela-table>
                </div>


                <div class="text-secondary mb-2 mt-3 " *ngIf="itemList  && itemList.length>0">Total participación accionaria identificada <span class="font-weight-bold"> {{this.sumSharePercentage}}% de 100%</span>
                </div>
            </ng-container>


            <ng-container *ngIf="showLoading ">
                <div class="mt-3">
                    <img src="./assets/public/images/loading.gif" alt="">
                </div>
            </ng-container>
        </div>
    </div>
</div>