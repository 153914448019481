import { Injectable } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { Subscription, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private httpService: HttpService) { }

  getTransactionData = (transactionNumber, role, profile) => {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: role,
          profile: profile
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    return this.httpService.executeRequest(params.name, params.params, true);
  }
}
