import { Component, OnInit, AfterContentInit, AfterViewInit } from '@angular/core';
import { TemplateService } from '../../../../../services/template.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit, AfterViewInit {
  templates: any = {};

  constructor(private templateService: TemplateService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.templates = this.templateService.getTemplates();
    }, 1);
  }

}
