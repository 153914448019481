import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { AuthService } from '@akeela/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { TransitionService } from '../../../../services/transition.service';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from 'util';
import { LocalService } from '@akeela/local-storage';
import { Table, Row, Column, Dropdown, DropdownList } from '@akeela/ak-table';
import { DatePipe } from '@angular/common';
import { PropertiesService } from '../../../../services';


@Component({
  selector: 'app-detail-client',
  templateUrl: './detail-client.component.html',
  styleUrls: ['./detail-client.component.css']
})
export class DetailClientComponent implements OnInit, OnDestroy, AfterViewInit {
  client;
  queryParams;
  workflow;
  stage;
  step;
  url_transition = 'workflow$transitionssteps$get';
  active_redirect = false;
  transactionData;
  transactions;

  trans_subscriber;
  route_subscriber;
  wf_events_subscriber;
  username;
  cargando = '';

  paginator: any = null;
  loading_table = true;

  countrys;
  isAnalist = false;

  role;

  configuration_table: Table = null;
  readonly = false;
  legalForms = [];
  env: any = environment;

  ruleUniqueTransaction = false;
  nationality;

  constructor(
    private _httpService: HttpService,
    public _auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private akSectionService: AkeelaSectionService,
    private _transition: TransitionService,
    private akeelaBreadcrumbService: AkeelaBreadcrumbService,
    private akeelaSectionService: AkeelaSectionService,
    private localStorage: LocalService,
    private datePipe: DatePipe,
    private _properties: PropertiesService,


  ) { }

  ngAfterViewInit() {
  }

  ngOnInit() {



    this._properties.resetWks();


    if (this.localStorage.getItem('workspaceReadonly') === 'true' || this.localStorage.getItem('readonly') === 'true') {
      this.readonly = true;
    }

    //this.localStorage.removeItem('clientaudience');
    this.localStorage.setItem('currentView', 'REGISTER_CLIENT');
    this.role = this.localStorage.getItem('role');

    this.getCountrys();
    this.akWorkflowService.worflowEvents$.next(null);
    this.workflow = this.akWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akStageService.currentStage$.getValue();
    this.step = this.akStepService.currentStep$.getValue();
    this.akSectionService.addModalSection({ name: 'PROD_PLAN_SELECTOR_MODAL' });
    this.akSectionService.addModalSection({ name: 'PROD_PLAN_SELECTOR_EXECUTIVE_MODAL' });
    this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });

    if (!isNullOrUndefined(this.env.ruleUniqueTransaction)) {
      this.ruleUniqueTransaction = this.env.ruleUniqueTransaction === true ? true : false;
    }

    if (this.localStorage.getItem('profile') === 'ANALIST') {
      this.isAnalist = true;
    }
    const params = {
      name: 'person$enduser$get',
      params: {
        request: {
          'username': this.queryParams['viewUsername']
        },
        path: {},
        body: {}
      }
    };

    this.getLegalForms();

    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.client = response;
      this.localStorage.setItem('clientaudience', this.client.personMarketType.identifier);
   


      if (this.client.personMarketType.identifier === 'LEGAL' && Object.keys(this.client.adminOrganization.address).length > 0) {



        this.nationality = this.client.adminOrganization.address.addressCountry.nationality;
      }

      if (this.client.personMarketType.identifier === 'NATURAL' && this.client.person.homeAddress) {
        this.nationality = this.client.person.homeAddress.addressCountry.nationality;


      }



      //        : client.person.homeAddress.addressCountry.identifier"
     // this.getTransactions(response.identifier, environment.paginator.page, environment.paginator.size);
      this.localStorage.setItem('clientUsername', response.identifier);
      this.new();

      this.akWorkflowService.transactionData$.subscribe((data: any) => {
        this.transactionData = data;
      });

    });

    this.wf_events_subscriber = this.akWorkflowService.worflowEvents$.subscribe((response: any) => {
      if (response) {
        if ((response.action === 'account$requests_withLegal_representative$post' || response.action === 'account$accountopening$post') && response.response.transactionNumber && !this.active_redirect) {

          this.akSectionService.hideModalSection('PROD_PLAN_SELECTOR_MODAL');
          this.active_redirect = true;
          this.akWorkflowService.setTransactionNumber(response.response.transactionNumber);
          this.getAccountproductinformation(response, response.response.transactionNumber);
        }
      }
    });



  }

  getLegalForms() {

    const params = {
      name: 'product_plan$legal_forms$get',
      params: {
        request: {},
        path: {},
        body: {}
      }
    };

    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {


      for (const obj of Object.keys(response)) {

        this.legalForms[response[obj].identifier] = response[obj].name;

      }

    });

  }

  setConfigurationTable() {
    const base: Column[] = environment.akeelaTablesConfig.listTransactions.columns;
    this.configuration_table = {
      cssCustom: '',
      width: '100%',
      header: {
        cssCustom: 'font-weight-bold class-color-title',
        rows: [{
          backgroundColor: '#F8F7F7',
          columns: base
        }],
      },
      body: {
        cssCustom: '',
        rows: this.setContentTable(base)
      },
      footer: {
        cssCustom: '',
        rows: []
      }
    };
  }

  setContentTable(configuration): Row[] {
    const content: Row[] = [];
    let i = 0;
    for (const row of this.transactions) {


      /* Valores configurables */
      /*icono*/
      let icon = '';
      const initials = this.getInitials(row.holder.name, '');
      const initial = this.getInitial(row.holder.name);
      const name = row.holder.name;
      icon = '<div class="DomainUserAvatar-avatar Avatar--color-' + initial + ' AvatarPhoto AvatarPhoto--small" role="img">' + initials + '</div>';


      content.push(
        {
          identifier: row.identifier,
          cssCustom: '',
          enabled: true,
          columns: [
            {
              alignContent: configuration[0].alignContent,
              fixed: configuration[0].fixed,
              maxWidth: configuration[0].maxWidth,
              minWidth: configuration[0].minWidth,
              html: icon,
              cssCustom: configuration[0].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[0].enableLeftBorder) ? configuration[0].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[0].enableRightBorder) ? configuration[0].enableRightBorder : false
            },
            {
              alignContent: configuration[1].alignContent,
              fixed: configuration[1].fixed,
              maxWidth: configuration[1].maxWidth,
              minWidth: configuration[1].minWidth,
              html: row.holder.name,
              cssCustom: configuration[1].cssCustom + ' text-capitalize',
              enableLeftBorder: !isNullOrUndefined(configuration[1].enableLeftBorder) ? configuration[1].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[1].enableRightBorder) ? configuration[1].enableRightBorder : false
            },
            {
              alignContent: configuration[2].alignContent,
              fixed: configuration[2].fixed,
              maxWidth: configuration[2].maxWidth,
              minWidth: configuration[2].minWidth,
              html: (row.transactionNumber),
              cssCustom: configuration[2].cssCustom + ' ak-text-color-4',
              enableLeftBorder: !isNullOrUndefined(configuration[2].enableLeftBorder) ? configuration[2].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[2].enableRightBorder) ? configuration[2].enableRightBorder : false
            },
            {
              alignContent: configuration[3].alignContent,
              fixed: configuration[3].fixed,
              maxWidth: configuration[3].maxWidth,
              minWidth: configuration[3].minWidth,
              html: row.accountStatus[0].userName,
              cssCustom: configuration[3].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[3].enableLeftBorder) ? configuration[3].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[3].enableRightBorder) ? configuration[3].enableRightBorder : false
            },
            {
              alignContent: configuration[4].alignContent,
              fixed: configuration[4].fixed,
              maxWidth: configuration[4].maxWidth,
              minWidth: configuration[4].minWidth,
              html: this.datePipe.transform(row.dateOfThing.orderDate),
              cssCustom: configuration[4].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[4].enableLeftBorder) ? configuration[4].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[4].enableRightBorder) ? configuration[4].enableRightBorder : false
            },
            {
              alignContent: configuration[5].alignContent,
              fixed: configuration[5].fixed,
              maxWidth: configuration[5].maxWidth,
              minWidth: configuration[5].minWidth,
              html: this.datePipe.transform(row.dateOfThing.modifiedDate),
              cssCustom: configuration[5].cssCustom,
              enableLeftBorder: !isNullOrUndefined(configuration[5].enableLeftBorder) ? configuration[5].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[5].enableRightBorder) ? configuration[5].enableRightBorder : false
            },
            {
              alignContent: configuration[6].alignContent,
              fixed: configuration[6].fixed,
              maxWidth: configuration[6].maxWidth,
              minWidth: configuration[6].minWidth,
              html: '<i class="fas fa-angle-right text-primary" title="Ir..."></i>',
              cssCustom: configuration[6].cssCustom + ' ak-text-color-4',
              enableLeftBorder: !isNullOrUndefined(configuration[6].enableLeftBorder) ? configuration[6].enableLeftBorder : false,
              enableRightBorder: !isNullOrUndefined(configuration[6].enableRightBorder) ? configuration[6].enableRightBorder : false
            },
          ]
        }
      );
      i++;
    }
    return content;
  }
  // responseTime,
  table_response(response) {
    if (response.action === 'open') {
      let transaction = null;
      transaction = this.transactions.find((wspc: any) => {
        return wspc.identifier === response.object.identifier;
      });

      this.openFormalities(transaction.transactionNumber);

    }
    // if (response.action === 'order') {
    //   this.filter_order = response.object;
    //   this.applyFilter(response.object);
    // }

    // if (response.action === 'action') {
    //   if (response.object.action === 'assign_user') {
    //     this.assign(response.object.obj.client, response.object.obj.procedure);
    //   }
    // }

    if (response.action === 'paginator') {
      this.action_paginator(response.object);
    }
  }

  getInitials(givenName: string, familyName: string) {
    return (
      (givenName ? givenName.substr(0, 1).toUpperCase() : '') +
      (familyName ? familyName.substr(0, 1).toUpperCase() : '')
    );
  }

  getAccountproductinformation(responseIn, transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role,
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };

    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.akWorkflowService.transactionData$.next(response);
      const audience = this.client.adminOrganization ? 'LEGAL' : 'NATURAL';
      this.akWorkflowService.setTransactionNumber(response.transactionNumber);
      const aux = Object.assign({ transactionNumber: response.transactionNumber, audience: audience }, this.queryParams);
      const parameters = {
        name: this.url_transition,
        params: {
          request: {
            role: 'A',
            profile: 'USER',
            audience: '',
            event_code: 'PRODUCT_PLAN_SELECTED_EXECUTIVE'
          },
          path: {
            prefixWorkflow: 'workflow_manager',
            workflow: this.workflow,
            stage: this.stage,
            step: this.step
          }
        },
        o: {}
      };
      this._transition.next('private', parameters, aux);
      this.destroySubscribers();

      // this.router.navigateByUrl(
      //   this.router.createUrlTree(['private/ACCOUNT_OPENING/stages/PRODUCT_INFORMATION/steps/PRODUCT_INFORMATION'],
      //     {
      //       queryParams: queryParams
      //     }
      //   )
      // ).then(() => {
      //   this.destroySubscribers();
      // });
    });
  }



  getCountrys() {
    const params = {
      name: 'app_properties$countrys$get',
      params: {
        request: {},
        path: {},
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.countrys = response;
    }, error => { });
  }

  getCountryByIdentifier(identifier) {
    const country = this.countrys.find((_country) => {
      return _country.identifier === identifier;
    });
    return country ? country.description : identifier;
  }

  
  getTransactions(username, page, size) {
    const params = {
      name: 'account$myformalities_paginated$get',
      params: {
        request: {
          page: page,
          size: size
        },
        path: {
          username: username
        },
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.loading_table = false;
      this.paginator = response;
      console.log('PAGINACIÓN:');
      console.log(this.paginator);
      this.transactions = response.content;
      this.setConfigurationTable();
    }, error => {
      this.loading_table = false;
    });
  }

  action_paginator(page) {
    this.loading_table = true;
    this.getTransactions(this.client.identifier, page, environment.paginator.size);
  }

  openFormalities(transactionNumber) {
    if (!this.loading_table) {
      this.localStorage.removeItem('transactionModeEditShowFlag');
      this._transition.showWorkflow$.next(false);
      this.cargando = transactionNumber;

      this.akWorkflowService.setTransactionNumber(transactionNumber);
      // ;
      this.getTransactionData(transactionNumber).subscribe((data: any) => {
        this.transactionData = data;
        this.getPerson(data.holder.identifier).subscribe((response: any) => {
          this.redirect(transactionNumber, 'NATURAL');
        }, (error: any) => {
          this.redirect(transactionNumber, 'LEGAL');
        });
      });

      this.cargando = '';
    }
  }

  getInitial(text) {
    let retorno = '';
    if (!isNullOrUndefined(text)) {
      retorno = text.substr(0, 1).toUpperCase();
    }
    return retorno;
  }


  getPerson(identifier) {
    const params = {
      name: 'person$person$get',
      params: {
        request: { id: identifier },
        path: {},
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  getTransactionData(transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role,
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  redirect(transactionNumber, audience) {
    this.localStorage.setItem('clientaudience', audience);

    const queryParams = {
      profile: this.localStorage.getItem('profile'),
      role: this.localStorage.getItem('role'),
      audience: audience,
      transactionNumber: transactionNumber
    };


    let url;




    const configuration = {
      name: 'account_opening$current_step$get',
      params: {
        path: {
          transactionNumber: this.transactionData.transactionNumber,
          idPerson: this.transactionData.holder ? this.transactionData.holder.identifier : null
        },
        request: {
          idPlan: this.transactionData.plan.identifier,
          role: this.localStorage.getItem('role'),
          audience: audience,
          depend: false
        }
      }
    };

    this._httpService.executeRequest(configuration.name, configuration.params, true).subscribe((resp: any) => {

      if (!isNullOrUndefined(resp)) {

        let stage = resp.stageParentCode;
        let step = resp.alternateName;
        let urlWf = resp.url;
        url = ['private/' + urlWf + '/stages/' + stage + '/steps/' + step];

      } else {

        url = environment.staticLinks.detailClientResumen;

      }


      this.router.navigateByUrl(
        this.router.createUrlTree(url,
          {
            queryParams: queryParams
          }
        )
      ).then(() => {
        this.destroySubscribers();
      });

      this.cargando = '';

    }, error => {

      url = environment.staticLinks.detailClientResumen;
      this.router.navigateByUrl(
        this.router.createUrlTree(url,
          {
            queryParams: queryParams
          }
        )
      ).then(() => {
        this.destroySubscribers();
      });

    });

  }


  new() {

    this.localStorage.setItem('clientaudience', this.client.personMarketType.identifier);
    this.akSectionService.showModalSection('PROD_PLAN_SELECTOR_EXECUTIVE_MODAL', false);
    this.akStepService.setModeCurrentStep(false);

  }

  destroySubscribers() {
    if (this.trans_subscriber) {
      this.trans_subscriber.unsubscribe();
    }
    if (this.route_subscriber) {
      this.route_subscriber.unsubscribe();
    }
    if (this.wf_events_subscriber) {
      this.wf_events_subscriber.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.localStorage.removeItem('currentView');

    if (this.trans_subscriber) {
      this.trans_subscriber.unsubscribe();
    }
    if (this.route_subscriber) {
      this.route_subscriber.unsubscribe();
    }

    if (this.wf_events_subscriber) {
      this.wf_events_subscriber.unsubscribe();
    }
  }

}
