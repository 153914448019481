import { Component, OnInit, OnDestroy } from '@angular/core';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AuthService } from '@akeela/auth';
import { HttpService } from '@akeela/properties';

import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from '@angular/forms';
// import { ErrorHandler } from '@angular/router/src/router';

@Component({
  selector: 'app-private-client-executive',
  templateUrl: './client-executive.html',
  styleUrls: ['./client-executive.css']
})
export class ClientExecutiveComponent implements OnInit, OnDestroy {
  rows: any = [];
  showWindowNewContact = false;
  lang: any;
  clients: any = null;
  clients_backup: any = null;
  form: FormGroup;
  executives;

  constructor(
    public _router: Router,
    private formBuilder: FormBuilder,
    private router: Router,
    private _auth: AuthService,
    private _httpService: HttpService
  ) { }

  ngOnInit() {
    document.body.classList.add('teme-gray');
    this.form = this.formBuilder.group({
      filter: ['']
    });

    // consulta de clientes...
    const params = {
      name: 'person$endusers$get',
      params: {
        request: {},
        path: {},
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params).subscribe((response: any) => {
      this.clients = response;
      this.clients_backup = response;
    });


    // this._userRegister.getExecutives('MANAGER').subscribe(data => {
    //   this.executives = data;
    // });

    // this._userRegister.getClients().subscribe(data => {
    //   this.clients = data;
    //   this.clients_backup = data;
    // });


    // this._userRegister.get_detect_clients().subscribe(data => {
    //   this._userRegister.getClients().subscribe(data => {
    //     this.clients = data;
    //     this.clients_backup = data;
    //   });
    // });
  }

  ngOnDestroy() {
    document.body.classList.remove('teme-gray');
  }

  callFn(fn) {
    if (fn) {
      if (typeof this[fn['name']] === 'function') {
        let data = '';
        fn['data'].forEach(function (value, key) {
          if (key < (fn['data'].length - 1)) {
            data += value + ',';
          } else {
            data += value;
          }
        });
        this[fn['name']](data);
      } else {
        console.log('function no definida:', fn.f);
      }
    }
  }

  assignExecutive(client, systemuser) {


    // this._userRegister.saveAssociateClientSystemuser(client, systemuser).subscribe(data => {

    //   this.clients = false;
    //   this._userRegister.getClients().subscribe(data => {
    //     this.clients = data;
    //     this.clients_backup = data;
    //   });


    //   swal.fire({
    //     type: 'success',
    //     title: 'Éxito',
    //     html: '<b>El executive ha sido asignado exitosamente</b>',
    //   });
    // });
  }


  notificacion(evt) {
    swal.fire({
      title: evt.title,
      html: evt.html,
    });
  }

  response_function(response) {
    if (response !== null) {
      switch (response.error) {
        case 0:
          swal.fire({
            title: '',
            html: '<b>El usuario ha sido registrado correctamente, y se ha notificado por correo</b>',
          });
          // $('.close').trigger('click');
          this.clients = null;
          // this._userRegister.set_detect_clients();
          // this._router.navigate(['user/contacts']);
          // this._router.navigate(['user/contact/detail/'+response.data.identifier]);
          break;
        case 1:
          swal.fire({
            title: 'Éxito',
            html: '<b>El usuario ha sido registrado correctamente, pero no pudo enviar el correo de notificación.</b>',
          });
          // $('.close').trigger('click');
          this.clients = null;
          // this._userRegister.set_detect_clients();
          // this._router.navigate(['user/contacts']);
          // this._router.navigate(['user/contact/detail/'+response.data.identifier]);
          break;
        case 2:
          swal.fire({
            title: 'Error',
            html: '<b>El usuario no pudo ser registrado</b>',
          });
          break;
        case 3:
          swal.fire({
            title: 'Error',
            html: '<b>El usuario no pudo ser registrado</b>',
          });
          break;
        default:
          swal.fire({
            title: 'Error',
            html: '<b>El usuario no pudo ser registrado</b>',
          });
          break;
      }

    } else {
      swal.fire({
        title: 'Error',
        html: '<b>El usuario no pudo ser registrado</b>',
      });
    }
  }

  getInitials(givenName: string, familyName: string) {
    return (
      givenName.substr(0, 1).toUpperCase() +
      familyName.substr(0, 1).toUpperCase()
    );
  }

  getFirstLetter(data: string) {
    return data.substr(0, 1).toUpperCase();
  }

  search_clients(evt) {
    this.clients = this.clients_backup;
    let search_text = '';
    if (evt.key === 'Backspace') {
      search_text = this.form
        .get('filter')
        .value.substring(0, this.form.get('filter').value.length - 1);
    } else {
      search_text = this.form.get('filter').value + evt.key;
    }
    const aux = this.clients;

    // client.personMarketType?.identifier  == '2'?client.person.givenName+' '+client.person.familyName:client?.adminOrganization?.legalName

    this.clients = this.clients.filter((client: any) => {
      let first_search = '';
      // client.personMarketType.identifier
      if ('2' === '2') {
        first_search = client.person.givenName;
      } else {
        first_search = client.adminOrganization.legalName;
      }

      let second_search = '';
      if ('2' === '2') {
        second_search = client.person.familyName;
      } else {
        second_search = client.adminOrganization.legalName;
      }

      return (
        first_search.toUpperCase().indexOf(search_text.toUpperCase()) !== -1 ||
        second_search.toUpperCase().indexOf(search_text.toUpperCase()) !== -1
      );
    });
  }

  showDetail(username: string, idPerson: number) {
    // this._procedure.getPerson(idPerson).subscribe(data=>{
    //   localStorage.setItem('formality_audience', '2')
    // },err=>{
    //   localStorage.setItem('formality_audience', '3')
    // });
    // localStorage.setItem('formality_person_identifier', idPerson.toString())
    // this.router.navigateByUrl('user/contact/detail/' + username+'/'+idPerson);
  }

}
