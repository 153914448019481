import { AuthService, ErrorService } from '@akeela/auth';
import { UploadService } from '@akeela/common';
import { I18nPipe } from '@akeela/i18n';
import { LocalService } from '@akeela/local-storage';
import { HttpService } from '@akeela/properties';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';
import swal from 'sweetalert2';
import { Subscription, timer } from 'rxjs';
import { isNullOrUndefined, isNull } from 'util';
import { PropertiesService } from 'src/app/services';
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';



@Component({
  selector: 'akeela-user-verification',
  templateUrl: './akeela-user-verification.component.html',
  styleUrls: ['./akeela-user-verification.component.css']
})
export class AkeelaUserVerificationComponent implements OnInit,OnDestroy {



  env = environment;
  animate_init = true;
  animate_init_bottom = true;
  buttomModal;
  clientDetail;
  init_meet;
  back = false;
  menuSelect = false;
  shared = false;
  newInterview = false
  dataAndSelfi;
  filePreview;
  client;
  dataIdentity;
  compleatVerifi;
  loading;
  loadingTransaction;

  verifiedIdentification;
  copy;
  interviews = [];
  interviewsObj = {};
  rows = [];
  transactions;
  interviewTypeIcon = [];
  interviewType = [];
  formsDataResponse = {};
  interviewsTime = {};
  optVerification;
  clientAtteps;
  clientAttepsResponse = [];
  clientNumberAtteps = [];
  clientNumberAnalisis = [];
  clientDatesAnalisis = [];
  dataInformation;
  items = [];
  dataInformationsSubscription: Subscription;


  @Output() change = new EventEmitter();

  @Input() set username(client) {
    this.clientDetail = [];

    if (client) {
      this.dataAndSelfi = null;
      this.filePreview = null;
      this.init_meet = false;

      this.client = client;
     // this.get_data_identity();
      this.getUser();
      this.init_meet = false;

    }
  }


  @Input() set selected(buttomModal) {
    if (buttomModal) {
      let html = document ?.getElementById("idHtml");
      html ?.classList ?.add('scroll-none');

      this.buttomModal = true;
      this.animate_init_bottom = false;
    }
  }

  constructor(
    private _httpService: HttpService,
    private i18n: I18nPipe,
    private _upload: UploadService,
    private datePipe: DatePipe,
    private _auth: AuthService,
    private sanitizer: DomSanitizer,
    private localStorage: LocalService,
    private error: ErrorService,
    public _properties: PropertiesService,
    public readonly swalTargets: SwalPortalTargets


  ) { }

  ngOnInit(): void {



  }


  ngOnDestroy() {


    if (this.dataInformationsSubscription) {
      this.dataInformationsSubscription.unsubscribe();
    }
  }
  
  


  verificarClient(resp) {
    let name = this.clientDetail ?.person ?.givenName + ' ' + this.clientDetail ?.person ?.familyName 

    const params = {
      name: 'person$verified_identification$post',
      params: {
        request: {
          'verified':resp,
          'username': this.client,
          'requestUser': this._auth.getUsername()
        },
        path: {},
        body: {}
      }
    };

    let texto;
    if(resp===true) {
      texto = 'Aprobar'
    } else {
      texto = 'Rechazar';
    }

    swal.fire({
      title: '',
      html: this.i18n.transform(`¿Desea ${texto} la verificación de identidad a <b>${name}</b>?`),
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result?.isConfirmed) {
       this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
          this.optVerification = false;
          this.getUser();
        });
      }

    }, error => {


    });


    
  }

  showInfoFacialRecognition = (type) => {
    this.items = [];
    if (this.validateAttemp(type).length > 0) {
      this.items = this.clientAttepsResponse[type];
    }

  }

  resetVerification() {
    let name = this.clientDetail ?.person ?.givenName + ' ' + this.clientDetail ?.person ?.familyName 

  const params = {
      name: 'person$reset_complete_identification$post',
      params: {
        request: {
          'username': this.client,
          'requestUser': this._auth.getUsername()

        },
        path: {},
        body: {}
      }
    };

    swal.fire({
      title: '',
      html: this.i18n.transform('¿Desea devolver la verificación de identidad a <b>' + name + '</b>?'),
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {

      if (result ?.isConfirmed) {

        this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

          this.optVerification = false;
          this.getUser();


        });


      }



    }, error => {


    });

  }



  changes() {

    let html = document ?.getElementById("idHtml");
    html ?.classList ?.remove('scroll-none');

    this.buttomModal = false;
    this.change.emit(false);
  }



  setBack() {
    this.resetMenu();
    this.optVerification = false;

  }


  setUserDetail() {

    this.buttomModal = false;
    this.change.emit(this.client);


  }


  resetMenu() {
    this.menuSelect = false;
    this.shared = false;
    this.newInterview = false;
    this.optVerification = false;
    this.copy = false;
  }



  getAttempt = async () => {
    const params = {
      name: 'person$doc_attempts$get',
      params: {
        request: {
          'idPerson': this.clientDetail ?.person ?.identifier
      },
        path: {},
        body: {}
      }
    };
    return await this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }


  getUser() {
    const params = {
      name: 'person$enduser$get',
      params: {
        request: {
          'username': this.client
        },
        path: {},
        body: {}
      }
    };


    this.loading = true;

    this._httpService.executeRequest(params.name, params.params, true).subscribe(async (response: any) => {

      this.clientDetail = response;
      this.loading = false;
      this.clientAtteps = await this.getAttempt();
      this.processAttept();
   
      this.getDataInformations();

    });

  }

  processAttept = () => {
    const req = ['SELFIE', 'SELFIE_DOC_IDENTITY', 'DOC_IDENTITY'];
    req.map((r) => {
      this.clientAtteps.filter(async (att) => {
        if (att.alternateName === r) {
          if (!isNullOrUndefined(att.contentSize)) {
            this.clientNumberAtteps[r] = att.contentSize;
          } else {
            this.clientNumberAtteps[r] = null;
          }
          if (att.additionalType) {
            this.clientAttepsResponse[r] = await this.getAnalisis(this.getFacialRecognitionJson(att.additionalType));
          }
          if (att.description) {
            this.clientNumberAnalisis[r] = att.description.includes('unknow') ? false : true;
          }
        }
      });
      if (isNullOrUndefined(this.clientAttepsResponse[r])) {
        this.clientAttepsResponse[r] = '';
      }
    });
  }

  getJsonAttempt = (req) => {
    const attempt = this.clientAtteps.filter((att) => {
      return req === att.alternateName
    });
    if (attempt.length > 0) {
      if (attempt[0].additionalType) {
        return attempt[0].additionalType;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  validateAttemp = (requirement) => {
    if (!isNullOrUndefined(this.clientAtteps)) {
      const attempt = this.clientAtteps.filter((att) => {
        return att.alternateName === requirement;
      });
      return attempt;
    }
    return '';
  }

  getFacialRecognitionJson = (obj) => {
    if (!isNullOrUndefined(obj)) {
      // this.getFacialRecognitionExpresion(JSON.parse(obj));
      return JSON.parse(obj);
    }
    return null;
  }

  getAnalisis = async (obj) => {
    let analisis = [];
    let age = this.getAge(obj);
    let expresions = await this.getFacialRecognitionExpresion(obj);
    let gender = this.getGender(obj);
    let confidence = this.getConfidence(obj);
    analisis = [...analisis, age];
    analisis = [...analisis, expresions];
    analisis = [...analisis, gender];
    analisis = [...analisis, confidence];

    return analisis;

    // let text = 'IA análisis facial: <br><div class="text-left">';
    // if (!isNullOrUndefined(obj)) {
    //   text = text + '   Expresión: <b>' + expresions.description + '</b> ('+(this.round(expresions.percentaje*100))+'%)';
    //   return text + '</div>';
    // } else {
    //   return text + '</div>';
    // }

  }

  round = (val) => {
    return Math.round(val);
  }

  getResponsePreview(response) {

  }

  getConfidence = (obj) => {
    const age = !isNullOrUndefined(obj[0].age) ? obj[0].age : null;
    return { 'percentaje': this.roundToPercent(0.98), 'description': 'Confianza' };
  }

  getAge = (obj) => {
    console.log(obj);
    const age = !isNullOrUndefined(obj[0].age) ? obj[0].age : null;
    return { 'percentaje': 'unknow', 'description': Math.ceil(age) + ' Años (Aprox.)' };
  }

  getGender = (obj) => {
    let description = !isNullOrUndefined(obj[0].gender) ? obj[0].gender : null;
    if (description === 'female') {
      description = 'Femenino';
    } else {
      description = 'Masculino';
    }
    const percentaje = !isNullOrUndefined(obj[0].genderProbability) ? obj[0].genderProbability : null;
    return { 'percentaje': this.roundToPercent(percentaje), 'description': description };
  }

  roundToPercent = (percent) => {
    return Math.ceil((percent * 100));
  }

  getFacialRecognitionExpresion = async (obj) => {
    let max = 0;
    let max_selected = 'Sin identificar';
    if (!isNullOrUndefined(obj)) {
      await Object.keys(obj[0]['expressions']).map(async (item) => {
        if (obj[0]['expressions'][item] > max) {
          max = obj[0]['expressions'][item];
          max_selected = this.getExpresion(item);
        }
      });
    }
    return { 'percentaje': this.roundToPercent(max), 'description': max_selected };

  }

  getAttepts = () => {

  }

  getExpresion = (expresion) => {
    switch (expresion) {
      case 'happy':
        return 'Feliz';
      case 'neutral':
        return 'Neutral';
      case 'sad':
        return 'Triste';
      case 'angry':
        return 'Molesto';
      case 'fearful':
        return 'Asustado';
      case 'disgusted':
        return 'Molesto';
      case 'surprised':
        return 'Sorprendido';
      default:
        return 'Sin identificar';
    }
  }


  get_data_identity() {

    const params = {
      name: 'person$get_data_identity$get',
      params: {
        request: {
          'username': this.client
        },
        path: {},
        body: {}
      }
    };



    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      this.dataIdentity = response;




    });

  }


  getDocumentClient(type) {
    if (!isNullOrUndefined(this.clientDetail)) {
      if (this.clientDetail.personDocuments) {
        const doc = this.clientDetail.personDocuments.filter((doc) => {
          return doc.alternateName === type;
        });
        if (!isNullOrUndefined(doc) && doc.length > 0) {
          return doc[0].identifier;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }


  show_filePreview(data) {
    this.show_file(data.identifier);
  }



  show_file(file) {
    file = { identifier: file };
    const archive = {
      identifier: file.identifier,
      name: file.name,
      dateCreated: {
        orderDate: null
      }
    };

    if (file.dateCreated) {
      archive['dateCreated']['orderDate'] = file.dateCreated.modifiedDate;
    } else {
      archive['dateCreated']['orderDate'] = null;
    }
    if (archive.identifier !== null) {
      this._upload.visor_params.name = '';
      this._upload.visor_params.date = '';
      this._upload.visor_params.endpoint = 'archive$downloadFile$get';
      this._upload.visor_params.archive = archive;
      this._upload.visor_params.deleteAfterShow = '';
      if (file['action'] === 'download') {
        this._upload.visor_params.action = 'download';
        this._upload.visor_params.deleteAfterShow = {
          deleteRequirement: file['deleteAfterShow']
        };
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      } if (file['action'] === 'downloadzip') {

        if (this.env.theme.images === 'FACEBANK') {
          this._upload.visor_params.endpoint = 'archive$downloadZipFile$get';
        }

        this._upload.visor_params.action = 'download';
        this._upload.visor_params.deleteAfterShow = {
          deleteRequirement: file['deleteAfterShow']
        };
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      } else {
        this._upload.visor_params.action = '';
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      }
    }
  }


  calculateAge(birthday) {

    let temp;
    if (!isNullOrUndefined(birthday)) {

      let birthday_arr = birthday.split("/");
      let birthday_date = new Date(birthday_arr[2], birthday_arr[1] - 1, birthday_arr[0]);
      let ageDifMs = Date.now() - birthday_date.getTime();
      let ageDate = new Date(ageDifMs);
      temp = '(' + Math.abs(ageDate.getUTCFullYear() - 1970) + ' años)';
    }

    return temp;

  }


  toDateString(data) {

    return this._properties.toDateString(data);

  }

  toDateTime(data) {
    if (!isNullOrUndefined(data)) {
      return this._properties.toDateTime(data);
    } else {
      return null;
    }


  }


getDataInformations() {


  if (this.dataInformationsSubscription) {
    this.dataInformationsSubscription.unsubscribe();
  }

  const params = {
    name: 'briefcase$person$get',
    params: {
      request: {
        'id': this.clientDetail?.person?.identifier
      },
      path: {},
      body: {}
    }
  };

  this.dataInformationsSubscription = this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {


    this.compleatVerifi = response.completeIdentification ;
    this.verifiedIdentification = response.verifiedIdentification ;
    
   this.dataInformation = response;
  
  });


  

}

}
