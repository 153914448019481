import { AuthService } from '@akeela/auth';
import { I18nPipe } from '@akeela/i18n';
import { HttpService } from '@akeela/properties';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import swal from 'sweetalert2';
import { UploadService, ErrorService } from '@akeela/common';
import { environment } from '../../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalService } from '@akeela/local-storage';

import { isNullOrUndefined } from 'util';
import { AkeelaSectionService, AkeelaStageService, AkeelaStepService, AkeelaWorkflowService } from '@akeela/workflow';
import { ActivatedRoute, Router } from '@angular/router';
import { async, Subscription } from 'rxjs';
import { PropertiesService } from 'src/app/services';
import { WorkflowService } from 'src/app/services/workflow.service';
import { NotesService } from 'src/app/services/notes.service';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.css']
})
export class ClientDetailComponent implements OnInit,OnDestroy {
  subscriptions: Subscription[] = [];
  transactionCompleat;
  transactionNumberCompleat;
  animate_init_bottom = true;
  buttomModal = false;
  client;
  clientDetail: any = [];
  loading = true;
  loadingVerifi = true;
  dataIdentity;
  optVerification;
  compleatVerifi;
  env= environment;
  dataInformation;
  verifiedIdentification;
  transactions;
  rows;

  dataAndSelfi;
  filePreview;
  animate_meet;
  init_meet;
  preview;
  userMeet;
  clientAtteps;
  userClientData;
  init_clientData;
  registerCVV;
  indicatorsLoading;
  indicatorsProgresRsp={};
  indicatorsProgres;
  @Output() change = new EventEmitter();
  @Output() la? = new EventEmitter();


  @Input() set username(client) {
    if(!isNullOrUndefined(client)) {
      this.setClient(client);
      this.dataInfoClientLA = null;
      this.loadingdataInfoClientLA = false;
    }
  }

  @Input() set selected(buttomModal) {

    if(buttomModal){

      this.loadingdataInfoClientLA = false;
      this.dataInfoClientLA = null;
      this.getdataInfoClientLAError = null;

      let html =  document?.getElementById("idHtml");
      html?.classList?.add('scroll-none');
      this.setSelect(buttomModal);

    }
  }

  transactionDetail;

  animate_contact = true;
  animate_verifi = false;
  animate_solic = false;
  animate_solic_requirements = false;

  userNameSesion;

  transactionSelected = null;
  private subscription_query_params: Subscription;
  userverif ;
  init_verif;
  dataInformationsSubscription: Subscription;
  dataInfoClientLASubscription: Subscription;
  dataInfoClientLA;
  isGetServceLA;

  dataInfoClientAKB;
  loadingdataInfoClientLA = true;
  loadingdataInfoClientAKB = true;

  verificationLA;
  verificationAKB;
  listWfSubscription: Subscription;

  listWf = {};
  isAnalist;
  newTransaction;

  getdataInfoClientLAError;
  getdataInfoClientAKBError;
  cvv;

  errorEmail;
  loadingEmail;
  updateEmail:any;
  newEmail:any = "";

  loadingPhone;
  updatePhone:any;
  newPhone:any = "";
  errorPhone;  
  mdActve;
  constructor(
    private _httpService: HttpService,
    private i18n: I18nPipe,
    private _upload: UploadService,
    private datePipe: DatePipe,
    private _auth: AuthService,
    private sanitizer: DomSanitizer,
    private localStorage: LocalService,
    private akStepService: AkeelaStepService,
    private akSectionService: AkeelaSectionService,
    private route: ActivatedRoute,
    private router: Router,
    public _properties : PropertiesService,
    private _wfService: WorkflowService,
    private _error:ErrorService,
    public  _notes: NotesService,



  ) { }



  ngOnInit(): void {
    this.mdActve = true;
    this.env = environment;

    if (environment.theme.images === 'RENDIVALORES' ||  environment.theme.images === 'MC'  || environment.theme.images === 'KOI'  ) {

       this.verificationLA = true;

    }
    

    if (environment.theme.images === 'MC' ||  environment.theme.images === 'RENDIVALORES'  || environment.theme.images === 'KOI' ) {
     
      this.cvv = true;

    }

    /*if (environment.theme.imag                    'fecha_creacion': `${dia}.${mes}.${anno}`,
es === 'MC' ) {

      this.verificationAKB = true;

    }*/

    



    this.userNameSesion = this._auth.getSession() ?.businessName ? this._auth.getSession() ?.businessName : this._auth.getSession() ?.firstName + ' ' + this._auth.getSession() ?.lastName;
  
  
    if (this.listWfSubscription) {
      this.listWfSubscription.unsubscribe();
    }
    
    this.listWfSubscription = this._wfService.listWF$.subscribe((listWf: any) => {
      if(listWf){
        this.listWf = listWf;

      }
             
    });

    if (this.localStorage.getItem('profile') === 'ANALIST') {
      this.isAnalist = true;
    } else {
      this.isAnalist = false;
    }
  }


getListWf(wf){
  return   this.listWf[wf];
  }

  changeStatusFacialRecognition = async(status) => {
    const action = status!==true?'Activar':'Desactivar'
    swal.fire({
      title: '',
      html: "¿Desea <b>'" +action+ "'</b> el reconocimiento facial del cliente?",
      showCancelButton: true,
      confirmButtonText: 'Si'

    }).then(async (result) => {
      if (result.value) {
        const params = {
          name: 'person$active_facial_recognition$post',
          params: {
            request: {
              'idPerson': this.clientDetail.person.identifier,
              'active': !status
            },
            path: {},
            body: {}
          }
        };
        const response = await this._httpService.executeRequest(params.name, params.params, true).toPromise();
        if(!isNullOrUndefined(response)) {
          this.clientDetail.person.facialRecognitionActive = response;     
        }
      }

    });
    
  }

  

  setClient(client){


    this.errorEmail = null;
    this.loadingEmail = null;
    this.updateEmail  = null;
    this.newEmail = "";
  
    this.loadingPhone = null;
    this.updatePhone  = null;
    this.newPhone = "";
    this.errorPhone = null;  

    this.isGetServceLA = null;
    this.registerCVV = null;
    this.clientDetail = [];
    if (client) {
      this.dataAndSelfi = null;
      this.filePreview = null;
      this.client = client;
      this.get_dataAndSelfi();
    //  this.get_data_identity();
      this.getUser();
      this.getTransactions();

    }
  }
  
  setSelect(buttomModal){

    if (buttomModal) {
      this.buttomModal = true;
      this.animate_init_bottom = false;
      this.animate_contact = true;
      this.animate_verifi = false;
      this.animate_solic = false;
      this.animate_solic_requirements = false;
      if (this.localStorage.getItem('objContactSetView')) {
        this.setView(this.localStorage.getItem('objContactSetView'));
      }
    }
  }

  setTransactionView = (transactionNUmber) => {

    this.buttomModal = false;
    this.change.emit({
      open: 'transaction',
      value: transactionNUmber
    });
  }


  getPreview(transactionNumber, audience) {
    const params = {
      name: 'account_opening$preview-document$get',
      params: {
        request: {
          role: 'CLIENT',
          audience: audience
        },
        body: {},
        path: {
          transactionNumber: transactionNumber
        }
      }
    };
    this.wait();

    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((resp: any) => {
      swal.close();
      if (resp.length > 0) {
        this.show_filePreview(resp[0]);
      } else {
        swal.fire({
          title: '',
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: false,
          html: 'Mensaje:<br><b>Ficha no disponible</b><br>',
        });
      }
      this.preview = resp;
    }, error => {
      this.loading = false;
      this.preview = [];
      swal.close();
    }));
  }

  show_filePreview(data) {
    this.show_file(data.identifier);
  }


  wait() {
    swal.fire({
      title: '',
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false,
      html: '<b>' + this.i18n.transform('@i18n-text-please-wait') + '...<b><br><i class="fa fa-spin fa-spinner"></i>',
    });
  }


  setView(data) {

    this.animate_meet = false;
    this.animate_contact = false;
    this.animate_verifi = false;
    this.animate_solic = false;
    this.animate_solic_requirements = false;


    if (data == 0) {

      this.animate_contact = true;

    } else if (data == 1) {

      this.animate_verifi = true;



    } else if (data == 2) {

      this.animate_solic = true;



    } else if (data == 3) {

      this.animate_solic_requirements = true;


    } else if (data == 4) {

      this.animate_meet = true;

    }

  }

  setUrlmeet() {

    this.init_meet = false;
  }


  changes() {
    let html =  document?.getElementById("idHtml");
      html.classList.remove('scroll-none');

    this.buttomModal = false;
    this.change.emit(false);
  }

  getResponsePreview(event) {
    console.log(event);
  }

  show_file(file) {

    file = { identifier: file };
    const archive = {
      identifier: file.identifier,
      name: file.name,
      dateCreated: {
        orderDate: null
      }
    };

    if (file.dateCreated) {
      archive['dateCreated']['orderDate'] = file.dateCreated.modifiedDate;
    } else {
      archive['dateCreated']['orderDate'] = null;
    }

    if (archive.identifier !== null) {
      this._upload.visor_params.name = '';
      this._upload.visor_params.date = '';
      this._upload.visor_params.endpoint = 'archive$downloadFile$get';
      this._upload.visor_params.archive = archive;
      this._upload.visor_params.deleteAfterShow = '';
      if (file['action'] === 'download') {
        this._upload.visor_params.action = 'download';
        this._upload.visor_params.deleteAfterShow = {
          deleteRequirement: file['deleteAfterShow']
        };
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      } if (file['action'] === 'downloadzip') {

        if (this.env.theme.images === 'FACEBANK') {
          this._upload.visor_params.endpoint = 'archive$downloadZipFile$get';
        }

        this._upload.visor_params.action = 'download';
        this._upload.visor_params.deleteAfterShow = {
          deleteRequirement: file['deleteAfterShow']
        };
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      } else {
        this._upload.visor_params.action = '';
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      }
    }
  }



  isLegal = (clientDetail = this.clientDetail)=>{

    return   clientDetail?.personMarketType?.audienceType == 'LEGAL';

   } 

  getUser() {
    this.clientAtteps = [];
    const params = {
      name: 'person$enduser$get',
      params: {
        request: {
          'username': this.client
        },
        path: {},
        body: {}
      }
    };


    this.loading = true;

    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe( async (response: any) => {
      this.clientDetail = response;

      this.localStorage.setItem('clientName',this.clientDetail?.person?.givenName+' '+this.clientDetail?.person?.familyName);
      this.localStorage.setItem('clientUsername', this.clientDetail.identifier);
      this.localStorage.setItem('clientIdentifier',this.isLegal(this.clientDetail)? this.clientDetail?.adminOrganization?.identifier:this.clientDetail?.person?.identifier);
      this.localStorage.setItem('clientaudience', this.clientDetail.personMarketType.audienceType);
  
      let a = this.localStorage.getItem('clientName');
      let b =this.localStorage.getItem('clientUsername');
      let c =this.localStorage.getItem('clientIdentifier');
      let d =this.localStorage.getItem('clientaudience');
     
     
      if(this.isLegal(this.clientDetail)){
        
        this.localStorage.setItem('clientlegalName',  this.clientDetail?.adminOrganization?.legalName );

      }


     
      this.loading = false;
    
      this.getDataInformations();
      this.getIndicatorsProgres();
      }));

  }


  getDocumentClient(type) {
    if (!isNullOrUndefined(this.clientDetail)) {
      if (this.clientDetail.personDocuments) {
        const doc = this.clientDetail.personDocuments.filter((doc) => {
          return doc.alternateName === type;
        });
        if (!isNullOrUndefined(doc) && doc.length > 0) {
          return doc[0].identifier;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  get_data_identity() {

    const params = {
      name: 'person$get_data_identity$get',
      params: {
        request: {
          'username': this.client
        },
        path: {},
        body: {}
      }
    };



    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.dataIdentity = response;
    }));

  }



  getCompleatVerifi() {

    const params = {
      name: 'person$is_complete_identification$get',
      params: {
        request: {
          'username': this.client
        },
        path: {},
        body: {}
      }
    };

    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      this.compleatVerifi = response;

    }));

  }




  verificarClient() {
    let name = this.clientDetail ?.person ?.givenName + ' ' + this.clientDetail ?.person ?.familyName 

  const params = {
      name: 'person$verified_identification$post',
      params: {
        request: {
          'verified':true,
          'username': this.client,
          'requestUser': this._auth.getUsername()
        },
        path: {},
        body: {}
      }
    };

    swal.fire({
      title: '',
      html: this.i18n.transform('Desea Aprobar la verificación de identidad a <b>' + name + '</b>'),
      showCancelButton: false,
      confirmButtonText: this.i18n.transform('@i18n-popup-text-ok'),
      reverseButtons: true
    }).then((result) => {

      this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

        this.optVerification = false;
        this.getUser();

      }));

    }, error => {


    });
  }

  resetVerification() {
    let name = this.clientDetail ?.person ?.givenName + ' ' + this.clientDetail ?.person ?.familyName 

  const params = {
      name: 'person$reset_complete_identification$post',
      params: {
        request: {
          'username': this.client,
          'requestUser': this._auth.getUsername()
        },
        path: {},
        body: {}
      }
    };

    swal.fire({
      title: '',
      html: this.i18n.transform('¿Desea devolver la verificación de identidad a <b>' + name + '</b>?'),
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {

      if (result ?.isConfirmed) {

        this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

          this.optVerification = false;
          this.getUser();


        }));


      }



    }, error => {


    });

  }

  getTransactions() {
    const params = {
      name: 'account$myformalities_paginated$get',
      params: {
        request: {
          page: 0,
          size: 60
        },
        path: {
          username: this.client
        },
        body: {}
      }
    };
    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {


      this.transactions = response.content;
      this.rows = [];

      this.transactionCompleat = null;

      for (const transaction of this.transactions) {

        let row = transaction;
        let jsonTmp =
          {
            "plan": {name: this.i18n.transform(row.plan.name),shortDescription: this.i18n.transform(row.plan.shortDescription)},
            "workflow":  row?.plan?.url,
            "identifier": row.identifier,
            "solicitud": row.transactionNumber,
            "transactionNumber": row.transactionNumber,
            "agencia": row.channelAgency ? row.channelAgency.name : 'Todos',
            "ejecutivo": ((row.salesManagerUser) ? (row.salesManagerUser.name) : ('Todos')),
            "status": ((row.accountStatus) ? (row.accountStatus[0]) : ''),
            "status2": ((row.accountStatus) ? (row.accountStatus[0].userName) : ''),
            "fecha": (row.accountStatus) ? (this.datePipe.transform(row.accountStatus[0].dateOfThing.startDate, 'dd/MM/yyyy hh:mm aa')) : '-',
            "fechaInic": (row) ? (this.datePipe.transform(row.dateOfThing.orderDate, 'dd/MM/yyyy hh:mm aa')) : '-',

            "audience": row?.additionalType
          }
                  
          
          if(row.accountStatus[0].additionalType == 'AC_RECEIVED'){
            
             this.transactionCompleat = row.identifier;
             this.transactionNumberCompleat = row.transactionNumber;

          }
          


        this.rows = [...this.rows, jsonTmp];

       // this.getIndicatorsProgres(this.rows);

        this.getIndicatorsProgresTransactions(this.rows,this.localStorage.getItem('role'),this.localStorage.getItem('audience'));

      }

    }, error => {
    }));
  }

  getIndicatorsProgresTransactions(datas,role,audience) {
  
    let transactions = [];
    let data :any;

      for (const row of   datas ) {
    
          transactions = [...transactions, {transactionNumber:row?.transactionNumber}]
                
      }

     

        data = {
           bankAccountTransactions :transactions
         } ;




  const params = {

    name: 'resume$workflow_progress$post',
    params: {
      request: {
        role: role,
        audience: audience,

      },
      path: {},
      body: data

    }
  };

  this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    if (response) {
      let indicatorsProgresResponse = {};

      for (const row of   response ) {
  
      let transactionNumber =    row?.bankAccountTransaction?.transactionNumber;
         
       let progress :any;
      let preData =row.modules;

      let  progress2 = preData;



       if(progress2?.length > 0) {

        for (const row2 of   progress2 ) {

          progress  = row2?.progress;

          let  maxValue = progress?.maxValue;
          let  minValue = progress?.minValue;
  
          let indicatorsProgres = {};
          indicatorsProgres['name'] = row2?.name;
        
          indicatorsProgres['maxValue'] = maxValue;
          indicatorsProgres['minValue'] = minValue;
          indicatorsProgres['percent'] = minValue > 0 ?   this.round(minValue*100/maxValue):0;
  
          if(!this.indicatorsProgresRsp[transactionNumber]){
            this.indicatorsProgresRsp[transactionNumber] = {};
  
          }

          if(!row2?.alternateName){

            this.indicatorsProgresRsp[transactionNumber]['DOC_SUPPORT']  = indicatorsProgres;
  

          }else{


             this.indicatorsProgresRsp[transactionNumber][row2?.alternateName]  = indicatorsProgres;



          }
          
  

     
        }
      

  }

      }
      ;

      }


  }, error => {


  }));

}


ifIndicatorProgresItem(transactionNumber,item){

  return (this.indicatorsProgresRsp[transactionNumber] && this.indicatorsProgresRsp[transactionNumber][item])?true:false;
}




  get_dataAndSelfi() {

    const params = {
      name: 'person$selfie$get',
      params: {
        request: {
          'username': this.client
        },
        path: {},
        body: {}
      }
    };



    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      this.dataAndSelfi = response;
      this.filePreview = 'data:image/jpg' + ';base64,' + response?.description;

    }));


  }


  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  



round(value) {

  if(value){
    return Math.round(value);

  }
  return 0;

}
redirectMeet(){
     // Abrir nuevo tab
     var win = window.open('https://meet.jit.si/meet-'+this.env.theme.images+'-'+this.clientDetail?.identifier, '_blank');
     // Cambiar el foco al nuevo tab (punto opcional)
     win.focus();

}



setMeetData(e) {
  if(e){
    this.init_meet = false;
    this.userMeet = null;
    setTimeout(() => {
    this.setClient(e);
    this.setSelect(true);
   }, 500);


  }else{
    this.init_meet = false;
    this.userMeet = null;
  }
    
}
setClientData(e) {
  if(e){
    this.init_clientData = false;
    this.userClientData = null;
    this.setClient(e);
    this.setSelect(true);

  }else{
    this.init_clientData = false;
    this.userClientData = null;
  }
    
}



setVerificationData(e){
  if(e){
    this.init_verif = false;
    this.userverif = null;
    setTimeout(() => {
    this.setClient(e);
    this.setSelect(true);
   }, 500);


  }else{
    this.init_verif = false;
    this.userverif = null;
  }
    

}


setOpenMeet(){

  this.changes();
  setTimeout(() => {
    this.userMeet = this.clientDetail?.identifier;
    this.init_meet= true;
    this.init_verif= false;

  }, 500);
}


setDataClient(){

  const queryParams = {
    role: 'CLIENT',
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('clientaudience'),
    id : this.client
    
  };


  //let url =  'console/client-data?role='+queryParams.role+'&profile='+queryParams?.profile+'&audience='+queryParams?.audience+'&id='+this.client+"&wo=true";
  //window.open(url, '_blank');
  this.mdActve = false;

    this.router.navigate(['console/client-data'], { queryParams: queryParams });



}




setOpenVerif(event:any = false) {
  
   if(event){
        event.stopPropagation();
    }

   this.changes();
   
  setTimeout(() => {
    this.userverif = this.clientDetail?.identifier;
    this.init_verif= true;
    this.init_meet= false;
    this.init_clientData= false;


  }, 500);
}



redirectNewTransaction() {

  this.subscription_query_params =  this.route.queryParams.subscribe(queryParams => {

 //   console.log(this.route.snapshot.url); // array of states
 

 
  let temp = {
    url : 'private/ACCOUNT_OPENING/stages/LIST_CLIENTS/steps/LIST_CLIENTS',
    queryParams :queryParams
  }
  this.akSectionService.setBackAndUrl(temp);

  this.changes();


  let client =this.clientDetail;
  let aux;

    let idPerson = '';
    if (client.adminOrganization) {
      idPerson = client.adminOrganization.identifier;
    } else {
      idPerson = client.person.identifier;
    }


    if (client.adminOrganization) {
      aux = Object.assign(
        {
          audience: 'LEGAL',
          viewUsername: client.identifier,
          viewIdentifierContact: client.adminOrganization.identifier
        }
        , queryParams);
    } else {
      aux = Object.assign(
        {
          audience: 'NATURAL',
          viewUsername: client.identifier,
          viewIdentifierContact: client.person.identifier
        },
        queryParams);
    }

    this.router.navigateByUrl(
      this.router.createUrlTree([environment.staticLinks.viewClient],
        {
          queryParams: aux
        }
      )
    );

});

}

toDateString(data){

  return  this._properties.toDateString(data);

}



ngOnDestroy() {
  this.mdActve = false;
  let html =  document?.getElementById("idHtml");
  html?.classList?.remove('scroll-none');
  if (this.subscription_query_params) {
    this.subscription_query_params.unsubscribe();
  }

  if (this.dataInfoClientLASubscription) {
    this.dataInfoClientLASubscription.unsubscribe();
  }


  if (this.listWfSubscription) {
    this.listWfSubscription.unsubscribe();
  }

  if(this.subscriptions) {
    this.subscriptions.forEach(ele=> ele.unsubscribe());
  }
  
}


getDataInformations = async () => {

  const params = {

    name:   this.isLegal(this.clientDetail)? 'briefcase$business_person$get' : 'briefcase$person$get',
    params: {
      request: {
        'id': this.localStorage.getItem('clientIdentifier')
      },
      path: {},
      body: {}
    }
  };


  try {
    const response:any = await this._httpService.executeRequest(params.name, params.params, true).toPromise()

    if(response){
      this.dataInformation = response;


      if (this.dataInformation?.dateRegisteredCvv) {


        this.registerCVV = {
          extraDataCvv: this.dataInformation?.extraDataCvv && JSON.parse(this.dataInformation?.extraDataCvv),
          userRegisteredCvv: this.dataInformation?.userRegisteredCvv,
          dateRegisteredCvv: this.dataInformation?.dateRegisteredCvv

        }

      } 




      this.compleatVerifi = response?.completeIdentification ;
      this.verifiedIdentification = response?.verifiedIdentification ;
      

        
        if(this.verificationLA ){
          
    
          let identity = `${this.dataInformation?.identity ||this.dataInformation?.taxID}`;

            this.getDataInfoClientLA(identity);
        }

        if(this.verificationAKB ){
          //let ci = `V${'13132132'}`
          let ci = `${this.dataInformation?.identity}`;
          this.loadingdataInfoClientAKB = true;
          this.getdataInfoClientAKBError =null;
          this.dataInfoClientAKB = null;
            this.getDataInfoClientAKB(ci);

        }




    }
  } catch (error) {
     throw new Error(error);

  }


  

}



getDataInfoClientLA = async (identity) => {
  
  this.loadingdataInfoClientLA = true;
  this.getdataInfoClientLAError =null;
  this.dataInfoClientLA = null;

  const params = {
    name: 'core_integration$consultarClienteLA$get',
    params: {
      request: {
        'numeroDocumento': identity
      },
      path: {},
      body: {}
    }
  };

  this.dataInformation;

  try {



  if( this.dataInformation?.externalCode ){

    this.dataInfoClientLA = {
      externalCode:this.dataInformation.externalCode,
      identity:this.dataInformation.identity,
      givenName:this.dataInformation.givenName,
      familyName:this.dataInformation.familyName,
      telephone:this.dataInformation.telephone,
      email:this.dataInformation.email,
      contactPoint:{
        telephone:this.dataInformation.otherPhone
      },
      dateOfThing:{

        startDate:this.dataInformation.externalDate
        
      },

    };

    }else{

      //TODO:se comento la consulta automatica del cliente en la

/*
      let response  = await this._httpService.executeRequest(params.name, params.params, true).toPromise();

      if(Object.keys(response)?.length > 0){
        this.dataInfoClientLA = response;

    }
*/
    
  }



      this.loadingdataInfoClientLA = false;
    
  
  } catch (error) {
      
    this.getdataInfoClientLAError =true;
    this.loadingdataInfoClientLA = false;


  }


  
}

getExistClientLA = async ()=>{

  let identity = `${this.dataInformation?.identity ||this.dataInformation?.taxID}`;

  this.loadingdataInfoClientLA = true;
  this.getdataInfoClientLAError =null;
  this.dataInfoClientLA = null;

  const params = {
    name: 'core_integration$consultarClienteLA$get',
    params: {
      request: {
        'numeroDocumento': identity
      },
      path: {},
      body: {}
    }
  };
     
try {
  
  this.isGetServceLA = true;
  
  let response  = await this._httpService.executeRequest(params.name, params.params, true).toPromise();

  if(Object.keys(response)?.length > 0){
    this.dataInfoClientLA = response;



    try {
      const params = {
        name: 'core_integration$guardarCodigoLA$put',
        params: {
          request: {
            'idPerson': this.clientDetail.person.identifier,
            'codigoLA': this.dataInfoClientLA?.externalCode,
            "externalDate":  this.datePipe.transform(this.dataInfoClientLA?.dateOfThing?.startDate, 'dd-MM-yyyy'),
  
          },
          path: {},
          body: {}
        }
      };
  
      const emintResp ={
        externalCode:  this.dataInfoClientLA?.externalCode,
        username:  this.client
  
      };

      await this._httpService.executeRequest(params.name, params.params, true).toPromise();

     this.la.emit(emintResp); 


    } catch (error) {

      console.error("core_integration$guardarCodigoLA$put",error);
      
      
    }
   

  }

  this.loadingdataInfoClientLA = false;

} catch (error) {

  this.getdataInfoClientLAError =true;
  this.loadingdataInfoClientLA = false;
  
}
  
}

getDataInfoClientAKB = async (identity) => {
  
  const params = {
    name: 'core_integration$consultarClienteAKB$get',
    params: {
      request: {
        'numeroDocumento': identity
      },
      path: {},
      body: {}
    }
  };

  try {
    
    let response  = await this._httpService.executeRequest(params.name, params.params, true).toPromise();

    if(Object.keys(response)?.length > 0){
      this.dataInfoClientAKB = response;
    }


      this.loadingdataInfoClientAKB = false;
    
  
  } catch (error) {
      
    this.getdataInfoClientAKBError =true;
    this.loadingdataInfoClientAKB = false;


  }

}








setNewTransaction(number) {
if(number){
  this.setTransactionView(number);
  this.getTransactions();
}
  this.newTransaction = false;  
}


getIndicatorsProgres() {
  let data :any;  
    data = {
      "workflow":'CLIENT_DATA',
      "personId": this.localStorage.getItem('clientIdentifier')
  }
 
    
 
  const params = {

    name: 'resume$workflow_progress$post',
    params: {
      request: {
        role: 'CLIENT',
        audience: this.localStorage.getItem('clientaudience'),

      },
      path: {},
      body: data

    }
  };

  this.indicatorsLoading = true;
  let indicatorsProgres = {};
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    if (response) {

      let progress;
      let preData =response[0]?.modules;

       progress = response[0]?.modules[0]?.progress;
    

       let  maxValue = progress?.maxValue;
       let  minValue = progress?.minValue;

       indicatorsProgres['maxValue'] = maxValue;
       indicatorsProgres['minValue'] = minValue;
       indicatorsProgres['percent'] = maxValue > 0 ?   this.round(minValue*100/maxValue):0;
        
       this.indicatorsProgres = indicatorsProgres;
        this.indicatorsLoading = false;
      }


  }, error => {

    this.indicatorsLoading = false;

  });

}



crearPersonaNaturalLA = () => {
  debugger;
    let idPerson = this.clientDetail?.person?.identifier;
    if(this.clientDetail.adminOrganization) {
      idPerson = this.clientDetail.adminOrganization.identifier;
    }

  
    const params = {
  
      name: 'core_integration$crearPersonaLA$post',
      params: {
        request: {
         "idAccount":this.transactionCompleat,
         "idPerson": idPerson,
         "audience":this.clientDetail?.personMarketType?.audienceType
        },
        path: {},
        body: {}
      }
    };
  
    this.loadingdataInfoClientLA = true;
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      if (response) {
        let identity = `${this.dataInformation?.identity ||this.dataInformation?.taxID}`;
        this.getExistClientLA();
      } else {
        this.loadingdataInfoClientLA = false;
      }
    }, error => {
      let error_prov = '';
      if(this.is_json(error)) {
        error_prov = JSON.stringify(error);
      }
      if(error_prov.includes('PersonServiceProxy#senNotification')) {
        this.getdataInfoClientLAError = true;
        this.loadingdataInfoClientLA = false;
      } else {
        this._error.show(error);
        this.loadingdataInfoClientLA = false;
      }  
    });
  
  }


    crearPersonaNaturalAKB = () => {

      const params = {

        name: 'core_integration$crearPersonaNaturalAKB$post',
        params: {
          request: {
          "transactionNumber":this.transactionNumberCompleat
          },
          path: {},
          body: {}
        }
      };

      this.loadingdataInfoClientLA = true;
      this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        if (response) {

          let ci = `${this.dataInformation?.identity}`
          this.getDataInfoClientAKB(ci);
          }
          this.loadingdataInfoClientAKB = false;


      }, error => {
       console.log(error);
       
      });

    }

  is_json = (str) => {
    try {
        JSON.stringify(str);
    } catch (e) {
        return false;
    }
    return true;
  }
  

  gotoWhatsapp(num) : void {
    window.open(" https://wa.me/"+num?.replace('+', ''), "_blank");
  }


  setViewsNote(userName){
    this._wfService.setShowNoteForClient(userName);

  }

  setViewsCVV(userName){
    this._wfService.setShowCVVForClient(userName);

  }


setEditEmail = (param) =>{
    this.updateEmail = true;
    this.newEmail = param;
  }

  validateEmail(ollEmail,email){
    this.errorEmail = null;
    //^([a-zA-Z0-9_\\.\\-])+\\@(([a-zA-Z0-9\\-])+\\.)+([a-zA-Z0-9]{2,4})+$
    // const re = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");
    const re = new RegExp("^([a-zA-Z0-9_\\.\\-])+\\@(([a-zA-Z0-9\\-])+\\.)+([a-zA-Z0-9]{2,4})+$");

    if (!email || email?.trim() == "") {
      this.errorEmail = "Email requerido";
      
    }else if (!re.test(email)) {
      this.errorEmail = "Email incorrecto";
      
    }else{
      this.getExitEmail(email);
    }
    
  }
  


setEditPhone = (param) =>{
  this.updatePhone = true;
  this.newPhone = param;
}

validatePhone(ollphone,phone){
  this.errorPhone = null;
  const re = new RegExp("^[\+]?[0-9]{1,3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{6,6}$");

  if (!phone || phone?.trim() == "") {
    this.errorPhone = "Teléfono requerido";
    
  }else if (!re.test(phone)) {
    this.errorPhone = "Teléfono incorrecto";
    
  }else{
    if(this.env?.theme?.images=="MC" || this.env?.theme?.images=="KOI" || this.env?.theme?.images=="RENDIVALORES") {
      if(this.clientDetail?.personMarketType?.audienceType!=="LEGAL") {
        this.getExitPhone(phone);
      }
    } else {
      this.getExitPhone(phone);
    }
  }
  
}

  
getExitPhone = async (phone) => {
    this.loadingEmail = true;

  const params = {
    name: 'person$exists_phone$get',
    params: {
      request: {
        phone
      },
      path: {},
      body: {}
    }
  };

  try {
    
    let response  = await this._httpService.executeRequest(params.name, params.params, true).toPromise();


    if(response){
      
      this.errorPhone = "Teléfono existente";

    }

    this.loadingPhone = false;
      
  } catch (error) {
      
     this.loadingPhone = false;


  }

}

getExitEmail = async (email) => {
  this.loadingEmail = true;
  email = email.toLowerCase()
const params = {
  name: 'person$exists_email$get',
  params: {
    request: {
      email
    },
    path: {},
    body: {}
  }
};

try {
  
  let response  = await this._httpService.executeRequest(params.name, params.params, true).toPromise();


  if(response){
    
    this.errorEmail = "Email existente";

  }

  this.loadingEmail = false;
    
} catch (error) {
    
   this.loadingEmail = false;


}

}


 updateEmailService = async ()   =>  {
  

  const params = {
    name: 'person$update_client_email$put',
    params: {
      request: {
        idPerson: this.clientDetail.person.identifier

      },
      path: {},
      body: {
        email: this.newEmail.toLowerCase(),

      }
    }
  };


  try {
    let response:any = await   this._httpService.executeRequest(params.name, params.params, true).toPromise();
        
    this.setNoteNewEmail(this.clientDetail?.person?.email, this.newEmail)

    this.getUser();

     let emailMsg = "Email actualizado."
     this.loading = false;
     swal.fire({
      title: ' ',
      html:  emailMsg,
    });

    this.updateEmail= null;


  
  } catch (error) {
    this._error.show(error,' ',error?.error?.message);
  }

}



updatePhoneService = async ()   =>  {
  const params = {
    name: 'person$update_client_phone$put',
    params: {
      request: {
        idPerson: this.clientDetail.person.identifier

      },
      path: {},
      body: {
        otherPhone: `${this.newPhone}`,

      }
    }
  };


  try {
    let response:any = await   this._httpService.executeRequest(params.name, params.params, true).toPromise();
          
    this.setNoteNewPhone(this.clientDetail?.person?.otherPhone, this.newPhone)

    this.getUser();


     let phoneMsg = "Teléfono actualizado."
     this.loading = false;
     swal.fire({
      title: ' ',
      html:  phoneMsg,
    });
    
    this.updatePhone= null;

  } catch (error) {
    this.loading = false;
    this._error.show(error,' ',error?.error?.message);
  }

}


submitEmail(){

  swal.fire({
    title: '',
    html: `¿Desea <b> modificar </b> el email del cliente?`,
    showCancelButton: true,
    confirmButtonText: 'Si'

  }).then(async (result) => {
    if (result.value) {
      this.updateEmailService();

    }

  });

  
}

submitPhone(){

  swal.fire({
    title: '',
    html: `¿Desea <b> modificar </b> el teléfono del cliente?`,
    showCancelButton: true,
    confirmButtonText: 'Si'

  }).then(async (result) => {
    if (result.value) {
      this.updatePhoneService();

    }

  });

  
}


setNoteNewEmail = async (ollEmail,email) =>{
  const client = this.clientDetail;
      const env: any = environment;
     
    const note = 
    this.i18n.transform(`Se ha cambiado el email  al cliente, ${ollEmail} por ${email}`);

    const nameClient = client?.personMarketType?.audienceType == 'NATURAL'?
    `${client?.person?.givenName} ${client?.person?.familyName}`:
    `${client?.adminOrganization?.legalName}`
  
       const data =  {
        "environment_code": env?.code || env?.theme?.images,
         "type": "CLIENT",
         "show_note": "TRUE",
         "note_extra_data_json": "",
         "client": client?.identifier,
         "full_name":  nameClient,
         "ejecutive":this._auth.getUsername(),
         "ejecutive_update": "",
         "title": '',
         "subtitle": 'Cambio de Email',
         "status_view": "NEW_CLIENT",
         "extra_data": "",
         "note": note,
       };
        await this._notes.saveNote(data);
     
     };



setNoteNewPhone = async (ollPhone,phone) =>{
  const client = this.clientDetail;
      const env: any = environment;
     
    const note = 
    this.i18n.transform(`Se ha cambiado el  teléfono  al cliente, ${ollPhone} por ${phone}s`);

    const nameClient = client?.personMarketType?.audienceType == 'NATURAL'?
    `${client?.person?.givenName} ${client?.person?.familyName}`:
    `${client?.adminOrganization?.legalName}`
  
       const data =  {
        "environment_code": env?.code || env?.theme?.images,
         "type": "CLIENT",
         "show_note": "TRUE",
         "note_extra_data_json": "",
         "client": client?.identifier,
         "full_name":  nameClient,
         "ejecutive":this._auth.getUsername(),
         "ejecutive_update": "",
         "title": '',
         "subtitle": 'Cambio de Teléfono',
         "status_view": "NEW_CLIENT",
         "extra_data": "",
         "note": note,
       };
        await this._notes.saveNote(data);
     
     };

}

