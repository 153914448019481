<app-template>
    <ng-template app-content componentName="mainContentModalApp">

        <div class="capa-menu-2" *ngIf="buttomModal" (click)="buttomModal =!buttomModal"> </div>

        <div [ngClass]="{'d-none':animate_init_bottom,'animate__slideInUp':buttomModal,animate__slideOutDown:!buttomModal } " class="  animate__animated animate__faster  ak-modal-botoom p-2 ">
            <div class="body pb-4 pt-2 " style="overflow:auto;">
                <div>
                    <div class="container text-center">
                        <div class=" w-100 text-center ">
                            <div class="DomainUserAvatar-avatar Avatar--color-J AvatarPhoto AvatarPhoto--coord" role="img">
                                <i class="fas fa-layer-group"></i>
                            </div>
                            <div class="text-secondary mt-2 font-weight-bold fz-16">{{selectedRow?.wpname}} </div>

                        </div>
                        <div class="row">

                            <div class="col-12 col-md-6 offset-md-3">
                                <hr>
                                <div class=" text-left">
                                    <div class="p-2 fz-14 d-flex bd-highlight  list-flex flex-wrap text-left size-caja-dopd">

                                        <div class="w-100">



                                            <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                <div class="w-50   text-secondary  text-capitalize">
                                                    clave
                                                </div>
                                                <div class="w-50  text-right">
                                                    {{selectedRow?.clave}}
                                                </div>
                                            </div>

                                            <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                <div class="w-50  text-secondary text-capitalize">
                                                    Vista
                                                </div>
                                                <div class="w-50  text-right">
                                                    {{selectedRow?.vista}}
                                                </div>
                                            </div>

                                            <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                <div class="w-50   text-secondary  text-capitalize">
                                                    Agencia
                                                </div>
                                                <div class="w-50  text-right">
                                                    {{selectedRow?.agencia}}
                                                </div>
                                            </div>

                                            <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                <div class="w-50   text-secondary  text-capitalize">
                                                    Audiencia
                                                </div>
                                                <div class="w-50   text-right">
                                                    {{selectedRow?.tipo}}
                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                </div>

                            </div>

                        </div>



                    </div>
                </div>
            </div>
        </div>


    </ng-template>
</app-template>

<app-template>
    <ng-template app-content componentName="mainContentApp">
        <div class="full-modal-2 compleat-modal" style="height: 100vh!important;" *ngIf="showModal">
            <div class="header active-cursor" style="text-align:right">
                <div class="float-right" (click)="showModal=!showModal">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i>
                </div>
                <div class="pr-2 pl-2 pb-2 float-left">
                    <div class=" pt-1">
                        <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>
                    </div>
                </div>
            </div>
            <div class="body " style="overflooverfloww:auto">
                <div style="overflow-y: scroll;max-height:95vh!important;">
                    <div class="container box_scroll">

                        <div class="jumbotron jumbotron-fluid p-3 ">

                            <div class="d-flex bd-highlight">

                                <div>
                                    <h5 class="font-weight-normal">
                                        <i class="fas fa-layer-group pr-1"></i> {{wsMetric}}
                                    </h5>
                                </div>

                                <div class="ml-auto">
                                    <i class="fas fa-ellipsis-v "></i>
                                </div>
                            </div>

                        </div>


                        <div class="font-weight-bold fz-16 mb-2"> Solicitudes</div>
                        <div class="card mb-2  active-cursor w-50">
                            <div class="card-body p-3 ">
                                <div class="d-flex bd-highlight  flex-wrap ">
                                    <div class="w-20">
                                        Nuevas
                                        <b>100</b>
                                    </div>
                                    <div class="w-80 pl-3 mt-1">
                                        <div class="progress mb-3">
                                            <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">10%</div>
                                        </div>
                                    </div>

                                    <div class="w-20">
                                        Devueltas
                                        <b>10</b>
                                    </div>
                                    <div class="w-80 pl-3 mt-1">
                                        <div class="progress mb-3">
                                            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                                        </div>
                                    </div>


                                    <div class="w-20">
                                        Recibidas
                                        <b>300</b>
                                    </div>

                                    <div class="w-80 pl-3 mt-1">
                                        <div class="progress mb-3">
                                            <div class="progress-bar progress-bar-striped bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">50%</div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                        <div class="d-flex bd-highlight  flex-wrap ">
                            <div class="w-50">
                                <div class="card mb-2  active-cursor  w-98">
                                    <div class="card-body p-3 ">
                                        <div class="d-flex bd-highlight  flex-wrap ">
                                            <div class="w-100 font-weight-bold mb-3">
                                                Solicitudes diarias

                                            </div>

                                            <div class="w-33  font-weight-bold fz-14 text-center">
                                                Hoy
                                            </div>

                                            <div class="w-33 font-weight-bold fz-14 text-center">
                                                Ayer
                                            </div>

                                            <div class="w-33 font-weight-bold fz-14 text-center">
                                                Ult. Sem.
                                            </div>

                                            <div class="w-33 mt-3  font-weight-bold text-center">
                                                <h4 class="font-weight-bold border-1 border-secondary border-right">8</h4>
                                            </div>

                                            <div class="w-33 mt-3  font-weight-bold text-center border-1 border-secondary border-right">
                                                <h4 class="font-weight-bold ">20</h4>
                                            </div>

                                            <div class="w-33 mt-3  font-weight-bold text-center">
                                                <h4 class="font-weight-bold ">40</h4>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="w-50">
                                <div class="card mb-2  active-cursor w-98 float-md-right ">
                                    <div class="card-body p-3  ">
                                        <div class="d-flex bd-highlight  flex-wrap ">
                                            <div class="w-100 font-weight-bold mb-3">
                                                Solicitudes mensuales

                                            </div>

                                            <div class="w-33  font-weight-bold fz-14 text-center">
                                                Este mes
                                            </div>

                                            <div class="w-33 font-weight-bold fz-14 text-center">
                                                Mes Anterior
                                            </div>

                                            <div class="w-33 font-weight-bold fz-14 text-center">
                                                YTD
                                            </div>

                                            <div class="w-33 mt-3  font-weight-bold text-center">
                                                <h4 class="font-weight-bold border-1 border-secondary border-right">60</h4>
                                            </div>

                                            <div class="w-33 mt-3  font-weight-bold text-center border-1 border-secondary border-right">
                                                <h4 class="font-weight-bold ">80</h4>
                                            </div>

                                            <div class="w-33 mt-3  font-weight-bold text-center">
                                                <h4 class="font-weight-bold ">300</h4>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="d-flex bd-highlight  flex-wrap mt-3">
                            <div class="w-31-3 ">
                                <div class="card mb-2 w-100 active-cursor ">
                                    <div class="card-body p-3 ">
                                        <div class="d-flex bd-highlight  flex-wrap ">
                                            <div class="w-100 font-weight-bold mb-2">
                                                Tiempo promedio solicitud
                                            </div>
                                            <div>
                                                <h3 class="font-weight-bold number-car">8Hrs</h3>
                                            </div>

                                            <div class="w-100 mb-1">
                                                Ayer
                                                <span class="font-weight-bold">2 días</span>

                                            </div>
                                            <div class="w-100 ">
                                                Esta semana
                                                <span class="font-weight-bold">2.5 días</span>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-31-3">
                                <div class="card mb-2 w-100 active-cursor float-md-right">
                                    <div class="card-body p-3 ">
                                        <div class="d-flex bd-highlight  flex-wrap ">
                                            <div class="w-100 font-weight-bold mb-2">
                                                Tiempor promedio datos
                                            </div>
                                            <div>
                                                <h3 class="font-weight-bold number-car">8Hrs</h3>
                                            </div>

                                            <div class="w-100 mb-1">
                                                xxxx
                                                <span class="font-weight-bold">xxx</span>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-31-3">
                                <div class="card mb-2 w-100 active-cursor float-md-right">
                                    <div class="card-body p-3 ">
                                        <div class="d-flex bd-highlight  flex-wrap ">
                                            <div class="w-100 font-weight-bold mb-2">
                                                Tiempo promedio recaudos
                                            </div>
                                            <div>
                                                <h3 class="font-weight-bold number-car">8Hrs</h3>
                                            </div>

                                            <div class="w-100 mb-1">
                                                xxxx
                                                <span class="font-weight-bold">xxx</span>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--  <div class="w-100  mb-2" style="height: 150px" #chartsContent>                                                                                           </ngx-charts-bar-horizontal-stacked>
                                                                                                         </div>-->
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</app-template>
<app-client-detail *ngIf="clientDetail" [selected]="clientDetail" [username]="clientSelected" (change)="setClientDetail($event)"></app-client-detail>

<div class="mt-1  animated fadeIn">

    <div class="jumbotron jumbotron-fluid p-3 mb-2 ">

        <div class="d-flex bd-highlight">

            <div>
                <h5 class="font-weight-normal">
                    <i class="fas fa-layer-group pr-1"></i> Workspaces
                </h5>
                <!--    <div class=" text-secondary  pb-1">{{((queue && queueName != 'Q_ALL_OP_M')?(queue.name):('' | i18n))}}</div>
                                  <div class=" ">{{rows?.length}} Solicitudes</div>-->
            </div>

            <div class="ml-auto">
                <i class="fas fa-ellipsis-v "></i>
            </div>
        </div>

    </div>



    <div class="w-100 mb-5">
        <i *ngIf="!enableAllWksDropdowns" class="active-cursor float-right  fas  fa-compress text-dark" (click)="setWksDropdowns()"></i>
        <i *ngIf="enableAllWksDropdowns" class="active-cursor float-right fas fa-compress-arrows-alt text-dark" (click)="setWksDropdowns()"></i>

    </div>
    <div class="w-100 ">
        <div class="row mb-3 ">
            <div class="col-12 text-primary col-md-6">
                <div class="d-inline-flex w-100">
                    <input class="form-control active-cursor padding-filter-searh-input" type="text" placeholder='Workspace, Agencia, Audiencia, Producto' (keyup)="updateFilter ($event.target.value)" />
                    <i class="fas fa-search icon-filter-searh"></i>

                </div>

            </div>
        </div>

        <div class="mt-3 w-100">

            <div class=" ">
                <i class="fas fa-redo text-secondary"></i>
                <span class="text-secondary float-right ">
                     {{rows?.length}}
                </span>
            </div>

        </div>
        <hr>

        <div *ngIf="!loading_table">

            <ng-container *ngFor="let row of rows ; let i = index">
                <div class="card mt-4 ak-shadow-box" *ngIf="i < 100" style=" min-height: 100px;">
                    <div class="card-body p-3 fz-14 ">
                        <div class="d-flex bd-highlight  flex-wrap ">
                            <!--<div class="w-10 d-block d-md-none  ">
                                                    <i class="fas fa-align-left pr-2" style="font-size: 18px;"></i>

                                            </div>-->
                            <div class="w-80 ">




                                <div class="elipsse">

                                    <!--   <i class="fas fa-align-left pr-2 d-none d-md-inline" ></i> -->
                                    <span class="font-weight-bold active-cursor text-capitalize" style="font-size: 18px;" (click)="selectedRow = row; togle_botton_window()">{{row.wpname}}</span>

                                </div>


                                <div class="scrollmenu text-capitalize mb-1 mt-1">

                                    <ul class=" pl-0 mb-0 fz-14 text-secondary ">
                                        <li class="d-inline" *ngIf="row.agencia != ''">{{row.agencia}}</li>
                                        <li class="d-inline" *ngIf="row.tipo != ''">, {{row.tipo}}</li>
                                        <li class="d-inline" *ngIf="row.product != ''">, {{row.product}}</li>
                                        <li class="d-inline" *ngIf="row.plan != ''">, {{row.plan}} </li>
                                    </ul>
                                </div>

                                <div class="mt-3 text-primary  active-cursor fz-16" (click)="openNew(row.identifier)">
                                    Entrar al workspace
                                    <i class=" fas fa-chevron-right "></i>
                                </div>


                                <!--   <div  class=" text-capitalize mt-1">Actualizacion de Expeniente</div>  -->


                            </div>

                            <div class="bd-highlight ml-auto  text-right">
                                <div>
                                    <i *ngIf="!wksDropdowns[row?.identifier] || !(i == 0)" class="active-cursor mr-2  fas  fa-compress text-secondary" (click)="wksDropdowns[row?.identifier] = !wksDropdowns[row?.identifier]"></i>
                                    <i *ngIf="wksDropdowns[row?.identifier] || i == 0" class="active-cursor mr-2  fas fa-compress-arrows-alt text-secondary" (click)="wksDropdowns[row?.identifier] = !wksDropdowns[row?.identifier]"></i>


                                    <i class="far fa-star mr-2 " style="color: yellow"></i>
                                    <i class="fas fa-ellipsis-v "></i>
                                </div>



                            </div>



                        </div>
                        <div class="d-flex bd-highlight  flex-wrap  " *ngIf="wksDropdowns[row?.identifier] || i == 0">
                            <div class="w-100">
                                <hr>
                            </div>

                            <!--
                            <div class=" w-100 ">

                                <div class=" mb-1 mt-2">
                                    <div class="font-weight-bold mb-2 active-cursor">
                                        <i class="fas  fa-user mr-2"></i> Registros
                                    </div>

                                    <ul class=" pl-0 mb-0  ">
                                        <li class="d-inline">
                                            <div class="item-rd  fz-14  active-cursor">Sin verificar email
                                                <span class="font-weight-bold">122 (10%)</span>
                                            </div>
                                            <div class="item-rd fz-14     active-cursor">Sin solicitud
                                                <span class="font-weight-bold">10 (3%)</span>
                                            </div>

                                        </li>
                                    </ul>

                                    <div class=" ml-2 fz-14 text-muted mt-1">
                                        <span class="font-weight-normal ">Ult. registro 13/01/2022 15:14</span>
                                    </div>
                                </div>

                            </div>
-->
                            <div class=" w-100 ">

                                <div class=" mb-1 mt-2">
                                    <div class="font-weight-bold mb-2 active-cursor">
                                        <i class="fas fa-chart-line mr-2"></i> Colas
                                        <!-- <i class="ml-2 fas fa-chevron-right text-primary"></i> -->
                                    </div>

                                    <ul class=" pl-0 mb-0  ">
                                        <li class="d-inline">
                                            <div (click)="openNew(row.identifier,responseType[row?.clave]['PI_IN_PROCESS']?.url)" class="item-rd  fz-14  active-cursor">En Proceso
                                                <ng-container *ngIf="!isNullOrUndefined(responseType[row?.clave])">
                                                    <span class="font-weight-bold">{{responseType[row?.clave]['PI_IN_PROCESS']?.value}}</span>
                                                </ng-container>
                                            </div>
                                            <div (click)="openNew(row.identifier,responseType[row?.clave]['AC_INCOMPLETE']?.url)" class="item-rd fz-14     active-cursor">Devueltos
                                                <ng-container *ngIf="!isNullOrUndefined(responseType[row?.clave])">
                                                    <span class="font-weight-bold">{{responseType[row?.clave]["AC_INCOMPLETE"]?.value}}</span>
                                                </ng-container>
                                            </div>

                                            <!-- <div (click)="openNew(row.identifier,responseType[row?.clave]['EXECUTIVE_REVIEW']?.url)" class="item-rd item-rd-2  fz-14   active-cursor">Negocio
                                                <span class="font-weight-bold">{{responseType[row?.clave]["EXECUTIVE_REVIEW"]?.value}}</span>
                                            </div> -->
                                            
                                            <ng-container *ngIf="!isNullOrUndefined(responseType[row?.clave])">

                                            <div (click)="openNew(row.identifier,responseType[row?.clave]['AC_PENDING']?.url)" class="item-rd item-rd-2  fz-14   active-cursor">Cumplimiento
                                                <span class="font-weight-bold">{{responseType[row?.clave]["AC_PENDING"]?.value}}</span>
                                            </div>
                                            </ng-container>

                                        </li>
                                    </ul>
                                </div>

                            </div>





                            <!--(click)="wsMetric = row?.wpname;showModal= true"-->

                            <div class="w-100 pl-0 mt-4">
                                <div class="active-cursor">
                                    <i class="fas fa-chart-pie mr-2 "></i>
                                    <span class="font-weight-bold">Estadísticas </span>
                                    <!-- <i class="ml-2 fas fa-chevron-right text-primary"></i>-->

                                </div>



                                <ul class="scrollmenu pl-0 mt-3">
                                    <li class="d-inline">
                                        <div class="card mb-2 boxstatus active-cursor " (click)="openNew(row.identifier,responseType[row?.clave]['AC_RECEIVED'].url)">
                                            <div class="card-body p-3 ">
                                                <div class="d-flex bd-highlight  flex-wrap ">
                                                    <div class="w-100 font-weight-bold mb-2 ">
                                                        Aprobadas
                                                    </div>
                                                    <div>
                                                        <ng-container *ngIf="!isNullOrUndefined(responseType[row?.clave])">

                                                        <h3 class="text-success font-weight-bold number-car">{{ responseType[row?.clave]["AC_RECEIVED"].value }}</h3>
                                                        </ng-container>
                                                    </div>

                                                    <div class="w-100 mb-1">
                                                        Tasa de conversión
                                                        <ng-container *ngIf="!isNullOrUndefined(responseType[row?.clave])">

                                                        <span class="font-weight-bold">{{ (responseType[row?.clave]["AC_RECEIVED"]?.value*100)/responseType[row?.clave]["AC_RECEIVED"]?.maxValue |  number:'1.0-1' }}%</span>
                                                        </ng-container>
                                                    </div>
                                                    <div class="w-100 ">
                                                        Total Solicitudes
                                                        <ng-container *ngIf="!isNullOrUndefined(responseType[row?.clave])">

                                                        <span class="font-weight-bold">{{responseType[row?.clave]["AC_RECEIVED"]?.maxValue}}</span>
                                                        </ng-container>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div class="card ml-3 mb-2 boxstatus active-cursor " (click)="openNew(row.identifier,responseType[row?.clave]['AC_REJECTED'].url)">



                                            <ng-container *ngIf="!isNullOrUndefined(responseType[row?.clave])">

                                            <div class="card-body p-3 ">
                                                <div class="d-flex bd-highlight  flex-wrap ">
                                                    <div class="w-100 font-weight-bold mb-2 ">
                                                        Rechazadas
                                                    </div>
                                                    <div>
                                                        <h3 class="font-weight-bold number-car text-secondary">{{ responseType[row?.clave]["AC_REJECTED"].value }}</h3>
                                                    </div>

                                                    <div class="w-100 mb-1">
                                                        Promedio
                                                        <span class="font-weight-bold">{{ (responseType[row?.clave]["AC_REJECTED"]?.value*100)/responseType[row?.clave]["AC_REJECTED"]?.maxValue |  number:'1.0-1' }}%</span>

                                                    </div>
                                                    <div class="w-100 ">
                                                        Total Solicitudes
                                                        <span class="font-weight-bold">{{responseType[row?.clave]["AC_REJECTED"]?.maxValue}}</span>

                                                    </div>


                                                </div>
                                            </div>
                                            </ng-container>

                                        </div>


                                    </li>
                                    <!--
                                    <li class="d-inline ml-3">
                                        <div class="card mb-2 boxstatus active-cursor ">
                                            <div class="card-body p-3 ">
                                                <div class="d-flex bd-highlight  flex-wrap ">
                                                    <div class="w-100 font-weight-bold mb-2">
                                                        Velocidad

                                                    </div>

                                                    <div class="w-100 mb-1">
                                                        Nuevas
                                                        <div class="float-right font-weight-bold">10/día</div>
                                                    </div>

                                                    <div class="w-100  mb-1">
                                                        Enviadas
                                                        <div class="float-right font-weight-bold">5/día</div>
                                                    </div>

                                                    <div class="w-100 ">
                                                        Aprobadas
                                                        <div class="float-right font-weight-bold">3/día</div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li class="d-inline ml-3">
                                        <div class="card mb-2 boxstatus active-cursor ">
                                            <div class="card-body p-3 ">
                                                <div class="d-flex bd-highlight  flex-wrap ">
                                                    <div class="w-100 font-weight-bold mb-2">
                                                        Desiertas

                                                    </div>
                                                    <div>
                                                        <h2 class="font-weight-bold number-car">330</h2>
                                                    </div>

                                                    <div class="w-100 mb-2 ">
                                                        Tasa de desierto
                                                        <span class="font-weight-bold">77%</span>

                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li class="d-inline ml-3">
                                        <div class="card mb-2 boxstatus active-cursor ">
                                            <div class="card-body p-3 ">
                                                <div class="d-flex bd-highlight  flex-wrap ">
                                                    <div class="w-100 font-weight-bold mb-2">
                                                        Esta semana

                                                    </div>

                                                    <div class="w-100 mb-1">
                                                        Solicitudes
                                                        <div class="float-right font-weight-bold">50
                                                            <i class="ml-1 fas fa-arrow-up text-success"></i>
                                                        </div>
                                                    </div>
                                                    <div class="w-100  mb-1">
                                                        Aprobadas
                                                        <div class="float-right font-weight-bold">30
                                                            <i class="ml-1 fas fa-arrow-down text-danger"></i>
                                                        </div>
                                                    </div>
                                                    <div class="w-100  ">
                                                        Desiertas
                                                        <div class="float-right font-weight-bold">50
                                                            <i class="ml-1 fas fa-arrow-up text-success"></i>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li class="d-inline ml-3">
                                        <div class="card mb-2 boxstatus active-cursor " style="width: 260px;">
                                            <div class="card-body p-3 ">
                                                <div class="d-flex bd-highlight  flex-wrap ">
                                                    <div class="w-100 font-weight-bold mb-2">
                                                        Ultima interacción

                                                    </div>

                                                    <div class="w-100  ">
                                                        <div>Solicitudes</div>
                                                        <span class="number-car">Juan soto</span>
                                                        <div class="float-right font-weight-bold fz-12">Hoy 10:31 AM</div>

                                                    </div>
                                                    <div class="w-100  mt-2">
                                                        <div>Aprobaciones</div>

                                                        <span class="number-car">Mario Díaz</span>
                                                        <div class="float-right font-weight-bold fz-12">Ayer 11:45 AM</div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                -->

                                </ul>
                            </div>

                        </div>


                    </div>
                </div>
            </ng-container>


            <!--
            <ngx-datatable
            #table
            [sorts]="[{prop: 'wpname', dir: 'asc'}]"    

            (activate)="onActivate($event)"
            class="material"
            columnMode="force"
            [footerHeight]="50"
            rowHeight="auto"
            [limit]="limitRows"
            [rows]="rows"
            [scrollbarH]="true"
            [loadingIndicator]="loading_table2"
            [messages]="{
              emptyMessage: 'No se encontraron resultados.',
              totalMessage: 'total',
              selectedMessage: 'selected'
              }"
            >

            <ngx-datatable-column  name="#" [width]="60" frozenLeft="true" >
                    <ng-template ngx-datatable-cell-template let-row="row"  let-rowIndex="rowIndex">
                     <span > {{ rowIndex + 1 }} </span> 
                    </ng-template>
           </ngx-datatable-column>


               <ngx-datatable-column prop="wpname" name ="Nombre" [width]="250" >
                    <ng-template ngx-datatable-cell-template let-row="row" >
                            <span class=" text-primary active-cursor" [innerHTML]="row?.wpname"></span>
                    </ng-template>    
               </ngx-datatable-column>

                 <ngx-datatable-column prop="clave" name ="Clave" [width]="200"></ngx-datatable-column>
                <ngx-datatable-column prop="product" name ="Producto" [width]="200"></ngx-datatable-column>
                <ngx-datatable-column prop="plan" name ="Plan" [width]="200"></ngx-datatable-column>
                <ngx-datatable-column prop="vista" name ="Vista" [width]="150"></ngx-datatable-column>
                <ngx-datatable-column prop="agencia" name ="Agencia" [width]="200"></ngx-datatable-column>
                <ngx-datatable-column prop="responsable" name ="Responsable" [width]="200"></ngx-datatable-column>
                <ngx-datatable-column prop="tipo" name ="Audiencia" [width]="200"></ngx-datatable-column>
                <ngx-datatable-column prop="statusSolicitud" name ="Estatus Solicitud" [width]="200"></ngx-datatable-column>
                <ngx-datatable-column prop="statusCola" name ="Estatus Cola" [width]="150"></ngx-datatable-column>
    
          </ngx-datatable>-->
        </div>
        <!-- 
        <div class="row content-paginator m-t-1" *ngIf="paginator">
            <akeela-paginator [paginator]="paginator" (response)="action_paginator($event)" *ngIf="!loading_table">
            </akeela-paginator>
        </div>
-->
        <ng-container *ngIf="loading_table">
            <div class="row">
                <div class="col-12">

                    <img src="./assets/public/images/loading.gif" alt="">
                </div>
            </div>
        </ng-container>
    </div>
</div>