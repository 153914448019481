import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { PropertiesService } from '../../../services/index';
import { AuthService } from '@akeela/auth';
import { AkeelaWorkflowService } from '@akeela/workflow';

import { AkeelaSidebarMenuService } from '@akeela/menu';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { PathService, HttpService } from '@akeela/properties';
import { LocalService } from '@akeela/local-storage';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-navbarauth',
  templateUrl: './navbarauth.component.html',
  styleUrls: ['./navbarauth.component.css']
})

export class NavbarauthComponent implements OnInit {
  showSidebarLeft: boolean = false;
  env = environment;
  profileUserDefault;
  profileUser;
  profilesUser;
  superUser;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _pathService: PathService,
    public _httpService: HttpService,
    private localStorage: LocalService,
    public config: NgbDropdownConfig,
    public _auth: AuthService, public _properties: PropertiesService, public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    public akeelaWorkflowService: AkeelaWorkflowService
  ) {

    config.placement = 'top-left';
    config.autoClose = true;
  }

  logout() {


    this._auth.logout();

  }

  changeProfile(profile) {

    this.localStorage.setItem('profile', profile);
    this.profileUser = profile;

    this.router.navigateByUrl(
      this.router.createUrlTree([environment.staticLinks.workspace],
        {
          queryParams: {
            role: 'USER',
            profile: profile
          }
        }));
  }

  setShowChangePassword(param) {


    this._auth.setShowChangePassword(param);

  }


  ngOnInit() {
    this.superUser =   this.localStorage.getItem('superExecutive');


    this._akeelaSidebarMenu.showSidebarLeft.subscribe((data: boolean) => {
      this.showSidebarLeft = data;

    });


    this.profileUserDefault = this.localStorage.getItem('profileDefaut');
    this.profileUser = this.localStorage.getItem('profile');

    const opt = {
      name: 'TOP_MENU_USER',
      typeUser: true,
      username: this._auth.getUsername()
    };

    this._pathService.getPathService(false, environment.appEndPontDefaultConfig.appPropertyMenu).subscribe(pathResponse => {
      this._akeelaSidebarMenu.setsidebarTopOptions(opt);
      this._akeelaSidebarMenu.setsidebarTopKey(pathResponse);
    });


    this.getUserProfiles();
  }



  getUserProfiles() {
    const params = {
      name: 'person$enduser_profiles$get',
      params: {
        request: {
          username: this._auth.getUsername(),
          status: "ACTIVE"
        },
        path: {},
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((data: boolean) => {

      this.profilesUser = data;

    });



  }


  toggleMenu() {
    this._akeelaSidebarMenu.setshowSidebarLeft(!this.showSidebarLeft);

  }

  eventBreadcrumb(response) {
    if (response.action === 'navigate') {
      if (!isNullOrUndefined(this.localStorage.getItem('transactionModeEdit'))) {
        this.breakfreeTransactionEdit(); // Mario: este caso era de liberación individual, pero debido a que ahora se va a liberar por usuario, se comenta para ver el comportamiento
        this.breakfreeTransactionsByUser();
      } else {
        this.breakfreeTransactionsByUser();
      }
    }
  }

  breakfreeTransactionsByUser() {
    const configuration = {
      name: 'account$finish_editing_role_by_user$put',
      params: {
        path: {},
        request: {
          role: 'USER',
          systemUser: this._auth.getUsername()
        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.akeelaWorkflowService.transactionData$.next(null);

    }, error => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.akeelaWorkflowService.transactionData$.next(null);

    });
  }


  breakfreeTransactionEdit() {
    const configuration = {
      name: 'account_opening$finish_editing_role$put',
      params: {
        path: {
          transactionNumber: this.localStorage.getItem('transactionModeEdit')
        },
        request: {
          role: 'USER',
          systemUser: this._auth.getUsername()
        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.akeelaWorkflowService.transactionData$.next(null);

    }, error => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.akeelaWorkflowService.transactionData$.next(null);

    });
  }

  redirectHome() {
    this.router.navigate(['/'], {});
  }

  redirectNotes() {
    this.router.navigate(['/console/stream'], {});
  }
}
