import { Component, OnInit, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { HttpService } from '@akeela/properties';
import { AlertService } from '@akeela/alert';
import swal from 'sweetalert2';
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';


@Component({
  selector: 'app-filter-status',
  templateUrl: './filter-status.component.html',
  styleUrls: ['./filter-status.component.css']
})
export class FilterStatusComponent implements OnInit {
  @Input() current;
  @Output() response = new EventEmitter();

  current_object = null;
  stage;
  workflow;
  states = null;

  constructor(
    private akStageService: AkeelaStageService,
    private akWorkflowService: AkeelaWorkflowService,
    private _httpService: HttpService,
    alert: AlertService,
    public readonly swalTargets: SwalPortalTargets
  ) { }

  ngOnInit() {
    this.workflow = this.akWorkflowService.currentWorkflow$.getValue();
    this.getStates();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    swal.close();
  }

  getStatusByIdentifier(identifier) {
    this.current_object = this.states.filter(response => {
      return response.code === identifier;
    });
  }

  apply(code) {
    swal.close();
    this.current = code;
    this.getStatusByIdentifier(code);
    this.response.emit(code);
  }

  getStates() {
    const params: any = {
      name: 'workflow$allstates$get',
      params: {
        request: {},
        path: {
          workflow: this.workflow
        },
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response) => {
      this.states = response;
      this.getStatusByIdentifier(this.current);
    }, error => {
      this.states = {};
    });
  }

}
