import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
import { PathService, HttpService } from '@akeela/properties';
import { LocalService } from '@akeela/local-storage';
import { AuthService, ErrorService } from '@akeela/auth';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { isNullOrUndefined } from 'util';


@Component({
  selector: 'workflow-embed-components',
  templateUrl: './embed-components.component.html',
  styleUrls: ['./embed-components.component.css']
})
export class EmbedComponentsComponent implements OnInit {
  resumen = true;
  action;
  menuFooter = 'revisions';
  env = environment;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _httpService: HttpService,
    private localStorage: LocalService,
    private _error: ErrorService,
    private _akSection: AkeelaStepService,
    private akeelaSectionService: AkeelaSectionService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private _auth: AuthService
  ) { }

  ngOnInit() {
    this.akeelaSectionService.addModalSection({ name: 'FORMALIZE_ACCOUNT_MODAL' });

  }

  update(event) {
    // Comentado por Mario, siempre se redirigia al resumen viejo

    // this.router.navigateByUrl(
    //   this.router.createUrlTree([environment.staticLinks.viewAccountOpeningAnalisys],
    //     {
    //       queryParams: {}
    //     }
    //   )
    // );
    // this.resumen = false;
    // setTimeout(() => {
    //   this.resumen = true;
    // }, 10);
  }

  


  finishEditMode(object) {
    const transactionNumber = object.identifier;
    const params = {
      name: 'account_opening$finishediting$put',
      params: {
        request: {},
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.localStorage.removeItem('takenedit');
      this.renew(object.object);
      this.router.navigate([environment.staticLinks.resumenUser], { queryParams: {} });
    }, (error: any) => {
      this._error.show(error);
    });
  }

  setAction(event) {
    if (event.action === 'finishedit') {
      this.finishEditMode(event);
    }
    if (event.action === 'finishview') {
      // this.finishViewMode();
    }
    if (event.action === 'renew') {
      this.renew(event.object);
    }
    if (event.action === 'openModal') {
      this._akSection.setModeCurrentStep(false);

      if (!isNullOrUndefined(event.action)) {
        switch (event.action) {
          case 'openModal':
            if (event.identifier === 'FORMALIZE_ACCOUNT_MODAL') {
              this.comment();
            }
            break;
        }
      }

      // Comentado por Mario, siempre se redirigia al resumen viejo, con el query 215.sql cambie el modal a la vista de resumen nueva por lo cual no se require redirección

      // this.router.navigateByUrl(
      //   this.router.createUrlTree([environment.staticLinks.viewAccountOpeningAnalisys],
      //     {
      //       queryParams: {}
      //     }
      //   )
      // ).then((response) => {
      //   if (response) {
      //     this.action = event;
      //   }
      // });
    }

  }

  renew (object) {
    const transactionData = object;
    const request = {};
    const param = {
      name: 'account$renew-document$get',
      params: {
        request: {
          role: 'USER',
          audience: object.organization ? 'LEGAL' : 'NATURAL'
        },
        path: {
          transactionNumber: object.transactionNumber,
          systemUser: this._auth.getUsername()
        },
        body: {}
      }
    };

    this._httpService.executeRequest(param.name, param.params, true).subscribe((response: any) => {
      transactionData['formsheets'] = response;
      this.akeelaWorkflowService.transactionData$.next(transactionData);
    }, error => {
      this._error.show(error);
    });
  }


  comment() {
    this.akeelaSectionService.showModalSection('FORMALIZE_ACCOUNT_MODAL', true);
  }
}
