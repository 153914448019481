
<div  *ngFor="let param of params ; let first = first; let i = index">

  <div class="w-100 ak-font-roboto">

    
    <div [ngClass]="{through:param?.status == 'COMPLETED'}"[innerHTML]="param.name | i18n:lang " > </div>
    <div class="font-gray" ><em [innerHTML]="param.description | i18n:lang "></em></div>
    <hr>
  </div>
</div>


<ng-container *ngIf="loadingGuide" >
  <div>
      <img  src="./assets/public/images/loading.gif" alt="">
  </div>
</ng-container>