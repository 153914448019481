<div class=" mb-3 pb-1">

    <div class='text-secondary float-right fz-14'>
        {{(transactionData?.dateOfThing?.modifiedDate?(transactionData?.dateOfThing?.modifiedDate | date: 'd MMM, yyyy'):(transactionData?.dateOfThing?.startDate | date: 'd MMM, y h:mm a'))}}
    </div>


    <h6 class="mt-3 mb-2">
        <span [innerHTML]="'Respuesta' | i18n" class="font-weight-bold"></span>
    </h6>

    <div class="d-flex bd-highlight mt-3">
        <div class=" bd-highlight ">

            <div>
                <i class=" far fa-comment-dots mr-2  text-secondary"> </i>
                <span [innerHTML]="transactionData?.accountStatus[0]?.responseDescription | i18n" class="font-weight-normal text-secondary"></span>
            </div>

        </div>

    </div>

    <ng-container *ngIf="!transactionData">
        <div class="float-right">
            <img src="./assets/public/images/loading.gif" alt="">
        </div>
    </ng-container>

</div>

<ng-container>
    <div class="active-cursor " *ngIf="sectionRequirementsIncompleats.length>0  ">


        <div class="mb-4">

            <div class="">

                <span class="font-weight-normal title">
                                                        <i class="fas fa-paperclip mr-1 text-primary "></i>Actualice los siguientes recaudos
                                                   
                                                    </span>




            </div>

            <ng-container *ngFor="let section of sectionRequirementsIncompleats ; let first3 = first; let y = index">

                <div class="fz-14 d-flex mt-2 pb-2">
                    <div>
                        <span [innerHTML]="section.name | i18n:lang"></span>
                        <div [innerHTML]="section.reasonIncomplete?section.reasonIncomplete.alternateName:'' | i18n:lang" class="font-weight-normal pb-1"></div>
                        <div [innerHTML]="section.description | i18n:lang" class="font-weight-normal text-secondary"></div>

                    </div>

                </div>



            </ng-container>
        </div>

        <ng-container *ngIf="loadRecaudos">
            <div class="float-right">
                <img src="./assets/public/images/loading.gif" alt="">
            </div>
        </ng-container>

    </div>

</ng-container>
<ng-container *ngIf="(sectionIncompleats?.length > 0 )">


    <div class=" mb-4 pb-3">

        <h6 class="mt-3 mb-2">
            <span class="font-weight-normal title">
                                             <i class="fas fa-id-card-alt mr-1 text-primary"></i>Actualice la información solicitada
                                        </span>
        </h6>

        <ng-container *ngFor="let stage of sectionIncompleats; let first = first; let i = index">

            <ng-container *ngFor="let step of stage?.howToSteps ; let first2 = first; let x = index">

                <ng-container *ngFor="let section of step?.sections ; let first3 = first; let y = index">


                    <div class="d-flex mt-3 pb-2">

                        <div class=" pr-2  pr-md-3">
                            <i class=" fas fa-circle text-primary" style="font-size: 12px"> </i>

                        </div>
                        <div class="pb-1 active-cursor">

                            <span [innerHTML]="section?.sectionTitle | i18n:lang " class="font-weight-normal pb-1 "></span>


                            <div [innerHTML]="section?.sectionIncompleted?.description | i18n:lang" class="font-weight-normal text-secondary"></div>

                        </div>



                    </div>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="loadDatos">
            <div class="float-right">
                <img src="./assets/public/images/loading.gif" alt="">
            </div>
        </ng-container>


    </div>
</ng-container>