import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
declare var JitsiMeetExternalAPI: any;


declare global {
    interface MediaDevices {
      getDisplayMedia(constraints?: MediaStreamConstraints): Promise<MediaStream>;
    }
  }


/*
document.querySelector("#start-capture").addEventListener("click", () => {
 
});

document.querySelector("#end-capture").addEventListener("click", ()=> {

});

*/
@Component({
    selector: 'app-jitsi',
    templateUrl: './jitsi.component.html',
    styleUrls: ['./jitsi.component.css']
})
export class JitsiComponent implements OnInit, AfterViewInit {
    init_start = false;
    domain: string = "meet.jit.si";
    room: any;
    options: any;
    api: any;
    user: any;

    // For Custom Controls
    isAudioMuted = false;
    isVideoMuted = true;
    localVideo;
    @ViewChild("size", {static: false}) size: ElementRef;
    @Input('client') client?: string;
    @Input('executive') executive?: string;
    @Input('urlmeet') urlMeet?: string;
    @Output() change = new EventEmitter();



    constructor(
        private router: Router
    ) { 

    }

    ngOnInit(): void {
  
        this.room = this.urlMeet ; // set your room name
        this.user = {
            name:  this.executive // set your username
        }
    }

    start(){
        this.init_start = true;

         navigator.mediaDevices
         .getDisplayMedia({ audio: true, video: true })
         // navigator.mediaDevices.getUserMedia()
         .then(stream => {
           let videoTracks = stream.getVideoTracks();
           console.log(videoTracks);
           this.localVideo.srcObject = stream;
         })
         .catch(error => {
           console.error(error);
           document.getElementById("errors").textContent += `${error}\n`;
         });
 
    }

    stop(){
        this.init_start = false;

       // let localVideo2:any = document.getElementById('video2')
        this.localVideo.pause();
        // // MediaStreamの各トラックをすべてstopしないと、ブラウザはキャプチャが続いていると認識してしまうので。
        //let tracks =  (<MediaStream>this.localVideo.srcObject).getTracks().forEach(t => t.stop());
       this.localVideo.srcObject = null;


        
      //  localVideo2.srcObject = tracks;

    }

    ngAfterViewInit(): void {

        this.localVideo = document.querySelector("video");


        this.options = {
            roomName: this.room,
            width: this.size.nativeElement.offsetwidth,
            height: 400,

            configOverwrite: { prejoinPageEnabled: false },
            interfaceConfigOverwrite: {
                // overwrite interface properties
                TOOLBAR_ALWAYS_VISIBLE:false
            },
            parentNode: document.querySelector('#jitsi-iframe'),
            userInfo: {
                displayName: this.user.name
            }
        }

        this.api = new JitsiMeetExternalAPI(this.domain, this.options);


        this.api.addEventListeners({
            readyToClose: this.handleClose,
            participantLeft: this.handleParticipantLeft,
            participantJoined: this.handleParticipantJoined,
            videoConferenceJoined: this.handleVideoConferenceJoined,
            videoConferenceLeft: this.handleVideoConferenceLeft,
            audioMuteStatusChanged: this.handleMuteStatus,
            videoMuteStatusChanged: this.handleVideoStatus
        });
    }


    handleClose = () => {
        console.log("handleClose");
    }

    handleParticipantLeft = async (participant) => {
        console.log("handleParticipantLeft", participant); // { id: "2baa184e" }
        const data = await this.getParticipants();
    }

    handleParticipantJoined = async (participant) => {
        console.log("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
        const data = await this.getParticipants();
    }

    handleVideoConferenceJoined = async (participant) => {
        console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
        const data = await this.getParticipants();
    }

    handleVideoConferenceLeft = () => {
        console.log("handleVideoConferenceLeft");
       // this.router.navigate(['/thank-you']);
       this.change.emit(false)

    }

    handleMuteStatus = (audio) => {
        console.log("handleMuteStatus", audio); // { muted: true }
    }

    handleVideoStatus = (video) => {
        console.log("handleVideoStatus", video); // { muted: true }
    }

    getParticipants() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(this.api.getParticipantsInfo()); // get all participants
            }, 500)
        });
    }

    // custom events
    executeCommand(command: string) {
        this.api.executeCommand(command);;
        if(command == 'hangup') {
           // this.router.navigate(['/thank-you']);
           this.change.emit(false)

            return;
        }

        if(command == 'toggleAudio') {
            this.isAudioMuted = !this.isAudioMuted;
        }

        if(command == 'toggleVideo') {
            this.isVideoMuted = !this.isVideoMuted;
        }
    }


}
