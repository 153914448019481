<div class="container animated fadeIn">

    <div class="box-head skin-white x">
        <div class="row">
            <div class="col-md-12 col-12">
                <h5 *ngIf="!internal" class="mt-5 mb-4">Crear Invitado</h5>


                <form [formGroup]="form" (ngSubmit)="submit()">

                    <div class="row  form-group">

                        <div class="col-md-6  col-12 text-left ">

                            <label class="label mb-2 font-weight-normal" style="word-wrap: break-word;">
                                <span [innerHTML]="'Tipo' | i18n:lang"></span>
                            </label>
                            <div class="input-group ">
                                <select class="form-control" [ngClass]="{'error': form.controls.personType.invalid && form.controls.personType.dirty}" formControlName="personType">
                                    <option value="NATURAL">Natural</option>
                                    <option value="LEGAL">Jurídico</option>

                                </select>



                            </div>

                            <div class="form-control-feedback help-block" *ngIf="form.get('personType').errors">
                                <div *ngIf="form.get('personType').errors['required'] && !form.get('personType').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n:lang">
                                </div>

                                <div *ngIf="form.get('personType').hasError('personType')  && !form.get('personType').pristine">
                                    <span [innerHTML]="'@i18n-form-pattern-personType' | i18n:lang"></span>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row  form-group">

                        <div class="col-md-6  col-12 text-left ">

                            <label class="label  mb-2  font-weight-normal" style="word-wrap: break-word;">
                                <span [innerHTML]="'Nombre' | i18n:lang"></span>
                            </label>
                            <div class="input-group ">

                                <input autocomplete="current-name" [placeholder]="'Nombre' | i18n:lang" [type]="'text'" class="form-control" [ngClass]="{'error': form.controls.name.invalid && form.controls.name.dirty}" formControlName="name">

                            </div>

                            <div class="form-control-feedback help-block" *ngIf="form.get('name').errors">
                                <div *ngIf="form.get('name').errors['required'] && !form.get('name').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n:lang">
                                </div>

                                <div *ngIf="form.get('name').hasError('name')  && !form.get('name').pristine">
                                    <span [innerHTML]="'@i18n-form-pattern-name' | i18n:lang"></span>
                                </div>

                            </div>
                        </div>

                        <div class="col-md-6  col-12 text-left ">

                            <label class="label  mb-2  font-weight-normal" style="word-wrap: break-word;">
                                <span [innerHTML]="'Apellido' | i18n:lang"></span>
                            </label>
                            <div class="input-group ">

                                <input autocomplete="current-lastName" [placeholder]="'Apellido' | i18n:lang" [type]="'text'" class="form-control" [ngClass]="{'error': form.controls.lastName.invalid && form.controls.lastName.dirty}" formControlName="lastName">


                            </div>

                            <div class="form-control-feedback help-block" *ngIf="form.get('lastName').errors">
                                <div *ngIf="form.get('lastName').errors['required'] && !form.get('lastName').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n:lang">
                                </div>

                                <div *ngIf="form.get('lastName').hasError('lastName')  && !form.get('lastName').pristine">
                                    <span [innerHTML]="'@i18n-form-pattern-lastName' | i18n:lang"></span>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="row  form-group" *ngIf="form?.get('personType')?.value == 'LEGAL'">


                        <div class="col-md-6  col-12 text-left ">
                            <label class="label  mb-2  font-weight-normal" style="word-wrap: break-word;">
                                <span [innerHTML]="'Empresa' | i18n:lang"></span>
                            </label>
                            <div class="input-group ">

                                <input autocomplete="current-businessName" [placeholder]="'Empresa' | i18n:lang" [type]="'text'" class="form-control" [ngClass]="{'error': form.controls.businessName.invalid && form.controls.businessName.dirty}" formControlName="businessName">


                            </div>

                            <div class="form-control-feedback help-block" *ngIf="form.get('businessName').errors">
                                <div *ngIf="form.get('businessName').errors['required'] && !form.get('businessName').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n:lang">
                                </div>

                                <div *ngIf="form.get('businessName').hasError('businessName')  && !form.get('businessName').pristine">
                                    <span [innerHTML]="'@i18n-form-pattern-businessName' | i18n:lang"></span>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="row  form-group">


                        <div class="col-md-6  col-12 text-left ">

                            <label class="label  mb-2  font-weight-normal" style="word-wrap: break-word;">
                                <span [innerHTML]="'Email' | i18n:lang"></span>
                            </label>
                            <div class="input-group ">

                                <input autocomplete="current-email" [placeholder]="'Email' | i18n:lang" [type]="'text'" class="form-control" [ngClass]="{'error': form.controls.email.invalid && form.controls.email.dirty}" formControlName="email">


                            </div>

                            <div class="form-control-feedback help-block" *ngIf="form.get('email').errors">
                                <div *ngIf="form.get('email').errors['required'] && !form.get('email').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n:lang">
                                </div>

                                <div *ngIf="form.get('email').hasError('email')  && !form.get('email').pristine">
                                    <span [innerHTML]="'@i18n-form-pattern-email' | i18n:lang"></span>
                                </div>

                            </div>
                        </div>


                        <div class="col-md-6  col-12 text-left ">

                            <label class="label  mb-2  font-weight-normal" style="word-wrap: break-word;">
                                <span [innerHTML]="'Teléfono' | i18n:lang"></span>
                            </label>
                            <div class="input-group ">

                                <input autocomplete="current-phone" [placeholder]="'Teléfono' | i18n:lang" [type]="'text'" class="form-control" [ngClass]="{'error': form.controls.phone.invalid && form.controls.phone.dirty}" formControlName="phone">

                            </div>

                            <div class="form-control-feedback help-block" *ngIf="form.get('phone').errors">
                                <div *ngIf="form.get('phone').errors['required'] && !form.get('phone').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n:lang">
                                </div>

                                <div *ngIf="form.get('phone').hasError('phone')  && !form.get('phone').pristine">
                                    <span [innerHTML]="'@i18n-form-pattern-phone' | i18n:lang"></span>
                                </div>

                            </div>
                        </div>
                        <!--
                        <div class="col-md-6 col-12  text-left">
                            <label class="label font-weight-normal" style="word-wrap: break-word;">
                            <span [innerHTML]="'@i18n-store-front-login-form-confirmPassword' | i18n:lang"></span>
                            <span class="text-danger mt-1" > * </span>
                         </label>

                            <div class="input-group ">
                                <input autocomplete="current-password" [placeholder]="'@i18n-quote-createUser-form-label-confirmPassword' | i18n:lang" [type]="!typeInputPass2?'password':'text'" class="form-control" [ngClass]="{'error': form.controls.password2.invalid && form.controls.password2.dirty}"
                                    formControlName="password2">


                                <div class="input-group-append ">
                                    <i [ngClass]="{' fa-eye': typeInputPass2,'fa-eye-slash':!typeInputPass2 }" (click)="viewPassword2()" class="pt-2 far cursor-pounter input-group-text"></i>
                                </div>
                            </div>



                            <div class="form-control-feedback help-block" *ngIf="form.get('password2').errors">
                                <div *ngIf="form.get('password2').errors['required'] && !form.get('password').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n:lang">
                                </div>

                                <div *ngIf="form.get('password2').hasError('minlength')  && !form.get('password').pristine">
                                    Mínimo 8 caracteres
                                </div>

                                <div *ngIf="form.get('password2').hasError('maxlength')  && !form.get('password').pristine">
                                    Máximo 12 caracteres
                                </div>

                                <div *ngIf="form.get('password2').hasError('pattern')  && !form.get('password').pristine">
                                    <span [innerHTML]="'@i18n-form-pattern-password' | i18n:lang"></span>
                                </div>

                                <div *ngIf="form.get('password2').hasError('passwordDistinct')  && !form.get('password').pristine">
                                    Las contraseñas no coinciden.
                                </div>

                            </div>
                        </div>
                         -->

                    </div>

                    <div class="row ">
                        <div class="col-12 text-left form-group">
                            <br>
                            <button (click)="submit()" type="submit " [disabled]="!(form.valid  && form.dirty ) || submit_disabled " class="btn  btn-primary btn-md "><span>Continuar</span>&nbsp; <span *ngIf="submit_disabled ">

                                <img style="width: 20px;" src="./assets/public/images/loading.gif" alt="">

                            </span></button>
                        </div>
                    </div>
                </form>
                <!-- <pre>{{ form.getRawValue() | json }}</pre> -->
            </div>
        </div>

    </div>
</div>