import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { I18nPipe } from '@akeela/i18n';
import { HttpService } from '@akeela/properties';
import swal from 'sweetalert2';
import { ErrorService } from '@akeela/common';
import { LocalService } from '@akeela/local-storage';
import { AuthService } from '@akeela/auth';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'ak-new-guest',
  templateUrl: './new-guest.component.html',
  styleUrls: ['./new-guest.component.css']
})
export class NewGuestComponent implements OnInit {


  @Output() response: EventEmitter<any> = new EventEmitter<any>();
  @Input() internal;

  form: FormGroup;
  submit_disabled = false;
  lang;
  password;
  regxpassword = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,12}$)(?=.*\\W)(?!.*(.)\\1\\1\\1).*$";
  typeInputPass = false;
  typeInputPass2 = false;
  executiveUsername;
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private formBuilder: FormBuilder,
    private _i18n: I18nPipe,
    private _auth: AuthService,
    private _httpService: HttpService,
    private _error: ErrorService,
    private localStorage: LocalService,
    private error: ErrorService,

  ) {
  }



  ngOnInit() {

    this.executiveUsername = this._auth.getUsername() || 'ejecutivo';
    this.form = this.formBuilder.group({
      personType: ['NATURAL', [Validators.required]],
      name: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      businessName: [''],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      executiveUsername: [this.executiveUsername, [Validators.required]], 
      application:environment?.theme?.images

      /*    
      password: ['', [Validators.required, , Validators.minLength(8), Validators.maxLength(12), Validators.pattern(this.regxpassword)]],
      password2: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(12)]]
   */
    });

  }


  updateValueAndValidity() {

    let controlField = this.form.controls['password2'];
    controlField.updateValueAndValidity();
  }


  submit() {

    this.submit_disabled = true;
    if (this.form.dirty && this.form.valid) {

      swal.fire({
        title: '',
        html: '¿Estas seguro de enviar esta invitación?',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',      
        reverseButtons: true
      }).then((result) => {
    
        if(result?.isConfirmed){

    
      let data :any;
 
      const params = {
    
        name: 'person$create_guest$post',
        params: {
          request: {
          },
          path: {},
          body: this.form.getRawValue()
        }
      };
    
    
      this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    
          if (response) {
            this.response.emit(true);

            this.form.reset();
            swal.fire({
              reverseButtons: true,
              confirmButtonText: 'Ok',
              html: 'Invitación Creada',
            });

    
          }
          this.submit_disabled = false;

      }, error => {
          this.error.show(error);

        this.submit_disabled = false;
      });
     

    }});
    
    }
  }


}
