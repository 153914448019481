<!--
 <div class="card mt-2">
                                    <div class="card-body p-3">
                    
                                        <div *ngIf="name font-weight-bold" >
                                        <div class="mr-2 d-inline-flex DomainUserAvatar-avatar Avatar--color-{{initial}}  AvatarPhoto AvatarPhoto--small" role="img"> {{initials}} </div>
                                        <div class="d-inline-flex "> {{name}}</div>
                                        <div class=" mt-1 text-secondary text">

                                        <a href="javascript:void(0)" (click)="show_file(file)"  > {{ file.alternateName }}</a>

                                        </div>
                                        </div>
                                    </div>
                            </div>
-->

<ng-container *ngIf="transactionData?.formsheets">
<!-- {{ transactionData?.formsheets | json }} -->


    <!-- <ng-container *ngIf="transactionData?.formsheets.length===0">
        <div class="text-center"><br>No se ha generado la planilla. Aún en proceso de formación de la solicitud.</div>
        <br>
    </ng-container> -->

    <ng-container *ngIf="transactionData?.formsheets.length>0">
        <div class="active-cursor">
            <div class="w-100 fz-body">
                <div class="d-flex bd-highlight list-flex flex-wrap pb-3 pt-3 pb-md-2 pt-md-2">

                    <div class=" mb-1 mb-md-0 text-md-right  mt-md-0 w-10 pl-md-2 pr-md-2  order-2">

                    </div>

                    <div class=" mb-1 mb-md-0 text-md-right  mt-md-0 w-10 pl-md-2 pr-md-2  order-3 d-none d-md-block ">

                    </div>

                </div>
                <div class="d-flex bd-highlight list-flex flex-wrap pb-3 pt-3 pb-md-2 pt-md-2">
                    <ng-container *ngFor="let file of transactionData?.formsheets">



                        <div class="card mt-2 w-100" (click)="show_file(file)">
                            <div class="card-body p-3">
                                <div *ngIf="name font-weight-bold ">
                                        <img  [src]="url+'/pdf50.png'"  class="float-right" alt="">


                                    <div class="mr-2 d-inline-flex DomainUserAvatar-avatar Avatar--color-{{initial}}  AvatarPhoto AvatarPhoto--small" role="img">
                                    {{initials}} </div>
                                    <div class="d-inline-flex "> {{name}}</div>
                                    <div class=" mt-1 text-primary text">
                                      Ficha de Identificación del Cliente

                                        <!--
                                        {{ file.alternateName }}
                                        -->
                                    </div>

                                </div>
                            </div>
                        </div>


                    </ng-container>

                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-container *ngIf="transactionData && !transactionData?.formsheets">
    {{ transactionData?.formsheets }}
    <div class="card mt-2 w-100">
        <div class="card-body p-3">
            <div *ngIf="name font-weight-bold">
                <div class="mr-2 d-inline-flex DomainUserAvatar-avatar Avatar--color-{{initial}}  AvatarPhoto AvatarPhoto--small" role="img">
                {{initials}} </div>
                <div class="d-inline-flex "> {{name}}</div>
                <div class=" mt-1 text-secondary text">
                    <ng-container *ngIf="transactionData.editTaken">
                        Está editando la solicitud, se generará la planilla cuando finalice la edición de la solicitud.
                    </ng-container>
                    <ng-container *ngIf="!transactionData.editTaken">
                        No se ha generado la planilla. Aún en proceso de formación de la solicitud.
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>