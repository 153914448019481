<swal [width]="'100%'" [showCloseButton]="true" [showConfirmButton]="false" [showCancelButton]="false" #showWindow>
    <ng-container *swalPortal="swalTargets.content">
        <div class="pt-2 pb-2">
            <app-face-api-analisis [analisis]="items"></app-face-api-analisis>
        </div>
    </ng-container>
</swal>





<app-template>
    <ng-template app-content componentName="mainContentModalBottonVerification">
        <div class="capa-menu-2" *ngIf="buttomModal" (click)="changes()"> </div>

        <div [ngClass]="{'d-none':animate_init_bottom,'animate__slideInUp':buttomModal,animate__slideOutDown:!buttomModal } " class="  animate__animated animate__faster ak-modal-botoom ak-modal-botoom2  p-2 ">
            <div class="body pb-4 pt-2 " style="overflow:auto;">
                <div class="container text-center">
                    <div class="mt-4">

                        <div class="row text-center ">
                            <div class="col-12 col-md-6 offset-md-3">
                                <div>
                                    <div class="d-flex ">
                                        <div (click)="setUserDetail()" *ngIf="!optVerification" class="pr-2 w-10">
                                            <i class="fas fa-angle-left text-secondary active-cursor  fa-2x" aria-hidden="true"></i>
                                        </div>

                                        <div (click)="setBack()" *ngIf="optVerification" class="pr-2 w-10">
                                            <i class="fas fa-angle-left text-secondary active-cursor  fa-2x" aria-hidden="true"></i>
                                        </div>
                                        <div *ngIf="!back" class="fz-12 w-90 text-left">
                                            <div class="font-weight-bold text-capitalize">
                                                <div *ngIf="clientDetail?.personMarketType?.identifier==='NATURAL'"> {{ clientDetail.person.givenName + ' ' + clientDetail.person.familyName }}
                                                </div>
                                                <div *ngIf="clientDetail?.adminOrganization?.legalName"> {{clientDetail?.adminOrganization?.legalName }} </div>

                                            </div>

                                            <div classtrue="text-secondary">
                                                {{dataInformation?.additionalType =="2"?'Cédula':'' }} {{dataInformation?.identity}}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <hr>
                                <h5 class="pt-2 mb-4 font-weight-bold"> Verificación de Identidad</h5>

                                <div class="text-center ">
                                    <i class="huella3 fas fa-fingerprint fa-3x" [ngClass]="{'text-success': verifiedIdentification,'text-primary': !verifiedIdentification && compleatVerifi  }"></i>
                                </div>

                                <div class="fz-14 text-secondary text-center mb-4">
                                    <!--verifiedIdentification: {{verifiedIdentification}}<br>-->
                                    <span *ngIf="verifiedIdentification">Verificada</span>
                                    <span *ngIf=" !verifiedIdentification && compleatVerifi">Pendiente por revisar</span>
                                    <span *ngIf="!verifiedIdentification && !compleatVerifi">Pendiente por Completar</span>
                                </div>

                                <ng-container>

                                    <button [disabled]="!compleatVerifi " *ngIf="!optVerification && !verifiedIdentification" class="btn btn-outline-primary mt-2 mb-2 " (click)="optVerification = true">
                                        Responder verificación
                                    </button>
                                    <div *ngIf="verifiedIdentification" class="pt-2 pb-3">
                                        <span class="alert alert-success ">Verificación de identidad aprobada</span>

                                    </div>

                                    <ng-container *ngIf="optVerification">
                                        <div class="animate__animated animate__fadeIn">

                                            <button (click)="verificarClient(true)" class="btn btn-primary btn-block mt-2 mb-3 ">
                                                Verificar
                                            </button>
                                            <button (click)="resetVerification()" class="btn btn-outline-primary btn-block mt-2 mb-3 ">
                                                Devolver
                                            </button>
                                            <!-- 
                                            <button disabled class="btn btn-secondary btn-block mt-2 mb-3 ">
                                                Copy Link
                                            </button>-->
                                            <!--  <button (click)="verificarClient(false)" class="btn btn-outline-danger btn-block mt-4 mb-3 ">
                                                Rechazar
                                            </button> -->

                                        </div>

                                    </ng-container>


                                    <ng-container *ngIf="!optVerification ">
                                        <div class=" text-left mt-4 animate__animated animate__fadeIn">


                                            <div class="p-2 fz-14 d-flex bd-highlight  list-flex flex-wrap text-left size-caja-dopd">

                                                <div class="w-100" *ngIf="dataInformation?.nationality?.identifier">

                                                    <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                        <div class="w-50   text-capitalize">

                                                            <div class="font-weight-bold  fz-14  ">Nacionalidad</div>
                                                            <div class="text-secondary  fz-14  ">{{dataInformation?.nationality?.identifier == 'VE'?'Venezolano':dataInformation?.nationality?.identifier}}</div>


                                                        </div>
                                                        <div class="w-50  text-right" *ngIf="verifiedIdentification && clientDetail?.createDate">
                                                            <div>
                                                                <i class="fas fa-check text-success"></i>
                                                            </div>
                                                            <!--<div class="text-secondary  fz-14 ">{{dataInformation?.birthDate | date: 'd MMM, y h:mm a'}}</div> -->

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="w-100 mt-2" *ngIf="dataInformation?.additionalType">
                                                    <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                        <div class="w-50   text-capitalize ">
                                                            <div class="font-weight-bold  fz-14  ">Número de Documento</div>
                                                            <div class="text-secondary  fz-14  ">{{dataInformation?.identity}}</div>

                                                        </div>
                                                        <div class="w-50  text-right" *ngIf="verifiedIdentification && clientDetail?.mailConfirmationDate">
                                                            <div>
                                                                <i class="fas fa-check text-success"></i>
                                                            </div>
                                                            <!--<div class="text-secondary  fz-14 ">{{dataInformation?.birthDate | date: 'd MMM, y h:mm a'}}</div> -->

                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="w-100 mt-2" *ngIf="dataInformation?.birthDate">
                                                    <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                        <div class="w-100   text-capitalize">
                                                            <div class="font-weight-bold  fz-14  ">Fecha de Nacimiento</div>
                                                            <div class="text-secondary  fz-14  ">{{toDateTime(dataInformation?.birthDate)!==null?(toDateTime(dataInformation?.birthDate) | date: 'd MMM, y'):''}}
                                                                <span class="fz-12 text-dark">{{calculateAge(toDateTime(dataInformation?.birthDate) | date:
                                                                    'dd/MM/yyyy':'UTC')}} 
                                                                </span>
                                                            </div>

                                                        </div>
                                                        <div class="w-50  text-right">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="pl-2 text-secondary font-weight-bold fz-16 mt-3 mb-2">Documentos de Identificación</div>


                                            <div class="p-2 fz-14 d-flex bd-highlight  list-flex flex-wrap text-left size-caja-dopd">

                                                <div class="w-100">



                                                    <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                        <div class="w-90   text-capitalize">
                                                            <div class="font-weight-bold  fz-14  pb-2">Selfie</div>

                                                        </div>

                                                        <div class="w-10  text-right pb-1" *ngIf="clientDetail?.createDate">
                                                            <div *ngIf="getDocumentClient('SELFIE')" (click)="showInfoFacialRecognition('SELFIE')" [swal]="showWindow">
                                                                <!-- <i class="fas fa-check text-secondary" [ngClass]="{'text-success': verifiedIdentification }"></i> -->
                                                                <i class="fas fa-smile-beam fa-2x pr-2 text-success" *ngIf="clientNumberAnalisis['SELFIE']===true"></i>
                                                                <i class="fas fa-frown fa-2x pr-2 " style="color:red" *ngIf="clientNumberAnalisis['SELFIE']===false"></i>

                                                                <i class="huella3 fas fa-fingerprint fa-2x" [ngClass]="{'text-success': this.clientAttepsResponse['SELFIE'] }"></i>
                                                                <span class="badge badge-pill badge-warning" *ngIf="this.clientNumberAtteps['SELFIE']>0">{{this.clientNumberAtteps['SELFIE']}}</span>

                                                            </div>

                                                        </div>
                                                        <div class="w-100">
                                                            <div class="container-image">


                                                                <div class="image-column text-center br-10" (click)="show_file(getDocumentClient('SELFIE'))" *ngIf="getDocumentClient('SELFIE')!==null">
                                                                    <app-preview-img [identifier]="getDocumentClient('SELFIE')" (response)="getResponsePreview($event)"></app-preview-img>
                                                                    <div class="overlay">
                                                                        <div class="text active-cursor">
                                                                            <i class="fas fa-eye"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="image-column text-center" *ngIf="!getDocumentClient('SELFIE')">

                                                                    <div>
                                                                        <i class="far fa-clock fa-2x"></i>.
                                                                        <br>
                                                                        <div class="font-weight-bold text-black">Pendiente</div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <!-- <div *ngIf="validateAttemp('SELFIE').length>0">
                                                                    <ng-container *ngIf="this.clientAttepsResponse['SELFIE']">
                                                                        <span [innerHTML]="this.clientAttepsResponse['SELFIE']"></span>
                                                                    </ng-container>
                                                                </div> -->
                                                            <!-- <div>
                                                                    Intentos: <span [innerHTML]="this.clientNumberAtteps['SELFIE']"></span>
                                                                </div> -->

                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="w-100 mt-3">
                                                    <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                        <div class="w-70   text-capitalize">
                                                            <div class="font-weight-bold  fz-14  pb-2">Documento de ID personal</div>

                                                        </div>
                                                        <div class="w-30  text-right pb-1" *ngIf="clientDetail?.createDate">
                                                            <div *ngIf="getDocumentClient('DOC_IDENTITY')" (click)="showInfoFacialRecognition('DOC_IDENTITY')" [swal]="showWindow">
                                                                <!-- <i class="fas fa-check text-secondary" [ngClass]="{'text-success': verifiedIdentification }"></i> -->
                                                                <i class="fas fa-smile-beam fa-2x pr-2 text-success" title="El rostro coincide con el Selfie" *ngIf="clientNumberAnalisis['DOC_IDENTITY']===true"></i>
                                                                <i class="fas fa-frown fa-2x pr-2 " title="No se logro hacer match con el rostro del Selfie" style="color:red" *ngIf="clientNumberAnalisis['DOC_IDENTITY']===false"></i>

                                                                <i class="huella3 fas fa-fingerprint fa-2x" [ngClass]="{'text-success': this.clientAttepsResponse['DOC_IDENTITY'] }"></i>
                                                                <span class="badge badge-pill badge-warning" *ngIf="this.clientNumberAtteps['DOC_IDENTITY']>0">{{this.clientNumberAtteps['DOC_IDENTITY']}}</span>

                                                            </div>

                                                        </div>
                                                        <div class="w-100">
                                                            <div class="container-image">

                                                                <div class="image-column text-center br-10" (click)="show_file(getDocumentClient('DOC_IDENTITY'))" *ngIf="getDocumentClient('DOC_IDENTITY')!==null">
                                                                    <app-preview-img [identifier]="getDocumentClient('DOC_IDENTITY')" (response)="getResponsePreview($event)"></app-preview-img>
                                                                    <div class="overlay">
                                                                        <div class="text active-cursor">
                                                                            <i class="fas fa-eye"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="image-column text-center" *ngIf="!getDocumentClient('DOC_IDENTITY')">

                                                                    <div>
                                                                        <i class="far fa-clock fa-2x"></i>
                                                                        <br>
                                                                        <div class="font-weight-bold text-black">Pendiente</div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <!-- <div *ngIf="validateAttemp('DOC_IDENTITY').length>0">
                                                                        <ng-container *ngIf="this.clientAttepsResponse['DOC_IDENTITY']">
                                                                            <span [innerHTML]="this.clientAttepsResponse['DOC_IDENTITY']"></span>
                                                                           
                                                                        </ng-container>
                                                                    </div> -->
                                                            <!-- <div>
                                                                        Intentos: <span [innerHTML]="this.clientNumberAtteps['DOC_IDENTITY']"></span>
                                                                    </div> -->

                                                        </div>
                                                    </div>

                                                </div>


                                                <div class="w-100 mt-3">

                                                    <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                        <div class="w-70   text-capitalize">
                                                            <div class="font-weight-bold  fz-14  pb-2">Selfie + ID</div>

                                                        </div>
                                                        <div class="w-30  text-right pb-1" *ngIf="clientDetail?.createDate">
                                                            <div *ngIf="getDocumentClient('SELFIE_DOC_IDENTITY')" (click)="showInfoFacialRecognition('SELFIE_DOC_IDENTITY')" [swal]="showWindow">
                                                                <!-- <i class="fas fa-check text-secondary" [ngClass]="{'text-success': verifiedIdentification }"></i> -->
                                                                <i class="fas fa-smile-beam fa-2x pr-2 text-success" title="El rostro coincide con el Selfie" *ngIf="clientNumberAnalisis['SELFIE_DOC_IDENTITY']===true"></i>
                                                                <i class="fas fa-frown fa-2x pr-2 " style="color:red" title="No se logro hacer match con el rostro del Selfie" *ngIf="clientNumberAnalisis['SELFIE_DOC_IDENTITY']===false"></i>

                                                                <i class="huella3 fas fa-fingerprint fa-2x" [ngClass]="{'text-success': this.clientAttepsResponse['SELFIE_DOC_IDENTITY'] }"></i>
                                                                <span class="badge badge-pill badge-warning" *ngIf="this.clientNumberAtteps['SELFIE_DOC_IDENTITY']>0">{{this.clientNumberAtteps['SELFIE_DOC_IDENTITY']}}</span>

                                                            </div>

                                                        </div>
                                                        <div class="w-100">

                                                            <div class="container-image">

                                                                <div class="image-column text-center br-10" (click)="show_file(getDocumentClient('SELFIE_DOC_IDENTITY'))" *ngIf="getDocumentClient('SELFIE_DOC_IDENTITY')!==null">
                                                                    <app-preview-img [identifier]="getDocumentClient('SELFIE_DOC_IDENTITY')" (response)="getResponsePreview($event)"></app-preview-img>
                                                                    <div class="overlay">
                                                                        <div class="text active-cursor">
                                                                            <i class="fas fa-eye"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div class="image-column text-center" *ngIf="!getDocumentClient('SELFIE_DOC_IDENTITY')">

                                                                    <div>
                                                                        <i class="far fa-clock fa-2x"></i>.
                                                                        <br>
                                                                        <div class="font-weight-bold text-black">Pendiente</div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <!-- <div class="font-weight-bold text-secondary  fz-12 text-left">
                                                                    ajsbdfbasbdf
                                                                    <!-- {{ archive?.dateOfThing?.startDate | date: 'd MMM, y h:mm a' }} -->
                                                            <!--</div> -->

                                                            <!-- <div *ngIf="validateAttemp('SELFIE_DOC_IDENTITY').length>0">
                                                                <ng-container *ngIf="this.clientAttepsResponse['SELFIE_DOC_IDENTITY']">
                                                                    <span [innerHTML]="this.clientAttepsResponse['SELFIE_DOC_IDENTITY']"></span>
                                                                </ng-container>
                                                            </div> -->
                                                            <!-- <div>
                                                                Intentos: <span [innerHTML]="this.clientNumberAtteps['SELFIE_DOC_IDENTITY']"></span>
                                                            </div> -->

                                                        </div>

                                                        <div class="w-100 pt-4" *ngIf="compleatVerifi && !verifiedIdentification">
                                                            <div class="row">
                                                                <div class="col-6" style="display: flex;
                                                                justify-content: left;
                                                                align-items: center;color:gray">
                                                                    ¿Cumple con la política?
                                                                </div>
                                                                <div class="col-6 text-right">
                                                                    <button type="button" class="btn btn-outline-primary pl-2" (click)="verificarClient(true)">Si <i class="fas fa-thumbs-up"></i></button>&nbsp;
                                                                    <button type="button" class="btn btn-outline-primary" (click)="resetVerification()">No <i class="fas fa-thumbs-down"></i></button>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>

                                            </div>

                                        </div>


                                    </ng-container>


                                </ng-container>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </ng-template>
</app-template>