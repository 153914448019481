

<ng-container *ngIf="workspace">
<!--
    <div>

        <div class="mb-2" style="font-size: 16px;">
                <i class="text-secondary pr-2 fas fa-archive "></i> 
                <span  class="text-secondary font-weight-bold" >Espacio de Trabajo</span>
        
        </div>

        <div class=" ak-menu-left-dd-link">
            <span style="font-size: 14px;" >{{ workspace.name }}</span>

            <div ngbDropdown display="dynamic" placement="bottom-left">
                                           

                <div ngbDropdownToggle id="navbarDropdown3"   class=" btn-link text-primary font-color-gray mt-2 active-cursor" style="font-size: 14px">
                    <span [innerHTML]=" 'Detalle' | i18n:lang " class=""> </span>
                
                </div>
                            <div style="width: 210px; margin-left: -10px;background: #fefefe " ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu">
            
                                    <ng-container *ngTemplateOutlet="htmlContent" ></ng-container>
                        </div>

                    
                </div>
            
       </div>
       <hr>
   </div>-->

    <ng-template #htmlContent>
            <div style="font-size:.82rem" class="w-100 p-2">

                   
                 <div [innerHTML]="'<b>Espacio</b>'" class="text-secondary" style="font-size: 16px"> </div>
                        
                    <hr class="m-2">
                    <div class=" mt-1" *ngIf="workspace?.name"> 
                        <div class=" w-100  font-weight-bold ">Nombre</div>
                        <div class=" w-100 ">{{workspace?.name}}</div>

                    </div>
                    <div class=" mt-2"  *ngIf="workspace?.product?.name"> 
                            <div class="font-weight-bold ">Producto</div>
                            <div class=" ">{{workspace.product.name}}</div>
    
                    </div>

                    <div class=" mt-2" *ngIf="workspace?.plan?.name"> 
                                <div class=" font-weight-bold ">Plan</div>
                                <div class=" w-100 ">{{workspace?.plan?.name}}</div>
        
                    </div>


                    <div class=" mt-2" *ngIf="workspace?.agency?.name"> 
                            <div class=" font-weight-bold ">Agencia</div>
                            <div class=" w-100 ">{{workspace.agency.name}}</div>
    
                </div>


                <div class=" mt-2"  *ngIf="workspace?.leadership?.name"> 
                        <div class=" font-weight-bold ">Líder</div>
                        <div class=" w-100 ">{{workspace.leadership.name}}</div>

            </div>


            <div class=" mt-2" *ngIf="workspace?.transactionState"> 
                    <div class=" font-weight-bold ">Estatus Solicitud</div>
                    <div class=" w-100 ">{{(workspace.transactionState.name)}}</div>

             </div>


            <div class=" mt-2" *ngIf="workspace?.status"> 
                        <div class=" font-weight-bold ">Estatus Espacio</div>
                        <div class=" w-100 ">{{(workspace.status==='ACTIVE'?'Activo': 'Ináctivo')}}</div>

            </div>
                            
             </div>
    </ng-template>
</ng-container>
