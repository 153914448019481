import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from '@akeela/properties';
import {
  AkeelaWorkflowService,
  AkeelaStageService,
  AkeelaStepService,
  AkeelaSectionService
} from '@akeela/workflow';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs';
import { LocalService } from '@akeela/local-storage';
// import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { Table, Row, Column } from '@akeela/ak-table';


@Component({
  selector: 'app-section-fiscal-directions',
  templateUrl: './section-fiscal-directions.component.html',
  styleUrls: ['./section-fiscal-directions.component.css']
})
export class SectionFiscalDirectionsComponent implements OnInit, OnDestroy {

  workflow;
  stage;
  step;
  transactionNumber;
  transactionData;
  itemList: any;
  queryParams;
  readonlyMode = false;
  subscriptions: Subscription[] = [];
  role;
  showLoading = false;
  configuration_table: Table;
  paramsSubscription: Subscription;


  
  private transactionDataSubscription: Subscription;
  private workflowEventsSubscription: Subscription;
  private requestSubscription: Subscription;

  constructor(
    public _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private route: ActivatedRoute,
    private akeelaSectionService: AkeelaSectionService,
    private i18n: I18nPipe,
    private localStorage: LocalService

  ) { }

  ngOnInit() {
    this.localStorage.removeItem('excludeOptionsDirectionsOnly');
    this.akeelaWorkflowService.worflowEvents$.next(null);
    this.role = this.localStorage.getItem('role');

    this.subscriptions.push(this.akeelaStepService.currentStepMode$.subscribe((readonly) => {
      this.readonlyMode = readonly;
    }));


    this.workflow = this.akeelaWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akeelaStageService.currentStage$.getValue();
    this.step = this.akeelaStepService.currentStep$.getValue();
    this.transactionData = this.akeelaWorkflowService.transactionData$.getValue();

    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    }));

    this.getItemList();

    this.subscriptions.push(this.akeelaWorkflowService.worflowEvents$.subscribe((response: any) => {
      if (response && (response.action === 'briefcase_pe$fiscal_address$post' || response.action === 'account$client_identification_fiscal_address$post')) {
        
              this.getItemList();
        } 
      
    }));

  }


  ngOnDestroy() {
    if (this.subscriptions) {
      // prevent memory leak when component destroyed
      this.subscriptions.forEach(s => s.unsubscribe());
    }
    if (this.transactionDataSubscription) {
      this.transactionDataSubscription.unsubscribe();
    }
    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  getItemList() {
    this.localStorage.removeItem('excludeOptionsDirections');
    const params = {
      name: 'briefcase_pe$fiscal_addresses$get',
      params: {
        request: {
          idPerson: this.localStorage.getItem('clientIdentifier')    

        },
        path: {
        },
        body: {}
      }
    };
    this.itemList = [];
    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.itemList = response;

      this.itemList?.sort(function (a, b) {
        return a.addressType.identifier - b.addressType.identifier;
      });

      this.setConfigurationTable();
      if (this.itemList.length === 0) {
        this.akeelaWorkflowService.setResetEstatus(true);
      } //else {


      //Mario: Comento el inhabilitar valor de lista
      for (let item of this.itemList) {
        if (Number(item.addressType.identifier) === 1) {
          this.localStorage.setItem('excludeOptionsDirectionsOnly', '[1]');
        }
      }

    


      this.setConfigurationTable();
    this.updateFather();

  }, error => {
  this.itemList = [];
}));
  }




/**tabla */

setConfigurationTable() {
  const base: Column[] = this.setHeadTable();
  this.configuration_table = {
    cssCustom: '',
    width: '100%',
    header: {
      cssCustom: 'class-color-title-table-section',
      rows: [{
        backgroundColor: '#fff',
        columns: base
      }],
    },
    body: {
      cssCustom: '',
      rows: this.setContentTable(base)
    },
    footer: {
      cssCustom: '',
      rows: []
    }
  };

}



setContentTable(configuration): Row[] {
  const content: Row[] = [];
  let i = 0;
  for (const row of this.itemList) {
    console.log(row);
    let description =  (!isNullOrUndefined(row.description)?row.description: row.addressLocality.name  ) +", " +  (row.city && row.city.name ) +", " +  (row.state && row.state.name ) +", " + (row.addressCountry && row.addressCountry.description);
    
    i++;
    content.push(
      {
        identifier: row.identifier,
        cssCustom: '',
        enabled: true,
        columns: [

          this.setRowContentTable({
            configuration,
            number: 0,
            html: description ,

          }),
          
          this.setRowContentTable({

            configuration,
            number: 1,
            html: (row.addressType.alternateName),


          }),

          this.setRowContentTable({

            configuration,
            number: 2,
            html: 'Ver',
            cssCustom: 'ak-tabled-row-active text-capitalize'

          }),

          this.setRowContentTable({
            configuration,
            number: 3,
            html: '<i class="fa fa-trash" title="Eliminar" aria-hidden="true"></i>',
            click: 'delete_item',
          })
        ]
      }
    );
  }
  return content;
}

table_response(response) {
  if (response.action === 'open') {

    let row = this.itemList.find((item: any) => {
      return item.identifier === response.object.identifier;
    });
    this.openItem(row);

  } else if (response.action === 'action') {
    if (response.object.action === 'delete_item' && !this.readonlyMode) {

      let row = this.itemList.find((item: any) => {
        return item.identifier === response.object.obj.identifier;
      });
      this.confirmDelete(row);
    }
  }

}

updateFather() {
  const self = this;
  const modalFather = self.akeelaSectionService.getModalFather();
  if (!isNullOrUndefined(modalFather)) {
    if (!isNullOrUndefined(modalFather.person)) {
      const fatherToUpdate = this.itemList.find(
        (_father) => (!isNullOrUndefined(_father.person)) && (_father.person.identifier === modalFather.person.identifier)
      );
      if (!isNullOrUndefined(fatherToUpdate)) {
        self.akeelaWorkflowService.worflowEvents$.next({
          response: fatherToUpdate,
          action: 'update_legal_representative_pep'
        });
      }
    }
  }
}

openItem(row?) {

    
  if(row){

    this.getAddress(row).then((resp) => {
      if (!isNullOrUndefined(resp)) {
          console.log(row);
      
      //  this.localStorage.setItem('modalFormModeUpdate', 'organization.address.addressType.identifier'); // le indico a wf, que está activado el modo de edición de un modal
  
        this.akeelaSectionService.addModalSection({ name: 'FISCAL_ADDRESS_MODAL' });
        this.akeelaSectionService.showModalSection('FISCAL_ADDRESS_MODAL');
        this.akeelaSectionService.setModalFormValue('FISCAL_ADDRESS_MODAL', resp['organization']);
      }
    });
  }else{
    

  row = {};

  this.akeelaSectionService.addModalSection({ name: 'FISCAL_ADDRESS_MODAL' });
  this.akeelaSectionService.showModalSection('FISCAL_ADDRESS_MODAL');
  this.akeelaSectionService.setModalFormValue('FISCAL_ADDRESS_MODAL', row);
  }
}

getAddress(row) {
  return new Promise((resolve, reject) => {
    const params = {
      name: 'briefcase$fiscal_address$get',
      params: {
        path: {
        },
        request: {
          idAddress: row.identifier,

        }
      }
    };
    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      resolve(response);
    }, error => {
      resolve(null);
    }));
  });
}


confirmDelete(row: any) {
  swal.fire({
    title: this.i18n.transform('@i18n-popup-text-delete-question'),
    text: this.i18n.transform('@i18n-popup-text-delete-question-text'),
    showCancelButton: true,
    confirmButtonText: this.i18n.transform('@i18n-popup-text-yes'),
    cancelButtonText: this.i18n.transform('@i18n-popup-text-no'),
    reverseButtons: true
  }).then((result) => {
    if (!isNullOrUndefined(result.value) && result.value) {
      this.deleteItem(row);
    }
  });
}

deleteItem(row: any) {
  const fiscalAddressId = row?.identifier;
  const configuration = {
    name: 'briefcase_pe$fiscal_address$delete',
    params: {
      path: {
      },
      request: {
        idAddress: fiscalAddressId,
        stepCode:'BUSINESS_IDENTITY',
        role: this.localStorage.getItem('role'),
        audience: this.localStorage.getItem('audience')
      }
    }
  };

  this.subscriptions.push(this._httpService.executeRequest(configuration.name, configuration.params).subscribe((response) => {
    this.localStorage.removeItem('excludeOptionsDirections');
    this.localStorage.removeItem('excludeOptionsDirectionsOnly');

    this.updateList('account$client_identification_fiscal_address$post');
    this.akeelaWorkflowService.setResetEstatus(true);

  }, error => {
    // dfutrille esto debe borrarse.
    this.localStorage.removeItem('excludeOptionsDirectionsOnly');

    this.updateList('account$client_identification_fiscal_address$post');
  }));
}

updateList(action: string) {
  this.akeelaWorkflowService.worflowEvents$.next({
    response: {},
    action: action
  });
}



  setHeadTable(){
    
    const base: Column[] = [

      {
        alignContent: 'left',
        fixed: false,
        minWidth: '100',
        html: this.i18n.transform('Dirección'),
        cssCustom: '',

      },

      {
        alignContent: 'left',
        fixed: 'right',
        maxWidth: '200',
        minWidth: '100',
        html: 'Tipo',
        cssCustom: '',

      },  

      {
        alignContent: 'left',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html:'',
        cssCustom: ''
      },
     
      {
        alignContent: 'center',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html:'',
        cssCustom: ''
      }
  ]
    return base;
  }
  


  setRowContentTable(row){
    
    let configuration = row.configuration;
    let number = row.number;
    let html = row.html;
    let click = row.click?row.click:null ;
    let dropdown = row.dropdown?row.dropdown:null ;
    let cssCustom = row.cssCustom?row.cssCustom:configuration[number].cssCustom;


let result = {
      
      alignContent: configuration[number].alignContent,
      fixed: configuration[number].fixed,
      maxWidth: configuration[number].maxWidth,
      minWidth: configuration[number].minWidth,
      html: html,
      click:click,
      dropdown:dropdown,
      cssCustom: cssCustom,
      enableLeftBorder: !isNullOrUndefined(configuration[number].enableLeftBorder) ? configuration[number].enableLeftBorder : false,
      enableRightBorder: !isNullOrUndefined(configuration[number].enableRightBorder) ? configuration[number].enableRightBorder : false
    }
  
return result;

  }
  


}


