import { Component, OnInit, EventEmitter, Output, Input, HostListener } from '@angular/core';
import swal from 'sweetalert2';
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-filter-order',
  templateUrl: './filter-order.component.html',
  styleUrls: ['./filter-order.component.css']
})
export class FilterOrderComponent implements OnInit {
  @Input() filter;
  @Output() response = new EventEmitter();

  constructor(public readonly swalTargets: SwalPortalTargets) {
  }

  ngOnInit() {
  }

  applyFilter(event: any) {
    swal.close();
    this.filter.active = event.id;
    this.filter.object[event.id].movement = event.action;
    this.response.emit(event);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    swal.close();
  }

}
