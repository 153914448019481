<!-- <pre>{{ historyData | json }}</pre> -->

<app-template>
    <ng-template app-content componentName="mainAssignContentModalApp">

        <div class="full-modal-wf-full" *ngIf="objModal">
            <div class="header">
                <div class="d-flex bd-highlight pr-2 pl-2 pb-2">
                    <div *ngIf="!sizeMenuLeft" class=" pt-1">
                        <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>
                    </div>
                    <div class="text-secondary  pr-0 pl-md-3 flex-grow-1 bd-highlight">
                    </div>

                    <div class="  bd-highlight pt-2 ">
                        <a class=" text-dark" href="javascript:void(0)">
                            <i class="fas fa-times active-cursor fa-2x" (click)="close()"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="body container-fluid mb-4">

                <div class="text-secondary  pr-0  ">
                    <h4 class=" pt-3 pl-3" [innerHTML]=" 'Asignar ejecutivo' | i18n"></h4>
                </div>
                <br>

                <div class="row mb-3 ">
                    <div class="col-12 text-primary col-md-4">
                        <div class="d-inline-flex w-100">
                            <input [(ngModel)]='fieldSearchEjecutive' class="form-control active-cursor padding-filter-searh-input" type="text" placeholder='Buscar coincidencia en la tabla' (keyup)="updateFilterEjecutive ($event.target.value)" />
                            <i class="fas fa-search icon-filter-searh"></i>

                        </div>

                    </div>
                </div>


                <ngx-datatable #table class="material list-ejecutive" columnMode="force" (activate)="onActivateEjecutive($event)" [sorts]="[{prop: 'nombre'}]" [footerHeight]="50" rowHeight="auto" [limit]="200" [rows]="rows_executive" [scrollbarH]="true" [messages]="{
                              emptyMessage: ' No se encontraron resultados.',
                              totalMessage: 'total',
                              selectedMessage: 'selected'
                              }">

                    <ngx-datatable-column prop="nombre" name="Nombre">
                        <ng-template ngx-datatable-cell-template let-row="row">

                            <div class=" DomainUserAvatar-avatoptar Avatar--color-{{akinitial_executive[row.identifier]?.initial}}  AvatarPhoto AvatarPhoto--small" role="img">{{akinitial_executive[row.identifier]?.initials}}</div>
                            <div class="d-inline  ml-2"> {{row.nombre}}</div>

                        </ng-template>
                    </ngx-datatable-column>



                    <ngx-datatable-column prop="agencia" name="Agencia"></ngx-datatable-column>



                    <ngx-datatable-column>
                        <ng-template ngx-datatable-cell-template let-row="row">
                            <div class="text-primary active-cursor text-right">Asignar</div>
                        </ng-template>
                    </ngx-datatable-column>

                </ngx-datatable>
            </div>
        </div>

    </ng-template>
</app-template>






<app-template>
    <ng-template app-content componentName="mainContentModalApp">


        <div class="capa-menu-2" *ngIf="buttomModal" (click)="buttomModal =!buttomModal"> </div>
        <div [ngClass]="{'d-none':animate_init_bottom,'animate__slideInDown':buttomModal,animate__slideOutUp:!buttomModal } " class="  animate__animated animate__faster ak-modal-top p-2 ">

            <div class="body pb-3  pt-3 " style="overflow:auto;">
                <div>
                    <div class="container text-center">

                        <div class="row">

                            <div class="col-12 col-md-6 offset-md-3">

                                <ng-container *ngIf="profile === 'SALES_MANAGER'">
                                    <ng-container *ngIf="listOptionEjecutive">
                                        <ng-container *ngFor="let lst of listOptionEjecutive; let i_elem = index">



                                            <ng-container *ngIf="(!rowSelected?.confirm  &&  lst.obj.option == 'email_verification_user')  || (lst.obj.option != 'email_verification_user' )">

                                                <div class="mb-3">

                                                    <button (click)="response_action({identifier:rowSelected?.identifier , action:lst.obj.option })" class="btn btn-outline-secondary btn-block mt-2 mb-3 ">
                                                        {{ lst.value }}
                                                    </button>

                                                </div>

                                            </ng-container>

                                        </ng-container>
                                    </ng-container>


                                </ng-container>

                                <ng-container *ngIf="profile === 'ANALIST' ">


                                    <ng-container *ngFor="let lst of listOptionAnalist; let i_elem = index">

                                        <ng-container *ngIf="(!rowSelected?.confirm  &&  lst.obj.option == 'email_verification_user')  || (lst.obj.option != 'email_verification_user' )">

                                            <div class="mb-3">

                                                <button (click)="response_action({identifier:rowSelected?.identifier , action:lst.obj.option })" class="btn btn-outline-secondary btn-block mt-2 mb-3 ">
                                                    {{ lst.value }}
                                                </button>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>






        <div class="capa-menu-2" *ngIf="openFilter" (click)="openFilter =!openFilter"> </div>

        <div class="">

            <div class="animate__animated  animate__faster   ak-modal-right p-2" [ngClass]="{'d-none':animate_init,'animate__slideInRight':openFilter,animate__slideOutRight:!openFilter } ">

                <div class="cl" style="height: 30px !important;">

                    <span class="float-right active-cursor" (click)="togle_window();">
                        <i class="text-secondary fas fa-times " style="font-size: 1.2rem;"></i>
                    </span>
                </div>


                <div class="body pb-4 pt-2 " style="overflow:auto;">



                    <div class="d-block  pb-5 " style="max-height: 100%; background: #fcfcfc">


                        <div *ngIf="filterBy" class="pb-5">

                            <div class=" font-weight-bold mb-4">
                                <i class="fas fa-filter iconsearch mr-1"></i> Filtrar</div>
                            <span *ngIf=" rows?.length > 0" class="text-primary fz-14 ">Resultados
                                <span class="font-weight-bold"> {{ rows?.length}} </span>
                            </span>


                            <div class="row p-0 m-0 ">
                                <div class="col-11  p-0 m-0">

                                    <div class="row">

                                        <!-- <div class="col-6 mt-3">
                                            <span class="label">Desde</span>
                                            <input onkeydown="return false" [(ngModel)]='formsData.fecha.dateInit' class="form-control" type="date" placeholder="Desde - 01/06/2021" (change)="setFilterMultiple()" />

                                        </div>
                                        <div class="col-6 mt-3 ">
                                            <span class="label">Hasta</span>

                                            <input onkeydown="return false" [(ngModel)]='formsData.fecha.dateEnd' class="form-control" type="date" placeholder="Hasta - 30/6/2021" (change)="setFilterMultiple()" />
                                        </div> -->


                                        <div class="col-12 mt-4 ">
                                            <span (change)="setFilterMultiple()" class=" label">Ejecutivo</span>
                                            <select [(ngModel)]='formsData.ejecutivo.key' class="form-control search withoutorder" name="status" (change)=" setFilterMultiple()">
                                                <option value='' [selected]="true">--</option>

                                                <ng-container *ngFor="let executive of executivesOptions; let i = index">

                                                    <option [value]='executive?.value'>{{executive?.label}} ({{executive?.value}})</option>

                                                </ng-container>

                                            </select>



                                        </div>


                                        <div class="col-12 mt-4">

                                            <div class="label">Tipo de persona </div>


                                            <div class="row">


                                                <div class="col-6 mt-3">
                                                    <button [ngClass]="{'btn-secondary': formsData.tipo.key == 'NATURAL','btn-outline-secondary': formsData.tipo.key !== 'NATURAL' }" class="btn  btn-block" (click)="formsData.tipo.key = 'NATURAL' ;setFilterMultiple()">
                                                        <i class="fas fa-user mr-2"></i>Natural</button>

                                                </div>
                                                <div class="col-6 mt-3">
                                                    <button [ngClass]="{'btn-secondary': formsData.tipo.key == 'LEGAL','btn-outline-secondary': formsData.tipo.key !== 'LEGAL'}" class="btn  btn-block" (click)="formsData.tipo.key = 'LEGAL' ;setFilterMultiple()">
                                                        <i class="fas fa-building mr-2"></i>Juridica</button>

                                                </div>
                                            </div>


                                        </div>

                                        <!-- <div class="col-12 mt-4">
                                            <div class="label">Email verificado </div>


                                            <div class="row">


                                                <div class="col-4 mt-3">
                                                    <button [ngClass]="{'btn-secondary': formsData.confirm.key === 'true','btn-outline-secondary': formsData.confirm.key !== 'true' }" class="btn  btn-block" (click)="formsData.confirm.key = 'true' ;setFilterMultiple()">Si</button>

                                                </div>
                                                <div class="col-4 mt-3">
                                                    <button [ngClass]="{'btn-secondary': formsData.confirm.key === 'false','btn-outline-secondary': formsData.confirm.key !== 'false'}" class="btn  btn-block" (click)="formsData.confirm.key = 'false' ;setFilterMultiple()">No</button>

                                                </div>
                                            </div>

                                        </div> -->

                                        <!-- 
                                        <div class="col-12 mt-4">

                                            <div class="label ">Verificación de identidad
                                                <b>completada</b>
                                            </div>



                                            <div class="row">
                                                <div class="col-4 mt-3">
                                                    <button [ngClass]="{'btn-secondary': formsData.completeIdentification.key === 'true','btn-outline-secondary': formsData.completeIdentification.key !== 'true' }" class="btn  btn-block" (click)="formsData.completeIdentification.key = 'true' ;setFilterMultiple()">Si</button>

                                                </div>
                                                <div class="col-4 mt-3">
                                                    <button [ngClass]="{'btn-secondary': formsData.completeIdentification.key === 'false','btn-outline-secondary': formsData.completeIdentification.key !== 'false'}" class="btn  btn-block" (click)="formsData.completeIdentification.key = 'false' ;setFilterMultiple()">No</button>

                                                </div>
                                            </div>

                                        </div>

                                        <div class="col-12 mt-4">

                                            <div class="label ">verificación de identidad
                                                <b>aprobada</b>
                                            </div>



                                            <div class="row">
                                                <div class="col-4 mt-3">
                                                    <button [ngClass]="{'btn-secondary': formsData.verifiedIdentification.key === 'true','btn-outline-secondary': formsData.verifiedIdentification.key !== 'true' }" class="btn  btn-block" (click)="formsData.verifiedIdentification.key = 'true' ;setFilterMultiple()">Si</button>

                                                </div>
                                                <div class="col-4 mt-3">
                                                    <button [ngClass]="{'btn-secondary': formsData.verifiedIdentification.key === 'false','btn-outline-secondary': formsData.verifiedIdentification.key !== 'false'}" class="btn  btn-block" (click)="formsData.verifiedIdentification.key = 'false' ;setFilterMultiple()">No</button>

                                                </div>
                                            </div>

                                        </div> -->

                                        <!-- <div class="col-12 mt-4">

                                            <div class="label ">Con solicitudes </div>
                                            <div class="row">
                                                <div class="col-4 mt-3">
                                                    <button [ngClass]="{'btn-secondary': formsData.solicitudes.key === 'true','btn-outline-secondary': formsData.solicitudes.key !== 'true' }" class="btn  btn-block" (click)="formsData.solicitudes.key = 'true' ;setFilterMultiple()">Si</button>

                                                </div>
                                                <div class="col-4 mt-3">
                                                    <button [ngClass]="{'btn-secondary': formsData.solicitudes.key === 'false','btn-outline-secondary': formsData.solicitudes.key !== 'false'}" class="btn  btn-block" (click)="formsData.solicitudes.key = 'false' ;setFilterMultiple()">No</button>

                                                </div>
                                            </div>
                                        </div> -->

                                    </div>

                                </div>

                            </div>

                        </div>


                        <div *ngIf="orderBy">
                            <div class=" font-weight-bold mb-4">
                                <i class="fas fa-sort iconsearch mr-1"></i> Ordenar</div>


                            <div class="row">

                                <div class="col-12 mt-3">
                                    <div class="label mb-3">Tiempo de respuesta </div>

                                    <button [ngClass]="{'btn-secondary': timeReponseDesc,'btn-outline-secondary': !timeReponseDesc}" class="btn " (click)="orderAscDesc(true)">
                                        <i class="fas fa-sort-amount-down-alt mr-2"></i>Descendente</button>

                                    <button [ngClass]="{'btn-secondary': !timeReponseDesc,'btn-outline-secondary': timeReponseDesc}" class="btn  ml-3" (click)="orderAscDesc(false)">
                                        <i class="fas fa-sort-amount-up-alt mr-2"></i>Ascendente</button>


                                </div>
                            </div>

                        </div>


                    </div>
                </div>

            </div>

        </div>


    </ng-template>
</app-template>

<app-transaction-detail *ngIf="transactionDetail" [selected]="transactionDetail" [transaction]="transactionSelected" (change)="setTransactionDetail($event)"></app-transaction-detail>
<app-client-detail [selected]="clientDetail" [username]="clientSelected" (change)="setClientDetail($event)"></app-client-detail>


<div class="mt-1">

    <div class="jumbotron jumbotron-fluid p-3 mb-2 ">

        <div class="d-flex bd-highlight">

            <div>
                <h5 class="font-weight-normal">
                    <i class="fas fa-users pr-1"></i> Clientes
                </h5>
            </div>

            <div class="ml-auto">
                <button *ngIf="!isAnalist " (click)="newContact() " class="btn btn-white active-cursor mr-2">
                    <i class="fas fa-plus pr-1"></i>
                    Nuevo cliente
                </button>

                <i class="fas fa-ellipsis-v "></i>
            </div>
        </div>

    </div>

    <div class="w-100 pl-0 mt-4 animate__animated animate__fadeIn" *ngIf="!client_indicators?.loading">

        <div class="d-flex bd-highlight  flex-wrap">

            <div class="w-60  mt-1 mt-md-2  w-sm-100 pr-0 pr-md-3">

                <div class="   card mb-2  boxstatus2 active-cursor">
                    <div class="card-body pl-3 pr-3 pt-2 pb-0 ">
                        <div class="d-flex bd-highlight  flex-wrap pt-1 pb-2 ">

                            <div class="d-flex bd-highlight  w-100 flex-wrap ">
                                <div class="w-30 " (click)="getconfirmEmail()">
                                    <div class="w-100 fz-14 h-sm-45">
                                        <span class="font-weight-normal text-secondary "> Sin verificar email</span>
                                    </div>
                                    <div>
                                        <span class="font-weight-bold text-secondary"> {{client_indicators?.notConfirm}}</span>
                                    </div>

                                </div>
                                <div class="w-40" (click)="getCompleteIdentification()">
                                    <div class="w-100 fz-14 h-sm-45">
                                        <span class="font-weight-normal text-secondary "> Sin verificación
                                            <br class="d-block d-md-none"> de identidad</span>

                                    </div>
                                    <div>
                                        <span class="font-weight-bold text-secondary"> {{client_indicators?.notVerif}}</span>
                                    </div>

                                </div>
                                <div class="w-30" (click)="getSinSolicitudes()">
                                    <div class="w-100 fz-14 h-sm-45">
                                        <span class="font-weight-normal text-secondary ">Sin solicitud </span>
                                    </div>
                                    <div>
                                        <span class="font-weight-bold text-secondary"> {{client_indicators?.notTransaction}}</span>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

    <div class="row   pt-4 mb-4 ">
<!-- Nombre, Apellido -->
        <div class="col-12 text-primary col-md-7 mb-3 ">
            <input (keyup.enter)="updateFilter (fieldSearch) " [disabled]="loadingIndicator?true:false" [(ngModel)]='fieldSearch' class="form-control active-cursor padding-filter-searh-input " type="text " placeholder='CI, Email, Nombre, Apellido' />
            <i *ngIf="!loadingIndicator" (click)="updateFilter(fieldSearch) " class="text-primary fas fa-search icon-filter-searh active-cursor"></i>
            <img *ngIf="loadingIndicator" class="icon-filter-searh  icon-filter-searh-load " title="Buscar " src="./assets/public/images/loading.gif " alt=" "></div>


        <!--
        <div *ngIf="isAkeela()" class=" text-left  col-12 text-primary col-md-5 mb-3 ">
            <div class="float-right pt-1 pt-md-0 pb-md-0 ml-md-2 ">
                <button (click)="redirectGuest()" class="d-none d-md-block btn btn-outline-primary ">
                    Ir a Inviados
                </button>
            </div>

        </div>
 -->

        <!--
        <div class="col-12">
            <div class="text-left pt-1 pt-md-0 pb-md-0  ">
                <div class="text-left pt-1 pt-md-0 pb-md-0 mb-2">

                    <button *ngIf="isAkeela()" (click)="redirectGuest()" class=" ml-2 d-md-none btn btn-outline-primary ">
                        Ir a Inviados
                    </button>
                </div>
            </div>
        </div>
 -->
        <div class="col-12 text-primary pt-3 ">
            <div class="d-inline-flex w-100 ">

                <button style="height: 35px !important; " class="btn-light btn " (click)=" togle_window(1);fieldSearch='' ">
                    Filtrar
                    <i class="fas fa-filter iconsearch "></i>
                </button>
                <button *ngIf="cvv" style="height: 35px !important; " class="btn-light btn ml-2" (click)=" setViewsExport()">
                    Exportar
                    <i class="fas fa-sticky-note iconsearch "></i>
                </button>

                <!-- <button style="height: 35px !important; " class="btn-light ml-2 btn ml-auto " (click)=" togle_window(2);fieldSearch='' ">
                    <i class="fas fa-sort iconsearch "></i> Ordenar
                </button>
                 -->

            </div>
        </div>
        <div class="col-12 ">
            <div class="w-100 animate__animated animate__fadeInDown " *ngIf="filterEtique.length> 0 ">

                <ul class="scrollmenu pl-0 pt-2 ">
                    <li class="d-inline  mt-2" *ngFor="let row of filterEtique ; let i = index">
                        <div class="item-rd text-secondary fz-14 ">{{row?.label}}
                            <i class="fa fa-times ml-2 active-cursor" (click)="deleteFilter(row?.key,row?.type)" aria-hidden="true"></i>

                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>


    <div class="d-flex bd-highlight mt-3" *ngIf="paginator">

        <div class="">
            <i (click)="setShowFavorites()" class="fa-star mt-1 ml-3 mr-2" [ngClass]="{'text-secondary': !showFavorites,'text-yellow':showFavorites,'far': !showFavorites,'fas':showFavorites}"></i>

            <i *ngIf="!reload_loading" (click)="reloadTransaction()" class="fas fa-redo text-secondary ml-2 active-cursor "></i>
            <span *ngIf="updateDate" class="text-secondary  fz-14 ml-2" [title]="updateDate?.time"> Actualizado
                <span [innerHTML]="updateDate?.value"></span>
            </span>

            <i *ngIf="actives" (click)="actives = false; getClientsPaginator()" class="fas fa-user-alt  fz-14 ml-3 text-secondary"></i>
            <i *ngIf="!actives" (click)="actives = true; getClientsPaginator()" class="fas fa-user-alt-slash  fz-14 ml-3 text-danger"></i>



        </div>

        <div class="text-secondary ml-auto">
            {{ (paginator?.pageable?.to===0)?(paginator?.pageable?.to):(paginator?.pageable?.from) }} - {{ paginator?.pageable?.to }} de
            <b>{{ paginator?.number }} </b>
            <i class="fas fa-chevron-left mr-1 text-secondary active-cursor" [class.active-cursor]="!paginator?.first" [class.disabled]="paginator?.first" (click)="action_paginator('BACK')"></i>
            <i class="fas fa-chevron-right text-secondary " [class.active-cursor]="!paginator?.last" [class.disabled]="paginator?.last" (click)="action_paginator('NEXT')"></i>
        </div>
    </div>
    <hr>


    <ng-container *ngIf="reload_loading">
        <div class=" w-100 mt-2">
            <img src="./assets/public/images/loading.gif" alt="">
        </div>
    </ng-container>

    <div class="pb-3">

        <ng-container *ngFor="let row of rows ; let i = index">
            <div class="card pt-4 border-0 ">

                <div class="card-body p-2 pl-0 pr-0 fz-14 ">

                    <div class="d-flex bd-highlight">
                        <div class="w-5 w-sm-10">

                            <i (click)="setDataHistoryFavorites(row)" class=" fa-star mt-1 text-start mr-2 fz-16" [ngClass]="{'text-secondary': !historyData?.historyFavorites[row?.username],'text-yellow': historyData?.historyFavorites[row?.username],'far': !historyData?.historyFavorites[row?.username],'fas': historyData?.historyFavorites[row?.username]}"></i>
                            <!--
                            <i (click)="setDataHistoryFavorites(row)" class=" fa-comment mt-1 text-secondary mr-2 fz-16" [ngClass]="{'far': !historyData?.historyFavorites[row?.username],'fas': historyData?.historyFavorites[row?.username]}"></i>
 -->
                        </div>
                        <div class="w-85  w-sm-85">
                            <div class="d-flex bd-highlight  flex-wrap active-cursor" (click)="setClient(row?.identifier)">

                                <div class="w-33 w-sm-100  d-flex bd-highlight  list-flex flex-wrap">

                                    <div class="bd-highlight w-15">
                                        <div (click)="$event.stopPropagation();getDocumentClient(row?.username,'SELFIE')" *ngIf="!fileAvatarPreview[row?.username]" class="DomainUserAvatar-avatar text-dark AvatarPhoto--client Avatar--color-{{akinitial[row.identifier]?.initial}}  AvatarPhoto AvatarPhoto--small"
                                            role="img">
                                            {{akinitial[row.identifier]?.initials}}
                                            <div *ngIf="row?.tipo == 'NATURAL'" class="huella">
                                                <i class="fas fa-fingerprint" [ngClass]="{'text-success': row?.verifiedIdentification ,'text-primary':row?.completeIdentification &&  !row?.verifiedIdentification}"></i>
                                            </div>
                                        </div>

                                        <div (click)="$event.stopPropagation();getDocumentClient(row?.username,'SELFIE')" style="float: left; border: 1px solid #ddd; background: url({{fileAvatarPreview[row?.username]}}) no-repeat center" class=" animate__animated animate__fadeIn content-avata-img2 mr-1"
                                            *ngIf="fileAvatarPreview[row?.username]">
                                            <div class="huella2">
                                                <i class="fas fa-fingerprint" [ngClass]="{'text-success': row?.verifiedIdentification ,'text-primary':row?.completeIdentification &&  !row?.verifiedIdentification}"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bd-highlight w-85 pl-2">
                                        <div class="font-weight-bold active-cursor text-capitalize fz-14 ">


                                            <i [ngClass]="{'text-danger ': row?.status == 'INACTIVE','text-secondary ': row?.status == 'ACTIVE'}" *ngIf="row?.tipo != 'NATURAL'" class="fz-14 fas fa-building pr-1"></i>
                                            <i [ngClass]="{'text-danger ': row?.status == 'INACTIVE','text-secondary ': row?.status == 'ACTIVE'}" *ngIf="row?.tipo == 'NATURAL'" class="fz-14 fas fa-user  pr-1"></i>

                                            <span>{{row.nombre}}</span>
                                            <span *ngIf="usersTransactions[row?.username]?.completeBriefcase">
                                                <i *ngIf="!usersTransactions[row?.username]?.verifiedBriefcase" class=" pl-1 text-success  fas fa-check mt-1"></i>
                                                <i *ngIf="usersTransactions[row?.username]?.verifiedBriefcase" class=" pl-1 text-success small fas fa-check-double mt-1"></i>
                                            </span>



                                        </div>
                                        <div class="text-secondary  ">{{row?.doc}}
                                        </div>

                                        <div class="text-secondary  ">
                                            {{row?.fecha}}
                                        </div>



                                    </div>
                                </div>


                                <div class="w-33 w-sm-100 mt-3 mt-md-0">
                                    <div *ngIf="row.telephone || row.otherPhone || row.correo" class="d-flex bd-highlight  flex-wrap">
                                        <div class="w-15 text-center ">
                                            <i class="fa fa-phone-alt text-secondary mr-2 "></i>
                                        </div>

                                        <div class="w-85">
                                            
                                            


                                            <div class=" " *ngIf="row?.otherPhone">
                                                <a (click)="gotoWhatsapp($event,row?.otherPhone);" href="javascript:void(0) " class="text-primary ">
                                                    {{row?.otherPhone}} <i class="fab fa-whatsapp pl-1"></i>
                                                </a>
                                            </div>

                                            <div class="text-capitalize ">{{row.telephone}}</div>

                                            <div class="">{{row.correo}}
                                                <i *ngIf="row?.confirm" class="text-success small fas fa-check "></i>
                                            </div>

                                        </div>


                                    </div>

                                    <div class="d-flex bd-highlight  flex-wrap mt-2">
                                        <div class="w-15 text-center">
                                            <i class="fa fa-users text-secondary mr-2 "></i>

                                        </div>

                                        <div class="w-85">
                                            {{row?.ejecutivo}}
                                        </div>


                                    </div>

                                </div>
                                <div class="w-33 w-sm-100 mt-3 mt-md-0">
                                    <div class="d-flex bd-highlight  flex-wrap">

                                        <div class="w-100">
                                            <!-- <pre>{{ usersTransactions[row?.username]?.transaction | json }}</pre> -->
                                            <div style="width: 250px;" class="bat-listc mt-2 m-1 badge-primary-ak fz-12 p-2" *ngIf="usersTransactions[row?.username]?.transaction">
                                                <div class="font-weight-bold" [innerHTML]="usersTransactions[row?.username]?.transaction?.plan?.name | i18n"></div>

                                                {{usersTransactions[row?.username]?.transaction?.plan.shortDescription}}

                                                <i *ngIf="usersTransactions[row?.username]?.transaction?.accountStatus[0]?.stateInWorkFlow?.code == 'AC_RECEIVED'" class="float-right pl-1 text-success   fas fa-check-double  "></i>

                                            </div>


                                        </div>


                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="w-10">
                            <div class="text-right text-primary ">
                                <a (click)="$event.stopPropagation();setViewsNote({user: row?.username}) " class="activate-cursor">
                                    <i class=" mr-1 fas fa-comment"></i>
                                </a>
                                <i [title]="'Opciones'" (click)="togle_botton_window(row)" class="pr-1 ml-2 fas fa-list text-secondary active-cursor"></i>
                            </div>

                            <!--<ng-container *ngTemplateOutlet="menu; context:{row:row}">
                            </ng-container>-->
                        </div>

                    </div>




                </div>
            </div>

        </ng-container>
    </div>

    <ng-container *ngIf="!loading_table && !reload_loading">
    <div class="pb-3 pt-5 text-center" *ngIf="rows.length===0">
        NO SE ENCONTRARON CLIENTES<br><br><br>
        <hr>
    </div>
    </ng-container>


    <!-- spinner -->
    <!-- <div class="row content-paginator m-t-1" *ngIf="loading_table">
        <i class="fas fa-spinner fa-spin"></i>
    </div> -->

    <!-- paginator -->
    <div class="row content-paginator m-t-1 disabled" *ngIf="paginator && !loading_table">
        <akeela-paginator [paginator]="paginator" (response)="action_paginator($event)" *ngIf="!loading_table">
        </akeela-paginator>
    </div>


</div>


<ng-container *ngIf="loading_table">
    <img src="./assets/public/images/loading.gif" alt="">
</ng-container>