import { AuthService, ErrorService } from "@akeela/auth";
import { UploadService } from "@akeela/common";
import { LocalService } from "@akeela/local-storage";
import { AkeelaSidebarMenuService } from "@akeela/menu";
import { HttpService } from "@akeela/properties";
import { AkeelaWorkflowService } from "@akeela/workflow";
import { DatePipe } from "@angular/common";
import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";
import { PropertiesService } from "src/app/services";
import { WorkflowService } from "src/app/services/workflow.service";
import { environment } from "../../../../../environments/environment";

import swal from "sweetalert2";
import { I18nPipe } from "@akeela/i18n";
import { Subscription } from "rxjs";
import { NotesService } from "src/app/services/notes.service";

@Component({
  selector: "app-transaction-dashboard",
  templateUrl: "./transaction-dashboard.component.html",
  styleUrls: ["./transaction-dashboard.component.css"],
})
export class TransactionDashboardComponent implements OnInit, OnDestroy {
  indicatorsProgres = {};
  transactionData;
  listWf = {};
  download_file = false;

  preview: any = null;
  tmpAcept = true;
  documents: any = [];
  formSheet;
  dataInformation;
  loadingCheck = false;
  showModalResponses;
  incomplete;
  clientIdentifier;
  private transactionDataSubscription: Subscription;
  clientActive = "CLIENT_DATA";

  buttomModal;
  animate_init_bottom = true;
  transactionData_subscriber: Subscription;
  optActive;
  isFinalClientData;
  viewOptional = true;

  sendTransactioModal;
  isNatural;
  showLoadingSend;
  disabledForm;

  @Output() close = new EventEmitter();

  constructor(
    private _wfService: WorkflowService,
    private router: Router,
    private _httpService: HttpService,
    private datePipe: DatePipe,
    public _auth: AuthService,
    private localStorage: LocalService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    private akWorkflowService: AkeelaWorkflowService,
    private _upload: UploadService,
    private _properties: PropertiesService,
    private i18n: I18nPipe,
    private _error: ErrorService,
    public _notes: NotesService
  ) {}

  ngOnInit(): void {
    this.localStorage.removeItem("sectionRedirect");

    this.isNatural = this.localStorage.getItem("clientaudience") == "NATURAL";

    this.viewOptional = true;

    this.localStorage.setItem("histotyBak", "bandeja");

    if (this.transactionDataSubscription) {
      this.transactionDataSubscription.unsubscribe();
    }

    if (this.localStorage.getItem("clientaudience") === "NATURAL") {
      this.getDataInformations();
    } else {
      this.getDataInformationsLegal();
    }

    this.transactionDataSubscription =
      this.akWorkflowService.transactionData$.subscribe((response: any) => {
        if (response) {
          this.transactionData = response;

          //  this.clientIdentifier =  this.transactionData?.holder?.person?.identifier;
          this.clientIdentifier =
            this.localStorage.getItem("clientaudience") === "NATURAL"
              ? this.transactionData?.holder?.person?.identifier
              : this.transactionData?.holder?.adminOrganization?.identifier;

          let a = this.localStorage.getItem("clientName");
          let b = this.localStorage.getItem("clientUsername");
          let c = this.localStorage.getItem("clientIdentifier");
          let d = this.localStorage.getItem("clientaudience");

          this.localStorage.setItem(
            "clientName",
            this.transactionData?.holder?.person?.givenName +
              " " +
              this.transactionData?.person?.familyName
          );
          this.localStorage.setItem(
            "clientUsername",
            this.transactionData?.holder?.modifyUser
          );
          this.localStorage.setItem(
            "clientIdentifier",
            this.transactionData?.holder?.identifier
          );
          this.localStorage.setItem(
            "clientaudience",
            this.transactionData?.holder?.additionalType
          );
          this.localStorage.setItem(
            "clientlegalName",
            this.transactionData?.holder?.name
          );

          a = this.localStorage.getItem("clientName");
          b = this.localStorage.getItem("clientUsername");
          c = this.localStorage.getItem("clientIdentifier");
          d = this.localStorage.getItem("clientaudience");

          this.getShowCommentsBooleanButton();

          if (this.transactionData?.holder?.additionalType === "NATURAL") {
            this.getDataInformations();
          } else {
            this.getDataInformationsLegal();
          }
          this.getIndicatorsProgres(response?.plan?.url, response);
          this.getPreview();
        } else {
          if (this.localStorage.getItem("redirectTransation")) {
            this.akWorkflowService.setTransactionNumber(
              this.localStorage.getItem("redirectTransation")
            );
          }
        }
      });

    this._wfService.listWF$.subscribe((listWf: any) => {
      if (listWf) {
        this.listWf = listWf;
      }
    });

    this.akWorkflowService.worflowEvents$.subscribe((response: any) => {
      if (response) {
        if (response.response === "UPDATE_REQUIREMENT_FINISH") {
          this.getIndicatorsProgres(
            this.transactionData.plan.url,
            this.transactionData
          );
        }
      }
    });
  }

  getShowCommentsBooleanButton() {
    if (this.transactionData) {
      if (
        this.transactionData?.accountStatus[0]?.additionalType ===
        "AC_INCOMPLETE"
      ) {
        this.incomplete = true;
      } else {
        this.getHistoryStatusTransaction().then((history: any) => {
          if (history.length >= 2) {
            if (
              history[0].name === "PI_IN_PROCESS" &&
              history[1].name === "AC_INCOMPLETE"
            ) {
              this.incomplete = true;
            }
          }
        });
      }
    }
  }

  getHistoryStatusTransaction() {
    return new Promise((resolve, reject) => {
      const params = {
        name: "accounts$accountstates$get",
        params: {
          request: {},
          path: {
            transactionNumber: this.transactionData.transactionNumber,
          },
          body: {},
        },
      };

      this._httpService
        .executeRequest(params.name, params.params, true)
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(false);
          }
        );
    });
  }

  async getDataInformations() {
    const params = {
      name: "briefcase$person$get",
      params: {
        request: {
          id: this.localStorage.getItem("clientIdentifier"),
        },
        path: {},
        body: {},
      },
    };

    this.dataInformation = await this._httpService
      .executeRequest(params.name, params.params, true)
      .toPromise();
  }

  async getDataInformationsLegal() {
    const params = {
      name: "briefcase$business_person$get",
      params: {
        request: {
          id: this.localStorage.getItem("clientIdentifier"),
        },
        path: {},
        body: {},
      },
    };

    this.dataInformation = await this._httpService
      .executeRequest(params.name, params.params, true)
      .toPromise();
  }

  getListWf(wf) {
    return this.listWf[wf];
  }

  getIndicatorsProgres(workflowCode, transactionData: any = false) {
    let data: any;

    if (workflowCode && transactionData) {
      let transactions = [];
      transactions = [
        ...transactions,
        { transactionNumber: transactionData?.transactionNumber },
      ];
      data = {
        workflow: workflowCode,
        bankAccountTransactions: transactions,
      };
    }
    if (workflowCode && !transactionData) {
      data = {
        workflow: workflowCode,
        personId: this.localStorage.getItem("clientIdentifier"),
      };
    }

    const params = {
      name: "resume$workflow_progress$post",
      params: {
        request: {
          role: this.localStorage.getItem("role"),
          audience: this.localStorage.getItem("clientaudience"),
        },
        path: {},
        body: data,
      },
    };

    this._httpService
      .executeRequest(params.name, params.params, true)
      .subscribe(
        (response: any) => {
          if (response) {
            let progress;
            let preData = response[0]?.modules;

            this.indicatorsProgres = [];
            if (preData.length > 0) {
              preData?.filter((resp: any) => {
                let indicatorsProgres = {};

                progress = resp?.progress;

                let maxValue = progress?.maxValue;
                let minValue = progress?.minValue;

                indicatorsProgres["maxValue"] = maxValue;
                indicatorsProgres["minValue"] = minValue;
                indicatorsProgres["percent"] =
                  minValue > 0 ? this.round((minValue * 100) / maxValue) : 0;

                if (resp?.alternateName == "CLIENT_DATA") {
                  this.isFinalClientData = true;
                }

                if ((resp?.alternateName).includes("CLIENT_DATA")) {
                  this.clientActive = resp?.alternateName;
                }

                if (resp?.progressAdditional) {
                  let req = resp?.progressAdditional;

                  let maxValueReq = req?.maxValue;
                  let minValueReq = req?.minValue;

                  indicatorsProgres["maxValueReq"] = maxValueReq;
                  indicatorsProgres["minValueReq"] = minValueReq;
                  indicatorsProgres["percentReq"] =
                    minValueReq > 0
                      ? this.round((minValueReq * 100) / maxValueReq)
                      : 0;

                  if (maxValueReq < maxValue) {
                    indicatorsProgres["optional"] = maxValue - maxValueReq;
                  } else {
                    indicatorsProgres["optional"] = maxValueReq - maxValue;
                  }
                }

                this.indicatorsProgres[resp?.alternateName] = indicatorsProgres;

                return 0;
              });
            }

            //;

            if (
              (this.transactionData?.accountStatus[0]?.additionalType ==
                "PI_IN_PROCESS" ||
                this.transactionData?.accountStatus[0]?.additionalType ==
                  "AC_INCOMPLETE") &&
              this.getIndicatorsProgresData("DOC_SUPPORT")?.percentReq == 100 &&
              this.getIndicatorsProgresData(this.transactionData.plan.url)
                ?.percent == 100 &&
              this.getIndicatorsProgresData(this.clientActive)?.percent ==
                100 &&
              (this.isFinalClientData
                ? this.dataInformation?.completeBriefcase
                : true)
            ) {
              //juan: activacion de modal de terminos y condiciones
              // this.sendTransactioModal = true;
            } else {
              //  this.sendTransactioModal = false;
            }
          }
        },
        (error) => {}
      );
  }

  getIndicatorsProgresData(workflowCode) {
    return this.indicatorsProgres[workflowCode]
      ? this.indicatorsProgres[workflowCode]
      : false;
  }

  round(value) {
    return Math.round(value);
  }

  openFormalities(requirements = false) {
    let transactionNumber = this.transactionData?.transactionNumber;

    this.deleteEditTransaction();
    this.localStorage.removeItem("transactionModeEditShowFlag");

    this.localStorage.setItem("sectionRedirect", true);
    //this.akWorkflowService.setCurrentWorkflowCodeActive(this.transactionData.plan.url);
    this.akWorkflowService.setCurrentWorkflowCodeActive("PRODUCT_INFORMATION");

    const queryParams = {
      role: this.localStorage.getItem("role"),
      profile: this.localStorage.getItem("profile"),
      audience: this.localStorage.getItem("clientaudience"),
      transactionNumber: transactionNumber,
    };
    let url;
    if (requirements) {
      url = [environment.staticLinks.requirementsUpload];
      //url= ['private/PRODUCT_INFORMATION/stages/PRODUCT_INFORMATION/steps/PRODUCT_INFORMATION'];
      this.router
        .navigateByUrl(
          this.router.createUrlTree(url, {
            queryParams: queryParams,
          })
        )
        .then(() => {});
    } else {
      this.akWorkflowService.setCurrentWorkflowCodeActive(
        this.transactionData?.plan?.url
      );

      //this.akWorkflowService.setCurrentWorkflowCodeActive('PRODUCT_INFORMATION');

      this.akWorkflowService.setTransactionNumber(transactionNumber);

      const queryParams2 = {
        role: this.localStorage.getItem("role"),
        profile: this.localStorage.getItem("profile"),
        audience: this.localStorage.getItem("clientaudience"),
        transactionNumber: transactionNumber,
      };
      url = ["private/" + this.transactionData?.plan?.url];
      //url= ['private/PRODUCT_INFORMATION/stages/PRODUCT_INFORMATION/steps/PRODUCT_INFORMATION'];

      this.router
        .navigateByUrl(
          this.router.createUrlTree(url, {
            queryParams: queryParams2,
          })
        )
        .then(() => {});
    }
  }

  deleteEditTransaction() {
    if (!this.localStorage.getItem("transactionModeEdit")) {
      //this.breakfreeTransactionEdit(); // Mario: este caso era de liberación individual, pero debido a que ahora se va a liberar por usuario, se comenta para ver el comportamiento
      this.breakfreeTransactionsByUser();
    } else {
      this.breakfreeTransactionsByUser();
    }
  }

  breakfreeTransactionsByUser() {
    // alert('voy a ejecuctar 2');

    const configuration = {
      name: "account$finish_editing_role_by_user$put",
      params: {
        path: {},
        request: {
          role: "CLIENT",
          holderId:
            this.localStorage.getItem("audience") === "LEGAL"
              ? this._auth.getAdminOrganizationIdentifier()
              : this.clientIdentifier,
        },
      },
    };
    this._httpService
      .executeRequest(configuration.name, configuration.params)
      .subscribe(
        (resp: any) => {
          this.localStorage.removeItem("transactionModeEdit");
          this.localStorage.removeItem("transactionModeEditShow");
        },
        (error) => {
          this.localStorage.removeItem("transactionModeEdit");
          this.localStorage.removeItem("transactionModeEditShow");
        }
      );
  }

  breakfreeTransactionEdit() {
    // alert('voy a ejecuctar 111111');

    const configuration = {
      name: "account_opening$finish_editing_role$put",
      params: {
        path: {
          transactionNumber: this.localStorage.getItem("transactionModeEdit"),
        },
        request: {
          role: "CLIENT",
          holderId:
            this.localStorage.getItem("audience") === "LEGAL"
              ? this._auth.getAdminOrganizationIdentifier()
              : this.clientIdentifier,
        },
      },
    };
    this._httpService
      .executeRequest(configuration.name, configuration.params)
      .subscribe(
        (resp: any) => {
          this.localStorage.removeItem("transactionModeEdit");
          this.localStorage.removeItem("transactionModeEditShow");
        },
        (error) => {
          this.localStorage.removeItem("transactionModeEdit");
          this.localStorage.removeItem("transactionModeEditShow");
        }
      );
  }

  capitalize(word) {
    if (word && word[0]) {
      return word[0].toUpperCase() + word.slice(1);
    }
    return "";
  }

  show_file(file) {
    const archive = {
      identifier: file.identifier,
      name: file.name,
      dateCreated: {
        orderDate: file.dateCreated.modifiedDate,
      },
    };

    if (archive.identifier !== null) {
      this._upload.visor_params.name = "";
      this._upload.visor_params.date = "";
      this._upload.visor_params.endpoint = "archive$downloadFile$get";
      this._upload.visor_params.archive = archive;
      this._upload.visor = true;
    }
  }

  getPreview() {
    this._properties
      .getProperty({
        endpointKey: "app_properties$getProperty$get",
        propertyKey: "app.display.template",
      })
      .subscribe(
        (resp) => {
          if (resp === true || resp === "true") {
            // Mario: se condiciona para mostrar o no el preview
            const params = {
              name: "account_opening$preview-document$get",
              params: {
                request: {
                  role: this.localStorage.getItem("role"),
                  audience: this.localStorage.getItem("clientaudience"),
                },
                body: {},
                path: {
                  transactionNumber: this.transactionData?.transactionNumber,
                },
              },
            };

            return this._httpService
              .executeRequest(params.name, params.params, true)
              .subscribe(
                (resp) => {
                  this.preview = resp;
                  if (
                    !this.download_file &&
                    this.preview &&
                    this.transactionData.accountStatus[0].additionalType !==
                      "AC_REJECTED" &&
                    this.transactionData.accountStatus[0].additionalType !==
                      "AC_RECEIVED"
                  ) {
                    this.documents["all"] = {};

                    this.documents["all"]["ficha"] = {
                      response: this.preview[0],
                      preview: true,
                    };
                  }

                  if (
                    this.download_file &&
                    environment.appEndPontDefaultConfig.showPayroll &&
                    this.transactionData.accountStatus[0].additionalType !==
                      "AC_REJECTED" &&
                    this.transactionData.accountStatus[0].additionalType !==
                      "AC_RECEIVED"
                  ) {
                    this.documents["all"] = {};

                    this.documents["all"]["ficha"] = {
                      response: this.formSheet[0],
                      preview: false,
                    };
                  }
                },
                (error) => {
                  this.preview = [];
                }
              );
          } else {
            this.preview = null;
          }
        },
        (error) => {}
      );
  }

  show_filePreview(data) {
    this.show_file(data);
  }

  send() {
    swal
      .fire({
        title: this.i18n.transform("@i18n-confirmation-are-you-shure"),
        text: this.i18n.transform(
          "@i18n-confirmation-are-you-shure-send-analisys"
        ),
        showCancelButton: true,
        confirmButtonText: this.i18n.transform(
          "@i18n-quote-option-list-boolean-option-true"
        ),
        cancelButtonText: this.i18n.transform(
          "@i18n-quote-option-list-boolean-option-false"
        ),
        reverseButtons: true,
      })
      .then((result) => {
        if (result?.value) {
          this.showLoadingSend = true;

          this.sendForm();
        }
      });

    //  }
  }

  getRequirements() {
    const params = {
      name: "requirements$transaction_requirements$get",
      params: {
        request: {
          audience:
            this.transactionData.holder &&
            this.transactionData.holder.additionalType
              ? this.transactionData.holder.additionalType
              : "LEGAL",
        },
        path: {
          idPlan: this.transactionData.plan.identifier,
          transactionNumber: this.transactionData.transactionNumber,
          idPerson: this.transactionData.holder
            ? this.transactionData.holder.identifier
            : null,
        },
        body: {},
      },
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  sendForm() {
    this.loadingCheck = true;

    const params = {
      // name: 'account_opening$send_analysis_without_wkf$put',
      name: "account_opening$send_executive_revision$put",

      params: {
        request: {
          role: this.localStorage.getItem("role"),
          audience: this.localStorage.getItem("clientaudience"),
          transactionNumber: this.transactionData?.transactionNumber,
        },
        path: {},
        body: {},
      },
    };

    this._httpService
      .executeRequest(params.name, params.params, true)
      .subscribe(
        (response: any) => {
          this.formSheet = response;
          this.disabledForm = true;
          this.download_file = true;
          this.loadingCheck = false;
          this.akWorkflowService.transactionData$.next(this.transactionData);
          this.showLoadingSend = false;
          this.setNoteSendTransaction(this.transactionData?.transactionNumber);

          this.sendTransactioModal = !this.sendTransactioModal;
        },
        (error: any) => {
          this._error.show(error, " ", error?.error?.messageLabel);
          this.showLoadingSend = false;

          this.loadingCheck = false;
        }
      );
  }

  redirectDashboard(): void {
    const queryParams = {
      role: this.localStorage.getItem("role"),
      profile: this.localStorage.getItem("profile"),
      audience: this.localStorage.getItem("clientaudience"),
    };

    this.router.navigate(["/dashboard"], { queryParams: queryParams });
  }

  getSedCompleatDoc() {
    return (
      this.getIndicatorsProgresData("DOC_SUPPORT") &&
      this.getIndicatorsProgresData("DOC_SUPPORT")?.percent < 100
    );
  }

  getCompleatDoc() {
    return (
      this.getIndicatorsProgresData("DOC_SUPPORT") &&
      this.getIndicatorsProgresData("DOC_SUPPORT")?.percent == 100
    );
  }

  getSedCompleatTransaction() {
    return (
      this.getIndicatorsProgresData(this.transactionData.plan.url) &&
      this.getIndicatorsProgresData(this.transactionData.plan.url)?.percent <
        100
    );
  }

  getCompleatTransaction() {
    return (
      this.getIndicatorsProgresData(this.transactionData.plan.url) &&
      this.getIndicatorsProgresData(this.transactionData.plan.url)?.percent ==
        100
    );
  }

  redirectContinueClient(urlWf = "CLIENT_DATA") {
    this.transactionData;

    //let url = [urlWf];
    let url = [
      "CLIENT_DATA/stages/CLIENT_IDENTIFICATION/steps/PERSONAL_INFORMATION",
    ];
    const prefixed = "private";

    const queryParams = {
      role: this.localStorage.getItem("role"),
      profile: this.localStorage.getItem("profile"),
      audience: this.localStorage.getItem("clientaudience"),
      id: this.transactionData?.holder?.modifyUser,
    };

    this.localStorage.setItem(
      "redirectTransation",
      this.transactionData.transactionNumber
    );
    this.localStorage.setItem("clientActive", this.clientActive);
    this.akWorkflowService.setCurrentWorkflowCodeActive(this.clientActive);

    if (this.transactionDataSubscription) {
      this.transactionDataSubscription.unsubscribe();
    }

    this.akWorkflowService.setTransactionNumber(null);
    this.akWorkflowService.transactionData$.next(null);

    this.localStorage.setItem("sectionRedirect", true);
    //alert(prefixed + '/' + url);
    this.router.navigate([prefixed + "/" + url], { queryParams: queryParams });
  }

  ngOnDestroy() {
    this.sendTransactioModal = false;

    if (this.transactionData_subscriber) {
      this.transactionData_subscriber.unsubscribe();
    }

    if (this.transactionDataSubscription) {
      this.transactionDataSubscription.unsubscribe();
    }
  }

  countDocuments(jsonObject) {
    return Object.keys(jsonObject)?.length > 0;
  }

  sendCompleat = async () => {
    let data: any;

    const params = {
      name: "briefcase$complete-data$post",
      params: {
        request: {
          username: this._auth.getUsername(),
        },
        path: {},
        body: {},
      },
    };

    return await this._httpService
      .executeRequest(params.name, params.params, true)
      .toPromise();
  };

  changes = () => {
    this.buttomModal = false;
  };

  setNoteSendTransaction = async (transacton) => {
    const env: any = environment;

    const data = {
      environment_code: env?.code || env?.theme?.images,
      type: "TRANSACTON",
      show_note: "TRUE",
      note_extra_data_json: "",
      client: this.localStorage.getItem("clientUsername"),
      full_name: this.localStorage.getItem("clientName"),
      ejecutive: this._auth.getUsername(),
      ejecutive_update: "",
      title: "",
      subtitle: "Nueva solicitud enviada",
      status_view: "TRANSACTON_SEND",
      extra_data: "",
      note: this.i18n.transform(`Solicitud enviada #${transacton}`),
    };

    await this._notes.saveNote(data);
  };

  cerrar = () => {
    //this.animate_init_bottom = true;
    this.buttomModal = false;
  };
}
