import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { AuthService } from '@akeela/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import swal from 'sweetalert2';
import { RefreshService } from '../../services/refresh.service';
import { Subscription } from 'rxjs';
import { LocalService } from '@akeela/local-storage';
import { SectionBankAccountOtherInstitutionComponent } from '../section-bank-account-other-institution/section-bank-account-other-institution.component';
import { isNullOrUndefined } from 'util';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-private-resumen',
  templateUrl: './resumen.component.html',
  styleUrls: ['./resumen.component.css']
})
export class ResumenComponent implements OnInit, OnDestroy {

  queryParams;
  transactionData;
  transactionNumber;
  loading = true;
  currenttab;
  subscriptions: Subscription[] = [];
  role;
  env = environment;



  @Input() set setAction(value) {
    this.execute(value);
  }



  constructor(
    private akeelaSectionService: AkeelaSectionService,
    private _httpService: HttpService,
    private _auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private akStepService: AkeelaStepService,
    private akWorkflowService: AkeelaWorkflowService,
    private _breadcrumbService: AkeelaBreadcrumbService,
    private _refresh: RefreshService,
    private localStorage: LocalService

  ) {
    this.currenttab = 'P1';
  }

  ngOnInit() {
    this.akeelaSectionService.addModalSection({ name: 'FORMALIZE_ACCOUNT_MODAL' });
    this.role = this.localStorage.getItem('role');
    this.transactionNumber = this.akWorkflowService.transactionNumber$.getValue();
    this.getTransactionData(this.transactionNumber);
    this.akWorkflowService.transactionData$.subscribe((response: any) => {
      if (!isNullOrUndefined(response)) {
        this.transactionData = response;
      }
    });
  }

  execute(action) {
    if (!isNullOrUndefined(action)) {
      switch (action.action) {
        case 'openModal':
          if (action.identifier === 'FORMALIZE_ACCOUNT_MODAL') {
            this.comment();
          }
          break;
      }
    }
  }

  close() {
    swal.fire({
      title: '',
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false,
      html: '',
      timer: 3
    });
  }

  setBreacrumb(breadcrumbKey) {
    this._breadcrumbService.setbreadcrumbKey(breadcrumbKey);
  }

  gototab(tab) {
    this.currenttab = tab;
  }

  comment() {
    this.akeelaSectionService.showModalSection('FORMALIZE_ACCOUNT_MODAL', true);
  }


  getTransactionData(transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role,
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.getTransactionById(response.identifier);
      this.loading = false;
      this.transactionData = response;
      this.akWorkflowService.transactionData$.next(this.transactionData);
      // if (this.transactionData.accountStatus) {
      //   if (this.transactionData.accountStatus[0].additionalType === 'AC_PENDING') {
      //     this.akStepService.setModeCurrentStep(false);
      //   } else {
      //     this.akStepService.setModeCurrentStep(true);
      //   }

      // } else {
      //   this.akStepService.setModeCurrentStep(true);

      // }
      // this.akStepService.setModeCurrentStep(false);
    }, (error: any) => {
      this.loading = false;
    }));
  }

  getTransactionById(identifier) {
    const params = {
      name: 'account$account$get',
      params: {
        request: {},
        path: {
          identifierAccount: identifier
        },
        body: {}
      }
    };
    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params).subscribe((response: any) => {
    }, (error: any) => {
    }));
  }

  change(event) {
    this.loading = true;
    this.transactionNumber = event;
    this.getTransactionData(event);
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      // prevent memory leak when component destroyed
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }


}
