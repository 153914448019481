import { Component, OnInit, Input } from '@angular/core';
import { UploadService } from '@akeela/common';
import { AkeelaWorkflowService } from '@akeela/workflow';
import { isNullOrUndefined } from 'util';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-list-formalities-documentation',
  templateUrl: './list-formalities-documentation.component.html',
  styleUrls: ['./list-formalities-documentation.component.css']
})
export class ListFormalitiesDocumentationComponent implements OnInit {
  name;
  transactionData;
  icon;
  initials;
  initial;
  url;


  constructor(
    private _upload: UploadService,
    private workflowService: AkeelaWorkflowService,

  ) { }


  ngOnInit() {


    if (environment.theme.images === 'FACEBANK') {

      this.url = './assets/facebank/public/images';

    } else if (environment.theme.images === 'MIBANCO') {

      this.url = './assets/mibanco/public/images';

    } else {

      this.url = './assets/public/images';

    }
    this.workflowService.transactionData$.subscribe((transactionData: any) => {
      if (!isNullOrUndefined(transactionData)) {
        this.transactionData = transactionData;
        this.initials = this.getInitials(this.transactionData.holder.name, '');
        this.initial = this.getInitial(this.transactionData.holder.name);
        this.name = (this.transactionData.person) ? this.capitalize(this.transactionData.person.givenName) + " " + this.capitalize(this.transactionData.person.familyName) : this.transactionData.organization ? this.capitalize(this.transactionData.organization.legalName) : this.transactionData.holder.name;
      }
    });

  }

  getInitials(givenName: string, familyName: string) {
    return (
      givenName.substr(0, 1).toUpperCase() +
      familyName.substr(0, 1).toUpperCase()
    );
  }


  getInitial(text) {
    let retorno = '';
    if (!isNullOrUndefined(text)) {
      retorno = text.substr(0, 1).toUpperCase();
    }
    return retorno;
  }


  capitalize(word) {
    return word[0].toUpperCase() + word.slice(1);
  }



  show_file(file) {
    const archive = {
      identifier: file.identifier,
      name: file.name,
      dateCreated: {
        orderDate: file.dateCreated.modifiedDate
      }
    };
    if (archive.identifier !== null) {
      this._upload.visor_params.name = '';
      this._upload.visor_params.date = '';
      this._upload.visor_params.endpoint = 'archive$downloadFile$get';
      this._upload.visor_params.archive = archive;
      this._upload.visor = true;
    }
  }

}
