<div class="capa-menu" (click)="_properties.showMenu = false" *ngIf="_properties.showMenu"> </div>
<header class="mb-3" *ngIf="!this.wo">
    <!-- [ngIf]="!_auth.isAuthenticated()" -->
    <ng-template [ngIf]="!_auth.isAuthenticated()">
        <app-navbar></app-navbar>
    </ng-template>
    <ng-template [ngIf]="_auth.isAuthenticated()">
        <app-navbarauth></app-navbarauth>
    </ng-template>
    <!--<p-growl [(value)]="msgs" id="errorMessages" life="3000" styleClass="registerGrowl"></p-growl>-->
</header>