<div class="background-image">

</div>
<!-- <products 
    [params]="params_products"
></products> -->

<!-- 



<div class="container animated fadeIn">
    <div class="box" *ngIf="!plans">
        <br>
        <br>
        <div class="text-center ml-auto">
            <i class="fas fa-spinner fa-pulse fa-6x"></i>
        </div>
    </div>
</div> 

<div class="box  animated fadeIn" *ngFor="let plan of plans; let i = index" [attr.data-index]="i">
    <plans [product]="product" [plan]="plan">
    </plans>
</div> -->