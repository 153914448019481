import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, KeepAliveService } from '@akeela/auth';
import { HttpService, PathService } from '@akeela/properties';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { environment } from 'src/environments/environment';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import swal from 'sweetalert2';
import { isNullOrUndefined } from 'util';
import { LocalService } from '@akeela/local-storage';


@Component({
  selector: 'public-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  params;
  endpoint;
  profile;
  superUser;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _auth: AuthService,
    private _pathService: PathService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    private _breadcrumbService: AkeelaBreadcrumbService,
    private localStorage: LocalService,
    private keepaliveService: KeepAliveService,
    private _httpService: HttpService,

  ) { }

  ngOnInit() {

    if (this._auth.isAuthenticated()) {


      this.router.navigateByUrl(this.router.createUrlTree
        ([environment.staticLinks.workspace],
        {
          queryParams: {
            role: 'USER',
            profile: this.profile
          }
        }
        )
      ).then(() => {
        this.keepaliveService.setReset();
      });

  }

    document.body.classList.remove('teme-gray');
    this.params = {
      role: 'USER',
      clientCode: environment.security.clientCode,
      formsValidations: environment.formsValidations.login

    };
    this.endpoint = 'person$login$post';

  }

  setMenu(){
    let opt = {
      name: this.superUser ?'LEFT_MENU_USER_WKS':'LEFT_MENU_USER',
      username: this._auth.getUsername(),
      typeUser: false

    };


    this._pathService.getPathService(false, environment.appEndPontDefaultConfig.menu).subscribe(pathResponse => {

      this._akeelaSidebarMenu.setsidebarLeftOptions(opt);
      this._akeelaSidebarMenu.setsidebarLeftKey(pathResponse);

    });

    if (this.localStorage.getItem('showSidebarLeft') !== 'true') {
      if (screen.width > 768) {
        this._akeelaSidebarMenu.setshowSidebarLeft(true);
      } else {
        this._akeelaSidebarMenu.setshowSidebarLeft(false);
      }
    } else {
      this._akeelaSidebarMenu.setshowSidebarLeft(true);
    }
  }

  response(event) {
    if (this._auth.isAuthenticated()) {


        this.getViewAll(this._auth.getUsername());

      if (!isNullOrUndefined(this._auth.getSession().profile)) {

        let tmpProfile = this._auth.getSession().profile.replace('[', '').replace(']', '');
        let tmpProfilList = tmpProfile.split(',');

        this.profile = tmpProfilList[0];
        this.localStorage.setItem('profileDefaut', this.profile);

        if (this.profile === 'ANALIST') {
          this.localStorage.setItem('role', 'USER');
          this.localStorage.setItem('audience', 'LEGAL');


          this.localStorage.setItem('profile', this.profile);


          this.router.navigateByUrl(this.router.createUrlTree
            ([environment.staticLinks.workspace],
            {
              queryParams: {
                role: 'USER',
                profile: this.profile
              }
            }
            )
          ).then(() => {
            this.keepaliveService.setReset();
          });

        } else {
          this.localStorage.setItem('role', 'USER');
          this.localStorage.setItem('audience', 'LEGAL');
          this.localStorage.setItem('profile', this.profile);

          this.router.navigateByUrl(this.router.createUrlTree
            ([environment.staticLinks.workspace],
            {
              queryParams: {
                role: 'USER',
                profile: this.profile
              }
            }
            )
          ).then(() => {
            this.keepaliveService.setReset();
          });


        }
      } else {
        this._auth.logout(true);
      }
    }
  }

  getViewAll(client) {
    const params = {
      name: 'person$enduser$get',
      params: {
        request: {
          'username': client
        },
        path: {},
        body: {}
      }
    };

    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      if(response?.viewAll){
        this.superUser = true;

       this.localStorage.setItem('superExecutive',true);
      
      }else{

        this.localStorage.setItem('superExecutive',false);

      }
      this.setMenu();

   });
}




  ngOnDestroy() {
  }

  register() {
    this.router.navigateByUrl(this.router.createUrlTree
      ([environment.staticLinks.registerUser],
      {
        queryParams: {
          role: 'C'
        }
      }
      )
    );
  }


  recovery() {
    this.router.navigateByUrl(this.router.createUrlTree
      (['recovery-password'],
      {
        queryParams: {}
      }
      )
    );
  }


  unlock() {
    this.router.navigateByUrl(this.router.createUrlTree
      (['unlock'],
      {
        queryParams: {}
      }
      )
    );
  }
}


