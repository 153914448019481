<div class="full-modal-wf-full" *ngIf="objModal">
  <div class="header">
    <span class="float-lg-right">
      <i class="fas fa-times active-cursor fa-2x" (click)="close()"></i>
    </span>
  </div>
  <div class="body">
    <app-detail-resumen [transactionNumber]="objModal.transactionNumber"></app-detail-resumen>
  </div>
</div>


<ng-container *ngIf="transactions && configuration_table">
  <akeela-table 
      [configuration]="configuration_table" 
      [order]="filter_order"
      [loading]="loading_table"
      [paginator]="paginator"
      (response)="table_response($event)">
  </akeela-table>
  <br>
</ng-container>
  <div class="mt-3"  *ngIf="!(transactions && configuration_table)" >
        <img  src="./assets/public/images/loading.gif" alt="">
    </div>

