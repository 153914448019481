<ng-container *ngIf="loading">
    <app-template>
        <ng-template appContent componentName="topAlert">

        </ng-template>
    </app-template>
    <div class="row">
        <div class="col-12">
            <img src="./assets/public/images/loading.gif" alt="">
        </div>
    </div>
</ng-container>

<!-- Mensajes y alertas -->
<ng-container *ngIf="!loading">
    <!-- {{ download_file }} -->


    <!-- fin de los mensajes -->




    <ng-container *ngIf="(!download_file && transactionData.accountStatus[0].additionalType!=='AC_REJECTED'&& transactionData.accountStatus[0].additionalType!=='AC_RECEIVED') || requirementsIsStatusIncompleted === true">

        <ng-container *ngIf="loadView">
            <div class="row f-5">

                <div class="col-12 col-md-9 pr-md-3" [class.col-12]="!environment?.showTips" [class.col-md-8]="environment?.showTips===true">

                    <ng-container>
                        <ng-container *ngIf="showWindowError || requirementsIsStatusIncompleted === true">

                            <ng-container *ngIf="!requirementsIsStatusIncompleted && sectionsIncomplete && transactionData.accountStatus[0].additionalType!=='AC_INCOMPLETE'">

                                <div class="col-12 mb-3">
                                    <div class="alert alert-secondary mb-3">
                                        <div class="mb-3">


                                            <i class=" text-secondary pl-2 pr-2 fas fa-info-circle " aria-hidden="true"></i>

                                            <b>Datos de la solicitud incompletos.</b> Por favor complete los datos requeridos por la solicitud

                                        </div>
                                        <ng-container *ngFor="let section of sections; let i = index" [attr.data-index]="i">
                                            <ng-container *ngIf="(sections.length-1)!==i">


                                                <button onclick="" class="btn btn-outline-secondary mt-2 ml-4" disabled [routerLink]="['DOC_SUPPORT/stages/DOC_SUPPORT/steps/UPLOAD_REQUIREMENTS']">
                                                {{section.sectionTitle | i18n}}</button>

                                            </ng-container>

                                        </ng-container>
                                    </div>
                                </div>

                            </ng-container>
                        </ng-container>



                        <ng-container *ngIf="requireLoadRequirements === true && requirementsIsStatusIncompleted === false && !sectionsIncomplete">
                            <div class="col-12 mb-3">
                                <br>
                                <div class="alert alert-secondary">
                                    <i class=" text-secondary pl-2 pr-2 fas fa-info-circle " aria-hidden="true"></i>

                                    <b> Recaudos incompletos.</b> Por favor complete los recaudos
                                    <br>
                                    <button class="btn btn-outline-primary mt-2 ml-4" [routerLink]="['DOC_SUPPORT/stages/DOC_SUPPORT/steps/UPLOAD_REQUIREMENTS']">Recaudos</button>
                                </div>
                            </div>
                        </ng-container>

                    </ng-container>

                    <div class="pr-md-2">


                        <ng-container *ngIf="transactionData.accountStatus[0].additionalType!=='AC_REJECTED' && transactionData.accountStatus[0].additionalType!=='AC_RECEIVED' ">

                            <div class="row" *ngIf="!sectionsIncomplete">

                                <div class="col-12" *ngIf="!(requireLoadRequirements === true && requirementsIsStatusIncompleted === false && !sectionsIncomplete)">

                                    <div class="fafafa  text-center mt-2 rd-section-box p-2 pt-3 pb-3 ">


                                        <div class="mt-3 mb-4">

                                            <div>
                                                <span class="icon bg-white border border-secondary text-primary animate__animated animate__zoomIn far fa-flag "></span>
                                            </div>

                                        </div>


                                        <div class=" text-dark mt-2  mt-4" [class.mb-5]="environment.theme.images !== 'FACEBANK'">

                                            <span style="font-size: 1rem">
                                                <ng-container *ngIf="environment.theme.images === 'FACEBANK'">
                                                    ¡Felicitaciones! Tu registro para solicitud de cuenta ha sido completado. Cualquier información adicional, será requerida
                                                    por su ejecutivo de cuenta.
                                                </ng-container>
                                                <ng-container *ngIf="environment.theme.images !== 'FACEBANK'">
                                                    Felicitaciones, tu solicitud
                                                    <span class="font-weight-bold">{{transactionData?.plan?.name}}</span> , esta lista para enviarla a nuestros analistas
                        </ng-container>


                        </span>
                        </div>

                        <div class=" text-center">


                            <div [innerHTML]="'@i18n-quote-label-termsAndConditionsDescriptions' | i18n"></div>

                            <div [class.pt-2]="environment.theme.images !== 'FACEBANK'">

                                <div [innerHTML]="'@i18n-quote-termsAndConditions' | i18n"> </div>

                            </div>

                        </div>

                        <div class=" pt-4 text-center ">
                            <button (click)="send()" type="button" class="btn btn-primary " [disabled]="readonly_2">
                                                <i class="fas fa-paper-plane pr-2"></i>
                                                <span [innerHTML]="'@i18n-text-button-send-request' | i18n"></span>
                                            </button>
                        </div>
                        </div>

                        </div>

                        <div *ngIf="tmpAcept && objlength(documents) >  0 && objlength " class="col-12">

                            <div class="mt-3">

                                <app-related-documents-generated [dowloadAlert]="true" [name]="transactionData?.person ? 
                    capitalize(transactionData?.person?.givenName) + ' ' + capitalize(transactionData?.person?.familyName) : 
                    capitalize(transactionData?.organization?.legalName)" (response)="show_filePreview($event)" [documents]="documents"></app-related-documents-generated>

                            </div>

                        </div>
                        </div>
        </ng-container>


        </div>

        </div>
        <div class="animate__animated animate__fadeInRight  d-none d-md-block col-12 col-md-3 ak-resumen-tips" [innerHTML]="'@i18n-sworn-text-tips' | i18n" *ngIf="environment?.showTips"></div>


        </div>
    </ng-container>

    <ng-container *ngIf="!loadView">
        <div class="row">
            <div class="col-12">
                <img src="./assets/public/images/loading.gif" alt="">
            </div>
        </div>
    </ng-container>

</ng-container>

<ng-container *ngIf="download_file || transactionData.accountStatus[0].additionalType === 'AC_REJECTED' || transactionData.accountStatus[0].additionalType==='AC_PENDING' ||  transactionData.accountStatus[0].additionalType==='AC_RECEIVED' || transactionData.accountStatus[0].additionalType==='AC_INCOMPLETE'   ">
    <div class="row f-5 mt-3">

        <div class="col-12 col-md-9 pr-md-3 animate__animated animate__fadeIn">

            <div class="f3f3f3" *ngIf="transactionData.accountStatus[0].additionalType == 'AC_INCOMPLETE'">
                <div class="text-center  p-2 pb-5 background-image-w animate__animated animate__fadeInUp">
                    <div class=" mb-4">

                        <div>
                            <span class="icon  text-primary animate__animated animate__zoomIn fas fa-reply-all"></span>
                        </div>
                        <div class="ak-title-msg ">Revisada con observaciones</div>

                    </div>
                    <div class=" text-dark mb-5 pt-2 ">

                        <span style="font-size: 1rem">

                                Complete información adicional de tu solicitud
                                <span class="font-weight-bold">{{transactionData?.plan?.name}}</span>

                        </span>


                    </div>
                </div>
            </div>

            <div class="f3f3f3" *ngIf="transactionData.accountStatus[0].additionalType == 'AC_REJECTED'">
                <div class="text-center mt-3 rd-section-box p-2 pt-4 pb-5 background-image-w animate__animated animate__fadeInUp ">
                    <div class="mt-3 mb-4">

                        <div>
                            <span class="icon bg-secondary animate__animated animate__zoomIn far fa-flag"></span>
                        </div>
                        <div class="ak-title-msg mt-3 ">No cumple con la política</div>

                    </div>
                    <div class=" text-dark mt-2 mb-5 mt-4 pt-3">

                        <span style="font-size: 1rem">


                                Tu solicitud
                                <span class="font-weight-bold">{{transactionData?.plan?.name}}</span> , no cumple con la política


                        </span>
                    </div>
                </div>
            </div>

            <div class="f3f3f3" *ngIf="transactionData.accountStatus[0].additionalType == 'AC_RECEIVED'">
                <div class="text-center mt-3 rd-section-box p-2 pt-4 pb-5 background-image-w animate__animated animate__fadeInUp ">
                    <div class="mt-3 mb-4">

                        <div>
                            <span class="icon bg-primary animate__animated animate__zoomIn fas fa-star"></span>
                        </div>
                        <div class="ak-title-msg mt-3 "> Preaprobada</div>

                    </div>
                    <div class=" text-dark mt-2 mb-5 mt-4 pt-3">

                        <span style="font-size: 1rem">

                                Felicitaciones, tu solicitud
                                <span class="font-weight-bold">{{transactionData?.plan?.name}}</span> , ha sido Aprobada. Por favor dirígete a la agencia/sucursal, consigna los recaudos y retira tu producto.
                        <br>
                        <br>

                        <div *ngIf="environment?.theme?.images != 'MIBANCO'">


                            Contacta con tu ejecutivo
                            <span class="font-weight-bold" [innerHTML]="(transactionData?.salesManagerUser)?(transactionData?.salesManagerUser?.name) : '' "></span> para mayor información.
                        </div>

                        </span>
                    </div>
                </div>
            </div>



            <div class="f3f3f3" *ngIf="transactionData.accountStatus[0].additionalType == 'AC_PENDING'">
                <div class="text-center mt-3 rd-section-box p-2 pt-4 pb-5 background-image-w animate__animated animate__fadeInUp ">
                    <div class="mt-3 mb-4">

                        <div>
                            <span class="icon bg-primary animate__animated animate__zoomIn fas fa-users"></span>
                        </div>
                        <div class="ak-title-msg mt-3 ">Espera de análisis</div>

                    </div>
                    <div class=" text-dark mt-2 mb-5 mt-4 pt-3">

                        <span style="font-size: 1rem">


                                Tu solicitud
                                <span class="font-weight-bold">{{transactionData?.plan?.name}}</span> , está siendo revisada por nuestros analistas

                        </span>
                    </div>
                </div>
            </div>

            <app-related-documents-generated *ngIf="transactionData.accountStatus[0].additionalType !== 'AC_REJECTED' ||  transactionData.accountStatus[0].additionalType !=='AC_RECEIVED'" [name]="transactionData?.person ? 
            capitalize(transactionData?.person?.givenName) + ' ' + capitalize(transactionData?.person?.familyName) : 
            capitalize(transactionData?.organization?.legalName)" (response)="show_filePreview($event)" [documents]="documents"></app-related-documents-generated>
        </div>

        <ng-container>
            <div class="animate__animated animate__fadeInRight d-none d-md-block col-12 col-md-3 ak-resumen-tips" [innerHTML]="'@i18n-sworn-text-tips' | i18n" *ngIf="environment.showTips"></div>
        </ng-container>
    </div>
</ng-container>
</ng-container>