import { AuthService } from '@akeela/auth';
import { UploadService } from '@akeela/common';
import { I18nPipe } from '@akeela/i18n';
import { LocalService } from '@akeela/local-storage';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { HttpService } from '@akeela/properties';
import { AkeelaWorkflowService } from '@akeela/workflow';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { log } from 'console';
import { Subscription } from 'rxjs';
import { min } from 'rxjs/operators';
import { PropertiesService } from 'src/app/services';
import { WorkflowService } from 'src/app/services/workflow.service';
import { ErrorService } from '@akeela/common';

@Component({
  selector: 'app-send-client-section',
  templateUrl: './send-client-section.component.html',
  styleUrls: ['./send-client-section.component.css']
})
export class SendClientSectionComponent implements OnInit {
  dataInformation;
  indicatorsProgres :any = {};
  workflowCodeActive;
  indicatorsLoading;
  clientActive = 'CLIENT_DATA';
  isNatural ;

  constructor(
    public router: Router,
    public  config: NgbDropdownConfig,
    public _auth:AuthService,
    public _properties: PropertiesService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    private _wfService: WorkflowService,
    private workflowService: AkeelaWorkflowService,
    private _httpService: HttpService,
    private i18n: I18nPipe,
    private localStorage: LocalService,
    private akWorkflowService: AkeelaWorkflowService,
    private _error: ErrorService,

  ) { }

  ngOnInit(): void {
    
    this.isNatural = this.localStorage.getItem('clientaudience') == 'NATURAL';
    this.workflowCodeActive = this.clientActive;
    this.getIndicatorsProgres();
    this.getDataInformations();
    

  }

  getDataInformations() {
    const params = {

      name:  this.localStorage.getItem('clientaudience') == 'LEGAL' ? 'briefcase$business_person$get' : 'briefcase$person$get',
      params: {
        request: {
          'id': this.localStorage.getItem('clientIdentifier')
        },
        path: {},
        body: {}
      }
    };
    
  
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
  
      ;
     this.dataInformation = response;
   
     
  
    });
  
  }
  

sendCompleat() {
  let data :any;
 
  const params = {

    name: 'briefcase$complete-data$post',
    params: {
      request: {
        'username': this.localStorage.getItem('clientUsername')
      },
      path: {},
      body: {}
    }
  };


  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      if (response) {

        

        if( this.localStorage.getItem('redirectTransation')){

          this.redirectTransactions2(this.localStorage.getItem('redirectTransation'));

        }else{

          this.redirectDashboard();

         // this.redirecClientData();

        }
      }

  }, error => {

    this._error.show(error);

  });

}

redirectDashboard(): void {
  debugger
  const queryParams = {
    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience')
  };

  this.router.navigate(['/dashboard'],{ queryParams: queryParams });
  this.workflowService.setCurrentWorkflowCodeActive(null);


}

redirectTransactions2(transactionNumber){

  const queryParams2 = {

    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
    transactionNumber: transactionNumber,

  };

  let url = ['console/transaction-dashboard'];
  this.akWorkflowService.setTransactionNumber(transactionNumber);


  this.router.navigateByUrl(
    this.router.createUrlTree(url,
      {
        queryParams: queryParams2
      }
    )
  ).then(() => {
    
  });
}



redirecClientData(){
  const queryParams2 = {

    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience')
    
  };

  let url = ['/client-data'];

  this.router.navigateByUrl(
    this.router.createUrlTree(url,
      {
        queryParams: queryParams2
      }
    )
  ).then(() => {
    
  });


}


getIndicatorsProgres() {
  let data :any;


    data = {
      "workflow":this.workflowCodeActive,
      "personId": this.localStorage.getItem('clientIdentifier')
  }
 
    
 
  const params = {

    name: 'resume$workflow_progress$post',
    params: {
      request: {
        role: this.localStorage.getItem('role'),
        audience: this.localStorage.getItem('audience'),

      },
      path: {},
      body: data

    }
  };

  this.indicatorsLoading = true;
  let indicatorsProgres = {};
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    if (response) {

      let progress;
      let preData =response[0]?.modules;

       progress = response[0]?.modules[0]?.progress;
      
      


       let  maxValue = progress?.maxValue;
       let  minValue = progress?.minValue;

       indicatorsProgres['maxValue'] = maxValue;
       indicatorsProgres['minValue'] = minValue;
       indicatorsProgres['percent'] = minValue > 0 ?   this.round(minValue*100/maxValue):0;
        
       this.indicatorsProgres = indicatorsProgres;
        this.indicatorsLoading = false;
      }


  }, error => {

    this.indicatorsLoading = false;

  });

}


round(value) {
  
  return Math.round(value);
  }
  
  

  redirectContinue(urlWf = this.clientActive ) {
    if( this.localStorage.getItem('redirectTransation')){
      
      this.redirectTransactions2( this.localStorage.getItem('redirectTransation'));

    }else{

    if(!this.dataInformation?.completeBriefcase){
  
   let url = [urlWf];
   const prefixed = 'private';
  
    const queryParams = {
      role: this.localStorage.getItem('role'),
      profile: this.localStorage.getItem('profile'),
      audience: this.localStorage.getItem('audience')
    };
  
    this.localStorage.setItem('sectionRedirect',true);


    this.router.navigate([prefixed + '/' + url], { queryParams: queryParams })  .then(() => {
    window.location.reload();
     });


  
  }
      

}
  }
}
