
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@akeela/auth';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { TransitionService } from '../../../../services/transition.service';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { CommonService, ErrorService } from '@akeela/common';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { LocalService } from '@akeela/local-storage';
import { I18nPipe } from '@akeela/i18n';
import { DatePipe } from '@angular/common';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { PropertiesService } from '../../../../services';


@Component({
  selector: 'app-akeela-dashboard',
  templateUrl: './akeela-dashboard.component.html',
  styleUrls: ['./akeela-dashboard.component.css']
})
export class AkeelaDashboardComponent implements OnInit {

  client_indicators ;
  general_indicators ;
  clientLast;

  constructor(
    private _httpService: HttpService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    public _common: CommonService,
    public config: NgbDropdownConfig,
  ) { }

  ngOnInit(): void {

    this.getClientIndicators();
    this.getGeneralIndicators();

  }



getGeneralIndicators() {
  const params: any = {
    name: 'workspace_manager$indicators_general$get',
    params: {
      request: {},
      path: {},
      body: {}
    }
  };
  this.general_indicators = null;
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    this.general_indicators = {};  
    for (const row of response) {      
    
      this.general_indicators[row?.name]= row;
    
  }

  }, error => {
  })
}



getClientIndicators() {

  const params: any = {
    name: 'workspace_manager$indicators_client$get',
    params: {
      request: {},
      path: {},
      body: {}
    }
  };

  this.client_indicators = null;


  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    this.client_indicators = {};

    for (const row of response) {      
    
      this.client_indicators[row?.name]= row;
    
  }
      this.getUser(this.client_indicators['LAST_REC']?.modifyUser);

  }, error => {

  })
}

getUser(client) {
  const params = {
    name: 'person$enduser$get',
    params: {
      request: {
        'username': client
      },
      path: {},
      body: {}
    }
  };

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    this.clientLast = response;

  });

}


}
