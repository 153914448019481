import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SidebarService, PropertiesService } from '../../../services/index';
import { AuthService } from '@akeela/auth';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LocalService } from '@akeela/local-storage';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Output() changesidebar: EventEmitter<boolean> = new EventEmitter();
  sidebarOptions: any;
  dropDownMenu = {};
  show;
  queryParams;


  constructor(
    private _activatedRoute: ActivatedRoute,
    public _auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public _sidebar: SidebarService,
    public _properties: PropertiesService,
    public localStorage: LocalService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });
  }

  toggleMenu() {
    this._properties.SetShowMenu(false);
    this.changesidebar.emit(false);
  }


  closeMenu() {
    if (screen.width < 768) {
      this._properties.SetShowMenu(false);
    } else {
      this._properties.SetShowMenu(true);
    }
  }

  cerrar_Sesion() {
    this._auth.logout();
    this.router.navigate([environment.staticLinks.login]);
  }

  contacts() {
    this.router.navigateByUrl(
      this.router.createUrlTree([environment.staticLinks.listClients],
        {
          queryParams: this.queryParams
        }));

  }

  procedures() {
    this.router.navigateByUrl(
      this.router.createUrlTree([environment.staticLinks.listAccountOpening],
        {
          queryParams: this.queryParams
        }));
  }

  call_fn(fn) {
    if (typeof this[fn] === 'function') {
      this[fn]();
    }
  }

  logout() {
    sessionStorage.removeItem('existToken');
    sessionStorage.removeItem('activeToken');
    this.localStorage.removeItem('clienType');
    this._auth.logout();
    this.router.navigate(['/login']);
  }


  inbox() {
    this._sidebar.inbox = 'inbox';

  }

  sent() {
    this._sidebar.inbox = 'sent';

  }

  draft() {
    this._sidebar.inbox = 'draft';

  }


}
