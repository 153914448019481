<div class="capa-menu-2" *ngIf="buttomModal" (click)="setClose()"> </div>

<div [ngClass]="{'d-none':animate_init_bottom,'animate__slideInUp':buttomModal,animate__slideOutDown:!buttomModal } " class="  animate__animated animate__faster ak-modal-botoom ak-modal-botoom2  p-2 ">
    <div class="body pb-4 pt-2 " style="overflow:auto;">
        <div class="container">


            <div class="row">
                <div class="col-md-8 offset-md-2 col-12 ">

                    <div (click)="$event.stopPropagation();" class="w-100 bg-white mt-3  ">


                        <div style="min-height: 206px;">


                            <div class="d-flex bd-highlight">
                                <div class="w-80 w-sm-70">

                                    <h5 class="font-weight-bold fz-16"><i class="fas fa-sticky-note text-dark"></i> Exports</h5>

                                </div>

                                <div class="w-20 w-sm-30 pl-2">
                                    <div class="text-right">

                                        <div *ngIf="showForm ">
                                            <i (click)="showForm=! showForm " class=" active-cursor fas fa-times " style="font-size: 1.2rem; "></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr>


                            <ng-container *ngIf="loadingExport">
                                <div class="text-center mt-3">
                                    <img src="./assets/public/images/loading.gif" alt="">
                                </div>
                            </ng-container>

                            <ng-container *ngIf="!loadingExport">
                                <div class="w-100">

                                    <form [formGroup]="form" (ngSubmit)="submit()" class="text-left fz-14 mt-2">

                                        <div class="row">

                                            <div class="form-group col-12 col-md-6">
                                                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                                                <span [innerHTML]="'Tipo'"></span>
                                                <span class="text-danger mt-1" > * </span>
                                                </label>

                                                <select class="form-control" [ngClass]="{'error': form.controls.type.invalid && form.controls.type.dirty}" formControlName="type">
                                                    <option value='' >Caja Venezolana de Valores</option>
                                                </select>
                                                <div class="form-control-feedback help-block" *ngIf="form.get('type').errors">
                                                    <div *ngIf="form.get('type').errors['required'] && !form.get('type').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="form-group col-12 col-md-6">
                                                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                                                <span [innerHTML]="'Tipo de persona'"></span>
                                                <span class="text-danger mt-1" > * </span>
                                                </label>

                                                <select class="form-control" [ngClass]="{'error': form.controls.audience.invalid && form.controls.audience.dirty}" formControlName="audience">
                                                    <option value='NATURAL' >Persona Natural</option>
                                                    <option value='LEGAL' >Persona Jurídica</option>

                                                </select>
                                                <div class="form-control-feedback help-block" *ngIf="form.get('audience').errors">
                                                    <div *ngIf="form.get('audience').errors['required'] && !form.get('audience').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                                                    </div>

                                                </div>
                                            </div>

                                        </div>


                                        <div class="row">

                                            <div class="form-group col-12 col-md-6">
                                                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                                                <span [innerHTML]="'Fecha inicio'"></span>
                                                <span class="text-danger mt-1" > * </span>
                                                </label>
                                                <input data-date-format="DD/MM/YYY" [placeholder]="'dd/mm/aaaa'" type="date" class="form-control" [ngClass]="{'error': form.controls.datExportIni.invalid && form.controls.datExportIni.dirty}" formControlName="datExportIni">
                                                <div class="form-control-feedback help-block" *ngIf="form.get('datExportIni').errors">
                                                    <div *ngIf="form.get('datExportIni').errors['required'] && !form.get('datExportIni').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="form-group col-12 col-md-6">
                                                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                                                <span [innerHTML]="'Fecha fin'"></span>
                                                <span class="text-danger mt-1" > * </span>
                                                </label>
                                                <input [placeholder]="'dd/mm/aaaa'" type="date" class="form-control" [ngClass]="{'error': form.controls.datExportEnd.invalid && form.controls.datExportEnd.dirty}" formControlName="datExportEnd">
                                                <div class="form-control-feedback help-block" *ngIf="form.get('datExportEnd').errors">
                                                    <div *ngIf="form.get('datExportEnd').errors['required'] && !form.get('datExportEnd').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                        <div class="row">

                                            <br>
                                            <div class=" col-12 mt-3">

                                                <button type="submit" [disabled]="!form.valid || submit_disabled" class="btn btn-primary  ">
                                                    Exportar CVV
                                                    <span *ngIf="submit_disabled">
                                                    <i class="fa  fa-spinner fa-spin"></i>
                                                    </span>
                                                </button>

                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </ng-container>

                            <!--
                            <ng-container *ngIf="export?.length == 0 && !loadingExport ">
                                <div class=" mt-3"> No se encotraron registros </div>
                            </ng-container>

 -->

                            <ng-container *ngIf=" !loadingExport">

                                <div class="mt-2 note active-cursor mt-2 " *ngFor="let row of export">


                                    <!-- <div class="d-flex bd-highlight">
                                        <div class="w-100 w-sm-100 fz-14">

                                             <div class=" active-cursor note-text fz-14 font-weight-bold ">
                                                {{row.title}}
                                            </div>

                                            <div class=" active-cursor note-text fz-14 text-secondary">
                                                {{row.subtitle}}
                                            </div>

                                       


                                        </div>

                                        
                                        <div class="w-20 w-sm-30 pl-2">
                                            <div class=" fz-12 text-right fz-12">

                                                <span class=" text-secondary">{{row.create_date | date: 'dd/MM/yyyy HH:mm'}}</span><br>
                                            

                                            </div>

                                        </div>
                                  
                                    </div>
  -->

                                    <div class=" active-cursor  fz-14 mt-3">
                                        <div class=" small text-secondary ">{{row?.create_date | date: 'dd/MM/yyyy HH:mm'}}</div>

                                        <span class=" font-weight-bold  pr-2">{{row.ejecutive }}</span>
                                        <span>{{row.note}}</span>
                                    </div>

                                </div>
                            </ng-container>

                            <!-- 
                            <pre>{{ form.getRawValue() | json }}</pre>

-->



                        </div>



                    </div>

                </div>
            </div>
        </div>

    </div>
</div>