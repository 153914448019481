import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@akeela/auth';
import { HttpService } from '@akeela/properties';
import { RefreshService } from '../../../services/refresh.service';
import { environment } from '../../../../../../environments/environment';
import { MiddlewareService } from '../../../../../services/middleware.service';
import { TabsService } from '../../../../../services/tabs.service';
import { isNullOrUndefined } from 'util';
import { ErrorService } from '@akeela/common';
import { Subscription } from 'rxjs';
import { LocalService } from '@akeela/local-storage';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';


@Component({
  selector: 'app-resumen-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() change = new EventEmitter();
  @Output() action?= new EventEmitter();



  transactionNumber;
  transactionData;
  queryParams;
  readonly = false;
  config;
  _back;
  _next;
  subscriptions: Subscription[] = [];
  role;
  audience;
  openDialog;
  win;
  interval;
  environment;
  constructor(
    private akeelaSectionService: AkeelaSectionService,
    private akStepService: AkeelaStepService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private route: ActivatedRoute,
    private router: Router,
    private _auth: AuthService,
    private _httpService: HttpService,
    private refresh: RefreshService,
    private _middleware: MiddlewareService,
    private _tabs: TabsService,
    private _error: ErrorService,
    private localStorage: LocalService,
    private _i18n: I18nPipe
  ) { }


  ngOnDestroy() {
    if (this.win == null || this.win.closed) {
      if (!isNullOrUndefined(this.win)) {
        this.win.close();
      }
    }


    if (this.subscriptions) {
      // prevent memory leak when component destroyed
      this.subscriptions.forEach(s => s.unsubscribe());
    }

  }


  ngOnInit() {

    this.environment = environment;

    if (this.localStorage.getItem('workspaceReadonly') === 'true' || this.localStorage.getItem('readonly') === 'true') {
      this.readonly = true;
    }

    this.akeelaWorkflowService.worflowEvents$.next(null);
    this.refresh.refresh.next(null);
    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
      this.audience = queryParams['audience'];
    }));

    this.transactionNumber = this.akeelaWorkflowService.transactionNumber$.getValue();
    this.getTransactionData(this.transactionNumber).subscribe((response) => {
      this.transactionData = response;
    });

    this._tabs.config$.subscribe((config) => {
      this.config = config;
    });

    this.akeelaWorkflowService.transactionData$.subscribe((response: any) => {
      if (!isNullOrUndefined(response)) {
        this.transactionData = response;
      }
    });


  }

  response() {
    this.akeelaSectionService.showModalSection('RESPONSE_MODAL');
    this._middleware.isModuleRequirementsActive().then((response) => {
      if (Object.keys(response).length === 0) {
        response = false;
      }
      if (response === false) {
        const obj = {
          section: 'RESPONSE_MODAL',
          response: {
            name: '',
            responseDescription: '',
            status: 'NOREQUIREMENTS',
            modifyUser: this._auth.getUsername()
          }
        };
        this.akeelaSectionService.externalUpdateSectionForm$.next(obj);
      } else {
        const obj = {
          section: 'RESPONSE_MODAL',
          response: {
            name: '',
            responseDescription: '',
            status: '',
            modifyUser: ''
          }
        };
        this.akeelaSectionService.externalUpdateSectionForm$.next(obj);
      }
    }, error => {
      const obj = {
        section: 'RESPONSE_MODAL',
        response: {
          name: '',
          responseDescription: '',
          status: 'NOREQUIREMENTS',
          modifyUser: this._auth.getUsername()
        }
      };
      this.akeelaSectionService.externalUpdateSectionForm$.next(obj);
    });

  }

  finishedit() {
    if (this.transactionData.accountStatus[0].additionalType === 'AC_PENDING' && this.transactionData.editTaken && !this.readonly) {
      if (!this.readonly) {
        this.action.emit({
          action: 'finishedit',
          identifier: this.transactionData.transactionNumber,
          object: this.transactionData
        });
         this.transactionData.editTaken = false;
        this.akeelaWorkflowService.transactionData$.next(this.transactionData);
      }
    }
  }

  formalize() {
    if (((isNullOrUndefined(this.transactionData.accountNumber) || this.transactionData.accountNumber === '') && this.transactionData.accountStatus[0].additionalType === 'AC_RECEIVED') && !this.readonly) {
      this.action.emit({
        action: 'openModal',
        identifier: 'FORMALIZE_ACCOUNT_MODAL'
      });
    }
  }

  formalities() {
    this.router.navigate([environment.staticLinks.accountContractAnalisys], { queryParams: this.queryParams });

    // this.router.navigateByUrl(
    //   this.router.createUrlTree([environment.staticLinks.accountContractAnalisys],
    //     {
    //       queryParams: this.queryParams
    //     }
    //   )
    // );
  }

  edit(transactionNumber) {
    if ((!this.transactionData.editTaken && this.transactionData.accountStatus[0].additionalType === 'AC_PENDING') && !this.readonly) {
      if (!this.readonly) {
        this.localStorage.setItem('takenedit', transactionNumber);
        const params = {
          name: 'account_opening$takentoedit$post',
          params: {
            request: {},
            path: {
              transactionNumber: this.transactionNumber,
              username: this._auth.getUsername()
            },
            body: {}
          }
        };
        this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
          this.openFormalities(transactionNumber);
        }, (error: any) => {
          this._error.show(error);
        }));
      }
    } else if (!this.readonly) {
      this.localStorage.setItem('takenedit', transactionNumber);
      this.openFormalities(transactionNumber);
    }
  }


  skip() {
    if ((!this.transactionData.editTaken && this.transactionData.accountStatus[0].additionalType === 'AC_PENDING') && !this.readonly) {
      const params = {
        name: 'account_openning$nextTransaction$get',
        params: {
          request: {
            codeAccountStatus: 'AC_PENDING'
          },
          path: {
            transactionNumber: this.transactionNumber,
            user: this._auth.getUsername()
          },
          body: {}
        }
      };
      this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        // this._auth.canAccessRoleExecutive();
        this.transactionNumber = response.transactionNumber;
        this.akeelaWorkflowService.setTransactionNumber(response.transactionNumber);
        this.transactionData = null;
        this.getTransactionData(this.transactionNumber).subscribe((resp) => {

          this.transactionData = response;
        });
        this.change.emit(response.transactionNumber);
      }, (error: any) => {
        if (error.error.message === 'no account available') {
          this.formalities();
        }
      }));
    }

  }

  openFormalitiesView(transactionNumber) {

    let data = this.transactionData;


    if (data.holder.additionalType === 'LEGAL') {
      this.redirectView(transactionNumber, 'LEGAL');
    } else {
      this.redirectView(transactionNumber, 'NATURAL');
    }
  }
  redirectView(transactionNumber, audience) {
    this.localStorage.setItem('audience', audience);

    let tmp = environment.urlFront + '/' + environment.staticLinks.detailClientResumen;
    tmp = tmp + '?profile=' + this.localStorage.getItem('profile');
    tmp = tmp + '&role=' + this.localStorage.getItem('role');
    tmp = tmp + '&audience=' + audience;
    tmp = tmp + '&transactionNumber=' + this.localStorage.getItem('transactionNumber');
    tmp = tmp + '&wo=true';

    this.win = window.open(tmp, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes");


    this.interval = window.setInterval(() => {
      try {
        if (this.win == null || this.win.closed) {
          this.akStepService.setModeCurrentStep(false);
          this.localStorage.setItem('readonly', 'false');
          window.clearInterval(this.interval);
        }
      }
      catch (e) {
      }
    }, 1000);


  }


  openFormalities(transactionNumber) {
    this.akeelaWorkflowService.setTransactionNumber(transactionNumber);
    this.subscriptions.push(this.getTransactionData(transactionNumber).subscribe((data: any) => {
      this.akeelaWorkflowService.transactionData$.next(data);
      this.subscriptions.push(this.getPerson(data.holder.identifier).subscribe((response: any) => {
        if (data.holder.additionalType === 'NATURAL') {
          this.redirect(transactionNumber, 'NATURAL');
        } if (data.holder.additionalType === 'LEGAL') {
          this.redirect(transactionNumber, 'LEGAL');
        } else {
          this.redirect(transactionNumber, 'NATURAL');
        }
      }, (error: any) => {
        if (data.holder.additionalType === 'NATURAL') {
          this.redirect(transactionNumber, 'NATURAL');
        } if (data.holder.additionalType === 'LEGAL') {
          this.redirect(transactionNumber, 'LEGAL');
        } else {
          this.redirect(transactionNumber, 'LEGAL');
        }
      }));
    }));

  }

  assign(transactionNumber) {

  }

  redirect(transactionNumber, audience) {
    this.localStorage.setItem('audience', audience);
    const queryParams = {
      profile: this.localStorage.getItem('profile'),
      role: this.localStorage.getItem('role'),
      audience: audience,
      transactionNumber: this.localStorage.getItem('transactionNumber')
    };

    this.router.navigate([environment.staticLinks.detailClientResumen], { queryParams: queryParams });


    // this.router.navigateByUrl(this.router.createUrlTree
    //   ([environment.staticLinks.detailClientResumen],
    //   {
    //     queryParams: queryParams
    //   }
    //   )
    // );
  }

  getPerson(identifier) {
    const params = {
      name: 'person$person$get',
      params: {
        request: { id: identifier },
        path: {},
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  confirmDelete() {
    if ((this.transactionData.accountStatus[0].additionalType === 'PI_IN_PROCESS') && !this.readonly) {
      swal.fire({
        title: this._i18n.transform('@i18n-popup-text-delete-question'),
        text: this._i18n.transform('@i18n-popup-text-delete-question-text'),
        showCancelButton: true,
        confirmButtonText: this._i18n.transform('@i18n-popup-text-yes'),
        cancelButtonText: this._i18n.transform('@i18n-popup-text-no'),
        reverseButtons: true
      }).then((result) => {

        if (!isNullOrUndefined(result.value) && result.value) {

          this.trash();

        }


      });
    }
  }

  trash() {
    const request = {};
    const param = {
      name: 'account_opening$account$delete',
      params: {
        request: {
          requestUser: this._auth.getUsername(),
          reason: 'SOMETEXT'
        },
        path: {
          transactionNumber: this.transactionData.transactionNumber,
        },
        body: {}
      }
    };

    this._httpService.executeRequest(param.name, param.params, true).subscribe((response: any) => {
      this.formalities();
    }, error => {
      this._error.show(error);
    });

  }

  getTransactionData(transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role,
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  go(identifier, prefix?) {
    if ((((!this.transactionData.editTaken && identifier !== 'P2') || !this.transactionData.editTaken) && this.transactionData.accountStatus[0].additionalType === 'AC_PENDING') && !this.readonly) {
      const next = this.config.tabs.filter(resp => {
        return resp.identifier === identifier;
      });
      if (!isNullOrUndefined(next)) {
        const _prefix = prefix ? prefix : 'private';

        this.router.navigate([_prefix + '/' + next[0].workflow + '/stages/' + next[0].stage + '/steps/' + next[0].step], { queryParams: {} });

        // this.router.navigateByUrl(this.router.createUrlTree([_prefix + '/' + next[0].workflow + '/stages/' + next[0].stage + '/steps/' + next[0].step],
        //   {
        //     queryParams: {}
        //   }
        // ));
      }
    }

  }

  view(transactionNumber) {

    // 
    // Juan se habilita el readonly


    this.openFormalitiesView(transactionNumber);
  }

  renew(transactionNumber) {
    if ((this.transactionData.accountStatus[0].additionalType === 'AC_PENDING') && !this.readonly) {

      this.action.emit({
        action: 'renew',
        identifier: this.transactionData.transactionNumber,
        object: this.transactionData
      });

      // const request = {};
      // const param = {
      //   name: 'account$renew-document$get',
      //   params: {
      //     request: {
      //       role: 'USER',
      //       audience: this.transactionData.organization ? 'LEGAL' : 'NATURAL'
      //     },
      //     path: {
      //       transactionNumber: transactionNumber,
      //       systemUser: this._auth.getUsername()
      //     },
      //     body: {}
      //   }
      // };

      // this._httpService.executeRequest(param.name, param.params, true).subscribe((response: any) => {
      //   this.akeelaWorkflowService.transactionData$.next(this.transactionData['formsheets'] = response);

      // }, error => {
      //   this._error.show(error);
      // });
    }
  }

}
