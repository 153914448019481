import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { LocalService } from '@akeela/local-storage';
import swal from 'sweetalert2';
import { I18nService, I18nPipe } from '@akeela/i18n';
import { UploadService, ErrorService } from '@akeela/common';
import { AkeelaWorkflowService } from '@akeela/workflow';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';
import { AuthService } from '@akeela/auth';
import { WorkflowService } from 'src/app/services/workflow.service';
import { environment } from '../../../../../environments/environment';
import { async, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { NotesService } from 'src/app/services/notes.service';



@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.css']
})
export class TransactionDetailComponent implements OnInit, OnDestroy {
  _transaction;
  clientDetail;
  transactionData;
  dataAndSelfie;
  filePreview;
  compleatVerifi;
  verifiedIdentification;
  back;
  currentView = 'transaction';
  requirements;
  filterRequirements = ['all'];

  /* Flags */
  buttomModal = false;
  loading = false;
  animate_init_bottom = true;
  animate_contact = true;
  animate_verifi = false;
  animate_solic = false;
  role;
  preview;
  dataIdentity;
  activeRequirements = false; LA
  folder;
  env;
  config_requirements: any = null;

  transactionCompleat;

  balance_requirements = null;

  idTransaction;
  transactionNumber;
  audience;
  idPerson;
  idPersonMarketType;
  user;
  submit_disabled;

  workflow;
  stage;
  step;

  queryParams;

  mb_max_filesize: any = 1;

  filter_selected = '';
  filter_category_selected = '';
  categories;

  extensions;
  isBeforeTransactionIncomplete = false;
  checkreviewed  = { option: '', description: '' };
  responseData = { option: '', description: '' };
  transitionSubscription: Subscription;
  optionsResponsesSubscription: Subscription;
  saveSectionsIncompleatSubscription: Subscription;
  sectionsIncompleatSubscription: Subscription;
  workflowEventsSubscription: Subscription;

  responseSubscription: Subscription;
  listWfSubscription: Subscription;
  dataInformation;
  dataInformationsSubscription: Subscription;

  listWf = {};

  sectionsIncompleat: any = [];

  dataInfoClientLASubscription: Subscription;
  dataInfoClientLA = null;
  getdataInfoClientLAError = null;
  loadingdataInfoClientLA = true;
  verificationLA;
  cities = ["Mohali", "Chandigarh"];
  form: FormGroup;
  data: any = {};
  optionsResponses = [];
  showModalResponses;
  viewOptional = true;
  clientDataResp = true;
  profile;

  dataInfoClientAKB;
  loadingdataInfoClientAKB = true;
  verificationAKB;
  getdataInfoClientAKBError;
  firstAnalystRevision ;
  mdActve;
  @Input() set transaction(transaction) {
    this.destroySubscription();
    this.akWorkflowService.transactionData$.next(null);
    this.akWorkflowService.setTransactionNumber(transaction);

    this.getTransaction();

    this.loadingdataInfoClientLA = true;
    this.loadingdataInfoClientAKB = true;


    this._transaction = transaction;
    this.transactionData = null;
    this.dataAndSelfie = null;
    this.filePreview = null;


    this.back = null;
    if (this.localStorage.getItem('objBackTransaction')) {
      this.back = JSON.parse(this.localStorage.getItem('objBackTransaction'));
    }
  }

  editReque:any;

  @Input() set selected(buttomModal) {



    let html = document?.getElementById("idHtml");
    html?.classList?.add('scroll-none');

    if (buttomModal) {

      this.loadingdataInfoClientLA = true;
      this.dataInfoClientLA = null;
      this.getdataInfoClientLAError = null;


      this.loadingdataInfoClientAKB = true;
      this.dataInfoClientAKB = null;
      this.getdataInfoClientAKBError = null;


      this.buttomModal = true;
      this.animate_init_bottom = false;
      this.animate_contact = true;
      this.animate_verifi = false;
      this.animate_solic = false;
      this.currentView = 'transaction';
      if (this.localStorage.getItem('objBackTransaction')) {
        this.back = JSON.parse(this.localStorage.getItem('objBackTransaction'));
      }
    }


  }

  @Output() change = new EventEmitter();


  constructor(
    private i18n: I18nPipe,
    public _httpService: HttpService,
    public localStorage: LocalService,
    private _upload: UploadService,
    private akWorkflowService: AkeelaWorkflowService,
    private router: Router,
    public _auth: AuthService,
    private _wfService: WorkflowService,
    private _error: ErrorService,
    private formBuilder: FormBuilder,
    public _notes: NotesService



  ) { }

  ngOnInit(): void {
    this.mdActve = true;

    let a = this.localStorage.removeItem('clientName');
    let b = this.localStorage.removeItem('clientUsername');
    let c = this.localStorage.removeItem('clientIdentifier');
    let d = this.localStorage.removeItem('clientaudience');



    this.profile = this.localStorage.getItem('profile');


    if (environment.theme.images === 'RENDIVALORES' ||  environment.theme.images === 'MC'  || environment.theme.images === 'KOI'  ) {

      this.verificationLA = true;

    }
    
/*
    if (environment.theme.images === 'MC') {

      this.verificationAKB = true;


    }
    */

    this._wfService.listWF$.subscribe((listWf: any) => {

      if (listWf) {
        this.listWf = listWf;

      }

    });

    this.role = this.localStorage.getItem('role');


    this.env = environment;

    this.folder = '';

    if (environment.theme.images === 'FACEBANK') {

      this.folder = 'facebank/';

    } else if (environment.theme.images === 'MIBANCO') {

      this.folder = 'mibanco/';

    } else if (environment.theme.images === 'CARONI') {

      this.folder = 'caroni/';

    }
    else if (environment.theme.images === 'RENDIVALORES') {

      this.folder = 'rendivalores/';

    }
    else if (environment.theme.images === 'MC') {

      this.folder = 'mc/';

    }

    this.getOptionsResponses();


    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }

    this.workflowEventsSubscription = this.akWorkflowService.worflowEvents$.subscribe((response: any) => {

      if (response?.action === 'reloadDataResponse') {
        this.akWorkflowService.setTransactionNumber(this._transaction);
        //this.changes();


      }
    });

  }

  getTransaction() {


    if (this.transitionSubscription) {
      this.transitionSubscription.unsubscribe();
    }

    this.transitionSubscription = this.akWorkflowService.transactionData$.subscribe((response: any) => {
      if (!isNullOrUndefined(response)) {
        this.transactionData = response;
        //  this.get_data_identity();

        const  {
          firstAnalystRevisionComments, 
          firstAnalystRevisionDate,
          firstAnalystRevisionFlag,
          firstAnalystRevisionUsername
        } =  this.transactionData?.accountStatus[0];

        this.firstAnalystRevision = {
          firstAnalystRevisionComments,
          firstAnalystRevisionDate,
          firstAnalystRevisionFlag,
          firstAnalystRevisionUsername

         }


        this.getSectionsIncompleatList()
        this.getUser();
        this.getSelfie();
        this.getTransactions();
        this.getPreview(this.transactionData?.transactionNumber, this.transactionData?.holder?.additionalType);

      }
    });
  }



  setRequirementsList = (list) => this.requirements = list;

  setBack = () => {
    this.buttomModal = false;
    this.change.emit({
      open: 'contact',
      value: this.back.username
    });
  }

  getPreview(transactionNumber, audience) {
    const params = {
      name: 'account_opening$preview-document$get',
      params: {
        request: {
          role: 'CLIENT',
          audience: audience
        },
        body: {},
        path: {
          transactionNumber: transactionNumber
        }
      }
    };


    this._httpService.executeRequest(params.name, params.params, true).subscribe((resp: any) => {
      if (resp.length > 0) {
        this.preview = resp;

      }
    }, error => {
      this.preview = null;
    });
  }

  wait() {
    swal.fire({
      title: '',
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false,
      html: '<b>' + this.i18n.transform('@i18n-text-please-wait') + '...<b><br><i class="fa fa-spin fa-spinner"></i>',
    });
  }

  show_filePreview() {
    if (this.preview[0]) {
      this.show_file(this.preview[0]);

    }
  }

  show_file(file) {


    const archive = {
      identifier: file.identifier,
      name: file.name,
      dateCreated: {
        orderDate: file.dateCreated.modifiedDate
      }
    };


    if (archive.identifier !== null) {
      this._upload.visor_params.name = '';
      this._upload.visor_params.date = '';
      this._upload.visor_params.endpoint = 'archive$downloadFile$get';
      this._upload.visor_params.archive = archive;
      this._upload.visor = true;
    }
  }

  getSelfie = () => {
    const params = {
      name: 'person$selfie$get',
      params: {
        request: {
          'username': this.transactionData?.requestUser?.identifier
        },
        path: {},
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.dataAndSelfie = response;
      this.filePreview = 'data:image/jpg' + ';base64,' + response?.description;
    });
  }

  getUser = () => {

    const params = {
      name: 'person$enduser$get',
      params: {
        request: {
          'username': this.transactionData?.requestUser?.identifier
        },
        path: {},
        body: {}
      }
    };
    this.loading = true;
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      this.clientDetail = response;

      if (this.isLegal(this.clientDetail)) {
        this.verificationLA = false;
      }



      this.loading = false;
      this.compleatVerifi = response?.person?.completeIdentification;
      this.verifiedIdentification = response?.person?.verifiedIdentification;
      this.getDataInformations();



    });
  }

  changes() {

    let html = document?.getElementById("idHtml");
    html?.classList?.remove('scroll-none');

    this.buttomModal = false;
    this.change.emit(false)
  }

  async getTransactionData(transactionNumber): Promise<any> {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.localStorage.getItem('role'),
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    return await this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }

  applyFilter = (event) => this.filterRequirements = [event];


  setView = (view) => this.currentView = view;

  validateView = (view) => {
    return this.currentView === view;
  }

  returnToClient = () => {
    this.buttomModal = false;
    this.change.emit({
      open: 'contact',
      value: this.transactionData?.requestUser?.identifier
    });
  }




  get_data_identity() {

    const params = {
      name: 'person$get_data_identity$get',
      params: {
        request: {
          'username': this.transactionData?.requestUser?.identifier
        },
        path: {},
        body: {}
      }
    };



    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      this.dataIdentity = response;

    });

  }


  getPerson(identifier) {
    const params = {
      name: 'person$person$get',
      params: {
        request: { id: identifier },
        path: {},
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }


  getTransactionData2(transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.localStorage.getItem('role'),
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  deleteEditTransaction() {
    if (!isNullOrUndefined(this.localStorage.getItem('transactionModeEdit'))) {
      this.breakfreeTransactionEdit(); // Mario: este caso era de liberación individual, pero debido a que ahora se va a liberar por usuario, se comenta para ver el comportamiento
      this.breakfreeTransactionsByUser();
    } else {
      this.breakfreeTransactionsByUser();
    }
  }

  breakfreeTransactionsByUser() {
    const configuration = {
      name: 'account$finish_editing_role_by_user$put',
      params: {
        path: {},
        request: {
          role: 'USER',
          systemUser: this._auth.getUsername()
        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {

      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.akWorkflowService.transactionData$.next(null);

    }, error => {

      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.akWorkflowService.transactionData$.next(null);

    });
  }


  breakfreeTransactionEdit() {
    const configuration = {
      name: 'account_opening$finish_editing_role$put',
      params: {
        path: {
          transactionNumber: this.localStorage.getItem('transactionModeEdit')
        },
        request: {
          role: 'USER',
          systemUser: this._auth.getUsername()
        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.akWorkflowService.transactionData$.next(null);

    }, error => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.akWorkflowService.transactionData$.next(null);

    });
  }

  openFormalities = async (transactionNumber) => {
    // this.deleteEditTransaction();
    this.wait();
    this.localStorage.removeItem('transactionModeEditShowFlag');

    // this.transitionService.showWorkflow$.next(false);

    this.akWorkflowService.setTransactionNumber(transactionNumber);


    try {
      let data: any = await this.getTransactionData2(transactionNumber).toPromise();

      this.akWorkflowService.transactionData$.next(data);


      let audience = "NATURAL";

      try {
        await this.getPerson(data?.holder.identifier);
      } catch (error) {
        audience = "LEGAL";
      }


      let taken = false;
      const temp = data.accountStatus[0].additionalType;
      if (data && temp !== 'AC_PENDING' && temp !== 'AC_RECEIVED' && temp !== 'AC_REJECTED') {
        if (data?.takenByRol && data?.takenByRol?.trim() !== this.role.trim() && data.takenByRol !== '') {
          taken = true;
        }
      }


      this.redirect(transactionNumber, audience, false);


    } catch (error) {
      swal.close();

    }

  }



  redirect(transactionNumber, audience, close?) {


    //this.localStorage.setItem('clientaudience', audience);
    this.localStorage.setItem('audience', audience);
    this.localStorage.setItem('sectionRedirect', true);

    const queryParams = {
      role: this.localStorage.getItem('role'),
      profile: this.localStorage.getItem('profile'),
      audience: this.localStorage.getItem('audience'),
      transactionNumber: transactionNumber,
    };
    swal.close();

    //let url = 'console/transaction-dashboard?role=' + queryParams.role + '&profile=' + queryParams?.profile + '&audience=' + queryParams?.audience + '&transactionNumber=' + queryParams?.transactionNumber + "&wo=true";
    //window.open(url, '_blank');


    this.router.navigateByUrl('console/transaction-dashboard', { queryParams: queryParams });


  }


  getOptionsResponses() {
    const params = {
      name: 'account$stageresponses$get',
      params: {
        request: {},
        path: {},
        body: {}
      }
    };

    if (this.optionsResponsesSubscription) {
      this.optionsResponsesSubscription.unsubscribe();
    }
    this.optionsResponsesSubscription = this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.optionsResponses = response;
    });
  }


  getsectionsIncompleat() {
    const params = {
      name: 'account_opening$sections_completed$get',
      params: {
        request: {},
        path: {
          transactionNumber: this.transactionData?.transactionNumber,
          mtWorkflow: this.transactionData?.plan?.url
        },
        body: {}
      }
    };

    return this._httpService.executeRequest(params.name, params.params, true);

  }



  getSectionsIncompleatList() {
    this.sectionsIncompleatSubscription = this.getsectionsIncompleat().subscribe((response: any) => {
      let sectionsIncompleat: any = [];

      if (response?.length > 0) {

        for (let row of response) {
          row['check'] = true;
          sectionsIncompleat = [...sectionsIncompleat, row]
        }


      }

      this.sectionsIncompleat = sectionsIncompleat;
    });

  }



  setsectionsIncompleat(data) {
    const params = {
      name: 'account$responsestransactionsincompleted$post',
      params: {
        request: {},
        path: {
          transactionNumber: this.transactionData?.transactionNumber,
          identifierPerson: this.transactionData?.holder?.identifier
        },
        body: data
      }
    };

    return this._httpService.executeRequest(params.name, params.params, true);

  }



  setTransactionResponse = async (data) => {

    let audience = 'NATURAL';

    try {
      await this.getPerson(this.transactionData?.holder.identifier);

    } catch (error) {
      audience = "LEGAL";
    }

    const params = {
      name: 'account_opening$account_response$post',
      params: {
        request: {
          role: this.localStorage.getItem('role'),
          audience: audience
        },
        path: {
          transactionNumber: this.transactionData?.transactionNumber,
          workflow: this.transactionData?.plan?.url,
          stage: 'ACCOUNT_CONTRACT_ANALYSIS',
          step: 'REVISIONS_TAB'

        },
        body: data
      }
    };

    return this._httpService.executeRequest(params.name, params.params, true).toPromise();

  }



  setResponseAprovEjecutive = async (data) => {

    let audience = 'NATURAL';

    try {
      await this.getPerson(this.transactionData?.holder.identifier);

    } catch (error) {
      audience = "LEGAL";
    }

    const params = {
      name: 'account_opening$send_analysis_without_wkf$put',
      params: {
        request: {
          role: this.localStorage.getItem('role'),
          audience: audience
        },
        path: {
          transactionNumber: this.transactionData?.transactionNumber
        },
        body: data
      }
    };

    return this._httpService.executeRequest(params.name, params.params, true).toPromise();

  }


  setResponseIncompleEjecutive = async (data) => {

    let audience = 'NATURAL';

    try {

      await this.getPerson(this.transactionData?.holder.identifier);

    } catch (error) {

      audience = "LEGAL";

    }
    
    const params = {
      name: 'account_opening$returnToClient$post',
      params: {
        request: {
          transactionNumber: this.transactionData?.transactionNumber
        },
        path: {
        },
        body: data
      }
    };

    return this._httpService.executeRequest(params.name, params.params, true).toPromise();

  }


  submitResponse = async ()=> {

    if (this.responseData?.option != '') {
      if (this.saveSectionsIncompleatSubscription) {
        this.saveSectionsIncompleatSubscription.unsubscribe();
      }


      if (this.sectionsIncompleatSubscription) {
        this.sectionsIncompleatSubscription.unsubscribe();
      }

      if (this.responseSubscription) {
        this.responseSubscription.unsubscribe();
      }


      let items = [];
      let jsonResp;

      if (this.responseData?.option == 'AC_INCOMPLETE') {

        try {
         // await  this.firstAnalystRevFlagServ(false);
  
        } catch (error) {
        }

        if (this.sectionsIncompleat?.length > 0) {


          for (let row of this.sectionsIncompleat) {

            console.log(row);
            console.log(this.clientDataResp);
            if (!row?.check) {
              console.log(row.check);
              items = [...items, { "name": row?.identifier, "description": "Ajustar lo requerido" }]

            }
            // items = [...items, { "name": "191", "description": "Ajustar lo requerido 2" }]
            // console.log(items);

            if (items?.length > 0) {

              jsonResp =
              {
                "modifyUser": this._auth.getUsername(),
                "sectionsIncomplete": items
              }

              this.saveSectionsIncompleatSubscription = this.setsectionsIncompleat(jsonResp).subscribe((response: any) => {


                if (response) {

                  this.saveResponse();
                }


              }
                , error => {
                  this._error.show(error);

                });


            } else {

              this.saveResponse();


            }
          }

        } else {

          this.saveResponse();

        }

      } else {
        this.saveResponse();

      }

    }


  }




  backCompleat() {
    let data: any;


    const params = {

      name: 'briefcase$reset_complete-data$post',
      params: {
        request: {
          'username': this.clientDetail?.identifier,
          'requestUser': this._auth.getUsername(),
          'comments': ''
                },
        path: {},
        body: {}
      }
    };

    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      if (response) {

        this.getDataInformations();

      }
    }, error => {


    });

  }


  sendCompleat() {
    let data: any;

    const params = {

      name: 'briefcase$verified$post',
      params: {
        request: {
          'username': this.clientDetail?.identifier,
          'requestUser': this._auth.getUsername(),
          'comments': '',
          'verified': true
        },
        path: {},
        body: {}
      }
    };


    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      if (response) {

        this.getDataInformations();

      }
    }, error => {


    });

  }

  saveResponse = async () => {

    swal.fire({
      title: '',
      html: this.i18n.transform('¿Estas seguro que deseas dar respuesta a esta solicitud?'),
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then(async (result) => {

      if (result?.isConfirmed) {

        debugger
        
        if(this.isResponseAnalist){
          this.respAnalist();
        }

        if(this.isResponseEjecutive){
          this.respEjecutivo();
        }
      
      }
    });

  }



  respEjecutivo = async ()=>{
    let resp;
    this.responseData?.option

    let items = [];
    for (let row of this.sectionsIncompleat) {

        
        console.log(row);
        console.log(this.clientDataResp);
        if (!row?.check) {
          console.log(row.check);
          items = [...items, { "name": row?.identifier, "description": "Ajustar lo requerido" }]

        }
        // items = [...items, { "name": "191", "description": "Ajustar lo requerido 2" }]
        // console.log(items);

        
    }
    
      if (items?.length > 0) {
        resp = { "name": 'AC_INCOMPLETE', "responseDescription": this.responseData?.description, "modifyUser": this._auth.getUsername(), "sectionsIncomplete": items }
      } else {
        resp = { "name": 'AC_INCOMPLETE', "responseDescription": this.responseData?.description, "modifyUser": this._auth.getUsername() }
      }

    
    // let resp = { "name": 'AC_INCOMPLETE', "responseDescription": this.responseData?.description, "modifyUser": this._auth.getUsername() }

    try {

      const opt:any = this.responseData?.option;

      if(opt == 1){
  
        await this.setResponseAprovEjecutive(resp);

        if ( this.dataInformation?.completeBriefcase && !this.dataInformation?.verifiedBriefcase) {
  
          this.sendCompleat();
  
        }
  
      }else{

        
  
        await this.setResponseIncompleEjecutive(resp);

        if (!this.clientDataResp &&  this.dataInformation?.completeBriefcase) {

          this.backCompleat();
       
        }


      }

       this.setNoteResponseTransactionEjecutivo(this.transactionData?.transactionNumber);

       this.akWorkflowService.worflowEvents$.next({
        value: '',
        action: 'reloadDataResponse',
        text: ''   

      });

    } catch (error) {

      this._error.show(error);

    }    
  }


  respAnalist = async ()=>{


    let resp = { "name": this.responseData?.option, "responseDescription": this.responseData?.description, "modifyUser": this._auth.getUsername(), "status": "NOREQUIREMENTS", "sectionsIncompleteAccredited": [] }

    try {

      await this.setTransactionResponse(resp);

      this.setNoteResponseTransaction(this.transactionData?.transactionNumber);


      if (this.responseData?.option == 'AC_RECEIVED' && this.dataInformation?.completeBriefcase && !this.dataInformation?.verifiedBriefcase) {

        this.sendCompleat();

      } else if (!this.clientDataResp && this.responseData?.option == 'AC_INCOMPLETE' && this.dataInformation?.completeBriefcase) {


        this.backCompleat();

      }

      this.akWorkflowService.worflowEvents$.next({
        value: '',
        action: 'reloadDataResponse',
        text: ''
      });



      if (this.verificationLA) {
        let identity = `${this.dataInformation?.identity || this.dataInformation?.taxID}`;
        this.getDataInfoClientLA(identity);
      }

      if (this.verificationAKB) {
        let ci = `${this.dataInformation?.identity}`;
        this.getDataInfoClientAKB(ci);
      }

    } catch (error) {

      this._error.show(error);
    }


  }

  destroySubscription() {
    let html =  document?.getElementById("idHtml");
    html?.classList?.remove('scroll-none');

    if (this.transitionSubscription) {
      this.transitionSubscription.unsubscribe();
    }


    if (this.dataInfoClientLASubscription) {
      this.dataInfoClientLASubscription.unsubscribe();
    }

    if (this.listWfSubscription) {
      this.listWfSubscription.unsubscribe();
    }

    if (this.optionsResponsesSubscription) {
      this.optionsResponsesSubscription.unsubscribe();
    }

    if (this.saveSectionsIncompleatSubscription) {
      this.saveSectionsIncompleatSubscription.unsubscribe();
    }


    if (this.sectionsIncompleatSubscription) {
      this.sectionsIncompleatSubscription.unsubscribe();
    }

    if (this.responseSubscription) {
      this.responseSubscription.unsubscribe();
    }


    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }


    if (this.dataInformationsSubscription) {
      this.dataInformationsSubscription.unsubscribe();
    }

  }

  ngOnDestroy() {
    this.mdActve = false;

    this.destroySubscription();

  }


  getListWf(wf) {
    return this.listWf[wf];
  }




  isLegal = (clientDetail = this.clientDetail) => {

    return clientDetail?.personMarketType?.audienceType == 'LEGAL';

  }
  getDataInformations() {

    const params = {
      name: this.isLegal(this.clientDetail) ? 'briefcase$business_person$get' : 'briefcase$person$get',
      params: {
        request: {
          'id': this.isLegal(this.clientDetail) ? this.clientDetail?.adminOrganization?.identifier : this.clientDetail?.person?.identifier
        },
        path: {},
        body: {}
      }
    };

    
    this.dataInformationsSubscription = this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {


      this.dataInformation = response;


      if (this.verificationLA) {
        //let ci = `V${'13132132'}`
        let identity = `${this.dataInformation?.identity || this.dataInformation?.taxID}`;
        this.getDataInfoClientLA(identity);
      }


      if (this.verificationAKB) {
        //let ci = `V${'13132132'}`
        let ci = `${this.dataInformation?.identity}`;
        this.loadingdataInfoClientAKB = true;
        this.getdataInfoClientAKBError = null;
        this.dataInfoClientAKB = null;

        this.getDataInfoClientAKB(ci);
      }


    });




  }


  getDataInfoClientLA(identity) {
    this.loadingdataInfoClientLA = true;


    this.dataInfoClientLA = null;

    this.getdataInfoClientLAError = null;

    if (this.dataInformationsSubscription) {
      this.dataInformationsSubscription.unsubscribe();
    }

    const params = {
      name: 'core_integration$consultarClienteLA$get',
      params: {
        request: {
          'numeroDocumento': identity
        },
        path: {},
        body: {}
      }
    };

    this.dataInfoClientLASubscription = this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      if (Object.keys(response)?.length > 0) {
        this.dataInfoClientLA = response;
      }


      this.loadingdataInfoClientLA = false;


    },
      error => {
        this.getdataInfoClientLAError = true;
        this.loadingdataInfoClientLA = false;
      });

  }



  getDataInfoClientAKB = async (identity) => {

    

    if (this.dataInformationsSubscription) {
      this.dataInformationsSubscription.unsubscribe();
    }

    const params = {
      name: 'core_integration$consultarClienteAKB$get',
      params: {
        request: {
          'numeroDocumento': identity
        },
        path: {},
        body: {}
      }
    };

    try {

      let response = await this._httpService.executeRequest(params.name, params.params, true).toPromise();

      if (Object.keys(response)?.length > 0) {
        this.dataInfoClientAKB = response;
      }


      this.loadingdataInfoClientAKB = false;


    } catch (error) {

      this.getdataInfoClientAKBError = true;
      this.loadingdataInfoClientAKB = false;


    }

  }


  getTransactions() {

    this.transactionCompleat = null;

    const params = {
      name: 'account$myformalities_paginated$get',
      params: {
        request: {
          page: 0,
          size: 60
        },
        path: {
          username: this.transactionData?.requestUser?.identifier
        },
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {



      const transactions = response.content;


      for (const transaction of transactions) {

        let row = transaction;



        if (row.accountStatus[0].additionalType == 'AC_RECEIVED') {

          this.transactionCompleat = row.identifier;

        }



      }

    }, error => {
    });
  }



  crearPersonaNaturalLA = () => {
    const params = {

      name: 'core_integration$crearPersonaLA$post',
      params: {
        request: {
          "idAccount": this.transactionCompleat,
          "idPerson": this.clientDetail?.person?.identifier,
          "audience": this.clientDetail?.personMarketType?.audienceType
        },
        path: {},
        body: {}

      }
    };

    this.loadingdataInfoClientLA = true;
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      if (response) {

        let identity = `${this.dataInformation?.identity || this.dataInformation?.taxID}`;
        this.getDataInfoClientLA(identity);

      }
      this.loadingdataInfoClientLA = false;


    }, error => {
      // this._error.show(error);
      // this.loadingdataInfoClientLA = false;
      let error_prov = '';
      if (this.is_json(error)) {
        error_prov = JSON.stringify(error);
      }
      if (error_prov.includes('PersonServiceProxy#senNotification')) {
        this.getdataInfoClientLAError = true;
        this.loadingdataInfoClientLA = false;
      } else {
        this._error.show(error);
        this.loadingdataInfoClientLA = false;
      }


    });

  }

  is_json = (str) => {
    try {
      JSON.stringify(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  crearPersonaNaturalAKB = () => {
    const params = {

      name: 'core_integration$crearPersonaNaturalAKB$post',
      params: {
        request: {
          "idAccount": this.transactionCompleat
        },
        path: {},
        body: {}
      }
    };

    this.loadingdataInfoClientLA = true;
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      if (response) {

        let identity = `${this.dataInformation?.identity || this.dataInformation?.taxID}`;
        this.getDataInfoClientLA(identity);

      }
      this.loadingdataInfoClientLA = false;


    }, error => {
      let error_prov = '';
      if (this.is_json(error)) {
        error_prov = JSON.stringify(error);
      }
      if (error_prov.includes('PersonServiceProxy#senNotification')) {
        this.getdataInfoClientLAError = true;
        this.loadingdataInfoClientLA = false;
      } else {
        this._error.show(error);
        this.loadingdataInfoClientLA = false;
      }
    });

  }


  setNoteResponseTransaction = async (transacton) => {

    const env: any = environment;

    const nameClient = this.clientDetail?.personMarketType?.audienceType == 'NATURAL' ?
      `${this.clientDetail.person.givenName} ${this.clientDetail?.person?.familyName}` :
      `${this.clientDetail?.adminOrganization?.legalName}`;

    const arreglo = this.optionsResponses.filter(data => data?.code == this.responseData?.option)
    const res = arreglo[0]?.name;

    const data = {
      "environment_code": env?.code || env?.theme?.images,
      "type": "RESP_TRANSACTON",
      "show_note": "TRUE",
      "note_extra_data_json": "",
      "client": this.clientDetail?.identifier,
      "full_name": nameClient,
      "ejecutive": this._auth.getUsername(),
      "ejecutive_update": "",
      "title": '',
      "subtitle": 'Nueva solicitud enviada',
      "status_view": "TRANSACTON",
      "extra_data": "",
      "note": this.i18n.transform(`Se ha dado respuesta ( ${res} ) a la solicitud #${transacton} ${this.responseData?.description && ', '}${this.responseData?.description || ''}`),
    };

    await this._notes.saveNote(data);
  };


  setNoteResponseTransactionEjecutivo = async (transacton) => {

    const env: any = environment;

    const nameClient = this.clientDetail?.personMarketType?.audienceType == 'NATURAL' ?
      `${this.clientDetail.person.givenName} ${this.clientDetail?.person?.familyName}` :
      `${this.clientDetail?.adminOrganization?.legalName}`;

      const responseData:any = this.responseData;

      let resp = responseData?.option == 1? 'Aprobada': "Devuelta"

      //colocar el profile
    const data = {

      "environment_code": env?.code || env?.theme?.images,
      "type": "RESP_TRANSACTON",
      "show_note": "TRUE",
      "note_extra_data_json": "",
      "client": this.clientDetail?.identifier,
      "full_name": nameClient,
      "ejecutive": this._auth.getUsername(),
      "ejecutive_update": "",
      "title": '',
      "subtitle": 'Nueva solicitud enviada',
      "status_view": "TRANSACTON",
      "extra_data": "",
      "note": this.i18n.transform(`Se ha dado respuesta  ${resp } por parte del ejecutivo a la solicitud #${transacton} ${this.responseData?.description && ', '}${this.responseData?.description || ''}`),
   
    };

    await this._notes.saveNote(data);
  };

  get isResponseEjecutive() {
    return this.transactionData?.accountStatus[0]?.additionalType == 'EXECUTIVE_REVIEW' && this.profile == 'SALES_MANAGER';
 
    //return this.profile == 'SALES_MANAGER';
  }


  get isResponseAnalist() {

    return this.transactionData?.accountStatus[0]?.additionalType == 'AC_PENDING' && this.profile == 'ANALIST' &&  this._auth.getSession()?.business_unit != "BU_RIES";  

  }

   epoch (date) {
    return Date.parse(date)
  }
  
  setFirstAnalystRevFlag = async () =>{

    if(this.checkreviewed?.option != ""){

      try {

        await  this.firstAnalystRevFlagServ(true,this.checkreviewed?.description);

        this.setNoteResponseFlag(this.transactionData?.transactionNumber);


        const timestamp = this.epoch(new Date())
    

        this.firstAnalystRevision =  {
      
            "firstAnalystRevisionFlag": true,
            "firstAnalystRevisionComments":this.checkreviewed?.description || "",
            "firstAnalystRevisionDate": timestamp,
            "firstAnalystRevisionUsername": this._auth.getUsername()
            
          }
  

        swal.fire({

          title: '',
          html: 'Se ha dado respuesta satisfactoriamente',
          
        });



      } catch (error) {
        

      }

    }


  }

  setTransactionData = (response) => {

  }


  setNoteResponseFlag = async (transacton) => {

    const env: any = environment;

    const nameClient = this.clientDetail?.personMarketType?.audienceType == 'NATURAL' ?
      `${this.clientDetail.person.givenName} ${this.clientDetail?.person?.familyName}` :
      `${this.clientDetail?.adminOrganization?.legalName}`;


      //colocar el profile

    const data = {
      "environment_code": env?.code || env?.theme?.images,
      "type": "RESP_TRANSACTON",
      "show_note": "TRUE",
      "note_extra_data_json": "",
      "client": this.clientDetail?.identifier,
      "full_name": nameClient,
      "ejecutive": this._auth.getUsername(),
      "ejecutive_update": "",
      "title": '',
      "subtitle": 'Nueva solicitud enviada',
      "status_view": "TRANSACTON",
      "extra_data": "",
      "note": this.i18n.transform(`Se ha dado respuesta por parte del (Analista de Riesgo) a la solicitud #${transacton} ${this.checkreviewed?.description && ', '}${this.checkreviewed?.description || ''}`),
    };

    await this._notes.saveNote(data);
  };

 firstAnalystRevFlagServ = async (resp,description?) => {

    const timestamp = this.epoch(new Date())
    

  const datas =  {

      "firstAnalystRevisionFlag": resp,
      "firstAnalystRevisionComments":description || "",
      "firstAnalystRevisionDate": timestamp,
      "firstAnalystRevisionUsername": this._auth.getUsername()
  
    }
    
    const params = {
      name: 'account_opening$firstAnalystRevisionFlag$post',
      params: {
        request: {
          transactionNumber: this.transactionData?.transactionNumber
        },
        path: {
        },
        body: datas
      }
    };

    return this._httpService.executeRequest(params.name, params.params, true).toPromise();

  }

}

