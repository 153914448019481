import { Component, OnInit } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { I18nService, I18nPipe } from '@akeela/i18n';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { TransitionService } from '../../../../services/transition.service';
import { EmailService } from '../../../../services/email.service';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { isNullOrUndefined } from 'util';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';



@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class PublicDashboardComponent implements OnInit {
  params_products;
  params_plans;
  plans;
  product;
  states;
  forms: any = {};
  submit_disabled;
  loading_form: boolean = true;
  currentStep;

  workflow;
  stage;
  step;
  event = 'USER_CREATED_ACCOUNT_OPENING';
  url_transition = 'workflow$transitionssteps$get';
  showSubHeaderStep;
  showSubHeaderStepSubscription;



  constructor(
    private formBuilder: FormBuilder,
    private _httpService: HttpService,
    private _akeelaStepService: AkeelaStepService,
    private _akeelaWorkflowService: AkeelaWorkflowService,
    private _i18n: I18nService,
    private i18nPipe: I18nPipe,
    private route: ActivatedRoute,
    private router: Router,
    private transitionService: TransitionService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private akSectionService: AkeelaSectionService,
    private _email: EmailService,
    private _breadcrumbService: AkeelaBreadcrumbService,

  ) { }

  ngOnInit() {
    this._breadcrumbService.setbreadcrumbOptions([]);

    this.showSubHeaderStepSubscription = this.akStepService.showSubHeaderStep$.subscribe(data => {

      this.showSubHeaderStep = data;

    });
    this.workflow = 'ACCOUNT_OPENING';
    this.stage = 'CREATE_USER_ACCOUNT_OPENING';
    this.step = 'CREATE_USER_ACCOUNT_OPENING';



    document.body.classList.add('teme-gray');
    this.params_products = {
      endpoint: {
        products: {
          name: 'product_plan$father_products$get',
          params: {
            path: {},
            request: {}
          }
        }
      }
    };


    this._akeelaStepService.currentStep$.subscribe((current) => {
      if (current) {
        this.currentStep = current;
      }
    });

    this._akeelaWorkflowService.worflowEvents$.subscribe((event: any) => {
      if (event) {
        if (event.action === 'setNewClient') {
          this.sendMail(event.response.person.email, event.response).subscribe((event: any) => {
          swal.fire({
            title: this.i18nPipe.transform('@i18n-text-user-registred'),
            html: this.i18nPipe.transform('@i18n-text-user-registred-detail'),
          });
          setTimeout(() => {
           
            this.login();

          }, 30);
        });
        }
      }
    });



    this._akeelaWorkflowService.worflowEvents$.subscribe((event: any) => {
      if (event) {
        if (event.value === '2: 3') {
          //defaultValue, DataFieldItem.identifier 50
          this._akeelaWorkflowService.queryParams$.next({
            'audience': 'LEGAL'
          });
          setTimeout(() => {

            let obj = {
              section: 'CREATE_USER_ACCOUNT_OPENING',
              response: {
                applicantType: '3'
              }
            }
            this.akSectionService.externalUpdateSectionForm$.next(obj);
          }, 20);
        } else {
          this._akeelaWorkflowService.queryParams$.next({
            'audience': 'NATURAL'
          });
          setTimeout(() => {
            let obj = {
              section: 'CREATE_USER_ACCOUNT_OPENING',
              response: {
                applicantType: '2'
              }
            }
            this.akSectionService.externalUpdateSectionForm$.next(obj);
          }, 20);
        }

      }
    })

  }

  sendMail(emailTo, data) {


    return new Observable(resolve => {


    let url = '';
    let portal = 'Facebank';
    let properties: object = {};
    properties['template'] = 'templates/' + this._i18n.lang + '/userconfirmation.html';

    let site_header = environment.theme.site.name;
    if (!isNullOrUndefined(environment.theme.image)) {
      if (!isNullOrUndefined(environment.theme.image.active)) {
        if (environment.theme.image.active) {
          site_header = '<img src="' + environment.theme.image.urlImageHeader + '"></img>';
        }
      }
    }

    properties['attr'] = [
      { 'key': '%%USERNAME%%', 'value': data.identifier },
      { 'key': '%%URLDESCRIPTION%%', 'value': this.i18nPipe.transform('@i18n-mail-verify-account') },
      { 'key': '%%NOMBRE%%', 'value': data.person.givenName },
      { 'key': '%%PORTAL%%', 'value': portal },
      {
        'key': '%%ALIAS_APP%%',
        'value': site_header
      },
      {
        'key': '%%TEXTFOOTER%%',
        'value': !isNullOrUndefined(environment.theme.textFooterMail) ? environment.theme.textFooterMail : ''
      }];
    let email_message = {
      identifier: 1,
      dateOfThing: {},
      status: null,
      sender: null,
      text: null,
      type: 'text/html',
      subjectOf: 'Registro de usuario',
      toRecipient: emailTo
    };
    let template = this._email.getTemplate(properties);
    template.subscribe(template_mail => {
      let response = this._email.sendMail(
        email_message,
        properties,
        template_mail
      );
      response.subscribe(mail_result => {
        console.log(mail_result);
        resolve.next(mail_result);
        resolve.complete();

      }, error => {
        console.log(error);
        resolve.next(false);
        resolve.complete();

      });
    }, error => {
      console.log(error);
      resolve.next(false);
        resolve.complete();
    });

  });
  }




  login() {
    this.router.navigateByUrl(this.router.createUrlTree
      (['security/login'],
      {
        queryParams: {}
      }
      )
    );
  }


  register(audience) {
    this.router.navigateByUrl(this.router.createUrlTree
      (['dashboard/ACCOUNT_OPEN/stages/CREATE_USER_ACCOUNT_OPENING/steps/CREATE_USER_ACCOUNT_OPENING'],
      {
        queryParams: {
          role: 'C',
          audience: audience
        }
      }
      )
    );
  }

  ngOnDestroy() {
    document.body.classList.remove('teme-gray');
  }

}
