<div class="d-none" *ngIf="showSubHeaderStep" [ngClass]="{'d-block':showSubHeaderStep}">

    <plans-subheader></plans-subheader>
</div>
<!--
<products-akeela-template>
    <ng-template products-akeela-content componentName="sectionRequirementsPDFImageHeader" let-persontype>
        <div>
            <div>
                <app-logo-img [theme]="env?.theme?.logoConf?.themeColorPdf" [type]="env?.theme?.logoConf?.typePdf"></app-logo-img>
            </div>
            <br>
            <br>
            <div class="text-center">RECAUDOS PARA APERTURA DE CUENTA
                <br> {{ persontype }}
                <br>
                <br>
                <i>El solicitante debe consignar dos copias de los documentos y el original para la validación de las copias entregadas</i>
                <br>
                <br>
            </div>
        </div>
    </ng-template>
    
    <ng-template products-akeela-content componentName="imgLogo" let-plan>
        <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>
    </ng-template>

    <ng-template products-akeela-content componentName="detailForm" let-plan>

        <div class="text-center ml-auto mr-auto">
            <div>
                <i [ngClass]="product.image?.iconClass? product.image?.iconClass :'fab fa-product-hunt fa-4x'" aria-hidden="true"></i>
            </div>
            <div style="font-size: 0.8rem;	 font-weight: bold; line-height:  0.8125rem;margin-top: 15px;" [innerHTML]="product.name | i18n"></div>
            <br>
        </div>

        <div class="font-weight-bold title" [innerHTML]="plan.name | i18n"></div>

        <div *ngIf="plan.images">
            <div class="img-plan d-none d-lg-block ">
            </div>
        </div>

        <div class="row">
            <div class="" [ngClass]="[plan.type == 'img' ? 'col-12' : 'col-lg-10']" [innerHTML]="plan.description | i18n"></div>
            <div class="col-12">
                <div class="col-12 plan-descripotion"></div>
            </div>
        </div>
        <hr>
        <div class="row">
            <!-- <div class="col-12 mb-4">
        <div class="font-weight-bold section-title" [innerHTML]="'@i18n-plans-description' | i18n:lang | ucwords "></div>
      </div>
      <div [ngClass]="[plan.type == 'img' ? 'col-md-6' : 'col-md-5','m-b-1']">
        <div>
          <div class="label" [innerHTML]="'@i18n-plans-label-rate' | i18n:lang "></div>
          <div class="c-label">
            <span *ngFor="let rate of plan.rates">
              <div class="c-label" *ngIf="rate.alternateName">
                {{ rate.percentage }}%
                <span class="c-label" *ngIf="rate.alternateName=='R'" [innerHTML]="'@i18n-plans-text-rate-fixed' | i18n:lang"></span>
                <span class="c-label" *ngIf="rate.alternateName=='I'" [innerHTML]="'@i18n-plans-text-rate-unfixed' | i18n:lang"></span>
              </div>
            </span>
          </div>
        </div>
      </div> -->
            <!-- <div [ngClass]="[plan.type == 'img' ? 'col-md-6' : 'col-md-5','m-b-1']">
        <div>
          <div [innerHTML]="'@i18n-plans-label-financeAmount' | i18n:lang" class="label"></div>
          <ng-template [ngIf]="plan.amount.minValue" [ngIfElse]="noAmountMinValue">
            <div>
              <span class="c-label" [innerHTML]="'@i18n-plans-global-text-until' | i18n:lang"></span> {{ plan.amount.minValue | number:'1.2-2' | numberI18n:'es' }}</div>
          </ng-template>
          <ng-template #noAmountMinValue>
            <ng-template [ngIf]="plan.amount.maxValue" [ngIfElse]="noAmountMaxValue">
              <div>
                <span class="c-label" [innerHTML]="'@i18n-plans-global-text-until' | i18n:lang | ucwords"></span> {{ plan.amount.maxValue | number:'1.2-2' | numberI18n:'es' }}</div>
            </ng-template>
            <ng-template #noAmountMaxValue>
              -
            </ng-template>
          </ng-template>
        </div>
      </div> -->
            <!-- <div [ngClass]="[plan.type == 'img' ? 'col-md-6' : 'col-md-5','m-b-1']">
        <div>
          <div class="label" [innerHTML]="'@i18n-plans-label-terms' | i18n:lang "></div>

          <ng-template [ngIf]="plan.loanTerm.minValue" [ngIfElse]="noLoanTermMinValue">
            <div>
              <span class="c-label" [innerHTML]="'@i18n-plans-global-text-from' | i18n:lang | ucwords"></span> {{ plan.loanTerm.minValue }}
              <span [innerHTML]="'@i18n-plans-global-text-to' | i18n:lang"></span> {{ plan.loanTerm.maxValue }}
              <span [innerHTML]="'@i18n-plans-global-text-months' | i18n:lang"></span>
            </div>
          </ng-template>

          <ng-template #noLoanTermMinValue>
            <ng-template [ngIf]="plan.loanTerm.maxValue" [ngIfElse]="noLoanTermMaxValue">
              <div>
                <span class="c-label" [innerHTML]="'@i18n-plans-global-text-from' | i18n:lang | ucwords"></span> {{ plan.loanTerm.maxValue }}
                <span [innerHTML]="'@i18n-plans-global-text-months' | i18n:lang"></span>
              </div>
            </ng-template>
            <ng-template #noLoanTermMaxValue>
              <span class="c-label">



                {{ '@i18n-text-months' | i18n }}: [
                <span *ngFor="let item of plan.deadlines; let i = index">
                  {{item}}{{ (i+1)
                  < (plan.deadlines).length? ', ': ''}} </span> ]
                </span>
            </ng-template>
          </ng-template>
        </div>
      </div> -->
            <!-- <div [ngClass]="[plan.type=='img' ? 'col-md-6' : 'col-md-5', 'm-b-1'] ">
        <div>
          <div class="label " [innerHTML]=" '@i18n-plans-label-flatcomission' | i18n:lang "></div>
          <ng-template [ngIf]="plan.financialProduct.feesAndCommissionsSpecification " [ngIfElse]="noFeesAndCommissionsSpecification ">
            <div class="c-label">{{ plan.financialProduct.feesAndCommissionsSpecification }}%
              <span [innerHTML]=" '@i18n-plans-text-flatcomission-next' | i18n:lang "></span>
            </div>
          </ng-template>
          <ng-template #noFeesAndCommissionsSpecification>
            -
          </ng-template>
        </div>
      </div>
      <div [ngClass]="[plan.type=='img' ? 'col-md-6' : 'col-md-5', 'm-b-1'] ">
        <div>
          <div class="label " [innerHTML]=" '@i18n-plans-label-initial' | i18n:lang "></div>
          <ng-template [ngIf]="plan.initialAmount " [ngIfElse]="noInitialAmount ">
            <div class="c-label">{{ plan.minimunInitialPercentage }}</div>
          </ng-template>
          <ng-template #noInitialAmount>
            <span class="c-label" [innerHTML]=" '@i18n-plans-text-initial-default' | i18n:lang | ucwords "></span>
          </ng-template>
        </div>
      </div>--><!--


        </div>
        <!-- <div class="row ">
      <div class="col-12 ">
        <br>
      </div>
    </div> --><!--
    </ng-template>



    <ng-template products-akeela-content componentName="sectionForm" let-plan>
        <form novalidate [formGroup]="forms[plan.identifier]" (ngSubmit)="submit(plan.identifier)">
            <input [type]="'hidden'" [(ngModel)]="plan.identifier" formControlName="identifierPlan">
            <!-- <div class="row ">
        <div class="col-12 col-md-4 offset-md-8 ">
          <label class="" style="word-wrap: break-word;" [innerHTML]="'@i18n-select-location' | i18n "></label>
          <select class="form-control" [ngClass]="{'error': forms[plan.identifier].controls.location.invalid && forms[plan.identifier].controls.location.dirty}"
            formControlName="location">
            <option value="" [innerHTML]="'@i18n-text-select' | i18n" selected></option>
            <ng-container *ngFor="let state of states">
              <option [ngValue]="state.identifier" [innerHTML]="state.name | i18n"></option>
            </ng-container>
          </select>
          <div class="form-control-feedback help-block" *ngIf="forms[plan.identifier].get('location').errors">
            <div *ngIf="forms[plan.identifier].get('location').errors['required'] && !forms[plan.identifier].get('location').pristine"
              [innerHTML]="'@i18n-text-error-field-required' | i18n:lang">
            </div>
          </div>
        </div>
      </div>
      <div class="row ">
        <div class="col-12">
          <br>
        </div>
      </div> --><!--
            <div class="row">
                <div class="col-4 offset-8 text-right">
                    <button type="submit" [disabled]="!forms[plan.identifier].valid" class="btn btn-block btn-primary btn-lg">
            <span [innerHTML]="'@i18n-text-button-apply' | i18n:lang"></span>&nbsp;
          </button>
                </div>
            </div>
        </form>
    </ng-template>
</products-akeela-template>



<div class="container animated fadeIn">
    <div class="box" *ngIf="!plans">
        <br>
        <br>
        <div class="text-center ml-auto">
            <i class="fas fa-spinner fa-pulse fa-6x"></i>
        </div>
    </div>
</div>


<div class="box  animated fadeIn" *ngFor="let plan of plans; let i = index" [attr.data-index]="i">
    <plans [product]="product" [plan]="plan">
    </plans>
</div>-->