<div class="mt-1  animated fadeIn">

    <div class="jumbotron jumbotron-fluid p-3 mb-2 ">

        <div class="d-flex bd-highlight">

            <div>
                <h5 class="font-weight-normal">
                    <i class="fas fa-layer-group pr-1"></i> Dashboard
                </h5>
            </div>

            <div class="ml-auto">
                <i class="fas fa-ellipsis-v "></i>
            </div>
        </div>
    </div>

    <div class="w-100 pl-0 mt-4 animate__animated animate__fadeIn" *ngIf="client_indicators">

        <div class="d-flex bd-highlight  flex-wrap">

            <div class="w-60  mt-1 mt-md-2  w-sm-100 pr-0 pr-md-3">

                <div class="   card mb-2  boxstatus2 ">
                    <div class="card-body pl-3 pr-3 pt-2 pb-0 ">
                        <div class="d-flex bd-highlight  flex-wrap ">
                            <h5 class="w-100  mb-2">
                                Registro
                            </h5>

                            <div class="d-flex bd-highlight  w-100 flex-wrap ">
                                <div class="w-30 ">
                                    <div class="w-100 fz-14 h-sm-45">
                                        <span class="font-weight-normal text-secondary ">Sin verificar email</span>
                                    </div>
                                    <div>
                                        <h2 class="font-weight-bold text-dark">{{client_indicators['UNCONFIRMED_EMAIL']?.value}}</h2>
                                    </div>
                                </div>
                                <div class="w-40">
                                    <div class="w-100 fz-14 h-sm-45">
                                        <span class="font-weight-normal text-secondary ">Sin verificación  <br class="d-block d-md-none"> de identidad</span>
                                    </div>
                                    <div>
                                        <h2 class="font-weight-bold text-dark">{{client_indicators['WITHOUT_VERIF_IDENT']?.value}}</h2>
                                    </div>
                                </div>
                                <div class="w-30">
                                    <div class="w-100 fz-14 h-sm-45">
                                        <span class="font-weight-normal text-secondary ">Sin solicitud</span>
                                    </div>
                                    <div>
                                        <h2 class="font-weight-bold text-dark">{{client_indicators['NO_TRANSACTION']?.value}}</h2>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div class="w-40  mt-1 mt-md-2  w-sm-100 ">

                <div class="card mb-2 boxstatus2  " *ngIf="clientLast">
                    <div class="card-body pl-3 pr-3 pt-2 pb-0 ">
                        <div class="d-flex bd-highlight  flex-wrap ">
                            <h5 class="w-100  mb-2">
                                Último registro
                            </h5>

                            <div class="w-100 mb-2 fz-14">
                                <span class="font-weight-bold">Contacto</span>
                            </div>
                            <div>

                                <div class="w-100 fz-14 ">
                                    <div *ngIf="clientLast?.personMarketType?.identifier==='NATURAL'"> {{ clientLast.person.givenName + ' ' + clientLast.person.familyName }} </div>
                                    <div *ngIf="clientLast?.adminOrganization?.legalName"> {{clientLast?.adminOrganization?.legalName }} </div>

                                </div>
                            </div>


                            <div class="w-100 fz-14 text-secondary">
                                Últ. registro {{ (client_indicators['LAST_REC']?.dateOfThing?.startDate) | date: 'd MMM, y h:mm a' }}
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="w-100 pl-0 animate__animated animate__fadeIn" *ngIf="general_indicators">

        <div class="d-flex bd-highlight  flex-wrap">
            <div class="w-40  mt-1 mt-md-2  w-sm-100 pr-0 pr-md-3">
                <div class="   card mb-2  boxstatus2 ">
                    <div class="card-body pl-3 pr-3 pt-2 pb-0 ">
                        <div class="d-flex bd-highlight  flex-wrap ">
                            <h5 class="w-100  mb-2">
                                Solicitudes en proceso
                            </h5>

                            <div class="d-flex bd-highlight  w-100 flex-wrap">
                                <div class="w-33">
                                    <div class="w-100 fz-14">
                                        <span class="font-weight-normal text-secondary ">En proceso</span>
                                    </div>
                                    <div>
                                        <h2 class="font-weight-bold text-dark">{{general_indicators['PI_IN_PROCESS']?.value}}</h2>
                                    </div>
                                </div>
                                <div class="w-33">
                                    <div class="w-100 fz-14">
                                        <span class="font-weight-normal text-secondary ">Recibidas</span>
                                    </div>
                                    <div>
                                        <h2 class="font-weight-bold text-dark">{{general_indicators['AC_PENDING']?.value}}</h2>
                                    </div>
                                </div>
                                <div class="w-33">
                                    <div class="w-100 fz-14">
                                        <span class="font-weight-normal text-secondary ">Devueltas</span>
                                    </div>
                                    <div>
                                        <h2 class="font-weight-bold text-dark">{{general_indicators['AC_INCOMPLETE']?.value}}</h2>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="w-30  mt-1 mt-md-2  w-sm-100">
                <div class="card mb-2  boxstatus2  ">
                    <div class="card-body pl-3 pr-3 pt-2 pb-0 ">
                        <div class="d-flex bd-highlight  flex-wrap ">
                            <h5 class="w-100  mb-2">
                                Solicitudes Finalizadas
                            </h5>

                            <div class="d-flex bd-highlight  w-100 flex-wrap">
                                <div class="w-40 w-sm-33">
                                    <div class="w-100 fz-14">
                                        <span class="font-weight-normal text-secondary ">Aprobadas</span>
                                    </div>
                                    <div>
                                        <h2 class="font-weight-bold text-success">{{general_indicators['AC_RECEIVED']?.value}}</h2>
                                    </div>
                                </div>
                                <div class="w-40 w-sm-33">
                                    <div class="w-100 fz-14">
                                        <span class="font-weight-normal text-secondary ">Rechazadas</span>
                                    </div>
                                    <div>
                                        <h2 class="font-weight-bold text-secondary">{{general_indicators['AC_REJECTED']?.value}}</h2>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




</div>