<ol style="padding-left: 14px">
    <ng-container *ngFor="let trequirement of show_requirements">
        <div class="w-100   text-capitalize pt-1">
            <div class="fz-14  pb-2 text-left pt-2" [class.font-weight-bold]="trequirement?.requirement?.required" [innerHTML]="'<li>'+trequirement?.requirement?.name+' '+((trequirement?.requirement?.required)?'*':'')+'</li>'">
            </div>
            <ng-container *ngFor="let archive of trequirement.requirementArchives">
                <div class="pt-2">
                    <div class="container-image ">
                        <div class="image-column text-center" (click)="show_file(archive.identifier)">
                            <app-preview-img [identifier]="archive.identifier" [name]="archive.name" (response)="getResponsePreview($event)"></app-preview-img>
                            <div class="overlay" *ngIf="activateOverlay.includes(archive.identifier)">
                                <div class="text active-cursor">
                                    <i class="fas fa-eye"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="font-weight-bold text-secondary  fz-12 text-left">
                        {{ archive?.dateOfThing?.startDate | date: 'd MMM, y h:mm a' }}
                    </div>
                </div>
            </ng-container>

            <!-- NO Files-->
            <ng-container *ngIf="isNullOrUndefined(trequirement.requirementArchives)">
                <div class="card">
                    <div class="card-body">
                        No se encontraron archivos
                    </div>
                </div>
            </ng-container>

        </div>
    </ng-container>
</ol>