



<ng-template #sectionInformation let-data_step="data_step" let-data_section="data_section">

    

 <!-- <h1 class=""><br>  {{data_section?.alternateName}}<br></h1> -->



    <ng-container *ngIf="data_section?.alternateName == 'PERSONAL_INFORMATION'">
        <div class=" fz-14">

            <div class="mb-2  mt-3">
                <div class="font-weight-bold">{{dataInformation?.givenName}} {{dataInformation?.name}} {{dataInformation?.alternateName}} {{dataInformation?.familyName}}</div>
                <div>{{dataInformation?.identity}}</div>
            </div>

            <div>
                <div class="font-weight-bold  mt-3">Fecha de Nacimiento</div>
                <div>{{dataInformation?.birthDate | date: 'dd/MM/yyyy':'UTC' }} </div>
            </div>

            <div>
                <div *ngIf="false" class="font-weight-bold  mt-3">Lugar de Nacimiento</div>
                <div *ngIf="false"> - </div>
            </div>


            <div>
                <div class="font-weight-bold  mt-3">Estado Civil</div>
                <div>{{civilStatus[dataInformation?.civilStatus] | i18n }} </div>
            </div>

            <div *ngIf="dataInformation?.civilStatus == 'M'">
                <div>{{dataInformation?.spouse?.givenName }} {{dataInformation?.spouse?.familyName }}, {{dataInformation?.spouse?.identity }}</div>
            </div>


        </div>
    </ng-container>

    <ng-container *ngIf="data_section?.alternateName == 'BUSINESS_DATA'">
        <div class=" fz-14">

            <div class="mb-2  mt-2">
                <div class="font-weight-bold">Nombre de la Razón Social</div>
                <div>{{dataInformation?.legalName}} {{dataInformation?.familyName}}</div>
            </div>

            <div class="mb-3">
                <div class="font-weight-bold">Número de Identificación Fiscal</div>
                <div>CI. {{dataInformation?.taxID}}</div>
            </div>

            <div>
                <div class="font-weight-bold">Nombre Comercial</div>
                <div>{{dataInformation?.alternateName }} </div>
            </div>

        </div>
    </ng-container>



    <ng-container *ngIf="data_section?.alternateName == 'HOME_ADDRESS'">

        <div class=" fz-14">

            <div class="mb-2  mt-2">
                <div> {{dataInformation?.homeAddress?.state?.name}}, {{dataInformation?.homeAddress?.city?.name}}, {{dataInformation?.homeAddress?.addressCountry?.name}} </div>
            </div>

            <div class="mb-2  mt-2">
                <div> {{dataInformation?.homeAddress?.urbanization}},{{dataInformation?.homeAddress?.streetAddress}}, {{dataInformation?.homeAddress?.name}}, {{dataInformation?.homeAddress?.floor}}, {{dataInformation?.homeAddress?.postalCode}}
                </div>
            </div>


            <div>
                <div class="font-weight-bold  mt-3">Condición de la Vivienda</div>
                <div>{{housingCondition[dataInformation?.homeAddress?.housingCondition] | i18n}} </div>
            </div>

        </div>
    </ng-container>

    <ng-container *ngIf="data_section?.alternateName == 'CONTACT_MEANS_PN' || data_section?.alternateName == 'CONTACT_MEANS' ">
        <div class=" fz-14">
            <div class="mb-2  mt-2">
                <div class="font-weight-bold">Teléfonos</div>
                <div>{{dataInformation?.otherPhone}}. {{dataInformation?.telephone}}</div>
            </div>

            <div>
                <div class="font-weight-bold">Email</div>
                <div>{{dataInformation?.email}}</div>
            </div>


        </div>
    </ng-container>

    <ng-container *ngIf="data_section?.alternateName == 'LEGAL_REPRESENTATIVE_PN'">

        <div class="mt-2">


            <ng-container *ngIf="itemList['LEGAL_REPRESENTATIVE_PN']">

                <ng-container *ngFor="let row of itemList['LEGAL_REPRESENTATIVE_PN'] ; let first = first; let i = index">

                    <span class='text-capitalize mt-1 fz-14 font-weight-bold'>{{row.person.givenName}}  {{row.person.familyName}} </span>
                    <hr class="m-1 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!(itemList['LEGAL_REPRESENTATIVE_PN'] &&  itemList['LEGAL_REPRESENTATIVE_PN']?.length > 0 )">
                <span class=" fz-14" style=" color: #333 !important">
                    No no se ha cargado información.
                </span>
            </ng-container>

        </div>

    </ng-container>


    <ng-container *ngIf="data_section?.alternateName == 'PEP_PN'">

        <div class="mt-2">

            <div class="mb-2  mt-3">
                <div class="font-weight-bold" *ngIf="!dataInformation?.itsAPep">No es PEP </div>
                <div class="font-weight-bold" *ngIf="dataInformation?.itsAPep">Es PEP </div>
                <div *ngIf="dataInformation?.itsAPep" class="mt-2">{{dataInformation?.institutionNamePep}}, {{dataInformation?.jobTitlePep}}, {{dataInformation?.countryPep?.identifier }}
                </div>


            </div>


        </div>
    </ng-container>


    <ng-container *ngIf="data_section?.alternateName == 'RELATED_PEP_PN'">

        <div class="mt-2">

            <div class="font-weight-bold" *ngIf="!dataInformation?.isRelatedToPep">No tiene parentesco con PEP </div>
            <div class="font-weight-bold" *ngIf="dataInformation?.isRelatedToPep">Tiene parentesco con PEP </div>

            <div *ngIf="dataInformation?.isRelatedToPep && itemList['RELATED_PEP_PN']" class="mt-2">

                <ng-container *ngFor="let row of itemList['RELATED_PEP_PN'] ; let first = first; let i = index">

                    <span class='text-capitalize mt-1 fz-14 '>{{row.person.givenName}} {{row.person.secondName}} {{row.person.familyName}}   {{row.person.secondName}}, {{row.person.institutionNamePep}}  </span>
                    <hr class="mt-2 p-1">


                </ng-container>
            </div>

            <div class="mt-2" *ngIf=" !isRelatedToPep && (!itemList['RELATED_PEP_PN'] || itemList['RELATED_PEP_PN']?.length < 1) ">

                <span class="fz-14 ">
                No no se ha cargado información.</span>
            </div>

        </div>

    </ng-container>


    <ng-container *ngIf="data_section?.alternateName == 'RELATED_PEP_ASSOCIATION_PN'">

        <div class="mt-2">

            <div class="font-weight-bold" *ngIf="!dataInformation?.isRelatedToPepAssociation">No tiene relación con PEP</div>
            <div class="font-weight-bold" *ngIf="dataInformation?.isRelatedToPepAssociation">Tiene relación con PEP</div>

            <div *ngIf="dataInformation?.isRelatedToPepAssociation && itemList['RELATED_PEP_ASSOCIATION_PN']" class="mt-2">

                <ng-container *ngFor="let row of itemList['RELATED_PEP_ASSOCIATION_PN'] ; let first = first; let i = index">

                    <span class='text-capitalize mt-1 fz-14 '>{{row.person.givenName}} {{row.person.secondName}} {{row.person.familyName}}   {{row.person.secondName}}, {{row.person.institutionNamePep}}  </span>
                    <hr class="mt-2 p-1">


                </ng-container>
            </div>

            <div class="mt-2" *ngIf=" dataInformation?.isRelatedToPepAssociation && (!itemList['RELATED_PEP_ASSOCIATION_PN'] || itemList['RELATED_PEP_ASSOCIATION_PN']?.length < 1) ">

                <span class="fz-14 ">
                No no se ha cargado información.</span>
            </div>

        </div>

    </ng-container>


    <ng-container *ngIf="data_section?.alternateName == 'ECONOMIC_ACTIVITY_PN'">

        <div class="mt-2">

            <div>
                <div>{{economicActivity[dataInformation?.isicV4] }} </div>
            </div>

            <div class="mt-3">
                <div>{{dataInformation?.specificActivity }} </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="data_section?.alternateName == 'ECONOMIC_ACTIVITY'">
        <div class="mt-2">

            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-economicActivity-form-label-economicActivity' | i18n"></div>
                    <div>{{economicActivity2[dataInformation?.isicV4] }}</div>
                </div>
            </div>

            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-economicActivity-form-label-especificActivity' | i18n"></div>
                    <div>{{dataInformation?.specificActivity}}</div>
                </div>
            </div>

            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-economicActivity-form-label-specialCategory' | i18n"></div>
                    <div>{{specialCategory[dataInformation?.specialCategory?.identifier ]}}</div>
                </div>
            </div>



            <!-- <div>
                <div>{{economicActivity[dataInformation?.isicV4] }} </div>
            </div>

            <div class="mt-3">
                <div>{{dataInformation?.specificActivity }} </div>
            </div> -->
        </div>
    </ng-container>

    <ng-container *ngIf="data_section?.alternateName == 'CONSTITUTION_DATA'">


        <div class="mt-2">

            
            <h6 class="text-secondary">
                <span> Datos del registro </span>
            </h6>
            <div class="pl-0">
            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-constitutionData-form-label-registryName' | i18n"></div>
                    <div>{{dataInformation?.registryData?.name?dataInformation?.registryData?.name:'-' }}</div>
                </div>
            </div>

            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-constitutionData-form-label-registryNumber' | i18n"></div>
                    <div>{{dataInformation?.registryData?.registryNumber?dataInformation?.registryData?.registryNumber:'-' }}</div>
                </div>
            </div>

            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-constitutionData-form-label-tomo' | i18n"></div>
                    <div>{{dataInformation?.registryData?.tomo?dataInformation?.registryData?.tomo:'-' }}</div>
                </div>
            </div>

            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-constitutionData-form-label-folio' | i18n"></div>
                    <div>{{dataInformation?.registryData?.folio?dataInformation?.registryData?.folio: '-' }}</div>
                </div>
            </div>

            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-constitutionData-form-label-registryDate' | i18n"></div>
                    <div>{{dataInformation?.registryData?.registryDate?(dataInformation?.registryData?.registryDate | date: 'dd/MM/yyyy':'UTC') : '-' }}</div>
                </div>
            </div>
            </div>
            
            <h6 class="text-secondary pt-3">
                <span> Última modificación </span>
            </h6>
            <div class="pl-0">
            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-constitutionData-form-label-registryName' | i18n"></div>
                    <div>{{dataInformation?.registryLastUpdate?.name?dataInformation?.registryLastUpdate?.name:'-' }}</div>
                </div>
            </div>

            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-constitutionData-form-label-registryNumber' | i18n"></div>
                    <div>{{dataInformation?.registryLastUpdate?.registryNumber?dataInformation?.registryLastUpdate?.registryNumber:'-' }}</div>
                </div>
            </div>

            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-constitutionData-form-label-tomo' | i18n"></div>
                    <div>{{dataInformation?.registryLastUpdate?.tomo?dataInformation?.registryLastUpdate?.tomo:'-' }}</div>
                </div>
            </div>

            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-constitutionData-form-label-folio' | i18n"></div>
                    <div>{{dataInformation?.registryLastUpdate?.folio?dataInformation?.registryLastUpdate?.folio:'-' }}</div>
                </div>
            </div>

            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-constitutionData-form-label-registryDate' | i18n"></div>
                    <div>{{dataInformation?.registryLastUpdate?.registryDate?(dataInformation?.registryLastUpdate?.registryDate  | date: 'dd/MM/yyyy':'UTC'):'-' }}</div>
                </div>
            </div>

            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-constitutionData-form-label-socialCapital' | i18n"></div>
                    <div>{{dataInformation?.registryLastUpdate?.socialCapital?dataInformation?.registryLastUpdate?.socialCapital:'-'}}</div>
                </div>
            </div>
            </div>


            


            <!-- <div>
                <div>{{economicActivity[dataInformation?.isicV4] }} </div>
            </div>

            <div class="mt-3">
                <div>{{dataInformation?.specificActivity }} </div>
            </div> -->
        </div>
    </ng-container>

    <ng-container *ngIf="data_section?.alternateName == 'MARKET_INFORMATION'">
        
        <div class="mt-2">

            
        
            <div class="pl-0">
            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-marketInformation-form-label-numberOfEmployees' | i18n"></div>
                    <div>{{dataInformation?.numberOfEmployees?.value?dataInformation?.numberOfEmployees?.value:'-' }}</div>
                </div>
            </div>

            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-marketInformation-form-label-countryWithTheGreatestPresence' | i18n"></div>
                    <div>{{dataInformation?.countryWithTheGreatestPresence?.identifier?(countrys[dataInformation?.countryWithTheGreatestPresence?.identifier]):'-' }}</div>
                </div>
            </div>

            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-marketInformation-form-label-totalMonthlyIncome' | i18n"></div>
                    <div>{{dataInformation?.totalMonthlyIncome?dataInformation?.totalMonthlyIncome:'-' }}</div>
                </div>
            </div>

            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-marketInformation-form-label-totalMonthlyExpenses' | i18n"></div>
                    <div>{{dataInformation?.totalMonthlyExpenses?(dataInformation?.totalMonthlyExpenses ):'-' }}</div>
                </div>
            </div>

            <div class=" fz-14">
                <div class="mb-2  mt-3">
                    <div class="font-weight-bold" [innerHTML]="'@i18n-quote-marketInformation-form-label-numberOfBranches' | i18n"></div>
                    <div>{{dataInformation?.numberOfBranches?dataInformation?.numberOfBranches: '-' }}</div>
                </div>
            </div>
            </div>
            
            <h6 class="text-secondary pt-3">
                <span [innerHTML]="'@i18n-quote-marketInformation-ISRLDeclaration-section.title' | i18n"></span>
            </h6>
            <div class="pl-0">
               

                <div class=" fz-14">
                    <div class="mb-2  mt-3">
                        <div class="font-weight-bold" [innerHTML]="'@i18n-quote-ISRLDeclaration-form-label-year' | i18n"></div>
                        <div>{{dataInformation?.islrdeclarationYear?dataInformation?.islrdeclarationYear: '-' }}</div>
                    </div>
                </div>

                <div class=" fz-14">
                    <div class="mb-2  mt-3">
                        <div class="font-weight-bold" [innerHTML]="'@i18n-quote-ISRLDeclaration-form-label-amount' | i18n"></div>
                        <div>{{dataInformation?.islrdeclarationAmount?dataInformation?.islrdeclarationAmount: '-' }}</div>
                    </div>
                </div>
            </div>


            


            <!-- <div>
                <div>{{economicActivity[dataInformation?.isicV4] }} </div>
            </div>

            <div class="mt-3">
                <div>{{dataInformation?.specificActivity }} </div>
            </div> -->
        </div>
    </ng-container>

    <ng-container *ngIf="data_section?.alternateName == 'RELATION_OF_LABOR_DEPENDENCY_CHECK'">

        <div class="mt-2">


            <div class="font-weight-bold" *ngIf="!dataInformation?.dependent">No trabaja para una empresa </div>
            <div class="font-weight-bold" *ngIf="dataInformation?.dependent">Trabaja para una empresa</div>

        </div>
    </ng-container>

    <ng-container *ngIf="data_section?.alternateName == 'RELATION_OF_LABOR_DEPENDENCY'">

        <div class="mt-2">

            <div class="font-weight-bold  mt-3">{{dataInformation?.worksFor.legalName}}</div>
            <div class="  mt-2">{{dataInformation?.worksFor?.taxID}}</div>
            <div class="  mt-2">{{dataInformation?.remuneration}} Bs.</div>

        </div>
    </ng-container>



    <ng-container *ngIf="data_section?.alternateName == 'OWN_BUSINESS_CHECK'">

        <div class="mt-2">


            <div class="font-weight-bold" *ngIf="!dataInformation?.haveOwnBusiness">No tiene negocio propio </div>
            <div class="font-weight-bold" *ngIf="dataInformation?.haveOwnBusiness">Tiene negocio propio</div>

        </div>
    </ng-container>

    <ng-container *ngIf="data_section?.alternateName == 'OWN_BUSINESS'">

        <div class="mt-2">

            <div class="font-weight-bold  mt-3">{{ dataInformation?.ownBusiness.legalName}}</div>
            <div class="  mt-2">{{dataInformation?.ownBusiness?.taxID}}</div>
            <div class="  mt-2">{{dataInformation?.ownBusiness?.totalMonthlyIncome}} Bs.</div>

        </div>
    </ng-container>


    <ng-container *ngIf="data_section?.alternateName == 'ANOTHER_SOURCE_INCOME'">

        <div class="mt-2">


            <div class="font-weight-bold" *ngIf="!dataInformation?.anotherSourceIncome">No posee otras fuentes de ingresos </div>
            <div class="font-weight-bold" *ngIf="dataInformation?.anotherSourceIncome">Tiene otras fuentes de ingresos</div>
            <div *ngIf="dataInformation?.anotherSourceIncome" class="  mt-2">{{incomeGeneratingActivity[dataInformation?.incomeGeneratingActivity] | i18n}}</div>
            <div *ngIf="dataInformation?.anotherSourceIncome" class="  mt-2">{{dataInformation?.monthlyIncomeAnotherSource}} Bs.</div>

        </div>
    </ng-container>


    <ng-container *ngIf="data_section?.alternateName == 'PERSONAL_REFERENCES'">

        <div class="mt-2">



            <ng-container *ngIf="itemList['PERSONAL_REFERENCES']">

                <ng-container *ngFor="let row of itemList['PERSONAL_REFERENCES'] ; let first = first; let i = index">

                    <div class='text-capitalize font-weight-bold  mt-1 mb-1 fz-14 '>{{row?.personContact?.givenName}} {{row?.personContact?.familyName}} </div>
                    <div class=' mt-1 fz-14 '>{{row?.personContact?.identity}} </div>
                    <div class=' mt-1 fz-14 '>{{row?.personContact?.contactPoint?.telephone }} </div>



                    <hr class="mt-2 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!itemList['PERSONAL_REFERENCES'] || itemList['PERSONAL_REFERENCES']?.length < 1">

                <span class="fz-14 ">
                No no se ha cargado información.

            </span>
            </ng-container>

        </div>

    </ng-container>




    <ng-container *ngIf="data_section?.alternateName == 'BANK_ACCOUNT_WITH_INST'">

        <div class="mt-2">


            <ng-container *ngIf="itemList['BANK_ACCOUNT_WITH_INST']">

                <ng-container *ngFor="let row of itemList['BANK_ACCOUNT_WITH_INST'] ; let first = first; let i = index">
                    <div class='text-capitalize font-weight-bold  mt-1 mb-1 fz-14 '>{{row?.provider?.name}}</div>
                    <div class=' mt-1 fz-14 '>{{accountType[row?.bankAccountType] | i18n}}</div>
                    <div class=' mt-1 fz-14 '>{{row?.accountNumber}} </div>



                    <hr class="mt-2 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!itemList['BANK_ACCOUNT_WITH_INST'] || itemList['BANK_ACCOUNT_WITH_INST']?.length < 1">

                <span class="fz-14 ">
                No no se ha cargado información.

            </span>
            </ng-container>

        </div>

    </ng-container>


    
    <ng-container *ngIf="data_section?.alternateName == 'BANK_ACCOUNT_WITH_OTHER_INST'">

        <div class="mt-2">


            <ng-container *ngIf="itemList['BANK_ACCOUNT_WITH_OTHER_INST']">

                <ng-container *ngFor="let row of itemList['BANK_ACCOUNT_WITH_OTHER_INST'] ; let first = first; let i = index">
                    <div class='text-capitalize font-weight-bold  mt-1 mb-1 fz-14 '>{{row?.provider?.name}}</div>
                    <div class=' mt-1 fz-14 '>{{accountType[row?.bankAccountType] | i18n}}</div>
                    <div class=' mt-1 fz-14 '>{{row?.accountNumber}} </div>

                    <hr class="mt-2 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!itemList['BANK_ACCOUNT_WITH_OTHER_INST'] || itemList['BANK_ACCOUNT_WITH_OTHER_INST']?.length < 1">

                <span class="fz-14 ">
                No no se ha cargado información.

            </span>
            </ng-container>

        </div>

    </ng-container>


    <!-- LEGAL-->


    <ng-container *ngIf="data_section?.alternateName == 'LEGAL_REPRESENTATIVE'">
        <div class="mt-2">


            <ng-container *ngIf="itemList['LEGAL_REPRESENTATIVE']">

                <ng-container *ngFor="let row of itemList['LEGAL_REPRESENTATIVE'] ; let first = first; let i = index">

                    <span class='text-capitalize mt-1 fz-14 font-weight-bold'>{{row.person.givenName}}  {{row.person.familyName}} </span>
                    <hr class="m-1 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!(itemList['LEGAL_REPRESENTATIVE'] &&  itemList['LEGAL_REPRESENTATIVE']?.length > 0 )">
                <span class=" fz-14" style=" color: #333 !important">
                    No no se ha cargado información.
                </span>
            </ng-container>

        </div>

    </ng-container>


    <ng-container *ngIf="data_section?.alternateName == 'SHAREHOLDERS'">

        <div class="mt-2">


            <ng-container *ngIf="itemList['SHAREHOLDERS']">

                <ng-container *ngFor="let row of itemList['SHAREHOLDERS'] ; let first = first; let i = index">
                    <span class='text-capitalize mt-1 fz-14 font-weight-bold' *ngIf="row.applicantType==='NATURAL'">{{row?.person?.givenName}}  {{row?.person?.name}}  {{row?.person?.familyName}} {{row?.person?.alternateName}} </span>
                    <span class='text-capitalize mt-1 fz-14 font-weight-bold' *ngIf="row.applicantType==='LEGAL'">{{row?.organization?.legalName}} </span>

                    <hr class="m-1 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!(itemList['SHAREHOLDERS'] &&  itemList['SHAREHOLDERS']?.length > 0 )">
                <span class=" fz-14" style=" color: #333 !important">
                    No no se ha cargado información.
                </span>
            </ng-container>

        </div>

    </ng-container>


    <ng-container *ngIf="data_section?.alternateName == 'CUSTOMERS'">

        <div class="mt-2">


            <ng-container *ngIf="itemList['CUSTOMERS']">

                <ng-container *ngFor="let row of itemList['CUSTOMERS'] ; let first = first; let i = index">

                    <span *ngIf="row?.alternateName == 'NATURAL'" class='text-capitalize mt-1 fz-14 font-weight-bold'>{{row?.personContact?.givenName}}  {{row?.personContact?.familyName}} </span>
                    <span *ngIf="row?.alternateName == 'LEGAL'" class='text-capitalize mt-1 fz-14 font-weight-bold'>{{row?.organizationContact?.legalName}}</span>

                    <hr class="m-1 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!(itemList['CUSTOMERS'] &&  itemList['CUSTOMERS']?.length > 0 )">
                <span class=" fz-14" style=" color: #333 !important">
                    No no se ha cargado información.
                </span>
            </ng-container>

        </div>

    </ng-container>



    <ng-container *ngIf="data_section?.alternateName == 'SUPPLIERS'">

        <div class="mt-2">


            <ng-container *ngIf="itemList['SUPPLIERS']">

                <ng-container *ngFor="let row of itemList['SUPPLIERS'] ; let first = first; let i = index">

                    <span *ngIf="row?.alternateName == 'NATURAL'" class='text-capitalize mt-1 fz-14 font-weight-bold'>{{row?.personContact?.givenName}}  {{row?.personContact?.familyName}} </span>
                    <span *ngIf="row?.alternateName == 'LEGAL'" class='text-capitalize mt-1 fz-14 font-weight-bold'>{{row?.organizationContact?.legalName}}</span>

                    <hr class="m-1 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!(itemList['SUPPLIERS'] &&  itemList['SUPPLIERS']?.length > 0 )">
                <span class=" fz-14" style=" color: #333 !important">
                    No no se ha cargado información.
                </span>
            </ng-container>

        </div>

    </ng-container>



    <ng-container *ngIf="data_section?.alternateName == 'COMMERCIAL_REFERENCES'">

        <div class="mt-2">

            <ng-container *ngIf="itemList['COMMERCIAL_REFERENCES']">

                <ng-container *ngFor="let row of itemList['COMMERCIAL_REFERENCES'] ; let first = first; let i = index">

                    <span class='text-capitalize mt-1 fz-14 font-weight-bold'>{{row?.organizationContact?.legalName}}</span>
                    <hr class="m-1 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!(itemList['COMMERCIAL_REFERENCES'] &&  itemList['COMMERCIAL_REFERENCES']?.length > 0 )">
                <span class=" fz-14" style=" color: #333 !important">
                    No no se ha cargado información.
                </span>
            </ng-container>

        </div>

    </ng-container>



    <ng-container *ngIf="data_section?.alternateName == 'RELATED_COMPANIES'">

        <div class="mt-2">

            <ng-container *ngIf="itemList['RELATED_COMPANIES']">

                <ng-container *ngFor="let row of itemList['RELATED_COMPANIES'] ; let first = first; let i = index">

                    <span class='text-capitalize mt-1 fz-14 font-weight-bold'>{{row?.organizationContact?.legalName}}</span>
                    <hr class="m-1 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!(itemList['RELATED_COMPANIES'] &&  itemList['RELATED_COMPANIES']?.length > 0 )">
                <span class=" fz-14" style=" color: #333 !important">
                    No no se ha cargado información.
                </span>
            </ng-container>

        </div>

    </ng-container>


    <ng-container *ngIf="data_section?.alternateName == 'FISCAL_ADDRESS'">

        <div class="mt-2">


            <ng-container *ngIf="itemList['FISCAL_ADDRESS']">

                <ng-container *ngFor="let row of itemList['FISCAL_ADDRESS'] ; let first = first; let i = index">

                    <div *ngIf="row?.description" class="mb-2  mt-2">
                        <div> {{row?.description}}</div>
                    </div>

                    <div *ngIf="!row?.description" class="mb-2  mt-2">
                        <div> {{row?.addressLocality?.name}}, {{row?.state?.name}}, {{row?.city?.name}}, {{row?.addressCountry?.description}} </div>
                    </div>




                    <div>
                        <div class="font-weight-bold  mt-3 fz-14">Tipo</div>
                        <div>{{row?.addressType?.alternateName | i18n}} </div>
                    </div>


                    <hr class="m-1 p-1">


                </ng-container>
            </ng-container>

            <ng-container *ngIf="!(itemList['FISCAL_ADDRESS'] &&  itemList['FISCAL_ADDRESS']?.length > 0 )">
                <span class=" fz-14" style=" color: #333 !important">
                    No no se ha cargado información.
                </span>
            </ng-container>

        </div>

    </ng-container>






    

        <!-- {{data_section?.alternateName }} -->



</ng-template>




<div class="">
    <div class="mt-4">

        <div class="container-fluid">

            <div class="jumbotron jumbotron-fluid p-3 mb-3">
                <div class="d-flex bd-highlight">
                    <div>
                        <h5 class="font-weight-normal"><i class="fas fa-layer-group pr-1"></i> Informacion del Cliente </h5>
                    </div>
                    <div class="ml-auto"><i class="fas fa-ellipsis-v"></i></div>
                </div>
            </div>

            <div *ngIf="redirectTransation">
                <i (click)="redirectDashboard()" class="  active-cursor  text-secondary fas fa-arrow-left  " style="font-size: 1.2rem;"></i>
            </div>


            <div class=" text-center w-100 mt-4">




                <div style=" background: url({{filePreview}}) no-repeat center" class="animate__animated animate__fadeIn content-avata-img2 " *ngIf="dataAndSelfi?.description">
                </div>
                <div *ngIf="!dataAndSelfi?.description" class="ml-auto mr-auto mb-2  ak-abatar-big ak-dinamic-color-abatar  menu">{{ getInitials(this.clientDetail?.person?.givenName,this.clientDetail?.person?.familyName) }}</div>

                <div *ngIf="clientDetail" class="mt-2 text-center">
                    <h5>
                        <div class="ml-auto mr-auto  text-secondary ">
                            <span class=" mt-2 font-weight-bold fz-16 text-capitalize" *ngIf="!isLegal()"> {{ clientDetail?.person?.givenName + ' ' + clientDetail?.person?.familyName }} </span>
                            <span class=" mt-2 font-weight-bold fz-16" *ngIf="isLegal()"> {{clientDetail?.adminOrganization?.legalName }} </span>

                        </div>
                    </h5>
                </div>

            </div>

            <div *ngIf="dataInformation?.verifiedBriefcase" class="text-center text-success mb-4">Verificado</div>

            <ng-container *ngIf="false">

                <div class="text-center">
                    <button *ngIf="!dataInformation?.verifiedBriefcase &&  ( !optVerification && dataInformation?.completeBriefcase && indicatorsProgres?.percent && indicatorsProgres?.percent == 100)" class="btn btn-outline-primary mb-2 " (click)="optVerification = true">
                        Responder  
                    </button>
                </div>

                <div class="text-center">
                    <button *ngIf="!dataInformation?.verifiedBriefcase &&  (!dataInformation?.completeBriefcase && indicatorsProgres?.percent && indicatorsProgres?.percent == 100)" class="btn btn-outline-primary mb-2 " (click)="sendContinueComplete()">
                        Finalizar
                    </button>
                </div>

                <div *ngIf="verifiedIdentification" class="pt-2 pb-3">
                    <span class="alert alert-success ">Verificación de identidad aprobada</span>

                </div>

                <ng-container *ngIf="optVerification">
                    <div class="animate__animated animate__fadeIn mt-4 ">

                        <div *ngIf="dataInformation?.completeBriefcase && indicatorsProgres?.percent && indicatorsProgres?.percent == 100" class="row mb-4 ">


                            <div class="text-right mb-4 col-12 col-md-6 offset-md-3">
                                <i (click)="optVerification = !optVerification" class="  active-cursor  text-secondary fas fa-times " style="font-size: 1.2rem;"></i>

                            </div>


                            <div class="mb-3 form-group text-left  col-12 col-md-6 offset-md-3">

                                <select class="form-control" [(ngModel)]='responseData1'>
                                                    <option value='1'>Verificar</option>
                                                    <option value="2">Devolver</option>
                                                  </select>
                            </div>
                            <div class="mb-2 text-left form-group col-12 col-md-6 offset-md-3">
                                <label class="font-weight-bold" style="word-wrap: break-word;">Descripcion</label>

                                <textarea class="form-control" [(ngModel)]='responseData2'> </textarea>
                            </div>

                            <div class="mt-3 col-12 col-md-6 offset-md-3 text-left">
                                <button (click)="sendContinue()" class="btn btn-primary mt-2 mb-3 ">
                                                        Aceptar
                                                    </button>
                            </div>




                        </div>

                    </div>

                </ng-container>
            </ng-container>

            <!-- juan soto -->

            <ng-container *ngIf="!(optVerification) && workflowStatus">


                <div class=" col-12  mt-3 p-b-1">
                    <div class="container">


                        <div class="w-100 d-flex mb-2">

                            <div class="text-secondary  mt-2" style="    min-height: 45px;">Progreso</div>

                            <div class="ml-auto pb-3">
                                <div style="width: 40px;">
                                    <akeela-graphics [percent]="indicatorsProgres?.percent" [type]="'circle'">
                                    </akeela-graphics>
                                </div>
                            </div>
                        </div>
                        <hr>

                    </div>


                </div>

                <div class="col-12 col-md-10 offset-md-1 ">
                    <div class="container">


                        <ng-container *ngIf="!workflowStatusMenu">
                            <div class=" col-12  p-t-1 p-b-1">
                                <img src="./assets/public/images/loading.gif" alt="">
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let stage of workflowStatusMenu ; let first = first; let i = index">
                            <ng-container *ngFor="let step of stage?.howToSteps ; let first2 = first; let i2 = index">
                                <ng-container *ngIf="step?.alternateName != 'SEND_CUSTOMER_DATA'">
                                    <div (click)="dropDownTransitionsWfs[step?.alternateName] = !dropDownTransitionsWfs[step?.alternateName]" class="pt-2 w-100 text-left" *ngIf="step?.sections?.length > 0 && section?.additionalType != 'modal' && step?.canBeCompleted">
                                        <!-- {{ step?.alternateName }} -->
                                        <!-- {{ workflowStatus[stage?.alternateName])?.steps[step?.alternateName] | json }} -->
                                        <div class="d-flex w-100 active-cursor mt-4">
                                            <div style="cursor: pointer;  color: #666 !important">
                                                <div class="d-flex w-100">
                                                    <div class="mr-2">
                                                        <i *ngIf="(workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.state != 'COMPLETED'" title="Información incompleta" class="fas fa-info-circle text-warning fz-18 pr-1"></i>
                                                    </div>
                                                    <!-- {{ workflowStatus[stage?.alternateName])?.steps[step?.alternateName] | json }} -->

                                                    <div class="mb-2">
                                                        <h5 class="text-left text-dark">
                                                            <span> {{step?.name | i18n}} </span>
                                                        </h5>
                                                        <!--
                                                        <div class="fz-14">
                                                            {{'@i18n-htmlSubTitle-'+step?.alternateName | i18n }}
            
            
                                                        </div>
                                                         -->
                                                    </div>

                                                </div>

                                            </div>

                                            <div class="ml-auto text-right text-primary ">
                                                <i *ngIf="!dropDownTransitionsWfs[step?.alternateName]" class="fas fa-chevron-down"></i>
                                                <i *ngIf="dropDownTransitionsWfs[step?.alternateName]" class="fas fa-chevron-up"></i> </div>
                                        </div>

                                        <hr *ngIf="!dropDownTransitionsWfs[step?.alternateName]">

                                    </div>
                                    <div class="section-box mt-2 mb-2 pt-2 " *ngIf="dropDownTransitionsWfs[step?.alternateName] ">
                                        <ng-container *ngFor="let section of step?.sections ; let first3 = first; let i3 = index">
                                            <div class=" mb-2">
                                                <div class="w-100  p-3 pb-2  text-left" (click)="redirectSection(workflowCodeActive,stage?.alternateName,step?.alternateName,section?.alternateName);">
                                                    <div class="d-flex w-100">
                                                        <div>
                                                            <h6 class="text-secondary"> <span> {{section?.sectionTitle | i18n}} </span>
                                                            </h6>
                                                        </div>
                                                        <div class="ml-auto text-right text-primary">
                                                            <h6>
                                                                <div class="circle-check" *ngIf="(workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.sections[section?.alternateName]?.state != 'COMPLETED'"><i title="Información incompleta" class="fas fa-info-circle text-warning"></i> </div>

                                                                <ng-container *ngIf="section?.icono">

                                                                    <i class="{{section?.icono}} mr-1 "></i>

                                                                </ng-container>

                                                                <ng-container *ngIf="!section?.icono">

                                                                    <i class="fas fa-circle mr-1 "></i>

                                                                </ng-container>




                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <!-- {{ section?.alternateName }}<br>
                                                    {{ (workflowStatus[stage?.alternateName])?.steps[step?.alternateName] | json  }} -->
                                                    <div class="w-100" *ngIf="(workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.sections[section?.alternateName]?.state == 'COMPLETED'">
                                                        <ng-container *ngTemplateOutlet="sectionInformation; context:{data_step: step,data_section:section}">
                                                        </ng-container>
                                                    </div>

                                                    <div class="w-100 mt-2 mb-2 " style=" color: #333 !important" *ngIf="(workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.sections[section?.alternateName]?.state != 'COMPLETED'">
                                                        No no se ha cargado información.
                                                    </div>



                                                </div>
                                            </div>

                                        </ng-container>


                                    </div>


                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <!-- fin data -->

        </div>

    </div>
</div>
