<auth-akeela-template>
    <ng-template auth-akeela-content componentName="loginLinks">
        <br>
        <div class=" text-center ">
            <a (click)="recovery()" [routerLink]="''" class="font-weight-normal link" [innerHTML]="'@i18n-store-front-login-user-recovery-password' | i18n:lang"></a>
            ó
            <a (click)="unlock()" [routerLink]="''" class="font-weight-normal link" [innerHTML]="'@i18n-store-front-login-user-unlock-password' | i18n:lang"></a>

        </div>
    </ng-template>
</auth-akeela-template>

<akeela-login [params]="params" [endpoint]="endpoint" (response)="response($event)"></akeela-login>