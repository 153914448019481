<app-template>
    <ng-template app-content componentName="mainContentModalBottonMeet">
        <div class="capa-menu-2" *ngIf="buttomModal" (click)="changes()"> </div>

        <div [ngClass]="{'d-none':animate_init_bottom,'animate__slideInUp':buttomModal,animate__slideOutDown:!buttomModal } " class="  animate__animated animate__faster ak-modal-botoom ak-modal-botoom2  p-2 ">
            <div class="body pb-4 pt-2 " style="overflow:auto;">
                <div class="container text-center">
                    <div class="mt-4">
                        <ng-container *ngIf="editMeetInputLoading">
                            <div>
                                <img src="./assets/public/images/loading.gif " alt=" ">
                            </div>
                        </ng-container>
                        <div class="row text-center " *ngIf="!editMeetInputLoading">

                            <div class="col-12 col-md-6 offset-md-3">

                                <div>
                                    <div class="d-flex ">
                                        <div (click)="setUserDetail()" *ngIf="!back && !editMeetInput" class="pr-2 w-10">
                                            <i class="fas fa-angle-left text-secondary active-cursor  fa-2x" aria-hidden="true"></i>
                                        </div>

                                        <div (click)="setBack()" *ngIf="back && !editMeetInput" class="pr-2 w-10">
                                            <i class="fas fa-angle-left text-secondary active-cursor  fa-2x" aria-hidden="true"></i>
                                        </div>
                                        <div *ngIf="!back" class="fz-12 w-90 text-left">
                                            <div class="font-weight-bold text-capitalize">
                                                <div *ngIf="clientDetail?.personMarketType?.identifier==='NATURAL'"> {{ clientDetail.person.givenName + ' ' + clientDetail.person.familyName }} </div>
                                                <div *ngIf="clientDetail?.adminOrganization?.legalName"> {{clientDetail?.adminOrganization?.legalName }} </div>

                                            </div>
                                            <div classtrue="text-secondary">
                                                <div *ngIf="!isLegal()">{{dataInformation?.identity}}</div>
                                                <div *ngIf="isLegal()">{{dataInformation?.taxID}}</div>


                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <hr>
                                <h5 class="pt-2 font-weight-bold"> Reuniones</h5>
                                <div *ngIf="!back" class="d-flex  justify-content-top flex-wrap mt-3">
                                    <div class="w-100">
                                        <ul class="scrollmenu pl-0 mb-0 ">
                                            <li class="d-inline">
                                                <div (click)="setMenuSelect();" class="item-rd active-cursor  ">
                                                    <div class=" DomainUserAvatar-avatar text-secondary  AvatarPhoto AvatarPhoto--opt  active-cursor  " role="img">
                                                        <i class="fas fa-calendar-alt"></i>
                                                    </div>
                                                    <div class=" mt-2 text-dark fz-14">Nueva <br> Reunion</div>
                                                </div>

                                                <div class="item-rd  ">
                                                    <div class="DomainUserAvatar-avatar text-secondary AvatarPhoto AvatarPhoto--opt  active-cursor  " role="img">
                                                        <i class="fas fa-people-arrows"></i>
                                                    </div>
                                                    <div class=" mt-2 text-secondary fz-14">Unirse</div>
                                                </div>

                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <ng-container>
                            <div class="row mt-3 animate__animated animate__fadeIn">
                                <div class="col-12 col-md-6 offset-md-3">
                                    <div class=" text-left">
                                        <div class="p-2 fz-14 d-flex bd-highlight  list-flex flex-wrap text-left size-caja-dopd">

                                            <div class="w-100 animate__animated animate__fadeIn" *ngIf="!back && !editMeetInputLoading">
                                                <div class="row">
                                                    <div class="col-12 col-md-8 offset-md-2">

                                                        <div class="row">
                                                            <div class="col-6">
                                                                <button (click)="meetNew = true" [ngClass]="{'btn-primary':  meetNew,' btn-outline-primary ': !meetNew }" class="btn btn-block ">
                                                                    Proximas
                                                                </button>
                                                            </div>
                                                            <div class="col-6">
                                                                <button (click)="meetNew = false" [ngClass]="{'btn-primary':  !meetNew,' btn-outline-primary ': meetNew }" class="btn   btn-block ">
                                                                    Pasadas
                                                               </button>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>



                                            <ng-container *ngIf="!back && !loadingTransaction && !editMeetInputLoading">
                                                <div class="w-100 pt-5  text-center animate__animated animate__fadeIn" *ngIf="interviews?.length < 1">
                                                    <i class=" pt-4 pb-2 opacity-card fas fa-people-arrows fa-5x  text-center text-primary"></i>
                                                    <div>No se encontraron reuniones</div>
                                                </div>

                                                <div *ngIf="interviews?.length > 0" class="mt-4 w-100">


                                                    <ng-container *ngFor="let row of interviews ; let i = index">

                                                        <div class="d-flex bd-highlight  list-flex mt-2" *ngIf="interviewsTime[row.idTransaction]['valid'] == meetNew ">

                                                            <div>
                                                                <i class="fas mr-3 icon-list-meet text-secondary" [ngClass]="{
                                                                    'fa-video': interviewType[row?.type].label == '@i18n-quote-option-list-interviewType-option3',
                                                                    'fa-phone-alt':interviewType[row?.type].label == '@i18n-quote-option-list-interviewType-option2',
                                                                    'fa-user':interviewType[row?.type].label == '@i18n-quote-option-list-interviewType-option1'}"></i>
                                                            </div>

                                                            <div class="mb-3">
                                                                <div class="d-flex  flex-wrap text-left ">
                                                                    <div class=" mb-1 w-100 font-weight-bold"> {{interviewType[row?.type].label | i18n}} <span class="font-weight-normal"> - {{row?.transaction}}</span></div>
                                                                    <div class="mb-1 w-100  text-secondary"> {{toDateString(row?.interviewDate)}} </div>
                                                                    <div class="mb-2 p-2 fz-12 text-secondary bg-light " *ngIf="interviewsTime[row.idTransaction]['valid'] ">
                                                                        <span class="pr-2 ">Faltan</span>
                                                                        <span class="pr-2"><span *ngIf="interviewsTime[row.idTransaction]['day'] > 0" >{{ interviewsTime[row.idTransaction]['day']}}</span>D</span>
                                                                        <span class="pr-2"><span *ngIf="interviewsTime[row.idTransaction]['hours'] > 0"  >{{ interviewsTime[row.idTransaction]['hours'] }}</span>Hor</span>
                                                                        <span class="pr-2"><span *ngIf="interviewsTime[row.idTransaction]['minutes'] > 0"  >{{interviewsTime[row.idTransaction]['minutes'] }}</span>Min</span>
                                                                        <span class="pr-2"><span  *ngIf="interviewsTime[row.idTransaction]['seconds'] > 0" >{{interviewsTime[row.idTransaction]['seconds'] }}</span>Seg</span>
                                                                        <span class="pl-2 far fa-clock blink-me rounded "></span>
                                                                    </div>
                                                                    <div class="mb-2 p-2 fz-12 text-secondary bg-light " *ngIf="!interviewsTime[row.idTransaction]['valid']">
                                                                        <span class="pr-2 text-danger">Fecha expirada</span>
                                                                        <span class="pl-2 far fa-clock blink-me rounded "></span>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="fz-16 text-secondary ml-auto  activate-cursor" (click)="setNewInterview(row?.idTransaction)">
                                                                <i class="fas fa-edit"></i>
                                                            </div>
                                                        </div>



                                                        <div class="d-flex  flex-wrap text-left " *ngIf="interviewsTime[row.idTransaction]['valid'] == meetNew ">
                                                            <div *ngIf="row?.description " class="font-weight-bold mb-2 ">Descripción </div>
                                                            <div class=" mb-2 w-100 "> {{row?.description}} </div>


                                                            <div class="text-left w-100 pb-4">

                                                                <div *ngIf=" interviewType[row?.type].label == '@i18n-quote-option-list-interviewType-option3'" class="mt-1 mb-2  text-primary active-cursor " (click)="redirectMeet('https://meet.jit.si/meet-'+env?.theme?.images+'-'+clientDetail?.identifier+'-'+row?.transaction) ">
                                                                    {{'https://meet.jit.si/meet-'+env?.theme?.images+'-'+clientDetail?.identifier+'-'+row?.transaction}}
                                                                </div>

                                                                <div class="w-100">
                                                                    <span *ngIf=" interviewType[row?.type].label == '@i18n-quote-option-list-interviewType-option3'" class="text-secondary active-cursor pb-3 pr-3" (click)="setCopy( 'https://meet.jit.si/meet-'+env?.theme?.images+ '-'+clientDetail?.identifier+'-'+row?.transaction) ">
                                                                    <button class="btn btn-outline-secondary ">
                                                                        <i class="fas fa-copy pr-2 "></i>Copiar
                                                                   </button>
                                                                   </span>
                                                                    <span class="  d-md-none  text-secondary pb-3 text-left" (click)="setShare(interviewType[row?.type].label == '@i18n-quote-option-list-interviewType-option3'?'https://meet.jit.si/meet-'+env?.theme?.images+ '-'+clientDetail?.identifier+'-'+row?.transaction: false ,{fecha:row?.interviewDate,tipo:interviewType[row?.type].label}) ">

                                                                    <button class="btn btn-outline-secondary ">
                                                                        <i class="fas fa-share-alt pr-2 "></i>Compartir
                                                                   </button>
                                                                 </span>
                                                                    <!--
                                                                    <div class="" *ngIf="copy ">
                                                                        <span class="alert alert-info ">Link copiado!</span>

                                                                    </div>
                                                                     -->

                                                                </div>


                                                            </div>
                                                        </div>

                                                    </ng-container>
                                                </div>

                                                <ng-container *ngIf="loadingTransaction || editMeetInputLoading">
                                                    <div>
                                                        <img src="./assets/public/images/loading.gif " alt=" ">
                                                    </div>
                                                </ng-container>
                                            </ng-container>

                                            <div *ngIf="back && menuSelect " class="w-100 animate__animated animate__fadeIn ">

                                                <div class="row ">
                                                    <div class="col-12 col-md-10 offset-md-1 fz-14">

                                                        <div class="bg-info-box p-3 ">
                                                            <a (click)="setShared() " class=" text-secondary d-block activ " href="javascript:void(0) ">
                                                                <i class="fas fa-paperclip pr-2 "></i>
                                                                <span>Obtener información para compartir</span>
                                                            </a>

                                                            <a (click)="redirectMeet() " class=" text-secondary d-block mt-3 " href="javascript:void(0) ">
                                                                <i class="fas fa-plus pr-2 "></i>
                                                                <span>Iniciar reunion instantanea</span>
                                                            </a>

                                                            <a (click)="setNewInterview()" class=" text-secondary d-block mt-3 " href="javascript:void(0) ">
                                                                <i class="fas fa-calendar-alt pr-2 "></i>
                                                                <span>Agendar una reunión en el Calendario</span>
                                                            </a>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div *ngIf="back && shared " class="w-100 animate__animated animate__fadeIn ">
                                                <ng-container *ngIf="loading ">
                                                    <div>
                                                        <img src="./assets/public/images/loading.gif " alt=" ">
                                                    </div>
                                                </ng-container>

                                                <div class="w-100 mt-2 mb-3 " *ngIf="!loading ">
                                                    <div class="d-flex bd-highlight mb-2 ">
                                                        <div class="w-100 text-capitalize text-center ">

                                                            <div class=" w-100 text-center ">

                                                                <div *ngIf="!dataAndSelfi?.description " class=" DomainUserAvatar-avatar
                                                                        text-secondary AvatarPhoto AvatarPhoto--client " role="img "><i class="fas fa-user "></i></div>

                                                                <div style=" background: url({{filePreview}}) no-repeat center " class=" animate__animated animate__fadeIn content-avata-img " *ngIf="dataAndSelfi?.description ">
                                                                </div>

                                                                <div class=" mt-2 font-weight-bold fz-16 " *ngIf="clientDetail?.personMarketType?.identifier==='NATURAL' "> {{ clientDetail.person.givenName + ' ' + clientDetail.person.familyName }} </div>
                                                                <div class=" mt-2 font-weight-bold fz-16 " *ngIf="clientDetail?.adminOrganization?.legalName "> {{clientDetail?.adminOrganization?.legalName }} </div>
                                                            </div>

                                                            <div class="text-secondary font-weight-bold mt-4 ">
                                                                Enlace permanente
                                                            </div>

                                                            <div class="mt-1 mb-2 text-center text-primary active-cursor " (click)="redirectMeet() ">
                                                                {{'https://meet.jit.si/meet-'+env?.theme?.images+'-'+clientDetail?.identifier}}
                                                            </div>

                                                            <div class="text-center fz-16 mt-4 ">
                                                                <div class="text-dark active-cursor mb-3 " (click)="setCopy( 'https://meet.jit.si/meet-'+env?.theme?.images+ '-'+clientDetail?.identifier) "><i class="fas fa-copy pr-2 "></i>Copiar</div>
                                                                <div class=" pb-4 " *ngIf="copy ">
                                                                    <span class="alert alert-info ">Link copiado!</span>

                                                                </div>

                                                                <div class="text-dark d-md-none mb-3 " (click)="setShare( 'https://meet.jit.si/meet-'+env?.theme?.images+ '-'+clientDetail?.identifier,row) "><i class="fas fa-share-alt pr-2 "></i>Compartir</div>



                                                                <div class="text-dark mr-3 active-cursor mb-3 mt-2 " (click)="redirectMeet() ">
                                                                    <button class="btn btn-outline-secondary ">
                                                                        <i class="fas fa-plus pr-2 "></i> Iniciar Reunión
                                                                    </button>
                                                                </div>


                                                            </div>

                                                        </div>

                                                    </div>

                                                    <app-jitsi *ngIf="init_meet " (change)="setUrlmeet($event) " [urlmeet]=" 'meet-hazling-'+clientDetail?.identifier " [client]="clientDetail?.identifier " [executive]="userNameSesion "></app-jitsi>

                                                </div>
                                            </div>


                                            <div *ngIf="back && newInterview " class="w-100 animate__animated animate__fadeIn ">

                                                <h5 class="text-secondary font-weight-bold mt-3 mb-3 ">
                                                    Agendar
                                                </h5>

                                                <div class="row">

                                                    <div class="col-12 mt-1 form-group">
                                                        <div class=" label mb-2 font-weight-bold">Solicitud </div>
                                                        <select [disabled]="editMeetInput" (change)='setformTransaction(formsDataResponse.idTransaction)' class="form-control search " name="type" [(ngModel)]='formsDataResponse.idTransaction'>
                                                            <option value='' [selected]="true">--</option>

                                                            <ng-container  *ngFor="let row of rows; let i = index">
                                                                <option  [value]='row?.identifier'> {{row?.solicitud | i18n }}</option>
                                                            </ng-container>
                                                        </select>
                                                    </div>


                                                    <div class="col-12 mt-1 form-group">
                                                        <select class="form-control search pl-select" name="type" [(ngModel)]='formsDataResponse.type'>
                                                            <ng-container *ngFor="let row of interviewType; let i = index">
                                                                <option *ngIf="row?.value" [value]='row?.value'> {{row?.label | i18n }}</option>
                                                            </ng-container>
                                                        </select>
                                                        <div class="icon-select">
                                                            <i class="fas text-secondary" [ngClass]="{
                                                                'fa-video': (interviewType[formsDataResponse?.type])?.label == '@i18n-quote-option-list-interviewType-option3',
                                                                'fa-phone-alt': (interviewType[formsDataResponse?.type])?.label == '@i18n-quote-option-list-interviewType-option2',
                                                                'fa-user': (interviewType[formsDataResponse?.type])?.label == '@i18n-quote-option-list-interviewType-option1'}"></i>
                                                        </div>

                                                    </div>



                                                    <div class="col-12 mt-1 form-group">
                                                        <span class=" label mb-1 ">Fecha </span>
                                                        <input [(ngModel)]="formsDataResponse.interviewDate" type="datetime-local" name="interviewDate" class="form-control " />
                                                    </div>

                                                    <div class="col-12 mt-1 form-group">
                                                        <div class=" label mb-2 font-weight-bold">Descripción </div>
                                                        <textarea [(ngModel)]='formsDataResponse.description' name="description" class="form-control " style="height: 100px !important; "></textarea>
                                                    </div>


                                                    <div class="col-12 mt-3 form-group text-right">
                                                        <button (click)="submitFormsDataResponse()" class="btn  btn-primary  btn-block ">Ok</button>
                                                    </div>

                                                </div>
                                            </div>





                                        </div>
                                    </div>

                                </div>

                            </div>
                        </ng-container>

                    </div>
                </div>
            </div>
        </div>

    </ng-template>
</app-template>