<div class="container">
    <div class="col-12 col-md-6 offset-md-3">
        <div class="card">
            <div class="card-header text-left">
                Analisis Fácial con Inteligencia Artificial
            </div>
            <div class="card-body">
                <div class="row pb-4" *ngFor="let item of items">
                    <div class="col-12">
                        <div class="w-100">
                            <span class="text-left" style="display: block;float: left" [innerHTML]="item.description"></span>
                            <span class="text-right" style="display: block;float: right" [innerHTML]="item.percentaje+'%'" *ngIf="item.percentaje!=='unknow' && item.percentaje!==null"></span>
                        </div>
                        <div class="w-100 pt-4">
                            <akeela-graphics [percent]="item.percentaje" [type]="'line'" *ngIf="item.percentaje!=='unknow' && item.percentaje!==null">
                            </akeela-graphics>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>