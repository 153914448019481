<div class="mt-2">
  <div class="mb-3 mt-3 font-weight-bold text-dark">Actividad</div>

  <div class="mr-2 d-inline-flex">
    <button (click)="menuFooter = 'revisions'" type="button" [class.active]="menuFooter == 'revisions'" class="btn btn-secondary btn-sm">
      <span>Historial</span>
    </button>
  </div>

  <div class="mr-2 d-inline-flex">
    <button (click)="menuFooter = 'relationship'" type="button" [class.active]="menuFooter == 'relationship'" class="btn btn-secondary btn-sm">
      <span>Versiones</span>
    </button>
  </div>

  <div class="mr-2 d-inline-flex">
    <button (click)="menuFooter = 'related'" type="button" [class.active]="menuFooter == 'related'" class="btn btn-secondary btn-sm">
      <span>Relación con otras solicitudes</span>
    </button>
  </div>
</div>

<div>


  <div class="animate__animated animate__fadeIn" *ngIf="menuFooter == 'revisions'">

    <app-previusly-revisions></app-previusly-revisions>

  </div>

  <div class="animate__animated animate__fadeIn" *ngIf="menuFooter == 'relationship'">

    <app-list-formalities-relationship></app-list-formalities-relationship>

  </div>

  <div class="animate__animated animate__fadeIn" *ngIf="menuFooter == 'related'">

    <app-list-formalities-related></app-list-formalities-related>

  </div>



</div>