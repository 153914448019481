<div class="container animated fadeIn">
    <div class="row box-header m-t-3 m-b-1">
        <div class="col-6 pull-left">
            <span class="text-color-gray text-size-2"></span>
        </div>

    </div>
    <div class="box-head skin-white">
        <div class="row">
            <div class="col-lg-12 text-center">
                <span class="text-center text-color-gray text-size-2" [innerHTML]="'Todos' | i18n:lang "></span>
                <span class="text-center">
                    <div class="btn-group ml-1" dropdown>
                        <i id="button-basic" dropdownToggle type="button" class="fa fa-ellipsis-v ellipsis pl-2 pr-2" aria-hidden="true"></i>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-size  dropdown-menu-right" role="menu" aria-labelledby="button-basic">

                        </ul>
                    </div>
                </span>
                <br>
            </div>
        </div>


    </div>
    <div class="row box skin-white">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-8 col-md-6 ">
                    <form novalidate [formGroup]="form">
                        <div class="d-flex bd-highlight flex-wrap ">
                            <div class="bd-highlight w-100 w-sm-100">
                                <a class="btn  btn-link p-0 text-dark float-right " role="button">
                                    <i class="fa fa-search float-right icon-search" aria-hidden="true"></i>
                                </a>
                                <input placeholder="Buscar..." type="text" name="filter" id="filter" formControlName="filter" (keydown)="search_clients($event)" class="form-control m-0 p-2  filtros input-search">
                            </div>
                        </div>
                    </form>
                </div>

                <div class="col-4 col-md-6  text-right ">


                </div>



            </div>

        </div>
        <div class="col-lg-12"><br><br></div>

        <div class="col-lg-12">

            <div class="d-none d-md-block d-lg-block d-xl-block w-100">
                <div class="d-flex bd-highlight flex-wrap ">
                    <div class="bd-highlight  mt-1 mt-md-0 mt-1 mt-md-0 pl-1 pr-1 pl-md-0 pr-md-0 w-25 w-sm-100">
                        <div class="text-dark font-weight-bold">
                            <small class=" font-weight-bold fz-1">Nombre u organización</small>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="d-none d-md-block d-lg-block d-xl-block mt-2 mb-2 black">

            <ng-container *ngIf="!clients">
                <div class="row">
                    <div class="col-12">
                        <img class="float-left" src="./assets/public/images/loading.gif" alt="">
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="clients">

                <div class="d-flex bd-highlight flex-wrap pt-1 pt-md-3  pb-1 pb-md-3 list-flex">

                    <div class="bd-highlight pb-3 pb-md-0   mt-1 mt-md-0 mt-1 mt-md-0  pl-1 pr-1 pl-md-0 pr-md-0 w-50 w-sm-50 " (click)="showDetail(client?.identifier, client?.person.identifier)">
                        <div class="text-secondary">
                            Client
                        </div>
                    </div>

                    <div class="bd-highlight   pb-3 pb-md-0 mt-1 mt-md-0   text-right pl-2 pr-2  w-25 w-sm-25  ">
                        <div class="text-secondary">
                            Executive
                        </div>
                    </div>

                </div>


                <div *ngFor="let client of clients" class="d-flex bd-highlight flex-wrap pt-1 pt-md-3  pb-1 pb-md-3 list-flex">

                    <div class=" text-capitalize bd-highlight pb-3 pb-md-0   mt-1 mt-md-0 mt-1 mt-md-0  pl-1 pr-1 pl-md-0 pr-md-0 w-40 w-sm-40 " (click)="showDetail(client?.identifier, client?.person.identifier)">
                        <div (click)="showClient(client.identifier, '2'  == '2'?client.person.givenName+' '+client.person.familyName:client?.adminOrganization?.legalName,client?.adminOrganization?.identifier)" [class]="'radius-client text-center pt-1 mr-3 d-inline float-left text-white c-A c-'+getFirstLetter('2'  === '2'?client.person.givenName:client.adminOrganization.legalName)">
                            <span [innerHTML]="getInitials('2'  === '2'?client.person.givenName:client.adminOrganization.legalName, '2'  === '2'?client.person.familyName:'')"></span>
                        </div>
                        <div class="pt-1 ">

                            <i style=" vertical-align: center;font-size: .5rem" [ngClass]="{'text-success':client?.updateDate,'text-secondary':!client?.updateDate}" class="text-capitalize fa  fa-certificate text-success "></i>
                            <!--  client.personMarketType?.identifier == "2"?client.person.givenName+' '+client.person.familyName:client?.adminOrganization?.legalName-->
                            <a class="btn text-dark  btn-link p-0 fz-inherit" (click)="showClient(client.identifier, '2'  == '2'?client.person.givenName+' '+client.person.familyName:client?.adminOrganization?.legalName,client?.adminOrganization?.identifier)" role="button"> {{ "2" == "2"?client.person.givenName+' '+client.person.familyName:client?.adminOrganization?.legalName
                                }} </a>


                        </div>
                    </div>

                    <div class="bd-highlight   pb-3 pb-md-0 mt-1 mt-md-0   text-right pl-2 pr-2  w-25 w-sm-25  ">
                        <div>
                            {{ client?.salesManagerUser?.identifier? client?.salesManagerUser?.identifier:'-'}}
                        </div>
                    </div>
                    <div class="bd-highlight   pb-3 pb-md-0 mt-1 mt-md-0   text-right pl-2 pr-2  w-25 w-sm-25">
                        <div>

                            <div class="btn-group ml-1" dropdown>


                                <a id="button-basic" dropdownToggle class="pl-2 pr-2 text-secondary" href="javascript:void(0);" aria-hidden="true">Asignar Ejecutivo</a>


                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-size  dropdown-menu-right" role="menu" aria-labelledby="button-basic">

                                    <li *ngFor="let lst of executives" role="menuitem">
                                        <a style="text-decoration: underline" (click)="assignExecutive(client.identifier,lst.identifier)" class="dropdown-item" href="javascript:void(0); ">

                                          {{ lst.person.givenName+' '+ lst.person.familyName}}
                                           
                                        </a>
                                    </li>
                                </ul>
                            </div>


                        </div>
                    </div>
                </div>
                <hr class="m-0 mb-2 mt-2">
            </ng-container>
            <div class="w-100 text-center" *ngIf="clients?.length== 0">
                NO FIND USERS
            </div>
        </div>

    </div>
</div>






























<!--

<user-dashboard-footer></user-dashboard-footer>

-->