<div class="ak-nav-fixed" [class.ak-nav-fixed-calc]="showSidebarLeft">
    <nav class="navbar ak-nav-a pl-2">


        <div class="w-100">
            <span class="navbar-header pull-left">
                    <a (click)=" toggleMenu()" class="navbar-brand">
                        <i  class="fa fa-bars " aria-hidden="true"></i>
                 </a>
                 <div>
                    <app-logo-img (click)="redirectHome()" *ngIf="!showSidebarLeft" [theme]="env?.theme?.logoConf?.themeColorNavAuth" [type]="env?.theme?.logoConf?.typeNavAuth"></app-logo-img>

                 </div>
                
            </span>
            <!--
        <span class="navbar-brand p-0">
            <akeela-sidebarTop [class.d-none]="false" (response)="eventBreadcrumb($event)">
            </akeela-sidebarTop>
        </span>
    -->



            <div class="navbar-header top-nav">

                <div class="icon-comments text-primary">

                    <a (click)=" redirectNotes()">
                        <i class=" mr-1 far fa-comments"></i>
                    </a>
                </div>

                <div ngbDropdown display="dynamic" placement="bottom-right">


                    <div style="cursor: pointer" ngbDropdownToggle id="navbarDropdown3" class=" navbar-brand-profile ak-vertical-center">
                        <div class=" ak-abatar ak-dinamic-color-abatar  ">{{ _auth.getInitials() }}</div>
                    </div>

                    <div style="width: 270px;" ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu text-center">

                        <div class="col-12 pb-3  pt-2">
                            <div style="font-size:.82rem" class=" container w-100 mt-2">

                                <div class=" w-100 ">
                                    <div class=" ml-auto mr-auto mb-2  ak-abatar ak-dinamic-color-abatar  menu">{{ _auth.getInitials() }}</div>


                                    <div class="font-weight-bold " *ngIf="_auth.getSession()?.businessName" style="font-size: 16px">
                                        <span>
                                        <b> {{_auth.getSession()?.businessName}}</b>
                                    </span>
                                        <br>

                                    </div>

                                    <div class="ml-auto mr-auto">

                                        <span *ngIf="_auth.getSession()?.firstName"> {{_auth.getSession()?.firstName}}</span>
                                        <span *ngIf="_auth.getSession()?.lastName"> {{_auth.getSession()?.lastName}}</span>
                                    </div>

                                    <div class="mt-2 mb-2" *ngIf="_auth.getSession()?.email"> {{_auth.getSession()?.email}}</div>
                                </div>


                                <hr>
                            </div>
                            <button (click)="setShowChangePassword(true)" class=" btn btn-block btn-outline-primary btn-sm ">
                            Cambiar Contraseña
                        </button>
                            <hr>

                            <div class="font-weight-bold text-left mb-2 fz-14">
                                <span>Perfiles </span>
                            </div>

                            <div style="font-size:.82rem" class=" animated fadeIn" *ngFor="let profile of profilesUser; let i = index">
                                <div class="d-flex bd-highlight active-cursor  list-flex flex-wrap mb-2">

                                    <div class="text-left w-10  order-1">
                                        <i class="fas fa-check text-primary" *ngIf="profile.alternateName == profileUser"></i>
                                    </div>

                                    <div [ngClass]="{'w-50':profile.alternateName == profileUserDefault,'w-90':profile.alternateName != profileUserDefault }" class="text-left  order-2">
                                        <a [ngClass]="{'text-primary':profile.alternateName == profileUser}" href="javascript:void(0) " (click)="changeProfile(profile.alternateName) " class="text-dark ">
                                            <span [innerHTML]="profile.name | i18n "></span>
                                        </a>
                                    </div>
                                    <div *ngIf="profile.alternateName==profileUserDefault " class=" text-right w-40 text-secondary order-3 ">
                                        <i>Por Defecto </i>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="!profilesUser ">
                                <div class="row ">
                                    <div class="col-12 ">
                                        <img class="float-left " src="./assets/public/images/loading.gif " alt=" ">
                                    </div>
                                </div>
                            </ng-container>
                            <hr>
                            <a href="javascript:void(0) " (click)="logout() " class="text-dark font-weight-bold center ">
                                <span [innerHTML]=" '@i18n-menu-label-exit' | i18n "></span>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </nav>
</div>