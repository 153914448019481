<app-template>
    <ng-template app-content componentName="mainContentModalCenter">

        <div *ngIf="newGuest" class=" capa-menu-2 w-100 h-100" (click)="newGuest = false">

            <div class="p-2 d-flex justify-content-center flex-column align-items-center w-100 h-100" style="border-radius: 8px;">
                <div class="container bg-white p-2" (click)="$event.stopPropagation();" style="height: 70px;">
                    <h5 class="font-weight-bold text-secondary pt-2">Crear Invitado</h5>
                    <hr>
                </div>
                <div style="max-height:calc(90%  - 150px) ;overflow: auto;" class="container bg-white p-2" (click)="$event.stopPropagation();">
                    <div class="  p-2">
                        <ak-new-guest (response)="setListGuest()" [internal]="true"></ak-new-guest>
                    </div>
                </div>

            </div>

        </div>

    </ng-template>

    <ng-template appContent componentName="responsesWindowContent">
        <div class="full-modal-2 compleat-modal" style="height: 100vh!important;" *ngIf="showModalResponses">
            <div class="header active-cursor" style="text-align:right">
                <div class="float-right" (click)="showModalResponses=!showModalResponses">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i>
                </div>
                <div class="pr-2 pl-2 pb-2 float-left">
                    <div class=" pt-1">
                        <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>
                    </div>
                </div>
            </div>
            <div class="body" style="overflow:auto">
                <ak-responses (close)="showModalResponses = $event" [showCommentsBoolean]="showCommentsBoolean"></ak-responses>
            </div>
        </div>
    </ng-template>
</app-template>


<div class="mt-1 container-fluid">


    <div class="mt-4 jumbotron jumbotron-fluid p-3 mb-2 ">

        <div class="d-flex bd-highlight">

            <div>
                <h5 class="font-weight-normal">
                    <i class="fas fa-users pr-1"></i> Invitados
                </h5>
            </div>

            <div class="ml-auto">
                <i class="fas fa-ellipsis-v "></i>
            </div>
        </div>

    </div>


    <div class="row   pt-4 mb-4 ">

        <div class="col-12 text-primary col-md-7 mb-3 ">
            <input (keyup.enter)="updateFilter(fieldSearch) " [disabled]="loadingIndicator?true:false" [(ngModel)]='fieldSearch' class="form-control active-cursor padding-filter-searh-input " type="text " placeholder='CI, Telf, Nombre, Apellido' />
            <i *ngIf="!loadingIndicator" (click)="updateFilter (fieldSearch) " class="text-primary fas fa-search icon-filter-searh active-cursor"></i>
            <img *ngIf="loadingIndicator" class="icon-filter-searh  icon-filter-searh-load " title="Buscar " src="./assets/public/images/loading.gif " alt=" "></div>

        <div class="d-none d-md-block text-primary col-md-5 mb-3 ">
            <div class="text-left text-md-right pt-1 pt-md-0 pb-md-0 ml-md-2 ">
                <button (click)="redirectClient()" class="btn btn-outline-primary ">
                    Ir a clientes
                </button>
            </div>

        </div>

        <div class="col-12">
            <div class="text-left pt-1 pt-md-0 pb-md-0  ">
                <button (click)="newGuest = true" class="btn btn-primary ">
                    Nuevo
                </button>
                <button (click)="redirectClient()" class="ml-2  d-md-none btn btn-outline-primary ">
                    Ir a clientes
                </button>
            </div>


        </div>
    </div>


    <div class="d-flex bd-highlight mt-3" *ngIf="paginator">

        <div class="">
            <i *ngIf="!reload_loading" (click)="reloadTransaction()" class="fas fa-redo text-secondary ml-2 active-cursor "></i>
            <span *ngIf="updateDate" class="text-secondary  fz-14 ml-2" [title]="updateDate?.time"> Actualizado
                <span [innerHTML]="updateDate?.value"></span>
            </span>
        </div>

        <div class="text-secondary ml-auto">
            {{ (paginator?.pageable?.to===0)?(paginator?.pageable?.to):(paginator?.pageable?.from) }} - {{ paginator?.pageable?.to }} de
            <b>{{ paginator?.number }} </b>
            <i class="fas fa-chevron-left mr-1 text-secondary active-cursor" [class.active-cursor]="!paginator?.first" [class.disabled]="paginator?.first" (click)="action_paginator('BACK')"></i>
            <i class="fas fa-chevron-right text-secondary " [class.active-cursor]="!paginator?.last" [class.disabled]="paginator?.last" (click)="action_paginator('NEXT')"></i>
        </div>

    </div>

    <hr>


    <ng-container *ngIf="reload_loading">
        <div class=" w-100 mt-2">
            <img src="./assets/public/images/loading.gif" alt="">
        </div>
    </ng-container>



    <div class="pb-3">


        <ng-container *ngFor="let row of rows ; let i = index">

            <div class="card pt-4 border-0 ">

                <div class="card-body p-2 pl-0 pr-0 fz-14 ">

                    <div class="d-flex bd-highlight">
                        <div class="w-95">
                            <div class="d-flex bd-highlight  flex-wrap active-cursor">

                                <div class="w-33 w-sm-100  d-flex bd-highlight  list-flex flex-wrap">

                                    <div class="bd-highlight w-15">
                                        <div class="DomainUserAvatar-avatar text-dark AvatarPhoto--client Avatar--color-{{akinitial[row.identifier]?.initial}}  AvatarPhoto AvatarPhoto--small" role="img">
                                            <span *ngIf="!row?.businessName">{{akinitial[row?.identifier]?.initials}}</span>
                                            <span *ngIf="row?.businessName">{{akinitial[row?.identifier]?.initial}}</span>
                                        </div>

                                    </div>
                                    <div class="bd-highlight w-85 pl-2">


                                        <div class="font-weight-bold active-cursor text-capitalize fz-14 ">
                                            <i *ngIf="row?.personType != 'NATURAL'" [ngClass]="{'text-primary': row?.isClient }" class="iconNomber fas fa-building  pr-1"></i>
                                            <i *ngIf="row?.personType == 'NATURAL'" [ngClass]="{'text-primary': row?.isClient}" class="iconNomber fas fa-user   pr-1"></i>

                                            <span *ngIf="row?.businessName">{{row?.businessName}}</span><br *ngIf="row?.businessName">
                                            <span [ngClass]="{'font-weight-normal ml-3 fz-14 ': row?.businessName}">{{row?.name}}</span>


                                        </div>


                                        <div class="text-secondary   ml-3">
                                            {{row?.fecha | date: 'dd/MM/yyyy HH:mm'}}
                                        </div>

                                    </div>
                                </div>

                                <div class="w-33 w-sm-100 mt-3 mt-md-0">
                                    <div class="d-flex bd-highlight  flex-wrap">
                                        <div class="w-15 text-center ">
                                            <i class="fa fa-phone-alt text-secondary mr-2 "></i>
                                        </div>

                                        <div class="w-85">
                                            <div class="text-capitalize ">

                                                <a (click)="gotoWhatsapp($event,row?.phone);" href="javascript:void(0) " class="text-primary ">
                                                {{row?.phone}} <i class="fab fa-whatsapp pl-1"></i>
                                            </a>

                                            </div>
                                            <div class="">{{row?.email}}</div>
                                        </div>


                                    </div>

                                </div>

                                <div class="w-33 w-sm-100 mt-3 mt-md-0 ">
                                    <div class="d-flex bd-highlight flex-wrap ">
                                        <div class="w-15 text-center ">
                                            <i class="fa fa-users text-secondary mr-2 "></i>

                                        </div>

                                        <div class="w-85 ">
                                            <div class="text-lowercase ">{{row?.executiveUsername}}

                                            </div>

                                        </div>


                                    </div>

                                    <div class="d-flex bd-highlight flex-wrap mt-2 ">

                                        <div class="w-15 text-center ">
                                            <i class="fa fa-paper-plane text-secondary mr-2 "></i>

                                        </div>
                                        <div class="w-85 ">

                                            <div class=" ">{{row?.application}}

                                            </div>

                                        </div>


                                    </div>
                                    <div class="d-flex bd-highlight flex-wrap mt-2 " *ngIf="row?.extraData == 'order'">

                                        <div class="w-15 text-center ">
                                            <i class="fa fa-indent text-secondary mr-2 "></i>

                                        </div>
                                        <div class="w-85 ">

                                            <div class="text-capitalize ">{{row?.extraData}}

                                            </div>

                                        </div>


                                    </div>


                                </div>



                            </div>

                        </div>
                        <div class="w-5 ">

                            <div [title]=" 'Opciones' ">
                                <i (click)="togle_botton_window(row) " class="pr-1 fas fa-list text-secondary active-cursor "></i>
                            </div>

                        </div>

                    </div>




                </div>
            </div>


        </ng-container>
    </div>

    <!-- paginator -->
    <div class="row content-paginator m-t-1" *ngIf="paginator">
        <akeela-paginator [paginator]="paginator" (response)="action_paginator($event)" *ngIf="!reload_loading">
        </akeela-paginator>
    </div>


</div>