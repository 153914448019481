<div class="container-fluid mt-5">
    <h4 class="">{{emailMsg}}</h4>

    <h4 class="">{{PhoneMsg}}</h4>



    <ng-container *ngIf="loading">
        <div>
            <img src="/assets/public/images/loading.gif" alt="">
        </div>
    </ng-container>
</div>