
<ng-container *ngIf="responses?.length>0">

    <ng-container *ngFor="let response of responses; let i = index" [attr.data-index]="i">
        <div class="col-lg-12">
            <span>
                <b>{{ response?.stateInWorkFlow.name }}</b>
            </span>
            <br>
            <span>
                <small>{{ (response?.dateOfThing?.startDate | date:' d/M/yyyy h:mm a') }} </small>
            </span>
            <br>
            <br>
            <div class="col-12">
                <span [innerHTML]="response?.responseDescription"></span>
            </div>
            <br>
            <br>
        </div>
    </ng-container>

</ng-container>

<ng-container *ngIf="responses?.length===0">

    <div class="col-lg-12 text-center" [innerHTML]="'@i18n-text-no-result-to-show' | i18n">
        
    </div>
    <br>
    <br>
    <br>
</ng-container>
<br>
<br>
<br>
<br>