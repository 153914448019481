import { HttpService } from '@akeela/properties';
import { Injectable } from '@angular/core';

@Injectable()
export class ProgressService {
  constructor(
    private _httpService: HttpService,

  ) { }
  indicatorsProgres = {};



getIndicatorsProgres(workflowCode,transactionData:any = false,personIdentifier,role,audience) {

  let data :any;

  if(workflowCode && transactionData){

    let transactions = [];
        transactions = [...transactions, {transactionNumber: transactionData?.transactionNumber}]
    data = {
      "workflow":workflowCode,
      bankAccountTransactions :transactions
    } ;

  }  
  
  if(workflowCode && !transactionData){

    data = {
      "workflow":workflowCode,
      "personId": personIdentifier
  } ;


  }
 
    
 
  const params = {

    name: 'resume$workflow_progress$post',
    params: {
      request: {
        role: role,
        audience: audience,

      },
      path: {},
      body: data

    }
  };
  

  let indicatorsProgres = {};
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    if (response) {
      let progress;
      let preData =response[0]?.modules;

      if( preData.length > 1){
      let  progress2 = preData?.filter((resp) => {
          return resp?.alternateName == workflowCode;
        });
       progress  = progress2[0].progress;

      }else{
       progress = response[0]?.modules[0]?.progress;

      }
    

       let  maxValue = progress?.maxValue;
       let  minValue = progress?.minValue;

       indicatorsProgres['maxValue'] = maxValue;
       indicatorsProgres['minValue'] = minValue;
       indicatorsProgres['percent'] = minValue > 0 ?   this.round(minValue*100/maxValue):0;

            this.indicatorsProgres[workflowCode] = indicatorsProgres;

      }

  }, error => {


  });

}

getIndicatorsProgresData(workflowCode){

  return  this.indicatorsProgres[workflowCode]?this.indicatorsProgres[workflowCode]:false;


} 


round(value) {
  return Math.round(value);
}
getSedCompleatDoc(){
  return this.getIndicatorsProgresData('DOC_SUPPORT') && this.getIndicatorsProgresData('DOC_SUPPORT')?.percent < 100
}

getCompleatDoc(){
  return this.getIndicatorsProgresData('DOC_SUPPORT') && this.getIndicatorsProgresData('DOC_SUPPORT')?.percent == 100
}


getSedCompleatTransaction(transactionData){
  return this.getIndicatorsProgresData(transactionData.plan.url) && this.getIndicatorsProgresData(transactionData.plan.url)?.percent < 100
}


getCompleatTransaction(transactionData){
  
  return  this.getIndicatorsProgresData(transactionData.plan.url) && (this.getIndicatorsProgresData(transactionData.plan.url)?.percent == 100)

}

}
