import { Component, OnInit, Input, AfterContentInit } from "@angular/core";
import { environment } from "../../../../../environments/environment";
const BASE_URL = "./";

@Component({
  selector: "app-navbar-img",
  templateUrl: "./navbar-img.component.html",
  styleUrls: ["./navbar-img.component.css"]
})
export class NavbarImgComponent implements OnInit {
  url_desktop: string;
  url_movil: string;
  @Input() height;
  @Input() imgmovil;
  @Input('type') type?: string;

  constructor() { }

  ngOnInit() {

    if (environment.theme.images === 'MIBANCO') {
      if (this.type === 'black') {
        this.url_desktop = BASE_URL + 'assets/mibanco/public/images/mibanco2.svg';
        this.url_movil = BASE_URL + 'assets/mibanco/public/images/mibanco2.svg';
      } else {
        this.url_desktop = BASE_URL + 'assets/mibanco/public/images/mibanco2.svg';
        this.url_movil = BASE_URL + 'assets/mibanco/public/images/mibanco2.svg';
      }
    } else if (environment.theme.images === 'FACEBANK') {
      if (this.type === 'black') {
        this.url_desktop = BASE_URL + 'assets/facebank/public/images/icono_claro.png';
        this.url_movil = BASE_URL + 'assets/facebank/public/images/icono_claro.png';
      } else {
        this.url_desktop = BASE_URL + 'assets/facebank/public/images/icono_claro.png';
        this.url_movil = BASE_URL + 'assets/facebank/public/images/icono_claro.png';
      }
    }

    else if (environment.theme.images === 'FACEBANK') {

      if (this.type === 'claro') {

        this.url_desktop = BASE_URL + "";
        this.url_movil = BASE_URL + "";
      } else {

        this.url_desktop = BASE_URL + "assets/facebank/public/images/logo_claro.jpeg";
        this.url_movil = BASE_URL + "assets/facebank/public/images/icono_claro.png";
      }


    }
    else {
      if (this.type === 'claro') {

        this.url_desktop = BASE_URL + "";
        this.url_movil = BASE_URL + "";

      } else {


        this.url_desktop = BASE_URL + "assets/public/images/akeela_logo_claro.svg";
        this.url_movil = BASE_URL + "assets/public/images/akeela_logo_claro.svg";
      }
    }
  }

}
