import { Route } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { WorkflowRoutes } from '@akeela/workflow';
import { WorkflowComponent } from '../components/workflow/workflow.component';
import { PublicDashboardComponent } from '../components/dashboard/dashboard.component';
import { RecoveryPasswordComponent } from '../components/recovery-password/recovery-password.component';
import { CreateUserPasswordComponent } from '../components/create-user-password/create-user-password.component';
import { ValidateSession } from '../../../guards/validatesession.guard';
import { UnlockPasswordComponent } from '../components/unlock-password/unlock-password.component';
import { ChangePasswordPublicComponent } from '../components/change-password/change-password.component';
import { AuthGuard } from '../../../guards/auth.guard';
import { ListMeetComponent } from '../../private/components/list-meet/list-meet.component';
import { ClientDashboardComponent }      from '../../../modules/private/components/client-dashboard/client-dashboard.component';
import { TransactionDashboardComponent }      from '../../../modules/private/components/transaction-dashboard/transaction-dashboard.component';
import { LoginComponent } from '../../../modules/public/components/login/login.component';

import { NewGuestComponent } from '../../../components/new-guest/new-guest.component';

import { ListGuestComponent } from '../../../modules/private/components/list-guest/list-guest.component';
import { ListOrdersComponent } from '../../../modules/private/components/list-orders/list-orders.component';
import { streamComponent } from '../../../modules/private/components/stream/stream.component';


import { UpdateEmailPhoneComponent } from '../../../modules/private/components/update-email-phone/update-email-phone.component';


export const PUBLIC_EXTERNAL_ROUTES: Route = {
    path: '',
    children: [
        {
            path: 'login',
            component: LoginComponent
        }
        ,
        {
            path : 'recovery-password',
            component: RecoveryPasswordComponent
        },
        {
            path : 'create-new-password/:username/:token',
            component: CreateUserPasswordComponent
        },
        {
            path : 'change-password/:username/:token',
            component: ChangePasswordPublicComponent
        },
        {
            path : 'unlock',
            component: UnlockPasswordComponent
        },
       {
            path: 'guest',
            component: NewGuestComponent
        },
       
        {

            
            path: 'console',
            children: [
                {
                    path: '', 
                      redirectTo: '/private/ACCOUNT_OPENING/stages/ACCOUNT_CONTRACT_ANALYSIS/steps/USER_WORKSPACES', pathMatch: 'full'
                },
                {
                    path: 'listMeet',
                    component: ListMeetComponent,
                    canActivate: [AuthGuard]
                },
                {
                    path: 'client-data',
                    component: ClientDashboardComponent,
                    canActivate: [AuthGuard]
            
                },
                {
                    path: 'transaction-dashboard',
                    component: TransactionDashboardComponent,
                    canActivate: [AuthGuard]
            
                }, 

                {
                    path: 'list-guest',
                    component: ListGuestComponent,
                    canActivate: [AuthGuard]
                },
                {
                    path: 'list-orders',
                    component: ListOrdersComponent,
                    canActivate: [AuthGuard]

                },

                
                {
                    path: 'update-email',
                    component: UpdateEmailPhoneComponent,
                    canActivate: [AuthGuard]
                },
                {
                    path: 'update-phone',
                    component: UpdateEmailPhoneComponent,
                    canActivate: [AuthGuard]
                },
                {
                    path: 'stream',
                    component: streamComponent,
                    canActivate: [AuthGuard]
                },
                
            ]
        },
    ]
};

export const PUBLIC_ROUTES: Route = {
    path: 'public',
    component: WorkflowComponent,
    children: WorkflowRoutes,
    canActivate: [ValidateSession]
};

