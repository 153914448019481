import { Component, OnInit, Input, Output, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { isNullOrUndefined, isNull } from 'util';
import { HttpService } from '@akeela/properties';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '@akeela/auth';
import { CommonService } from '@akeela/common';
import { LocalService } from '@akeela/local-storage';
import { environment } from '../../../../environments/environment';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-detail-popup',
  templateUrl: './detail-popup.component.html',
  styleUrls: ['./detail-popup.component.css']
})
export class DetailPopupComponent implements OnInit, OnChanges, OnDestroy {
  code;
  subscriptions: Subscription[] = [];
  workspace = null;
  env = environment;

  constructor(
    private _httpService: HttpService,
    private _auth: AuthService,
    private _common: CommonService,
    private localStorage: LocalService,
    public  config: NgbDropdownConfig,

  ) {
    config.placement = 'top-left';
    config.autoClose = true;
   }

  ngOnInit() {
    this.code = this.localStorage.getItem('workspace');
    if (!isNullOrUndefined(this.code) && this._auth.isAuthenticated()) {
      this.getWorkflowDetail();
    }

    this._common.workspaceCode$.subscribe((codeWorkspace) => {
      if (!isNullOrUndefined(codeWorkspace)) {
        this.code = this.localStorage.getItem('workspace');
        this.getWorkflowDetail();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'code': {
            if (!isNullOrUndefined(changes[propName].currentValue)) {
              this.code = changes[propName].currentValue;
              if (!isNullOrUndefined(this.code) && this._auth.isAuthenticated()) {
                this.getWorkflowDetail();
              }
            }
          }
        }
      }
    }
  }

  getWorkflowDetail() {
    this.workspace = null;
    const request = {
      light: true
    };

    const params: any = {
      name: 'workflow$workspace$get',
      params: {
        request: request,
        path: {
          workspaceCode: this.code,
          prefixWorkflow: 'workspace_manager'
        },
        body: {}
      }
    };

    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.workspace = response;
    }, error => {
      this.workspace = null;
    }));
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

}
