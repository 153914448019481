<div *ngIf="env?.appEndPontDefaultConfig?.showPayroll" class="mb-3 ">
    <app-list-formalities-documentation></app-list-formalities-documentation>
</div>

<div class="recaudos" *ngIf="total_requirements && transactionData.accountStatus[0].additionalType==='AC_PENDING'">

    <div class="font-weight-bold  mb-3 text-secondary">Recaudos de la solicitud</div>


    <div *ngIf="total_requirements && transactionData.accountStatus[0].additionalType==='AC_PENDING'" class="card active-cursor" (click)="redirect_requirements()">
        <div class="card-body p-2 ">
            <div class="d-flex bd-highlight">
                <div class="bd-highlight item mr-2">
                    <span class="font-weight-normal  pb-2  pr-2">Aprobados</span>
                    <div class="text-success font-weight-bold" [innerHTML]="total_requirements?.total_required_responses_user_requirements_completed_in_status_verified"></div>
                </div>

                <div class="bd-highlight  item mr-2 ml-2 pr-2">
                    <span class="font-weight-normal pb-2 ">Incompletos</span>
                    <div class="text-warning font-weight-bold" [innerHTML]="total_requirements?.total_required_responses_user_requirements_completed_in_status_incompleted"></div>
                </div>

                <div class="bd-highlight  item  pr-2 ml-2">
                    <span class="font-weight-normal  pb-2">Rechazados</span>
                    <div class="text-danger font-weight-bold" [innerHTML]="total_requirements?.total_required_responses_user_requirements_completed_in_status_rejected"></div>
                </div>

                <div class="bd-highlight    pr-2 ml-2">
                    <span class="font-weight-normal pb-2">En revisión</span>
                    <div class="text-danger font-weight-bold" [innerHTML]="total_requirements?.total_required_responses_user_requirements_completed_in_status_inconsistent"></div>
                </div>

                <div class="ml-auto bd-highlight  ml-2">
                    <i class="mt-3 ml-3 fas fa-chevron-right text-primary float-right"></i>

                    <div class="mt-1 float-right mr-2 ak-abatar ak-dinamic-color-abatar" [innerHTML]="total_requirements?.total_requirements"></div>
                </div>
            </div>

        </div>
    </div>
    <ng-container *ngIf="total_requirements?.total_requirements_completed!==null && transactionData.accountStatus[0].additionalType==='AC_PENDING'">
        <ng-container *ngIf="total_requirements?.total_requirements_completed>0">
            <div class=" mt-2 " *ngIf="total_requirements?.total_required_responses_user_requirements_completed  < total_requirements?.total_requirements_completed || total_requirements?.total_required_responses_user_requirements_completed_in_status_inconsistent > 0">
                <a (click)="redirect_requirements()" href="javascript:void(0)" class="font-weight-normal text-danger small">
                    <span class="fas fa-info-circle pr-"></span>
                    Revise y apruebe los recaudos
                </a>
            </div>
        </ng-container>
    </ng-container>

</div>



<div class="mb-5" *ngIf="!(total_requirements)">
    <img class="float-left " src="./assets/public/images/loading.gif " alt=" ">
    <br>

</div>
<div  *ngIf="response" class="pt-4"></div>

<h5  class="position-absolute font-weight-bold text-secondary mt-2" *ngIf="response" >Respuesta de la Solicitud</h5>



<!--
<div class="font-weight-bold text-dark mb-2 " style="margin-top: 50px">Respuesta</div>
-->