

<akeela-auth-unlock-password 
  [params]="params_unlock"
  (response)="response_check($event)"
  *ngIf="step===1"></akeela-auth-unlock-password>

<akeela-auth-unlock-password-check 
  [username]="username"
  [email]="email"
  [time]="time"
  [params]="params_check"
  (response)="response_check_code($event)"
  *ngIf="step===2">
</akeela-auth-unlock-password-check>

<akeela-auth-unlock-password-check-question
  [username]="username"
  [email]="email"
  [params]="params_question"
  (response)="response_check_question($event)"
  *ngIf="step===3">
</akeela-auth-unlock-password-check-question>

<akeela-auth-create-user-password
  [username]="username"
  [config]="params_change_password"
  (response)="response_change_password($event)"
  *ngIf="step===4">
</akeela-auth-create-user-password>

