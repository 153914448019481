import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// MODULES...
import { AkeelaPropertiesModule } from '@akeela/properties';
import { AkeelaAuthModule as AuthModule } from '@akeela/auth';
import { ProductsPlansModule } from '@akeela/products-plans';
import { AkeelaMenuModule, AkeelaSidebarMenuService } from '@akeela/menu';


import { Routes, RouterModule } from '@angular/router';
import { I18nModule, I18nService, I18nPipe } from '@akeela/i18n';
import { CommonModule, APP_BASE_HREF, DatePipe } from '@angular/common';
import { AkeelaCommonModule, I18nService as I18nServiceAkeela } from '@akeela/common';
import { AkeelaWorkflowModule, AkeelaStepService, AkeelaDatamodelService } from '@akeela/workflow';
import { AkeelaBreadcrumbModule, AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { AkeelaRequirementsModule } from '@akeela/requirements';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ResponsesComponent } from '../components/responses/responses.component';
import { InnerResponsesComponent } from '../components/inner-responses/inner-responses.component';



// SERVICES...
import { SidebarService } from '../services/sidebar/sidebar.service';
import { PropertiesService } from '../services/properties/properties.service';
import { TransitionService } from '../services/transition.service';
import { TemplateService } from '../services/template.service';
import { EmailService } from '../services/email.service';
import { Favicons, BrowserFavicons, BROWSER_FAVICONS_CONFIG } from '../services/favicons.service';
import { NotesService } from '../services/notes.service';

// COMPONENTS...
import { HeaderComponent } from '../components/header/header.component';
import {
    SubHeaderComponent, StageComponent, StepComponent, FooterComponent,
    SidebarComponent, NavbarComponent, NavbarImgComponent, NavbarauthComponent
} from '../components/index';


// OTROS...
import { UcwordsPipe } from '../helpers/pipes/ucwords.pipe';
import { environment } from 'src/environments/environment';
import { SubheaderService } from '../services/subheader.service';
import { TemplateComponent } from '../components/template/template.component';
import { ContentDirective } from '../directives/content.directive';
import { SafePipe } from '../helpers/pipes/safe.pipe';
import { MiddlewareService } from '../services/middleware.service';

import { AuthGuard } from '../guards/auth.guard';
import { ValidateSession } from '../guards/validatesession.guard';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterComponent } from '../components/filter/filter.component';
import { SearchComponent } from '../components/search/search.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { FilterStatusComponent } from '../components/filter-status/filter-status.component';
import { FilterOrderComponent } from '../components/filter-order/filter-order.component';
import { TabsComponent } from '../components/tabs/tabs.component';
import { TabsService } from '../services/tabs.service';
import { AlertComponent } from '../components/alert/alert.component';
import { DatePipePipe } from '../helpers/pipes/date-pipe.pipe';
import { LogoImgComponent } from '../components/logo-img/logo-img.component';
import { FilterPolicysComponent } from '../components/filter-policys/filter-policys.component';
import { SortComponent } from '../components/sort/sort.component';
import { Utf8RegexService } from '../services/utf8-regex.service';
import { DetailPopupComponent } from '../components/workspace/detail-popup/detail-popup.component';
import { LocalStorageModule } from '@akeela/local-storage';
import { AkTableModule } from '@akeela/ak-table';

import { MenuModuleComponent } from '../components/menu-module/menu-module.component';
import { BoxComponent } from '../components/menu-module/box/box.component';
import { GraphModule } from '@akeela/graph';
import { BoxComponent as ResumenBoxComponent } from '../modules/private/components/resume/box/box.component';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { NgxChartsModule } from '@swimlane/ngx-charts';

import { TransactionService } from '../services/transaction.service';

import { NgxFaceApiJsModule } from '@akeela/ngx-face-api-js';

import { NgxDocViewerModule } from 'ngx-doc-viewer2';
import { ImageViewerModule } from 'ngx-image-viewer';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NewGuestComponent } from '../components/new-guest/new-guest.component';

import { CurrencyMaskModule } from 'ng2-currency-mask';



@NgModule({
    imports: [
        NgxFaceApiJsModule.forRoot({
            modelsUrl: 'https://raw.githubusercontent.com/justadudewhohacks/face-api.js/master/weights'
        }),
        CommonModule,
        I18nModule.config(environment.i18nConfig),
        RouterModule,
        BsDropdownModule.forRoot(),
        AkeelaPropertiesModule.config(environment.appPropertiesConfig),
        AkeelaWorkflowModule.config(environment.apiConfig),
        AkeelaCommonModule,
        FormsModule,
        ReactiveFormsModule,
        AuthModule.config(environment.auth),
        ProductsPlansModule,
        AkeelaMenuModule,
        AkeelaRequirementsModule,
        AkeelaBreadcrumbModule,
        NgbModule,
        SweetAlert2Module.forRoot(),
        LocalStorageModule.config(environment.localStorageConfig),
        AkTableModule,
        GraphModule,
        NgxDatatableModule,
        BrowserAnimationsModule,
        NgxDocViewerModule,
        ImageViewerModule.forRoot(),
        CurrencyMaskModule
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        NavbarComponent,
        NavbarImgComponent,
        NavbarauthComponent,
        StageComponent,
        StepComponent,
        SubHeaderComponent,
        UcwordsPipe,
        TemplateComponent,
        ContentDirective,
        SafePipe,
        FilterComponent,
        SearchComponent,
        FilterStatusComponent,
        FilterOrderComponent,
        TabsComponent,
        AlertComponent,
        DatePipePipe,
        LogoImgComponent,
        FilterPolicysComponent,
        SortComponent,
        DetailPopupComponent,
        MenuModuleComponent,
        BoxComponent,
        ResumenBoxComponent,
        ResponsesComponent,
        InnerResponsesComponent,
        NewGuestComponent
        
    ],
    providers: [
        NotesService,
        DatePipe,
        EmailService,
        TemplateService,
        SubheaderService,
        TransitionService,
        SidebarService,
        PropertiesService,
        I18nServiceAkeela,
        AkeelaStepService,
        AkeelaDatamodelService,
        AkeelaSidebarMenuService,
        AkeelaBreadcrumbService,
        AuthGuard,
        ValidateSession,
        MiddlewareService,
        // The Favicons is an abstract class that represents the dependency-injection
        // token and the API contract. THe BrowserFavicon is the browser-oriented
        // implementation of the service.
        {
            provide: Favicons,
            useClass: BrowserFavicons
        },
        // The BROWSER_FAVICONS_CONFIG sets up the favicon definitions for the browser-
        // based implementation. This way, the rest of the application only needs to know
        // the identifiers (ie, 'happy', 'default') - it doesn't need to know the paths
        // or the types. This allows the favicons to be modified independently without
        // coupling too tightly to the rest of the code.
        {
            provide: BROWSER_FAVICONS_CONFIG,
            useValue: {
                icons: {
                    'MIBANCO': {
                        type: 'image/x-icon',
                        href: './assets/mibanco/public/images/favicon.ico'
                    },
                    'FACEBANK': {
                        type: 'image/x-icon',
                        href: './assets/facebank/public/images/favicon.ico'
                    },
                    'AKEELA': {
                        type: 'image/x-icon',
                        href: './assets/public/images/favicon.ico',
                        isDefault: true
                    },
                    'DEMO1': {
                        type: 'image/x-icon',
                        href: './assets/demo1/public/images/favicon.ico',
                        isDefault: true
                    },
                    'DEMO2': {
                        type: 'image/x-icon',
                        href: './assets/demo2/public/images/favicon.ico',
                        isDefault: true
                    },
                    'CARONI': {
                        type: 'image/x-icon',
                        href: './assets/caroni/public/images/favicon.ico'
                    },
                    'RENDIVALORES': {
                        type: 'image/x-icon',
                        href: './assets/rendivalores/public/images/favicon.png'
                    },
                    'MC': {
                        type: 'image/x-icon',
                        href: './assets/mc/public/images/favicon.png'
                    },'KOI': {
                        type: 'image/x-icon',
                        href: './assets/koi/public/images/favicon.ico',
                        isDefault: true
                    },'PERCAPITA': {
                        type: 'image/x-icon',
                        href: './assets/percapita/public/images/favicon.ico',
                        isDefault: true
                    }
                },

                // I determine whether or not a random token is auto-appended to the HREF
                // values whenever an icon is injected into the document.
                cacheBusting: true
            }
        },
        TabsService,
        Utf8RegexService,
        TransactionService,
        NgxImageCompressService
        ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        UcwordsPipe,
        CommonModule,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        NavbarComponent,
        NavbarImgComponent,
        NavbarauthComponent,
        AkeelaWorkflowModule,
        StageComponent,
        StepComponent,
        SubHeaderComponent,
        AuthModule,
        ProductsPlansModule,
        TemplateComponent,
        ContentDirective,
        AkeelaMenuModule,
        AkeelaBreadcrumbModule,
        AkeelaCommonModule,
        SafePipe,
        AkeelaRequirementsModule,
        FilterComponent,
        SearchComponent,
        FilterStatusComponent,
        FilterOrderComponent,
        TabsComponent,
        AlertComponent,
        DatePipePipe,
        LogoImgComponent,
        NgbModule,
        FilterPolicysComponent,
        SortComponent,
        SweetAlert2Module,
        DetailPopupComponent,
        AkTableModule,
        GraphModule,
        MenuModuleComponent,
        BoxComponent,
        ResumenBoxComponent,
        NgxDatatableModule,
        ResponsesComponent,
        InnerResponsesComponent,
        BrowserAnimationsModule,
        NgxDocViewerModule,
        ImageViewerModule ,
        NewGuestComponent,
        CurrencyMaskModule      
    ],
    entryComponents: []
})
export class SharedModule {
    constructor() {
        I18nModule.config(environment.i18nConfig);
    }
}

