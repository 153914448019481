import { Component, OnInit } from '@angular/core';
import { isNullOrUndefined, isNull } from 'util';
import { AkeelaStepService, AkeelaWorkflowService, AkeelaStageService } from '@akeela/workflow';
import { RequirementsService } from '@akeela/requirements';
import { environment } from '../../../../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-resumen-sections-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class ResumenHeaderComponent implements OnInit {
  total_requirements: any = null;
  env = environment;
  response ;

  transactionData: any = null;

  constructor(
    private akWorkflowService: AkeelaWorkflowService,
    private _akeelaRequirementsService: RequirementsService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {


  this.akWorkflowService.transactionData$.subscribe((transactionData: any) => {
    
      if (!isNullOrUndefined(transactionData)) {
        const temp = transactionData.accountStatus[0].additionalType;
    
            if (temp !== 'AC_PENDING'){
              let hideSections = {};
              hideSections['RESPONSE_MODAL'] = true;

              this.akWorkflowService.setHideSections(hideSections);

            }else{
              this.response = true;

              let hideSections = {};

              this.akWorkflowService.setHideSections(hideSections);
              
          
            }
      }

    });
      
    // Contador de recaudos
    this.requirements().then((response) => {
      this.total_requirements = response;
    });

  }

  redirect_requirements() {
    this.router.navigateByUrl(
      this.router.createUrlTree(['private/ACCOUNT_OPENING/stages/ACCOUNT_CONTRACT_ANALYSIS/steps/CHECKLIST_TAB'],
        {
          queryParams: {}
        }
      )
    ).then(() => {
      // Something action after redirect
    });
  }

  requirements() {
    return new Promise((resolve, reject) => {
      this.akWorkflowService.transactionData$.subscribe((transactionData: any) => {
        if (!isNullOrUndefined(transactionData)) {
          this.transactionData = transactionData;
          
          const config = {
            statusResponseRequirement: {
              verified: 'VERIFIED',
              inconsistent: 'INCONSISTENT',
              incomplete: 'INCOMPLETED',
              rejected: 'REJECTED'
            },
            endpoints: {
              getRequirements: {
                identifier: '',
                name: 'requirements$transaction_requirements$get',
                params: {
                  request: {
                    audience: transactionData.holder.additionalType ? transactionData.holder.additionalType : 'LEGAL'
                  },
                  path: {
                    idPlan: transactionData.plan.identifier,
                    transactionNumber: transactionData.transactionNumber,
                    idPerson: transactionData.holder ? transactionData.holder.identifier : null
                  },
                  body: {}
                }
              },
              saveRequirement: {
                params: {
                  request: {},
                  path: {},
                  body: {
                    'transaction': {
                      'identifier': transactionData.identifier,
                      'name': transactionData.transactionNumber
                    },
                    'requirement': {
                      'identifier': null
                    },
                    'requirementArchives': [
                      {
                        'creator': {
                          'identifier': null
                        }
                      }
                    ],
                    'person': {
                      'identifier': transactionData.holder.identifier,
                      'identity': transactionData.holder.alternateName,
                    }

                  }
                }
              },
              deleteRequirement: {
                name: 'requirements$requirement$delete',
                params: {
                  request: {},
                  path: {},
                  body: {
                    alternateName: 'ACCREDITED',
                    transaction: {
                      'identifier': transactionData.identifier,
                      'name': transactionData.transactionNumber
                    },
                    requirement: {
                      'identifier': null
                    },
                    person: {
                      'identifier': transactionData.holder.identifier,
                    },
                    requirementArchives: [{
                      identifier: null
                    }]
                  }
                }
              }
            }
          };

          // verifico si ya cargo todos sus recaudos...
          this._akeelaRequirementsService.verifyStatusUploadFiles(config).then((resp: any) => {
            resolve(resp);
          }, error => {
            resolve(null);
          });
        }
      });
    });

  }

}
