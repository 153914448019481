<div class="container  px-0">
    <div class="mb-0 row box  box-section skin-white  animate__animated animate__fadeIn" style="padding-left: 0; padding-right:0">
        <div class="col-12 mt-2">


            <ng-container *ngIf=" !showLoading ">
                <div>
                    <button (click)="openItem(null)" class="btn btn-outline-dark">
                    <span class="fas fa-plus mr-1" ></span>

                    Agregar
                    <span [innerHTML]="'@i18n-quote-fiscal-directions-section.title-plural' | i18n:lang "></span>
                </button>
                </div>
            </ng-container>


            <ng-container *ngIf="itemList  && !showLoading">

                <div *ngIf="!((itemList && itemList.length===0) && !showLoading)" class="mt-4">


                    <akeela-table [configuration]="configuration_table" [loading]="loading_table" (response)="table_response($event)">
                    </akeela-table>

                </div>

            </ng-container>


            <ng-container *ngIf="showLoading ">
                <div class="w-50 ">
                    <img class="" src="./assets/public/images/loading.gif" alt="">
                </div>
            </ng-container>
        </div>
    </div>
</div>