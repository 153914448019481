<div class="row">
  <div class="col-12">

      <ng-container *ngIf="client && configuration_table ">
        <br>
          <akeela-table [configuration]="configuration_table" [order]="filter_order" [loading]="loading_table" [paginator]="paginator"
              (response)="table_response($event)">
          </akeela-table>
          <br>
      </ng-container>

  </div>
</div>


