

<ng-container *ngIf="showLoading">
  <div class="">
    <img src="./assets/public/images/loading.gif" alt="">
  </div>
</ng-container>

<div class="row " *ngIf="items">
  <div class="scrollmenu mt-2">
    <div class="d-flex justify-content-start">

         <div  *ngFor="let item of items; let i_step = index"> 
            <app-box [obj]="item" [selected]="_selected" [last]="i_step===(items.length - 1)" (response)="event_response($event)"></app-box>
         </div>
    </div>

  </div>
</div>


<!--
<div class="row" *ngIf="items">

  <div class="ak-font-roboto col-12 font-weight-bold section-title mt-4">
    <ng-container *ngIf="_selected==='ACCOUNT_OPENING'">
      <span [innerHTML]="'@i18n-workflow-name-accountopening' | i18n"></span>
    </ng-container>
    <ng-container *ngIf="_selected==='REQUIREMENT'">
      
      <span [innerHTML]="'@i18n-workflow-name-docsupport' | i18n"></span>
      
    </ng-container>
    <ng-container *ngIf="_selected==='INTERVIEW'">
      <span [innerHTML]="'@i18n-workflow-name-interview' | i18n"></span>
    </ng-container>
  </div>
</div>
-->