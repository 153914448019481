import { AuthService } from '@akeela/auth';
import { PaginatorService } from '@akeela/common';
import { LocalService } from '@akeela/local-storage';
import { HttpService } from '@akeela/properties';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmailService } from 'src/app/services/email.service';
import { environment } from '../../../../../environments/environment';
import { DatePipe } from '@angular/common';
import { AkeelaWorkflowConfigService } from '@akeela/workflow/lib/services/config/akeela-config.service';
import { I18nPipe } from '@akeela/i18n';
import { WorkflowService } from 'src/app/services/workflow.service';

@Component({
  selector: 'app-list-stream',
  templateUrl: './stream.component.html',
  styleUrls: ['./stream.component.css']
})
export class streamComponent implements OnInit {

  stream: any = [];
  rows = [];
  temp = [];
  columns = [];
  akinitial = {};
  paginator;
  reload_loading;
  loadingIndicator;
  fieldSearch: any = '';
  newStream;
  env: any = environment;
 
  loadingsendEmail = {};
  clientDetail;
  clientSelected;

  constructor(
    private _httpService: HttpService,
    private _auth: AuthService,
    private router: Router,
    private localStorage: LocalService,
    private paginatorService: PaginatorService,
    private datePipe: DatePipe,
    private _wfService: WorkflowService,


  ) { }

  ngOnInit(): void {


    this.paginator = {
      totalPages: 0,
      first: true,
      last: false,
      number: 0,
      pageable: {
        pageNumber: 0,
        from: null,
        to: null
      }
    };
    this.getListNotesAll();

  }

  setConfigurationTableNew() {
    this.columns = [];

    let data = [];
    const content = [];
    let i = 0;



    this.reload_loading = false;

    this.temp = [...this.stream];
    this.rows = [...this.stream];



    data = this.paginatorService.getDataPaginator(this.paginator, this.env, this.temp);
    this.paginator.number = this.temp?.length;
    this.paginator.totalPages = Math.ceil(this.temp?.length / this.env.paginator.size);
    this.paginator.pageable.to = this.paginatorService.getPaginatorTo(this.paginator, this.env);
    this.paginator.pageable.from = this.paginatorService.getPaginatorFrom(this.paginator, this.env);
    this.rows = data;


  }


  resetList = (event) => {

    this.getListNotesAll();
  }



  action_paginator(page) {
    if (page === 'NEXT') {
      page = this.paginator.pageable.pageNumber + 1;
      if (page >= this.paginator.totalPages) {
        page = this.paginator.pageable.pageNumber;
      }
    } else if (page === 'BACK') {
      page = this.paginator.pageable.pageNumber - 1;
      if (page < 0) {
        page = this.paginator.pageable.pageNumber;
      }
    }
    this.paginator.pageable.pageNumber = page;
    this.paginator.first = (page === 0 ? true : false);
    this.paginator.last = ((page + 1) === this.paginator.totalPages ? true : false);
    this.setTablePaginators();
  }



  setTablePaginators() {

    let data = this.paginatorService.getDataPaginator(this.paginator, this.env, this.temp);

    this.paginator.number = this.temp?.length;
    this.paginator.totalPages = Math.ceil(this.temp?.length / this.env.paginator.size);
    this.paginator.pageable.to = this.paginatorService.getPaginatorTo(this.paginator, this.env);
    this.paginator.pageable.from = this.paginatorService.getPaginatorFrom(this.paginator, this.env);
    this.rows = data;


  }



  custom_sort (a, b) {
    return new Date(b?.create_date).getTime() - new Date(a?.create_date).getTime();
  }
  
  
  custom_sort_asc (a, b) {
    return new Date(a?.create_date).getTime() - new Date(b?.create_date).getTime();
  }
  
  
  getListNotesServe = async () => {
  

  
    let data = [   {"ejecutive": "ejecutive"},
    {"status": "ACTIVE"}]
  
    try {
      //environment?.theme?.site?.name
      const params = {
        pathResponse: {
          url: 'https://utilities.akeela.co/note/search',
          httpMethod: 'POST'
        },
        params: {
          request: {
            'environment_code': this.env?.code || this.env?.theme?.images
          },
          body: data
  
        }
      }
  
      return this._httpService.executeRequestExternalUrl(params).toPromise();


    } catch (error) {
  
      console.error(error);
      this.reload_loading = false;
  
    }
  
  }
  getListNotesAll = async () =>{

    this.reload_loading = true;
  
    let listNotes: any = await this.getListNotesServe();
    listNotes.sort(this.custom_sort);
  
    
    let obj = {};
  
    listNotes?.map((r, i) => {
  
  
      let date =  this.datePipe.transform(r?.create_date, 'dd/MM/yyyy');

      let a = obj[date] || [];
      obj[date] = [...a,r];
  
    });
  
    let objectArray = [];
  
    for (const property in obj) {
      objectArray = [...objectArray,{create_date:property,object:obj[property]}]
  
    }

    this.stream = [...objectArray];
    this.setConfigurationTableNew();
    this.reload_loading = false;

  }
  
  private parseDateString(date:string): Date {
    date = date.replace('T','-');
    let parts:any = date.split('-');
    let timeParts = parts[3].split(':');
    
   // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[0], parts[1]-1, parts[2], timeParts[0], timeParts[1]); // Note: months are 0-based
  
  }
  
  
  toDateString(fecha): string {
    let temp2 = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16);
   
     let temp = new Date(fecha).toISOString().slice(0, 16); 
        let date:Date = this.parseDateString(temp2);
     return (("0" + (date.getDate())).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' +date.getFullYear().toString());
   }
   
   
   convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
  
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
  
    newDate.setHours(hours - offset);
  
    return newDate;   
    
  }
  


  setClient(client) {
    this.clientDetail = true;
    this.clientSelected = client;
  }



  setClientDetail(e) {

    if (e?.open) {
     
    } else {
      this.clientDetail = e;
    }

  }

}
