import {Component,OnInit,Renderer2,ViewChild,ElementRef,HostListener,Input} from "@angular/core";

@Component({
  selector: 'dashboard-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class DashboardFooterComponent implements OnInit {
  @ViewChild("footer", {static: false}) footer: ElementRef;

  lang: string;
  height: number;

  constructor(private renderer: Renderer2) {}
  
  ngOnInit() { }

  ngAfterViewInit() {
    document.body.style.paddingBottom =
      this.footer.nativeElement.offsetHeight + "px";
    this.height = this.footer.nativeElement.offsetHeight;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    document.body.style.paddingBottom =
      this.footer.nativeElement.offsetHeight + "px";
    this.height = this.footer.nativeElement.offsetHeight;
  }
}
