import { Injectable } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { BehaviorSubject, Observable } from 'rxjs';
import { resolve, reject } from 'q';
import { environment } from '../../environments/environment';
import { LocalService } from '@akeela/local-storage';


@Injectable({
  providedIn: 'root'
})
export class TransitionService {

  showWorkflow$: BehaviorSubject<boolean> = new BehaviorSubject(false);


  constructor(
    private _httpService: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private i18n: I18nPipe,
    private localStorage: LocalService
  ) { }

  next(prefix, params, queryParams?) {
    if (!params) params = {};
    if (!prefix) prefix = '';



    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      let tmp = response.nextStep.alternateName;
      //JUAN esto es temporal tiene que ser dinamico
      if (params.name == "account_opening$transition$get") {

        tmp = 'REVISIONS_TAB';
      }


      this.router.navigate([prefix + '/ACCOUNT_OPENING/stages/' + response.nextStep.stageParentCode + '/steps/' + tmp], queryParams ? queryParams : {}).then(() => {
        if (this.localStorage.getItem('profile') !== 'SALES_MANAGER') {
          swal.close();
        }
      });

      // this.router.navigateByUrl(this.router.createUrlTree([prefix + '/ACCOUNT_OPENING/stages/' + response.nextStep.stageParentCode + '/steps/' + response.nextStep.alternateName],
      //   {
      //     queryParams: queryParams ? queryParams : {}
      //   }
      // )
      // ).then(() => {
      //   swal.close();
      // });
    }, (error) => {
      if (this.localStorage.getItem('profile') !== 'SALES_MANAGER') {
        swal.close();
      }
    });
  }

  // close() {
  //   swal.fire({
  //     title: '',
  //     showCancelButton: false,
  //     showCloseButton: false,
  //     showConfirmButton: false,
  //     html: '',
  //     timer: 3
  //   });
  // }

  nextStep(prefix, nextStep, params?, queryParams?) {
    this.router.navigate([prefix + '/' + environment.workflow + '/stages/' + nextStep.stageParentCode + '/steps/' + nextStep.alternateName], { queryParams: queryParams });
  }

}
