import { Component, OnInit } from '@angular/core';
import { ProductsService } from "@akeela/products-plans";
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService} from "@akeela/workflow";
import { Router, ActivatedRoute } from '@angular/router';
import { TransitionService } from "../../../../services/transition.service";

@Component({
  selector: 'select-product',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  params_products: any = {};
  default: boolean = true;

  workflow;
  stage;
  step;
  event='ACCOUNT_TYPE_SELECTED';
  url_transition='workflow$transitionssteps$get';

  constructor(
    private _productsService: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private transitionService: TransitionService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private akSectionService: AkeelaSectionService
  ) { }


  ngOnInit() {
    this.akSectionService.background$.next(false);
    this.workflow=this.akWorkflowService.currentWorkflow$.getValue();
    this.stage=this.akStageService.currentStage$.getValue();
    this.step=this.akStepService.currentStep$.getValue();

    this.params_products = {
      endpoint: {
        products: {
          name: 'product_plan$father_products$get',
          params: {
            path: {},
            request: {}
          }
        }
      }
    };

    this._productsService.currentProduct$.subscribe((identifierProduct) => {
      if (identifierProduct && !this.default) {
        const params = {
          name: this.url_transition,
          params: {
            request: {
              role: 'C',
              audience: 'ALL',
              event_code: this.event
            },
            path: {
              prefixWorkflow: 'workflow_manager',
              workflow: this.workflow,
              stage: this.stage,
              step: this.step
            }
          },
          o: {
            identifierProduct: identifierProduct
          }
        }
        this.transitionService.next('dashboard', params);
      }
      this.default = false;
    });

  }



}
