import { AuthService } from '@akeela/auth';
import { UploadService } from '@akeela/common';
import { I18nPipe } from '@akeela/i18n';
import { LocalService } from '@akeela/local-storage';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { HttpService } from '@akeela/properties';
import { AkeelaWorkflowService } from '@akeela/workflow';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { log } from 'console';
import { Subscription } from 'rxjs';
import { PropertiesService } from 'src/app/services';
import { WorkflowService } from 'src/app/services/workflow.service';
import { ErrorService } from '@akeela/common';


@Component({
  selector: 'client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.css']
})
export class ClientDashboardComponent implements OnInit,OnDestroy {

  filePreview;

  optVerification;
  itemList = {};

  animate_init = true;
  animate_init_bottom = false;
  buttomModal =true;
  clientDetail;
  init_meet =true;
  back = false;
  client;
  responseData1=1 ;
  responseData2='';

  formsData = {};

  dataAndSelfi;
  showBackDashboard ;
  verifiedIdentification;
  compleatVerifi;
  indicatorsLoading;
  indicatorsProgres :any;
  transitionsWfs;
  workflowStatus = null;
  workflowStatusMenu;
  workflowStatusSubscription: Subscription;
  dropDownTransitionsWfs= {};
  workflowCodeActive;
  transactionData;
  primer = true;
  dataInformation;
  queryParams;
  clientActive = 'CLIENT_DATA';
  redirectTransation;
  accountType;
  economicActivity;
  economicActivity2;
  specialCategory;
  civilStatus;
  housingCondition;
  incomeGeneratingActivity;
  paramsSubscription: Subscription;
  isNatural;
  countrys;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _error: ErrorService,
    public  config: NgbDropdownConfig,
    public _auth:AuthService,
    public _properties: PropertiesService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    private _wfService: WorkflowService,
    private workflowService: AkeelaWorkflowService,
    private _httpService: HttpService,
    private i18n: I18nPipe,
    private localStorage: LocalService,
    private akWorkflowService: AkeelaWorkflowService,


  ) { }

  
  ngOnInit(): void {
    this.isNatural = this.localStorage.getItem('clientaudience') == 'NATURAL';

    if( this.localStorage.getItem('redirectTransation')){
      this.redirectTransation =   this.localStorage.getItem('redirectTransation')
      this.clientActive =   this.localStorage.getItem('clientActive');
    }
  
    this.workflowCodeActive = this.clientActive;

    this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
      if(this.queryParams?.id){
        this.getUser(this.queryParams?.id);
      }
    });
  }

  
getDataInformations() {
  const params = {
    name:   this.isLegal(this.clientDetail)? 'briefcase$business_person$get' : 'briefcase$person$get',
    params: {
      request: {
        'id': this.client
      },
      path: {},
      body: {}
    }
  };

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
   this.dataInformation = response;
   if(this.isNatural){
    this.getItemListLR();
    this.getItemListPepR();
    this.getItemListPepRA();
    this.getItemListRefPer();
    this.getCivilStatus();
    this.getHousingCondition();
    this.getIncomeGeneratingActivity();
  }else{
    this.getItemListLR_legal();
    this.getItemListShareHolder();
    this.getItemListSupliers();
    this.getItemListCustomers();  
    this.getItemListComRef();  
    this.getItemListRelComp();  
    this.getItemListFiscalAdress();
    this.getSpecialCategory();
  }
  this.getEconomicActivity();
  this.getEconomicActivity2();
  this.getItemListOtherBank();
  this.getItemListBank();
  this.getAccountType();
  this.getCountrys();

  });


  

}


getItemListOtherBank() {
  

  const params = {
    name: 'briefcase$person_bank_accounts$get',
    params: {
      request: {
        otherInstitution: true,
        idPerson: this.localStorage.getItem('clientIdentifier'),

      },
      path: {
      },
      body: {}
    }
  };
  
     

     this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        this.itemList['BANK_ACCOUNT_WITH_OTHER_INST'] = response;
      }, error => {
  
      });

    }

    
    getEconomicActivity2() {
      const params = {
        name: 'workflow_manager$attributes_options$get',
        params: {
          request: {},
          path: {
            code: 'economicActivity2'
          },
          body: {}
        }
      };
      
      this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        let nameItem = '';
        if(response.length){
          this.economicActivity2 = [];
          for (const value of response) {
            nameItem = value.item.alternateName;
            this.economicActivity2[nameItem] = this.i18n.transform(value.name);
    
          }}});
      
    }


getIncomeGeneratingActivity() {
  const params = {
    name: 'workflow_manager$attributes_options$get',
    params: {
      request: {},
      path: {
        code: 'incomeGeneratingActivity'
      },
      body: {}
    }
  };
  
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    let nameItem = '';
    if(response.length){
      this.incomeGeneratingActivity = [];
      for (const value of response) {
        nameItem = value.item.alternateName;
        this.incomeGeneratingActivity[nameItem] = value.name;

      }}});
  
}

getHousingCondition() {
  const params = {
    name: 'workflow_manager$attributes_options$get',
    params: {
      request: {},
      path: {
        code: 'housingCondition'
      },
      body: {}
    }
  };
  
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    let nameItem = '';
    if(response.length){
      this.housingCondition = [];
      for (const value of response) {
        nameItem = value.item.alternateName;
        this.housingCondition[nameItem] = value.name;

      }}});
  
}

getCivilStatus() {
  const params = {
    name: 'workflow_manager$attributes_options$get',
    params: {
      request: {},
      path: {
        code: 'civilStatus'
      },
      body: {}
    }
  };
  
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    let nameItem = '';
    if(response.length){
      this.civilStatus = [];
      for (const value of response) {
        nameItem = value.item.alternateName;
        this.civilStatus[nameItem] = value.name;

      }}});
  
}



getSpecialCategory() {
  const params = {
    name: 'workflow_manager$attributes_options$get',
    params: {
      request: {},
      path: {
        code: 'specialCategoy'
      },
      body: {}
    }
  };
  

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
  
  
    let nameItem = '';
    if(response.length){
      this.specialCategory = [];
      for (const value of response) {
        nameItem = value.item.alternateName;
        this.specialCategory[nameItem] = this.i18n.transform(value.name);

      }
    }});

      console.log(this.specialCategory);
  
}

getCountrys = () => {
  const params = {
    name: 'app_properties$countrys$get',
    params: {
      request: {},
      path: {},
      body: {}
    }
  };
  return this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any)=>{
    let nameItem = '';
    if(response.length){
      this.countrys = [];
      for (const value of response) {
        nameItem = value.alternateName;
        this.countrys[nameItem] = value.name;
      }}
  });
}

getEconomicActivity() {
  const params = {
    name: 'product_plan$economic_activities$get',
    params: {
      request: {},
      path: {},
      body: {}
    }
  };
  

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
  
  
    let nameItem = '';
    if(response.length){
      this.economicActivity = [];
      for (const value of response) {
        nameItem = value.identifier;
        this.economicActivity[nameItem] = value.name;

      }}});
  
}

getAccountType() {
  const params = {
    name: 'workflow_manager$attributes_options$get',
    params: {
      request: {},
      path: {
        code: 'accountType'
      },
      body: {}
    }
  };
  
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    let nameItem = '';
    if(response.length){
      this.accountType = [];
      for (const value of response) {
        nameItem = value.item.alternateName;
        this.accountType[nameItem] = value.name;

      }}});
  
}



redirectContinue(urlWf = this.clientActive ) {

  if(!this.dataInformation?.completeBriefcase){

 let url = [urlWf];
 const prefixed = 'private';

  const queryParams = {
    role: 'CLIENT',
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('clientaudience'),
  };


  this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });

}
}



get_dataAndSelfi(){

    const params = {
      name: 'person$selfie$get',
      params: {
        request: {
          'username':this.clientDetail?.identifier
        },
        path: {},
        body: {}
      }
    };

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

    this.dataAndSelfi = response;
    this.filePreview = 'data:image/jpg' + ';base64,' + response?.description;

    
  });

}


redirecClient(section = false){


  const queryParams2 = {

     role: 'CLIENT',
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('clientaudience'),
    section:section?section:'PERSONAL_INFORMATION'

  };

  let url = ['private/' + this.clientActive+ '/stages/CLIENT_IDENTIFICATION/steps/PERSONAL_INFORMATION'];

  this.router.navigateByUrl(
    this.router.createUrlTree(url,
      {
        queryParams: queryParams2
      }
    )
  ).then(() => {
  });

}



getOptionsMenu(wf) {
  const params = {
    name: 'workflows$stagesMenu$get',
    params: {
      request: {
         role: 'CLIENT',
        audience: this.localStorage.getItem('clientaudience'),
        profile: this.localStorage.getItem('profile'),
        idPerson:  this.localStorage.getItem('clientIdentifier')


      },
      path: {
           workflowCode: wf


      },
      body: {}
    }
  };

  let sectionBack = null;

 this._httpService.executeRequest(params.name, params.params, true).subscribe((result: any) => {
   let endSection    = null;

      if (result) {
        
        let transitionsWfs = [];

         for (const dataStage of result) {
          if (dataStage) {
              if (dataStage.howToSteps) {
                  for (const dataStep of dataStage.howToSteps) {

                    if(this.primer &&  dataStep?.sections?.length > 0){
                      this.dropDownTransitionsWfs[dataStep?.alternateName] = true;
                      this.primer = false;
                    }else{
                      this.dropDownTransitionsWfs[dataStep?.alternateName] = true;
                    }

                      if (dataStep.sections) {

                        for (const dataSection of dataStep.sections) {

                          endSection = dataSection.alternateName;


                        transitionsWfs[dataSection.alternateName]={};
                        transitionsWfs[dataSection.alternateName]['active'] = {section:dataSection.alternateName,step:dataStep.alternateName,stage:dataStage.alternateName,wf: wf} ;
                        

                        if(sectionBack){
                          
                          transitionsWfs[sectionBack?.section]['new'] = {section:dataSection.alternateName,step:dataStep.alternateName,stage:dataStage.alternateName,wf: wf } ;
                          transitionsWfs[dataSection.alternateName]['back'] = sectionBack;
                          sectionBack =  {section:dataSection.alternateName,step:dataStep.alternateName,stage:dataStage.alternateName,wf: wf};

                        }else{
                          sectionBack =  {section:dataSection.alternateName,step:dataStep.alternateName,stage:dataStage.alternateName,wf: wf};
                        }
                      }

                      }

                  }
              }
          }

      }

      transitionsWfs[endSection]['end'] = '/dashboard';
    

      this.transitionsWfs =  transitionsWfs;
      this.workflowStatusMenu =  result;
      }else{
        this.transitionsWfs = null;
         this.workflowStatusMenu = null;

      }


  });
 
}



redirectSection(urlWf, stage,step,section ) {

  this.akWorkflowService.setCurrentWorkflowCodeActive(this.clientActive);



  this.dropDownTransitionsWfs[step] = true;
  const prefixed = 'private';
 let url = [urlWf + '/stages/' + stage + '/steps/' + step];

  const queryParams = {
     role: 'CLIENT',
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('clientaudience'),
    section: section,

  };


  this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });
  
}



resetStatus() {    

let params;

   params = {

    name: 'briefcase$stages_status$get',
    params: {
      request: {
        role: 'CLIENT',
        audience: this.localStorage.getItem('clientaudience'),
        id_person:  this.client,
        wfp_code: this.workflowCodeActive

      },
      path: {
     
      },
      body: {}
    }
  };



  if(this.workflowStatusSubscription){
    this.workflowStatusSubscription.unsubscribe();
  }



  this.workflowStatusSubscription =  this._httpService.executeRequest(params.name, params.params, true).subscribe((result: any) => {
    let sectionBack = null;
    let sectionNext = null;
      
      if (result) {

       //   this._akeelaSidebarMenu.setshowSidebarLeft(true);
          let WorkflowStatus = [];
          let stepsStates;
          let sectionsStates;

          for (const dataStage of result) {
              if (dataStage) {

                  stepsStates = [];
                  if (dataStage.howToSteps) {
                      for (const dataStep of dataStage.howToSteps) {
                          sectionsStates = [];
                          console.log(dataStep);
                          if (dataStep.sections) {
                            // console.log(dataStep.sections);
                              for (const dataSection of dataStep.sections) {                                 
                                  sectionsStates[dataSection.alternateName] = {
                                      code: dataSection.alternateName,
                                      state: dataSection.status,
                                  };
                              }
                          }

                          stepsStates[dataStep.alternateName] = {
                              code: dataStep.alternateName,
                              state: dataStep.status,
                              sections: sectionsStates
                          };

                      }
                  }
              }


              WorkflowStatus[dataStage.alternateName] = {
                  code: dataStage.alternateName,
                  state: dataStage.status,
                  steps: stepsStates
              };
              console.log(WorkflowStatus);

           

          }

          this.workflowStatus = WorkflowStatus;
              

      }

  });
 
}


getIfSection(section,data){


  if(this.transitionsWfs && this.transitionsWfs[section]){

    let resp =   this.transitionsWfs[section] ?   this.transitionsWfs[section][data] != null  : false;

    return true;
  }else{

    this.transitionsWfs;
    return false;
  }
}




getIndicatorsProgres() {
  let data :any;  




    data = {
      "workflow":this.workflowCodeActive,
      "personId": this.client
  }
 
    
 
  const params = {

    name: 'resume$workflow_progress$post',
    params: {
      request: {
        role: 'CLIENT',
        audience: this.localStorage.getItem('clientaudience'),

      },
      path: {},
      body: data

    }
  };

  this.indicatorsLoading = true;
  let indicatorsProgres = {};
  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    if (response) {

      let progress;
      let preData =response[0]?.modules;

       progress = response[0]?.modules[0]?.progress;
    

       let  maxValue = progress?.maxValue;
       let  minValue = progress?.minValue;

       indicatorsProgres['maxValue'] = maxValue;
       indicatorsProgres['minValue'] = minValue;
       indicatorsProgres['percent'] = maxValue > 0 ?   this.round(minValue*100/maxValue):0;
        
       this.indicatorsProgres = indicatorsProgres;
        this.indicatorsLoading = false;
      }


  }, error => {

    this.indicatorsLoading = false;

  });

}

round(value) {

  if(value){
    return Math.round(value);

  }
  return 0;

}



sendCompleat() {
  let data :any;
 
  const params = {

    name: 'briefcase$verified$post',
    params: {
      request: {
        'username': this.clientDetail?.identifier ,
        'requestUser': this._auth.getUsername(),
        'comments':this.responseData2,
         'verified':true
      },
      path: {},
      body: {}
    }
  };


  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      if (response) {
        this.optVerification = false;

        this.getDataInformations();

      }
  }, error => {


  });

}


backCompleat() {
  let data :any;

  
  const params = {

    name: 'briefcase$reset_complete-data$post',
    params: {
      request: {
        'username': this.clientDetail?.identifier ,
        'requestUser': this._auth.getUsername(),
        'comments':this.responseData2,
        'verified':true
      },
      path: {},
      body: {}
    }
  };


  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      if (response) {


        this.optVerification = false;
        this.getDataInformations();

      }
  }, error => {


  });

}

sendContinueComplete(){

    let data :any;
   
    const params = {
  
      name: 'briefcase$complete-data$post',
      params: {
        request: {
          'username': this.clientDetail?.identifier ,
          'requestUser': this._auth.getUsername()
        },
        path: {},
        body: {}
      }
    };
  
  
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
  
        if (response) {
          this.optVerification = false;
  
          this.getDataInformations();
  
        }
    }, error => {
      this._error.show(error);
    });
  

}

sendContinue(){

  if(this.responseData1 == 1){
    
    this.sendCompleat();

  }else{
    this.backCompleat();

  }
}



setBack() {
 this.optVerification = false;

}





getItemListLR() {

  const params = {
    name: 'briefcase$get-relateds$get',
    params: {
      request: {
        personId: this.client,
        relatedType:'LEGAL_REPRESENTATIVE'
      },
      path: {
      },
      body: {}
    }
  };

     this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        this.itemList['LEGAL_REPRESENTATIVE_PN'] = response;
      }, error => {
  
      });

    }


    getItemListPepR() {



      const params = {
        name: 'briefcase$get-relateds$get',
        params: {
          request: {
            personId: this.client,
            relatedType:'PEP',
            pepType:'RELATIONSHIP'
          },
          path: {
          },
          body: {}
        }
      };
         
    
         this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
            this.itemList['RELATED_PEP_PN'] = response;
          }, error => {
      
          });
    
        }
    

    getItemListPepRA() {

      const params = {
        name: 'briefcase$get-relateds$get',
        params: {
          request: {
            personId: this.client,
            relatedType:'PEP',
            pepType:'ASSOCIATION'
          },
          path: {
          },
          body: {}
        }
      };
         
    
         this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
            this.itemList['RELATED_PEP_ASSOCIATION_PN'] = response;
          }, error => {
      
          });
    
        }
    



    getItemListRefPer() {


      const params = {
        name: 'account_contacts$contacts$get',
        params: {
          request: {
            idPerson: this.client,
            contactType:'PERSONAL_REFERENCE'
          },
          path: {
          },
          body: {}
        }
      };
         
    
         this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
            this.itemList['PERSONAL_REFERENCES'] = response;
          }, error => {
      
          });
    
        }
  


        ngOnDestroy() {
         
          if (this.paramsSubscription) {
            this.paramsSubscription.unsubscribe();
          }
        }

        getInitials(givenName: string, familyName: string) {
          return (
            (givenName ? givenName.substr(0, 1).toUpperCase() : '') +
            (familyName ? familyName.substr(0, 1).toUpperCase() : '')
          );
        }
        



  isLegal = (clientDetail = this.clientDetail)=>{

    return   clientDetail?.personMarketType?.audienceType == 'LEGAL';

   } 
        
  getUser(username) {
    const params = {
      name: 'person$enduser$get',
      params: {
        request: {
          'username': username
        },
        path: {},
        body: {}
      }
    };


    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.clientDetail = response;
    
      this.localStorage.setItem('clientName',this.clientDetail?.person?.givenName+' '+this.clientDetail?.person?.familyName);
      this.localStorage.setItem('clientUsername', this.clientDetail.identifier);
      this.localStorage.setItem('clientIdentifier',this.isLegal(this.clientDetail)? this.clientDetail?.adminOrganization?.identifier:this.clientDetail?.person?.identifier);
      this.localStorage.setItem('clientaudience', this.clientDetail.personMarketType.audienceType);
      if(this.isLegal(this.clientDetail)){
        this.localStorage.setItem('clientlegalName',  this.clientDetail?.adminOrganization?.legalName );

      }else{
        this.localStorage.removeItem('clientlegalName');
      }
      this.client =   this.localStorage.getItem('clientIdentifier');

      this.getDataInformations();

      this.getIndicatorsProgres();
  
      this.getOptionsMenu(this.workflowCodeActive);
  
      this.resetStatus();
      this.workflowService.transactionData$.next(null);
      this.workflowService.setTransactionNumber(null);


    });

  }

  redirectDashboard(){

  
  
        const queryParams = {
          role: 'CLIENT',
          profile: this.localStorage.getItem('profile'),
          audience: this.localStorage.getItem('clientaudience'),
          'transactionNumber' : this.redirectTransation
          
        };
      
      
        this.router.navigate(['console/transaction-dashboard'], { queryParams: queryParams });
      
  
  
  
  }


  getItemListBank() {

    const params = {
      name: 'briefcase$person_bank_accounts$get',
      params: {
        request: {
          otherInstitution: false,
          idPerson: this.localStorage.getItem('clientIdentifier'),

        },
        path: {
        },
        body: {}
      }
    };
    
       
  
       this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
          this.itemList['BANK_ACCOUNT_WITH_INST'] = response;
          console.log(response);
        }, error => {
          console.log(error);
        });
  
      }


getItemListLR_legal= async () => {

const params = {
name: 'briefcase$get-relateds$get',
params: {
request: {
  personId: this.localStorage.getItem('clientIdentifier'),
  relatedType:'LEGAL_REPRESENTATIVE'
},
path: {
},
body: {}
}
};

try {

let response = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();

this.itemList['LEGAL_REPRESENTATIVE'] = response;

} catch (error) {

this.itemList['LEGAL_REPRESENTATIVE'] = null;

}

}



getItemListShareHolder= async () => {
const params = {
name: 'briefcase$get-relateds$get',
params: {
request: {
  personId: this.localStorage.getItem('clientIdentifier'),
  relatedType:'SHAREHOLDER'
},
path: {
},
body: {}
}
};

try {

let response = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();
console.log(response);
this.itemList['SHAREHOLDERS'] = response;

} catch (error) {

this.itemList['SHAREHOLDERS'] = null;

}

}



getItemListCustomers= async () => {

const params = {
name: 'account_contacts$contacts$get',
params: {
request: {
  idPerson: this.localStorage.getItem('clientIdentifier'),
  contactType:'CUSTOMER'
},
path: {
},
body: {}
}
};
try {
let response = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();

this.itemList['CUSTOMERS'] = response;

} catch (error) {

this.itemList['CUSTOMERS'] = null;

}

}




getItemListSupliers= async () => {

const params = {
name: 'account_contacts$contacts$get',
params: {
request: {
  idPerson: this.localStorage.getItem('clientIdentifier'),
  contactType:'SUPPLIER'
},
path: {
},
body: {}
}
};
try {


let response = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();

this.itemList['SUPPLIERS'] = response;

} catch (error) {

this.itemList['SUPPLIERS'] = null;

}

}



getItemListComRef = async () => {

const params = {
name: 'account_contacts$contacts$get',
params: {
request: {
  idPerson: this.localStorage.getItem('clientIdentifier'),
  contactType:'RELATED_COMPANY'
},
path: {
},
body: {}
}
};
try {

let response = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();
this.itemList['RELATED_COMPANIES'] = response;

} catch (error) {

this.itemList['RELATED_COMPANIES'] = null;

}

}



getItemListRelComp= async () => {

const params = {
name: 'account_contacts$contacts$get',
params: {
request: {
  idPerson: this.localStorage.getItem('clientIdentifier'),
  contactType:'COMMERCIAL_REFERENCE'
},
path: {
},
body: {}
}
};
try {

let response = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();

this.itemList['COMMERCIAL_REFERENCES'] = response;

} catch (error) {

this.itemList['COMMERCIAL_REFERENCES'] = null;

}

}




getItemListFiscalAdress= async () => {

const params = {
  name: 'briefcase_pe$fiscal_addresses$get',
  params: {
    request: {
      idPerson: this.localStorage.getItem('clientIdentifier')
    },
    path: {
    },
    body: {}
  }
};
  try {

    let response = await  this._httpService.executeRequest(params.name, params.params, true).toPromise();

    this.itemList['FISCAL_ADDRESS'] = response;

  } catch (error) {

    this.itemList['FISCAL_ADDRESS'] = null;
    
  }

  }



}

