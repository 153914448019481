import { Component, OnInit, DoCheck, AfterViewInit, OnDestroy, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { SubheaderService } from './services/subheader.service';
import { PropertiesService } from './services/properties/properties.service';
import { AuthService } from '@akeela/auth';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { PathService, HttpService } from '@akeela/properties';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService } from '@akeela/workflow';
import { isNullOrUndefined } from 'util';
import { UploadService } from '@akeela/common';
import { Favicons } from './services/favicons.service';
import { environment } from '../environments/environment';
import { TemplateService } from './services/template.service';
import { TabsService } from './services/tabs.service';
import { AkeelaTemplateService } from '@akeela/requirements';
import { MiddlewareService } from './services/middleware.service';
import { CommonService, ErrorService } from '@akeela/common';
import { LocalService } from '@akeela/local-storage';
import { I18nPipe } from '@akeela/i18n';
import { Observable, Subscription } from 'rxjs';
import { EmailService } from './services/email.service';
import { WorkflowService } from './services/workflow.service';
import { ConnectionService } from 'ng-connection-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, DoCheck, OnDestroy,  AfterViewInit, AfterContentChecked {
  title = 'app';
  subheaderShow = false;
  showSidebarLeft: boolean;
  role: any;
  profile: any;
  audience: any;
  dinamicCssUrl;
  templates: any = {};
  templateRequirements: any = {};
  isAuth = false;
  public env = environment;

  timeinactivity = null;
  reloadkeepalive = false;
  active_popup = false;

  transactionData;
  transactionNumber;
  subscriptions: Subscription[] = [];
  loadingAutoSave;
  loadingAutoSaveOut;
  encryptSecretKey = "ejemplo de encriptado"
  w8;
  wo;
  superUser;
  workflowCodeActive;
  queryParams;
  transactionTakenYou;
  isAnalist;
  status;
  isConnected;

  config;

  activeSuggestion = false;
  detailSuggestion;
  
  constructor(
    private _httpService: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private _subHeaderService: SubheaderService,
    private _properties: PropertiesService,
    private _auth: AuthService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    private _pathService: PathService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    public upload: UploadService,
    private favicons: Favicons,
    private titleService: Title,
    private sanitizer: DomSanitizer,
    private templateService: TemplateService,
    private _tabService: TabsService,
    private _templateRequirements: AkeelaTemplateService,
    private _middleware: MiddlewareService,
    private _common: CommonService,
    private localStorage: LocalService,
    private error: ErrorService,
    private i18n: I18nPipe,
    private _emailService: EmailService,
    private cdr: ChangeDetectorRef,
    private _wfService: WorkflowService,
    private httpClient: HttpClient,
    private connectionService: ConnectionService

    ) {

      // control de cache por versión
      this.config = require('./../assets/config.json'); 
      console.log("************************************************");
      console.log("System Version: "+this.config.version);      
      console.log("************************************************");

      const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache'); 
      
      this.httpClient
      .get<{ version: string }>("/assets/config.json", {headers})
      .subscribe(config => {
        console.log(config);
        console.log(this.config);
       if (config.version !== this.config.version) {
          console.log("*************************************************");
          console.log("*New Version: "+config.version+". Will be reload*");      
          console.log("*************************************************");
          location.reload(); 
        }
      });

        if(navigator.onLine) {
            this.isConnected = true;
            this.status = "ONLINE";

        } else {
            this.isConnected = false;
            this.status = "OFFLINE";

        }

        this.connectionService.monitor().subscribe(isConnected => {

            if (isConnected) {

                this.status = "ONLINE";

                setTimeout(() => {
                    this.isConnected = isConnected;
                }, 7000);


            }
            else {
                this.status = "OFFLINE";
                this.isConnected = isConnected;
            }


        });


        this.akWorkflowService.showSuggestion$.subscribe((response) => {
          if (response) {
              this.activeSuggestion = true;
              this.detailSuggestion = response;
          }
        });


    
    this.akWorkflowService.setFieldsNotRequired(environment.appEndPontDefaultConfig.isFieldNotRequired || false);


    this._pathService.getPathService(false, environment.appEndPontDefaultConfig.transactionCode).subscribe(pathResponse => {
      this.akWorkflowService.setendpointTransactioData(pathResponse);
    });


    this._pathService.getPathService(false, environment.appEndPontDefaultConfig.transitions).subscribe(pathResponse => {
      this.akStepService.setendpointTransitions(pathResponse);
    });


    this._pathService.getPathService(false, environment.appEndPontDefaultConfig.workflowState).subscribe(pathResponse => {
      this.akWorkflowService.setEndpointWorkflowState(pathResponse);
    });


    /*
          this._pathService.getPathService(false, environment.appEndPontDefaultConfig.stageStatus).subscribe(pathResponse => {
            this.akStageService.setendpointStageState(pathResponse);
        });
        
        this._pathService.getPathService(false, environment.appEndPontDefaultConfig.stepState).subscribe(pathResponse => {
           this.akStepService.setendpointStepState(pathResponse);
        });
        
        this._pathService.getPathService(false, environment.appEndPontDefaultConfig.sectionsState).subscribe(pathResponse => {
           this.akStepService.setendpointSectionsState(pathResponse);
        });
    */
  }



  closeSuggestion = (e) => {

    this.activeSuggestion = false;

}


  ngOnInit() {



    this.route.queryParams.subscribe(queryParams => {

  
      this.queryParams = queryParams;


    });

    // this.sendMailTest();

    if (this.localStorage.getItem('workspace')) {

      this._common.setWorkspaceCode(this.localStorage.getItem('workspace'));
      this._common.workspaceCode$.next(this.localStorage.getItem('workspace'));
  
    }

    this._akeelaSidebarMenu.showSidebarLeft.subscribe((data: boolean) => {
      this.showSidebarLeft = data;

    });


    this._akeelaSidebarMenu.sidebarLeftActive$.subscribe(rep => {

      if (screen.width < 768) {
          this._akeelaSidebarMenu.setshowSidebarLeft(false);
      }
  });


    this.localStorage.setItem('modeDebug', 'true');
    this.localStorage.removeItem('modeDebug');
    this.localStorage.removeItem('transactionModeEditShow');
    this.localStorage.removeItem('transactionModeEditShowFlag');


   // this.localStorage.setItem('notWorkflowfluid',true);

   this.akStepService.currentStep$.subscribe(() => {
    if (this._auth.isAuthenticated()) {
     // this.tabs();
    }
  });

  
    this.akWorkflowService.saveSectios$.subscribe((response: any) => {

      this.loadingAutoSave = response;
      this.loadingAutoSaveOut = false;

      if (response === false) {

        this.loadingAutoSaveOut = true;

        setTimeout(() => {

          this.loadingAutoSaveOut = false;

        }, 1000);
      }



    });



    this.localStorage.setItem('typeShareholder', 'SHAREHOLDER');


    let sidebarLeftActive;
    sidebarLeftActive = this.localStorage.getItem('sidebarLeftActive');

    if (!isNullOrUndefined(sidebarLeftActive) && sidebarLeftActive != "null") {
        this._akeelaSidebarMenu.setSidebarLeftActive(sidebarLeftActive);
    } else {

        this._akeelaSidebarMenu.setSidebarLeftActive(environment.appEndPontDefaultConfig.sidebarLeftActive);

    }

    let sidebarTopActive;
    sidebarTopActive = this.localStorage.getItem('sidebarTopActive');


    if (!isNullOrUndefined(sidebarTopActive) && sidebarTopActive != "null") {
      this._akeelaSidebarMenu.setSidebarTopActive(sidebarTopActive);
    }

   

    this.role = this.localStorage.getItem('role');

    this.route.queryParams.subscribe(queryParams => {
      if (isNullOrUndefined(this.role)) {
        this.role = queryParams['role'];
      }


      if (!isNullOrUndefined(queryParams['wo'])) {
      //  this.wo = queryParams['wo'];
       // this.localStorage.setItem('readonly', 'true');
        this._akeelaSidebarMenu.setshowSidebarLeft(false);

      }

    });


    // Juan soto
    // metodo que se utiliza para actualizar estatus cuando la transaccion pase de incompleta a en proceso 
    this.UpdateTransactionStatus('AC_INCOMPLETE');


    this._auth.isAuth$.subscribe((isAuth: boolean) => {
      if (!isAuth) {
        this.akWorkflowService.transactionData$.next(null);
        this.akStepService.setModeCurrentStep(false);
      }
      if (isAuth !== this.isAuth) {
        if (!isAuth) {
          if (this.isAuth) {
            this.isAuth = isAuth;
            location.reload();
          } else {
            this.isAuth = isAuth;
          }
        } else {
          this.isAuth = isAuth;
        }
      }


      this.timeinactivity = null;
      this.reloadkeepalive = false;
      if (isAuth) {

      
        if (this.localStorage.getItem('profile') === 'ANALIST') {

          this.isAnalist = true;


          this.setTrakeUser();
      
        } else {
      
          this.isAnalist = false;
          
        }



        if (environment.auth.inactivity.active) {
          this._properties.getProperty({
            endpointKey: 'app_properties$getProperty$get',
            propertyKey: 'app.inactivity.time'
          }).subscribe((response: any) => {
            this.timeinactivity = response;
            this.reloadkeepalive = true;
          }, error => {
            this.timeinactivity = 3;
            this.reloadkeepalive = true;
          });
        } else {
          this.reloadkeepalive = true;
        }
        
        let sidebarSubLeftActive;
        sidebarSubLeftActive = this.localStorage.getItem('sidebarSubLeftActive');
    
        if (!isNullOrUndefined(sidebarSubLeftActive) && sidebarSubLeftActive != "null") {
        
          this._akeelaSidebarMenu.setSidebarSubLeftActive(sidebarSubLeftActive);
    
        }else{

          if (this.localStorage.getItem('profile') === 'SALES_MANAGER') {

            this._akeelaSidebarMenu.setSidebarSubLeftActive("Q_RECENT");

          }else{

            this._akeelaSidebarMenu.setSidebarSubLeftActive("Q_PEND_ALL");

          }
    
    
        }
      }
    });

    if (!isNullOrUndefined(this.localStorage.getItem('transactionNumber'))) {
      this.transactionNumber = this.localStorage.getItem('transactionNumber');
      this.getAccountproductinformation();
    }



    this.akWorkflowService.transactionData$.subscribe((response: any) => {
      if (!isNullOrUndefined(response)) {
        
        /*
        if (!isNullOrUndefined(this.wo)) {
          this.akStepService.setModeCurrentStep(true);
          this.localStorage.setItem('readonly', true)
        }
         */
        this.modeForm(response);

      }

    });

    this.titleService.setTitle(environment.theme.site.title);
    this.favicons.reset();
    this.favicons.activate(environment.theme.images);
    this.getDinamicCssUrl();

    if (screen.width < 768 || this.wo) {
      this._akeelaSidebarMenu.setshowSidebarLeft(false);
    } else {
      this._akeelaSidebarMenu.setshowSidebarLeft(true);
    }

    this._akeelaSidebarMenu.showSidebarLeft$.subscribe((data: boolean) => {


      if (this.wo) {

        this.showSidebarLeft = false;

      } else {
        this.showSidebarLeft = data;

      }
    });

    this._auth.isAuth.subscribe((response: boolean) => {
      if (!response) {
        this._akeelaSidebarMenu.setshowSidebarLeft(false);
      }
      this.role = this.localStorage.getItem('role');
      this.profile = 'USER';
      this.audience = this._auth.getUserAudience();
    });

    this.getViewAll(this._auth.getUsername());

          

    this._subHeaderService.subHeader$.subscribe((show) => {
      this.subheaderShow = show;
    });
    this.reloadkeepalive = true;

    this.akWorkflowService.currentWorkflow$.subscribe((response) => {

      let respActual = this.akWorkflowService.currentWorkflowActive$.getValue();
       if (response && response !=respActual ) {

           this.workflowCodeActive = response;

           this.getDataWorkflows();
       }
   });


    this.akWorkflowService.currentWorkflowActive$.subscribe(response => {

      this.workflowCodeActive = response;

  });

  this.getAllDataWorkflows();



  
  }

  setTrakeUser = async () =>{

    let isTakenResponse: any = await this.isTaken(this._auth.getUsername()).toPromise();
      
    if (isTakenResponse !== null && isTakenResponse !== '' && isTakenResponse !== undefined) {
     this._wfService.setTransactionTakenYou({taken:true,transaction:isTakenResponse});
   }else{
     this._wfService.setTransactionTakenYou(null);
   }

  }



  isTaken(username) {
    if (this.isAnalist) {
      const params = {
        name: 'account_opening$taken$get',
        params: {
          responseType: 'text',
          request: {},
          path: {
            user: username
          },
          body: {}
        }
      };
      const subscriptor = this._httpService.executeRequest(params.name, params.params, true);
      return subscriptor;
    } else {
      return new Observable(resolve => {
        resolve.next(false);
      });
    }
  }

  

  getAllDataWorkflows() {
    const params = {
      name: 'workflow$workflows$get',
      params: {
        request: {
          content:'none'
        },
        path: {
        },
        body: {}
      }
    };
  
    let sectionBack = null;
  
   this._httpService.executeRequest(params.name, params.params, true).subscribe((result: any) => {
        if (result) {

            let results = {}
 
            for(let item of result ){

                results[item.alternateName]  = item;

              }
              this._wfService.setListWF(results);
        }
  
    });
  }

getDataWorkflows() {
  const params = {
    name: 'workflow$workflow$get',
    params: {
      request: {
        content:'none'
      },
      path: {
        "workflow":this.workflowCodeActive,
      },
      body: {}
    }
  };

  let sectionBack = null;

 this._httpService.executeRequest(params.name, params.params, true).subscribe((result: any) => {

  debugger
      if (result) {
          this.akWorkflowService.setCurrentWorkflowCodeActive(this.workflowCodeActive);
        
          if(result?.endpointStatus &&   !this.queryParams?.section){

              this.localStorage.setItem('sectionRedirect',true);
          }else{
              this.localStorage.removeItem('sectionRedirect');

          }

      }


  });


}

  getViewAll(client) {
    const params = {
      name: 'person$enduser$get',
      params: {
        request: {
          'username': client
        },
        path: {},
        body: {}
      }
    };
    
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

      if(response?.viewAll){
        this.superUser = true;

       this.localStorage.setItem('superExecutive',true);
      
      }else{

        this.localStorage.setItem('superExecutive',false);

      }
      this.setMenu();
   });
}


setMenu(){
  let opt = {
    name: this.superUser ?'LEFT_MENU_USER_WKS':'LEFT_MENU_USER',
    username: this._auth.getUsername(),
    typeUser: false

  };

  this._akeelaSidebarMenu.setsidebarLeftOptions(opt);
            if (this.localStorage.getItem('showSidebarLeft') === null) {
                if (screen.width > 768) {

                    this._akeelaSidebarMenu.setshowSidebarLeft(true);
                }
            } else {

                if (this.localStorage.getItem('showSidebarLeft') === 'true') {
                    this._akeelaSidebarMenu.setshowSidebarLeft(true);


                } else {
                    this._akeelaSidebarMenu.setshowSidebarLeft(false);
                }

            }
        

    this._pathService.getPathService(false, environment.appEndPontDefaultConfig.menu).subscribe(pathResponse => {
      this._akeelaSidebarMenu.setsidebarLeftOptions(opt);
      this._akeelaSidebarMenu.setsidebarLeftKey(pathResponse);
    });
}

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
}

  sendMailTest() {
    const email = 'mjdamariscua@gmail.com';
    const token = '444444444';
    const name = '';
    const username = 'mjdamaduff';
    const origin = 'localhost';
    const url = 'nula.com';
    const portal = 'akeela';
    const properties: object = {};
    const site_email = 'akeela@info.com';
    properties['template'] = 'templates/es/recoverypassword.html';

    let site_header = environment.theme.site.name;
    if (!isNullOrUndefined(environment.theme.image)) {
      if (!isNullOrUndefined(environment.theme.image.active)) {
        if (environment.theme.image.active) {
          site_header = '<img src="https://apertura.mibanco.com.ve/arn/assets/mibanco/public/images/image.png" ></img>';
        }
      }
    }

    properties['attr'] = [
      { 'key': '%%URL%%', 'value': url },
      { 'key': '%%URLDESCRIPTION%%', 'value': this.i18n.transform('@i18n-mail-verify-account') },
      { 'key': '%%NOMBRE%%', 'value': name },
      { 'key': '%%USERNAME%%', 'value': username },
      { 'key': '%%SITENAME%%', 'value': portal },
      { 'key': '%%SITEEMAIL%%', 'value': environment.theme.site.supportMail },
      {
        'key': '%%FONT_COLOR%%',
        'value': environment.theme.fontColor
      },
      {
        'key': '%%BACKGROUND_COLOR%%',
        'value': environment.theme.backgroundColor
      },
      {
        'key': '%%PROCEDURE%%',
        'value': environment.procedureMail
      },
      {
        'key': '%%BACKGROUND_BORDER%%',
        'value': environment.theme.backgroundColorBorderMail
      },
      {
        'key': '%%ALIAS_APP%%',
        'value': site_header
      },
      {
        'key': '%%TEXTFOOTER%%',
        'value': !isNullOrUndefined(environment.theme.textFooterMail) ? environment.theme.textFooterMail : ''
      }
    ];
    const email_message = {
      identifier: 1,
      dateOfThing: {},
      status: null,
      sender: null,
      text: null,
      type: 'text/html',
      subjectOf: portal,
      toRecipient: email
    };
    const template = this._emailService.getTemplate(properties);
    template.subscribe(template_mail => {
      const dataMail = this._emailService.configureTemplate(template_mail, properties['attr']);
      const template_data = { properties, template_mail };
      template_mail = dataMail;
      const response = this._emailService.sendMail(
        email_message,
        template_data.properties,
        template_data.template_mail
      );
      response.subscribe(mail_result => {
        console.log('correo enviado');
      }, error => {
        console.log('Correo TEST no enviado');
        console.log(error);
      });
    }, error => {
      console.log('Correo TEST no enviado');
      console.log(error);
    });
  }




  getAccountproductinformation() {
    this.transactionData = null;
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role,
          profile: this.localStorage.getItem('profile')
        },
        path: {
          transactionNumber: this.transactionNumber
        },
        body: {}
      }
    };
    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.transactionData = response;
      this.akWorkflowService.transactionData$.next(response);
    }));
  }

  modeForm(response) {

    const readonly = this.localStorage.getItem('readonly');
    if (this.localStorage.getItem('workspaceReadonly') === 'true' || readonly) {
      this.akStepService.setModeCurrentStep(true);
    } else {
      if (this.akStageService.currentStage$.getValue() !== 'CREATE_USER_ACCOUNT_OPENING') {
        this.transactionData = response;
        const temp = response.accountStatus[0].additionalType;
        if (this.localStorage.getItem('profile') === 'SALES_MANAGER') {
          // SEPARAMOS EN DOS CASOS:
          // CASO EJECUTIVO:

          if (!isNullOrUndefined(response)) {
            // 

            
           // let a =this.localStorage.getItem('transactionModeEditShowFlag');
            // let b =this.localStorage.getItem('transactionModeEditShow');

            if (temp === 'AC_PENDING' || temp === 'AC_RECEIVED' || temp === 'AC_REJECTED') {
              this.akStepService.setModeCurrentStep(true);
            } else {
              if (!isNullOrUndefined(response.takenByRol) && this.role !== '' && response.takenByRol !== 'USER' && !isNullOrUndefined(this.role)) {
                
                if (response.takenByRol.trim() !== this.role.trim() && response.takenByRol !== '' && false) {
                  this.akStepService.setModeCurrentStep(true);
       
                  if (this.localStorage.getItem('transactionModeEditShow') !== response.transactionNumber && this.localStorage.getItem('transactionModeEditShowFlag') !== response.transactionNumber) {
                  // if (!this.localStorage.getItem('transactionModeEditShow')) {
                    const error = {
                      error: {
                        status: 500,
                        code: 'AKC-014',
                        titleLabel: '',
                        name: ''
                      }
                    };
                    this.error.show(error, 'Mensaje!', this.i18n.transform(response.takenByRol));
                    this.localStorage.setItem('transactionModeEditShow', response.transactionNumber);
                    this.localStorage.setItem('transactionModeEditShowFlag', response.transactionNumber);
                  }
                } else {
                  this.akStepService.setModeCurrentStep(false);
                  if (!isNullOrUndefined(this.localStorage.getItem('transactionNumber'))) {
                    this.localStorage.setItem('transactionModeEdit', response.transactionNumber);
                  }
                }
              } else {
                this.akStepService.setModeCurrentStep(false);
              }
            }
          } else {
            this.akStepService.setModeCurrentStep(false);
          }
        } else if (this.localStorage.getItem('profile') === 'ANALIST') {
          // SEPARAMOS EN DOS CASOS:
          // CASO ANALISTA:
          if (!isNullOrUndefined(response)) {
            if (temp === 'AC_PENDING') {
              if (temp === 'AC_PENDING' && this.localStorage.getItem('takenedit') === response.transactionNumber) {
                this.akStepService.setModeCurrentStep(false);
              } else {
                this.akStepService.setModeCurrentStep(false);
              }
            } else {
              this.akStepService.setModeCurrentStep(true);
            }
          } else {
            this.akStepService.setModeCurrentStep(false);
          }

        } else {
          this.akStepService.setModeCurrentStep(true);
        }
      } else {
        this.akStepService.setModeCurrentStep(false);

      }


      // Mario: Otra condición, si el modulo es el de entrevista, para cualquier perfil, en cualquier momento se puede guardar ese step
      // por lo cual acá condicionaré que para ese workflow, stage y step, sea en modo de edición

      if (this.akStepService.currentStep$.getValue() === 'CLIENT_INTERVIEW') {
        this.akStepService.setModeCurrentStep(false);
      }


    }

  }



  action(response) {
    if (response) {
      if (response.action) {
        if (response.action === 'download') {
          if (response.status === 'success') {
            if (response.deleteAfterShow) {
              this._httpService.executeRequest(response.deleteAfterShow.deleteRequirement.name, response.deleteAfterShow.deleteRequirement.params, true).subscribe((del: any) => {
              }, error => {
              });
            }
          }
        }
      }
    }
  }




  getStep() {
    return new Promise((resolve, reject) => {
      this.akStepService.currentStep$.subscribe((step) => {
        if (!isNullOrUndefined(step) && step !== 'do nothing') {
          resolve(step);
        }
      });
    });
  }

  getStage() {
    return new Promise((resolve, reject) => {
      this.akStageService.currentStage$.subscribe((stage) => {
        if (!isNullOrUndefined(stage) && stage !== 'do nothing') {
          resolve(stage);
        }
      });
    });
  }


  tabs() {
    Promise.all([this.getStage(), this.getStep()]).then((response) => {

      this._middleware.isModuleRequirementsActive().then((isActiveRequirements) => {
        const step = response[1];
        const stage = response[0];

        const workflow = this.akWorkflowService.currentWorkflow$.getValue();
        let config = null;
        if (stage === 'ACCOUNT_CONTRACT_ANALYSIS') {
          if (step === 'ACCOUNT_OPENING_ANALYSIS') {
            config = {
              isActiveMyNumber: false,
              isActiveMenu: false,
              tabs: [
                {
                  identifier: 'P1',
                  description: '@i18n-text-tab-plate',
                  enabled: true,
                  workflow: workflow,
                  stage: stage,
                  step: 'ACCOUNT_OPENING_ANALYSIS'
                },
                {
                  identifier: 'P2',
                  description: '@i18n-text-tab-progress',
                  enabled: false,
                  workflow: workflow,
                  stage: stage,
                  step: ''
                }
              ]
            };
          } else if (step === 'REVISIONS_TAB' || step === 'VERSIONS_TAB' || step === 'CHECKLIST_TAB' || step === 'RELATION_TO_OTHER_PROCEDURES_TAB' || step === 'VIEW_ACCOUNT_OPENING_ANALYSIS') {
            config = {
              isActiveMyNumber: true,
              isActiveMenu: true,
              tabs: [
                {
                  identifier: 'P1',
                  description: '@i18n-text-tab-resume',
                  enabled: true,
                  workflow: workflow,
                  stage: stage,
                  step: 'VIEW_ACCOUNT_OPENING_ANALYSIS'
                },
                {
                  identifier: 'P4',
                  description: '@i18n-text-tab-checklist',
                  enabled: isActiveRequirements,
                  workflow: workflow,
                  stage: stage,
                  step: 'CHECKLIST_TAB'
                },
                {
                  identifier: 'P2',
                  description: '@i18n-text-tab-revisions',
                  enabled: true,
                  workflow: workflow,
                  stage: stage,
                  step: 'REVISIONS_TAB'
                },
                {
                  identifier: 'P3',
                  description: '@i18n-text-tab-versions',
                  enabled: true,
                  workflow: workflow,
                  stage: stage,
                  step: 'VERSIONS_TAB'
                },

                {
                  identifier: 'P5',
                  description: '@i18n-text-tab-procedures',
                  enabled: true,
                  workflow: workflow,
                  stage: stage,
                  step: 'RELATION_TO_OTHER_PROCEDURES_TAB'
                }
              ]
            };
          }
        } else if (stage === 'LIST_ACCOUNT_OPENING') {
          if (step === 'LIST_ACCOUNT_OPENING') {
            config = {
              isActiveMyNumber: false,
              isActiveMenu: false,
              tabs: [
                {
                  identifier: 'P1',
                  description: '@i18n-text-tab-plate',
                  enabled: true,
                  workflow: workflow,
                  stage: stage,
                  step: 'LIST_ACCOUNT_OPENING'
                },
                {
                  identifier: 'P2',
                  description: '@i18n-text-tab-progress',
                  enabled: false,
                  workflow: workflow,
                  stage: stage,
                  step: ''
                }
              ]
            };
          }
        } else if (stage === 'LIST_CLIENTS') {
          if (step === 'LIST_CLIENTS') {
            this.akStepService.setModeCurrentStep(false);
            config = {
              isActiveMyNumber: false,
              isActiveMenu: false,
              tabs: [
                {
                  identifier: 'P1',
                  description: '@i18n-text-tab-plate',
                  enabled: true,
                  workflow: workflow,
                  stage: 'LIST_CLIENTS',
                  step: 'LIST_CLIENTS'
                },
                {
                  identifier: 'P2',
                  description: '@i18n-text-tab-create',
                  enabled: true,
                  workflow: workflow,
                  stage: 'CREATE_USER_ACCOUNT_OPENING',
                  step: 'CREATE_USER_ACCOUNT_OPENING'
                }
              ]
            };
          }
        } else if (stage === 'CREATE_USER_ACCOUNT_OPENING') {
          if (step === 'CREATE_USER_ACCOUNT_OPENING') {
            config = {
              isActiveMyNumber: false,
              isActiveMenu: false,
              tabs: [
                {
                  identifier: 'P1',
                  description: '@i18n-text-tab-plate',
                  enabled: true,
                  workflow: workflow,
                  stage: stage,
                  step: 'LIST_CLIENTS'
                },
                {
                  identifier: 'P2',
                  description: '@i18n-text-tab-create',
                  enabled: true,
                  workflow: workflow,
                  stage: 'CREATE_USER_ACCOUNT_OPENING',
                  step: 'CREATE_USER_ACCOUNT_OPENING'
                }
              ]
            };
          }
        };
        this._tabService.config$.next(config);
      });
    });
  }




  UpdateTransactionStatus(status) {
    // Juan soto
    this._httpService.HttpUpdate$.subscribe(type => {
      if (type && type !== 'get') {
        let transactionData: any = this.akWorkflowService.transactionData$.getValue();
        if (transactionData) {
          let temp = transactionData.accountStatus[0].additionalType;
          if (temp == status) {
            this.akWorkflowService.setTransactionData().subscribe();
          }
        }
      }
    });

  }

  getDinamicCssUrl() {
    if (environment.theme.images === 'FACEBANK') {
      this.dinamicCssUrl = './assets/facebank/public/css';
    } else if (environment.theme.images === 'MIBANCO') {
      this.dinamicCssUrl = './assets/mibanco/public/css';
    } else if (environment.theme.images === 'DEMO1') {
      this.dinamicCssUrl = './assets/demo1/public/css';
    } else if (environment.theme.images === 'DEMO2') {
      this.dinamicCssUrl = './assets/demo2/public/css';
    } else if (environment.theme.images === 'CARONI') {
      this.dinamicCssUrl = './assets/caroni/public/css';
    }else if (environment.theme.images === 'RENDIVALORES') {
      this.dinamicCssUrl = './assets/rendivalores/public/css';
    }else if (environment.theme.images === 'MC') {
        this.dinamicCssUrl = './assets/mc/public/css';
    }else if (environment.theme.images === 'KOI') {
      this.dinamicCssUrl = './assets/koi/public/css';
    }else if (environment.theme.images === 'PERCAPITA') {
      this.dinamicCssUrl = './assets/percapita/public/css';
    }  else {
      this.dinamicCssUrl = './assets/public/css';
    }
    // this.dinamicCssUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.dinamicCssUrl);
  }


  ngAfterViewInit() {
    setTimeout(() => {
      this.templates = this.templateService.getTemplates();
    }, 10);

    setTimeout(() => {
      this.templateRequirements = this._templateRequirements.getTemplates();
    }, 10);
  }


  public ngDoCheck() {
    if (this.showSidebarLeft && this._auth.isAuthenticated()) {
      document.getElementById("sidebarleft").classList.add('d-block');

      document.body.classList.add('p-body');
      document.body.classList.add('size-menu-left');

    } else {

      document.getElementById("sidebarleft").classList.remove('d-block');
      document.body.classList.remove('p-body');
      document.body.classList.remove('size-menu-left');
    }
  }



  toggleMenu() {
    this._akeelaSidebarMenu.setshowSidebarLeft(!this.showSidebarLeft);
  }

  window_close() {
    this.upload.visor = false;
  }

  eventMenu(response) {
    if (response.action === 'navigate') {
      if (!isNullOrUndefined(this.localStorage.getItem('transactionModeEdit'))) {
        this.breakfreeTransactionEdit(); // Mario: este caso era de liberación individual, pero debido a que ahora se va a liberar por usuario, se comenta para ver el comportamiento
        this.breakfreeTransactionsByUser();
      } else {
        this.breakfreeTransactionsByUser();
      }
    }
  }


  breakfreeTransactionsByUser() {
    const configuration = {
      name: 'account$finish_editing_role_by_user$put',
      params: {
        path: {},
        request: {
          role: 'USER',
          systemUser: this._auth.getUsername()
        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.akWorkflowService.transactionData$.next(null);

    }, error => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.akWorkflowService.transactionData$.next(null);

    });
  }


  breakfreeTransactionEdit() {
    const configuration = {
      name: 'account_opening$finish_editing_role$put',
      params: {
        path: {
          transactionNumber: this.localStorage.getItem('transactionModeEdit')
        },
        request: {
          role: 'USER',
          systemUser: this._auth.getUsername()
        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.akWorkflowService.transactionData$.next(null);

    }, error => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.akWorkflowService.transactionData$.next(null);

    });
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      // prevent memory leak when component destroyed
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

redirectSpace(){

  const queryParams = {
    role: this.localStorage.getItem('role'),
    audience: this.localStorage.getItem('audience'),
    profile: this.localStorage.getItem('profile')

  };


  this.router.navigateByUrl(this.router.createUrlTree(['private/ACCOUNT_OPENING/stages/ACCOUNT_CONTRACT_ANALYSIS/steps/USER_WORKSPACES'],
    {
      queryParams: queryParams ? queryParams : {}
    }));


}

}