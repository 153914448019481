<form [formGroup]="formGroup"  novalidate>
  <div class="input-group">
    <input type="search" name="search" autocomplete="off" formControlName="search" class="form-control search" (keyup)="check()"
      [readonly]="disabled" (keyup.enter)="onEnter()" placeholder="Buscar Solicitud #..." />
    <span class="search input-group-addon" title="Buscar" (click)="search()" *ngIf="activeSearch && !disabled">
      <i class="fas fa-search iconsearch"></i>
    </span>
    <span class="search input-group-addon" title="Borrar" (click)="trash()" *ngIf="activeTrash && !disabled">
      <i class="fas fa-times icontrash"></i>
    </span>
    <span class="search input-group-addon" [swal]="showWindow" title="Filtros avanzados" (click)="advanced()" *ngIf="!disabled">
      <i class="fas fa-caret-down iconsearch"></i>
    </span>
    <span class="search input-group-addon" title="Buscando" *ngIf="disabled">
      <i class="fas fa-spinner fa-spin"></i>
    </span>
  </div>
</form>


<swal [width]="'850px'" [showCloseButton]="true" [showConfirmButton]="false" [showCancelButton]="false" #showWindow>
  <ng-container *swalPortal="swalTargets.content">
    <br>
    <div class="row">
      <div class="d-none col-md-2">
      </div>
      <div class="col-12 col-md-12">
        <form [formGroup]="formGroupAdvanced" (submit)="send()" novalidate>
          <div class="form-group row">
            <label for="tramite" class="col-sm-2 col-form-label ">Solicitud #</label>
            <div class="col-sm-10">
              <input type="text" autocomplete="off" class="form-control withoutorder" name="procedure" formControlName="procedure">
            </div>
          </div>

          <div class="form-group row">
            <label for="tramite" class="col-sm-2 col-form-label ">Cliente</label>
            <div class="col-sm-10">
              <input type="text" autocomplete="off" class="form-control withoutorder" name="client" formControlName="client">
            </div>
          </div>


          <div class="form-group row">
            <label for="received" class="col-sm-2 col-form-label">Creación</label>
            <div class="col-4 col-sm-2">
              <select class="form-control search withoutorder" name="c_type" formControlName="c_type" (change)="verify('c_type')">
                <option value='' [selected]="true">--</option>
                <option value="GREATER_THAN">Superior a</option>
                <option value="LESS_THAN">Menor a</option>
                <option value="EQUAL">Igual a</option>
                <option value="BETWEEN">Entre </option>
              </select>
            </div>
            <div [class.col-8]="!c_date_between_active" [class.col-4]="c_date_between_active">
              <input type="date" autocomplete="off" class="form-control withoutorder" [class.error]="formGroupAdvanced.get('date_c_from').status==='INVALID'"
                name="date_c_from" formControlName="date_c_from">
            </div>
            <div [class.d-none]="!c_date_between_active" [class.d-block]="c_date_between_active" [class.col-4]="c_date_between_active">
              <input type="date" autocomplete="off" class="form-control withoutorder" [class.error]="formGroupAdvanced.get('date_c_to').status==='INVALID'"
                name="date_c_to" formControlName="date_c_to">
            </div>
          </div>

          <div class="form-group row">
              <label for="received" class="col-sm-2 col-form-label">Solicitud</label>
              <div class="col-4 col-sm-2">
                <select class="form-control search withoutorder" name="s_type" formControlName="s_type" (change)="verify('s_type')">
                  <option value='' [selected]="true">--</option>
                  <option value="GREATER_THAN">Superior a</option>
                  <option value="LESS_THAN">Menor a</option>
                  <option value="EQUAL">Igual a</option>
                  <option value="BETWEEN">Entre </option>
                </select>
              </div>
              <div [class.col-8]="!s_date_between_active" [class.col-4]="s_date_between_active">
                <input type="date" autocomplete="off" class="form-control withoutorder" [class.error]="formGroupAdvanced.get('date_s_from').status==='INVALID'"
                  name="date_s_from" formControlName="date_s_from">
              </div>
              <div [class.d-none]="!s_date_between_active" [class.d-block]="s_date_between_active" [class.col-4]="s_date_between_active">
                <input type="date" autocomplete="off" class="form-control withoutorder" [class.error]="formGroupAdvanced.get('date_s_to').status==='INVALID'"
                  name="date_s_to" formControlName="date_s_to">
              </div>
            </div>



          <div class="form-group row">
            <label for="received" class="col-sm-2 col-form-label">Recibido</label>
            <div class="col-4 col-sm-2">
              <select class="form-control search withoutorder" name="m_type" formControlName="m_type" (change)="verify('m_type')">
                <option value='' [selected]="true">--</option>
                <option value="GREATER_THAN">Superior a</option>
                <option value="LESS_THAN">Menor a</option>
                <option value="EQUAL">Igual a</option>
                <option value="BETWEEN">Entre </option>
              </select>
            </div>
            <div [class.col-8]="!m_date_between_active" [class.col-4]="m_date_between_active">
              <input type="date" autocomplete="off" class="form-control withoutorder" [class.error]="formGroupAdvanced.get('date_m_from').status==='INVALID'"
                name="date_m_from" formControlName="date_m_from">
            </div>
            <div [class.d-none]="!m_date_between_active" [class.d-block]="m_date_between_active" [class.col-4]="m_date_between_active">
              <input type="date" autocomplete="off" class="form-control withoutorder" [class.error]="formGroupAdvanced.get('date_m_to').status==='INVALID'"
                name="date_m_to" formControlName="date_m_to">
            </div>
          </div>
          <div class="form-group row">
            <label for="agency" class="col-sm-2 col-form-label">Estatus</label>
            <div class="col-sm-10">
              <select class="form-control search withoutorder" name="status" formControlName="status">
                <option value='' [selected]="true">--</option>
                <ng-container *ngFor="let state of states">
                  <option [value]='state.code'>{{ state.name | i18n }} </option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="agency" class="col-sm-2 col-form-label">Canal</label>
            <div class="col-sm-10">
              <select class="form-control search withoutorder" name="channel" formControlName="channel">
                <option value='' [selected]="true">--</option>
                <ng-container *ngFor="let channel of channels">
                  <option [value]='channel.alternateName'>{{ channel.name | i18n }} </option>
                </ng-container>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <label for="agency" class="col-sm-2 col-form-label">Agencia</label>
            <div class="col-sm-10">
              <select class="form-control search withoutorder" name="agency" formControlName="agency">
                <option value='' [selected]="true">--</option>
                <ng-container *ngFor="let agency of agencys">
                  <option [value]='agency.identifier'>{{ agency.name | i18n }} </option>
                </ng-container>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <label for="agency" class="col-sm-2 col-form-label">Ejecutivo</label>
            <div class="col-sm-10">
              <select class="form-control search withoutorder" name="executive" formControlName="executive">
                <option value='' [selected]="true">--</option>
                <ng-container *ngFor="let executive of executives">
                  <option [value]='executive.identifier'>{{ executive.person.givenName+' '+executive.person.familyName | i18n }} </option>
                </ng-container>
              </select>
            </div>
          </div>


          <div class="text-right">
            <button type="submit" class="btn btn-primary">Buscar&nbsp;
              <i class="fas fa-spinner fa-spin" *ngIf="loading"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
</swal>