<akeela-requirements-template>

    <ng-template akeelaRequirementsContent componentName="requirementsDetailHeaderContent">
    </ng-template>
    <ng-template akeelaRequirementsContent componentName="requirementsDetailFooterContent">
    </ng-template>
    <ng-template akeelaRequirementsContent componentName="requirementsHeaderContent">

        <ng-container *ngIf="isActiveResume">

        </ng-container>
        <!-- && this.transactionData.accountStatus[0].identifier==='3' || this.transactionData.accountStatus[0].identifier==='4' || this.transactionData.accountStatus[0].identifier==='5' -->
        <div class="w-100" *ngIf="balance_requirements && config_requirements.isActiveProgressBarUpload">
            <!--
            <div class="w-100 d-inline-block pr-2 text-right" title="Mostrar/Ocultar Descripciones">
                <div class="row">

                    <div class="d-none d-md-block col-md-5">
                    </div>
                    <div [class.col-md-4]="!config_requirements.isFilterRequirementsByResponse" [class.col-md-2]="config_requirements.isFilterRequirementsByResponse" [class.col-4]="config_requirements.isFilterRequirementsByResponse" [class.col-8]="!config_requirements.isFilterRequirementsByResponse"
                        *ngIf="config_requirements.isFilterRequirementsByCategory">
                        <select class="form-control" style="height: 90%!important;" id="filtro_tipo" (click)="filter_category($event)">
                            <option value="" [selected]="filter_category_selected===''">-seleccione-</option>
                            <ng-container *ngFor="let category of categories">
                                <option value="{{ category.identifier }}">{{ category.name | i18n }}</option>
                            </ng-container>

                        </select>
                    </div>
                    <div class="col-4 col-md-2" *ngIf="config_requirements.isFilterRequirementsByResponse">
                        <select class="form-control" style="height: 90%!important;" id="filtro_tipo" (click)="filter($event)">
                            <option value="" [selected]="filter_selected===''">-seleccione-</option>
                            <option value="VERIFIED" [selected]="filter_selected==='VERIFIED'" [innerHTML]="'VERIFIED' | i18n"></option>

                            <option value="INCOMPLETED" [selected]="filter_selected==='INCOMPLETED'" [innerHTML]="'INCOMPLETED' | i18n"></option>
                            <option value="REJECTED" [selected]="filter_selected==='REJECTED'" [innerHTML]="'REJECTED' | i18n"></option>
                            <option value="INCONSISTENT" [selected]="filter_selected==='INCONSISTENT'" [innerHTML]="'INCONSISTENT' | i18n"></option>
                        </select>
                    </div>
                </div>


            </div>

        

        
            <div class="row">
                <div class="col-12 col-lg-10" style="font-size: 14px">
                    <akeela-requirements-progress [balanceRequirements]="balance_requirements" [configuration]="config_requirements"></akeela-requirements-progress>
                </div>
            </div>
    -->
        </div>

    </ng-template>
</akeela-requirements-template>

<ng-container *ngIf="activeRequirements">

    <akeela-requirements-user [viewOptional]="viewOptional" [folder]="folder" [config]="config_requirements" (showFile)="show_file($event)" (openWindowRequirement)="openWindowRequirement($event)" (response)="response_requirements($event)" (finishupload)="finish_upload($event)"
        (responseTransactionChange)="response_transaction_change($event)" (responsetransaction)="response_transaction($event)" *ngIf="config_requirements"></akeela-requirements-user>


    <ng-container *ngIf="balance_requirements && !config_requirements.isReadonly">
        <ng-container *ngIf="balance_requirements.total_required_requirements_completed >= balance_requirements.total_required_requirements">
            <div class="box m-t-2  m-b-2 p-b-1 p-t-1">
                <div class="w-100 ">
                    <div class="">
                        <div class="col-4 offset-8 text-right">
                            <button type="button" [disabled]="submit_disabled" class="btn btn-block btn-primary btn-lg" (click)="continuar()">
                                <span [innerHTML]="'Continuar' | i18n:lang"></span>&nbsp;
                                <span *ngIf="submit_disabled">
                                    <!--  -->
                                    <i class="fa fa-refresh fa-spin"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>

<ng-container *ngIf="!config_requirements">
    <div class="container animated fadeIn ">
        <div class="col-12 text-center">
            <div>
                <br>
                <br>
                <br>
                <div>
                    <img class=" float-left" src="./assets/public/images/loading.gif" alt="">
                </div> <br>
            </div>
        </div>
    </div>
</ng-container>