import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { AuthService } from '@akeela/auth';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-detail-formality',
  templateUrl: './detail-formality.component.html',
  styleUrls: ['./detail-formality.component.css']
})
export class DetailFormalityComponent implements OnInit {
  @Input() transactionData;

  constructor() { }

  ngOnInit() {

  }

}
