<!--
<br><br>
<div> <button (click)="start()">start grabación </button>
    <button (click)="stop()">stop  grabación </button>

</div>
<div></div>

<div *ngIf="init_start">
    <video autoplay playsinline style="height: 200px ;width: 300px;"></video>

</div>
<hr>


 -->
<div id="jitsi-iframe" #size></div>

<div class="item-center">
    <span>Custom Controls</span>
</div>
<div class="item-center">
    <span>&nbsp;&nbsp;</span>
    <i (click)="executeCommand('toggleAudio')" class="fas  grey-color" [ngClass]="isAudioMuted ? 'fa-microphone-slash' : 'fa-microphone'" aria-hidden="true" title="Mute / Unmute"></i>
    <i (click)="executeCommand('hangup')" class="fas fa-phone-slash  red-color" aria-hidden="true" title="Leave"></i>
    <i (click)="executeCommand('toggleVideo')" class="fas   grey-color" [ngClass]="isVideoMuted ? 'fa-video-slash' : 'fa-video'" aria-hidden="true" title="Start / Stop camera"></i>
    <i (click)="executeCommand('toggleShareScreen')" class="fas fa-film  grey-color" aria-hidden="true" title="Share your screen"></i>
</div>
<p id="errors"></p>
<p>