import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LocalService } from '@akeela/local-storage';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @Output() lang = new EventEmitter();
  url: String = '/login';
  token;
  env = environment;
  lang_local: string;

  constructor(
    private router: Router,
    private localStorage: LocalService
  ) {
    this.lang_local = 'es'
  }

  ngOnInit() {

  }

  changelang(i18n) {
    this.lang_local = i18n;
    this.lang.emit({ lang: this.lang_local });
  }

  login() {
    this.router.navigate([environment.security.uriLogin]);
  }

  register() {
    this.localStorage.setItem('role', 'USER');
    this.router.navigateByUrl(this.router.createUrlTree
      (['dashboard/ACCOUNT_OPEN/stages/CREATE_USER_ACCOUNT_OPENING/steps/CREATE_USER_ACCOUNT_OPENING'],
      {
        queryParams: {
          role: 'C'
        }
      }
      )
    );
  }

  redirectHome(){
    this.router.navigate(['/'], {});
  }

}
