<!-- {{ transactions | json }} -->
<div class="mt-3">


  <ng-container *ngIf="transactions">
  <ng-container *ngFor="let transaction of transactions; let idx = index">
      <div class=" mt-3 "  *ngIf="(!open && (transactions.length-300)<idx) || open">

      
      <a class="nounderline active-cursor" data-toggle="collapse" href="#demo{{transaction?.identifier}}" (click)="updateShow(transaction?.identifier)">
          <div class="card mt-3 bg-light b-0">
              <div class="card-body p-3 b-0">
                  <div *ngIf="name font-weight-bold" >
                  <div class="mr-2 d-inline-flex DomainUserAvatar-avatar Avatar--color-{{initial}}  AvatarPhoto AvatarPhoto--small" role="img"> {{initials}} </div>
                  <div class="d-inline-flex "> 
                     <div class="font-weight-bold">
                        {{name}}
                     </div>


                    
                      <div class="pl-2   text">
                          {{ (transaction?.name | i18n) }}
  
                      </div>

                      <div class="pl-2 text-secondary small mt-1 float-right text">
                           {{ transaction?.dateOfThing.startDate | date:'medium' }}
                      </div>
                      
                  
                  </div>

                  <div style="margin-left: 30px;" class="d-block small w-100 mt-2" *ngIf="transaction?.responseDescription">
                     
                      <span  class="pr-2 text-secondary">
                          <i class=" far fa-comment mr-1"></i>              
                           Respuesta 
                      </span>
                  
                       {{ transaction?.responseDescription }}
                       
                     </div>
                     
                
                  </div>
              </div>
          </div>
  
      </a>

      <ng-container *ngIf="transaction?.responseDescription">
        <div id="demo{{transaction?.identifier}}" [class.collapse]="(active!==transaction?.identifier)">
       
         
          <div class="row" *ngIf="transaction?.sectionsIncompleteAccredited">
            <div class="col-12">
              <div style=" padding-left: 14px" class="mt-2">

                <ng-container *ngIf="transaction?.sectionsIncompleteAccredited!==null">
                  <ng-container *ngIf="transaction?.sectionsIncompleteAccredited.length>0">
                    <span class="font-weight-bold mt-2">Secciones incompletas: </span>

                    <ng-container *ngFor="let row of transaction?.sectionsIncompleteAccredited; let first = first; let i_stf = index">
                      <div class="row pt-1">
                        <div class="col-8 fz-body">
                          <span class="font-weight-bold">{{ row.url | i18n }}</span>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-8 fz-body">
                          <span>{{ row.description }}</span>
                        </div>
                        <div class="col-4 fz-body text-right">
                          <span style="font-size:0.7rem" *ngIf="row.modifyUser">{{ '@'+row.modifyUser }}</span>
                        </div>

                      </div>
                      <div class="row">

                        <div class="col-12 fz-body text-right">
                          <span style="font-size:0.7rem" *ngIf="row.modifyUser">{{ row.dateOfThing.startDate | datePipe: 'simple' }}</span>
                        </div>

                      </div>
                      <hr class="mt-025 mb-0" *ngIf="(transaction?.sectionsIncompleteAccredited.length - 1) !== i_stf">
                    </ng-container>
                  </ng-container>
                </ng-container>
                <!-- AQUIIIIIII -->
                <!-- {{ requirements | json }} -->
                <ng-container *ngIf="requirements!==null  && finish">

                  <ng-container *ngIf="requirements.length>0">
                    <ng-container *ngIf="requirements[transaction.additionalType]!==undefined">
                      <ng-container *ngIf="requirements[transaction.additionalType][0]!==undefined">
                        <span class="font-weight-bold">Requisitos incompletos: </span>

                        <ng-container *ngFor="let row of requirements[transaction.additionalType][0].value; let first = first; let i_stf = index">
                          <div class="row pt-1">
                            <div class="col-8 fz-body">
                              <span class="font-weight-bold">{{ row.requirement.name | i18n }}</span>
                            </div>

                          </div>
                          <div class="row">
                            <div class="col-8 fz-body">
                              <span>{{ row.responseDescription }}</span>
                            </div>
                            <!-- <div class="col-4 fz-body text-right">
                          <span style="font-size:0.7rem" *ngIf="row.modifyUser">{{ '@'+row.modifyUser }}</span>
                        </div> -->

                          </div>
                          <div class="row">

                            <div class="col-12 fz-body text-right">
                              <span style="font-size:0.7rem" *ngIf="row.modifyUser">{{ row.dateOfThing.startDate | datePipe: 'simple' }}</span>
                            </div>

                          </div>
                          <hr class="mt-025 mb-0" *ngIf="(transaction?.sectionsIncompleteAccredited.length - 1) !== i_stf">
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="transaction?.sectionsIncompleteAccredited===null">
                  <div class="row">
                    <div class="col-12 text-center">

                        <div>
                            <img class=" float-left" src="./assets/public/images/loading.gif" alt="">
                        </div>                  
                  
                    </div>
                  </div>
                </ng-container>

              </div>
            </div>
          </div>
        </div>
      </ng-container>
  

    </div>

  </ng-container>
<!--
  <ng-container *ngIf="transactions.length>4">
      <div (click)="open=!open" *ngIf="!open" class="w-100 font-weight-bold active-cursor" style="display: flex;">
        <a href="javascript:void()"> Expandir historial ({{ transactions.length}}) </a>
        <div style="position: absolute; right: 0px">
          <i class="fa  pull-right text-right" style="margin: 0" [class.fa-angle-down]="true" aria-hidden="true"></i>
        </div>
      </div>
      <div (click)="open=!open" *ngIf="open" class="w-100 font-weight-bold active-cursor" style="display: flex;">
        <a href="javascript:void()"> Ocultar </a>
        <div style="position: absolute; right: 0px">
          <i class="fa  pull-right text-right" style="margin: 0" [class.fa-angle-up]="true" aria-hidden="true"></i>
        </div>
      </div>
    </ng-container>-->
  </ng-container>

</div>


<div class="mt-3"  *ngIf="!(transaction && transactions)" >
    <img  src="./assets/public/images/loading.gif" alt="">
</div>

