<div class="">

    <form [formGroup]="form" (ngSubmit)="submit()" class="text-left">
        <div class="row">

            <div class="form-group col-12 col-md-6">
                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span [innerHTML]="'Tipo de solicitante'"></span>
                            <span class="text-danger mt-1" > * </span>
                </label>

                <select class="form-control" [ngClass]="{'error': form.controls.personType.invalid && form.controls.personType.dirty}" formControlName="personType">
                   
                    <option value="">Seleccione</option>
                    <option value="NATURAL">Persona Natural</option>
                    <option value="LEGAL">Persona Jurídica</option>

                </select>

                <div class="form-control-feedback help-block" *ngIf="form.get('personType').errors">
                    <div *ngIf="form.get('personType').errors['required'] && !form.get('personType').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                    </div>

                </div>
            </div>

            <div *ngIf="form?.get('personType')?.value == 'LEGAL'" class="form-group col-12 col-md-6">
                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span [innerHTML]="'Nombre de la Razón Social'"></span>
                            <span class="text-danger mt-1" > * </span>
                </label>
                <input [placeholder]="'Nombre de la Razón Social'" type="text" class="form-control" [ngClass]="{'error': form.controls.legalName.invalid && form.controls.legalName.dirty}" formControlName="name">
                <div class="form-control-feedback help-block" *ngIf="form.get('legalName').errors">
                    <div *ngIf="form.get('legalName').errors['required'] && !form.get('legalName').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                    </div>

                </div>
            </div>

        </div>
        <div class="row">

            <div class="form-group col-12 col-md-6">
                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span [innerHTML]="'Nombre'"></span>
                            <span class="text-danger mt-1" > * </span>
                </label>
                <input [placeholder]="'Nombre'" type="text" class="form-control" [ngClass]="{'error': form.controls.name.invalid && form.controls.name.dirty}" formControlName="name">
                <div class="form-control-feedback help-block" *ngIf="form.get('name').errors">
                    <div *ngIf="form.get('name').errors['required'] && !form.get('name').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                    </div>

                </div>
            </div>

            <div class="form-group col-12 col-md-6">
                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span [innerHTML]="'Apellido'"></span>
                            <span class="text-danger mt-1" > * </span>
                </label>
                <input [placeholder]="'Aplellido'" type="text" class="form-control" [ngClass]="{'error': form.controls.lastName.invalid && form.controls.lastName.dirty}" formControlName="lastName">
                <div class="form-control-feedback help-block" *ngIf="form.get('lastName').errors">
                    <div *ngIf="form.get('lastName').errors['required'] && !form.get('lastName').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                    </div>
                </div>
            </div>




            <div class="form-group col-12 col-md-6">
                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                        <span [innerHTML]="'Número de documento'"></span>
                        <span class="text-danger mt-1" > * </span>
            </label>
                <input [placeholder]="'Número de documento'" type="text" class="form-control" [ngClass]="{'error': form.controls.identity.invalid && form.controls.identity.dirty}" formControlName="identity">
                <div class="form-control-feedback help-block" *ngIf="form.get('identity').errors">
                    <div *ngIf="form.get('identity').errors['required'] && !form.get('identity').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                    </div>

                    <div *ngIf="form.get('identity').hasError('pattern')  && !form.get('identity').pristine">
                        <span>
                            Introduzca un formato válido Eje: (V19163585)
                        </span>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">


            <div class="form-group col-12 col-md-6">
                <label class=" label font-weight-normal" style="word-wrap: break-word;">

                        <span [innerHTML]="'Email'"></span>
                        <span class="text-danger mt-1" > * </span>
            </label>
                <input [placeholder]="'Email'" type="text" class="form-control" [ngClass]="{'error': form.controls.email.invalid && form.controls.email.dirty}" formControlName="email">
                <div class="form-control-feedback help-block" *ngIf="form.get('email').errors">
                    <div *ngIf="form.get('email').errors['required'] && !form.get('email').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                    </div>

                    <div *ngIf="form.get('email').hasError('pattern')  && !form.get('email').pristine">
                        <span>Correo Invalido</span>
                    </div>
                </div>
            </div>


            <div class="form-group col-12 col-md-6">
                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                        <span [innerHTML]="'Teléfono'"></span>
                        <span class="text-danger mt-1" > * </span>
            </label>
                <input [placeholder]="'Teléfono'" type="text" class="form-control" [ngClass]="{'error': form.controls.phone.invalid && form.controls.phone.dirty}" formControlName="phone">
                <div class="form-control-feedback help-block" *ngIf="form.get('phone').errors">
                    <div *ngIf="form.get('phone').errors['required'] && !form.get('phone').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                    </div>

                    <div *ngIf="form.get('phone').hasError('pattern')  && !form.get('phone').pristine">
                        <span>
                            Formato (Cod. País + Cod. Área + Nro. Teléfono) Ej: +584240000000, USA +10000000000
                        </span>
                    </div>

                </div>
            </div>


            <div class="form-group col-12 col-md-6">
                <label class=" label font-weight-normal" style="word-wrap: break-word;">
                        <span [innerHTML]="'Cantidad de acciones'"></span>
                        <span class="text-danger mt-1" > * </span>
            </label>
                <input [placeholder]="'Cantidad de acciones'" type="text" class="form-control" [ngClass]="{'error': form.controls.phone.invalid && form.controls.phone.dirty}" formControlName="countActions">
                <div class="form-control-feedback help-block" *ngIf="form.get('countActions').errors">
                    <div *ngIf="form.get('countActions').errors['required'] && !form.get('countActions').pristine" [innerHTML]="'@i18n-store-front-login-error-field-required' | i18n">
                    </div>

                </div>
            </div>
            <br>
            <div class=" col-12 mt-3">


                <button type="submit" [disabled]="!form.valid || submit_disabled" class="btn btn-primary  ">
                    Continuar
                            <span *ngIf="submit_disabled">
                              <i class="fa  fa-spinner fa-spin"></i>
                            </span>
                </button>
            </div>

        </div>
    </form>
</div>