<ng-container *ngIf="transactionData">
    <div class="col-12 ">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 mb-sm-2">
                <div>
                    <div [innerHTML]="'Cliente' | i18n:lang" class="label"></div>
                    <div [innerHTML]="(transactionData?.holder.additionalType==='LEGAL'?transactionData.holder.adminOrganization.legalName:transactionData.holder.person.name)"
                        class="text"></div>
                </div>

            </div>
            <div class="col-12 col-sm-6 col-md-4 mb-sm-2">
                <div>
                    <div [innerHTML]="'Usuario aplicante' | i18n:lang" class="label"></div>
                    <div [innerHTML]="(transactionData?.holder?.person?.name)" class="text"></div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 mb-sm-2">
                <div>
                    <div [innerHTML]="'Creado por' | i18n:lang" class="label"></div>
                    <div [innerHTML]="transactionData?.requestUser?.person?.name" class="text"></div>
                </div>
            </div>
        </div>
        <div class="row d-none d-md-block">
            <div class="col-12">
                <br>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 mb-sm-2">
                <div>
                    <div [innerHTML]="'Canal' | i18n:lang" class="label"></div>
                    <div [innerHTML]="transactionData?.availableChannel?.name" class="text"></div>
                </div>
            </div>


            <div class="col-12 col-sm-6 col-md-4 mb-sm-2">
                <div>
                    <div [innerHTML]="'Agencia' | i18n:lang" class="label"></div>
                    <div [innerHTML]="transactionData?.channelAgency?.name" class="text"></div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 mb-sm-2">
                <div>
                    <div [innerHTML]="'Alianza' | i18n:lang" class="label"></div>
                    <div [innerHTML]="transactionData?.alliance? (transactionData?.alliance.name | i18n) : '-'" class="text"></div>
                </div>
            </div>
        </div>
        <div class="row d-none d-md-block">
            <div class="col-12">
                <br>
            </div>
        </div>

        <div class="row">





            <div class="col-12 col-sm-6 col-md-4 mb-sm-2">
                <div>
                    <div [innerHTML]="'Ejecutivo' | i18n:lang" class="label"></div>
                    <div [innerHTML]="transactionData?.salesManagerUser?.name" class="text"></div>
                </div>
            </div>

            <div class="col-12 col-sm-6 col-md-4 mb-sm-2">
                <div>
                    <div [innerHTML]="'Número de versión' | i18n:lang" class="label"></div>
                    <div [innerHTML]="transactionData?.numberOfVersions" class="text"></div>

                </div>
            </div>


        </div>
        <div class="row d-none d-md-block">
            <div class="col-12">
                <br>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 mb-sm-2">
                <div>
                    <div [innerHTML]="'Estado' | i18n:lang" class="label"></div>
                    <div [innerHTML]="transactionData.accountStatus[0].userName | i18n" class="text"></div>
                </div>
            </div>
        </div>
        <div class="row d-none d-md-block">
            <div class="col-12">
                <br>
            </div>
        </div>
        <div class="row">

            <div class="col-12 col-sm-6 col-md-4 mb-sm-2">
                <div>
                    <div [innerHTML]="'Fecha de creación' | i18n:lang" class="label"></div>
                    <div [innerHTML]="transactionData?.dateOfThing?.startDate | datePipe: 'simple'" class="text"></div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 mb-sm-2">
                <div>
                    <div [innerHTML]="'Fecha del estatus' | i18n:lang" class="label"></div>
                    <div [innerHTML]="transactionData?.accountStatus[0].dateOfThing?.startDate | datePipe: 'simple'" class="text"></div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 mb-sm-2">
                <div>
                    <div [innerHTML]="'Fecha de última actualización' | i18n:lang" class="label"></div>
                    <div [innerHTML]="transactionData?.dateOfThing?.modifiedDate | datePipe: 'simple'" class="text"></div>
                </div>
            </div>
        </div>
        <div class="row d-none d-md-block">
            <div class="col-12">
                <br>
            </div>
        </div>

        <div class="w-100  text-size-1">
            Datos de la operación
        </div>
        <div class="row">
            <div class="col-12">
                <br>
            </div>
        </div>

        <div class="row">

            <div class="col-12 col-sm-6 col-md-4 mb-md-2 mb-sm-2">
                <div>
                    <div [innerHTML]="'Producto' | i18n:lang" class="label"></div>
                    <div [innerHTML]="transactionData?.plan?.financialProduct.name" class="text"></div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 mb-md-2 mb-sm-2">
                <div>
                    <div [innerHTML]="'Tipo' | i18n:lang" class="label"></div>
                    <div [innerHTML]="transactionData?.plan?.name" class="text"></div>
                </div>
            </div>

        </div>
        <div class="row d-none d-md-block">
            <div class="col-12">
                <br>
            </div>
        </div>
        <ng-container *ngIf="transactionData?.accountNumber">
            <div class="w-100  text-size-1">
                Datos de cuenta
            </div>
            <div class="row">
                <div class="col-12">
                    <br>
                </div>
            </div>

            <div class="row">

                <div class="col-12 col-sm-6 col-md-4 mb-md-2 mb-sm-2">
                    <div>
                        <div [innerHTML]="'Número de Cuenta' | i18n:lang" class="label"></div>
                        <div [innerHTML]="transactionData?.accountNumber" class="text"></div>
                    </div>
                </div>


            </div>
            <div class="row d-none d-md-block">
                <div class="col-12">
                    <br>
                </div>
            </div>
        </ng-container>



    </div>
</ng-container>
<ng-container *ngIf="!transactionData">
    <div class="row">
        <div class="col-lg-12">
            <img class="float-left" src="./assets/public/images/loading.gif" alt="">
        </div>
    </div>
</ng-container>