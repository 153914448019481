<h5 *ngIf="shareholders_related?.length > 0" class="mt-2 font-weight-bold ak-text-color-1">
    Accionistas Relacionados</h5>


<div class="container  px-0  mb-3">
    <div class="mb-0 row  skin-white  animate__animated animate__fadeIn" style="padding-left: 0; padding-right:0">
        <ng-container>

            <ng-container  *ngIf="shareholders_related?.length > 0" >
                <div class="w-100 fz-body">
                    <!-- {{ shareholders_related | json }}
                    {{ (shareholder.applicantType==='NATURAL'? (shareholder.person.givenName+' '+shareholder.person.familyName) : (shareholder.organization.legalName)) }}
                     -->
                     
                     <div class="col-12 mt-4">
                            <div class="row mt-3" >
                                <div class="cod-12 col-md-3 mt-2" *ngFor="let row of shareholders_related;">
                                        <div (click)="select_related(row)"  class="active-cursor base-style-option-button   p-2  " [class.active]="shareholders_related_selected == row.name" >

                                                <div class="d-flex bd-highlight">

                                                        <div  class=" bd-highligh w-20">
                                                                <span class="pr-2 fa fa-building fa-2x"></span>
                                                            </div>
                                                
                                                        <div  class=" bd-highligh w-80 text-left">
        
                                                            <div class="mb-1">
                                                                            <span class=" text-capitalize  font-weight-bold "> 
                                                                                    {{ getNameShareholder(row.name)}}</span>
                                                            </div>

                                                            <div class="sub-title" > 

                                                                    <div *ngIf="row.totalAccionistas < 1 ">
                                                                          <span>Agregar</span>  
                                                                     </div>
                                                                    
                                                                        <div *ngIf="row.totalAccionistas > 0 " >
                                                                          {{row.totalAccionistas }}  Accionista(s) {{row.totalAccionistasPorcentage}}%
                                                                        </div>
                                                                
                                                            </div>                      
        
                                                        </div>
                                                </div> 


                                         </div>
                                    </div>
                            </div>
                        </div>


                        
                    <ng-container *ngIf="!loading">

                            

                        <div class="mt-5"  *ngFor="let row of shareholders_related;">

                            <div class="col-12 animate__animated animate__fadeIn" *ngIf="shareholders_related_selected == row.name">


                                    <div class="mb-2 font-weight-bold" style="font-size: 1rem">
                                            {{ getNameShareholder(row.name)}} </div>
        
                                    <div class="mb-3" style="font-size: 1rem">
                                            Identifique a todos los accionistas con participación mayor a 1%. <br>En caso que algún accionista sea una empresa con participación mayor a 1%, usted deberá identificar la participación accionaria de esta.
                                    </div> 

                            <div *ngIf="row.value.length > 0">
                                <akeela-table [configuration]="configuration_tables[row.name]" [loading]="loading_table" (response)="table_response($event)" *ngIf="configuration_tables.length > 0">
                                </akeela-table>
                            </div>
                                    

                                    <!--
                                <ng-container  *ngFor="let sh of row.value let first = first; let i_stf = index">
                                      
                
                                    <hr class="m-0">
                                    <div class="d-flex bd-highlight list-flex flex-wrap pb-3 pt-3 pb-md-2 pt-md-2">
                                        <div *ngIf="sh?.person" (click)="openItem(sh, getShareholderByIdentifier(row.name))" class=" bd-highlight w-30  order-1  ml-4 ml-md-0 ">
                                            <div class=" text-capitalize">
                                                {{capitalize(sh?.person?.givenName)}} {{capitalize(sh?.person?.familyName)}}
                                            </div>
                                        </div>

                                        <div *ngIf="!sh?.person" (click)="openItem(sh, getShareholderByIdentifier(row.name))" class="mb-1 mb-md-0  bd-highlight   w-30  order-1  ml-4 ml-md-0 ">
                                            <div class=" text-capitalize">
                                                {{capitalize(sh?.organization?.legalName)}}
                                            </div>
                                        </div>

                                        <div (click)="openItem(sh, getShareholderByIdentifier(row.name))" class="mb-1 mb-md-0  mt-md-0 w-30 pl-md-2 pr-md-2  order-2 ">
                                            <div *ngIf="sh?.applicantType">
                                                <span *ngIf="sh?.applicantType == 'NATURAL'" [innerHTML]="'@i18n-quote-option-list-applicantType-option-natural' | i18n:lang "></span>
                                                <span *ngIf="sh?.applicantType == 'LEGAL'" [innerHTML]="'@i18n-quote-option-list-applicantType-option-legal' | i18n:lang "></span>
                                            </div>
                                        </div>


                                        <div *ngIf="sh?.applicantType == 'NATURAL'" (click)="openItem(sh, getShareholderByIdentifier(row.name))" class="mb-1 mb-md-0   order-2  mt-md-0 w-20 pl-md-2 pr-md-2   ">
                                            <div>
                                                {{sh?.person?.sharePercentage}}%
                                            </div>
                                        </div>
                                        <div *ngIf="sh?.applicantType == 'LEGAL'" (click)="openItem(sh, getShareholderByIdentifier(row.name))" class="mb-1 mb-md-0  mt-md-0 w-20 pl-md-2 pr-md-2  order-3 ">
                                            <div>
                                                {{sh?.organization?.sharePercentage}}%
                                            </div>
                                        </div>

                                        <div class=" mb-1 mb-md-0 text-md-right  mt-md-0 w-10 pl-md-2 pr-md-2  order-3">
                                            <a *ngIf="!readonlyMode" href="javascript:void(0)" (click)="confirmDelete(sh)" class="font-weight-bold">
                                                <i class="fa fa-trash"></i>
                                            </a>
                                        </div>

                                        <div class=" mb-1 mb-md-0 text-md-right  mt-md-0 w-10 pl-md-2 pr-md-2  order-4 d-none d-md-block ">
                                            <a href="javascript:void(0)" (click)="openItem(sh, getShareholderByIdentifier(row.name))" class="font-weight-bold">
                                                <i class="fas fa-angle-right text-primary"></i>
                                            </a>
                                        </div>

                                    </div>
                                    <hr class="m-0" *ngIf="(i_stf+1) === row.value.length">
                                
                                </ng-container>

                            -->


                                <ng-container *ngIf="row.value.length > 0">

                                        <div class="text-secondary mb-2 mt-4" style="font-size: 1rem" >Total participación accionaria identificada <span class="font-weight-bold"> {{row.totalAccionistasPorcentage}}% de 100%</span></div>

                                </ng-container>



                                <ng-container *ngIf="row.value.length === 0">
                                    <hr>
                                    <div class="text-center" style="font-size: 1rem"> 
                                      Por favor identifique a los accionistas de <b> {{ getNameShareholder(row.name) }}</b>
                                    </div>
                                    <hr>

                                </ng-container>
                                <div class="mt-3" *ngIf="!readonlyMode && row.totalAccionistasPorcentage <100">
                                    <button type="button" (click)="addRelated(getShareholderByIdentifier(row.name))" class="btn col-md-3 offset-md-9 px-3 btn-outline-primary">
                                        <span>Agregar</span>
                                    </button>
                                </div>

                            </div>



                        </div>
                    </ng-container>

                    <ng-container *ngIf="!shareholders_related">
                        <div class="w-100 ak-table-0-comment py-2">
                            <hr class="mb-4">
                            <i class="ak-text-color-1  fas fa-sitemap mr-3"></i>             

                            <span [innerHTML]="'@i18n-register-text-standard' | i18n:lang "></span>
                            <span [innerHTML]="'@i18n-quote-shareholders-related-shareholdersTable-section.title' | i18n:lang "></span>


                            <hr class="mt-4">
                        </div>
                    </ng-container>


                </div>
            </ng-container>

            <ng-container *ngIf="loading">
                <div>
                    <img  src="/assets/public/images/loading.gif" alt="">
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>